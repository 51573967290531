import PropTypes from 'prop-types';
import Icon from '../../../new_arch/components/Icon/Icon';
import './WarningIcon.scss';

const WarningIcon = ( { size, color } ) => (
	<div className="WarningIcon">
		<div className="white-background" />
		<Icon
			icon="error"
			size={size}
			color={color}
		/>
	</div>
);

WarningIcon.propTypes = {
	size: PropTypes.number,
	color: PropTypes.string
};

WarningIcon.defaultProps = {
	size: '',
	color: ''
};

export default WarningIcon;
