import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import classNames from 'classnames';
import SingleValue from './SingleValue/SingleValue';
import Option from './Option/Option';

import './Select.scss';

const types = [ 'tall', 'borderless' ];

const Select = ( {
	initialValue, options, placeholder, showSelectedDot,
	selected, onChange, withDescription, displayValueFormatter,
	label, type, informationTooltip, disabled, isSearchable,
	required, withButtonOption, invalidSelection, error,
	willShowError, selectedOptionType
} ) => (
	<div className={classNames( 'Select', {
		[ type ]: type, withDescription, withButtonOption, invalidSelection, error: !!error
	} )}
	>
		<div className="select-header">
			{!!label && (
				<h4>
					{label}
					{required && <span>*</span>}
				</h4>
			)}
			{informationTooltip}
		</div>
		<ReactSelect
			defaultValue={initialValue}
			value={selected}
			placeholder={placeholder}
			options={options}
			onChange={onChange}
			isDisabled={disabled}
			isSearchable={isSearchable}
			components={{
				SingleValue: props => (
					<SingleValue
						{...props}
						greyOut
						showSelectedDot={showSelectedDot}
						displayValueFormatter={displayValueFormatter}
						selectedOptionType={selectedOptionType}
					/>
				),
				Option: props => <Option {...props} withDescription={withDescription} />,
				NoOptionsMessage: () => <p className="empty-selection">No options</p>
			}}
			classNamePrefix="Select"
		/>
		{willShowError
		&& (
			<p className={classNames( 'error-message', { visible: error && typeof error === 'string' } )}>
				{invalidSelection && error}
			</p>
		) }
	</div>
);

Select.propTypes = {
	initialValue: PropTypes.oneOfType( [
		PropTypes.shape( {
			label: PropTypes.string.isRequired,
			caption: PropTypes.string,
			options: PropTypes.arrayOf( PropTypes.any )
		} ),
		PropTypes.any
	] ),
	options: PropTypes.oneOfType( [
		PropTypes.arrayOf(
			PropTypes.shape( {
				label: PropTypes.string.isRequired,
				caption: PropTypes.string,
				options: PropTypes.arrayOf( PropTypes.any )
			} )
		),
		PropTypes.arrayOf( PropTypes.any )
	] ),
	selected: PropTypes.any,
	placeholder: PropTypes.string,
	displayValueFormatter: PropTypes.func,
	onChange: PropTypes.func,
	withDescription: PropTypes.bool,
	showSelectedDot: PropTypes.bool,
	label: PropTypes.string,
	type: PropTypes.oneOf( types ),
	informationTooltip: PropTypes.element,
	disabled: PropTypes.bool,
	isSearchable: PropTypes.bool,
	required: PropTypes.bool,
	withButtonOption: PropTypes.bool,
	invalidSelection: PropTypes.bool,
	error: PropTypes.oneOfType( [ PropTypes.bool, PropTypes.string ] ),
	willShowError: PropTypes.bool,
	selectedOptionType: PropTypes.string
};

Select.defaultProps = {
	initialValue: undefined,
	options: [],
	selected: undefined,
	placeholder: 'Select...',
	displayValueFormatter: label => label,
	onChange: () => {},
	withDescription: false,
	showSelectedDot: false,
	label: undefined,
	type: undefined,
	informationTooltip: undefined,
	disabled: false,
	isSearchable: false,
	required: false,
	withButtonOption: false,
	invalidSelection: false,
	error: false,
	willShowError: false,
	selectedOptionType: undefined
};

export default Select;
