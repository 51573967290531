import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import Icon from '../../new_arch/components/Icon/Icon';

import './DropdownSelect.scss';

/* eslint-disable react/prop-types */
const Option = props => (
	<div className="option-wrapper">
		<components.Option {...props} />
		{props.isSelected && <Icon icon="check" size={14} />}
	</div>
);

const createSingleValueComponentForFormatter = formatter => props => (
	<components.SingleValue {...props}>{formatter( props.data )}</components.SingleValue>
);
/* eslint-enable react/prop-types */

const DropdownSelect = ( {
	options, placeholder, displayValueFormatter,
	selected, onChange
} ) => (
	<div className="DropdownSelect">
		<Select
			value={selected}
			placeholder={placeholder}
			options={options}
			onChange={onChange}
			components={{
				SingleValue: createSingleValueComponentForFormatter( displayValueFormatter ),
				Option
			}}
			classNamePrefix="DropdownSelect"
			aria-label="Dropdown"
		/>
	</div>
);

DropdownSelect.propTypes = {
	options: PropTypes.oneOfType( [
		PropTypes.arrayOf(
			PropTypes.shape( {
				label: PropTypes.string,
				options: PropTypes.arrayOf( PropTypes.any )
			} )
		),
		PropTypes.arrayOf( PropTypes.any )
	] ),
	displayValueFormatter: PropTypes.func,
	selected: PropTypes.any,
	placeholder: PropTypes.string,
	onChange: PropTypes.func
};

DropdownSelect.defaultProps = {
	options: [],
	selected: undefined,
	placeholder: 'Select...',
	displayValueFormatter: selected => selected.label,
	onChange: () => {}
};

export default DropdownSelect;
