import { createSelector } from 'reselect';
import UserPreferences from '../entities/userPreferences';

export const getRawUserPreferences = state => state.userPreferences;
export const getRawCurrentUser = state => state.currentUser;

export const getUserPreferences = createSelector(
	getRawUserPreferences,
	userPreferences => userPreferences.map(
		userPreference => UserPreferences.fromJSON( userPreference )
	)
);

export const getCurrentUserPreferences = createSelector(
	[ getRawCurrentUser, getUserPreferences ],
	( currentUser, userPreferences ) => userPreferences.get( currentUser?.userPreferencesID )
);
