type GuestHashParams = {
	id: number;
	guestHash: string;
	enabled: boolean;
}

export type GuestHashJSON = {
	id: number;
	guest_hash: string;
	enabled: boolean;
}

export default class GuestHash {
	id: number;
	guestHash: string;
	enabled: boolean;

	constructor( { id, guestHash, enabled }: GuestHashParams ) {
		this.id = id;
		this.guestHash = guestHash;
		this.enabled = enabled || false;
	}

	static fromJSON( json: GuestHashJSON ) {
		return new this( {
			id: json.id,
			guestHash: json.guest_hash,
			enabled: json.enabled
		} );
	}

	toJSON() {
		return {
			id: this.id,
			guest_hash: this.guestHash,
			enabled: this.enabled
		};
	}
}
