import { useEffect } from 'react';

import UserProfilePanel from '../../components/UserProfilePanel/UserProfilePanel';
import ProfileSettingsContainer from '../ProfileSettingsContainer/ProfileSettingsContainer';
import NotificationsSettingsContainer from '../NotificationsSettingsContainer/NotificationsSettingsContainer';
import SecurityTab from '../../components/SecurityTab/SecurityTab';

import useTabsWithRoutes from '../../hooks/useTabsWithRoutes';
import { trackNavigatedToMyProfile } from '../../services/tracker/trackers/navigation';

export const PROFILE_TABS = [
	{ name: 'Profile', component: ProfileSettingsContainer, availableForUser: () => true },
	{ name: 'Security', component: SecurityTab, key: 'security', availableForUser: user => !user.isLinkedToExternalAuthProvider },
	{ name: 'Notifications', component: NotificationsSettingsContainer, key: 'notifications', availableForUser: () => true }
];

const UserProfilePanelContainer = () => {
	useEffect( () => trackNavigatedToMyProfile(), [] );

	const [ tabs, selectedTabIndex, onTabChanged ] = useTabsWithRoutes(
		PROFILE_TABS, 'section', '/user/profile'
	);

	return (
		<UserProfilePanel
			tabs={tabs}
			selectedTabIndex={selectedTabIndex}
			onTabChange={onTabChanged}
		/>
	);
};

export default UserProfilePanelContainer;
