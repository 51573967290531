import React, { ReactNode, createContext, useState } from 'react';
import { useStore } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import ReduxTanStackSync from '../services/ReduxTanStackSync';

const ReduxTanStackSyncContext = createContext<ReduxTanStackSync | null>( null );

const ReduxTanStackSyncProvider = ( { children } : { children: ReactNode } ) => {
	const store = useStore();
	const queryClient = useQueryClient();
	const [ sync ] = useState( () => new ReduxTanStackSync( { store, queryClient } ) );
	return (
		<ReduxTanStackSyncContext.Provider value={sync}>
			{ children }
		</ReduxTanStackSyncContext.Provider>
	)
}

export const useReduxTanStackSync = () => React.useContext( ReduxTanStackSyncContext ) as ReduxTanStackSync;

export default ReduxTanStackSyncProvider;
