import { Map } from 'immutable';
import {
	DELETE_ASSETS, DELETE_ASSET, FETCH_COMMENTS_LAST_SEEN_BY_USERS, LOGOUT
} from '../actions/types';
import { handleStart, handleSuccess } from '../lib/reduxUtils';

export const assetCommentLastSeenByUsers = ( state = Map(), action ) => {
	switch ( action.type ) {
	case FETCH_COMMENTS_LAST_SEEN_BY_USERS:
		return handleSuccess(
			state,
			action,
			( prevState ) => {
				const { payload: lastSeenByUsers, meta: { assetID } } = action;
				const newKey = lastSeenByUsers.map(
					lastSeenByUser => ( {
						userID: lastSeenByUser.user.id,
						lastSeenAt: lastSeenByUser.last_seen_at
					} )
				);
				return prevState.set( assetID, newKey );
			} );
	case DELETE_ASSET:
		return handleSuccess(
			state,
			action,
			prevState => prevState.delete( action.payload )
		);
	case DELETE_ASSETS:
		return handleSuccess(
			state,
			action,
			prevState => action.payload.reduce(
				( newState, deleteID ) => newState.delete( deleteID ),
				prevState
			)
		);
	case LOGOUT:
		return handleStart( state, action, () => new Map() );
	default:
		return state;
	}
};
