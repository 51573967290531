import { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
	getWorkspaceInvoices, getWorkspaceInvoicesPaginationData
} from '../../selectors/workspaces';

import { connectComponent } from '../../lib/connectComponent';
import WorkspaceInvoicesList from '../../components/WorkspaceInvoicesList/WorkspaceInvoicesList';
import WorkspaceInvoice from '../../entities/workspaceInvoice';

export const WorkspaceInvoicesListContainer = ( {
	workspaceID, invoices, pageData, fetchWorkspaceInvoices
} ) => {
	const { lastInvoiceID: lastID, hasMore } = pageData;

	const fetchInvoicesList = () => {
		fetchWorkspaceInvoices( workspaceID );
	};

	const onShowMoreClicked = () => {
		fetchWorkspaceInvoices( workspaceID, lastID );
	};

	useEffect( fetchInvoicesList, [ workspaceID ] );

	return (
		<WorkspaceInvoicesList
			workspaceID={workspaceID}
			invoices={invoices}
			hasMore={hasMore}
			onShowMoreClicked={onShowMoreClicked}
			lastID={lastID}
		/>
	);
};

WorkspaceInvoicesListContainer.propTypes = {
	workspaceID: PropTypes.number.isRequired,
	invoices: PropTypes.arrayOf( PropTypes.instanceOf( WorkspaceInvoice ) ),
	pageData: PropTypes.shape( {
		hasMore: PropTypes.bool,
		lastInvoiceID: PropTypes.string
	} ),
	fetchWorkspaceInvoices: PropTypes.func
};

WorkspaceInvoicesListContainer.defaultProps = {
	invoices: [],
	pageData: {
		hasMore: undefined,
		lastInvoiceID: undefined
	},
	fetchWorkspaceInvoices: () => {}
};

export default connectComponent( ( state, props ) => ( {
	invoices: getWorkspaceInvoices( state, props ),
	pageData: getWorkspaceInvoicesPaginationData( state, props )
} ) )( WorkspaceInvoicesListContainer );
