import PropTypes from 'prop-types';
import { connectComponent } from '../../lib/connectComponent';
import Project from '../../entities/project';

import AssetDropFilesIndicatorBox
	from '../../components/assets/AssetsDropFilesIndicatorBox/AssetsDropFilesIndicatorBox';
import { getProject } from '../../selectors/projects';
import { getFolder } from '../../selectors/folders';
import { getAsset } from '../../selectors/assets';
import Folder from '../../entities/folder';
import Asset from '../../entities/asset';

export const AssetDropFilesIndicatorBoxContainer = ( {
	project, folder, asset, uploadingRoundForAsset
} ) => (
	<AssetDropFilesIndicatorBox
		projectName={project?.name}
		folderName={folder?.name}
		assetName={asset?.name}
		uploadingRoundForAsset={uploadingRoundForAsset}
	/>
);

AssetDropFilesIndicatorBoxContainer.propTypes = {
	project: PropTypes.instanceOf( Project ),
	folder: PropTypes.instanceOf( Folder ),
	asset: PropTypes.instanceOf( Asset ),
	uploadingRoundForAsset: PropTypes.bool
};

AssetDropFilesIndicatorBoxContainer.defaultProps = {
	project: undefined,
	folder: undefined,
	asset: undefined,
	uploadingRoundForAsset: false
};

export default connectComponent( ( state, props ) => ( {
	project: getProject( state, props ),
	folder: getFolder( state, props ),
	asset: getAsset( state, props )
} ) )( AssetDropFilesIndicatorBoxContainer );
