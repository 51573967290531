import PropTypes from 'prop-types';
import classNames from 'classnames';
import CloseableModal from '../CloseableModal';
import IconButton from '../../components/UI/IconButton/IconButton';

import './CloseableModalWithTitle.scss';

const HEADER_BORDER_TYPES = [ 'none', 'small' ];

const CloseableModalWithTitle = ( {
	title, children, onClose, headerBorderType, name, onBack, className
} ) => {
	const headerClassName = classNames(
		'header',
		`border-radius-${headerBorderType}`
	);

	return (
		<CloseableModal
			className={classNames( 'react-router-modal__modal CloseableModalWithTitle', { onBack }, className )}
			name={name}
			onClose={onClose}
		>
			<div className={headerClassName}>
				{onBack && <IconButton onClick={onBack} icon="arrow_back" />}
				{title && <h4>{title}</h4>}
				{!onBack && <IconButton onClick={onClose} icon="close" />}
			</div>
			{children}
		</CloseableModal>
	);
};

CloseableModalWithTitle.propTypes = {
	title: PropTypes.string,
	onClose: PropTypes.func,
	children: PropTypes.node,
	headerBorderType: PropTypes.oneOf( HEADER_BORDER_TYPES ),
	name: PropTypes.string,
	className: PropTypes.string,
	onBack: PropTypes.func
};

CloseableModalWithTitle.defaultProps = {
	title: undefined,
	onClose: () => {},
	children: null,
	headerBorderType: 'none',
	name: undefined,
	className: undefined,
	onBack: null
};

export default CloseableModalWithTitle;
