import AssetSystem from './AssetSystem';
import FolderSystem from './FolderSystem';
import LabelSystem from './LabelSystem';
import OverlaySystem from './OverlaySystem';
import PopupSystem from './PopupSystem';
import RotateMobileScreenSystem from './RotateMobileScreenSystem';
import SnackbarSystem from './SnackbarSystem';
import ModalSystem from './ModalSystem';
import WorkspaceSystem from './WorkspaceSystem';

export default {
	labelSystem: LabelSystem,
	popupSystem: PopupSystem,
	assetSystem: AssetSystem,
	snackbarSystem: SnackbarSystem,
	overlaySystem: OverlaySystem,
	rotateMobileScreenSystem: RotateMobileScreenSystem,
	folderSystem: FolderSystem,
	modalSystem: ModalSystem,
	workspaceSystem: WorkspaceSystem
};
