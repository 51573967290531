import PropTypes from 'prop-types';

import CloseableModalWithTitle from '../CloseableModalWithTitle/CloseableModalWithTitle';
import Workspace from '../../entities/workspace';
import AddOn from '../../entities/addOn';
import InviteWorkspaceMemberBillingConfBox from '../../components/InviteWorkspaceMemberBillingConfBox/InviteWorkspaceMemberBillingConfBox';

import './InviteWorkspaceMemberBillingConfirmationModal.scss';

const InviteWorkspaceMemberBillingConfirmationModal = (
	{ workspace, addOn, onCancel, onConfirm, loading, emails }
) => (
	<CloseableModalWithTitle
		headerBorderType="small"
		onClose={onCancel}
		name="invite-workspace-member-billing-confirmation"
		title="Additional charges will apply"
	>
		<div className="InviteWorkspaceMemberBillingConfirmationModal">
			<InviteWorkspaceMemberBillingConfBox
				workspace={workspace}
				addOn={addOn}
				onCancel={onCancel}
				onConfirm={onConfirm}
				loading={loading}
				emails={emails}
			/>
		</div>
	</CloseableModalWithTitle>
);

InviteWorkspaceMemberBillingConfirmationModal.propTypes = {
	workspace: PropTypes.instanceOf( Workspace ).isRequired,
	addOn: PropTypes.instanceOf( AddOn ).isRequired,
	loading: PropTypes.bool,
	onCancel: PropTypes.func,
	onConfirm: PropTypes.func,
	emails: PropTypes.arrayOf( PropTypes.string ).isRequired
};

InviteWorkspaceMemberBillingConfirmationModal.defaultProps = {
	onCancel: () => {},
	onConfirm: () => {},
	loading: false
};

export default InviteWorkspaceMemberBillingConfirmationModal;
