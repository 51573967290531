import AddOn from './addOn';
import AddOnPrice from './addOnPrice';

export default class AddOnItem {
	constructor( {
		id, quantity, includedAmount, type, addOnPrice
	} ) {
		this.id = id;
		this.quantity = quantity;
		this.includedAmount = includedAmount;
		this.type = type;
		this.addOnPrice = addOnPrice;

		this._validateType();
	}

	static fromJSON( properties ) {
		return new AddOnItem( {
			id: properties.id,
			quantity: properties.quantity,
			includedAmount: properties.included_amount,
			type: properties.type,
			addOnPrice: AddOnPrice.fromJSON( properties.add_on_price )
		} );
	}

	static get null() {
		return new AddOnItem( {
			id: 0,
			quantity: 0,
			includedAmount: 0,
			type: null,
			addOnPrice: AddOnPrice.null
		} );
	}

	get extraAmount() {
		return this.includedAmount * this.quantity;
	}

	get cost() {
		return this.quantity * this.addOnPrice.cost;
	}

	toJSON() {
		return {
			id: this.id,
			quantity: this.quantity,
			included_amount: this.includedAmount,
			type: this.type,
			add_on_price: this.addOnPrice.toJSON()
		};
	}

	_validateType() {
		AddOn.validateType( this.type );
	}
}
