import PropTypes from 'prop-types';

import UserPropType from '../../types/user';

import UserSelectorItem from '../UserSelectorItem/UserSelectorItem';

import './UserSelector.scss';

const UserSelector = ( { users, onUserClick } ) => (
	<div className="UserSelector">
		<div className="header">
			<p>Select User</p>
		</div>
		<div className="body">
			{users.map( user => (
				<UserSelectorItem
					key={user.id}
					user={user}
					onClick={() => onUserClick( user )}
				/>
			) )}
		</div>
	</div>
);

UserSelector.propTypes = {
	users: PropTypes.arrayOf( UserPropType ),
	onUserClick: PropTypes.func
};

UserSelector.defaultProps = {
	users: [],
	onUserClick: () => {}
};

export default UserSelector;
