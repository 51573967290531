import Project from './project';

export default class WorkspaceProject extends Project {
	constructor(
		id,
		name,
		description,
		clientName,
		primaryContact,
		coverImageURL,
		coverAssetID,
		status,
		shareHash,
		shareHashDownloadable,
		unresolvedCommentsCount,
		hasRushPriority,
		currentRoleId,
		startDate,
		dueDate,
		createdAt,
		updatedAt,
		workspace,
		lastActivityAt,
		users,
		storageUsedInBytes,
		totalAssetsCount,
		lastModifier,
		owner
	) {
		super(
			id,
			name,
			description,
			clientName,
			primaryContact,
			coverImageURL,
			coverAssetID,
			status,
			shareHash,
			shareHashDownloadable,
			unresolvedCommentsCount,
			hasRushPriority,
			currentRoleId,
			startDate,
			dueDate,
			createdAt,
			updatedAt,
			workspace,
			lastActivityAt,
			[],
			undefined,
			storageUsedInBytes,
			totalAssetsCount,
			lastModifier,
			owner
		);

		this.users = users;
	}

	// Serialization
	static fromJSON( projectProperties, workspace ) {
		return new WorkspaceProject(
			projectProperties.id,
			projectProperties.name,
			projectProperties.description,
			projectProperties.client_name,
			projectProperties.primary_contact,
			projectProperties.cover_url,
			projectProperties.cover_id,
			projectProperties.status,
			projectProperties.share_hash,
			projectProperties.share_hash_downloadable,
			projectProperties.unresolved_comments_count,
			projectProperties.rush_priority,
			projectProperties.current_user_role?.type,
			projectProperties.start_date,
			projectProperties.due_date,
			projectProperties.created_at,
			projectProperties.updated_at,
			workspace,
			projectProperties.last_activity_at,
			projectProperties.users,
			projectProperties.storage_used_in_bytes,
			projectProperties.total_assets_count,
			projectProperties.last_activity_by,
			projectProperties.owner
		);
	}
}
