import { Children, cloneElement, Component, forwardRef } from 'react';
import PropTypes from 'prop-types';

// list item container size
const listItemContainerMaxHeight = 100;
const listItemContainerMinHeight = 40;
const b = listItemContainerMinHeight;
const m = ( listItemContainerMaxHeight - b ) / 100;

// image size
const listItemImageMaxHeight = 90;
const listItemImageMinHeight = 30;
const b2 = listItemImageMinHeight;
const m2 = ( listItemImageMaxHeight - b2 ) / 100;

export class List extends Component {
	get listItems() {
		return Children
			.map( this.props.children, child => cloneElement( child, {
				componentProps: {
					containerHeight: this.itemHeight,
					imageSize: this.imageSize
				}
			} ) );
	}

	get listItemContainerSlope() { return m * this.props.itemsSize; }

	get imageSlope() { return m2 * this.props.itemsSize; }

	get itemHeight() { return this.listItemContainerSlope + b; }

	get imageSize() { return this.imageSlope + b2; }

	render() {
		const { listItems, props: { forwardedRef } } = this;
		return (
			<div ref={forwardedRef} className="items">
				{ listItems }
			</div>
		);
	}
}

List.propTypes = {
	children: PropTypes.arrayOf( PropTypes.any ).isRequired,
	itemsSize: PropTypes.number.isRequired,
	forwardedRef: PropTypes.any.isRequired
};

export default forwardRef( ( props, ref ) => (
	<List forwardedRef={ref} {...props} />
) );
