import { getFileExtension } from '../lib/fileUtils';
import AppError from './AppError';

export default class InvalidExtensionsError extends AppError {
	constructor( { files } ) {
		super( {
			name: 'InvalidExtensionsError',
			description: 'One or more selected files have invalid extensions.'
		} );
		this.files = files;
	}

	get invalidExtensions() {
		return this.files.map( file => getFileExtension( file.name ) ).uniq();
	}
}
