import { Map, List } from 'immutable';
import { handle } from 'redux-pack';
import {
	CREATE_COMMENT_FOR_ASSET, DELETE_ASSET, DELETE_ASSETS, DELETE_COMMENT,
	FREEZE_COMMENTS_VIEW_FILTER, UNFREEZE_COMMENTS_VIEW_FILTER,
	LOGOUT, SET_COMMENTS_VIEW_FILTER
} from '../actions/types';
import { handleStart, handleSuccess } from '../lib/reduxUtils';

export const commentsViewFilter = ( state = Map(), action ) => {
	switch ( action.type ) {
	case SET_COMMENTS_VIEW_FILTER: {
		const { assetID, filterID } = action.payload;
		return state.set( assetID, filterID );
	}
	case DELETE_ASSET:
		return handleSuccess( state, action,
			prevState => prevState.delete( action.payload )
		);
	case DELETE_ASSETS:
		return handleSuccess( state, action,
			prevState => action.payload.reduce(
				( newState, deletedID ) => newState.delete( deletedID ),
				prevState
			)
		);
	default:
		return state;
	}
};

export const commentsFrozenViewFilter = ( state = new List(), action ) => {
	switch ( action.type ) {
	case FREEZE_COMMENTS_VIEW_FILTER: {
		const { payload: commentIDs } = action;
		return new List( commentIDs );
	}
	case CREATE_COMMENT_FOR_ASSET:
		return handle( state, action,
			{
				start: prevState => prevState.push( action.meta.tempComment.id ),
				success: prevState => prevState
					.filter( id => id !== action.meta.tempComment.id )
					.push( action.payload.id ),
				failure: prevState => prevState.filter( id => id !== action.meta.tempComment.id )
			} );
	case DELETE_COMMENT:
		return handleSuccess( state, action,
			prevState => prevState.filter( id => id !== action.payload ) );
	case UNFREEZE_COMMENTS_VIEW_FILTER:
		return new List();
	case LOGOUT:
		return handleStart( state, action, () => new List() );
	default:
		return state;
	}
};
