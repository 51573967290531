import { sharedTracker } from '../Tracker';
import events from '../events';

export const trackOpenedSharedProject = shareHash => sharedTracker
	.trackSharingAction( {
		action: events.sharing.OPENED_SHARED_PROJECT,
		shareHash
	} );

export const trackOpenedSharedFolder = shareHash => sharedTracker
	.trackSharingAction( {
		action: events.sharing.OPENED_SHARED_FOLDER,
		shareHash
	} );
