export class UsersPermissions {
	constructor( {
		see = false,
		invite = false,
		editRole = false,
		destroy = false
	} ) {
		this.see = see;
		this.invite = invite;
		this.editRole = editRole;
		this.destroy = destroy;
	}
}

// Project Permissions
export const ownerUsersPermissions = new UsersPermissions( {
	see: true, invite: true, editRole: true, destroy: true
} );
export const projectCollaboratorUsersPermissions = new UsersPermissions( {
	see: true, invite: true, editRole: false, destroy: true
} );
export const projectGuestUsersPermissions = new UsersPermissions( {
	see: true, invite: false, editRole: false, destroy: false
} );

// Workspace Permissions
export const workspaceOwnerUsersPermissions = new UsersPermissions( {
	see: true, invite: true, editRole: true, destroy: true
} );
export const adminPermissions = new UsersPermissions( {
	see: true
} );
export const collaboratorPermissions = new UsersPermissions( {
	see: true
} );
