export default class AppError extends Error {
	constructor( { name, description = null } = {} ) {
		const message = typeof description === 'string' ? description : '';

		super( message );
		this.name = this.constructor.name;
		this.errorName = name;
		this.errorDescription = description;
	}
}
