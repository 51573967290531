import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAuthSystem from '../../../new_arch/modules/authentication/system/useAuthSystem';
import { UserJson } from '../../../new_arch/models/user/user';
import { CURRENT_USER_KEY } from '../../../new_arch/modules/authentication/queries/keys';
import { useReduxTanStackSync } from '../../../new_arch/providers/ReduxTanStackSyncProvider';

type useUpdateCurrentUserRoleParams = {
	selectedRole: string;
	userId: number;
}

const useUpdateCurrentUserRole = () => {
	const authSystem = useAuthSystem();
	const queryClient = useQueryClient();
	const sync = useReduxTanStackSync();

	return useMutation(
		(
			{ selectedRole, userId }: useUpdateCurrentUserRoleParams
		) => authSystem.updateCurrentUserRole( selectedRole, userId ),
		{
			onSuccess: ( data: UserJson ) => {
				queryClient.setQueryData( CURRENT_USER_KEY, data );
				sync.currentUser( data );
			}
		}
	);
}

export default useUpdateCurrentUserRole;
