import Point from './point';

class Drawable {
	constructor( strokeColor, strokeSize, isRelative = true ) {
		this._strokeColor = strokeColor;
		this._strokeSize = strokeSize;
		this._isRelative = isRelative;
	}

	get strokeColor() { return this._strokeColor; }

	get strokeSize() { return this._strokeSize; }

	get isRelative() { return this._isRelative; }

	get renderer() { throw new Error( 'Must implement in sublcass!' ); }

	_toRelativeCoordsConverter( { width, height } ) {
		return point => new Point( {
			x: point.get( 'x' ) / width,
			y: point.get( 'y' ) / height
		} );
	}
}

export default Drawable;
