export default class Field {
	constructor( name, label, value, validators ) {
		this.name = name;
		this.label = label;
		this.value = value;
		this.validators = validators;
		this.errors = [];
	}

	setValue( value ) {
		return new Field( this.name, this.label, value, this.validators );
	}

	validate( parentFormData ) {
		const { label, value, validators } = this;

		this.errors = [];

		return validators.reduce(
			( result, validator ) => {
				const validationResult = validator.validate(
					value, label, this.errors, parentFormData
				);
				return result && validationResult;
			},
			true
		);
	}
}
