import { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FilterSelector from '../../FilterSelector/FilterSelector';
import FilterOption from '../../../entities/filterOption';
import IconButton from '../../UI/IconButton/IconButton';

import './NotificationsHeader.scss';

const NotificationsHeader = ( {
	projectID, onClose, selectedFilterOption, onSetNotificationFilter,
	disabledFiltering, onSettingsOpened
} ) => {
	const setNotificationFilter = useCallback(
		selection => onSetNotificationFilter( projectID, selection ),
		[ projectID, onSetNotificationFilter ]
	);

	const noFilterSelected = selectedFilterOption?.id === FilterOption.none.id;

	return (
		<div className={classNames( 'NotificationsHeader', { 'disabled-filtering': disabledFiltering } )}>
			<div className="settings-title-close-container">
				<h4>Notifications</h4>
				<IconButton onClick={onClose} icon="close" size={24} />
			</div>
			<div className={classNames( 'filter-container', { 'no-filter-selected': noFilterSelected } )}>
				<FilterSelector
					selectedFilterOption={selectedFilterOption}
					filterOptions={FilterOption.notificationsFilters}
					onViewFilterSet={setNotificationFilter}
					disabled={disabledFiltering}
					isSearchable
				/>
				<IconButton
					icon="settings_outlined"
					size={20}
					onClick={onSettingsOpened}
				/>
			</div>
		</div>
	);
};

NotificationsHeader.propTypes = {
	projectID: PropTypes.number,
	onSetNotificationFilter: PropTypes.func,
	selectedFilterOption: PropTypes.instanceOf( FilterOption ).isRequired,
	onClose: PropTypes.func,
	projectName: PropTypes.string,
	disabledFiltering: PropTypes.bool,
	onSettingsOpened: PropTypes.func.isRequired
};

NotificationsHeader.defaultProps = {
	projectID: undefined,
	onSetNotificationFilter: () => {},
	onClose: () => {},
	projectName: '',
	disabledFiltering: false
};

export default NotificationsHeader;
