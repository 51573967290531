export default class NullWorkspace {
	get id() {
		return null;
	}

	get includedStorage() {
		return 0;
	}

	get storageAvailable() {
		return 0;
	}

	get storageAddOnQuantity() {
		return 0;
	}

	get hasStorageAddOns() {
		return false;
	}

	get storageAddOnIncludedAmount() {
		return 0;
	}

	get extraStorage() {
		return 0;
	}

	get includedMembers() {
		return 0;
	}

	get membersAddOnQuantity() {
		return 0;
	}

	get hasMembersAddOns() {
		return false;
	}

	get extraMembers() {
		return 0;
	}

	get membersAddOnsCost() {
		return 0;
	}

	get storageAddOnsCost() {
		return 0;
	}

	get currentUserCanCreateProject() {
		return false;
	}

	get currentUserIsOwner() {
		return false;
	}

	get userCanListAllMembers() {
		return false;
	}

	get status() {
		return null;
	}

	get isActive() {
		return false;
	}

	get isNullWorkspace() {
		return true;
	}

	get hasExpiredFreeTrial() {
		return false;
	}

	get hasSubscription() {
		return false;
	}

	get hasAPaidPlan() {
		return false;
	}

	get hasADowngradePending() {
		return false;
	}

	get isSubscriptionActive() {
		return false;
	}

	get isSubscriptionMarkedForCancellation() {
		return false;
	}

	get subscriptionCanBeCancelled() {
		return false;
	}

	get subscriptionInterval() {
		return null;
	}

	get hasYearlySubscriptionInterval() {
		return false;
	}

	get hasAMonthlyBillingPeriod() {
		return false;
	}

	get subscriptionProjectsCount() {
		return 0;
	}

	get subscriptionMaxProjectCount() {
		return 0;
	}

	get subscriptionLastPaymentSucceeded() {
		return false;
	}

	get billingPeriodEndsAt() {
		return null;
	}

	get daysUntilDowngrade() {
		return null;
	}

	get isOnFreeTrial() {
		return false;
	}

	get daysLeftOnFreeTrial() {
		return null;
	}

	get showPendingDowngradeBanner() {
		return false;
	}

	prorateCostToEndOfBillingPeriod( cost ) {
		return cost;
	}

	get isSuspended() {
		return false;
	}
}
