import type WorkspaceSystem from '../../../systems/WorkspaceSystem';
import { LabelParams } from '../../../entities/labelGroup';

export default class LabelGroupCreateStrategy {
	private workspaceID: number;
	private workspaceSystem: WorkspaceSystem;

	constructor( { workspaceID, workspaceSystem }: { workspaceID: number, workspaceSystem: WorkspaceSystem } ) {
		this.workspaceID = workspaceID;
		this.workspaceSystem = workspaceSystem;
	}

	get title() {
		return 'New Label group';
	}

	get initialName() {
		return '';
	}

	get initialLabels(): LabelParams[] {
		return [];
	}

	get submitButtonLabel() {
		return 'Create';
	}

	get successMessage() {
		return 'Label group created successfully';
	}

	makeLabelGroupRequest( name: string, labels: LabelParams[] ) {
		return this.workspaceSystem.createLabelGroup( {
			workspaceID: this.workspaceID,
			name,
			labels
		} );
	}
}
