import { WORKSPACES_URL } from '../config/urls';
import { FETCH_WORKSPACE_INVOICES, FETCH_WORKSPACE_INVOICES_NEXT_PAGE } from './types';
import { createURLWithParams } from '../lib/urlUtils';
import { catchNotAllowedResponseWrapper } from '../lib/catchNotAllowedResponseWrapper';

export const fetchWorkspaceInvoices = (
	workspaceID, afterID, limit
) => ( dispatch, _, api ) => {
	const parameters = { starting_after: afterID, limit };

	const type = !afterID ? FETCH_WORKSPACE_INVOICES : FETCH_WORKSPACE_INVOICES_NEXT_PAGE;

	return dispatch( {
		type,
		promise: catchNotAllowedResponseWrapper(
			api.get(
				createURLWithParams(
					`${WORKSPACES_URL}/${workspaceID}/invoices`,
					parameters
				)
			),
			dispatch,
			workspaceID
		)
			.then( response => response.response ),
		meta: { workspaceID }
	} );
};
