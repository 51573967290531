import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { preventDefaultAndCall } from '../../new_arch/lib/eventUtils';
import usePromoCodeInputPresenter from '../../presenters/PromoCodeInputPresenter/usePromoCodeInputPresenter';
import FormInput from '../../new_arch/components/FormInput/FormInput';
import LoaderButton from '../UI/LoaderButton/LoaderButton';
import IconButton from '../UI/IconButton/IconButton';

import './PromoCodeInput.scss';
import Coupon from '../../entities/coupon';
import PricingPlanPrice from '../../entities/pricing/pricingPlanPrice';

export type PromoCodeInputProps = {
	onPromoCodeApplied: ( appliedPromoCodeID: string | null, appliedCoupon: Coupon | null ) => void;
	pricingPlanPrice: PricingPlanPrice;
};

const PromoCodeInput = ( { onPromoCodeApplied, pricingPlanPrice }: PromoCodeInputProps ) => {
	const presenter = usePromoCodeInputPresenter( { onPromoCodeApplied, pricingPlanPrice } );

	const renderDeleteIconOrApplyButton = () => {
		if ( presenter.isApplied ) {
			return (
				<IconButton
					buttonClassName="PromoCodeInput-delete-button"
					testID="delete-icon"
					icon="delete"
					onClick={presenter.onDeleteClick}
					size={18}
				/>
			);
		}

		return (
			<LoaderButton
				disabled={presenter.applyButtonDisabled}
				loading={presenter.loading}
				onClick={preventDefaultAndCall( presenter.onApplyClick )}
				size="large"
			>
				Apply
			</LoaderButton>
		);
	};

	return (
		<form className="PromoCodeInput" onSubmit={presenter.onApplyClick}>
			<FormInput
				disabled={presenter.isApplied}
				className={classNames( 'PromoCodeInput-input', { isApplied: presenter.isApplied } )}
				error={presenter.error}
				infoMessage={presenter.infoMessage}
				label="Promo Code"
				name="promo_code"
				onChange={presenter.onPromoCodeChange}
				placeholder="PROMO10%"
				value={presenter.promoCode}
			/>
			{ renderDeleteIconOrApplyButton() }
		</form>
	);
};

export default observer( PromoCodeInput );
