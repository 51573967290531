import { createSelector } from 'reselect';

import WorkspaceBillingInformation from '../entities/workspaceBillingInformation';
import { getWorkspaceID } from './workspaces';

export const getRawWorkspacesBillingInformation = state => state.workspacesBillingInformation;

export const getWorkspacesBillingInformation = createSelector(
	[ getRawWorkspacesBillingInformation ],
	rawWorkspacesBillingInformation => rawWorkspacesBillingInformation
		.map( WorkspaceBillingInformation.fromJSON )
);

export const getBillingInformationForWorkspace = createSelector(
	[ getWorkspaceID, getWorkspacesBillingInformation ],
	( workspaceID, workspacesBillingInformation ) => workspacesBillingInformation.get( workspaceID )
);
