import { Map, OrderedMap } from 'immutable';
import { handle, KEY } from 'redux-pack';

export const initialAsyncRequestState = {
	sending: false, error: null, success: false
};

const filterReduxPackActionMeta = ( meta ) => {
	if ( !meta ) { return null; }

	const resultMeta = { ...meta };
	delete resultMeta[ KEY.LIFECYCLE ];
	delete resultMeta[ KEY.TRANSACTION ];

	return Object.keys( resultMeta ).length > 0
		? resultMeta
		: null;
};

const createAsyncActionState = ( sending, error, success, meta ) => {
	const state = { sending, error, success };
	const stateMeta = filterReduxPackActionMeta( meta );

	if ( stateMeta ) {
		state.meta = stateMeta;
	}

	return state;
};

export const reduceAsyncAction = ( state, action ) => handle( state, action, {
	start: ( _, { meta } ) => createAsyncActionState(
		true, null, false, meta
	),
	failure: ( _, { meta, payload } ) => createAsyncActionState(
		false, payload, false, meta
	),
	success: ( _, { meta } ) => createAsyncActionState(
		false, null, true, meta
	)
} );

export const makeAsyncActionReducer = (
	actionType, resetActionType = undefined
) => ( state = initialAsyncRequestState, action ) => {
	if ( !resetActionType ) {
		resetActionType = `RESET_${actionType}`;
	}

	const { type } = action;
	switch ( type ) {
	case actionType:
		return reduceAsyncAction( state, action );
	case resetActionType:
		return initialAsyncRequestState;
	default:
		return state;
	}
};

const handleLifeCycle = lifeCycle => (
	state, action, handler
) => handle( state, action, { [ lifeCycle ]: handler } );

export const handleSuccess = handleLifeCycle( 'success' );

export const handleFailure = handleLifeCycle( 'failure' );

export const handleStart = handleLifeCycle( 'start' );

export const entitiesMapForPayload = ( payload, entityClass, parserMethodName = 'fromJSON' ) => new Map(
	payload.map(
		( properties ) => {
			const entity = entityClass[ parserMethodName ]( properties );
			return [ entity.id, entity ];
		}
	)
);

const mapToPairs = payload => payload.map( properties => [ properties.id, properties ] );

export const mapForPayload = payload => new Map( mapToPairs( payload ) );

export const orderedMapForPayload = payload => new OrderedMap( mapToPairs( payload ) );

export const entitiyMapForPayload = (
	payload, entityClass, previousValues = {}
) => entityClass.fromJSON( { ...previousValues, ...payload } );

export const mapEntityToCustomSort = ( entityMap, storedSortedIDs = [] ) => {
	const entityArray = entityMap.toArray();
	const orderedEntitiesIDs = entityArray.reduce( ( prev, _, index ) => {
		if ( entityMap.has( storedSortedIDs[ index ] ) ) {
			prev.push( storedSortedIDs[ index ] );
		}
		return prev;
	}, [] );

	const unorderedEntitiesIDs = entityArray
		.map( entity => entity.id )
		.filter( entity => !orderedEntitiesIDs.includes( entity ) );

	return [ ...orderedEntitiesIDs, ...unorderedEntitiesIDs ]
		.map( entityID => entityMap.get( entityID ) );
};

export const actionResultIsError = result => result && result.error;

export const handleAsyncActionResult = (
	asyncActionResult,
	successTexts,
	errorTexts,
	resetAction,
	showSuccessMessageAction,
	showErrorMessageAction,
	onSuccess,
	onError
) => asyncActionResult.then( ( result ) => {
	if ( !actionResultIsError( result ) ) {
		if ( successTexts ) showSuccessMessageAction( successTexts );
		if ( resetAction ) resetAction();
		if ( onSuccess ) onSuccess( result.payload );
	} else {
		if ( errorTexts ) showErrorMessageAction( errorTexts );
		if ( onError ) onError( result );
	}

	return result;
} );
