import { SHOW_ROTATE_MOBILE_SCREEN, HIDE_ROTATE_MOBILE_SCREEN } from '../actions/types';

export default class RotateMobileScreenSystem {
	constructor( dispatch ) {
		this.dispatch = dispatch;
	}

	toggleRotateMobileScreen( showingRotateMobileScreen ) {
		if ( showingRotateMobileScreen ) return this.hideRotateMobileScreen();
		return this.showRotateMobileScreen();
	}

	showRotateMobileScreen() {
		return this.dispatch( { type: SHOW_ROTATE_MOBILE_SCREEN } );
	}

	hideRotateMobileScreen() {
		return this.dispatch( { type: HIDE_ROTATE_MOBILE_SCREEN } );
	}
}
