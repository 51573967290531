/* eslint-disable react/no-unused-prop-types */
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import NavigationBreadcrumbs from '../../components/UI/NavigationBreadcrumbs/NavigationBreadcrumbs';
import { connectComponent } from '../../lib/connectComponent';
import {
	getSharedEntity,
	getSharedEntityAncestors,
	getSharedProjectAsset
} from '../../selectors/sharedProjects';

import Asset from '../../entities/asset';
import Folder from '../../entities/folder';
import BreadcrumbLevelFactory from '../../factories/breadcrumbsFactory';
import HistoryPropType from '../../types/history';
import ShareRef from '../../structures/shareRef';
import SharedProject from '../../entities/sharedProject';

export const SharedNavigationBreadcrumbsContainer = ( {
	sharedEntity,
	sharedEntityAncestors,
	asset,
	history
} ) => {
	const ancestorsBreadcrumbs = sharedEntityAncestors.map( ancestor => ( {
		name: ancestor.name, url: ancestor.shareViewPath
	} ) );

	const sharedEntityUrl = asset ? sharedEntity?.shareViewPath : undefined;
	const sharedEntityBreadcrumb = sharedEntity
		? { name: sharedEntity.name, url: sharedEntityUrl }
		: undefined;
	const assetBreadcrumb = asset ? { name: asset.name } : undefined;

	const breadcrumbs = BreadcrumbLevelFactory.arrayFrom( {
		levels: [
			...ancestorsBreadcrumbs,
			sharedEntityBreadcrumb,
			assetBreadcrumb
		],
		history
	} );

	return (
		<NavigationBreadcrumbs
			breadcrumbs={breadcrumbs}
			hideHomeIcon
			hideFirstArrow
		/>
	);
};

SharedNavigationBreadcrumbsContainer.propTypes = {
	shareRef: PropTypes.instanceOf( ShareRef ).isRequired,
	assetID: PropTypes.number,
	sharedEntity: PropTypes.oneOfType( [
		PropTypes.instanceOf( SharedProject ),
		PropTypes.instanceOf( Folder )
	] ),
	sharedEntityAncestors: PropTypes.arrayOf( PropTypes.instanceOf( Folder ) ),
	asset: PropTypes.instanceOf( Asset ),
	history: HistoryPropType.isRequired
};

SharedNavigationBreadcrumbsContainer.defaultProps = {
	assetID: undefined,
	sharedEntity: undefined,
	sharedEntityAncestors: [],
	asset: undefined
};

export default connectComponent( ( state, props ) => ( {
	sharedEntity: getSharedEntity( state, props ),
	sharedEntityAncestors: getSharedEntityAncestors( state, props ),
	asset: getSharedProjectAsset( state, props )
} ) )( withRouter( SharedNavigationBreadcrumbsContainer ) );
