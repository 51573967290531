import { Component } from 'react';

import { connectComponent } from '../../lib/connectComponent';
import { getCurrentUser } from '../../selectors/currentUser';

import UserPropType from '../../types/user';

import ProfileButton from '../../components/navBar/ProfileButton/ProfileButton';

export class ProfileButtonContainer extends Component {
	render() {
		const { currentUser } = this.props;

		if ( !currentUser ) return null;

		return (
			<>
				<ProfileButton
					currentUser={currentUser}
					onProfileClick={this._onProfileClick}
				/>
			</>
		);
	}
}

ProfileButtonContainer.propTypes = {
	currentUser: UserPropType.isRequired
};

export default connectComponent( state => ( {
	currentUser: getCurrentUser( state )
} ) )( ProfileButtonContainer );
