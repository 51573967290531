export const ERROR_NAME_NOT_ALLOWED = 'Oops! There is already an asset with this filename!';
export const ERROR_DEFAULT = 'Oops, something went wrong! Try again.';
export const SUCCESS_UPDATE_DEFAULT = 'The asset filename was successfully updated!';
export const SUCCESS_DELETE_ROUND_DEFAULT = 'The last round was successfully deleted.';
export const DELETE_ASSET_TITLE = 'Delete Asset';
export const DELETE_ASSET_CONFIRMATION = 'Are you sure you want to delete this asset for all members?';
export const DELETE_CURRENT_ROUND_CONFIRMATION = 'Are you sure you want to delete the last round of this asset for all members?';
export const DELETE_CURRENT_ROUND_TITLE = 'Delete Last Round';
export const DELETE_ITEMS_SUCCESS_DEFAULT = 'The item was successfully deleted.';
export const DELETE_ITEMS_SUCCESS_PLURAL = 'The items were successfully deleted.';
