import { Map } from 'immutable';
import { createSelector } from 'reselect';
import WorkspaceInvitation from '../entities/workspaceInvitation';
import WorkspaceMember from '../entities/workspaceMember';
import { getRawUsers } from './users';
import { getRawWorkspaceInvitations } from './workspaceInvitations';

export const getRawWorkspacesMembers = state => state.workspacesMembers;

export const getWorkspacesMembers = createSelector(
	[ getRawWorkspacesMembers ],
	workspacesMembersSerialization => workspacesMembersSerialization
		.map( workspaceMemberProperties => WorkspaceMember.fromJSON( {
			...workspaceMemberProperties,
			user_id: workspaceMemberProperties.user.id
		} ) )
);

export const getWorkspaceMembersMapWithUsers = createSelector(
	[ getWorkspacesMembers, getRawUsers ],
	( workspacesMembers, users ) => (
		workspacesMembers
			.map( member => member.withUser( users.get( member.userID ) ) )
			.filter( member => !!member.user )
	)
);

export const getWorkspacesInvitations = createSelector(
	[ getRawWorkspaceInvitations ],
	workspacesInvitationsSerialization => workspacesInvitationsSerialization
		.map( WorkspaceInvitation.fromJSON )
);

const countWorkspaceMembersAndInvitations = (
	workspaceMembersAndInvitations, workspaceID
) => workspaceMembersAndInvitations.count( workspace => workspace.workspaceID === workspaceID );

export const getTotalMembersCountByWorkspaceID = createSelector(
	[ getWorkspaceMembersMapWithUsers, getWorkspacesInvitations ],
	( workspaceMembers, workspaceInvitations ) => {
		const workspaceMembersArray = workspaceMembers.toArray();
		const workspaceInvitationsArray = workspaceInvitations.toArray();

		return workspaceMembersArray
			.concat( workspaceInvitationsArray )
			.reduce( ( totalMembers, { workspaceID } ) => {
				if ( totalMembers.has( workspaceID ) ) return totalMembers;

				return totalMembers.set(
					workspaceID,
					countWorkspaceMembersAndInvitations(
						workspaceMembersArray.concat( workspaceInvitationsArray ),
						workspaceID
					)
				);
			}, Map() );
	}
);
