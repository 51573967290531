import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import BulletedList from '../../BulletedList/BulletedList';
import './PlanCard.scss';
import Button from '../../../new_arch/components/Button/Button';
import { PRIMARY_BASE, WARNING_700 } from '../../../styles/colors';
import PlanCardPresenter from '../../../presenters/PlanSettings/PlanCardPresenter';
import ConditionalWrapper from '../../ConditionalWrapper/ConditionalWrapper';
import Tooltip from '../../UI/Tooltip/Tooltip';
import Icon from '../../../new_arch/components/Icon/Icon';

const PlanCard = ( {
	presenter,
	onUpgradeButtonClicked,
	onDowngradeButtonClicked,
	onCancelDowngradeButtonClicked,
	onNoWorkspaceButtonClicked
} ) => {
	const renderChangeStatusButton = ( text, type, upgradeButtonClicked, disabled = false ) => (
		<ConditionalWrapper
			condition={disabled}
			wrapper={Tooltip}
			wrapperProps={{
				title: 'You cannot choose this plan because you might have<br/>add-ons or storage that reach the plan limit. You can<br/>try deleting seats or free storage space.'
			}}
		>
			<Button
				type={type}
				size="large"
				onClick={() => presenter.onUpgradeButtonClicked( upgradeButtonClicked )}
				disabled={disabled}
			>
				{text}
			</Button>
		</ConditionalWrapper>
	);

	const renderNoWorkspaceButton = ( text, noWorkspaceButtonClicked ) => (
		renderChangeStatusButton( text, 'filled', noWorkspaceButtonClicked )
	);

	const renderUpgradeButton = ( text, upgradeButtonClicked ) => (
		renderChangeStatusButton( text, 'filled', upgradeButtonClicked )
	);

	const renderDowngradeButton = ( text, downgradeButtonClicked ) => (
		renderChangeStatusButton( text, 'link', downgradeButtonClicked )
	);

	const renderCancelDowngradeButton = ( text, cancelDowngradeButtonClicked ) => (
		renderChangeStatusButton( text, 'filled', cancelDowngradeButtonClicked )
	);

	const renderDowngradePendingStatus = text => (
		<div className="downgradePendingBanner">
			<Icon icon="error" size={20} color={WARNING_700} />
			<div className="downgradePendingBannerText">
				<p>{text}</p>
			</div>
		</div>
	);

	const renderStayText = text => (
		<h4>{text}</h4>
	);

	const renderReactivateStatus = ( { text, disabled }, upgradeButtonClicked ) => (
		renderChangeStatusButton( text, 'filled', upgradeButtonClicked, disabled )
	);

	const statusToShow = ( currentStatus ) => {
		const statusHash = {
			stay: renderStayText( currentStatus.text ),
			noWorkspace: renderNoWorkspaceButton( currentStatus.text, onNoWorkspaceButtonClicked ),
			upgrade: renderUpgradeButton( currentStatus.text, onUpgradeButtonClicked ),
			downgrade: renderDowngradeButton( currentStatus.text, onDowngradeButtonClicked ),
			reactivate: renderReactivateStatus( currentStatus, onUpgradeButtonClicked ),
			downgradePending: renderDowngradePendingStatus( currentStatus.text ),
			cancelDowngrade: renderCancelDowngradeButton(
				currentStatus.text,
				onCancelDowngradeButtonClicked
			)
		};

		return statusHash[ currentStatus.status ];
	};

	return (
		<div className="PlanCard">
			<div className="title">
				<h2>{presenter.title}</h2>
				<h6>{presenter.text}</h6>
			</div>
			<div className="priceLine">
				<h2>${presenter.cost}</h2><h5>{presenter.costDescription}</h5>
			</div>
			<h5 className="minimumMembersInfo">{presenter.minimumMembersInfo}</h5>
			<div className="planCardBottom">
				<BulletedList color={PRIMARY_BASE}>
					{presenter.features.map( feature => (
						feature.infoText
							? ( <p>{feature.text}</p> )
							: <p>{feature}</p>
					) )}
				</BulletedList>
				<div className="planStatus">
					{ statusToShow( presenter.currentStatusToShow ) }
				</div>
			</div>
		</div>
	);
};

PlanCard.propTypes = {
	presenter: PropTypes.instanceOf( PlanCardPresenter ).isRequired,
	onUpgradeButtonClicked: PropTypes.func.isRequired,
	onDowngradeButtonClicked: PropTypes.func.isRequired,
	onCancelDowngradeButtonClicked: PropTypes.func.isRequired,
	onNoWorkspaceButtonClicked: PropTypes.func.isRequired
};

PlanCard.defaultProps = {
};

export default observer( PlanCard );
