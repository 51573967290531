import { ASSETS_URL, COMMENTS_URL, PROJECTS_URL } from '../config/urls';
import {
	FETCH_ASSET_ROUND_COMMENTS,
	CREATE_COMMENT_FOR_ASSET,
	RESET_CREATE_COMMENT_FOR_ASSET,
	SET_CURRENT_ACTIVE_MARKUP_COMMENT,
	CLEAR_CURRENT_ACTIVE_MARKUP_COMMENT,
	DELETE_COMMENT,
	UPDATE_MARKUP_COMMENT,
	RESET_UPDATE_MARKUP_COMMENT,
	START_WRITING_COMMENT,
	STOP_WRITING_COMMENT,
	MARK_COMMENT_AS_COMPLETED,
	UNMARK_COMMENT_AS_COMPLETED,
	UPDATE_COMMENTS_LAST_SEEN,
	SET_HOVERED_COMMENT,
	CLEAR_HOVERED_COMMENT,
	FETCH_COMMENTS_LAST_SEEN_BY_USERS,
	FETCH_ASSET_COMMENTS
} from './types';
import { getCommentOrReply, getAssetForCommentID, getComment } from '../selectors/comments';
import { getShapesFromComment } from '../selectors/markups';
import {
	trackCommentCreation, trackCommentDeletion, trackCommentEdit, trackCommentApproved
} from '../services/tracker/trackers/comments';
import { getAsset } from '../selectors/assets';
import { getCurrentUser } from '../selectors/currentUser';
import CommentFactory from '../factories/CommentFactory';
import QueryClientUpdater from '../new_arch/services/QueryClientUpdater';

export const fetchCommentsForAsset = assetID => ( dispatch, _, api ) => dispatch( {
	type: FETCH_ASSET_COMMENTS,
	promise: api
		.get(
			`${ASSETS_URL}/${assetID}/comments`
		)
		.then( data => data
			.response
			.map( comment => ( { ...comment, asset_id: assetID } ) )
		)
		.then( comments => ( {
			assetID,
			comments
		} ) )
} );

export const fetchCommentsForAssetRound = (
	assetID, roundID
) => (
	dispatch, _, api
) => dispatch( {
	type: FETCH_ASSET_ROUND_COMMENTS,
	promise: api
		.get(
			`${ASSETS_URL}/${assetID}/versions/${roundID}/comments`
		)
		.then( data => data
			.response
			.map( comment => ( { ...comment, asset_id: assetID, version_id: roundID } ) )
		)
		.then( comments => ( {
			assetID,
			roundID,
			comments
		} ) )
} );

const createCommentForAssetWithParameters = (
	assetID, roundID, {
		text, shapes = undefined, parentID = undefined, files, globalComment
	}
) => (
	dispatch, getState, api
) => {
	const parameters = {
		asset_id: assetID,
		text,
		parent_id: parentID,
		files: files.map( file => ( file.isNew ? file.file : file.id ) ),
		global_comment: !!globalComment
	};

	if ( shapes ) {
		parameters.markup = shapes.map( shape => shape.toJSON() );
	}

	const asset = getAsset( getState(), { assetID } );
	trackCommentCreation( { asset, shapes, files, text, isReply: !!parentID } );

	const author = getCurrentUser( getState() );

	return dispatch( {
		type: CREATE_COMMENT_FOR_ASSET,
		promise: api
			.post( COMMENTS_URL, parameters )
			.then( ( data ) => {
				QueryClientUpdater.commentCreated( { forAsset: asset } );

				return {
					...data.response,
					asset_id: assetID,
					version_id: roundID
				};
			} ),
		meta: {
			tempComment: CommentFactory.propertiesFrom( {
				assetID, roundID, parentID, text, shapes, files, globalComment, author
			} )
		}
	} );
};

export const createCommentForAsset = (
	assetID, roundID, text, shapes = undefined, files = []
) => createCommentForAssetWithParameters( assetID, roundID, { text, shapes, files } );

export const createCommentReplyForAssetAndComment = (
	assetID, roundID, parentID, text, files = []
) => createCommentForAssetWithParameters( assetID, roundID, { text, parentID, files } );

export const createGlobalComment = (
	assetID, roundID, text, shapes, files = []
) => createCommentForAssetWithParameters(
	assetID, roundID,
	{
		text, shapes, files, globalComment: true
	}
);

export const updateMarkupComment = (
	commentID, roundID, text, shapes = undefined, files = []
) => ( dispatch, getState, api ) => {
	trackCommentEdit();

	const parameters = { text, files: files.map( file => ( file.isNew ? file.file : file.id ) ) };
	if ( shapes ) {
		parameters.markup = shapes.map( shape => shape.toJSON() );
	}

	return dispatch( {
		type: UPDATE_MARKUP_COMMENT,
		meta: {
			asset_id: getCommentOrReply( getState(), commentID ).assetID
		},
		promise: api
			.patch( `${COMMENTS_URL}/${commentID}`, parameters )
			.then(
				response => ( { ...response.response, version_id: roundID } )
			)
	} );
};

const toggleCommentCompletionStatus = (
	commentID, completed
) => (
	dispatch, getState, api
) => {
	const comment = getComment( getState(), commentID );
	const asset = getAsset( getState(), { assetID: comment?.assetID } );
	const originalCompletedAt = comment
		? comment.completedAt
		: null;

	if ( comment && completed ) trackCommentApproved();

	return dispatch( {
		type: (
			completed
				? MARK_COMMENT_AS_COMPLETED
				: UNMARK_COMMENT_AS_COMPLETED
		),
		promise: api
			.post(
				completed
					? `${COMMENTS_URL}/${commentID}/mark_as_completed`
					: `${COMMENTS_URL}/${commentID}/unmark_as_completed`
			)
			.then(
				( response ) => {
					if ( asset ) {
						QueryClientUpdater.commentCompletionToggled( { forAsset: asset } );
					}

					return response.response;
				}
			),
		meta: {
			commentID,
			originalCompletedAt,
			assetID: comment && comment.assetID
		}
	} );
};

export const markCommentAsCompleted = commentID => toggleCommentCompletionStatus(
	commentID,
	true
);

export const unmarkCommentAsCompleted = commentID => toggleCommentCompletionStatus(
	commentID,
	false
);

export const deleteComment = ( commentID, commentParentID ) => ( dispatch, getState, api ) => {
	const state = getState();
	const asset = getAssetForCommentID( state, { commentID } );
	const comment = getCommentOrReply( state, commentID );
	trackCommentDeletion( { asset, comment } );

	return dispatch( {
		type: DELETE_COMMENT,
		promise: api
			.delete( `${COMMENTS_URL}/${commentID}` )
			.then( () => commentID ),
		meta: {
			parentID: commentParentID,
			assetID: asset && asset.id,
			wasMarkedAsCompleted: comment.wasMarkedAsCompleted
		}
	} );
};

export const resetCreateCommentForAssetRequest = () => ( {
	type: RESET_CREATE_COMMENT_FOR_ASSET
} );

export const resetUpdateCommentWithAssetRequest = () => ( {
	type: RESET_UPDATE_MARKUP_COMMENT
} );

export const setCurrentActiveMarkupComment = (
	commentID, isEditing = false, sourceIsComment = true
) => ( dispatch, getState ) => {
	const comment = getCommentOrReply( getState(), commentID );
	return dispatch( {
		type: SET_CURRENT_ACTIVE_MARKUP_COMMENT,
		payload: {
			commentID,
			isEditing,
			sourceIsComment,
			shapes: getShapesFromComment( comment )
		}
	} );
};

export const clearCurrentActiveMarkupComment = () => ( {
	type: CLEAR_CURRENT_ACTIVE_MARKUP_COMMENT
} );

export const startWritingComment = () => ( {
	type: START_WRITING_COMMENT
} );

export const stopWritingComment = () => ( {
	type: STOP_WRITING_COMMENT
} );

export const updateCommentsLastSeen = assetID => ( dispatch, _, api ) => dispatch( {
	type: UPDATE_COMMENTS_LAST_SEEN,
	promise: api
		.post( `${ASSETS_URL}/${assetID}/comments/last_seen` )
} );

export const setHoveredCommentID = commentID => ( {
	type: SET_HOVERED_COMMENT,
	payload: { commentID }
} );

export const clearHoveredCommentID = commentID => ( {
	type: CLEAR_HOVERED_COMMENT,
	payload: { commentID }
} );

export const fetchCommentsLastSeenByUsers = (
	assetID, projectID
) => ( dispatch, _, api ) => dispatch( {
	type: FETCH_COMMENTS_LAST_SEEN_BY_USERS,
	promise: api.get( `${PROJECTS_URL}/${projectID}/assets/${assetID}/comments/seen_by_users` )
		.then( response => response.response ),
	meta: { assetID }
} );
