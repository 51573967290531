import PropTypes from 'prop-types';

import './Nav.scss';

const Nav = ( { children } ) => (
	<nav className="Nav">
		{children}
	</nav>
);

Nav.propTypes = {
	children: PropTypes.node
};

Nav.defaultProps = {
	children: undefined
};

export default Nav;
