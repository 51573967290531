import { initializeDownload } from '../lib/fileUtils';

export class AssetsDownload {
	static handle( downloadJson, assetDownloadService ) {
		const download = AssetsDownload.fromJSON( downloadJson );
		download.handleWith( assetDownloadService );

		return download;
	}

	static fromJSON( json ) {
		return new AssetsDownload( {
			id: json.download_id,
			type: json.download_type,
			url: json.download_url
		} );
	}

	constructor( { id, type, url } ) {
		this.id = id;
		this.type = type;
		this.url = url;
	}

	handleWith( assetDownloadService ) {
		if ( this.isDirectDownload ) {
			initializeDownload( this.url );
		} else {
			assetDownloadService.registerAssetsDownload( this.id );
		}
	}

	get isDirectDownload() {
		return this.type === 'sync';
	}
}
