import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import ProgressCard from '../ProgressCard/ProgressCard';

import Icon from '../../../new_arch/components/Icon/Icon';
import Button from '../../../new_arch/components/Button/Button';
import { WHITE } from '../../../styles/colors';
import AddOnsModal from '../../../modals/AddOnsModal/AddOnsModal';
import AddOnModalPresenter from '../../../presenters/AddOnsModal/AddOnsModalPresenter';

import './AddOnUsage.scss';

const AddOnUsage = ( {
	showAddOnsButton,
	onAddOnsButtonClick,
	addOnsButtonText,
	addOnsModalIsOpen,
	addOnsModalPresenter,
	icon,
	valueCount,
	maxValue,
	greyOut,
	title,
	unit,
	button,
	hasAddOns,
	maxedOutMultiplier,
	bytesFormat,
	disabledButton
} ) => {
	const renderAddOnsButton = () => (
		showAddOnsButton
			? (
				<div className="add-ons-button">
					<Button onClick={onAddOnsButtonClick} disabled={disabledButton}>
						{addOnsButtonText}
					</Button>
				</div>
			) : null
	);

	return (
		<div className="AddOnUsage">
			{addOnsModalIsOpen && (
				<AddOnsModal
					presenter={addOnsModalPresenter}
				/>
			)}
			<ProgressCard
				icon={<Icon size={25} icon={icon} color={WHITE} />}
				valueCount={valueCount}
				maxValue={maxValue}
				greyOut={greyOut}
				title={title}
				unit={unit}
				button={button || renderAddOnsButton()}
				hasAddOns={hasAddOns}
				maxedOutMultiplier={maxedOutMultiplier}
				bytesFormat={bytesFormat}
			/>
		</div>
	);
};

AddOnUsage.propTypes = {
	showAddOnsButton: PropTypes.bool.isRequired,
	onAddOnsButtonClick: PropTypes.func.isRequired,
	addOnsButtonText: PropTypes.string.isRequired,
	addOnsModalIsOpen: PropTypes.bool.isRequired,
	addOnsModalPresenter: PropTypes.instanceOf( AddOnModalPresenter ).isRequired,
	icon: PropTypes.string.isRequired,
	valueCount: PropTypes.number.isRequired,
	maxValue: PropTypes.number.isRequired,
	greyOut: PropTypes.bool.isRequired,
	title: PropTypes.string.isRequired,
	unit: PropTypes.string.isRequired,
	button: PropTypes.node,
	hasAddOns: PropTypes.bool,
	maxedOutMultiplier: PropTypes.number,
	bytesFormat: PropTypes.bool,
	disabledButton: PropTypes.bool
};

AddOnUsage.defaultProps = {
	button: null,
	hasAddOns: false,
	maxedOutMultiplier: undefined,
	bytesFormat: undefined,
	disabledButton: false
};

export default observer( AddOnUsage );
