import { ownerRolePermissions, adminRolePermissions, collaboratorRolePermissions }
	from './workspaceRolePermissions';

const OWNER_LEVEL = 1;
const ADMIN_LEVEL = 2;
const COLLABORATOR_LEVEL = 3;

export default class WorkspaceRole {
	constructor( id, name, level, permissions ) {
		this.id = id;
		this.name = name;
		this.level = level;
		this.permissions = permissions;
	}

	get isOwner() { return this.id === WorkspaceRole.owner().id; }

	get usersPermissions() { return this.permissions.usersPermissions; }

	static owner() { return new WorkspaceRole( 'WorkspaceOwner', 'Owner', OWNER_LEVEL, ownerRolePermissions ); }

	static admin() { return new WorkspaceRole( 'WorkspaceAdmin', 'Member', ADMIN_LEVEL, adminRolePermissions ); }

	static collaborator() { return new WorkspaceRole( 'WorkspaceCollaborator', 'Collaborator', COLLABORATOR_LEVEL, collaboratorRolePermissions ); }

	isAtLeast( level ) {
		return this.level <= level;
	}

	canEditWorkspaceMemberRole( member ) {
		return !member.isOwner && this.usersPermissions.editRole;
	}

	canDeleteWorkspaceMember( member ) {
		return !member.isOwner
		&& this.usersPermissions.destroy
		&& ( this.hasLowerLevel( member.role ) || this.hasSameLevel( member.role ) );
	}

	canDeleteWorkspaceInvite( invite ) {
		return this.usersPermissions.destroy
		&& ( this.hasLowerLevel( invite.role ) || this.hasSameLevel( invite.role ) );
	}

	hasLowerLevel( role ) {
		return role && this.level < role.level;
	}

	hasSameLevel( role ) {
		return role && this.level === role.level;
	}
}

// The roles here are ordered by hierarchy
export const AVAILABLE_ROLES = [
	WorkspaceRole.owner(),
	WorkspaceRole.admin(),
	WorkspaceRole.collaborator()
];

export const VALID_ASSIGNABLE_ROLES = [
	WorkspaceRole.admin(),
	WorkspaceRole.collaborator()
];

export const AVAILABLE_ROLES_BY_TYPE = Object.freeze( {
	[ WorkspaceRole.owner().id ]: WorkspaceRole.owner(),
	[ WorkspaceRole.admin().id ]: WorkspaceRole.admin(),
	[ WorkspaceRole.collaborator().id ]: WorkspaceRole.collaborator()
} );

export const AVAILABLE_ROLES_NAMES_BY_KEY = Object.freeze( {
	[ WorkspaceRole.owner().id ]: 'Owner',
	[ WorkspaceRole.admin().id ]: WorkspaceRole.admin().name,
	[ WorkspaceRole.collaborator().id ]: WorkspaceRole.collaborator().name
} );
