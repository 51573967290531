import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { getWorkspaceLabelGroups } from '../../selectors/labelGroups';
import { connectComponent } from '../../lib/connectComponent';
import WorkspaceLabelGroups from '../../components/WorkspaceLabelGroups/WorkspaceLabelGroups';
import WorkspaceSystem from '../../systems/WorkspaceSystem';
import PopupSystem from '../../systems/PopupSystem';
import SnackbarSystem from '../../systems/SnackbarSystem';
import AsyncActionPresenterFactory from '../../factories/AsyncActionPresenterFactory';
import LabelGroup from '../../entities/labelGroup';

export const WorkspaceLabelGroupsContainer = ( {
	workspaceID, groups, workspaceSystem, popupSystem, snackbarSystem
} ) => {
	useEffect( () => {
		workspaceSystem.getLabelGroups( workspaceID );
	}, [ workspaceID ] );

	const onDeleteGroupClick = ( labelGroup ) => {
		AsyncActionPresenterFactory.forDeleteLabelGroup( {
			workspaceID, labelGroup, workspaceSystem, popupSystem, snackbarSystem
		} ).trigger();
	}

	return (
		<WorkspaceLabelGroups
			workspaceID={workspaceID}
			groups={groups}
			onDeleteGroupClick={onDeleteGroupClick}
		/>
	);
}

WorkspaceLabelGroupsContainer.propTypes = {
	workspaceID: PropTypes.number,
	groups: PropTypes.arrayOf( LabelGroup ),
	workspaceSystem: PropTypes.instanceOf( WorkspaceSystem ).isRequired,
	popupSystem: PropTypes.instanceOf( PopupSystem ).isRequired,
	snackbarSystem: PropTypes.instanceOf( SnackbarSystem ).isRequired
};

WorkspaceLabelGroupsContainer.defaultProps = {
	workspaceID: undefined,
	groups: undefined
};

export default connectComponent( ( state, props ) => ( {
	groups: getWorkspaceLabelGroups( state, props )
} ) )( WorkspaceLabelGroupsContainer );
