import { computed, makeObservable } from 'mobx';
import AddOn from '../../entities/addOn';
import UsagePresenter from '../UsagePresenter/UsagePresenter';

export default class StorageUsagePresenter extends UsagePresenter {
	constructor( {
		popupSystem, addOnSystem, snackbarSystem, workspace, addOns, onPaidFeatureModalAcceptClick
	} ) {
		super( {
			popupSystem, addOnSystem, snackbarSystem, workspace, addOns, onPaidFeatureModalAcceptClick
		} );

		makeObservable( this, {
			storageBarValueCount: computed,
			storageBarMaxValue: computed,
			hasStorageAddOns: computed,
			storageAddOnTooltipText: computed,
			addStorageButtonText: computed,
			selectedAddOnType: computed,
			storageUnitText: computed,
			_extraStorage: computed
		} );
	}

	get storageBarValueCount() {
		return this.workspace.storageUsedInBytes;
	}

	get storageBarMaxValue() {
		return this.workspace.includedStorage;
	}

	get hasStorageAddOns() {
		return this.workspace.hasStorageAddOns;
	}

	get storageAddOnTooltipText() {
		return this.hasStorageAddOns
			? `You have ${this._extraStorage.formatBytes()} in storage add-ons`
			: undefined;
	}

	get addStorageButtonText() {
		return this.hasStorageAddOns ? 'Manage Storage' : 'Add Storage';
	}

	get storageUnitText() {
		return 'available';
	}

	get selectedAddOnType() {
		return AddOn.storageType;
	}

	get _extraStorage() {
		return this.workspace.extraStorage;
	}
}
