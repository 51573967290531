import PropTypes from 'prop-types';
import { Line } from 'rc-progress';
import className from 'classnames';
import '../AssetUploaderBox/AssetUploaderBox.scss';
import { ITEM_UPLOAD_STATUS } from '../../../services/DirectFilesUploaderCoordinator';
import {
	SUCCESS, PRIMARY_BASE, MIDDLE_GREY, ERROR
} from '../../../styles/colors';

import IconButton from '../../UI/IconButton/IconButton';
import ItemUpload from '../../../entities/itemUpload';
import Icon from '../../../new_arch/components/Icon/Icon';

export const WAITING_KEY = 'waiting';
export const UPLOADING_KEY = 'uploading';

const progressBarStrokeColor = {
	[ ITEM_UPLOAD_STATUS.WAITING ]: MIDDLE_GREY,
	[ ITEM_UPLOAD_STATUS.UPLOADING ]: PRIMARY_BASE,
	[ ITEM_UPLOAD_STATUS.ABORTED ]: MIDDLE_GREY,
	[ ITEM_UPLOAD_STATUS.FAILED ]: ERROR,
	[ ITEM_UPLOAD_STATUS.CREATING_ASSET ]: PRIMARY_BASE,
	[ ITEM_UPLOAD_STATUS.SUCCEEDED ]: SUCCESS
};

const actionButton = ( uploadStatus, onClickAction ) => {
	const buttonActionsAndIcons = {
		[ ITEM_UPLOAD_STATUS.WAITING ]: {
			key: WAITING_KEY, icon: 'clear', action: onClickAction
		},
		[ ITEM_UPLOAD_STATUS.UPLOADING ]: {
			key: UPLOADING_KEY, icon: 'clear', action: onClickAction
		},
		[ ITEM_UPLOAD_STATUS.ABORTED ]: { key: 'aborted', icon: 'remove_circle' },
		[ ITEM_UPLOAD_STATUS.FAILED ]: { key: 'failed', icon: 'error', color: ERROR },
		[ ITEM_UPLOAD_STATUS.CREATING_ASSET ]: { key: 'creating', icon: 'access_time' },
		[ ITEM_UPLOAD_STATUS.SUCCEEDED ]: { key: 'succeeded', icon: 'check_circle', color: SUCCESS }
	};

	const {
		key, icon, action, color
	} = buttonActionsAndIcons[ uploadStatus ];

	return action
		? <IconButton keyID={key} icon={icon} onClick={action} buttonClassName={key} />
		: <Icon key={key} icon={icon} color={color} />;
};

const uploadTitle = ( { name, isFolderUpload } ) => {
	if ( !isFolderUpload ) return <p>{name}</p>;

	return (
		<div className="folder-upload-title">
			<Icon icon="folder" />
			<p>{name}</p>
		</div>
	);
}

const uploadFooter = ( upload ) => {
	const {
		status, errorMessage
	} = upload;

	if ( status === ITEM_UPLOAD_STATUS.FAILED ) {
		return <p className="error-message">{errorMessage}</p>;
	}

	return '';
}

const ItemUploadBox = ( { upload, onUploadCancel } ) => {
	const progressText = {
		[ ITEM_UPLOAD_STATUS.WAITING ]: 'Upload Pending',
		[ ITEM_UPLOAD_STATUS.UPLOADING ]: upload.progressText,
		[ ITEM_UPLOAD_STATUS.SUCCEEDED ]: 'Complete',
		[ ITEM_UPLOAD_STATUS.FAILED ]: 'Error Uploading',
		[ ITEM_UPLOAD_STATUS.ABORTED ]: 'Canceled'
	};

	return (
		<li
			className={className(
				'aligned',
				{
					completed: upload.isCompleted,
					canceled: upload.wasCanceled,
					failed: upload.hasFailed,
					uploading: upload.isUploading,
					waiting: upload.isWaiting
				}
			)}
		>
			<div className="action">
				{ actionButton( upload.status, onUploadCancel ) }
			</div>
			<div className="description">
				<div className="details">
					{uploadTitle( upload )}
					<span className="size">{progressText[ upload.status ]}</span>
				</div>
				<div className="progress">
					<Line
						percent={upload.hasFailed ? 100 : upload.progress}
						strokeWidth="2"
						trailWidth="2"
						trailColor={MIDDLE_GREY}
						strokeColor={progressBarStrokeColor[ upload.status ]}
					/>
				</div>
				<div className="footer">
					{uploadFooter( upload )}
				</div>
			</div>
		</li>
	);
};

ItemUploadBox.propTypes = {
	upload: PropTypes.instanceOf( ItemUpload ).isRequired,
	onUploadCancel: PropTypes.func
};

ItemUploadBox.defaultProps = {
	onUploadCancel: () => {}
};

export default ItemUploadBox;
