import Lottie from 'react-lottie';
import * as animationData from '../../assets/animations/auth-loader.json';
import { ReactComponent as Logo } from '../../assets/img/logo.svg';

import './FullScreenLoader.scss';

const FullScreenLoader = () => (
	<div className="FullScreenLoader">
		<div className="loader">
			<Lottie
				options={{
					loop: true,
					autoplay: true,
					animationData
				}}
				height="auto"
				width={63}
			/>
			<Logo className="logo" />
		</div>
	</div>
);

export default FullScreenLoader;
