import PropTypes from 'prop-types';
import historyPropType from '../../../types/history';

import Nav from '../Nav/Nav';
import NavigationBreadcrumbsContainer from '../../../containers/NavigationBreadcrumbsContainer/NavigationBreadcrumbsContainer';
import ProfileWithDropdownActionsContainer
	from '../../../containers/ProfileWithDropdownActionsContainer/ProfileWithDropdownActionsContainer';
import NavBarActions
	from '../NavBarActions/NavBarActions';
import { isMobile } from '../../../lib/isMobile';
import { isHomePage } from '../../../lib/urlUtils';
import { useIsGuest } from '../../../hooks/useIsGuest';
import NavigationLogo from '../NavigationLogo/NavigationLogo';

const SiteNav = ( {
	projectID, folderID, history
} ) => {
	const isGuest = useIsGuest();

	const showNav = !isMobile || isHomePage( { location: history.location } );
	const showLogo = isGuest;
	const showBreadcrumbs = !isGuest || !!folderID;

	return (
		showNav
			? (
				<Nav>
					{showLogo && <NavigationLogo isClickeable={false} />}
					{showBreadcrumbs && (
						<NavigationBreadcrumbsContainer
							projectID={projectID}
							folderID={folderID}
							hideHomeIcon={isGuest}
							hideFirstArrow={isGuest}
						/>
					)}

					<div className="right-side hide-on-mobile">
						<NavBarActions
							showingProjectID={projectID}
							showingFolderID={folderID}
						/>
						<ProfileWithDropdownActionsContainer history={history} />
					</div>

				</Nav>
			)
			: null
	);
};

SiteNav.propTypes = {
	projectID: PropTypes.number,
	folderID: PropTypes.number,
	history: historyPropType.isRequired
};

SiteNav.defaultProps = {
	projectID: null,
	folderID: null
};

export default SiteNav;
