/* eslint-disable max-len */
import { makeAutoObservable } from 'mobx';
import AddOnModalPresenterTypeFactory from '../../factories/AddOnModalPresenterTypeFactory';

export default class AddOnModalPresenter {
	constructor( {
		popupSystem, addOnSystem, snackbarSystem, workspace, addOns, selectedAddOnType, history
	} ) {
		this.popupSystem = popupSystem;
		this.addOnSystem = addOnSystem;
		this.snackbarSystem = snackbarSystem;
		this.workspace = workspace;
		this.addOns = addOns;
		this.selectedAddOnType = selectedAddOnType;
		this._initialAddOnType = selectedAddOnType;
		this.history = history;

		this.isOpen = false;

		makeAutoObservable( this );
	}

	get title() {
		return this._currentAddOnPresenterType.title;
	}

	get text() {
		return this._currentAddOnPresenterType.text;
	}

	get loading() {
		return !this.addOn;
	}

	get cost() {
		return this.addOnPrice.cost;
	}

	get costDescription() {
		return `/per ${this.workspace.hasYearlySubscriptionInterval ? 'year' : 'month'}`;
	}

	get addOnQuantity() {
		return this._currentAddOnPresenterType.addOnQuantity;
	}

	get hasAddOns() {
		return this.addOnQuantity > 0;
	}

	get addOnPrice() {
		return this.workspace.hasYearlySubscriptionInterval
			? this.addOn.annualPrice : this.addOn.monthlyPrice;
	}

	get buttonText() {
		return this._currentAddOnPresenterType.buttonText;
	}

	get removeButtonText() {
		return this._currentAddOnPresenterType.removeButtonText;
	}

	get quantityText() {
		return this.hasAddOns ? this._currentAddOnPresenterType.quantityText : undefined;
	}

	get showInformationTooltip() {
		return this.workspace.hasYearlySubscriptionInterval;
	}

	get infoText() {
		return this.showInformationTooltip
			? 'Payment is tied to your plan’s billing period, first one is proportional to your time left and then it will be renewed together with your plan.'
			: undefined;
	}

	get addOn() {
		return this.addOns.find( addOn => addOn.type === this.selectedAddOnType );
	}

	get addOnIncludedAmount() {
		return this.addOn.includedAmount;
	}

	get addOnTypeText() {
		return this.addOn.type.replace( 'AddOn', '' ).toLowerCase();
	}

	onMount = () => {
		this.addOnSystem.fetchPlanAddOns( { pricingPlan: this.workspace.pricingPlan } );
	}

	open = () => {
		this.isOpen = true;
	}

	close = () => {
		this.isOpen = false;
		this.selectedAddOnType = this._initialAddOnType;
	}

	addOnTypeWasSwitched = () => {
		this.selectedAddOnType = this.addOns.find( addOn => addOn.type !== this.selectedAddOnType ).type;
	}

	buyButtonWasClicked = () => {
		this._currentAddOnPresenterType.buyButtonWasClicked();
	};

	removeButtonWasClicked = () => {
		this._currentAddOnPresenterType.removeButtonWasClicked();
	};

	get _currentAddOnPresenterType() {
		return AddOnModalPresenterTypeFactory.for( this );
	}
}
