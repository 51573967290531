import { List, Map } from 'immutable';
import {
	START_DRAWING_MARKUP,
	ADD_SHAPE_TO_CURRENT_DRAWING_MARKUP,
	REMOVE_LAST_SHAPE_FROM_CURRENT_DRAWING_MARKUP,
	STOP_DRAWING_MARKUP,
	SET_MARKUP_EDITING_TOOL,
	SET_MARKUP_EDITING_COLOR,
	SET_MARKUP_EDITING_SIZE,
	SET_CURRENT_ACTIVE_MARKUP_COMMENT,
	CLEAR_CURRENT_ACTIVE_MARKUP_COMMENT,
	DELETE_COMMENT,
	RESET_MARKUP_EDITING_SETTINGS,
	SET_MARKUP_EDITOR_ACTIVE_STATUS,
	RESET_MARKUP_EDITOR_ACTIVE_STATUS
} from '../actions/types';
import { BRUSH_TOOL, SELECTION_TOOL } from '../components/assets/AssetEditingToolbar/AssetEditingToolbar';

export const markupEditorCurrentDrawingShapes = ( state = null, action ) => {
	switch ( action.type ) {
	case START_DRAWING_MARKUP:
		if ( state ) { return state; }
		return new List();
	case ADD_SHAPE_TO_CURRENT_DRAWING_MARKUP:
		if ( !state ) { return null; }
		return state.push( action.payload );
	case REMOVE_LAST_SHAPE_FROM_CURRENT_DRAWING_MARKUP:
		return !state || state.size <= 1 ? null : state.pop();
	case CLEAR_CURRENT_ACTIVE_MARKUP_COMMENT:
	case DELETE_COMMENT:
	case STOP_DRAWING_MARKUP:
		return null;
	default:
		return state;
	}
};

const defaultEditingSettingsState = new Map( {
	tool: BRUSH_TOOL,
	color: '#2B9324',
	size: 3
} );

export const markupEditorEditingSettings = ( state = defaultEditingSettingsState, action ) => {
	switch ( action.type ) {
	case SET_CURRENT_ACTIVE_MARKUP_COMMENT:
		return action.payload.isEditing ? state.set( 'tool', SELECTION_TOOL ) : state;
	case RESET_MARKUP_EDITING_SETTINGS:
		return defaultEditingSettingsState;
	case SET_MARKUP_EDITING_TOOL:
		return state.set( 'tool', action.payload );
	case SET_MARKUP_EDITING_COLOR:
		return state.set( 'color', action.payload );
	case SET_MARKUP_EDITING_SIZE:
		return state.set( 'size', action.payload );
	default:
		return state;
	}
};

export const markupEditorActiveStatus = ( state = true, action ) => {
	switch ( action.type ) {
	case SET_MARKUP_EDITOR_ACTIVE_STATUS:
		return true;
	case RESET_MARKUP_EDITOR_ACTIVE_STATUS:
		return false;
	default:
		return state;
	}
};
