import { sharedTracker } from '../Tracker';
import events from '../events';

export const trackUserLoggedIn = () => sharedTracker
	.trackAccessAction( { action: events.access.LOGGED_IN } );

export const trackUserLoggedOut = () => sharedTracker
	.trackAccessAction( { action: events.access.LOGGED_OUT } );

export const trackUserSignedUp = ( { isInvitationSignup, isGuestSignup } ) => sharedTracker
	.trackAccessAction( {
		action: events.access.SIGNED_UP,
		is_invitation_signup: isInvitationSignup,
		is_guest_signup: isGuestSignup
	} );
