import PropTypes from 'prop-types';
import ConditionalWrapper from '../ConditionalWrapper/ConditionalWrapper';
import Button from '../../new_arch/components/Button/Button';
import { simpleTextTruncate } from '../../lib/textTruncate';
import BreadcrumbLevel from '../../structures/breadcrumbLevel';
import BreadcrumbDividingArrow from '../BreadcrumbDividingArrow/BreadcrumbDividingArrow';

const Breadcrumb = ( { breadcrumb, bold, withDividingArrow } ) => (
	<>
		<BreadcrumbDividingArrow visible={withDividingArrow} />
		<ConditionalWrapper
			condition={breadcrumb.isClickable}
			wrapper={Button}
			wrapperProps={{
				className: 'link',
				type: 'text',
				onClick: breadcrumb.onClick,
				buttonType: 'button'
			}}
		>
			<p className={!bold && 'regular'} style={{ cursor: 'inherit' }}>
				{simpleTextTruncate( breadcrumb.name, 75, 50 )}
			</p>
		</ConditionalWrapper>
	</>
);

Breadcrumb.propTypes = {
	breadcrumb: PropTypes.instanceOf( BreadcrumbLevel ).isRequired,
	bold: PropTypes.bool,
	withDividingArrow: PropTypes.bool
};

Breadcrumb.defaultProps = {
	bold: false,
	withDividingArrow: true
};

export default Breadcrumb;
