type LabelColor = { name: string, baseColor: string, hoverColor: string }

export const LABEL_COLORS : Array<LabelColor> = [
	{ name: 'clay', baseColor: '#FED2B2', hoverColor: '#FCBA8A' },
	{ name: 'coral', baseColor: '#F5C1CA', hoverColor: '#F29FAE' },
	{ name: 'yellow', baseColor: '#F9F2B4', hoverColor: '#F8ED91' },
	{ name: 'aqua', baseColor: '#C3F4F2', hoverColor: '#9CEFEA' },
	{ name: 'blue', baseColor: '#C0D9FF', hoverColor: '#9DC4FF' },
	{ name: 'lilac', baseColor: '#DFD0FF', hoverColor: '#C7AEFD' },
	{ name: 'apple', baseColor: '#D9EEB7', hoverColor: '#CBE79F' },
	{ name: 'pink', baseColor: '#F5CDF1', hoverColor: '#F1B6EB' },
	{ name: 'stone', baseColor: '#E8F0FC', hoverColor: '#BFCEE3' },
	{ name: 'silver', baseColor: '#E9E9E9', hoverColor: '#D9D9D9' }
];

export const DEFAULT_LABEL_COLOR : LabelColor = LABEL_COLORS[ 0 ];
