import ColorView from '../structures/colorView';

export default class Label {
	constructor( {
		id, name, labellableID, labellableType, baseColor, hoverColor, order
	} ) {
		this.id = id;
		this.name = name;
		this.baseColor = baseColor;
		this.hoverColor = hoverColor;
		this.labellableID = labellableID;
		this.labellableType = labellableType;
		this.order = order;
	}

	static get type() {
		return { project: 'Project', workspace: 'Workspace' };
	}

	static fromJSON( properties ) {
		return new Label( {
			id: properties.id,
			name: properties.name,
			labellableID: properties.labellable_id,
			labellableType: properties.labellable_type,
			baseColor: properties.base_color,
			hoverColor: properties.hover_color,
			order: properties.order
		} );
	}

	get color() {
		return new ColorView( { base: this.baseColor, hovered: this.hoverColor } );
	}

	toJSON = () => ( {
		id: this.id,
		name: this.name,
		labellable_id: this.labellableID,
		labellable_type: this.labellableType,
		base_color: this.baseColor,
		hover_color: this.hoverColor,
		order: this.order
	} );
}
