import { createSelector } from 'reselect';
import { firstBy } from 'thenby';
import { compareAsc } from '../lib/comparators';

import { getCurrentUserID } from './currentUser';
import Workspace from '../entities/workspace';
import WorkspaceInvoice from '../entities/workspaceInvoice';
import CreditCard from '../entities/creditCard';
import WorkspaceProject from '../entities/workspaceProject';
import { getSubscriptions } from './subscriptions';
import WorkspaceRole from '../entities/workspaceRole';
import { getWorkspaceMembersMapWithUsers, getWorkspacesInvitations, getTotalMembersCountByWorkspaceID } from './workspaceMembers';
import SortOption from '../entities/sortOption';
import { getLabels, sortLabels } from './labels';

const getWorkspaceMemberID = ( _, props ) => props.memberID;
export const getRawWorkspaces = state => state.workspaces;
export const getWorkspaceID = ( _, props ) => props.workspaceID;
export const getRawProjects = state => state.projects;
export const getRawWorkspaceProjects = state => state.workspaceProjects;

export const getWorkspaces = createSelector(
	[ getRawWorkspaces, getSubscriptions, getTotalMembersCountByWorkspaceID, getLabels ],
	(
		workspacesSerialization,
		subscriptions,
		totalMembersCountByWorkspaceID,
		labels
	) => workspacesSerialization.map( serialization => Workspace.fromJSON(
		serialization,
		subscriptions.get( serialization.subscription ),
		totalMembersCountByWorkspaceID.get( serialization.id ),
		sortLabels( serialization.labels?.map( id => labels?.get( id ) ) )
	) )
);

export const getWorkspace = createSelector(
	[ getWorkspaces, getWorkspaceID ],
	( workspaces, workspaceID ) => workspaces.get( workspaceID )
);

export const getCurrentUserOwnWorkspace = createSelector(
	[ getWorkspaces, getCurrentUserID ],
	( workspaces, currentUserID ) => {
		const userWorkspace = workspaces.find( workspace => workspace.userIsOwner( currentUserID ) );
		return userWorkspace;
	}
);

export const getCurrentUserOwnsCurrentWorkspace = createSelector(
	[ getWorkspace, getCurrentUserID ],
	( workspace, currentUserID ) => !!workspace && workspace.userIsOwner( currentUserID )
);

export const getCurrentUserOwnsSingleWorkspace = createSelector(
	[ getWorkspaces, getCurrentUserID ],
	( workspaces, currentUserID ) => workspaces
		.filter( workspace => workspace.userIsOwner( currentUserID ) )
		.size === 1
);

export const getWorkspacesArray = createSelector(
	[
		getWorkspaces,
		getCurrentUserID
	],
	( workspaces, currentUserID ) => workspaces
		.valueSeq()
		.toArray()
		.sort(
			firstBy( workspace => workspace.userIsOwner( currentUserID ), -1 )
				.thenBy( 'name', { ignoreCase: true } )
		)
);

export const getFirstWorkspaceInWorkspacesArray = createSelector(
	[ getWorkspacesArray ],
	workspacesArray => (
		workspacesArray && workspacesArray.length > 0
			? workspacesArray[ 0 ]
			: undefined
	)
);

export const getCurrentUserWorkspacesWithProjectCreationPermissions = createSelector(
	[
		getWorkspacesArray
	],
	workspaces => workspaces
		.filter( workspace => workspace.currentUserCanCreateProject )
);

export const getWorkspacePanelTabsForWorkspaceAndCurrentUser = createSelector(
	[
		getWorkspace,
		getCurrentUserID,
		( _, props ) => props.tabs
	],
	( workspace, currentUserID, tabs ) => tabs
		.filter( tab => ( workspace && workspace.userIsOwner( currentUserID ) ) || !tab.onlyOwner )
);

const getWorkspaceMembersMapForWorkspace = createSelector(
	[ getWorkspaceID, getWorkspaceMembersMapWithUsers ],
	( workspaceID, workspacesMembers ) => (
		workspacesMembers
			.filter( member => member.workspaceID === workspaceID )
			.sortBy( member => member.user.name )
	)
);

export const getWorkspaceMembersForWorkspace = createSelector(
	[ getWorkspaceMembersMapForWorkspace ],
	workspaceMembersMap => workspaceMembersMap.toArray()
);

export const getWorkspaceMember = createSelector(
	[ getWorkspaceMembersForWorkspace, getWorkspaceMemberID ],
	( members, memberID ) => members.find( member => member.id === memberID )
);

export const getWorkspaceInvitations = createSelector(
	[ getWorkspaceID, getWorkspacesInvitations ],
	( workspaceID, workspacesInvitations ) => workspacesInvitations.valueSeq().toArray()
		.filter( invitation => invitation.workspaceID === workspaceID )
);

export const getWorkspaceProjects = createSelector(
	[ getWorkspace, getRawWorkspaceProjects ],
	( workspace, projectsProperties ) => projectsProperties
		.filter( projectProperties => projectProperties.workspace_id === workspace?.id )
		.sort(
			firstBy( project => project.current_user_role?.type === 'ProjectOwner', -1 )
				.thenBy( 'name', { ignoreCase: true } )
		)
		.toArray()
		.map( projectProperties => WorkspaceProject.fromJSON( projectProperties, workspace ) )
);

const getSortAscending = state => state.projectsViewOptions.get( 'sortAscending' );
const getSelectedSortOptionID = state => state.projectsViewOptions.get( 'sort' );

export const getSelectedProjectsSortOption = createSelector(
	[ getSelectedSortOptionID ],
	sortOptionID => SortOption.optionsByID[ sortOptionID ] || SortOption.byName
);

export const getSortedWorkspaceProjects = createSelector(
	[ getWorkspaceProjects, getSelectedProjectsSortOption, getSortAscending ],
	( projects, sort, ascending ) => {
		const sortedProjects = projects.sort( sort && sort.comparator );
		return ascending ? sortedProjects : sortedProjects.reverse();
	}
);

const getRawWorkspaceInvoices = state => state.workspaceInvoices;
export const getRawWorkspaceInvoicesPaginationData = state => state.workspaceInvoicesPaginationData;

export const getWorkspaceInvoices = createSelector(
	[ getWorkspaceID, getRawWorkspaceInvoices ],
	( workspaceID, workspaceInvoices ) => workspaceInvoices
		.map( WorkspaceInvoice.fromJSON )
		.filter( invoice => invoice.workspaceID === workspaceID )
		.sort( ( invoiceA, invoiceB ) => compareAsc( invoiceB.createdAt, invoiceA.createdAt ) )
		.toArray()
);

export const getWorkspaceInvoicesPaginationData = createSelector(
	[ getWorkspaceID, getRawWorkspaceInvoicesPaginationData ],
	( workspaceID, paginationData ) => {
		if ( !workspaceID || !paginationData ) { return undefined; }
		return paginationData.get( workspaceID );
	}
);

export const getUserWorkspaceRoleID = createSelector(
	[
		getCurrentUserID,
		getWorkspaceMembersForWorkspace
	],
	( currentUserID, workspaceMembers ) => {
		const userMember = workspaceMembers.find( member => member.user.id === currentUserID );
		return userMember && userMember.roleID;
	}
);

export const getUserWorkspaceRole = createSelector(
	[
		getCurrentUserID,
		getWorkspaceMembersForWorkspace
	],
	( currentUserID, workspaceMembers ) => {
		const userMember = workspaceMembers.find( member => member.user.id === currentUserID );
		return userMember && userMember.role;
	}
);

export const getUserCanInviteMembers = createSelector(
	[
		getUserWorkspaceRoleID
	],
	workspaceRoleID => workspaceRoleID === WorkspaceRole.owner().id
);

export const getUserCanEditWorkspaceMemberRole = createSelector(
	[
		( _, props ) => props.member,
		getCurrentUserID,
		getUserWorkspaceRole,
		getWorkspace
	],
	( member, currentUserID, currentUserRole, workspace ) => (
		workspace.isActive && member && currentUserRole
				&& member.userID !== currentUserID
				&& currentUserRole.canEditWorkspaceMemberRole( member )
	)
);

export const getUserCanDeleteWorkspaceMember = createSelector(
	[
		( _, props ) => props.member,
		getCurrentUserID,
		getUserWorkspaceRole
	],
	( member, currentUserID, currentUserRole ) => (
		member && currentUserRole && !member.isOwner
				&& ( currentUserRole.canDeleteWorkspaceMember( member ) || member.userID === currentUserID )
	)
);

export const getUserCanDeleteWorkspaceInvitation = createSelector(
	[
		( _, props ) => props.invite,
		getUserWorkspaceRole
	],
	( invite, currentUserRole ) => (
		invite && currentUserRole
			&& currentUserRole.canDeleteWorkspaceInvite( invite )
	)
);

export const getWorkspaceMemberOwnsProjects = createSelector(
	[
		( _, props ) => props.member,
		getWorkspaceProjects
	],
	( member, workspaceProjects ) => workspaceProjects.some(
		project => project.owner && project.owner.id === member.user.id
	)
);

export const getUserOwnsAWorkspace = createSelector(
	[
		getWorkspacesArray,
		getCurrentUserID
	],
	( workspaces, currentUserID ) => workspaces
		.some( workspace => workspace.userIsOwner( currentUserID ) )
);

export const getRawCreditCards = state => state.creditCards;

export const getCreditCards = createSelector(
	[ getRawCreditCards ],
	creditCardsSerialization => creditCardsSerialization
		.map( CreditCard.fromJSON )
);

export const getCreditCardForWorkspace = createSelector(
	[ getWorkspaceID, getCreditCards ],
	( workspaceID, creditCards ) => creditCards.find( card => card.workspaceID === workspaceID )
);

export const getCurrentUserOwnedWorkspaces = createSelector(
	[ getWorkspaces, getCurrentUserID ],
	( workspaces, currentUserID ) => workspaces
		.filter( workspace => workspace.userIsOwner( currentUserID ) )
		.toArray()
);

export const getCurrentUserMemberWorksapces = createSelector(
	[ getWorkspaces ],
	workspaces => workspaces
		.filter( workspace => workspace.currentRoleId === WorkspaceRole.admin().id )
		.toArray()
);

export const getCurrentUserCanCreateProjectsInAnotherWorkspace = createSelector(
	[ getCurrentUserWorkspacesWithProjectCreationPermissions, getCurrentUserOwnWorkspace ],
	( workspaces, currentUserWorkspace ) => workspaces.filter(
		workspace => !currentUserWorkspace || ( workspace.id !== currentUserWorkspace.id )
	).length > 0
);

export const getCurrentUserActiveWorkspacesWhereUserCanCreateProjects = createSelector(
	[ getCurrentUserWorkspacesWithProjectCreationPermissions ],
	workspaces => workspaces.filter( workspace => workspace.isActive )
);
