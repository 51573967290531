import { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { firstBy } from 'thenby';
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import Tooltip from '../UI/Tooltip/Tooltip';

import { textEndEllipsis } from '../../lib/textTruncate';
import './WorkspaceProjectListItem.scss';
import UserAvatar from '../UserAvatar/UserAvatar';
import User from '../../entities/user';

const MAX_USERS_TO_SHOW = 5;

class WorkspaceProjectListItem extends Component {
	constructor( props ) {
		super( props );

		this._titleRef = createRef();
	}

	get users() { return this.props.projectUsers; }

	get sortedUsers() {
		const { owner } = this.props.project;
		return this.users.sort(
			firstBy( user => user.id === owner.id, -1 )
				.thenBy( 'name', { ignoreCase: true } )
		);
	}

	get showedUsers() { return this.sortedUsers.slice( 0, MAX_USERS_TO_SHOW ); }

	get moreUsersCount() { return this.users.length - MAX_USERS_TO_SHOW; }

	get hasMoreUsers() { return this.users.length > MAX_USERS_TO_SHOW; }

	_renderUsers() {
		const { showedUsers, hasMoreUsers, moreUsersCount } = this;
		return (
			<div className="user-tooltip">
				{showedUsers.map( user => (
					<div className="user">
						<UserAvatar user={user} />
						<p
							key={user.id}
							className="name"
						>
							{user.name}
						</p>
					</div>
				) )}
				{hasMoreUsers
					&& (
						<p className="more">
							{`+${moreUsersCount} more ${moreUsersCount > 1 ? 'users' : 'user'}`}
						</p>
					)}
			</div>
		);
	}

	render() {
		const { project } = this.props;
		const usersCount = project.users?.length;

		const createdAt = moment( project.createdAt ).format( 'MM/DD/YY' );
		const projectName = textEndEllipsis( project.name, 33 );
		const ownerNameText = project.currentRoleId === 'ProjectOwner' ? 'Me' : textEndEllipsis( project.owner.name, 33 );

		return (
			<div className="WorkspaceProjectListItem">
				<div className="project-info">
					<div className="img-cover">
						<img src={project.coverImageURL} alt="" />
					</div>
					<div className="name-and-owner">
						<h5>{projectName}</h5>
						<p>Owned by {ownerNameText}</p>
					</div>
				</div>
				<div className="created-on">
					<p>{createdAt}</p>
				</div>
				<div className="users-count">
					<Tooltip
						html={this._renderUsers()}
						theme="list"
						position="bottom"
					>
						<p>{usersCount}</p>
					</Tooltip>
				</div>
				<div className="size">
					<p>{project.storageUsedInBytes?.formatBytes() ?? ' ... '}</p>
				</div>
				<div className="my-role">
					<p>{project.currentRoleId.replace( 'Project', '' ).capitalize()}</p>
				</div>
				<div className="actions-dropdown">
					<DropdownMenu
						items={this.props.dropdownItems}
						onItemClick={this.props.onDropdownItemClick}
					/>
				</div>

			</div>
		);
	}
}

WorkspaceProjectListItem.propTypes = {
	project: PropTypes.shape( {
		id: PropTypes.number,
		name: PropTypes.string.isRequired,
		coverImageURL: PropTypes.string,
		owner: PropTypes.shape( {
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired
		} ),
		users: PropTypes.arrayOf( PropTypes.number ),
		createdAt: PropTypes.instanceOf( Date ),
		currentRoleId: PropTypes.string.isRequired,
		storageUsedInBytes: PropTypes.number.isRequired
	} ).isRequired,
	projectUsers: PropTypes.instanceOf( User ),
	dropdownItems: PropTypes.arrayOf( PropTypes.string ),
	onDropdownItemClick: PropTypes.func.isRequired
};

WorkspaceProjectListItem.defaultProps = {
	projectUsers: [],
	dropdownItems: []
};

export default WorkspaceProjectListItem;
