import PropTypes from 'prop-types';
import FloatingBox from '../../FloatingBox/FloatingBox';
import AssetRound from '../../../entities/assetRound';

import './AssetRoundsMenu.scss';
import AssetRoundsMenuItem from '../AssetRoundsMenuItem/AssetRoundsMenuItem';

export const AssetRoundsMenu = ( {
	rounds, selected, onSelect, onClose, iconSize, iconColor
} ) => {
	const onItemSelect = ( round ) => {
		onClose();
		onSelect( round );
	};
	return (
		<FloatingBox showArrow={false} className="AssetRoundsMenu">
			<div className="AssetRoundsMenuHeader">
				<h3>Asset Round</h3>
				<span className="done" onClick={onClose}>Done</span>
			</div>
			<div className="AssetRoundsList">
				{rounds.map( round => (
					<AssetRoundsMenuItem
						round={round}
						isSelected={round.id === selected.id}
						onSelect={onItemSelect}
						iconSize={iconSize}
						iconColor={iconColor}
					/>
				)
				)}
			</div>
		</FloatingBox>
	);
};

AssetRoundsMenu.propTypes = {
	rounds: PropTypes.arrayOf( PropTypes.instanceOf( AssetRound ) ),
	selected: PropTypes.instanceOf( AssetRound ),
	onSelect: PropTypes.func,
	onClose: PropTypes.func,
	iconSize: PropTypes.number,
	iconColor: PropTypes.string
};

AssetRoundsMenu.defaultProps = {
	rounds: [],
	selected: undefined,
	onSelect: undefined,
	onClose: () => { },
	iconSize: 16,
	iconColor: 'white'
};
