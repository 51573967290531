import FileExtensionValidator from '../validators/fileExtensionValidator';
import { ASSET_ALL_EXTENSIONS, ASSET_DOCUMENT_EXTENSIONS, ASSET_IMAGE_EXTENSIONS } from '../entities/asset';

export default class FileExtensionValidatorFactory {
	static forAssets() {
		return new FileExtensionValidator( ASSET_ALL_EXTENSIONS );
	}

	static forAsset( { isImage } ) {
		return isImage ? this.forImages() : this.forDocuments();
	}

	static forDocuments() {
		return new FileExtensionValidator( ASSET_DOCUMENT_EXTENSIONS );
	}

	static forImages() {
		return new FileExtensionValidator( ASSET_IMAGE_EXTENSIONS );
	}
}
