import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import Icon from '../../../new_arch/components/Icon/Icon';
import Button from '../../../new_arch/components/Button/Button';
import InformationTooltipIcon from '../InformationTooltipIcon/InformationTooltipIcon';
import PlanSettingsPresenter from '../../../presenters/PlanSettings/PlanSettingsPresenter';

import './DowngradeWarning.scss';

export const DowngradeWarning = ( {
	presenter
} ) => (
	<div className="DowngradeWarning" style={{ background: presenter.downgradeWarningColor }}>
		<Icon icon="error" size={20} color={presenter.downgradeWarningIconColor} />
		<p className="DowngradeWarningText">
			{presenter.downgradeWarningText}
			<InformationTooltipIcon
				title={presenter.downgradeWarningTooltipText}
				position="top"
			/>
		</p>
		<Button onClick={presenter.onDowngradeWarningButtonClicked}>
			{presenter.downgradeWarningButtonText}
		</Button>
	</div>
);

DowngradeWarning.propTypes = {
	presenter: PropTypes.instanceOf( PlanSettingsPresenter ).isRequired
};

export default observer( DowngradeWarning );
