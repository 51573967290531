import { FOLDERS_URL, PROJECTS_URL } from '../config/urls';
import {
	CHANGE_FOLDER_NAME,
	CLEAR_DIRECT_SUBFOLDERS,
	CREATE_FOLDER,
	FETCH_FOLDER,
	FETCH_FOLDERS_FOR_PROJECT,
	RESET_CHANGE_FOLDER_NAME,
	RESET_CREATE_FOLDER,
	MOVE_ITEMS_INTO_FOLDER,
	DELETE_FOLDERS,
	FETCH_FOLDERS_FOR_FOLDER,
	FETCH_FOLDER_ANCESTORS,
	MARK_FOLDER_NEW_ACTIVITY_AS_SEEN,
	FETCH_FOLDER_OVERVIEW,
	FETCH_FOLDER_INFORMATION,
	CHANGE_FOLDER_COVER
} from '../actions/types';
import { trackFolderCreated } from '../services/tracker/trackers/folders';
import { OrganizerType } from '../entities/folder';
import { getAssetIndex } from '../selectors/assets';
import { createURLWithParams } from '../lib/urlUtils';
import QueryClientUpdater from '../new_arch/services/QueryClientUpdater';

export default class FolderSystem {
	constructor( dispatch ) {
		this.dispatch = dispatch;
	}

	createFolder( properties ) {
		return this.dispatch( ( dispatch, _, api ) => dispatch( {
			type: CREATE_FOLDER,
			promise: api
				.post(
					`${FOLDERS_URL}`,
					this._serializeProperties( properties )
				)
				.then( ( { response } ) => this._handleFolderCreated( response ) )
		} ) );
	}

	resetCreateFolderRequest = () => this.dispatch( ( { type: RESET_CREATE_FOLDER } ) );

	fetchFoldersForProject = ( { projectID, assetFilters, selectedSortOption, sortDirection } ) => (
		this._fetchOrganizerFolders( {
			actionType: FETCH_FOLDERS_FOR_PROJECT,
			organizersURL: PROJECTS_URL,
			organizerID: projectID,
			meta: { organizerType: OrganizerType.project, organizerID: projectID },
			assetFilters,
			selectedSortOption,
			sortDirection
		} )
	);

	fetchFoldersForFolder = ( { folderID, assetFilters, selectedSortOption, sortDirection } ) => (
		this._fetchOrganizerFolders( {
			actionType: FETCH_FOLDERS_FOR_FOLDER,
			organizersURL: FOLDERS_URL,
			organizerID: folderID,
			meta: { organizerType: OrganizerType.folder, organizerID: folderID },
			assetFilters,
			selectedSortOption,
			sortDirection
		} )
	);

	_fetchOrganizerFolders(
		{ actionType, meta, organizersURL, organizerID, assetFilters, selectedSortOption, sortDirection }
	) {
		return this.dispatch(
			( dispatch, _, api ) => {
				const requestURL = createURLWithParams(
					`${organizersURL}/${organizerID}/folders`,
					{
						asset_filters: assetFilters?.encode(),
						sort_by: selectedSortOption?.id,
						sort_order: sortDirection
					}
				);
				return dispatch( {
					type: actionType,
					promise: api
						.get( requestURL )
						.then( ( { response } ) => response ),
					meta
				} )
			}
		);
	}

	clearDirectSubfoldersOf = organizer => this.dispatch( {
		type: CLEAR_DIRECT_SUBFOLDERS,
		payload: { organizer }
	} );

	moveItemsIntoFolder( { assets, folder, folders, isFiltering = false } ) {
		return this.dispatch( ( dispatch, getState, api ) => {
			const assetIndexes = assets.map( asset => getAssetIndex(
				getState(), { assetID: asset.id, projectID: folder.projectID, folderID: asset.folderID }
			) )
			return dispatch( {
				type: MOVE_ITEMS_INTO_FOLDER,
				promise: api
					.post(
						`${FOLDERS_URL}/${folder.id}/move_items`,
						{
							assets_ids: assets.map( a => a.id ),
							folders_ids: folders.map( f => f.id )
						}
					),
				meta: { assets, assetIndexes, folder, folders, isFiltering }
			} );
		} );
	}

	changeCoverImage = ( { folder, assetID } ) => this.dispatch( ( dispatch, _, api ) => dispatch( {
		type: CHANGE_FOLDER_COVER,
		promise: api
			.put( `${FOLDERS_URL}/${folder.id}/cover`, { asset_id: assetID } )
			.then( ( response ) => {
				QueryClientUpdater.folderCoverChanged( folder );
				return response;
			} )
	} ) );

	renameFolder = ( { folderID, projectID, newName } ) => this.dispatch( ( dispatch, _, api ) => dispatch( {
		type: CHANGE_FOLDER_NAME,
		promise: api
			.patch( `${FOLDERS_URL}/${folderID}`, { name: newName } )
			.then( ( { response } ) => {
				QueryClientUpdater.folderRenamed( { folderID, projectID, newName } );
				return response;
			} )
	} ) );

	resetRenameFolderRequest = () => this.dispatch( ( { type: RESET_CHANGE_FOLDER_NAME } ) );

	fetchFolder = folderID => this.dispatch( ( dispatch, _, api ) => dispatch( {
		type: FETCH_FOLDER,
		promise: api
			.get( this._folderURLFor( folderID ) )
			.then( ( { response } ) => response )
	} ) );

	deleteFolders = ( { projectID, folderIDs } ) => this.dispatch( ( dispatch, _, api ) => dispatch( {
		type: DELETE_FOLDERS,
		promise: api
			.delete( FOLDERS_URL, { folders_ids: folderIDs } )
			.then( () => {
				QueryClientUpdater.foldersWereDeleted( { projectID, folderIDs } );
				return folderIDs;
			} )
	} ) );

	fetchAncestors = ( { folderID } ) => this.dispatch( ( dispatch, _, api ) => dispatch( {
		type: FETCH_FOLDER_ANCESTORS,
		promise: api
			.get( `${FOLDERS_URL}/${folderID}/ancestors` )
			.then( ( { response } ) => response ),
		meta: { folderID }
	} ) );

	markFolderNewActivityAsSeen = ( { folderID } ) => this.dispatch( ( dispatch, _, api ) => dispatch( {
		type: MARK_FOLDER_NEW_ACTIVITY_AS_SEEN,
		promise: api.post( `${FOLDERS_URL}/${folderID}/mark_new_activity_as_seen` ),
		meta: { folderID }
	} ) );

	fetchFolderOverview = folderID => this.dispatch( ( dispatch, _, api ) => dispatch( {
		type: FETCH_FOLDER_OVERVIEW,
		promise: api.get( `${FOLDERS_URL}/${folderID}/overview` ),
		meta: { folderID }
	} ) );

	fetchFolderInformation = folderID => this.dispatch( ( dispatch, _, api ) => dispatch( {
		type: FETCH_FOLDER_INFORMATION,
		promise: api
			.get( `${FOLDERS_URL}/${folderID}/information` )
			.then( ( { response } ) => response ),
		meta: { folderID }
	} ) );

	_folderURLFor = folderID => `${FOLDERS_URL}/${folderID}`;

	_serializeProperties( properties ) {
		return {
			name: properties.name,
			organizer_id: properties.organizerID,
			organizer_type: properties.organizerType
		};
	}

	_handleFolderCreated( response ) {
		trackFolderCreated( { id: response.id, name: response.name, isNested: response.organizer_type === 'Folder' } );
		QueryClientUpdater.createdFolder( response );
		return response;
	}
}
