import ProjectRole, { AVAILABLE_ROLES_BY_TYPE } from './projectRole';
import ImmutableEntity from './immutableEntity';

export default class ProjectMember extends ImmutableEntity {
	constructor( id, projectID, userID, roleID, user = undefined ) {
		super();

		this.id = id;
		this.projectID = projectID;
		this.userID = userID;
		this.roleID = roleID;
		this.user = user;
	}

	get role() { return AVAILABLE_ROLES_BY_TYPE[ this.roleID ]; }

	get isProjectCollaborator() { return this.roleID === ProjectRole.projectCollaborator().id; }

	get isOwner() { return this.roleID === ProjectRole.owner().id; }

	static fromJSON( properties, user ) {
		return new ProjectMember(
			properties.id,
			properties.project_id,
			properties.user,
			properties.role.type,
			user
		);
	}

	clone() {
		return new ProjectMember(
			this.id,
			this.projectID,
			this.userID,
			this.roleID,
			this.user
		);
	}

	canDeleteCommentFrom( authorMember ) {
		if ( !authorMember ) { return false; }
		const memberIsAuthor = this.userID === authorMember.userID;
		return memberIsAuthor || this.role.hasLowerLevel( authorMember.role );
	}
}
