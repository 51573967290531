import { makeAutoObservable } from 'mobx';
import SnackbarSystem from '../../systems/SnackbarSystem';
import { ErrorResponse } from '../../types/ErrorResponse';
import { ERROR_DEFAULT } from '../../messages/user';
import { isErrorWithStatus } from '../../errors';

export type UserProfile = {
	email: string;
	avatar?: File;
	name?: string;
}

export type EmailVerificationModalPresenterParams = {
	onClose: () => void;
	sendVerificationEmail: ( email: string ) => Promise<ErrorResponse>;
	updateProfile: ( userID: number, data: UserProfile, accessCode: string ) => Promise<ErrorResponse>;
	data: UserProfile;
	userID: number;
	snackbarSystem: SnackbarSystem;
}

export default class EmailVerificationModalPresenter {
	private onClose: () => void;
	private sendVerificationEmail: ( email: string ) => Promise<ErrorResponse>;
	private updateProfile: ( userID: number, data: UserProfile, accessCode: string ) => Promise<ErrorResponse>;
	private data: UserProfile;
	private userID: number;
	private snackbarSystem: SnackbarSystem;
	isSubmitting: boolean;
	code: string;
	error: string;

	constructor( {
		onClose,
		sendVerificationEmail,
		updateProfile,
		data,
		userID,
		snackbarSystem
	}: EmailVerificationModalPresenterParams ) {
		this.onClose = onClose;
		this.sendVerificationEmail = sendVerificationEmail;
		this.updateProfile = updateProfile;

		this.data = data;
		this.userID = userID;
		this.snackbarSystem = snackbarSystem;

		this.code = '';
		this.error = '';
		this.isSubmitting = false;

		makeAutoObservable( this );
	}

	resendEmailVerification = () => {
		this.sendVerificationEmail( this.data.email )
			.then( ( response ) => {
				if ( response.error ) {
					this.snackbarSystem.showErrorMessage( { title: ERROR_DEFAULT } );
				} else {
					this.snackbarSystem.showSuccessMessage( { title: 'Verification code sent' } );
				}
			} )
	}

	updateUserProfile = () => {
		if ( this.code.length < 6 ) {
			this.setError( 'Code must be at least 6 characters' );
			return;
		}

		this.setIsSubmitting( true );
		this.updateProfile( this.userID, this.data, this.code )
			.then( ( response ) => {
				if ( !response.error ) {
					this.onClose();
					this.snackbarSystem.showSuccessMessage( { title: 'Profile updated' } );
				} else if ( isErrorWithStatus( 400, 'incorrect_code' )( response.payload ) ) {
					this.setError( 'Code entered is incorrect' );
				} else if ( isErrorWithStatus( 400, 'expired_code' )( response.payload ) ) {
					this.setError( 'Code expired already, try resending it again' );
				} else if ( isErrorWithStatus( 400, 'used_code' )( response.payload ) ) {
					this.setError( 'Code has been already used, try resending it again' );
				} else {
					this.snackbarSystem.showErrorMessage( { title: ERROR_DEFAULT } );
				}
			} )
			.finally( () => this.setIsSubmitting( false ) );
	}

	onCodeChange = ( code: string ) => {
		this.code = code;
		this.resetError();
	}

	private resetError = () => {
		this.error = '';
	}

	private setError = ( error: string ) => {
		this.error = error;
	}

	private setIsSubmitting = ( isSubmitting: boolean ) => {
		this.isSubmitting = isSubmitting;
	}
}
