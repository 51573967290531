/* eslint-disable func-names */
import { Map } from 'immutable';
import get from 'lodash/get';
import { mapForPayload } from '../lib/reduxUtils';

Map.prototype.updateIfSet = function ( key, updater ) {
	return this.has( key )
		? this.update( key, updater )
		: this;
};

Map.prototype.replaceMatchingWithPayload = function ( payload, filterValue, key ) {
	let matchPredicate;

	if ( typeof filterValue === 'function' ) {
		matchPredicate = filterValue;
	} else {
		matchPredicate = el => get( el, key ) === filterValue;
	}

	return this
		.filter( el => !matchPredicate( el ) )
		.merge( mapForPayload( payload ) );
};
