import AddOn from '../../entities/addOn';
import Workspace from '../../entities/workspace';
import Button from '../../new_arch/components/Button/Button';
import InviteWorkspaceMemberBillingConfBoxPresenter from '../../presenters/InviteWorkspaceMemberBillingConfBoxPresenter/InviteWorkspaceMemberBillingConfBoxPresenter';
import LoaderButton from '../UI/LoaderButton/LoaderButton';

export type InviteWorkspaceMemberBillingConfBoxProps = {
	workspace: Workspace,
	addOn: AddOn,
	onCancel: () => void,
	onConfirm: () => void,
	loading: boolean,
	emails: string[]
}

const InviteWorkspaceMemberBillingConfBox = (
	{ workspace, addOn, onCancel, onConfirm, loading, emails } : InviteWorkspaceMemberBillingConfBoxProps
) => {
	const presenter = new InviteWorkspaceMemberBillingConfBoxPresenter( {
		workspace,
		addOn,
		emails
	} );

	const { cost, billingInterval, remainingSeatsText, newMembersText } = presenter;

	return (
		<div className="InviteWorkspaceMemberBillingConfirmationBox">
			<div className="info">
				<p>
					You are about to add <strong>{ newMembersText }</strong> to your workspace.
					You currently have { remainingSeatsText } available.
					Adding this member will increase your current subscription cost by <br />
					<strong>${ cost }/{ billingInterval }.</strong>
				</p>
				<p>
					The additional charges will be prorated based on you current billing
					cycle and will apply to your next payment.
				</p>
			</div>
			<div className="actions">
				<Button
					color="secondary"
					onClick={onCancel}
					className="cancel-button"
				>
					Cancel
				</Button>
				<LoaderButton
					onClick={onConfirm}
					loading={loading}
					disabled={loading}
					className="confirm-button"
					data-testid="confirm-button"
				>
					Confirm and Send Invite
				</LoaderButton>
			</div>
		</div>
	);
};

export default InviteWorkspaceMemberBillingConfBox;
