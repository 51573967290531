/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { textEndEllipsis } from '../../lib/textTruncate';
import { ERROR } from '../../styles/colors';
import Icon from '../../new_arch/components/Icon/Icon';
import Workspace from '../../entities/workspace';

import './MyProfileSideBarItem.scss';
import ConditionalWrapper from '../ConditionalWrapper/ConditionalWrapper';
import Tooltip from '../UI/Tooltip/Tooltip';

const MyProfileSideBarItem = ( {
	title, subtitle, image, selected, currentUserID, onClick,
	workspace, planToDisplay
} ) => {
	const renderWarningIcon = () => (
		<ConditionalWrapper
			condition={!workspace?.userIsOwner( currentUserID )}
			wrapper={Tooltip}
			wrapperProps={{ className: 'warning-icon', title: 'This workspace is suspended' }}
		>
			<Icon
				className={workspace?.userIsOwner( currentUserID ) && 'warning-icon'}
				icon="error"
				size={24}
				color={ERROR}
			/>
		</ConditionalWrapper>
	);

	const renderPlanTag = () => (
		<span
			className="plan-tag"
			style={{ backgroundColor: planToDisplay.tagColor }}
		>
			{planToDisplay.name}
		</span>
	);

	const renderIcon = () => {
		if ( workspace?.showWarningIcon ) return renderWarningIcon();
		if ( !workspace?.userIsOwner( currentUserID ) ) return null;
		return renderPlanTag();
	};

	return (
		<div className={classNames( 'MyProfileSideBarItem', { selected, isWorkspace: !!workspace } )} onClick={onClick}>
			<img alt={title} className="image" src={image} />
			<div className="title-and-subtitle">
				<h5 className="title">{title}</h5>
				<p className="subtitle">{textEndEllipsis( subtitle, 35 )}</p>
			</div>
			{renderIcon()}
			{selected && <div className="selected-mark" />}
		</div>
	);
};

MyProfileSideBarItem.propTypes = {
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string.isRequired,
	image: PropTypes.string.isRequired,
	workspace: PropTypes.instanceOf( Workspace ),
	selected: PropTypes.bool,
	onClick: PropTypes.func,
	currentUserID: PropTypes.number.isRequired,
	planToDisplay: PropTypes.shape( {
		name: PropTypes.string,
		tagColor: PropTypes.string
	} )
};

MyProfileSideBarItem.defaultProps = {
	workspace: undefined,
	selected: false,
	onClick: () => {},
	planToDisplay: {}
};

export default MyProfileSideBarItem;
