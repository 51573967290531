import { makeAutoObservable } from 'mobx';
import AsyncActionPresenterFactory from '../../factories/AsyncActionPresenterFactory';
import SnackbarFactory from '../../factories/SnackbarFactory';
import PopupFactory from '../../factories/PopupFactory';
import { isAddOnInUseError } from '../../errors/addons';

export default class StorageAddOnModalPresenter {
	constructor( modalPresenter ) {
		this._modalPresenter = modalPresenter;

		makeAutoObservable( this );
	}

	get title() {
		return `+${this._includedAmount} Storage`;
	}

	get text() {
		return `Storage is added in ${this._includedAmount} increments; you can add as much as you need.`;
	}

	get buttonText() {
		return `Add ${this._includedAmount}`;
	}

	get removeButtonText() {
		return `Remove ${this._includedAmount} of additional storage`;
	}

	get quantityText() {
		return `You currently have ${this.quantityAmount.formatBytes()} of additional storage`;
	}

	get addOnQuantity() {
		return this._workspace.storageAddOnQuantity;
	}

	get quantityAmount() {
		return this._modalPresenter.addOnIncludedAmount * this.addOnQuantity;
	}

	buyButtonWasClicked = () => {
		AsyncActionPresenterFactory.forBuyStorageAddOn( {
			addOn: this._modalPresenter.addOn,
			addOnPrice: this._modalPresenter.addOnPrice,
			addOnSystem: this._modalPresenter.addOnSystem,
			popupSystem: this._modalPresenter.popupSystem,
			workspaceID: this._workspace.id,
			snackbarSystem: this._modalPresenter.snackbarSystem,
			description: this._popupDescription,
			formatedIncludedStorage: this._includedAmount
		} ).trigger().then( ( result ) => {
			if ( result?.error ) return;
			this._modalPresenter.close();
		} );
	};

	removeButtonWasClicked = () => {
		AsyncActionPresenterFactory.forRemoveStorageAddOn( {
			addOnPrice: this._modalPresenter.addOnPrice,
			addOnSystem: this._modalPresenter.addOnSystem,
			popupSystem: this._modalPresenter.popupSystem,
			workspaceID: this._workspace.id,
			snackbarSystem: this._modalPresenter.snackbarSystem,
			onError: this._onRemoveAddOnError,
			description: this._confirmationPopupRemovalDescription,
			formatedStorageToBeRemoved: this._includedAmount
		} ).trigger().then( ( result ) => {
			if ( result?.error ) return;
			this._modalPresenter.close();
		} );
	};

	_onRemoveAddOnError = ( result ) => {
		if ( !result?.error ) return undefined;
		if ( isAddOnInUseError( result.payload ) ) {
			return PopupFactory.removeStorageAddOnErrorPopup( {
				popupSystem: this._modalPresenter.popupSystem
			} );
		}
		return SnackbarFactory.defaultErrorMessage( this._modalPresenter.snackbarSystem );
	}

	get _hasYearlySubscriptionInterval() {
		return this._workspace.hasYearlySubscriptionInterval;
	}

	get _confirmationPopupDescriptionPeriod() {
		return this._hasYearlySubscriptionInterval ? 'year' : 'month';
	}

	get _confirmationPopupRemovalDescription() {
		return `Are you sure you want to remove this additional storage of ${this._includedAmount}? You still have ${this._workspace.timeLeftUntilBillingPeriodEnd} to use it and if you proceed you will lose them.`;
	}

	get _popupDescription() {
		return this._hasYearlySubscriptionInterval
			? this._annualPopUpDescription
			: this._monthlyPopUpDescription;
	}

	get _monthlyPopUpDescription() {
		return `You will add ${this._includedAmount} of storage to your workspace for an additional $${this._modalPresenter.cost} per month, bringing your workspace's total storage to ${this._totalStorageWithAddOn}.`;
	}

	get _annualPopUpDescription() {
		return `You will add ${this._includedAmount} to your plan for a total of ${this._totalStorageWithAddOn} at a prorated $${this._proratedCost} ($${this._modalPresenter.cost} per year billed with your subscription).`;
	}

	get _includedAmount() {
		return this._modalPresenter.addOnIncludedAmount.formatBytes();
	}

	get _totalStorageWithAddOn() {
		const totalStorage = this._workspace.includedStorage + this._modalPresenter.addOnIncludedAmount;
		return totalStorage.formatBytes();
	}

	get _proratedCost() {
		return this._workspace.prorateCostToEndOfBillingPeriod( this._modalPresenter.cost );
	}

	get _workspace() {
		return this._modalPresenter.workspace;
	}
}
