import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import { NEUTRAL } from '../../styles/colors';

import './ProgressBar.scss';

const ProgressBar = ( {
	valueCount,
	maxValue,
	color,
	maxedOutColor,
	greyOut,
	maxedOutMultiplier
} ) => {
	const sliderStyle = ( count, max ) => {
		if ( count >= ( max * maxedOutMultiplier ) ) return { 'background-color': greyOut ? NEUTRAL : maxedOutColor };
		return { 'background-color': greyOut ? NEUTRAL : color };
	};
	return (
		<div className="ProgressBar">
			<Slider
				value={valueCount}
				max={maxValue}
				trackStyle={sliderStyle( valueCount, maxValue )}
			/>
		</div>
	);
};

ProgressBar.propTypes = {
	valueCount: PropTypes.number.isRequired,
	maxValue: PropTypes.number.isRequired,
	color: PropTypes.string.isRequired,
	maxedOutColor: PropTypes.string.isRequired,
	greyOut: PropTypes.bool,
	maxedOutMultiplier: PropTypes.number
};

ProgressBar.defaultProps = {
	greyOut: false,
	maxedOutMultiplier: 1
};

export default ProgressBar;
