import { Map } from 'immutable';
import { handle } from 'redux-pack';

import {
	SET_PROJECTS_VIEW_SORT,
	SET_PROJECTS_VIEW_FILTER,
	SET_PROJECTS_VIEW_ITEMS_SIZE,
	SET_PROJECTS_CUSTOM_SORT
} from '../actions/types';
import SortOption from '../entities/sortOption';
import FilterOption from '../entities/filterOption';
import { parseIDsArrayFromStringifiedArray } from '../lib/parsers';
import { UPDATER_ACTION_TYPE } from '../new_arch/services/ReduxStoreUpdater';

const defaultState = new Map( {
	sort: SortOption.byLastActivity.id,
	customSort: [],
	sortAscending: false,
	filter: FilterOption.none.id,
	itemSize: 50
} );

export const projectsViewOptions = ( state = defaultState, action ) => {
	switch ( action.type ) {
	case SET_PROJECTS_VIEW_SORT: {
		const { sortOption, ascending } = action.payload;
		return state.merge( { sort: sortOption, sortAscending: ascending } );
	}
	case SET_PROJECTS_VIEW_FILTER:
		return state.set( 'filter', action.payload );
	case SET_PROJECTS_VIEW_ITEMS_SIZE:
		return state.set( 'itemSize', action.payload );
	case SET_PROJECTS_CUSTOM_SORT:
		return handle( state, action, {
			start: prevState => prevState
				.set( 'customSort', action.meta.newSort )
				.set( 'sort', SortOption.byUser.id )
				.set( 'sortAscending', true )
		} );
	case UPDATER_ACTION_TYPE.currentUser: {
		const { projects_order: projectsOrder } = action.payload.user.user_preference;
		return state.set( 'customSort', parseIDsArrayFromStringifiedArray( projectsOrder ) );
	}
	default:
		return state;
	}
};
