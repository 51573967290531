import { USERS_URL, WORKSPACES_URL } from '../config/urls';
import {
	FETCH_WORKSPACES, DELETE_WORKSPACE, EDIT_WORKSPACE, TRANSFER_WORKSPACE_OWNERSHIP,
	CREATE_SUBSCRIPTION, CANCEL_SUBSCRIPTION,
	FETCH_WORKSPACE_BILLING_INFORMATION, FETCH_WORKSPACE_PAYMENT_METHOD,
	UPDATE_WORKSPACE_BILLING_INFORMATION, UPDATE_WORKSPACE_PAYMENT_METHOD,
	FETCH_WORKSPACE_SETTINGS_INFORMATION, FETCH_SUBSCRIPTION_RELATED_INFORMATION,
	REACTIVATE_SUBSCRIPTION, LEAVE_WORKSPACE, CREATE_WORKSPACE, DOWNGRADE_SUBSCRIPTION,
	CANCEL_DOWNGRADE_SUBSCRIPTION
} from './types';
import { trackWorkspaceDeletion, trackTransferWorkspaceOwnership, trackWorkspaceBillingEdit } from '../services/tracker/trackers/workspaces';
import {
	trackSubscriptionCancelled,
	trackSubscriptionReactivated, trackSubscriptionCreated, trackSubscriptionStarted
} from '../services/tracker/trackers/subscriptions';
import { objectToFormData } from '../lib/requestUtils';
import { getWorkspace } from '../selectors/workspaces';
import { fetchWorkspaceMembersAndInvitations } from './workspaceMembers';
import { fetchWorkspaceProjects } from './workspaceProjects';
import { fetchWorkspaceInvoices } from './workspaceInvoices';
import { catchNotAllowedResponseWrapper } from '../lib/catchNotAllowedResponseWrapper';
import AddOnSystem from '../systems/AddOnSystem';

const serializeBillingInformation = ( {
	address,
	city,
	companyName,
	country,
	state,
	postalCode
} ) => ( {
	address,
	city,
	company_name: companyName,
	country: country.value,
	state: state.value,
	postal_code: postalCode
} );

const getWorkspaceAndTrackDeletion = ( workspaceID, getState ) => {
	const workspace = getWorkspace( getState(), { workspaceID } );
	trackWorkspaceDeletion( workspace );
};

const getAddOnSystem = dispatch => new AddOnSystem( dispatch );

const eventPropertiesFrom = pricingPlanPrice => ( {
	plan: pricingPlanPrice?.pricingPlan?.name,
	includedMembers: pricingPlanPrice?.pricingPlan?.included_members,
	withFreeTrial: !pricingPlanPrice || pricingPlanPrice.isFree
} );

export const fetchCurrentUserWorkspaces = () => ( dispatch, _, api ) => dispatch( {
	type: FETCH_WORKSPACES,
	promise: api
		.get( `${USERS_URL}/me/workspaces` )
		.then( response => response.response )
} );

export const deleteWorkspace = workspaceID => ( dispatch, getState, api ) => {
	getWorkspaceAndTrackDeletion( workspaceID, getState );

	return dispatch( {
		type: DELETE_WORKSPACE,
		promise: api
			.delete( `${WORKSPACES_URL}/${workspaceID}` ),
		meta: { workspaceID }
	} );
};

export const editWorkspace = ( workspaceID, data ) => ( dispatch, _, api ) => dispatch( {
	type: EDIT_WORKSPACE,
	promise: catchNotAllowedResponseWrapper( api.patch(
		`${WORKSPACES_URL}/${workspaceID}`,
		objectToFormData( data ),
		{ 'Accept': 'application/json, */*', 'Content-Type': undefined }
	), dispatch, workspaceID )
		.then( response => response.response ),
	meta: { workspaceID }
} );

export const transferWorkspaceOwnership = (
	workspaceID, prevOwnerID, newOwner, message
) => ( dispatch, _, api ) => {
	const parameters = { workspace_member_id: newOwner.id, message };

	trackTransferWorkspaceOwnership();

	return dispatch( {
		type: TRANSFER_WORKSPACE_OWNERSHIP,
		promise: catchNotAllowedResponseWrapper( api.post(
			`${WORKSPACES_URL}/${workspaceID}/transfer_ownership`, parameters ), dispatch, workspaceID
		)
			.then( () => workspaceID ),
		meta: { workspaceID, prevOwnerID, newOwner }
	} );
};

export const createWorkspace = ( pricingPlanPrice, billingInformation, paymentMethodID, promoCodeID ) => (
	dispatch, _, api
) => {
	const parameters = {
		price_id: pricingPlanPrice?.id,
		billing_information: billingInformation && serializeBillingInformation( billingInformation ),
		payment_method_id: paymentMethodID,
		promotion_code_id: promoCodeID
	};

	return dispatch( {
		type: CREATE_WORKSPACE,
		promise: catchNotAllowedResponseWrapper(
			api.post( WORKSPACES_URL, parameters ),
			dispatch
		)
			.then( ( response ) => {
				trackSubscriptionStarted( eventPropertiesFrom( pricingPlanPrice ) );
				return response.response;
			} )
	} );
};

export const fetchWorkspaceBillingInformation = workspaceID => ( dispatch, _, api ) => dispatch( {
	type: FETCH_WORKSPACE_BILLING_INFORMATION,
	promise: catchNotAllowedResponseWrapper( api.get(
		`${WORKSPACES_URL}/${workspaceID}/billing_information` ), dispatch, workspaceID
	)
		.then( response => response.response ),
	meta: { workspaceID }
} );

export const fetchWorkspacePaymentMethod = workspaceID => ( dispatch, _, api ) => dispatch( {
	type: FETCH_WORKSPACE_PAYMENT_METHOD,
	promise: catchNotAllowedResponseWrapper( api.get(
		`${WORKSPACES_URL}/${workspaceID}/payment_method` ), dispatch, workspaceID
	)
		.then( response => response.response ),
	meta: { workspaceID }
} );

export const fetchSubscriptionRelatedInformation = workspaceID => dispatch => dispatch( {
	type: FETCH_SUBSCRIPTION_RELATED_INFORMATION,
	promise: Promise.all( [
		dispatch( fetchWorkspaceBillingInformation( workspaceID ) ),
		dispatch( fetchWorkspacePaymentMethod( workspaceID ) ),
		dispatch( fetchWorkspaceInvoices( workspaceID ) )
	] )
} );

export const createSubscription = (
	workspaceID, pricingPlanPrice, billingInformation, paymentMethodID, promoCodeID
) => ( dispatch, _, api ) => {
	const parameters = {
		price_id: pricingPlanPrice?.id,
		billing_information: billingInformation && serializeBillingInformation( billingInformation ),
		payment_method_id: paymentMethodID,
		promotion_code_id: promoCodeID
	};
	return dispatch( {
		type: CREATE_SUBSCRIPTION,
		promise: catchNotAllowedResponseWrapper( api.post(
			`${WORKSPACES_URL}/${workspaceID}/subscribe`, parameters ), dispatch, workspaceID
		)
			.then( ( response ) => {
				trackSubscriptionCreated( eventPropertiesFrom( pricingPlanPrice ) );
				dispatch( fetchSubscriptionRelatedInformation( workspaceID ) );
				return response.response;
			} ),
		meta: { workspaceID }
	} );
};

export const cancelSubscription = ( workspaceID, subscriptionID ) => ( dispatch, _, api ) => {
	trackSubscriptionCancelled();

	return dispatch( {
		type: CANCEL_SUBSCRIPTION,
		promise: catchNotAllowedResponseWrapper( api.post(
			`${WORKSPACES_URL}/${workspaceID}/cancel_subscription` ), dispatch, workspaceID
		),
		meta: { workspaceID, subscriptionID }
	} );
};

export const updateWorkspaceBillingInformation = (
	workspaceID, billingInformation
) => ( dispatch, _, api ) => {
	const parameters = serializeBillingInformation( billingInformation );

	return dispatch( {
		type: UPDATE_WORKSPACE_BILLING_INFORMATION,
		promise: catchNotAllowedResponseWrapper( api.patch(
			`${WORKSPACES_URL}/${workspaceID}/billing_information`, parameters ), dispatch, workspaceID
		)
			.then( ( response ) => {
				trackWorkspaceBillingEdit();
				return response.response;
			} ),
		meta: { workspaceID }
	} );
};

export const updateWorkspacePaymentMethod = (
	workspaceID, paymentMethodID
) => ( dispatch, _, api ) => {
	const parameters = {
		payment_method_id: paymentMethodID
	};

	return dispatch( {
		type: UPDATE_WORKSPACE_PAYMENT_METHOD,
		promise: catchNotAllowedResponseWrapper( api.post(
			`${WORKSPACES_URL}/${workspaceID}/payment_method`, parameters ), dispatch, workspaceID
		)
			.then( response => response.response ),
		meta: { workspaceID }
	} );
};

export const fetchWorkspaceSettingsInformation = workspace => ( dispatch ) => {
	const addOnSystem = getAddOnSystem( dispatch );
	const { id: workspaceID, pricingPlan } = workspace;

	return dispatch( {
		type: FETCH_WORKSPACE_SETTINGS_INFORMATION,
		promise: Promise.all( [
			dispatch( fetchWorkspaceMembersAndInvitations( workspaceID ) ),
			dispatch( fetchWorkspaceProjects( workspaceID ) ),
			dispatch( fetchWorkspaceBillingInformation( workspaceID ) ),
			dispatch( fetchWorkspacePaymentMethod( workspaceID ) ),
			addOnSystem.fetchPlanAddOns( { pricingPlan } )
		] )
	} );
}

export const reactivateSubscription = (
	workspaceID, subscriptionID
) => ( dispatch, _, api ) => dispatch( {
	type: REACTIVATE_SUBSCRIPTION,
	promise: catchNotAllowedResponseWrapper( api.post(
		`${WORKSPACES_URL}/${workspaceID}/reactivate_subscription` ), dispatch, workspaceID
	)
		.then( ( response ) => {
			trackSubscriptionReactivated();
			return response;
		} ),
	meta: { workspaceID, subscriptionID }
} );

export const downgradeSubscription = workspace => ( dispatch, _, api ) => {
	const workspaceID = workspace.id;
	const parameters = {
		price_id: workspace.pricingPlanPrice.id
	};

	return dispatch( {
		type: DOWNGRADE_SUBSCRIPTION,
		promise: catchNotAllowedResponseWrapper( api.post(
			`${WORKSPACES_URL}/${workspaceID}/downgrade_subscription`, parameters ), dispatch, workspaceID
		)
			.then( response => response.response ),
		meta: { workspaceID }
	} );
};

export const cancelDowngradeSubscription = workspaceID => ( dispatch, _, api ) => dispatch( {
	type: CANCEL_DOWNGRADE_SUBSCRIPTION,
	promise: catchNotAllowedResponseWrapper( api.post(
		`${WORKSPACES_URL}/${workspaceID}/cancel_downgrade` ), dispatch, workspaceID
	)
		.then( response => response.response ),
	meta: { workspaceID }
} );

export const leaveWorkspace = workspaceID => ( dispatch, _, api ) => dispatch( {
	type: LEAVE_WORKSPACE,
	promise: catchNotAllowedResponseWrapper( api.post(
		`${WORKSPACES_URL}/${workspaceID}/leave` ), dispatch, workspaceID
	)
		.then( ( response ) => {
			trackSubscriptionReactivated();
			return response;
		} ),
	meta: { workspaceID }
} );
