import PropTypes from 'prop-types';
import { Line as KonvaLine } from 'react-konva';
import tinycolor from 'tinycolor2';

const MarkupLine = ( {
	line, scale, opacity, ...props
} ) => (
	<KonvaLine
		points={[
			line.startPoint.x * scale.x,
			line.startPoint.y * scale.y,
			line.endPoint.x * scale.x,
			line.endPoint.y * scale.y
		]}
		stroke={tinycolor( line.strokeColor ).setAlpha( opacity ).toRgbString()}
		strokeWidth={line.strokeSize || 1}
		hitStrokeWidth={20}
		perfectDrawEnabled={false}
		lineJoin="round"
		lineCap="round"
		{...props}
	/>
);

MarkupLine.propTypes = {
	line: PropTypes.any.isRequired,
	scale: PropTypes.any.isRequired,
	opacity: PropTypes.number
};

MarkupLine.defaultProps = {
	opacity: 1
};

export default MarkupLine;
