export class TasksPermissions {
	constructor(
		see,
		add
	) {
		this.see = see;
		this.add = add;
	}
}

export const ownerTasksPermissions = new TasksPermissions( true, true );
export const projectCollaboratorTasksPermissions = new TasksPermissions( true, true );
export const projectGuestTasksPermissions = new TasksPermissions( true, true );
