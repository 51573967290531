import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import './NavigationLogo.scss';
import VeryBusyLogo from '../../VeryBusyLogo/VeryBusyLogo';

const NavigationLogo = ( { isClickeable, linkURL } ) => (
	<Link className={classNames( 'NavigationLogo', { isClickeable } )} to={linkURL || '/'}>
		<VeryBusyLogo />
	</Link>
);

NavigationLogo.propTypes = {
	linkURL: PropTypes.string,
	isClickeable: PropTypes.bool
};

NavigationLogo.defaultProps = {
	linkURL: undefined,
	isClickeable: true
};

export default NavigationLogo;
