import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import Button from '../../../new_arch/components/Button/Button';

const LinkButton = ( {
	to, children, onClick, ...props
} ) => {
	const history = useHistory();
	const handleLink = () => {
		history.push( to );
		onClick();
	};

	return (
		<div className="LinkButton">
			<Button {...props} onClick={handleLink}>
				{children}
			</Button>
		</div>
	);
};

LinkButton.propTypes = {
	to: PropTypes.oneOfType( [ PropTypes.string, PropTypes.object, PropTypes.func ] ).isRequired,
	children: PropTypes.node.isRequired,
	onClick: PropTypes.func
};

LinkButton.defaultProps = {
	onClick: () => {}
};

export default LinkButton;
