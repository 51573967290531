import { makeAutoObservable } from 'mobx';
import { type PaymentService } from '../../services/PaymentService';
import Coupon from '../../entities/coupon';
import PricingPlanPrice from '../../entities/pricing/pricingPlanPrice';

type PromoCodeInputPresenterParams = {
	onPromoCodeApplied: ( appliedPromoCodeID: string | null, appliedCoupon: Coupon | null ) => void;
	paymentService: PaymentService;
	pricingPlanPrice: PricingPlanPrice;
}

export default class PromoCodeInputPresenter {
	private readonly onPromoCodeApplied: ( appliedPromoCodeID: string | null, appliedCoupon: Coupon | null ) => void;
	private readonly paymentService: PaymentService;
	private readonly pricingPlanPrice: PricingPlanPrice;
	private coupon: Coupon | null;
	promoCode = '';
	loading = false;
	error = '';
	isApplied = false;

	constructor( { onPromoCodeApplied, paymentService, pricingPlanPrice }: PromoCodeInputPresenterParams ) {
		this.onPromoCodeApplied = onPromoCodeApplied;
		this.paymentService = paymentService;
		this.pricingPlanPrice = pricingPlanPrice;
		this.coupon = null;

		makeAutoObservable( this );
	}

	get applyButtonDisabled() {
		return !this.promoCode;
	}

	get infoMessage() {
		return this.isApplied ? this.successMessage : this.defaultInfoMessage;
	}

	onDeleteClick = () => {
		this.setCurrentPromoCodeTo( '' );
		this.updateAppliedPromoCode( null, null );
	}

	onPromoCodeChange = ( promoCode: string ) => {
		this.setCurrentPromoCodeTo( promoCode );
	}

	onApplyClick = async () => {
		this.startLoader();
		try {
			const { id: promoCodeID, coupon } = await this.paymentService.validatePromoCode( this.promoCode );
			this.updateAppliedPromoCode( promoCodeID, coupon );
			this.clearError();
		} catch ( error ) {
			this.setError( error instanceof Error ? error.message : undefined );
		} finally {
			this.stopLoader();
		}
	}

	private get defaultInfoMessage() {
		return 'Make sure to apply the code before continuing';
	}

	private get successMessage() {
		return `Promo code of ${this.coupon?.discountToDisplay} applied successfully. ${this.coupon?.durationToDisplayFor( this.pricingPlanPrice )}.`;
	}

	private setError = ( errorMessage = 'Promo code not valid' ) => {
		this.error = errorMessage;
	}

	private clearError = () => {
		this.setError( '' );
	}

	private startLoader = () => {
		this.loading = true;
	}

	private stopLoader = () => {
		this.loading = false;
	}

	private setCurrentPromoCodeTo = ( newPromoCode: string ) => {
		this.promoCode = newPromoCode;
	}

	private updateAppliedPromoCode = ( appliedPromoCodeID: string | null, appliedCoupon: Coupon | null ) => {
		this.onPromoCodeApplied( appliedPromoCodeID, appliedCoupon );
		this.isApplied = !!this.promoCode;
		this.coupon = appliedCoupon;
	}
}
