/* eslint-disable no-alert */
import InvalidExtensionError from '../../customErrors/InvalidExtensionError';
import StorageLimitError from '../../customErrors/StorageLimitError';
import PopupFactory from '../../factories/PopupFactory';
import FileExtensionValidatorFactory from '../../factories/FileExtensionValidatorFactory';
import DuplicateFolderNamesValidator from '../../validators/duplicateFolderNamesValidator';
import StorageLimitValidator from '../../validators/storageLimitValidator';
import ValidatorComposer from '../../validators/validatorComposer';
import DuplicateFolderNamesError from '../../customErrors/DuplicateFolderNamesError';
import { store } from '../../store/store';
import { getFolderNamesForOrganizer } from '../../selectors/folders';
import { trackAssetsUploaded } from '../../services/tracker/trackers/assets';

const folderNamesForOrganizerFromState = ( project, folder ) => getFolderNamesForOrganizer(
	store.getState(),
	{
		organizerID: folder?.id || project.id,
		organizerType: folder ? 'Folder' : 'Project'
	}
);

export default class AssetUploadPresenter {
	constructor( {
		fileSelection, project, folder, assetID,
		createFileUploads, createFolderUploads,
		fromDragAndDrop = false,
		uploadingImage, uploadingDocument, uploadingRoundForAsset,
		popupSystem, openEmailClient, history,
		folderNamesForOrganizer = folderNamesForOrganizerFromState( project, folder ),
		extensionValidator = FileExtensionValidatorFactory.forAssets()
	} ) {
		this._fileSelection = fileSelection;
		this._project = project;
		this._folder = folder;
		this._assetID = assetID;
		this._createFileUploads = createFileUploads;
		this._createFolderUploads = createFolderUploads;
		this._fromDragAndDrop = fromDragAndDrop;
		this._uploadingImage = uploadingImage;
		this._uploadingDocument = uploadingDocument;
		this._uploadingRoundForAsset = uploadingRoundForAsset;
		this._popupSystem = popupSystem;
		this._openEmailClient = openEmailClient;
		this._history = history;
		this._folderNamesForOrganizer = folderNamesForOrganizer;
		this._extensionValidator = extensionValidator;
	}

	trigger() {
		this._fileSelection.removeJunkFiles();

		try {
			this._filesValidator.validate( this._fileSelection.filesFromAllLevels );
			this._directoryNamesValidator.validate( this._fileSelection.directoryNames );
			this._createUploadsForSelectedFiles();
			this._createUploadsForSelectedDirectories();
			this._trackItemsUpload();
		} catch ( error ) {
			this._handleUploadError( error );
		}
	}

	get _storageLimit() {
		return this._workspace.storageAvailable;
	}

	get _projectID() {
		return this._project.id;
	}

	get _folderID() {
		return this._folder?.id;
	}

	get _organizerID() {
		return this._folder ? this._folderID : this._projectID;
	}

	get _organizerType() {
		return this._folder ? 'Folder' : 'Project';
	}

	get _workspace() {
		return this._project.workspace;
	}

	get _workspaceOwner() {
		return this._workspace.owner;
	}

	get _currentUserIsOwner() {
		return this._workspace.currentUserIsOwner;
	}

	get _filesValidator() {
		return new ValidatorComposer( [
			this._extensionValidator,
			new StorageLimitValidator( this._storageLimit )
		] );
	}

	get _directoryNamesValidator() {
		return new DuplicateFolderNamesValidator( { existingFolderNames: this._folderNamesForOrganizer } )
	}

	_createUploadsForSelectedFiles() {
		this._createFileUploads( {
			projectID: this._projectID,
			folderID: this._folderID,
			files: this._fileSelection.files,
			assetID: this._assetID,
			uploadingRoundForAsset: this._uploadingRoundForAsset
		} );
	}

	_createUploadsForSelectedDirectories() {
		this._createFolderUploads( {
			projectID: this._projectID,
			organizerID: this._organizerID,
			organizerType: this._organizerType,
			directories: this._fileSelection.directories
		} );
	}

	_handleUploadError( error ) {
		if ( error instanceof InvalidExtensionError ) {
			PopupFactory.unsupportedFileFormatsPopup( {
				popupSystem: this._popupSystem, unsupportedFormats: error.invalidExtensions
			} );
		} else if ( error instanceof DuplicateFolderNamesError ) {
			PopupFactory.duplicateFolderNamesPopup( { popupSystem: this._popupSystem } );
		} else if ( error instanceof StorageLimitError ) {
			PopupFactory.storageLimitReachedPopup( {
				popupSystem: this._popupSystem, workspace: this._workspace
			} ).then( this._handlePopupButtonClick );
		} else {
			throw error;
		}
	}

	_handlePopupButtonClick = ( buttonWasClicked ) => {
		if ( !buttonWasClicked ) return;

		if ( this._currentUserIsOwner ) { this._upgradeWasClicked(); return; }
		this._ownerEmailWasClicked();
	}

	_upgradeWasClicked() {
		this._history.replace( { pathname: `/user/workspaces/${this._workspace.id}/plan`, state: { showSubscriptionModal: true } } );
	}

	_ownerEmailWasClicked() {
		this._openEmailClient( this._workspaceOwner.email );
	}

	_trackItemsUpload() {
		trackAssetsUploaded( {
			assetsCount: this._fileSelection.totalFilesCount,
			assetsFileTypesCount: this._fileSelection.filesExtensionsCount
		} );
	}
}
