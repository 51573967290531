import { Map } from 'immutable';
import { handle } from 'redux-pack';
import { normalizeResponse } from '../lib/normalizeUtils';
import { comment as commentScheme, commentReply as commentReplyScheme } from './schemes';
import { makeAsyncActionReducer, handleSuccess, handleStart } from '../lib/reduxUtils';
import {
	FETCH_ASSET_ROUND_COMMENTS,
	CREATE_COMMENT_FOR_ASSET,
	SET_CURRENT_ACTIVE_MARKUP_COMMENT,
	CLEAR_CURRENT_ACTIVE_MARKUP_COMMENT,
	DELETE_COMMENT,
	LOGOUT,
	UPDATE_MARKUP_COMMENT,
	START_WRITING_COMMENT,
	STOP_WRITING_COMMENT,
	MARK_COMMENT_AS_COMPLETED,
	UNMARK_COMMENT_AS_COMPLETED,
	SET_MARKUP_EDITING_TOOL,
	SET_HOVERED_COMMENT,
	CLEAR_HOVERED_COMMENT,
	FETCH_ASSET_COMMENTS
} from '../actions/types';

const commentHasMatchingProperty = property => ( comment, value ) => (
	comment[ property ] === value
);

const commentBelongsToRound = commentHasMatchingProperty( 'version_id' );

const commentBelongsToAsset = commentHasMatchingProperty( 'asset_id' );

const addReplyToComment = ( comment, replyID ) => (
	{ ...comment, replies: [ ...comment.replies, replyID ] }
);
const removeReplyFromComment = ( comment, replyID ) => (
	{ ...comment, replies: comment.replies.filter( id => id !== replyID ) }
);

const markCommentAsCompleted = ( comment, completedAt = new Date().toISOString() ) => (
	{ ...comment, completed_at: completedAt }
);
const unmarkCommentAsCompleted = comment => (
	{ ...comment, completed_at: null }
);

export const fetchAssetCommentsRequest = makeAsyncActionReducer( FETCH_ASSET_COMMENTS );
export const fetchAssetRoundCommentsRequest = makeAsyncActionReducer( FETCH_ASSET_ROUND_COMMENTS );
export const createCommentForAssetRequest = makeAsyncActionReducer( CREATE_COMMENT_FOR_ASSET );
export const updateMarkupCommentRequest = makeAsyncActionReducer( UPDATE_MARKUP_COMMENT );
export const markCommentAsCompletedRequest = makeAsyncActionReducer( MARK_COMMENT_AS_COMPLETED );
export const unmarkCommentAsCompletedRequest = makeAsyncActionReducer(
	UNMARK_COMMENT_AS_COMPLETED
);

const isCommentReply = comment => !!comment.parent_id;

const normalizeComments = ( prevState, actionPayload ) => normalizeResponse(
	prevState, actionPayload, commentScheme, 'comments'
);

const createComment = ( newComment, prevState ) => (
	isCommentReply( newComment )
		? prevState.updateIfSet(
			newComment.parent_id,
			comment => addReplyToComment( comment, newComment.id )
		)
		: normalizeComments( prevState, newComment )
);

const removeComment = ( comment, prevState ) => (
	isCommentReply( comment )
		? prevState.updateIfSet(
			comment.parent_id,
			parentComment => removeReplyFromComment( parentComment, comment.id )
		)
		: prevState.delete( comment.id )
);

export const comments = ( state = new Map(), action ) => {
	switch ( action.type ) {
	case FETCH_ASSET_COMMENTS:
		return handleSuccess(
			state,
			action,
			prevState => normalizeComments(
				prevState.filter(
					comment => !commentBelongsToAsset( comment, action.payload.assetID )
				),
				action.payload.comments
			)
		);
	case FETCH_ASSET_ROUND_COMMENTS:
		return handleSuccess(
			state,
			action,
			prevState => normalizeComments(
				prevState.filter(
					comment => !commentBelongsToRound( comment, action.payload.roundID )
				),
				action.payload.comments
			)
		);
	case CREATE_COMMENT_FOR_ASSET:
		return handle(
			state,
			action,
			{
				start: prevState => createComment( action.meta.tempComment, prevState ),
				success: prevState => createComment(
					action.payload,
					removeComment( action.meta.tempComment, prevState )
				),
				failure: prevState => removeComment( action.meta.tempComment, prevState )
			}
		);
	case LOGOUT:
		return handleStart( state, action, () => new Map() );
	case UPDATE_MARKUP_COMMENT:
		return handleSuccess(
			state,
			action,
			prevState => (
				!isCommentReply( action.payload )
					? normalizeComments( prevState, {
						asset_id: action.meta.asset_id,
						...action.payload
					} )
					: prevState
			) );
	case MARK_COMMENT_AS_COMPLETED:
		return handle( state, action, {
			start: prevState => prevState.update(
				action.meta.commentID,
				comment => markCommentAsCompleted( comment )
			),
			failure: prevState => prevState.update(
				action.meta.commentID,
				comment => unmarkCommentAsCompleted( comment )
			),
			success: prevState => prevState.update(
				action.payload.id,
				comment => markCommentAsCompleted( comment, action.payload.completed_at )
			)
		} );
	case UNMARK_COMMENT_AS_COMPLETED:
		return handle( state, action, {
			start: prevState => prevState.update(
				action.meta.commentID,
				comment => unmarkCommentAsCompleted( comment )
			),
			failure: prevState => prevState.update(
				action.meta.commentID,
				comment => markCommentAsCompleted( comment, action.meta.originalCompletedAt )
			),
			success: prevState => prevState.update(
				action.payload.id,
				comment => unmarkCommentAsCompleted( comment )
			)
		} );
	case DELETE_COMMENT:
		return handleSuccess(
			state,
			action,
			prevState => (
				!action.meta.parentID
					? prevState.delete( action.payload )
					: prevState.updateIfSet(
						action.meta.parentID,
						comment => removeReplyFromComment( comment, action.payload )
					)
			)
		);
	default:
		return state;
	}
};

const normalizeCommentReplies = ( prevState, actionPayload ) => normalizeResponse(
	prevState, actionPayload, commentReplyScheme, 'commentReplies'
);

const normalizeRepliesForComments = ( prevState, actionPayload ) => normalizeResponse(
	prevState, actionPayload, commentScheme, 'commentReplies'
);

const createReply = ( newReply, prevState ) => (
	isCommentReply( newReply )
		? normalizeCommentReplies( prevState, newReply )
		: prevState
);

const removeReply = ( reply, prevState ) => (
	isCommentReply( reply )
		? prevState.delete( reply.id )
		: prevState
);

export const commentsReplies = ( state = new Map(), action ) => {
	switch ( action.type ) {
	case FETCH_ASSET_COMMENTS:
		return handleSuccess(
			state,
			action,
			prevState => normalizeRepliesForComments(
				prevState.filter(
					comment => !commentBelongsToAsset( comment, action.payload.assetID )
				),
				action.payload.comments
			)
		);
	case FETCH_ASSET_ROUND_COMMENTS:
		return handleSuccess(
			state,
			action,
			prevState => normalizeRepliesForComments(
				prevState.filter(
					reply => !commentBelongsToRound( reply, action.payload.roundID )
				),
				action.payload.comments
			)
		);
	case CREATE_COMMENT_FOR_ASSET:
		return handle(
			state,
			action,
			{
				start: prevState => createReply( action.meta.tempComment, prevState ),
				success: prevState => createReply(
					action.payload,
					removeReply( action.meta.tempComment, prevState )
				),
				failure: prevState => removeReply( action.meta.tempComment, prevState )
			}
		);
	case UPDATE_MARKUP_COMMENT:
		return handleSuccess(
			state,
			action,
			prevState => (
				isCommentReply( action.payload )
					? normalizeCommentReplies( prevState, action.payload )
					: prevState
			)
		);
	case LOGOUT:
		return handleStart( state, action, () => new Map() );
	case DELETE_COMMENT:
		return handleSuccess(
			state,
			action,
			prevState => prevState.delete( action.payload )
		);
	default:
		return state;
	}
};

export const currentActiveCommentInitialState = new Map( [
	[ 'commentID', null ],
	[ 'isEditing', false ]
] );
export const currentActiveMarkupComment = ( state = currentActiveCommentInitialState, action ) => {
	switch ( action.type ) {
	case SET_CURRENT_ACTIVE_MARKUP_COMMENT: {
		const { commentID, isEditing, sourceIsComment } = action.payload;
		if ( state.get( 'commentID' ) === commentID && !isEditing ) {
			return currentActiveCommentInitialState;
		}
		return currentActiveCommentInitialState
			.set( 'commentID', commentID )
			.set( 'isEditing', isEditing )
			.set( 'sourceIsComment', sourceIsComment );
	}
	case SET_MARKUP_EDITING_TOOL:
		return currentActiveCommentInitialState;
	case CLEAR_CURRENT_ACTIVE_MARKUP_COMMENT:
		return currentActiveCommentInitialState;
	case DELETE_COMMENT:
	case UPDATE_MARKUP_COMMENT:
		return handleSuccess(
			state,
			action,
			() => currentActiveCommentInitialState
		);
	default:
		return state;
	}
};

export const isWritingComment = ( state = false, action ) => {
	switch ( action.type ) {
	case START_WRITING_COMMENT:
		return true;
	case STOP_WRITING_COMMENT:
		return false;
	default:
		return state;
	}
};

export const hoveredCommentID = ( state = null, action ) => {
	switch ( action.type ) {
	case SET_HOVERED_COMMENT:
		return action.payload.commentID;
	case CLEAR_HOVERED_COMMENT:
		return action.payload.commentID === state ? null : state;
	default:
		return state;
	}
};
