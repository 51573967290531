import { useCallback } from 'react';
import PropTypes from 'prop-types';
import UserProfileSideBarItem from '../UserProfileSideBarItem/UserProfileSideBarItem';
import WorkspacesSection from '../WorkspacesSection/WorkspacesSection';
import User from '../../entities/user';
import Workspace from '../../entities/workspace';

import './MyProfileSideBar.scss';

const MyProfileSideBar = ( {
	currentUser, profilePanelSelected, selectedWorkspaceID,
	onProfileItemClick, onWorkspaceItemClick,
	ownedWorkspaces, memberWorkspaces, showCreateWorkspaceButton,
	onCreateWorkspaceClick
} ) => {
	const onWorkspaceItemClicked = useCallback(
		workspaceID => () => onWorkspaceItemClick( workspaceID ),
		[]
	);

	return (
		<div className="MyProfileSideBar">
			<div className="sidebar-title">
				<h2>
					Settings
				</h2>
			</div>
			<div className="body">
				<div className="profile-item">
					<UserProfileSideBarItem
						user={currentUser}
						selected={profilePanelSelected}
						onClick={onProfileItemClick}
					/>
				</div>
				<WorkspacesSection
					className="my-workspace"
					title="MY WORKSPACE"
					workspaces={ownedWorkspaces}
					selectedWorkspaceID={selectedWorkspaceID}
					onWorkspaceClick={onWorkspaceItemClicked}
					currentUserID={currentUser.id}
					showCreateWorkspaceButton={showCreateWorkspaceButton}
					onCreateWorkspaceClick={onCreateWorkspaceClick}
				/>
				{memberWorkspaces.length > 0
					&& (
						<WorkspacesSection
							className="workspaces"
							title="OTHER WORKSPACES"
							workspaces={memberWorkspaces}
							selectedWorkspaceID={selectedWorkspaceID}
							onWorkspaceClick={onWorkspaceItemClicked}
							currentUserID={currentUser.id}
						/>
					)}
			</div>
		</div>
	);
};

MyProfileSideBar.propTypes = {
	currentUser: PropTypes.instanceOf( User ).isRequired,
	profilePanelSelected: PropTypes.bool,
	showCreateWorkspaceButton: PropTypes.bool,
	selectedWorkspaceID: PropTypes.number,
	onProfileItemClick: PropTypes.func,
	onWorkspaceItemClick: PropTypes.func,
	onCreateWorkspaceClick: PropTypes.func,
	ownedWorkspaces: PropTypes.arrayOf( PropTypes.instanceOf( Workspace ) ),
	memberWorkspaces: PropTypes.arrayOf( PropTypes.instanceOf( Workspace ) )
};

MyProfileSideBar.defaultProps = {
	ownedWorkspaces: [],
	memberWorkspaces: [],
	profilePanelSelected: false,
	showCreateWorkspaceButton: false,
	selectedWorkspaceID: undefined,
	onProfileItemClick: () => {},
	onWorkspaceItemClick: () => {},
	onCreateWorkspaceClick: () => {}
};

export default MyProfileSideBar;
