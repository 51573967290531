import { PROJECTS_URL } from '../config/urls';
import {
	FETCH_PROJECT_MEMBERS, INVITE_PROJECT_MEMBER, DELETE_PROJECT_MEMBER
} from './types';
import { trackInvitedProjectMembers } from '../services/tracker/trackers/projects';
import { getProject } from '../selectors/projects';

export const fetchProjectMembers = projectID => ( dispatch, _, api ) => dispatch( {
	type: FETCH_PROJECT_MEMBERS,
	promise: api
		.get( `${PROJECTS_URL}/${projectID}/members` )
		.then( response => response.response ),
	meta: { projectID }
} );

export const deleteProjectMember = ( projectID, memberID ) => ( dispatch, _, api ) => dispatch( {
	type: DELETE_PROJECT_MEMBER,
	promise: api
		.delete( `${PROJECTS_URL}/${projectID}/members/${memberID}` ),
	meta: { memberID }
} );

export const inviteMembersToProject = (
	projectID, emails, role, message
) => ( dispatch, getState, api ) => {
	const project = getProject( getState(), { projectID } );
	trackInvitedProjectMembers( { project, emails } );

	return dispatch( {
		type: INVITE_PROJECT_MEMBER,
		promise: api
			.post(
				`${PROJECTS_URL}/${projectID}/members/invite`,
				{ invitees: emails, role, message }
			)
			.then( () => projectID )
	} );
};
