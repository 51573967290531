import { Map } from 'immutable';
import { handleSuccess, makeAsyncActionReducer } from '../lib/reduxUtils';
import {
	FETCH_WORKSPACES, FETCH_PRICING_PLANS, LOGOUT
} from '../actions/types';
import { normalizeResponse } from '../lib/normalizeUtils';
import { pricingPlan as pricingPlanScheme } from './schemes';

export const fetchPricingPlansRequest = makeAsyncActionReducer( FETCH_PRICING_PLANS );

const normalizePricingPlans = ( actionPayload, prevState ) => normalizeResponse(
	prevState, actionPayload, pricingPlanScheme, 'pricingPlans'
);

const normalizePricingPlansFromWorkspaces = ( workspaces, prevState ) => {
	const subscriptions = workspaces
		.map( workspace => workspace.subscription )
		.filter( subscription => !!subscription && !!subscription.pricing_plan_price );
	const pricingPlans = subscriptions
		.map( subscription => subscription.pricing_plan_price
			&& subscription.pricing_plan_price.pricing_plan );
	return normalizePricingPlans( pricingPlans, prevState );
};

export const pricingPlans = ( state = Map(), action ) => {
	switch ( action.type ) {
	case FETCH_PRICING_PLANS:
		return handleSuccess(
			state,
			action,
			() => normalizePricingPlans( action.payload, Map() )
		);
	case FETCH_WORKSPACES:
		return handleSuccess(
			state,
			action,
			prevState => normalizePricingPlansFromWorkspaces( action.payload, prevState )
		);
	case LOGOUT:
		return Map();
	default:
		return state;
	}
};
