import { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { connectComponent } from '../../lib/connectComponent';
import { renderOrRedirect } from '../../lib/renderUtils';

import MyProfileSideBar from '../../components/MyProfileSideBar/MyProfileSideBar';
import UserProfilePanelContainer from '../../containers/UserProfilePanelContainer/UserProfilePanelContainer';
import WorkspacePanelContainer from '../../containers/WorkspacePanelContainer/WorkspacePanelContainer';

import { getCurrentUser } from '../../selectors/currentUser';
import {
	getCurrentUserOwnedWorkspaces, getCurrentUserMemberWorksapces, getWorkspaces
} from '../../selectors/workspaces';

import User from '../../entities/user';
import Workspace from '../../entities/workspace';

import './UserSettings.scss';
import ModalSystem, { SUBSCRIPTION_MODAL_KEY } from '../../systems/ModalSystem';

export const DEFAULT_PATH = '/user';
export const WORKSPACE_PATH = '/user/workspaces/:workspaceID/:section*';
export const PROFILE_PATH = '/user/profile/:section*';

export const UserSettings = ( {
	currentUser, workspaces, ownedWorkspaces, memberWorkspaces,
	history, fetchCurrentUserWorkspaces, selectedWorkspaceID,
	modalSystem
} ) => {
	const userOwnsAWorkspace = ownedWorkspaces.length > 0;

	const onProfileItemClicked = useCallback(
		() => history.replace( '/user/profile' ),
		[ history ]
	);

	const onWorkspaceItemClicked = useCallback(
		workspaceID => history.replace( `/user/workspaces/${workspaceID}` ),
		[ history ]
	);

	useEffect( () => {
		fetchCurrentUserWorkspaces();
	}, [] );

	const createWorkspace = () => {
		modalSystem.showModal( { modalKey: SUBSCRIPTION_MODAL_KEY } );
	};

	return (
		<div className="UserSettings">
			<MyProfileSideBar
				currentUser={currentUser}
				ownedWorkspaces={ownedWorkspaces}
				memberWorkspaces={memberWorkspaces}
				onProfileItemClick={onProfileItemClicked}
				onWorkspaceItemClick={onWorkspaceItemClicked}
				showCreateWorkspaceButton={!userOwnsAWorkspace}
				profilePanelSelected={!selectedWorkspaceID}
				selectedWorkspaceID={selectedWorkspaceID}
				onCreateWorkspaceClick={createWorkspace}
			/>
			{selectedWorkspaceID
				? renderOrRedirect( {
					redirectIf: !workspaces.some( workspace => workspace.id === selectedWorkspaceID ),
					redirectTo: '/user/profile',
					component: <WorkspacePanelContainer workspaceID={selectedWorkspaceID} />
				} )
				: <UserProfilePanelContainer />}
		</div>
	);
};

UserSettings.propTypes = {
	history: PropTypes.any.isRequired,
	selectedWorkspaceID: PropTypes.number,
	currentUser: PropTypes.instanceOf( User ).isRequired,
	workspaces: PropTypes.arrayOf( PropTypes.instanceOf( Workspace ) ),
	ownedWorkspaces: PropTypes.arrayOf( PropTypes.instanceOf( Workspace ) ),
	memberWorkspaces: PropTypes.arrayOf( PropTypes.instanceOf( Workspace ) ),
	fetchCurrentUserWorkspaces: PropTypes.func,
	modalSystem: PropTypes.instanceOf( ModalSystem ).isRequired
};

UserSettings.defaultProps = {
	selectedWorkspaceID: undefined,
	workspaces: [],
	ownedWorkspaces: [],
	memberWorkspaces: [],
	fetchCurrentUserWorkspaces: undefined
};

export default connectComponent( state => ( {
	currentUser: getCurrentUser( state ),
	workspaces: getWorkspaces( state ),
	ownedWorkspaces: getCurrentUserOwnedWorkspaces( state ),
	memberWorkspaces: getCurrentUserMemberWorksapces( state )
} ) )( UserSettings );
