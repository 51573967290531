import PropTypes from 'prop-types';
import { ITEM_UPLOAD_STATUS } from '../services/DirectFilesUploaderCoordinator';

export const itemUploadPropType = PropTypes.shape( {
	id: PropTypes.oneOfType( [ PropTypes.number, PropTypes.string ] ),
	projectID: PropTypes.number,
	progress: PropTypes.number,
	status: PropTypes.oneOf( Object.values( ITEM_UPLOAD_STATUS ) ),
	name: PropTypes.string
} );
