import { Navigate } from '../../../navigation/useNavigate';

export default class Workspace {
	public id: number;
	public name: string;

	constructor( { id, name } : { id: number, name: string } ) {
		this.id = id;
		this.name = name;
	}

	static fromJson( json : { id: number, name: string } ) {
		return new this( json );
	}

	navigateToScreenWith( navigate : Navigate, state: unknown ) {
		navigate.toWorkspace( this.id, state )
	}
}
