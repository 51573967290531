import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import Button from '../../new_arch/components/Button/Button';
import IconButton from '../../components/UI/IconButton/IconButton';
import Checkbox from '../../components/UI/Checkbox/Checkbox';
import { PRIMARY_BASE } from '../../styles/colors';

import './LabelsGroupsModal.scss';
import useLabelsGroupsModalPresenter from '../../presenters/LabelsGroupsModalPresenter/useLabelsGroupsModalPresenter';
import { type LabelGroup } from '../../presenters/LabelsGroupsModalPresenter/LabelsGroupsModalPresenter';

type LabelsGroupsModalProps = {
	projectID: number;
	onClose: () => void;
	onConfirm: () => void;
}

const LabelsGroupsModal = ( { projectID, onClose = () => {}, onConfirm } : LabelsGroupsModalProps ) => {
	const presenter = useLabelsGroupsModalPresenter( projectID );

	useEffect( () => {
		presenter.onMount().then( () => presenter.setLoading( false ) );
	}, [] );

	const uncheckAllGroups = () => {
		presenter.uncheckAllGroups();
	};

	const onModalClosed = () => {
		onClose();
		uncheckAllGroups();
	}

	const onConfirmClicked = () => {
		presenter.addLabelsFromGroups();
		onConfirm();
		onModalClosed();
	}

	const { workspaceID } = presenter;

	const linkToManageGroups = () => (
		<a className="linkToSettings" href={`/user/workspaces/${workspaceID}/projects`}>workspace settings</a>
	);

	const renderActions = () => (
		<div className={classNames( 'actions', { 'no-groups': !presenter.thereAreGroups } )}>
			{presenter.thereAreGroups && !presenter.isLoading && (
				<Button
					className="cancel-button"
					type="text"
					onClick={onModalClosed}
				>
					Cancel
				</Button>
			)}
			<Button
				className="confirm-button"
				type="text"
				onClick={onConfirmClicked}
			>
				{( presenter.thereAreGroups && !presenter.isLoading ) ? 'Add' : 'Got it'}
			</Button>
		</div>
	);

	const renderLabelsGroups = () => (
		<div className="GroupsList">
			{presenter.getLabelsGroups.map( ( group : LabelGroup ) => {
				const res = (
					<div className="GroupItem" key={group.id}>
						<Checkbox
							key={group.name}
							label={group.name}
							onChange={() => { presenter.toggleGroupCheck( { groupID: group.id } ) }}
							value={presenter.isGroupChecked( { groupID: group.id } )}
							checkedColor={PRIMARY_BASE}
						/>
					</div>
				)
				return res;
			} ) }
		</div>
	);

	const renderEmptyState = () => (
		<div className="emptyState">
			<p>There aren&apos;t any groups available.</p>
			{presenter.userCanManageLabelsGroups
				? <p>Create or modify from {linkToManageGroups()}</p>
				: <p>Only owners and members can create or modify from workspace settings</p>}
		</div>
	);

	const renderHeader = () => (
		<div
			className={classNames( 'modalHeader',
				{
					'no-groups': !presenter.thereAreGroups,
					'cannot-manage-groups': !presenter.userCanManageLabelsGroups
				}
			)}
		>
			<div className="title">
				<h4>Label Groups</h4>
				<IconButton size={24} onClick={onModalClosed} icon="close" color="black" />
			</div>
		</div>
	);

	const renderContent = () => {
		if ( presenter.isLoading ) return ( <div className="loading"><p>Loading...</p></div> );
		return presenter.thereAreGroups ? renderLabelsGroups() : renderEmptyState();
	}

	const renderFooter = () => (
		<div className="modalFooter">
			{presenter.userCanManageLabelsGroups
				? <p>You can create of modify from {linkToManageGroups()}</p>
				: <p>Only owners and members can create or modify</p>}
		</div>
	);

	return (
		<div
			className="LabelsGroupsModal"
			data-testid="labels-groups-modal"
		>
			{renderHeader()}
			{renderContent()}
			{renderActions()}
			{presenter.thereAreGroups && renderFooter()}
		</div>
	);
}

export default observer( LabelsGroupsModal );
