export const PRIMARY_BASE = '#1c91ff';
export const PRIMARY_900 = '#2171dc';
export const PRIMARY_200 = '#e3f2ff';
export const SUCCESS = '#00c654';
export const SUCCESS_200 = '#e6f9ee';
export const SUCCESS_700 = '#009e43';
export const TEXT_GREY = '#7b7b7b';
export const WHITE = '#fff';
export const APP_BACKGROUND = '#f5f5f5';
export const SECONDARY_BASE = '#5f7292';
export const SECONDARY_PRESSED = '#384f6e';
export const MIDDLE_GREY = '#d9d9d9';
export const LIGHT_GREY = '#E9E9E9';
export const DARK_GREY = '#9d9d9d';
export const WARNING_BASE = '#fa973b';
export const WARNING_700 = '#f38837';
export const WARNING_200 = '#fff4e4';
export const ERROR = '#f85b58';
export const STRONG_ERROR = '#DD3938';
export const ERROR_LIGHT = '#ffecef';
export const BLACK = '#000000';
export const SECONDARY_DARK = '#061324';

export const NEUTRAL = '#B1B1B1';
export const NEUTRAL_200 = '#F5F5F5';
export const NEUTRAL_700 = '#9D9D9D';
export const NEUTRAL_800 = '#262626';
export const SECONDARY = '#DBE4F2';
export const SECONDARY_0_15 = 'rgba(6, 19, 36, 0.15)';

export const CORAL_BASE = '#F5C1CA';
export const CORAL_600 = '#F29FAE';
export const YELLOW_BASE = '#F9F2B4';
export const YELLOW_600 = '#F8ED91';
export const CLAY_BASE = '#FED2B2';
export const CLAY_600 = '#FCBA8A';
export const APPLE_BASE = '#D9EEB7';
export const APPLE_600 = '#CBE79F';
export const AQUA_BASE = '#C3F4F2';
export const AQUA_600 = '#9CEFEA';
export const BLUE_BASE = '#C0D9FF';
export const BLUE_600 = '#9DC4FF';
export const LILAC_BASE = '#DFD0FF';
export const LILAC_600 = '#C7AEFD';
export const PINK_BASE = '#F5CDF1';
export const PINK_600 = '#F1B6EB';
export const FUSCHIA_100 = '#EF5DA8';
export const DARK_GREEN = '#077A38';
export const GREEN_700 = '#008B3B';
export const STONE_BASE = '#E8F0FC';
export const STONE_600 = '#BFCEE3';
export const SILVER_BASE = '#E9E9E9';
export const SILVER_600 = '#D9D9D9';
