import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import FormInput from '../../new_arch/components/FormInput/FormInput';

import Field from '../../lib/forms/Field';
import MinWordCount from '../../lib/forms/validators/MinWordCount';

const BillingInput = ( {
	value,
	onChange,
	onValidationStatusChange,
	disabled,
	required,
	label,
	name,
	className,
	placeholder,
	errorMessage
} ) => {
	const [ hadFocusOnce, setHadFocusOnce ] = useState( false );

	const formField = new Field( name, label, value, [ new MinWordCount( 1 ) ] );

	const hasFormError = !!value && !formField.validate();

	const formError = hasFormError
		? formField.errors.first
		: null;

	const error = hadFocusOnce ? ( errorMessage || formError ) : null

	const isValid = !!value && !error;

	useEffect(
		() => onValidationStatusChange( isValid ),
		[ isValid ]
	);

	return (
		<FormInput
			className={className}
			name={name}
			value={value}
			onChange={onChange}
			placeholder={placeholder}
			error={error}
			required={required}
			onFocus={() => setHadFocusOnce( true )}
			disabled={disabled}
			label={label}
		/>
	);
};

BillingInput.propTypes = {
	value: PropTypes.any,
	onChange: PropTypes.func,
	onValidationStatusChange: PropTypes.func,
	disabled: PropTypes.bool,
	required: PropTypes.bool,
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	className: PropTypes.string.isRequired,
	placeholder: PropTypes.string.isRequired,
	errorMessage: PropTypes.string.isRequired
};

BillingInput.defaultProps = {
	value: '',
	onChange: () => {},
	onValidationStatusChange: () => {},
	disabled: false,
	required: false
};

export default BillingInput;
