import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';

import PricingSubscriptionPresenter from '../../presenters/PlanSettings/PricingSubscriptionPresenter';
import PlanCard from '../../components/subscription/PlanCard/PlanCard';
import PriceSwitch from '../../components/subscription/PriceSwitch/PriceSwitch';
import BillingPeriodTitle from '../../components/subscription/BillingPeriodTitle/BillingPeriodTitle';
import Loader from '../../components/Loader/Loader';
import PricingPlan from '../../entities/pricing/pricingPlan';
import { getPricingPlans } from '../../selectors/pricingPlans';
import { getWorkspace } from '../../selectors/workspaces';
import { connectComponent } from '../../lib/connectComponent';

import './PricingSubscriptionSelectionModal.scss';
import CloseableModalWithTitle from '../CloseableModalWithTitle/CloseableModalWithTitle';
import CustomCard from '../../components/subscription/CustomCard/CustomCard';
import { PRICING_PLANS_AVAILABLE } from '../../config/global';

export const PricingSubscriptionSelectionModal = ( {
	onClose, fetchPricingPlans, isLoadingPrices, pricingPlans,
	presenter, onUpgradeButtonClicked, onDowngradeButtonClicked,
	onCancelDowngradeButtonClicked, onNoWorkspaceButtonClicked
} ) => {
	useEffect( () => { fetchPricingPlans(); }, [] );

	const renderPlanCard = pricingPlan => (
		<PlanCard
			key={pricingPlan.id}
			presenter={presenter.planCardPresenterForPricingPlan( pricingPlan )}
			onUpgradeButtonClicked={onUpgradeButtonClicked}
			onDowngradeButtonClicked={onDowngradeButtonClicked}
			onCancelDowngradeButtonClicked={onCancelDowngradeButtonClicked}
			onNoWorkspaceButtonClicked={onNoWorkspaceButtonClicked}
		/>
	);

	const renderPricingPlans = () => (
		<div className="plans-box">
			{pricingPlans.map( renderPlanCard )}
			<CustomCard />
		</div>
	);

	const renderBillingPeriodSwitch = () => (
		<PriceSwitch
			isMonthly={presenter.isMonthly}
			annualDiscountPercentage={presenter.minimumAnualDiscountPercentage( pricingPlans )}
			onSwitch={presenter.onSwitched}
		/>
	);

	const renderBillingPeriodHeader = () => (
		presenter.shouldShowBillingPeriodSwitch
			? renderBillingPeriodSwitch()
			: <BillingPeriodTitle text={presenter.billingPeriodName} />
	);

	return PRICING_PLANS_AVAILABLE && (
		<CloseableModalWithTitle
			headerBorderType="small"
			onClose={onClose}
			name="subscription-selection"
			title="Change Workspace Plan"
		>
			<div className="PricingSubscriptionSelectionModal">
				{isLoadingPrices
					? <Loader active />
					: (
						<>
							{renderBillingPeriodHeader()}
							{renderPricingPlans()}
						</>
					)}
			</div>
		</CloseableModalWithTitle>
	);
};

PricingSubscriptionSelectionModal.propTypes = {
	presenter: PropTypes.instanceOf( PricingSubscriptionPresenter ).isRequired,
	onClose: PropTypes.func,
	isLoadingPrices: PropTypes.bool,
	fetchPricingPlans: PropTypes.func,
	onUpgradeButtonClicked: PropTypes.func,
	onDowngradeButtonClicked: PropTypes.func,
	onCancelDowngradeButtonClicked: PropTypes.func,
	onNoWorkspaceButtonClicked: PropTypes.func,
	pricingPlans: PropTypes.arrayOf( PropTypes.instanceOf( PricingPlan ) )
};

PricingSubscriptionSelectionModal.defaultProps = {
	onClose: () => { },
	isLoadingPrices: true,
	fetchPricingPlans: () => { },
	onUpgradeButtonClicked: () => { },
	onDowngradeButtonClicked: () => { },
	onCancelDowngradeButtonClicked: () => { },
	onNoWorkspaceButtonClicked: () => { },
	pricingPlans: []
};

export const PricingSubscriptionSelectionModalWithObserver = observer(
	PricingSubscriptionSelectionModal
);

export default connectComponent( ( state, props ) => ( {
	workspace: getWorkspace( state, props ),
	pricingPlans: getPricingPlans( state, props ).valueSeq().toArray(),
	isLoadingPrices: state.fetchPricingPlansRequest.sending
} ) )( PricingSubscriptionSelectionModalWithObserver );
