import { OrderedSet } from 'immutable';
import { createSelector } from 'reselect';
import { firstBy } from 'thenby';
import { getProjectID } from './projects';
import { ITEM_UPLOAD_STATUS } from '../services/DirectFilesUploaderCoordinator';
import AssetUpload from '../entities/assetUpload';
import { getFolderUploadID, getFolderUploads } from './folderUploads';

export const getUploadID = ( _, props ) => props.uploadID;

export const getRawAssetUploads = state => state.assetUploads;

export const getAssetUploads = createSelector(
	[ getRawAssetUploads ],
	rawAssetUploads => rawAssetUploads
		.map( rawAssetUpload => new AssetUpload(
			rawAssetUpload.id,
			rawAssetUpload.projectID,
			rawAssetUpload.file,
			rawAssetUpload.status,
			rawAssetUpload.bytesUploaded,
			rawAssetUpload.startedAt,
			rawAssetUpload.assetID,
			rawAssetUpload.uploadingRoundForAsset,
			rawAssetUpload.folderID,
			rawAssetUpload.parentUploadID,
			rawAssetUpload.path,
			rawAssetUpload.errorName
		) )
);

export const getAssetUpload = createSelector(
	[ getAssetUploads, getUploadID ],
	( assetUploads, uploadID ) => assetUploads.get( uploadID )
);

export const uploadIsActive = status => (
	status === ITEM_UPLOAD_STATUS.WAITING
	|| status === ITEM_UPLOAD_STATUS.UPLOADING
	|| status === ITEM_UPLOAD_STATUS.CREATING_ASSET
);

const uploadWasNotCanceledOrFailed = upload => !upload.wasCanceled && !upload.hasFailed;

export const getAssetUploadsArray = createSelector(
	[ getAssetUploads ],
	assetUploads => assetUploads.valueSeq().toArray().sort(
		firstBy( ( a, b ) => ( a.statusOrder - b.statusOrder ) )
			.thenBy( ( a, b ) => ( b.startedAt - a.startedAt ) )
	)
);

export const getAssetUploadsCount = createSelector(
	[ getAssetUploadsArray ],
	assetUploads => assetUploads.length
);

export const getAssetActiveUploadsArray = createSelector(
	[ getAssetUploadsArray ],
	assetUploadsArray => assetUploadsArray
		.filter( upload => uploadIsActive( upload.status ) )
);

export const getAssetActiveUploadsCount = createSelector(
	[ getAssetActiveUploadsArray ],
	assetUploads => assetUploads.length
);

export const getAssetNonAbortedOrFailedUploadsArray = createSelector(
	[ getAssetUploadsArray ],
	assetUploadsArray => assetUploadsArray.filter( uploadWasNotCanceledOrFailed )
);

export const getAssetNonAbortedOrFailedUploadsCount = createSelector(
	[ getAssetNonAbortedOrFailedUploadsArray ],
	nonAbortedOrFailedAssetUploads => nonAbortedOrFailedAssetUploads.length
);

export const getAssetSucceededUploadsArray = createSelector(
	[ getAssetUploadsArray ],
	assetUploadsArray => assetUploadsArray
		.filter( upload => upload.isCompleted )
);

const belongsToFolderUpload = ( upload, folderUploadID, folderUploadsMap ) => {
	const { parentUploadID } = upload;

	if ( !parentUploadID ) return false;
	if ( parentUploadID === folderUploadID ) return true;

	return belongsToFolderUpload(
		folderUploadsMap.get( parentUploadID ),
		folderUploadID,
		folderUploadsMap
	);
}

const pathFor = ( upload, folderUploadsMap ) => {
	const { parentUploadID, name } = upload;
	if ( !parentUploadID ) return name;

	const pathForParent = pathFor( folderUploadsMap.get( parentUploadID ), folderUploadsMap );
	return `${pathForParent}/${name}`;
}

export const getFailedAssetUploadsWithPathsForFolderUpload = createSelector(
	[ getAssetUploadsArray, getFolderUploads, getFolderUploadID ],
	( assetUploadsArray, folderUploadsMap, folderUploadID ) => assetUploadsArray
		.filter( upload => (
			upload.hasFailed && belongsToFolderUpload( upload, folderUploadID, folderUploadsMap )
		) )
		.map( upload => upload.withPath( pathFor( upload, folderUploadsMap ) ) )
);

export const getTopLevelAssetUploadIDsForFolderUpload = createSelector(
	[ getAssetUploadsArray, getFolderUploadID ],
	( assetUploadsArray, folderUploadID ) => assetUploadsArray
		.filter( upload => upload.parentUploadID === folderUploadID )
		.map( upload => upload.id )
);

export const getAssetSucceededUploadsCount = createSelector(
	[ getAssetSucceededUploadsArray ],
	succeededAssetUploads => succeededAssetUploads.length
);

export const getAssetActiveUploadsTotalSize = createSelector(
	[ getAssetActiveUploadsArray ],
	activeUploads => activeUploads.accumulate( upload => upload.size )
);

export const getAssetUploadsProjectIDs = createSelector(
	[ getAssetUploadsArray ],
	assetUploadsArray => [ ...new OrderedSet(
		assetUploadsArray.reduce( ( projectIDs, upload ) => [ ...projectIDs, upload.projectID ], [] )
	) ]
);

export const getAssetsUploadsForProject = createSelector(
	[ getAssetUploadsArray, getProjectID ],
	( assetUploads, projectID ) => assetUploads.filter( upload => upload.projectID === projectID )
);

export const getMainAssetsUploadsForProject = createSelector(
	[ getAssetsUploadsForProject ],
	assetUploadsForProject => assetUploadsForProject.filter( upload => !upload.parentUploadID )
);

export const getAssetsUploadsTotalProgress = createSelector(
	[ getAssetUploadsArray ],
	assetUploads => assetUploads
		.accumulate( upload => upload.progress ) / ( assetUploads.length || 1 )
);

export const getUploadSpeed = createSelector(
	[ getAssetActiveUploadsArray ],
	( activeUploads ) => {
		const validUploads = activeUploads
			.filter( upload => upload.uploadSpeedAvailable );
		if ( validUploads.length === 0 ) { return undefined; }
		return validUploads.accumulate( upload => upload.uploadSpeed );
	}
);

export const getETAForAllAssetUploads = createSelector(
	[ getAssetActiveUploadsTotalSize, getUploadSpeed ],
	( totalSize, uploadSpeed ) => totalSize / uploadSpeed
);
