import classNames from 'classnames';
import PropTypes from 'prop-types';

import './SwitchOption.scss';

const SwitchOption = ( { option: { Content, isSelected }, onClick } ) => (
	<div className={classNames( 'SwitchOption', { selected: isSelected } )} onClick={!isSelected && onClick}>
		<Content />
	</div>
);

SwitchOption.propTypes = {
	option: PropTypes.shape( {
		Content: PropTypes.node,
		isSelected: PropTypes.bool
	} ).isRequired,
	onClick: PropTypes.func.isRequired
};

export default SwitchOption;
