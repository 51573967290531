import { createSelector } from 'reselect';
import SortOption from '../entities/sortOption';
import FilterOption from '../entities/filterOption';
import { getHomeProjects } from './projects';
import { mapEntityToCustomSort } from '../lib/reduxUtils';

const getSelectedSortOptionID = state => state.projectsViewOptions.get( 'sort' );
const getSelectedFilterOptionID = state => state.projectsViewOptions.get( 'filter' );
const getGridItemsSize = state => state.projectsViewOptions.get( 'itemSize' );
const getCustomSortOrder = state => state.projectsViewOptions.get( 'customSort' );

export const getSortAscending = state => state.projectsViewOptions.get( 'sortAscending' );

export const getSelectedProjectsSortOption = createSelector(
	[ getSelectedSortOptionID ],
	sortOptionID => SortOption.optionsByID[ sortOptionID ] || SortOption.byLastActivity
);

export const getSelectedFilterOption = createSelector(
	[ getSelectedFilterOptionID ],
	filterOptionID => FilterOption.projectsFiltersByID[ filterOptionID ]
);

export const getProjectsViewOptions = createSelector(
	[
		getSelectedProjectsSortOption,
		getSortAscending,
		getSelectedFilterOption,
		getGridItemsSize,
		getCustomSortOrder
	],
	( sortOption, sortAscending, filterOption, itemsSize, customSortOrder ) => ( {
		sort: sortOption,
		sortAscending,
		filter: filterOption,
		itemSize: itemsSize,
		customSort: customSortOrder
	} )
);

export const getCustomSortedProjects = createSelector(
	[ getHomeProjects, getCustomSortOrder ],
	( projects, customSortedIDs ) => mapEntityToCustomSort( projects, customSortedIDs )
);
