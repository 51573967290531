import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import StorageUsagePresenter from '../../../presenters/StorageUsagePresenter/StorageUsagePresenter';
import AddOnUsage from '../AddOnUsage/AddOnUsage';

import './StorageUsage.scss';

const StorageUsage = ( { presenter } ) => (
	<div className="StorageUsage">
		<AddOnUsage
			title="Storage usage"
			icon="collections"
			showAddOnsButton={presenter.showAddOnsButton}
			onAddOnsButtonClick={presenter.addOnsButtonWasClicked}
			addOnsButtonText={presenter.addStorageButtonText}
			addOnsModalIsOpen={presenter.addOnsModalIsOpen}
			addOnsModalPresenter={presenter.addOnsModalPresenter}
			valueCount={presenter.storageBarValueCount}
			maxValue={presenter.storageBarMaxValue}
			greyOut={!presenter.isSubscriptionActive}
			unit={presenter.storageUnitText}
			maxedOutMultiplier={0.9}
			bytesFormat
			hasAddOns={presenter.hasStorageAddOns}
			disabledButton={presenter.disabledAddOnButton}
		/>
	</div>
);

StorageUsage.propTypes = {
	presenter: PropTypes.instanceOf( StorageUsagePresenter ).isRequired
};

export default observer( StorageUsage );
