import { ownerAssetsPermissions, projectCollaboratorAssetsPermissions, projectGuestAssetsPermissions }
	from './permissions/assetsPermissions';
import { ownerProjectsPermissions, projectCollaboratorProjectsPermissions, projectGuestProjectsPermissions }
	from './permissions/projectsPermissions';
import { ownerTasksPermissions, projectCollaboratorTasksPermissions, projectGuestTasksPermissions }
	from './permissions/tasksPermissions';
import { ownerCommentsPermissions, projectCollaboratorCommentsPermissions, projectGuestCommentsPermissions }
	from './permissions/commentsPermissions';
import { ownerUsersPermissions, projectCollaboratorUsersPermissions, projectGuestUsersPermissions }
	from './permissions/usersPermissions';

export class ProjectRolePermissions {
	constructor(
		projectsPermissions,
		assetsPermissions,
		tasksPermissions,
		commentsPermissions,
		usersPermissions
	) {
		this.projectsPermissions = projectsPermissions;
		this.assetsPermissions = assetsPermissions;
		this.tasksPermissions = tasksPermissions;
		this.commentsPermissions = commentsPermissions;
		this.usersPermissions = usersPermissions;
	}

	/**
	 * @param {bool} condition Accepts any boolean. Usually a selector of the permission
	 * @param {*} componentToShow
	 * @example RolePermissions.renderIfUserIsAllowed( getUserCanUploadAssets, <button /> )
	 */
	static renderIfUserIsAllowed( condition, componentToShow ) {
		return condition
			? componentToShow
			: null;
	}

	/**
	 * @param {'edit'|'add'|'destroy'|'archive'|'view'|'seeVersions'} permit
	 * @param {'projects'|'assets'|'markups'|'tasks'|'comments'|'users'} entity
	 * @example currentUserCan( 'edit', 'projects' ) // => true
	 */

	currentUserCan( permit, entity ) {
		const permission = `${entity}Permissions`;
		if ( !this[ permission ] || this[ permission ][ permit ] === undefined ) {
			throw new Error( 'The requested permission does not exist' );
		}
		return this[ permission ][ permit ];
	}
}

export const ownerRolePermissions = new ProjectRolePermissions(
	ownerProjectsPermissions,
	ownerAssetsPermissions,
	ownerTasksPermissions,
	ownerCommentsPermissions,
	ownerUsersPermissions
);

export const projectCollaboratorRolePermissions = new ProjectRolePermissions(
	projectCollaboratorProjectsPermissions,
	projectCollaboratorAssetsPermissions,
	projectCollaboratorTasksPermissions,
	projectCollaboratorCommentsPermissions,
	projectCollaboratorUsersPermissions
);

export const projectGuestRolePermissions = new ProjectRolePermissions(
	projectGuestProjectsPermissions,
	projectGuestAssetsPermissions,
	projectGuestTasksPermissions,
	projectGuestCommentsPermissions,
	projectGuestUsersPermissions
);
