export default class SomeValidator {
	constructor( validators ) {
		this.validators = validators;
	}

	validate( value, label, errors, parentFormData ) {
		const isValid = this.validators.reduce(
			( result, validator ) => {
				const validationResult = validator.validate(
					value, label, errors, parentFormData
				);
				return result || validationResult;
			},
			false
		);

		if ( isValid ) {
			errors.length = 0;
			return true;
		}
		return false;
	}
}
