import { AVAILABLE_ROLES_BY_TYPE } from './workspaceRole';

export default class WorkspaceInvitation {
	constructor( id, hash, email, role, workspaceID, workspaceAttributes ) {
		this.id = id;
		this.hash = hash;
		this.email = email;
		this.role = role;
		this.workspaceID = workspaceID;
		this.workspaceAttributes = workspaceAttributes;
	}

	static fromJSON( properties ) {
		return new WorkspaceInvitation(
			properties.id,
			properties.hash,
			properties.email,
			AVAILABLE_ROLES_BY_TYPE[ properties.role.type ],
			properties.workspace ? properties.workspace.id : properties.workspace_id,
			properties.workspace
		);
	}

	toJSON() {
		return {
			id: this.id,
			invite_hash: this.hash,
			email: this.email,
			role: this.role,
			workspace_id: this.workspaceID,
			workspace: this.workspaceAttributes
		};
	}
}
