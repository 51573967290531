import { removeJunkFiles, getFileExtension } from '../../lib/fileUtils';
import FileDirectory from './FileDirectory';
import FilePath from './FilePath';

type FileSelectionParams = {
	files?: File[],
	directories?: FileDirectory[]
}

export type DragAndDropFile = File & { path: string };

export default class FileSelection {
	files: File[];
	directories: FileDirectory[];

	constructor( { files = [], directories = [] }: FileSelectionParams = {} ) {
		this.files = files;
		this.directories = directories;
	}

	static fromHTMLInputFiles = ( fileList: FileList ) => new FileSelection( {
		files: Array.from( fileList )
	} );

	static fromHTMLInputDirectory = ( directoryFileList: FileList ) => this.fromFilesWithPaths(
		Array.from( directoryFileList ), file => file.webkitRelativePath
	);

	static fromDragAndDrop = ( dragAndDropFiles: DragAndDropFile[] ) => this.fromFilesWithPaths(
		dragAndDropFiles, file => file.path
	);

	static fromFilesWithPaths = <FileType extends File>(
		files: FileType[], pathExtractor: ( file: FileType ) => string
	) => files.reduce(
		( fileSelection, file ) => {
			const path = FilePath.parse( pathExtractor( file ) );
			return fileSelection.addFile( file, path.dropFilename );
		},
		new FileSelection()
	);

	get filesFromAllLevels() {
		return this.asDirectory.filesFromAllLevels;
	}

	get directoryNames() {
		return this.directories.map( directory => directory.name );
	}

	get totalFilesCount() {
		return this.filesFromAllLevels.length;
	}

	get filesExtensionsCount() {
		return this.filesFromAllLevels.reduce(
			( extensionsCount, file ) => {
				const extension = getFileExtension( file.name ).toString();
				extensionsCount[ extension ] = ( extensionsCount[ extension ] || 0 ) + 1;
				return extensionsCount;
			},
			{} as Record<string, number>
		);
	}

	removeJunkFiles() {
		this.files = removeJunkFiles( this.files );
		this.directories.forEach( directory => directory.removeJunkFiles() );
	}

	addFile( file: File, relativePath?: FilePath ) {
		this.asDirectory.addFile( file, relativePath );
		return this;
	}

	private get asDirectory() {
		return new FileDirectory( '', {
			files: this.files,
			subDirectories: this.directories
		} );
	}
}
