import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../../new_arch/components/Icon/Icon';

import './Tab.scss';

const Tab = ( {
	label, selected, onClick, withArrowPointer, icon, iconColor, className
} ) => (
	<li className={classNames( 'Tab', className )}>
		<button className={classNames( { 'active': selected } )} onClick={onClick}>
			{label}
			{withArrowPointer && <div className="arrow-pointer" />}
		</button>
		{icon && <Icon className="icon" icon={icon} size={19} color={iconColor} />}
	</li>
);

Tab.propTypes = {
	label: PropTypes.string.isRequired,
	selected: PropTypes.bool,
	onClick: PropTypes.func,
	withArrowPointer: PropTypes.bool,
	icon: PropTypes.string,
	iconColor: PropTypes.string,
	className: PropTypes.string
};

Tab.defaultProps = {
	selected: false,
	onClick: () => {},
	withArrowPointer: false,
	icon: undefined,
	iconColor: undefined,
	className: undefined
};

export default Tab;
