export const successMessage = email => ( {
	title: 'Invitation Sent',
	description: `${email} will receive an invitation to join this project.`
} );
const errorTitle = 'Invitation Error';
export const invalidUserErrorMessage = email => ( {
	title: errorTitle,
	description: `The user with email: ${email}, is already a member of this project.`
} );
export const invalidRecordErrorMessage = {
	title: errorTitle,
	description: 'This person has already been invited.'
};
export const defaultErrorMessage = {
	title: errorTitle,
	description: 'There was an error inviting the users. Please try again later.'
};
export const multipleInvitationsSuccessMessage = {
	title: 'Invitations Sent',
	description: 'All emails will receive an invitation to join this project.'
};
export const limitReachedErrorMessage = {
	title: 'Email limit reached',
	description: 'You have reached the limit of emails you can send for today.'
};
