import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import SwitchBox from '../../components/SwitchBox/SwitchBox';
import Select from '../../components/UI/Select/Select';
import Button from '../../new_arch/components/Button/Button';
import DownloadItemsPopupPresenter from '../../presenters/DownloadItemsPopupPresenter/DownloadItemsPopupPresenter';
import IconButton from '../../components/UI/IconButton/IconButton';
import LoaderButton from '../../components/UI/LoaderButton/LoaderButton';

import './DownloadItemsPopup.scss';

const DownloadItemsPopup = ( { presenter } ) => (
	<div className="DownloadItemsPopup">
		<IconButton buttonClassName="close-button" onClick={presenter.modalWasClosed} icon="close" size={24} />
		<h4 className="title">{`Download Item${presenter.downloadingOneAsset ? '' : 's'}`}</h4>
		{presenter.withDownloadAllRoundsOption && (
			<SwitchBox
				data-testid="downloadAllRoundsSwitchBox"
				title="Download All Rounds"
				onSwitch={presenter.downloadAllRoundsWasSwitched}
				checked={presenter.downloadingAllRounds}
			/>
		)}
		<SwitchBox
			data-testid="appendRoundNumberSwitchBox"
			title="Append Round Number"
			caption="e.g Filename_r4.jpg"
			onSwitch={presenter.appendRoundNumberWasSwitched}
			checked={presenter.appendingRoundNumber}
			disabled={presenter.downloadingAllRounds}
		/>
		<Select
			initialValue={presenter.selectedImageResolution}
			name="image-size"
			type="tall"
			label="Image Size"
			options={presenter.imageResolutionOptions}
			value={presenter.selectedImageResolution}
			onChange={presenter.imageResolutionWasChanged}
		/>
		<div className="buttons">
			<Button type="secondary" onClick={presenter.modalWasClosed}>Cancel</Button>
			<LoaderButton
				type="primary"
				onClick={presenter.downloadWasClicked}
				loading={presenter.isLoading}
				disabled={presenter.isLoading}
			>
				Download
			</LoaderButton>
		</div>
	</div>
);

DownloadItemsPopup.propTypes = {
	presenter: PropTypes.instanceOf( DownloadItemsPopupPresenter ).isRequired
};

export default observer( DownloadItemsPopup );
