/* The following list comes from the library https://github.com/sindresorhus/junk, version 4.0.1.
 * The library is not used due to unsupported syntax in its index.js file.
 */
const junkList = [
	// # All
	'^npm-debug\\.log$', // Error log for npm
	'^\\..*\\.swp$', // Swap file for vim state

	// # macOS
	'^\\.DS_Store$', // Stores custom folder attributes
	'^\\.AppleDouble$', // Stores additional file resources
	'^\\.LSOverride$', // Contains the absolute path to the app to be used
	'^Icon\\r$', // Custom Finder icon: http://superuser.com/questions/298785/icon-file-on-os-x-desktop
	'^\\._.*', // Thumbnail
	'^\\.Spotlight-V100(?:$|\\/)', // Directory that might appear on external disk
	'\\.Trashes', // File that might appear on external disk
	'^__MACOSX$', // Resource fork

	// # Linux
	'~$', // Backup file

	// # Windows
	'^Thumbs\\.db$', // Image file cache
	'^ehthumbs\\.db$', // Folder config file
	'^[Dd]esktop\\.ini$', // Stores custom folder attributes
	'@eaDir$' // Synology Diskstation "hidden" folder where the server stores thumbnails
];

const junkRE = new RegExp( junkList.join( '|' ) );

const fileExtensionRE = /(?:\.([^.]+))?$/;

export const removeJunkFiles = files => files.filter( file => !junkRE.test( file.name ) );

export const getFileName = fileURL => decodeURIComponent(
	fileURL.split( /[\\/]/ ).pop().split( '?' )[ 0 ]
);

export const mapExtensionsForFileInput = extensions => extensions.map( ext => `.${ext}` ).join( ',' );

export const getFileExtension = ( fileName ) => {
	const trimmedFileName = fileName.trim();
	// eslint-disable-next-line no-bitwise
	const extension = trimmedFileName.slice( ( trimmedFileName.lastIndexOf( '.' ) - 1 >>> 0 ) + 2 );

	return extension ? extension.toLowerCase() : undefined;
};

export const addExtension = ( fileName, fileExtension ) => (
	fileExtension ? `${fileName}.${fileExtension}` : fileName
);

export const removeExtension = fileName => fileName.replace( fileExtensionRE, '' );

export const initializeDownload = ( url ) => {
	const link = document.createElement( 'a' );
	link.href = url;
	link.setAttribute( 'download', true );
	document.body.appendChild( link );
	link.click();
	document.body.removeChild( link );
};

/**
 * returns a Promise with the result url for the image
 * @param {File} file
 * @returns {Promise}
 */
export const readLocalFile = file => new Promise( ( res, rej ) => {
	const reader = new FileReader();
	reader.onloadend = ( onFRLoadEnd ) => {
		if ( onFRLoadEnd.target.result ) {
			return res( onFRLoadEnd.target.result );
		}
		return rej( new Error( 'File could not be read' ) );
	};
	reader.readAsDataURL( file );
} );

export const renameFile = ( originalFile, newName ) => new File(
	[ originalFile ],
	newName, {
		type: originalFile.type,
		lastModified: originalFile.lastModified
	}
);

export const getParsedFileName = ( { filename } ) => (
	addExtension( removeExtension( filename ), getFileExtension( filename ) )
);
