export default class UserPreferences {
	constructor( { id, is2FAEnabled, assetDownloadsResolution } ) {
		this.id = id;
		this.is2FAEnabled = is2FAEnabled;
		this.assetDownloadsResolution = assetDownloadsResolution;
	}

	static fromJSON( properties ) {
		return new this( {
			id: properties.id,
			is2FAEnabled: properties.is_2fa_enabled,
			assetDownloadsResolution: properties.asset_downloads_resolution
		} );
	}

	static get assetDownloadsResolutions() {
		return {
			original: 'original',
			lowRes: 'low_res'
		};
	}

	toJSON() {
		return {
			id: this.id,
			is_2fa_enabled: this.is2FAEnabled,
			asset_downloads_resolution: this.assetDownloadsResolution
		};
	}
}
