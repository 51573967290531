export class CommentsPermissions {
	constructor( {
		see = false,
		add = false,
		destroyOwnComments = false,
		editOwnComments = false,
		destroyOtherComments = false,
		editOtherComments = false
	} ) {
		this.see = see;
		this.add = add;
		this.destroyOwnComments = destroyOwnComments;
		this.editOwnComments = editOwnComments;
		this.destroyOtherComments = destroyOtherComments;
		this.editOtherComments = editOtherComments;
	}
}

export const ownerCommentsPermissions = new CommentsPermissions( {
	see: true,
	add: true,
	destroyOwnComments: true,
	editOwnComments: true,
	destroyOtherComments: true,
	editOtherComments: false
} );

export const projectCollaboratorCommentsPermissions = new CommentsPermissions( {
	see: true,
	add: true,
	destroyOwnComments: true,
	editOwnComments: true,
	destroyOtherComments: true,
	editOtherComments: false
} );

export const projectGuestCommentsPermissions = new CommentsPermissions( {
	see: true,
	add: true,
	destroyOwnComments: true,
	editOwnComments: true,
	destroyOtherComments: false,
	editOtherComments: false
} );
