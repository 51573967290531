import { serialize as serializeToFormData } from 'object-to-formdata';
import { EDIT_PROFILE, CHANGE_USER_PASSWORD, SEND_PROFILE_VERIFICATION_EMAIL } from './types';
import { USERS_URL, ACCESS_CODES_URL } from '../config/urls';

export const editProfile = ( userID, data, accessCode ) => ( dispatch, _, api ) => dispatch( {
	type: EDIT_PROFILE,
	promise: api
		.put(
			`${USERS_URL}/${userID}/profile`,
			serializeToFormData( { attributes: data, access_code: accessCode } ),
			{ 'Accept': 'application/json, */*', 'Content-Type': undefined }
		).then( response => response.response )
} );

export const sendProfileVerificationEmail = email => ( dispatch, _, api ) => dispatch( {
	type: SEND_PROFILE_VERIFICATION_EMAIL,
	promise: api
		.put(
			`${ACCESS_CODES_URL}/profile`,
			{ access_code: { email } }
		).then( response => response.response )
} );

export const changeUserPassword = (
	userID, oldPassword, newPassword
) => ( dispatch, _, api ) => dispatch( {
	type: CHANGE_USER_PASSWORD,
	promise: api
		.post(
			`${USERS_URL}/${userID}/update_password`,
			{
				old_password: oldPassword,
				new_password: newPassword
			}
		)
		.then( () => userID )
} );
