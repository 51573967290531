import type WorkspaceSystem from '../../../systems/WorkspaceSystem';

export default class ContactGroupCreateStrategy {
	private workspaceID: number;
	private workspaceSystem: WorkspaceSystem;

	constructor( { workspaceID, workspaceSystem }: { workspaceID: number, workspaceSystem: WorkspaceSystem } ) {
		this.workspaceID = workspaceID;
		this.workspaceSystem = workspaceSystem;
	}

	get title() {
		return 'New group';
	}

	get initialName() {
		return '';
	}

	get initialEmails() {
		return [];
	}

	get submitButtonLabel() {
		return 'Create';
	}

	get successMessage() {
		return 'Contact group created successfully';
	}

	makeContactGroupRequest( name: string, emails: string[] ) {
		return this.workspaceSystem.createContactGroup( {
			workspaceID: this.workspaceID,
			name,
			emails
		} );
	}
}
