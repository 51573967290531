import InmutableEntity from './immutableEntity';
import Asset from './asset';
import Project from './project';
import RootComment from './rootComment';
import CommentReply from './commentReply';
import { fileNameTextTruncate, textEndEllipsis } from '../lib/textTruncate';

export const AVAILABLE_RELATED_ENTITIES = Object.freeze( {
	Asset,
	Project,
	RootComment,
	CommentReply
} );

export const RELATED_OBJECTS_TYPES_WITH_TEXT = [
	'RootComment', 'CommentReply'
];

// eslint-disable-next-line no-useless-escape
const FILENAME_REGEX = /[|].+.[|]/i;

const parseRelatedObjectType = ( rawType, object ) => {
	if ( rawType !== 'Comment' ) { return rawType; }
	return object.parent_id ? 'CommentReply' : 'RootComment';
};

const parseRelatedObject = ( type, properties, projectID ) => {
	if ( !type || !AVAILABLE_RELATED_ENTITIES[ type ] || !properties ) { return null; }

	const relatedObjectProperties = (
		type === 'Project'
			? properties
			: { ...properties, project_id: projectID }
	);

	return AVAILABLE_RELATED_ENTITIES[ type ].fromJSON( relatedObjectProperties );
};

export default class Notification extends InmutableEntity {
	constructor(
		id, projectID, title, message, image, wasRead,
		createdAt, relatedObjectType, relatedObject,
		projectName, folderID, folderName, folderHasAncestors
	) {
		super();

		this.id = id;
		this.projectID = projectID;
		this.title = title;
		this.message = message;
		this.image = image;
		this.wasRead = wasRead;
		this.createdAt = createdAt;
		this.relatedObjectType = relatedObjectType;
		this.relatedObject = relatedObject;
		this.projectName = projectName;
		this.folderID = folderID;
		this.folderName = folderName;
		this.folderHasAncestors = folderHasAncestors;
	}

	get hasAValidRelatedObject() {
		return !!AVAILABLE_RELATED_ENTITIES[ this.relatedObjectType ];
	}

	get relatedObjectUrl() {
		if ( !this.hasAValidRelatedObject ) { return null; }

		const urls = {
			Asset: `/projects/${this.projectID}/assets/${this.relatedObject.id}`,
			Project: `/projects/${this.relatedObject.id}`,
			RootComment: `/projects/${this.projectID}/assets/${this.relatedObject.assetID}`,
			CommentReply: `/projects/${this.projectID}/assets/${this.relatedObject.assetID}`
		};

		return urls[ this.relatedObjectType ];
	}

	get projectUrl() {
		return `/projects/${this.projectID}`;
	}

	get folderUrl() {
		return `/projects/${this.projectID}/folders/${this.folderID}`;
	}

	get projectBreadcrumbs() {
		return `${textEndEllipsis( this.projectName, 15 )}`;
	}

	get folderBreadcrumbs() {
		return `/${textEndEllipsis( this.folderName, 15 )}`;
	}

	get hasRelatedObject() {
		return this.relatedObjectType && this.relatedObject;
	}

	get hasRelatedObjectText() {
		return this.hasRelatedObject
			&& this.hasAValidRelatedObject
			&& RELATED_OBJECTS_TYPES_WITH_TEXT.includes( this.relatedObjectType )
			&& this.relatedObject.text;
	}

	get relatedObjectText() {
		if ( !this.hasRelatedObjectText ) { return null; }
		return this.relatedObject.text;
	}

	get htmlMessage() {
		const match = this.message && this.message.match( FILENAME_REGEX );
		if ( !match ) { return <span>{this.message}</span>; }
		const matchWithoutPipes = match[ 0 ].slice( 1, match[ 0 ].length - 1 );
		const splitMessage = this.message.split( match[ 0 ] );
		return (
			<span>
				{!!splitMessage[ 0 ] && splitMessage[ 0 ]}
				<span className="name">{fileNameTextTruncate( matchWithoutPipes, 20, 12 )}</span>
				{!!splitMessage[ 1 ] && splitMessage[ 1 ]}
			</span>
		);
	}

	get relatedObjectIsComment() {
		return this.relatedObjectType === 'RootComment' || this.relatedObjectType === 'CommentReply';
	}

	static fromJSON( properties ) {
		const {
			related_object_type: rawRelatedObjectType,
			related_object: relatedJSONObject
		} = properties;

		const relatedObjectType = parseRelatedObjectType(
			rawRelatedObjectType, relatedJSONObject
		);
		const relatedObject = parseRelatedObject(
			relatedObjectType, relatedJSONObject, properties.project_id
		);

		return new Notification(
			properties.id,
			properties.project_id,
			properties.title,
			properties.message,
			properties.image,
			properties.was_read,
			new Date( properties.created_at ),
			relatedObjectType,
			relatedObject,
			properties.project_name,
			properties.folder_id,
			properties.folder_name,
			properties.folder_has_ancestors
		);
	}

	markAsRead() {
		return this
			.clone()
			.set( 'wasRead', true );
	}

	clone() {
		return new Notification(
			this.id,
			this.projectID,
			this.title,
			this.message,
			this.image,
			this.wasRead,
			this.createdAt,
			this.relatedObjectType,
			this.relatedObject,
			this.projectName,
			this.folderID,
			this.folderName,
			this.folderHasAncestors
		);
	}

	toJSON() {
		return {
			id: this.id,
			project_id: this.projectID,
			title: this.title,
			message: this.message,
			image: this.image,
			was_read: this.wasRead,
			created_at: this.createdAt.toISOString(),
			related_object_type: this.relatedObjectType,
			related_object: this.relatedObject,
			project_name: this.projectName,
			folder_id: this.folderID,
			folder_name: this.folderName,
			folder_has_ancestors: this.folderHasAncestors
		};
	}
}
