import { Map } from 'immutable';
import {
	CLOSE_REACHED_PROJECT_LIMIT_WARNING,
	CLOSE_SUSPENDED_FOR_DOWNGRADE_WARNING,
	CLOSE_PENDING_DOWNGRADE_WARNING,
	CLOSE_EXPIRED_FREE_TRIAL_POPUP
} from '../actions/types';
import {
	REACHED_PROJECT_LIMIT_WARNING_BANNER_KEY,
	SUSPENDED_FOR_DOWNGRADE_WARNING_POPUP_KEY,
	PENDING_DOWNGRADE_WARNING_BANNER_KEY,
	EXPIRED_FREE_TRIAL_POPUP_KEY
} from '../actions/closedBanners';

const defaultState = Map( {
	[ REACHED_PROJECT_LIMIT_WARNING_BANNER_KEY ]: false,
	[ SUSPENDED_FOR_DOWNGRADE_WARNING_POPUP_KEY ]: false,
	[ PENDING_DOWNGRADE_WARNING_BANNER_KEY ]: false,
	[ EXPIRED_FREE_TRIAL_POPUP_KEY ]: false
} );

export const closedBanners = ( state = defaultState, action ) => {
	switch ( action.type ) {
	case CLOSE_REACHED_PROJECT_LIMIT_WARNING:
	case CLOSE_SUSPENDED_FOR_DOWNGRADE_WARNING:
	case CLOSE_PENDING_DOWNGRADE_WARNING:
	case CLOSE_EXPIRED_FREE_TRIAL_POPUP:
		return state.set( action.payload, true );
	default:
		return state;
	}
};
