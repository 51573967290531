import type Asset from '../asset';
import { type FilterJSON, type FilterOption } from './types';

type TypeFilter = 'image' | 'document';

type AssetFilterParams = {
	type?: TypeFilter,
	searchText?: string,
	filterOption?: FilterOption
}

export default class AssetFilters {
	type?: TypeFilter;
	searchText?: string;
	filterOption?: FilterOption;

	constructor( params: AssetFilterParams ) {
		this.type = params.type;
		this.searchText = params.searchText;
		this.filterOption = params.filterOption;
	}

	get filterOptionId() {
		return this.filterOption?.id;
	}

	get usesSearchTextOrFilterOption() {
		return !!this.searchText || !!this.filterOption;
	}

	get isSearching() {
		return !!this.searchText;
	}

	get isFiltering() {
		return this.usesSearchTextOrFilterOption;
	}

	filterAssets( assets: Asset[] ) {
		return assets.filter( asset => (
			this.passesTypeFilter( asset )
			&& this.passesSearchFilter( asset )
			&& this.passesFilterOption( asset )
		) );
	}

	toJson() {
		let json: FilterJSON = {};

		if ( this.type ) json.type = this.type;
		if ( this.searchText ) json.name = this.searchText;
		if ( this.filterOption ) json = { ...json, ...this.filterOption.toJson() };

		return json;
	}

	toJsonString() {
		return JSON.stringify( this.toJson() );
	}

	private passesTypeFilter( asset: Asset ) {
		return !this.type || ( this.type === 'image' ? asset.isImage : asset.isDocument );
	}

	private passesSearchFilter( asset: Asset ) {
		return !this.searchText || asset.name.toLowerCase().includes( this.searchText.toLocaleLowerCase() );
	}

	private passesFilterOption( asset: Asset ) {
		return !this.filterOption || this.filterOption.isPassedBy( asset );
	}
}
