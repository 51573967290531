const DEFAULT_RESPONSIVE_PATHS = [
	'/login', '/forgot', '/signup', '/projects',
	'/projects/:id', '/projects/:id/assets/:id',
	'/projects/:id/folders/:id'
];

export const DATE_FORMAT = 'MM/DD/YYYY';
export const TIME_FORMAT = 'HH:mm';
export const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
export const PROCESSING_ASSETS_FETCH_INTERVAL = 5000;
export const INTERCOM_ENABLED = process.env.REACT_APP_INTERCOM_ENABLED === 'true';
export const ON_MAINTENANCE = process.env.REACT_APP_ON_MAINTENANCE === 'true';
export const RESPONSIVE_PATHS = ( process.env.REACT_APP_RESPONSIVE_PATHS
	? JSON.parse( process.env.REACT_APP_RESPONSIVE_PATHS )
	: DEFAULT_RESPONSIVE_PATHS
);
export const HOTJAR_ID = parseInt( process.env.REACT_APP_HOTJAR_ID, 10 );
export const MICROSOFT_CLIENT_ID = process.env.REACT_APP_MICROSOFT_CLIENT_ID;
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const PUSHER_APP_KEY = process.env.REACT_APP_PUSHER_APP_KEY;
export const PUSHER_APP_CLUSTER = process.env.REACT_APP_PUSHER_APP_CLUSTER;
export const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN || '';
export const METRICS_PROXY_URL = process.env.REACT_APP_METRICS_PROXY_URL || '';
export const GA4_TOKEN = process.env.REACT_APP_GA_ID || '';
export const PRICING_PLANS_AVAILABLE = (
	!process.env.REACT_APP_PRICING_PLANS_AVAILABLE // Defaults to true
		|| process.env.REACT_APP_PRICING_PLANS_AVAILABLE === 'true'
);
export const NEW_RELIC_LICENSE_KEY = process.env.REACT_APP_NEW_RELIC_LICENSE_KEY || '';
export const NEW_RELIC_APP_ID = process.env.REACT_APP_NEW_RELIC_APP_ID || '';
export const NEW_RELIC_ENABLE_SESSION_REPLAY = process.env.REACT_APP_NEW_RELIC_ENABLE_SESSION_REPLAY === 'true';
