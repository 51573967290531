import { Map } from 'immutable';
import { handleSuccess } from '../lib/reduxUtils';
import { FETCH_PROJECT_ACTIVITIES } from '../actions/types';
import { activity as activityScheme } from './schemes';
import { normalizeResponse } from '../lib/normalizeUtils';

const normalizeActivities = ( prevState, actionPayload ) => normalizeResponse(
	prevState, actionPayload, activityScheme, 'activities'
);

export const activities = ( state = new Map(), action ) => {
	switch ( action.type ) {
	case FETCH_PROJECT_ACTIVITIES:
		return handleSuccess(
			state,
			action,
			prevState => normalizeActivities(
				prevState.filter(
					activity => activity.project_id !== action.payload.projectID
				),
				action.payload.activities
			)
		);
	default:
		return state;
	}
};
