import { SHOW_OVERLAY, HIDE_OVERLAY, LOGOUT } from '../actions/types';
import { handleStart } from '../lib/reduxUtils';

export const isShowingOverlay = ( state = false, action ) => {
	switch ( action.type ) {
	case SHOW_OVERLAY:
		return true;
	case HIDE_OVERLAY:
		return false;
	case LOGOUT:
		return handleStart( state, action, () => false );
	default:
		return state;
	}
};
