import { Component, createRef } from 'react';
import PropTypes from 'prop-types';

import ProjectRole from '../../entities/projectRole';
import ProjectMember from '../../entities/projectMember';
import ProjectInvitation from '../../entities/projectInvitation';

import InviteMember from '../InviteMember/InviteMember';
import ProjectMemberWithRoleContainer
	from '../../containers/ProjectMemberWithRoleContainer/ProjectMemberWithRoleContainer';
import ProjectInvitationContainer
	from '../../containers/ProjectInvitationContainer/ProjectInvitationContainer';
import ShareGuestLink from '../ShareGuestLink/ShareGuestLink';
import Project from '../../entities/project';

import './InviteProjectMemberBox.scss';

class InviteProjectMemberBox extends Component {
	constructor( props ) {
		super( props );

		this.inviteMemberBox = createRef();
		this.containerRef = createRef();
	}

	componentDidMount() {
		this.containerRef?.current?.scrollTo( 0, 0 );
	}

	get projectMembersTitle() {
		const { members } = this.props;
		return `Project Collaborators (${members.length})`;
	}

	clearInviteMemberBox() {
		if ( !this.inviteMemberBox.current ) { return; }
		this.inviteMemberBox.current.clear();
	}

	render() {
		const {
			projectID, currentUserRole, pendingInvites, sendingInvitation,
			onInviteClick, onCloseClick, membersCanBeInvited, useAlternateLayout,
			projectMemberRoleName, project, updateGuestHashEnabled, currentUserCanShareGuestLink, members
		} = this.props;

		const { inviteMemberBox } = this;

		const canInviteRoles = currentUserRole.canInviteRoles();
		const defaultInviteRole = (
			canInviteRoles.length > 0 ? canInviteRoles[ canInviteRoles.length - 1 ] : undefined
		);

		const shareGuestLinkComponent = (
			<ShareGuestLink
				project={project}
				updateGuestHashEnabled={updateGuestHashEnabled}
				canShareGuestLink={currentUserCanShareGuestLink}
			/>
		);

		const inviteProjectMemberComponent = (
			<InviteMember
				ref={inviteMemberBox}
				role={canInviteRoles[ 0 ]}
				defaultRole={defaultInviteRole}
				sending={sendingInvitation}
				disabled={!membersCanBeInvited}
				onInviteClick={onInviteClick}
				onCancelClick={onCloseClick}
				selectWithDescription
				workspace={project.workspace}
				projectMemberRoleName={projectMemberRoleName}
			/>
		);

		const separationLine = (
			<div className="separation-line" />
		);

		const projectMembersInvites = (
			<>
				<h3>{this.projectMembersTitle}</h3>
				<div className="members-invites">
					{members.map( member => (
						<ProjectMemberWithRoleContainer
							key={member.id}
							projectID={projectID}
							member={member}
						/>
					) )}
					{pendingInvites.length > 0
						&& (
							<div>
								{pendingInvites.map( invite => (
									<ProjectInvitationContainer
										key={invite.id}
										projectID={projectID}
										invite={invite}
									/>
								) )}
							</div>
						)}
				</div>
			</>
		);

		return (
			<div className="InviteProjectMemberBox" ref={this.containerRef}>
				<div className="box-container">
					{useAlternateLayout
						? [ projectMembersInvites,
							separationLine,
							inviteProjectMemberComponent ]
						: [ shareGuestLinkComponent,
							separationLine,
							inviteProjectMemberComponent,
							separationLine,
							projectMembersInvites ]}
				</div>
			</div>
		);
	}
}

InviteProjectMemberBox.propTypes = {
	projectID: PropTypes.number.isRequired,
	currentUserRole: PropTypes.instanceOf( ProjectRole ).isRequired,
	members: PropTypes.arrayOf( PropTypes.instanceOf( ProjectMember ) ),
	pendingInvites: PropTypes.arrayOf( PropTypes.instanceOf( ProjectInvitation ) ),
	sendingInvitation: PropTypes.bool,
	onInviteClick: PropTypes.func,
	onCloseClick: PropTypes.func,
	membersCanBeInvited: PropTypes.bool,
	useAlternateLayout: PropTypes.bool,
	projectMemberRoleName: PropTypes.string.isRequired,
	project: PropTypes.instanceOf( Project ),
	updateGuestHashEnabled: PropTypes.func,
	currentUserCanShareGuestLink: PropTypes.bool
};

InviteProjectMemberBox.defaultProps = {
	members: [],
	pendingInvites: [],
	sendingInvitation: false,
	onInviteClick: () => {},
	onCloseClick: () => {},
	membersCanBeInvited: false,
	useAlternateLayout: false,
	project: undefined,
	updateGuestHashEnabled: () => {},
	currentUserCanShareGuestLink: false
};

export default InviteProjectMemberBox;
