import { Dispatch } from 'redux';
import Api from '../services/Api';
import { WORKSPACES_URL } from '../config/urls';
import {
	CREATE_CONTACT_GROUP,
	DELETE_CONTACT_GROUP,
	DELETE_LABEL_GROUP,
	FETCH_CONTACT_GROUPS,
	FETCH_LABEL_GROUPS,
	UPDATE_CONTACT_GROUP,
	CREATE_LABEL_GROUP,
	UPDATE_LABEL_GROUP
} from '../actions/types';
import { type ContactGroup } from '../types/contactGroup';
import { type LabelGroupJSON, LabelParams } from '../entities/labelGroup';

type CreateContactGroupParams = {
	workspaceID: number,
	name: string,
	emails: string[]
};

type CreateLabelGroupParams = {
	workspaceID: number,
	name: string,
	labels: LabelParams[]
};

type UpdateContactGroupParams = CreateContactGroupParams & { contactGroupID: number }
type UpdateLabelGroupParams = CreateLabelGroupParams & { labelGroupID: number }

const labelParamsToJSON = ( labels: LabelParams[] ) => labels.map(
	( { name, baseColor, hoverColor } ) => ( {
		name,
		base_color: baseColor,
		hover_color: hoverColor
	} )
);

export default class WorkspaceSystem {
	private dispatch: Dispatch<any>

	constructor( dispatch : Dispatch<any> ) {
		this.dispatch = dispatch;
	}

	getContactsGroups = ( workspaceID : number ) => (
		this.dispatch( ( dispatch: Dispatch<any>, _: undefined, api: Api ) => dispatch( {
			type: FETCH_CONTACT_GROUPS,
			promise: api
				.get( `${WORKSPACES_URL}/${workspaceID}/contact_groups` )
				.then( ( payload : { response: ContactGroup[] } ) => payload.response ),
			meta: { workspaceID }
		} ) )
	);

	createContactGroup = ( { workspaceID, name, emails }: CreateContactGroupParams ) => (
		this.dispatch( ( dispatch: Dispatch<any>, _: unknown, api: Api ) => dispatch( {
			type: CREATE_CONTACT_GROUP,
			promise: api
				.post( `${WORKSPACES_URL}/${workspaceID}/contact_groups`, { name, emails } )
				.then( ( payload: any ) => payload.response ),
			meta: { workspaceID }
		} ) )
	);

	updateContactGroup = ( { workspaceID, contactGroupID, name, emails }: UpdateContactGroupParams ) => (
		this.dispatch( ( dispatch: Dispatch<any>, _: unknown, api: Api ) => dispatch( {
			type: UPDATE_CONTACT_GROUP,
			promise: api
				.put( `${WORKSPACES_URL}/${workspaceID}/contact_groups/${contactGroupID}`, { name, emails } )
				.then( ( payload: any ) => payload.response ),
			meta: { workspaceID }
		} ) )
	);

	deleteContactGroup = ( workspaceID: number, contactGroupID: number ) => (
		this.dispatch( ( dispatch: Dispatch<any>, _: unknown, api: Api ) => dispatch( {
			type: DELETE_CONTACT_GROUP,
			promise: api
				.delete( `${WORKSPACES_URL}/${workspaceID}/contact_groups/${contactGroupID}` )
				.then( ( payload: any ) => payload.response ),
			meta: { workspaceID }
		} ) )
	);

	getLabelGroups = ( workspaceID : number ) => (
		this.dispatch( ( dispatch: Dispatch<any>, _: undefined, api: Api ) => dispatch( {
			type: FETCH_LABEL_GROUPS,
			promise: api
				.get( `${WORKSPACES_URL}/${workspaceID}/label_groups` )
				.then( ( payload : { response: LabelGroupJSON[] } ) => payload.response ),
			meta: { workspaceID }
		} ) )
	);

	createLabelGroup = ( { workspaceID, name, labels }: CreateLabelGroupParams ) => (
		this.dispatch( ( dispatch: Dispatch<any>, _: unknown, api: Api ) => dispatch( {
			type: CREATE_LABEL_GROUP,
			promise: api
				.post( `${WORKSPACES_URL}/${workspaceID}/label_groups`, {
					name,
					labels: labelParamsToJSON( labels )
				} )
				.then( ( payload: any ) => payload.response ),
			meta: { workspaceID }
		} ) )
	);

	updateLabelGroup = ( { workspaceID, labelGroupID, name, labels }: UpdateLabelGroupParams ) => (
		this.dispatch( ( dispatch: Dispatch<any>, _: unknown, api: Api ) => dispatch( {
			type: UPDATE_LABEL_GROUP,
			promise: api
				.put( `${WORKSPACES_URL}/${workspaceID}/label_groups/${labelGroupID}`, {
					name,
					labels: labelParamsToJSON( labels )
				} )
				.then( ( payload: any ) => payload.response ),
			meta: { workspaceID }
		} ) )
	);

	deleteLabelGroup = ( workspaceID: number, labelGroupID: number ) => (
		this.dispatch( ( dispatch: Dispatch<any>, _: unknown, api: Api ) => dispatch( {
			type: DELETE_LABEL_GROUP,
			promise: api
				.delete( `${WORKSPACES_URL}/${workspaceID}/label_groups/${labelGroupID}` )
				.then( ( payload: any ) => payload.response ),
			meta: { workspaceID }
		} ) )
	);
}
