import { HIDE_OVERLAY, SHOW_OVERLAY } from '../actions/types';

export default class OverlaySystem {
	constructor( dispatch ) {
		this.dispatch = dispatch;
	}

	toggleOverlay( showingOverlay ) {
		if ( showingOverlay ) return this.hideOverlay();
		return this.showOverlay();
	}

	showOverlay() {
		return this.dispatch( { type: SHOW_OVERLAY } );
	}

	hideOverlay() {
		return this.dispatch( { type: HIDE_OVERLAY } );
	}
}
