import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import Api from '../../services/Api';
import { clearLogin } from '../../actions/authentication';
import { SESSIONS_URL } from '../../config/urls';
import { useRootStore } from './RootStoreProvider';
import { api as globalApi } from '../../store/store';

const ApiContext = React.createContext<Api | null>( null );

interface ApiProviderProps {
	api?: Api,
	children: React.ReactNode
}

export const useHandleUnauthorizedResponse = () => {
	const { authenticationStore } = useRootStore();
	const queryClient = useQueryClient();
	const dispatch = useDispatch();

	return ( { method, url } : { method: string, url: string } ) => {
		const isLogout = method === 'DELETE' && url === SESSIONS_URL;
		if ( !isLogout ) {
			queryClient.removeQueries();
			authenticationStore.clear();
			dispatch( clearLogin() );
		}
	};
}

export const ApiProvider = ( { api = new Api(), children } : ApiProviderProps ) => {
	const handleUnauthorizedResponse = useHandleUnauthorizedResponse();
	api.addLogoutOnUnauthorizedResponse( handleUnauthorizedResponse );
	globalApi.addLogoutOnUnauthorizedResponse( handleUnauthorizedResponse );
	return (
		<ApiContext.Provider value={api}>
			{children}
		</ApiContext.Provider>
	);
};

export const useApi = () => React.useContext( ApiContext ) as Api;
