export default class PricingPlanPrice {
	constructor( {
		id, billingPeriod, cost, pricingPlan
	} ) {
		this.id = id;
		this.billingPeriod = billingPeriod;
		this.cost = cost;
		this.pricingPlan = pricingPlan;
	}

	static fromJSON( properties, pricingPlan ) {
		return new PricingPlanPrice( {
			id: properties.id,
			billingPeriod: properties.billing_period,
			cost: properties.cost,
			pricingPlan
		} );
	}

	get planName() {
		return this.pricingPlan && this.pricingPlan.name;
	}

	get includedProjects() {
		return this.pricingPlan && this.pricingPlan.includedProjects;
	}

	get includedMembers() {
		return this.pricingPlan?.includedMembers;
	}

	get includedStorage() {
		return this.pricingPlan?.includedStorage;
	}

	get isMonthly() {
		return this.billingPeriod === 'month';
	}

	get isAnnualPrice() {
		return this.billingPeriod === 'year';
	}

	get isFree() {
		return this.cost === 0;
	}
}
