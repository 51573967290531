import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { connectComponent } from '../../lib/connectComponent';

import Notification from '../../entities/notification';
import Project from '../../entities/project';
import { getNotificationsForProject, getProjectNotificationsHasNextPage, getIsFilteringNotificationForProject } from '../../selectors/notifications';
import { getActiveProject, getActiveProjectBelongsToOwnWorkspace } from '../../selectors/projects';

import NotificationsList from '../../components/notifications/NotificationsList/NotificationsList';
import NotificationsSuspendedWorkspaceOverlay from '../../components/notifications/NotificationsSuspendedWorkspaceOverlay/NotificationsSuspendedWorkspaceOverlay';

import AsyncApiActionPropType, { defaultProp as defaultAsyncActionDefaultProp } from '../../types/asyncApiAction';
import Workspace from '../../entities/workspace';
import { getCurrentUserOwnWorkspace } from '../../selectors/workspaces';

export class NotificationsListContainer extends PureComponent {
	constructor( props ) {
		super( props );

		this._onFetchNextPage = this._onFetchNextPage.bind( this );
	}

	componentDidMount() {
		this._fetchProjectNotifications();
	}

	componentDidUpdate( prevProps ) {
		const { props: { projectID, markNotificationsAsSeen } } = this;
		const prevProjectID = prevProps.projectID;

		if ( projectID !== prevProjectID ) {
			this._fetchProjectNotifications();

			if ( markNotificationsAsSeen ) {
				markNotificationsAsSeen( prevProjectID );
			}
		}
	}

	componentWillUnmount() {
		const { props: { projectID, markNotificationsAsSeen } } = this;

		if ( markNotificationsAsSeen ) {
			markNotificationsAsSeen( projectID );
		}
	}

	get isFetchingFirstPage() {
		const {
			props: { fetchProjectNotificationsRequest }
		} = this;

		return fetchProjectNotificationsRequest
			&& fetchProjectNotificationsRequest.sending
			&& fetchProjectNotificationsRequest.meta.page === 1
			&& !fetchProjectNotificationsRequest.meta.isRefreshing;
	}

	get project() {
		return this.props.project;
	}

	get _showSuspendedWorkspaceOverlay() {
		return this._projectBelongsToSuspendedWorkspace || this._noProjectAndSuspendedWorkspace;
	}

	get _projectBelongsToSuspendedWorkspace() {
		return !!this.project && !this.project.hasActiveWorkspace;
	}

	get _noProjectAndSuspendedWorkspace() {
		return !this.project && this._currentUserOwnsSuspendedWorkspace;
	}

	get _currentUserOwnsSuspendedWorkspace() {
		return !!this.props.currentUserOwnWorkspace && !this.props.currentUserOwnWorkspace.isActive;
	}

	get _showButtonToManageSubscription() {
		return !!this.props.projectBelongsToOwnWorkspace;
	}

	get _redirectionURL() {
		return `/user/workspaces/${this.props.currentUserOwnWorkspace?.id}/plan`;
	}

	_fetchProjectNotifications() {
		const { props: { projectID, fetchProjectNotifications } } = this;
		fetchProjectNotifications( projectID );
	}

	_fetchProjectNotificationsNextPage() {
		const { props: { fetchProjectNotificationsNextPage, projectID } } = this;

		if ( fetchProjectNotificationsNextPage ) {
			fetchProjectNotificationsNextPage( projectID );
		}
	}

	_onFetchNextPage() {
		this._fetchProjectNotificationsNextPage();
	}

	render() {
		const {
			props: {
				projectID, notifications, onNotificationClicked, hasNextPage,
				filterIsApplied, onNotificationProjectClicked, onNotificationFolderClicked
			},
			isFetchingFirstPage
		} = this;

		return (
			<>
				<NotificationsSuspendedWorkspaceOverlay
					redirectionURL={this._redirectionURL}
					onButtonClick={this.props.onClose}
					showButton={this._showButtonToManageSubscription}
					visible={this._showSuspendedWorkspaceOverlay}
				/>
				<NotificationsList
					filterIsApplied={filterIsApplied}
					projectID={projectID}
					hasNextPage={hasNextPage}
					showLoader={isFetchingFirstPage}
					showEmptyMessage={!isFetchingFirstPage}
					onFetchNextPage={this._onFetchNextPage}
					onNotificationClicked={onNotificationClicked}
					notifications={!isFetchingFirstPage ? notifications : []}
					onNotificationProjectClicked={onNotificationProjectClicked}
					onNotificationFolderClicked={onNotificationFolderClicked}
				/>
			</>
		);
	}
}

NotificationsListContainer.propTypes = {
	filterIsApplied: PropTypes.bool,
	projectID: PropTypes.number.isRequired,
	notifications: PropTypes.arrayOf( PropTypes.instanceOf( Notification ) ),
	hasNextPage: PropTypes.bool,
	fetchProjectNotificationsRequest: AsyncApiActionPropType,
	markNotificationsAsSeen: PropTypes.func,
	onNotificationClicked: PropTypes.func,
	fetchProjectNotifications: PropTypes.func,
	fetchProjectNotificationsNextPage: PropTypes.func,
	project: PropTypes.instanceOf( Project ),
	currentUserOwnWorkspace: PropTypes.instanceOf( Workspace ),
	projectBelongsToOwnWorkspace: PropTypes.bool,
	onClose: PropTypes.func,
	onNotificationProjectClicked: PropTypes.func,
	onNotificationFolderClicked: PropTypes.func
};

NotificationsListContainer.defaultProps = {
	filterIsApplied: false,
	notifications: [],
	hasNextPage: false,
	fetchProjectNotificationsRequest: defaultAsyncActionDefaultProp,
	markNotificationsAsSeen: undefined,
	onNotificationClicked: undefined,
	fetchProjectNotifications: undefined,
	fetchProjectNotificationsNextPage: undefined,
	project: undefined,
	currentUserOwnWorkspace: undefined,
	projectBelongsToOwnWorkspace: false,
	onClose: undefined,
	onNotificationProjectClicked: undefined,
	onNotificationFolderClicked: undefined
};

export default connectComponent( ( state, props ) => ( {
	currentUserOwnWorkspace: getCurrentUserOwnWorkspace( state ),
	notifications: getNotificationsForProject( state, props ),
	filterIsApplied: getIsFilteringNotificationForProject( state, props ),
	hasNextPage: getProjectNotificationsHasNextPage( state, props ),
	fetchProjectNotificationsRequest: state.fetchProjectNotificationsRequest,
	project: getActiveProject( state, props ),
	projectBelongsToOwnWorkspace: getActiveProjectBelongsToOwnWorkspace( state, props )
} ) )( NotificationsListContainer );
