import PropTypes from 'prop-types';

import Tabs from '../Tabs/Tabs';

import './UserProfilePanel.scss';

const UserProfilePanel = ( { tabs, selectedTabIndex, onTabChange } ) => {
	const tabItems = tabs.map( tab => ( { label: tab.name } ) );
	const CurrentTabComponent = tabs[ selectedTabIndex ].component;

	return (
		<div className="UserProfilePanel">
			<div className="header-and-body">
				<div className="header">
					<Tabs
						tabSelected={selectedTabIndex}
						tabs={tabItems}
						onTabChange={onTabChange}
					/>
				</div>
				<div className="body">
					<CurrentTabComponent />
				</div>
			</div>
		</div>
	);
};

UserProfilePanel.propTypes = {
	selectedTabIndex: PropTypes.number,
	onTabChange: PropTypes.func,
	tabs: PropTypes.arrayOf( PropTypes.shape( {
		name: PropTypes.string.isRequired,
		component: PropTypes.any.isRequired
	} ) )
};

UserProfilePanel.defaultProps = {
	tabs: [],
	selectedTabIndex: 0,
	onTabChange: undefined
};

export default UserProfilePanel;
