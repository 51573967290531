import { useMemo } from 'react';
import LoaderButton from '../../components/UI/LoaderButton/LoaderButton';
import Coupon from '../../entities/coupon';
import PricingPlanPrice from '../../entities/pricing/pricingPlanPrice';

import './PayButton.scss';

export type PayButtonProps = {
	coupon: Coupon | null;
	disabled: boolean;
	onClick: () => void;
	pricingPlanPrice: PricingPlanPrice;
	sending: boolean;
};

const PayButton = ( { coupon, disabled, onClick, pricingPlanPrice, sending }: PayButtonProps ) => {
	const payButtonContent = useMemo( () => {
		const originalPrice = pricingPlanPrice.cost.toFixed( 2 );
		if ( !coupon ) { return `Pay $${originalPrice}`; }

		const priceWithDiscount = coupon.applyDiscountTo( pricingPlanPrice );
		return (
			<div className="priceContainer">
				<span className="originalPrice" data-testid="original-price">{`$${originalPrice}`}</span>
				<span className="newPrice" data-testid="new-price">{` Pay $${priceWithDiscount.toFixed( 2 )}`}</span>
			</div>
		);
	}, [ coupon, pricingPlanPrice ] );

	return (
		<div className="PayButton">
			<LoaderButton
				loading={sending}
				disabled={disabled}
				size="large"
				onClick={onClick}
			>
				{payButtonContent}
			</LoaderButton>
		</div>
	);
};

export default PayButton;
