import PropTypes from 'prop-types';

import './AssetsDropFilesIndicatorBox.scss';

import uploadWitheArrowIcon
	from '../../../assets/img/icons/upload-white-arrow-icon.svg';
import { fileNameTextTruncate } from '../../../lib/textTruncate';

const NewRoundIndicatorText = ( { assetName } ) => (
	<>
		<h1> Upload new round of {fileNameTextTruncate( assetName, 25, 30 )}</h1>
		<p>Drop a new asset here. You can upload only 1 file.</p>
	</>
);

NewRoundIndicatorText.propTypes = {
	assetName: PropTypes.string.isRequired
};

const NewAssetIndicatorText = ( { projectName, folderName } ) => (
	<>
		<h1>Upload to “{folderName || projectName}”</h1>
		<p>Drop your assets or documents here.</p>
	</>
);

NewAssetIndicatorText.propTypes = {
	projectName: PropTypes.string.isRequired,
	folderName: PropTypes.string
};

NewAssetIndicatorText.defaultProps = {
	folderName: undefined
};

const AssetsDropFilesIndicatorBox = ( {
	projectName, folderName, assetName, uploadingRoundForAsset
} ) => (
	<div className="AssetsDropFilesIndicatorBox">
		<div className="drop-files-message">
			<img src={uploadWitheArrowIcon} alt="upload" />
			{uploadingRoundForAsset && assetName
				? <NewRoundIndicatorText assetName={assetName} />
				: <NewAssetIndicatorText projectName={projectName} folderName={folderName} />}
			<p className="extensions-suggestion">
				Some valid extensions are: <br /> jpeg, png, tiff, pdf, psd.
			</p>
		</div>
	</div>
);

AssetsDropFilesIndicatorBox.propTypes = {
	projectName: PropTypes.string.isRequired,
	folderName: PropTypes.string,
	assetName: PropTypes.string,
	uploadingRoundForAsset: PropTypes.bool
};

AssetsDropFilesIndicatorBox.defaultProps = {
	folderName: undefined,
	assetName: undefined,
	uploadingRoundForAsset: false
};

export default AssetsDropFilesIndicatorBox;
