import Pusher, { Channel, ChannelAuthorizationCallback } from 'pusher-js';
import { PUSHER_APP_CLUSTER, PUSHER_APP_KEY } from '../../config/global';
import type { AssetProcessingEventHandler, DownloadEventHandler, MessagingService } from './types';
import { PUSHER_AUTH_URL } from '../../config/urls';

const ASSET_PROCESSING_FINISHED_EVENT = 'processing-finished';

// This custom authorizer sends the request to the server
// including credentials, so the server can authenticate the request.
// Pusher default authorizer does not include credentials.
const authorizer = ( channel: Channel ) => ( {
	authorize: ( socketId: string, callback: ChannelAuthorizationCallback ) => {
		const body = new FormData();
		body.append( 'socket_id', socketId );
		body.append( 'channel_name', channel.name );

		fetch( PUSHER_AUTH_URL, {
			method: 'POST',
			credentials: 'include',
			body
		} )
			.then( async ( response ) => {
				const data = await response.json();
				callback( null, data )
			} )
			.catch( ( error ) => {
				callback( new Error( `Error authenticating with server: ${error}` ), {
					auth: ''
				} )
			} )
	}
} )

export default class PusherMessagingService implements MessagingService {
	pusher?: Pusher;

	subscribeToDownload( downloadID: string, eventHandler: DownloadEventHandler ) {
		const downloadChannel = this.subscribeToChannel( this.channelNameForDownload( downloadID ) );
		downloadChannel.bind( 'progress', eventHandler );
	}

	unsubscribeFromDownload( downloadID: string ) {
		this.unsubscribeFromChannel( this.channelNameForDownload( downloadID ) );
	}

	subscribeToAssetsProcessingFinished( projectID: number, handler: AssetProcessingEventHandler ) {
		this
			.subscribeToChannel( this.channelNameforAssetProcessing( projectID ) )
			.bind( ASSET_PROCESSING_FINISHED_EVENT, handler );
	}

	unsubscribeFromAssetsProcessingFinished( projectID: number ) {
		this.unsubscribeFromChannel( this.channelNameforAssetProcessing( projectID ) );
	}

	isSubscribedToAssetsProcessingFinished( projectID: number ) {
		return this.isSubscribedToChannel( this.channelNameforAssetProcessing( projectID ) );
	}

	private channelNameForDownload( downloadID: string ) {
		return `cache-download-${downloadID}`;
	}

	private channelNameforAssetProcessing( projectID: number ) {
		return `private-project-${projectID}-assets-processing`;
	}

	private subscribeToChannel( channelName: string ) {
		this.ensureConnection();

		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		return this.pusher!.subscribe( channelName );
	}

	private unsubscribeFromChannel( channelName: string ) {
		this.pusher?.unsubscribe( channelName );
		this.checkIfConnectionCanBeClosed();
	}

	private isSubscribedToChannel( channelName: string ) {
		return this.pusher?.channel( channelName )?.subscribed || false;
	}

	private ensureConnection() {
		if ( !this.pusher ) {
			this.initializePusherClient();
		} else if ( this.pusher.connection.state === 'disconnected' ) {
			this.pusher.connect();
		}
	}

	private initializePusherClient() {
		if ( !PUSHER_APP_KEY || !PUSHER_APP_CLUSTER ) {
			throw new Error( 'Missing Pusher configuration' );
		}

		this.pusher = new Pusher( PUSHER_APP_KEY, {
			cluster: PUSHER_APP_CLUSTER,
			authorizer
		} );
	}

	private checkIfConnectionCanBeClosed() {
		if ( this.pusher && this.pusher.allChannels().length === 0 ) {
			this.pusher.disconnect();
		}
	}
}
