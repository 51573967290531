import { memo, createElement } from 'react';
import { ReactComponent as HideIcon } from '../../../../assets/img/icons/hide-icon.svg';
import { ReactComponent as UnhideIcon } from '../../../../assets/img/icons/unhide-icon.svg';
import { ReactComponent as AttachIcon } from '../../../../assets/img/icons/attach-icon.svg';
import { ReactComponent as DisabledAttachIcon } from '../../../../assets/img/icons/disabled-attach-icon.svg';
import { ReactComponent as ChromaticCircle } from '../../../../assets/img/icons/chromatic-circle.svg';
import { ReactComponent as Leave } from '../../../../assets/img/icons/leave.svg';
import { ReactComponent as Add } from '../../../../assets/img/icons/add.svg';
import { ReactComponent as PriorityFlag } from '../../../../assets/img/icons/priority-flag.svg';
import { ReactComponent as PriorityFlagShadow } from '../../../../assets/img/icons/priority-flag-shadow.svg';
import { ReactComponent as ApprovedIcon } from '../../../../assets/img/icons/approved.svg';
import { ReactComponent as WrenchIcon } from '../../../../assets/img/icons/wrench.svg';
import { ReactComponent as FilterIcon } from '../../../../assets/img/icons/filter-icon.svg';
import { ReactComponent as BrushIcon } from '../../../../assets/img/icons/brush-icon.svg';
import { ReactComponent as FileUploadIcon } from '../../../../assets/img/icons/file-upload.svg';
import { ReactComponent as FolderUploadIcon } from '../../../../assets/img/icons/folder-upload.svg';
import { ReactComponent as DriveFileRenameOutline } from '../../../../assets/img/icons/drive-file-rename-outline.svg';
import { ReactComponent as UnapproveIcon } from '../../../../assets/img/icons/unapprove.svg';
import { ReactComponent as NorthWestIcon } from '../../../../assets/img/icons/north-west.svg';
import { ReactComponent as PointerIcon } from '../../../../assets/img/icons/pointer.svg';
import { ReactComponent as ArrowOutwardIcon } from '../../../../assets/img/icons/arrow-outward.svg';
import { ReactComponent as LineIcon } from '../../../../assets/img/icons/line.svg';
import { ReactComponent as SquareIcon } from '../../../../assets/img/icons/square.svg';
import { ReactComponent as FolderCoverIcon } from '../../../../assets/img/icons/folder-cover.svg';
import { ReactComponent as SquareEditOutlinedIcon } from '../../../../assets/img/icons/square-edit-outlined.svg';

export const SVG_ICONS = {
	'hide': HideIcon,
	'unhide': UnhideIcon,
	'attach': AttachIcon,
	'disabled_attach': DisabledAttachIcon,
	'chromatic-circle': ChromaticCircle,
	'leave': Leave,
	'add': Add,
	'priority-flag': PriorityFlag,
	'priority-flag-shadow': PriorityFlagShadow,
	'approved': ApprovedIcon,
	'wrench': WrenchIcon,
	'filter-icon': FilterIcon,
	'brush': BrushIcon,
	'file-upload': FileUploadIcon,
	'folder-upload': FolderUploadIcon,
	'drive-file-rename-outline': DriveFileRenameOutline,
	'unapprove': UnapproveIcon,
	'north-west': NorthWestIcon,
	'pointer': PointerIcon,
	'arrow-outward': ArrowOutwardIcon,
	'line': LineIcon,
	'square': SquareIcon,
	'folder-cover': FolderCoverIcon,
	'square-edit-outlined': SquareEditOutlinedIcon
};

export type SvgIconString = keyof typeof SVG_ICONS;

type SvgIconProps = { icon: SvgIconString; size: number; color: string; className?: string; }

const SvgIcon = memo( ( { icon, size, className, color }: SvgIconProps ) => createElement(
	SVG_ICONS[ icon ],
	{
		className,
		style: { 'width': size, 'height': size, '--color': color }
	}
) );

export default SvgIcon;
