import { formatFileSize } from '../lib/fileSizeFormatters';
import { ITEM_UPLOAD_STATUS, ITEM_UPLOAD_STATUS_ORDER } from '../services/DirectFilesUploaderCoordinator';
import ImmutableEntity from './immutableEntity';

export default class ItemUpload extends ImmutableEntity {
	get progressText() {
		if ( this.hasFailed || this.wasCanceled ) { return ''; }
		if ( this.isWaiting ) { return this.formattedSize; }
		return `${this.formattedBytesUploaded} of ${this.formattedSize}`;
	}

	get formattedSize() {
		if ( Number.isNaN( this.size ) ) { return formatFileSize( 0 ); }
		return formatFileSize( this.size );
	}

	get formattedBytesUploaded() {
		return formatFileSize( this.bytesUploaded );
	}

	get isCompleted() {
		return this.status === ITEM_UPLOAD_STATUS.SUCCEEDED;
	}

	get wasCanceled() {
		return this.status === ITEM_UPLOAD_STATUS.ABORTED;
	}

	get hasFailed() {
		return this.status === ITEM_UPLOAD_STATUS.FAILED;
	}

	get isUploading() {
		return this.status === ITEM_UPLOAD_STATUS.UPLOADING;
	}

	get isWaiting() {
		return this.status === ITEM_UPLOAD_STATUS.WAITING;
	}

	get statusOrder() {
		return ITEM_UPLOAD_STATUS_ORDER[ this.status ];
	}
}
