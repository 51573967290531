import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Button from '../../new_arch/components/Button/Button';
import CloseableModalWithTitle from '../CloseableModalWithTitle/CloseableModalWithTitle';
import Checkbox from '../../components/UI/Checkbox/Checkbox';
import { PRIMARY_BASE } from '../../styles/colors';

import './ContactsGroupsModal.scss';
import useContactsGroupsModalPresenter from '../../presenters/ContactsGroupsModalPresenter/useContactsGroupsModalPresenter';
import Workspace from '../../entities/workspace';

type ContactsGroupsModalProps = {
	workspace: Workspace;
	onClose: () => void;
	onConfirm: ( emails: string[] ) => void;
}

const ContactsGroupsModal = ( { workspace, onClose, onConfirm } : ContactsGroupsModalProps ) => {
	const presenter = useContactsGroupsModalPresenter( workspace );

	useEffect( () => {
		presenter.onMount().then( () => presenter.setLoading( false ) );
	}, [] );

	const handleCheckboxChange = ( groupID: number ) : void => presenter.toggleGroupCheck( { groupID } );

	const uncheckAllGroups = () => {
		presenter.uncheckAllGroups();
	};

	const onModalClosed = () => {
		onClose();
		uncheckAllGroups();
	}

	const onAddEmailsClicked = () => {
		const emails = presenter.getEmailsFromCheckedGroups();
		onConfirm( emails );
		uncheckAllGroups();
		onModalClosed();
	};

	const onSettingsClicked = () => (
		<a className="linkToSettings" href={`/user/workspaces/${presenter.workspaceID}/projects`}>workspace settings</a>
	);

	const renderActions = () => (
		<div className="actions">
			{presenter.thereAreGroups ? (
				<div className="contact-groups-buttons">
					<Button type="text" color="secondary" onClick={onModalClosed}>Cancel</Button>
					<Button onClick={onAddEmailsClicked}>Add</Button>
				</div>
			) : <Button onClick={onModalClosed}>Got it</Button>}
		</div>
	);

	const renderFooter = () => (
		<div className="contact-groups-footer">
			{presenter.userCanManageContactsGroups
				? <p>You can create or modify from {onSettingsClicked()}</p>
				: (
					<p>Only owners and members can create or modify</p>
				)}
		</div>
	);

	const renderContactsGroups = () => (
		<div className="contact-groups">
			{presenter.getContactsGroups.map( group => (
				<div className="GroupItem" key={group.id}>
					<Checkbox
						key={group.name}
						label={group.name}
						onChange={() => handleCheckboxChange( group.id )}
						value={presenter.isGroupChecked( { groupID: group.id } )}
						checkedColor={PRIMARY_BASE}
					/>
				</div>
			) ) }
		</div>
	);

	const renderEmptyState = () => (
		<div className="emptyState">
			<p>There aren&apos;t any groups available.</p>
			{presenter.userCanManageContactsGroups
				? <p>Create or modify from<br /> {onSettingsClicked()}</p>
				: (
					<p>Only owners and members can<br />create or modify from workspace<br />settings</p>
				)}
		</div>
	);

	const renderContent = () => {
		if ( presenter.isLoading ) return ( <p className="loading">Loading...</p> );
		return presenter.thereAreGroups ? renderContactsGroups() : renderEmptyState();
	}

	return (
		<CloseableModalWithTitle
			headerBorderType="small"
			onClose={onClose}
			name="contact-groups"
			title="Collaborator Groups"
		>
			<div className="ContactsGroupsModal">
				{renderContent()}
				{!presenter.isLoading && renderActions()}
				{( !presenter.isLoading && presenter.thereAreGroups ) && renderFooter()}
			</div>
		</CloseableModalWithTitle>
	);
}

export default observer( ContactsGroupsModal );
