import Icon from '../../new_arch/components/Icon/Icon';
import Tooltip from '../UI/Tooltip/Tooltip';
import { PRIMARY_BASE, WARNING_BASE, SUCCESS, WHITE } from '../../styles/colors';
import BulletedList from '../BulletedList/BulletedList';

const ShareLinkTooltip = ( { canShareGuestLink } : { canShareGuestLink?: boolean } ) => {
	const memberTooltipData = (
		<div className="tippy-tooltip-content" style={{ textAlign: 'left', fontSize: '10px' }}>
			<h5 className="tooltip-text" style={{ fontSize: '12px' }}>Share invite link</h5>
			<div style={{ display: 'flex', gap: '5px', alignItems: 'baseline' }}>
				<span className="tooltip-text" style={{ color: WARNING_BASE, marginTop: '5px' }}>Low security</span>
				<Icon
					icon="trending-down"
					size={10}
					color={WARNING_BASE}
				/>
			</div>
			<BulletedList icon="dot" color={WHITE} size={5}>
				<p className="tooltip-text">No account needed to access<br />and collaborate (accountless<br />collaboration)</p>
				<p className="tooltip-text">Link is public, once enabled<br />anyone with it can access</p>
			</BulletedList>
			<h5 className="tooltip-text" style={{ fontSize: '12px' }}>Invite collaborators</h5>
			<div style={{ display: 'flex', gap: '5px', alignItems: 'baseline' }}>
				<span className="tooltip-text" style={{ color: SUCCESS, marginTop: '5px' }}>High security</span>
				<Icon
					icon="trending-up"
					size={10}
					color={SUCCESS}
				/>
			</div>
			<BulletedList icon="dot" color={WHITE} size={5}>
				<p className="tooltip-text">Sign-in / sign-up (free)<br />required to access and<br />collaborate</p>
				<p className="tooltip-text">By in-app email invitation only<br />(no share link)</p>
			</BulletedList>
		</div>
	);

	const collaboratorTooltipData = (
		<p className="tooltip-text">To access ask the owner to grant you a paid seat</p>
	);

	return (
		<div className="ShareLinkTooltip">
			<Tooltip
				className="share-link-tooltip"
				html={canShareGuestLink ? memberTooltipData : collaboratorTooltipData}
				position="right"
			>
				<div className="more-info">
					<p>{canShareGuestLink ? 'More Info' : 'Paid users'}</p>
					<Icon
						icon="info_outlined"
						size={14}
						color={PRIMARY_BASE}
					/>
				</div>
			</Tooltip>
		</div>
	);
};

export default ShareLinkTooltip;
