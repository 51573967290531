import AsyncActionPresenter from '../presenters/AsyncActionPresenter/AsyncActionPresenter';
import SnackbarFactory from './SnackbarFactory';

export default class AsyncBaseDownloadAssetsPresenterFactory {
	_forDownloadAssets( { action, onFinish, snackbarSystem } ) {
		return new AsyncActionPresenter( {
			action,
			onSuccess: onFinish,
			onError: onFinish,
			successMessage: ( result ) => {
				if ( result?.isDirectDownload || result.payload?.isDirectDownload ) {
					SnackbarFactory.forDownloadAssetSuccess( { snackbarSystem } );
				}
			},
			errorMessage: () => SnackbarFactory.defaultErrorMessage( snackbarSystem )
		} );
	}
}
