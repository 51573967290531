import { Location } from 'history';
import mixpanel from 'mixpanel-browser';
import TrackerBackend from './TrackerBackend';
import { METRICS_PROXY_URL, MIXPANEL_TOKEN } from '../../config/global';

class MixpanelTrackerBackend implements TrackerBackend {
	initialize() {
		mixpanel.init(
			MIXPANEL_TOKEN,
			{
				api_host: METRICS_PROXY_URL,
				track_pageview: true
			}
		);
	}

	setUserID( userId: number ) {
		mixpanel.identify( userId.toString() );
	}

	setSuperProperties( properties: Record<string, unknown> ) {
		mixpanel.register( properties );
	}

	setUserProperties( properties: Record<string, unknown> ) {
		mixpanel.people.set( properties );
	}

	trackLocation( location: Location ) {
		mixpanel.track_pageview( { location: location.pathname } );
	}

	trackPanel( panelName: string ) {
		mixpanel.track_pageview( { panel: `/panel/${panelName}` } );
	}

	trackEvent( args: { category: string, action: string, [key: string]: unknown } ) {
		mixpanel.track( args.action, { ...args } );
	}
}

export default MixpanelTrackerBackend;
