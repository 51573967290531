import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import WhiteBox from '../../WhiteBox/WhiteBox';
import Button from '../../../new_arch/components/Button/Button';
import BillingInformation from '../BillingInformation/BillingInformation';
import CreditCardInformation from '../CreditCardInformation/CreditCardInformation';
import WorkspaceBillingInformation from '../../../entities/workspaceBillingInformation';
import CreditCard from '../../../entities/creditCard';
import BillingAndPaymentInformationEditModalContainer from '../../../containers/BillingAndPaymentInformationEditModalContainer/BillingAndPaymentInformationEditModalContainer';
import InformationTooltipIcon from '../../UI/InformationTooltipIcon/InformationTooltipIcon';

import './WorkspaceBillingAndPaymentInformation.scss';
import { ERROR } from '../../../styles/colors';

const WorkspaceBillingAndPaymentInformation = ( {
	workspaceID,
	billingInformation,
	creditCard,
	onEditPaymentMethodComplete,
	showEditButton,
	showInvalidPaymentMethodTooltip
} ) => {
	const [
		showingBillingAndPaymentInformationEditModal, setShowingBillingAndPaymentInformationEditModal
	] = useState( false );

	const openBillingAndPaymentInformationEditModal = useCallback(
		() => setShowingBillingAndPaymentInformationEditModal( true ), []
	);
	const closeBillingAndPaymentInformationEditModal = useCallback(
		() => setShowingBillingAndPaymentInformationEditModal( false ), []
	);

	const onEditCompleted = useCallback(
		() => {
			closeBillingAndPaymentInformationEditModal();
			onEditPaymentMethodComplete();
		}, [ closeBillingAndPaymentInformationEditModal ]
	);

	return (
		<WhiteBox className="WorkspaceBillingAndPaymentInformation">
			<div className="content">
				{showingBillingAndPaymentInformationEditModal
				&& (
					<BillingAndPaymentInformationEditModalContainer
						workspaceID={workspaceID}
						onClose={closeBillingAndPaymentInformationEditModal}
						onEditComplete={onEditCompleted}
					/>
				)}
				<div className="billing-information-container">
					<h4>Billing Information</h4>
					<BillingInformation billingInformation={billingInformation} />
				</div>
				<div className="payment-method-container">
					<div className="payment-method-title-container">
						<h4>Payment Method</h4>
						{showInvalidPaymentMethodTooltip && (
							<InformationTooltipIcon
								title="Your last payment has failed. Please review <br />
your payment information to prevent your <br />
workspace from being suspended."
								position="right"
								idleIcon="error_outline"
								hoverIcon="error"
								color={ERROR}
							/>
						)}
					</div>
					<CreditCardInformation card={creditCard} />
				</div>
			</div>
			{showEditButton && (
				<div className="button-container">
					<Button size="small" onClick={openBillingAndPaymentInformationEditModal}>
						Edit
					</Button>
				</div>
			)}
		</WhiteBox>
	);
};

WorkspaceBillingAndPaymentInformation.propTypes = {
	workspaceID: PropTypes.number.isRequired,
	billingInformation: PropTypes.instanceOf( WorkspaceBillingInformation ),
	creditCard: PropTypes.instanceOf( CreditCard ),
	onEditPaymentMethodComplete: PropTypes.func,
	showEditButton: PropTypes.bool,
	showInvalidPaymentMethodTooltip: PropTypes.bool
};

WorkspaceBillingAndPaymentInformation.defaultProps = {
	billingInformation: undefined,
	creditCard: undefined,
	onEditPaymentMethodComplete: () => {},
	showEditButton: false,
	showInvalidPaymentMethodTooltip: false
};

export default WorkspaceBillingAndPaymentInformation;
