import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import Button from '../../../new_arch/components/Button/Button';
import PlanSettingsPresenter from '../../../presenters/PlanSettings/PlanSettingsPresenter';
import PlanNameLine from '../PlanNameLine/PlanNameLine';

import './ActiveSubscriptionDetail.scss';

const ActiveSubscriptionDetail = ( {
	presenter
} ) => {
	const renderDescription = () => (
		<div className="buttonLine">
			<div>
				<div className="planSecondLine">
					<h1>{presenter.subscriptionPriceWithDiscount}</h1><h5> /per {presenter.workspace.hasYearlySubscriptionInterval ? 'year' : 'month'}</h5>
					<h4>{presenter.workspace.isSubscriptionMarkedForCancellation ? 'Subscription suspension:' : 'Next payment:'} <span>{presenter.formatedBillingPeriodEnd}</span></h4>
				</div>
			</div>
			<Button
				className="upgradeButton"
				disabled={presenter.upgradeButtonData.disabled}
				size="large"
				type={presenter.upgradeButtonData.type}
				onClick={presenter.onUpgradePlanButtonClicked}
			>
				{presenter.upgradeButtonData.text}
			</Button>
		</div>
	);

	return (
		<div className="subscription-details">
			<div className="planInfoBox">
				<div className="planFirstLine">
					<PlanNameLine planName={presenter.planName} />
					<button
						className="billing-link"
						onClick={presenter.onGoToBillingClicked}
						type="button"
					>
						Go to billing
					</button>
				</div>
				{ renderDescription() }
			</div>
		</div>
	);
};

ActiveSubscriptionDetail.propTypes = {
	presenter: PropTypes.instanceOf( PlanSettingsPresenter ).isRequired
};

export default observer( ActiveSubscriptionDetail );
