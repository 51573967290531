import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import PricingSubscriptionSelectionModal from '../../modals/PricingSubscriptionSelectionModal/PricingSubscriptionSelectionModal';
import WorkspaceSubscriptionCheckoutModalContainer from '../../containers/WorkspaceSubscriptionCheckoutModalContainer/WorkspaceSubscriptionCheckoutModalContainer';
import SubscriptionModalPresenter from '../../presenters/SubscriptionModalPresenter/SubscriptionModalPresenter';

const SubscriptionModal = ( { presenter } ) => {
	const renderPricingSubscriptionSelectionModal = () => (
		<PricingSubscriptionSelectionModal
			workspaceID={presenter.workspace.id}
			presenter={presenter.pricingSubscriptionPresenter}
			onClose={presenter.closeSubscriptionModal}
			onUpgradeButtonClicked={presenter.onUpgradeButtonClicked}
			onNoWorkspaceButtonClicked={presenter.onNoWorkspaceButtonClicked}
		/>
	);

	const renderWorkspaceSubscriptionCheckoutModal = () => (
		<WorkspaceSubscriptionCheckoutModalContainer
			workspaceID={presenter.workspace.id}
			onClose={presenter.closeCheckoutModal}
			onSuccessfulDialogAccepted={presenter.onSuccessfulPaymentDialogAccepted}
			pricingPlanPrice={presenter.pricingPlanPrice}
		/>
	);

	return (
		<>
			{renderPricingSubscriptionSelectionModal()}
			{presenter.showingCheckoutModal && renderWorkspaceSubscriptionCheckoutModal()}
		</>
	);
};

SubscriptionModal.propTypes = {
	presenter: PropTypes.instanceOf( SubscriptionModalPresenter ).isRequired
};

export default observer( SubscriptionModal );
