import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import Button from '../../../new_arch/components/Button/Button';
import PlanSettingsPresenter from '../../../presenters/PlanSettings/PlanSettingsPresenter';
import PlanNameLine from '../PlanNameLine/PlanNameLine';

const daysLeftWarning = daysLeft => `${daysLeft === 1 ? 'day' : 'days'} left until free trial ends`;

const ActiveFreeTrialSubscriptionDetail = ( {
	presenter
} ) => {
	const renderDescription = () => (
		<div>
			<div className="buttonLine">
				<div className="planSecondLine">
					<h1>{presenter.daysLeftOnFreeTrial}</h1>
					<h5>{daysLeftWarning( presenter.daysLeftOnFreeTrial )}</h5>
				</div>
				<Button
					className="upgradeButton"
					disabled={presenter.upgradeButtonData.disabled}
					onClick={presenter.onUpgradePlanButtonClicked}
					size="large"
					type={presenter.upgradeButtonData.type}
				>
					{presenter.upgradeButtonData.text}
				</Button>
			</div>
		</div>
	);

	return (
		<div className="subscription-details">
			<div className="planInfoBoxOnFreeTrial">
				<div className="planFirstLine">
					<PlanNameLine planName="Free Trial" planNameColor={presenter.freeTrialColor} />
				</div>
				{ renderDescription() }
			</div>
		</div>
	);
};

ActiveFreeTrialSubscriptionDetail.propTypes = {
	presenter: PropTypes.instanceOf( PlanSettingsPresenter ).isRequired
};

export default observer( ActiveFreeTrialSubscriptionDetail );
