/* eslint-disable react/no-danger */

import PropTypes from 'prop-types';
import classNames from 'classnames';

import './NoItemsIndicatorBox.scss';
import { removeTags } from '../../lib/textUtils';

const NoItemsIndicatorBox = ( {
	children, text, tipText, small, decreasedLeftMargin, style, extraInfo
} ) => {
	const textWithoutHTML = removeTags( text );
	const tipWithoutHTML = removeTags( tipText );
	const extraInfoHTML = removeTags( extraInfo );
	return (
		<div
			style={style}
			className={classNames(
				'NoItemsIndicatorBox',
				{ small },
				{ 'decreased-left-margin': decreasedLeftMargin },
				{ 'with-extra-info': extraInfo }

			)}
		>
			{children || (
				<>
					<div>
						<p dangerouslySetInnerHTML={{ __html: textWithoutHTML.replace( '\n', '<br/>' ) }} />
						{ extraInfo && (
							<div className="extra-info-container">
								<p className="extra-info" dangerouslySetInnerHTML={{ __html: extraInfoHTML.replace( '\n', '<br/>' ) }} />
							</div>
						)}
					</div>
					{ tipText && (
						<div className="tip-container">
							<p className="tip" dangerouslySetInnerHTML={{ __html: tipWithoutHTML.replace( '\n', '<br/>' ) }} />
						</div>
					)}
				</>
			)}
		</div>
	);
};

NoItemsIndicatorBox.propTypes = {
	children: PropTypes.any,
	text: PropTypes.string,
	tipText: PropTypes.string,
	small: PropTypes.bool,
	decreasedLeftMargin: PropTypes.bool,
	style: PropTypes.any,
	extraInfo: PropTypes.string
};

NoItemsIndicatorBox.defaultProps = {
	children: undefined,
	text: '',
	tipText: '',
	small: false,
	decreasedLeftMargin: false,
	style: undefined,
	extraInfo: ''
};

export default NoItemsIndicatorBox;
