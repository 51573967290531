export default class ImmutableEntity {
	clone() {
		throw new Error( 'Must be implemented in subclass' );
	}
	/* eslint-enable class-methods-use-this */

	set( property, value ) {
		const updateEntity = this.clone();
		updateEntity[ property ] = value;
		return updateEntity;
	}

	setProperties( properties ) {
		const updateEntity = this.clone();
		Object.keys( properties ).forEach( ( property ) => {
			updateEntity[ property ] = properties[ property ];
		} );
		return updateEntity;
	}
}
