import { useState } from 'react';
import { useDispatch } from 'react-redux';
import useAutoupdatePresenterProperty from '../../hooks/useAutoupdatePresenterProperty';
import PopupSystem from '../../systems/PopupSystem';
import SharedViewNavPresenter from './SharedViewNavPresenter';

const useSharedViewNavPresenter = ( {
	assets, renderDownloadAssetPopup
} ) => {
	const dispatch = useDispatch();

	const createPresenter = () => {
		const popupSystem = new PopupSystem( dispatch );

		return new SharedViewNavPresenter( {
			popupSystem, assets, renderDownloadAssetPopup
		} );
	};

	const [ presenter ] = useState( createPresenter );

	useAutoupdatePresenterProperty( { presenter, name: 'assets', value: assets } );

	return presenter;
};

export default useSharedViewNavPresenter;
