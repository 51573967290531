import { useQuery } from '@tanstack/react-query';
import User from '../../../models/user/user';
import useAuthSystem from '../system/useAuthSystem';
import { CURRENT_USER_KEY } from './keys';

const useFetchCurrentUserQuery = ( { enabled } : { enabled: boolean } = { enabled: true } ) => {
	const system = useAuthSystem();
	const twentyFourHours = 1000 * 60 * 60 * 24;

	return useQuery(
		CURRENT_USER_KEY,
		system.fetchCurrentUser,
		{
			enabled: system.isAuthenticated && enabled,
			select: User.fromJson,
			staleTime: twentyFourHours,
			retry: false
		}
	);
};

export default useFetchCurrentUserQuery;
