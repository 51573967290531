export default class MinWordCount {
	constructor( minWordCount ) {
		this.minWordCount = minWordCount;
	}

	validate( value, label, errors ) {
		const words = value && value.trim().split( ' ' ).filter( w => w.length > 0 );

		if ( words && words.length >= this.minWordCount ) { return true; }
		errors.push( `${label} should be at least ${this.minWordCount} ${this.minWordCount > 1 ? 'words' : 'word'}.` );
		return false;
	}
}
