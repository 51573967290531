import PropTypes from 'prop-types';

import AssetRoundSelector from '../AssetRoundSelector/AssetRoundSelector';
import NavigationBreadcrumbsContainer from '../../../containers/NavigationBreadcrumbsContainer/NavigationBreadcrumbsContainer';

import Asset from '../../../entities/asset';
import AssetRound from '../../../entities/assetRound';

import './AssetDetailsHeader.scss';

const AssetDetailsHeader = ( {
	asset, rounds, selectedRound, onSelectedRoundChange,
	showRounds, projectID, userIsComparingRounds,
	hideHomeIcon
} ) => (
	asset && (
		<div className="AssetDetailsHeader">
			<NavigationBreadcrumbsContainer
				projectID={projectID}
				folderID={asset.folderID}
				assetID={asset.id}
				hideHomeIcon={hideHomeIcon}
			/>
			{showRounds && selectedRound
					&& (
						<AssetRoundSelector
							selected={selectedRound}
							rounds={rounds}
							onChange={onSelectedRoundChange}
							disabled={userIsComparingRounds}
						/>
					)}
		</div>
	)
);

AssetDetailsHeader.propTypes = {
	asset: PropTypes.instanceOf( Asset ),
	rounds: PropTypes.arrayOf( PropTypes.instanceOf( AssetRound ) ),
	selectedRound: PropTypes.instanceOf( AssetRound ),
	onSelectedRoundChange: PropTypes.func,
	showRounds: PropTypes.bool,
	projectID: PropTypes.number,
	userIsComparingRounds: PropTypes.bool,
	hideHomeIcon: PropTypes.bool
};

AssetDetailsHeader.defaultProps = {
	asset: undefined,
	rounds: [],
	selectedRound: undefined,
	onSelectedRoundChange: undefined,
	showRounds: true,
	projectID: undefined,
	userIsComparingRounds: false,
	hideHomeIcon: false
};

export default AssetDetailsHeader;
