import { compareAsc, naturalCompareAsc } from '../../lib/comparators';

export type Comparator<T> = ( e1: T, e2: T ) => number;

export const comparatorBy = <T>(
	property: keyof T,
	{ natural = false }: { natural?: boolean } = {}
): Comparator<T> => (
		( e1: T, e2: T ): number => (
			natural
				? naturalCompareAsc( e1[ property ], e2[ property ] )
				: compareAsc( e1[ property ], e2[ property ] )
		)
	);

export const flip = <T>( comparator: Comparator<T> ): Comparator<T> => ( e1, e2 ) => comparator( e2, e1 );
export const compose = <T>( c1: Comparator<T>, c2: Comparator<T> ): Comparator<T> => (
	( i1, i2 ) => {
		const firstComparison = c1( i1, i2 );
		return firstComparison !== 0 ? firstComparison : c2( i1, i2 );
	}
);
