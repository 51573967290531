import PropTypes from 'prop-types';
import DefaultImg from '../../assets/img/pending-invite.png';
import Icon from '../../new_arch/components/Icon/Icon';

import './ProjectInvitationsBox.scss';

const ProjectInvitationsBox = ( { invitation, canDelete, onDeleteClick } ) => (
	<div className="PendingInvite">
		<div className="profile-pic">
			<div className="img-container">
				<img alt="default pic" src={DefaultImg} />
			</div>
		</div>
		<div className="info">
			<h5>Pending Invite</h5>
			<p>{invitation.email}</p>
		</div>
		<p name="role" className="role">{ invitation.role ? invitation.role.name : 'View Only' }</p>
		<div className="remove-container">
			{( canDelete ) && (
				<button className="remove" onClick={onDeleteClick}>
					<Icon icon="delete_outlined" size={20} />
				</button>
			)}
		</div>
	</div>
);

ProjectInvitationsBox.propTypes = {
	invitation: PropTypes.shape( {
		email: PropTypes.string,
		role: PropTypes.shape()
	} ),
	canDelete: PropTypes.bool,
	onDeleteClick: PropTypes.func
};

ProjectInvitationsBox.defaultProps = {
	invitation: null,
	canDelete: false,
	onDeleteClick: () => {}
};

export default ProjectInvitationsBox;
