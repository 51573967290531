import moment from 'moment';

export default class Task {
	constructor(
		id, projectID, text, authorID, asigneeID,
		dueDate, completedAt, createdAt, updatedAt,
		author = undefined, asignee = undefined
	) {
		this.id = id;
		this.projectID = projectID;
		this.text = text;
		this.authorID = authorID;
		this.asigneeID = asigneeID;
		this.dueDate = dueDate;
		this.completedAt = completedAt;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.author = author;
		this.asignee = asignee;
	}

	static fromJSON( properties ) {
		return new Task(
			properties.id,
			properties.project_id,
			properties.text,
			properties.author,
			properties.responsibles[ 0 ],
			properties.due_date ? properties.due_date : undefined,
			properties.completed_at ? new Date( properties.completed_at ) : undefined,
			new Date( properties.created_at ),
			new Date( properties.updated_at )
		);
	}

	toJSON() {
		return {
			id: this.id,
			project_id: this.projectID,
			text: this.text,
			author: this.authorID,
			responsibles: [ this.asigneeID ],
			due_date: this.dueDate ? this.dueDate : undefined,
			completed_at: this.completedAt ? this.completedAt.toISOString() : undefined,
			created_at: this.createdAt.toISOString(),
			updated_at: this.updatedAt.toISOString()
		};
	}

	get sortDate() { return this.createdAt; }

	withAuthorAndAsignee( author, asignee ) {
		return new Task(
			this.id,
			this.projectID,
			this.text,
			this.authorID,
			this.asigneeID,
			this.dueDate,
			this.completedAt,
			this.createdAt,
			this.updatedAt,
			author,
			asignee
		);
	}

	get isCompleted() { return !!this.completedAt; }

	get hasDueDate() { return !!this.dueDate; }

	get isOverdue() { return this.dueDate && moment( this.dueDate ).isBefore( moment().startOf( 'day' ) ); }

	belongsToProject( projectID ) { return this.projectID === projectID; }
}
