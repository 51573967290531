import uuid from 'uuid/v1';
import CommentReply from '../entities/commentReply';
import RootComment from '../entities/rootComment';
import Markup from '../entities/markup';

export default class CommentFactory {
	static propertiesFrom( {
		assetID,
		roundID,
		parentID,
		text,
		shapes,
		files,
		author
	} ) {
		const CommentKlass = parentID ? CommentReply : RootComment;
		return new CommentKlass( {
			id: uuid(),
			assetID,
			roundID,
			text,
			parentID,
			authorID: author.id,
			createdAt: new Date(),
			markup: shapes ? new Markup( uuid(), 1, shapes ) : undefined,
			files
		} ).toJSON();
	}
}
