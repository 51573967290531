import { isValidElement } from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import BlueDot from '../../../../assets/img/icons/filter-mark.svg';

const SingleValue = ( props ) => {
	const {
		data: { label, caption, customComponent }, showSelectedDot, displayValueFormatter, selectedOptionType
	} = props;

	const formattedLabel = (
		<>
			<span className="selectedOptionType">{selectedOptionType}</span>
			{displayValueFormatter( label )}
			{caption && <span className="caption">{caption}</span>}
		</>
	);

	const renderDefaultValue = () => (
		<components.SingleValue {...props}>
			{
				showSelectedDot
					? (
						<div className="filter-mark">
							<img alt="filter mark" src={BlueDot} />
							{formattedLabel}
						</div>
					)
					: formattedLabel
			}
		</components.SingleValue>
	);

	return (
		isValidElement( customComponent ) ? customComponent : renderDefaultValue()
	);
};

SingleValue.propTypes = {
	displayValueFormatter: PropTypes.func,
	showSelectedDot: PropTypes.bool,
	value: PropTypes.string,
	label: PropTypes.string,
	data: PropTypes.any,
	selectedOptionType: PropTypes.string
};

SingleValue.defaultProps = {
	displayValueFormatter: label => label,
	showSelectedDot: false,
	value: undefined,
	label: undefined,
	data: undefined,
	selectedOptionType: undefined
};

export default SingleValue;
