import { Map } from 'immutable';
import { handle } from 'redux-pack';
import { handleStart, handleSuccess } from '../lib/reduxUtils';
import {
	CREATE_PROJECT, FETCH_PROJECT, LOGOUT,
	DELETE_PROJECTS,
	MARK_PROJECT_AS_SEEN,
	FETCH_ACTIVE_PROJECTS,
	FETCH_HIDDEN_PROJECTS
} from '../actions/types';
import { parseProjectMetrics } from '../parsers/projects';

export const projectsMetrics = ( state = new Map(), action ) => {
	switch ( action.type ) {
	case CREATE_PROJECT:
		return handleSuccess(
			state,
			action,
			prevState => prevState.set(
				action.payload.id, parseProjectMetrics( action.payload )
			)
		);
	case FETCH_PROJECT:
		return handle( state, action, {
			failure: ( prevState ) => {
				const { projectID } = action.meta;
				prevState.delete( projectID );
				return prevState;
			},
			success: ( prevState ) => {
				const { projectID } = action.meta;
				const project = parseProjectMetrics( action.payload );
				const prevProject = prevState.get( projectID, {} );

				return prevState.set( projectID, { ...prevProject, ...project } );
			}
		} );
	case DELETE_PROJECTS:
		return handleSuccess(
			state,
			action,
			prevState => action.payload.reduce(
				( result, deletedID ) => result.delete( deletedID ),
				prevState
			)
		);
	case FETCH_ACTIVE_PROJECTS:
	case FETCH_HIDDEN_PROJECTS:
		return handleSuccess(
			state,
			action,
			() => action
				.payload
				.reduce(
					( prevState, project ) => prevState.set(
						project.id, parseProjectMetrics( project )
					),
					new Map()
				)
		);
	case MARK_PROJECT_AS_SEEN:
		return handleSuccess(
			state,
			action,
			prevState => (
				prevState.updateIfSet(
					action.payload,
					project => ( {
						...project,
						has_new_assets: false,
						has_assets_unseen_by_current_user: false,
						is_new_for_current_user: false,
						has_new_comments: false,
						has_newly_flagged_assets: false,
						has_newly_approved_assets: false,
						has_new_tasks: false
					} )
				)
			)
		);
	case LOGOUT:
		return handleStart( state, action, () => new Map() );
	default:
		return state;
	}
};
