import { observer } from 'mobx-react-lite';
import User from '../../../models/user/user';
import useFetchCurrentUserQuery from './useFetchCurrentUserQuery';
import useIsLoggedIn from '../../../../hooks/useIsLoggedIn';
import SplashScreen from '../../../../screens/SplashScreen/SplashScreen';

const withCurrentUser = <P extends {currentUser?: User}>( Component : React.ComponentType<P> ) => observer(
	( props : P ) => {
		const isLoggedIn = useIsLoggedIn();
		const { data: currentUser } = useFetchCurrentUserQuery();

		if ( isLoggedIn && !currentUser ) {
			return <SplashScreen />;
		}

		return <Component {...props} currentUser={currentUser} />;
	}
);

export default withCurrentUser;
