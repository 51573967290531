import { List } from 'immutable';
import Drawable from './drawable';
import Point from './point';
import ShapeRenderer from '../lib/shapeRenderer';

class Curve extends Drawable {
	constructor( points, strokeColor, strokeSize, isRelative ) {
		super( strokeColor, strokeSize, isRelative );

		this._points = points;
	}

	static fromJSON( properties ) {
		return new Curve(
			List.of(
				...properties.points.map( ( { x, y } ) => new Point( { x, y } ) )
			),
			properties.stroke_color,
			properties.stroke_size,
			properties.is_relative
		);
	}

	toJSON() {
		return {
			type: 'curve',
			points: this.points.map(
				point => ( { x: point.get( 'x' ), y: point.get( 'y' ) } )
			).toJS(),
			stroke_color: this.strokeColor,
			stroke_size: this.strokeSize
		};
	}

	get points() { return this._points; }

	get renderer() { return ShapeRenderer.forCurve( this ); }

	get referencePoint() {
		const referencePointIndex = Math.floor( this.points.size / 2 );
		return this.points.get( referencePointIndex );
	}

	get isValid() { return this.points.size > 1; }

	static fromPoint( point, strokeColor, strokeSize ) {
		return new Curve( List.of( point ), strokeColor, strokeSize );
	}

	updateWith( point ) {
		return new Curve(
			this.points.push( point ), this.strokeColor, this.strokeSize
		);
	}

	convertToRelative( { width, height } ) {
		if ( this._isRelative || !width || !height ) return;
		this._points = this._points.map( this._toRelativeCoordsConverter( { width, height } ) );
		this._isRelative = true;
	}
}

export default Curve;
