import { SHARED_FOLDERS_URL, SHARED_PROJECTS_VIEW_URL } from '../config/urls';
import { trackOpenedSharedProject, trackOpenedSharedFolder } from '../services/tracker/trackers/sharing';
import { FETCH_SHARED_PROJECT, FETCH_SHARED_FOLDER } from './types';

const addProjectIDToAll = ( elems, projectID ) => elems?.map( elem => ( { ...elem, project_id: projectID } ) );

const buildSharedProjectOrganizerPayload = ( { response, projectID, shareHash } ) => ( {
	...response.response,
	assets: addProjectIDToAll( response.response.assets, projectID ),
	folders: addProjectIDToAll( response.response.folders, projectID ),
	project_id: projectID,
	share_hash: shareHash
} )

export const fetchSharedProject = shareHash => ( dispatch, _, api ) => {
	trackOpenedSharedProject( shareHash );
	return dispatch( {
		type: FETCH_SHARED_PROJECT,
		promise: api
			.get( `${SHARED_PROJECTS_VIEW_URL}/${shareHash}` )
			.then( response => buildSharedProjectOrganizerPayload( {
				response, projectID: response.response.id, shareHash
			} ) )
	} )
};

export const fetchSharedFolder = shareHash => ( dispatch, _, api ) => {
	trackOpenedSharedFolder( shareHash );
	return dispatch( {
		type: FETCH_SHARED_FOLDER,
		promise: api
			.get( `${SHARED_FOLDERS_URL}/${shareHash}` )
			.then( response => response.response )
	} );
};
