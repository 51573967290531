import { sharedTracker } from '../Tracker';
import events from '../events';

export const trackSubscriptionCancelled = () => sharedTracker
	.trackSubscriptionAction( {
		action: events.subscription.SUBSCRIPTION_CANCELLED
	} );

export const trackSubscriptionStarted = ( { plan, includedMembers, withFreeTrial } ) => sharedTracker
	.trackSubscriptionAction( {
		action: events.subscription.SUBSCRIPTION_STARTED,
		plan,
		included_members: includedMembers,
		with_free_trial: withFreeTrial
	} );

export const trackSubscriptionCreated = ( { plan, includedMembers, withFreeTrial } ) => sharedTracker
	.trackSubscriptionAction( {
		action: events.subscription.SUBSCRIPTION_CREATED,
		plan,
		included_members: includedMembers,
		with_free_trial: withFreeTrial
	} );

export const trackSubscriptionReactivated = () => sharedTracker
	.trackSubscriptionAction( {
		action: events.subscription.SUBSCRIPTION_REACTIVATED
	} );
