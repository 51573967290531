import NotificationsSettingsContainer from '../../../containers/NotificationsSettingsContainer/NotificationsSettingsContainer';
import IconButton from '../../UI/IconButton/IconButton';

import './NotificationsPanelSettings.scss';

type NotificationsPanelSettingsProps = {
	onBackClick: () => void;
	onClose: () => void;
};

const NotificationsPanelSettings = ( { onBackClick, onClose }: NotificationsPanelSettingsProps ) => (
	<div className="NotificationsPanelSettings">
		<div className="NotificationsPanelSettingsControls">
			<IconButton
				buttonClassName="BackButton"
				icon="arrow_back"
				onClick={onBackClick}
				size={20}
			/>
			<h4>Notifications Settings</h4>
			<IconButton
				buttonClassName="CloseIcon"
				icon="close"
				onClick={onClose}
				size={24}
			/>
		</div>
		<div className="divider" />
		<NotificationsSettingsContainer />
	</div>
);

export default NotificationsPanelSettings;
