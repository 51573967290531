import IconButton from '../../components/UI/IconButton/IconButton';
import Button from '../../new_arch/components/Button/Button';

import './UnsupportedFileFormatsPopup.scss';

type UnsupportedFileFormatsPopupProps = {
	unsupportedFormats: string[],
	onCancel: () => void,
	onConfirm: () => void
}

const UnsupportedFileFormatsPopup = ( {
	unsupportedFormats, onCancel, onConfirm
}: UnsupportedFileFormatsPopupProps ) => (
	<div className="UnsupportedFileFormatsPopup">
		<div className="dialog">
			<IconButton
				icon="clear"
				buttonClassName="close-icon"
				onClick={onCancel}
				size={24}
				testID="close-icon"
			/>
			<h4>Unsupported File Formats</h4>
			<div className="description-container">
				<p className="description">
					{'Sorry, the items you\'re trying to upload contain one or more unsupported formats. '}
					<span>Please change them and try again.</span>
				</p>
				<div className="unsupported-formats-container">
					<p className="title">Not supported</p>
					<p className="unsupported-formats">
						{unsupportedFormats.join( ', ' )}
					</p>
				</div>
			</div>
			<Button
				type="filled"
				color="primary"
				onClick={onConfirm}
			>
				Got it
			</Button>
		</div>
	</div>
);

export default UnsupportedFileFormatsPopup;
