/* eslint-disable max-len */
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import Button from '../../new_arch/components/Button/Button';
import WhiteBox from '../WhiteBox/WhiteBox';

import './TwoFactorAuthenticationCard.scss';

const TwoFactorAuthenticationCard = ( { presenter } ) => (
	<WhiteBox className="TwoFactorAuthenticationCard">
		<h4 className="title">Two-step verification</h4>
		<p>
			{presenter.is2FAEnabled
				? <>Additional authentication method is <span>activated</span>. </>
				: <>Secure your account by requiring an additional authentication method every time you log in. </>}
			You will have to validate
			your identity with a verification code we send you by email to
			{presenter.is2FAEnabled
				? <> <span>{presenter.email}</span> every time you log in.</>
				: <> <span>{presenter.email}</span> to log in.</>}
		</p>
		<Button onClick={presenter.buttonWasClicked}>{presenter.is2FAEnabled ? 'Deactivate' : 'Activate'}</Button>
	</WhiteBox>
);

TwoFactorAuthenticationCard.propTypes = {
	presenter: PropTypes.shape( {
		email: PropTypes.string.isRequired,
		buttonWasClicked: PropTypes.func.isRequired,
		is2FAEnabled: PropTypes.bool.isRequired
	} ).isRequired
};

export default observer( TwoFactorAuthenticationCard );
