import { schema } from 'normalizr';

export const user = new schema.Entity( 'users' );

const createEntitySchemaWithUser = ( key, withIdAttribute ) => (
	new schema.Entity( key, {
		user
	},
	withIdAttribute
		? { idAttribute: e => e.id }
		: undefined
	)
);

export const pricingPlanPrice = new schema.Entity( 'pricingPlanPrices' );

export const pricingPlan = new schema.Entity( 'pricingPlans', { prices: [ pricingPlanPrice ] } );

export const pricingPlanPriceWithPlan = new schema.Entity( 'pricingPlanPricesWithPlan', { pricing_plan: pricingPlan } );

export const subscription = new schema.Entity( 'subscriptions', { pricing_plan_price: pricingPlanPriceWithPlan } );

export const workspace = new schema.Entity( 'workspaces', { subscription } );

export const commentReply = createEntitySchemaWithUser( 'commentReplies', true );

export const comment = new schema.Entity( 'comments', {
	user,
	replies: [ commentReply ]
}, {
	idAttribute: c => c.id
} );

export const projectMember = createEntitySchemaWithUser( 'projectMembers', true );

export const workspaceMember = createEntitySchemaWithUser( 'workspaceMembers', true );

export const task = new schema.Entity( 'tasks', {
	author: user,
	responsibles: [ user ]
}, {
	idAttribute: t => t.id
} );

export const activity = createEntitySchemaWithUser( 'activities', true );

export const project = new schema.Entity( 'projects' );

export const seenByUsers = createEntitySchemaWithUser( 'seenByUser', false );
