import moment from 'moment';

export default class CreditCard {
	constructor(
		paymentMethodID,
		brand,
		lastFour,
		expirationDate,
		billingCountry,
		billingPostalCode,
		ownerName,
		workspaceID
	) {
		this.paymentMethodID = paymentMethodID;
		this.brand = brand;
		this.lastFour = lastFour;
		this.expirationDate = expirationDate;
		this.billingCountry = billingCountry;
		this.billingPostalCode = billingPostalCode;
		this.ownerName = ownerName;
		this.workspaceID = workspaceID;
	}

	static fromJSON( {
		id,
		card: {
			brand,
			last4,
			exp_month: expMonth,
			exp_year: expYear
		},
		billing_details: {
			address: {
				country,
				postal_code: postalCode
			},
			name
		},
		workspace_id: workspaceID
	} ) {
		return new CreditCard(
			id,
			brand,
			last4,
			moment( { y: expYear, M: expMonth - 1 } ).endOf( 'month' ),
			country,
			postalCode,
			name,
			workspaceID
		);
	}

	get hiddenNumber() {
		return `${this.brand.toUpperCase()} **** **** **** ${this.lastFour}`;
	}

	get formattedExpiration() {
		return this.expirationDate.format( 'MM/YY' );
	}

	get isExpired() {
		return moment().isAfter( this.expirationDate );
	}
}
