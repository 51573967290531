import { sharedTracker } from '../Tracker';
import events from '../events';

export const trackFolderCreated = ( { id, name, isNested } ) => sharedTracker
	.trackFolderAction( {
		action: events.folder.FOLDER_CREATED,
		id,
		name,
		is_nested: isNested
	} );
