import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { getCurrentUser } from '../../selectors/currentUser';
import { getWorkspace, getWorkspaceMembersForWorkspace } from '../../selectors/workspaces';

import { connectComponent } from '../../lib/connectComponent';
import WorkspaceSettings from '../../components/WorkspaceSettings/WorkspaceSettings';
import Workspace from '../../entities/workspace';
import User from '../../entities/user';
import WorkspaceMember from '../../entities/workspaceMember';
import { trackNavigatedToWorkspaceSettings } from '../../services/tracker/trackers/navigation';

export const WorkspaceSettingsContainer = ( {
	workspace, user, members
} ) => {
	useEffect( () => trackNavigatedToWorkspaceSettings(), [] );

	return (
		<WorkspaceSettings
			workspace={workspace}
			isOwner={workspace.userIsOwner( user.id )}
			canTransferOwnership={workspace.canTransferOwnership( members )}
		/>
	);
};

WorkspaceSettingsContainer.propTypes = {
	workspace: PropTypes.instanceOf( Workspace ),
	user: PropTypes.instanceOf( User ),
	members: PropTypes.arrayOf( WorkspaceMember )
};

WorkspaceSettingsContainer.defaultProps = {
	workspace: undefined,
	user: undefined,
	members: []
};

export default connectComponent( ( state, props ) => ( {
	workspace: getWorkspace( state, props ),
	user: getCurrentUser( state ),
	members: getWorkspaceMembersForWorkspace( state, props )
} ) )( WorkspaceSettingsContainer );
