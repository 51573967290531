import PropTypes from 'prop-types';
import classNames from 'classnames';
import Switch from '../UI/Switch/Switch';

import './SwitchBox.scss';

const SwitchBox = ( {
	title, caption, onSwitch, checked, disabled
} ) => (
	<div className={classNames( 'SwitchBox', { disabled } )}>
		<div>
			<h5>{title}</h5>
			<p>{caption}</p>
		</div>
		<Switch onSwitch={onSwitch} checked={checked} disabled={disabled} />
	</div>
);

SwitchBox.propTypes = {
	title: PropTypes.string.isRequired,
	caption: PropTypes.string,
	onSwitch: PropTypes.func.isRequired,
	checked: PropTypes.bool.isRequired,
	disabled: PropTypes.bool
};

SwitchBox.defaultProps = {
	caption: '',
	disabled: false
};

export default SwitchBox;
