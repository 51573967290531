import AsyncBaseDownloadAssetsPresenterFactory from './AsyncBaseDownloadAssetsPresenterFactory';

export default class AsyncDownloadSharedProjectAssetsPresenterFactory
	extends AsyncBaseDownloadAssetsPresenterFactory {
	constructor( { shareRef } ) {
		super();
		this._shareRef = shareRef;
	}

	forDownloadAsset( {
		asset, withRoundNumber, lowRes, assetSystem, snackbarSystem, onFinish
	} ) {
		const { projectID } = asset;
		const assetIDs = [ asset.id ];
		return this._forDownloadAssets( {
			action: () => assetSystem.downloadSharedProjectAssets( {
				projectID, assetIDs, withRoundNumber, lowRes, shareRef: this._shareRef
			} ),
			onFinish,
			snackbarSystem
		} );
	}

	forDownloadAssets( {
		withRoundNumber, assetSystem, snackbarSystem, lowRes, onFinish
	} ) {
		return this._forDownloadAssets( {
			action: () => assetSystem.downloadSharedProjectAssets( {
				withRoundNumber, lowRes, shareRef: this._shareRef
			} ),
			onFinish,
			snackbarSystem
		} );
	}
}
