import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useAutoupdatePresenterProperty from '../../hooks/useAutoupdatePresenterProperty';
import SubscriptionModalPresenter from './SubscriptionModalPresenter';
import { getCurrentUserOwnWorkspace } from '../../selectors/workspaces';
import ModalSystem from '../../systems/ModalSystem';
import { createWorkspace } from '../../actions/workspaces';
import NullWorkspace from '../../entities/nullWorkspace';

const useSubscriptionModalPresenter = ( { currentUser } ) => {
	const dispatch = useDispatch();
	const modalSystem = new ModalSystem( dispatch );
	const modals = useSelector( state => state.modals );
	const workspace = useSelector( state => getCurrentUserOwnWorkspace( state, { currentUser } ) )
		|| new NullWorkspace();

	const createWorkspaceAction = ( ...args ) => dispatch(
		createWorkspace( ...args )
	);

	const createPresenter = () => new SubscriptionModalPresenter( {
		workspace,
		modalSystem,
		modals,
		createWorkspaceAction
	} );

	const [ presenter ] = useState( createPresenter );

	useAutoupdatePresenterProperty( { presenter, name: 'workspace', value: workspace } );
	useAutoupdatePresenterProperty( { presenter, name: 'modals', value: modals } );

	return presenter;
};

export default useSubscriptionModalPresenter;
