import type WorkspaceSystem from '../../../systems/WorkspaceSystem';
import type LabelGroup from '../../../entities/labelGroup';
import { LabelParams } from '../../../entities/labelGroup';

type LabelGroupEditStrategyParams = {
	workspaceID: number,
	labelGroup?: LabelGroup,
	workspaceSystem: WorkspaceSystem
}

export default class LabelGroupEditStrategy {
	private workspaceID: number;
	private labelGroup: LabelGroup;
	private workspaceSystem: WorkspaceSystem;

	constructor( { workspaceID, labelGroup, workspaceSystem }: LabelGroupEditStrategyParams ) {
		if ( !labelGroup ) throw new Error( 'LabelGroupEditStrategy requires an existing label group' );

		this.workspaceID = workspaceID;
		this.labelGroup = labelGroup;
		this.workspaceSystem = workspaceSystem;
	}

	get title() {
		return 'Edit Label group';
	}

	get initialName() {
		return this.labelGroup.name;
	}

	get initialLabels() {
		return this.labelGroup.labels;
	}

	get submitButtonLabel() {
		return 'Save';
	}

	get successMessage() {
		return 'Label group edited successfully';
	}

	makeLabelGroupRequest( name: string, labels: LabelParams[] ) {
		return this.workspaceSystem.updateLabelGroup( {
			workspaceID: this.workspaceID,
			labelGroupID: this.labelGroup.id,
			name,
			labels
		} );
	}
}
