import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import useUpgradeButtonPresenter from '../../presenters/UpgradeButtonPresenter/useUpgradeButtonPresenter';
import UpgradeButton from '../../components/UpgradeButton/UpgradeButton';
import ModalSystem from '../../systems/ModalSystem';
import { connectComponent } from '../../lib/connectComponent';

export const UpgradeButtonContainer = ( { modalSystem } ) => {
	const presenter = useUpgradeButtonPresenter( { modalSystem } );

	return presenter.showUpgradeButton
		? <UpgradeButton presenter={presenter} />
		: null;
};

UpgradeButtonContainer.propTypes = {
	modalSystem: PropTypes.instanceOf( ModalSystem ).isRequired
};

export default connectComponent()( observer( UpgradeButtonContainer ) );
