import { isUnauthorizedError } from '../errors/common';
import ResponseError from '../new_arch/errors/ResponseError';
import AbortablePromise from './AbortablePromise';
import ApiHeaders from './ApiHeaders';

export default class Api {
	constructor( fetchBackend = fetch.bind( window ), abortControllerBackend = AbortController ) {
		this.fetch = fetchBackend;
		this.AbortControllerClass = abortControllerBackend;
		this.headers = new ApiHeaders();
		this.onUnauthorizedResponse = null;
	}

	_mergeHeaders( requestHeaders ) {
		const headersToRemove = Object
			.keys( requestHeaders )
			.filter( headerKey => requestHeaders[ headerKey ] === undefined );

		let headers = { ...this.headers.toDictionary(), ...requestHeaders };

		headersToRemove.forEach( ( headerKey ) => {
			delete headers[ headerKey ];
		} );

		return headers;
	}

	__buildFetchPromise( url, options, abortable ) {
		if ( abortable ) {
			const controller = new this.AbortControllerClass();
			const promise = this.fetch( url, { ...options, signal: controller.signal } );
			const onAbort = () => controller.abort();

			return new AbortablePromise( { promise, onAbort } );
		}

		return this.fetch( url, options );
	}

	__options( method, body = undefined, requestHeaders = {} ) {
		const headers = this._mergeHeaders( requestHeaders );
		const options = { method, headers, credentials: 'include' };

		if ( body !== undefined ) {
			if ( headers[ 'Content-Type' ] === 'application/json' ) {
				options.body = JSON.stringify( body );
			} else {
				options.body = body;
			}
		}

		return options;
	}

	__request( method, url, body = undefined, headers = {}, abortable = false ) {
		return this
			.__buildFetchPromise( url, this.__options( method, body, headers ), abortable )
			.then( ( response ) => {
				if ( !response.ok ) {
					return ResponseError
						.createFromResponse( response )
						.then( ( error ) => {
							if ( isUnauthorizedError( error ) && this.onUnauthorizedResponse ) {
								this.onUnauthorizedResponse( { method, url } );
							}

							throw error;
						} );
				}
				return response;
			} );
	}

	addLogoutOnUnauthorizedResponse( logout ) {
		this.onUnauthorizedResponse = logout;
	}

	get( url, headers = {}, { abortable = false } = {} ) {
		return this.__request( 'GET', url, undefined, headers, abortable ).then( data => data.json() );
	}

	post( url, body = {}, headers = {} ) {
		return this.__request( 'POST', url, body, headers ).then( data => data.json() );
	}

	patch( url, body = {}, headers = {} ) {
		return this.__request( 'PATCH', url, body, headers ).then( data => data.json() );
	}

	put( url, body = {}, headers = {} ) {
		return this.__request( 'PUT', url, body, headers ).then( data => data.json() );
	}

	delete( url, body = undefined, headers = {} ) {
		return this.__request( 'DELETE', url, body, headers ).then( data => data.json() );
	}
}
