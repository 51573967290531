import { useCallback } from 'react';
import { useHistory } from 'react-router';
import map from 'lodash/mapValues';
import Routes from './routes';

type Route = string;
type ParametricRoute = ( id: string | number ) => string;

export type Navigate = {
	[ K in keyof typeof Routes ]: typeof Routes[K] extends Route
		? ( state?: unknown ) => void
		: ( id: string | number, state?: unknown ) => void;
}

const updateState = ( newState: unknown, history: { location: { state: unknown } } ) => {
	if ( typeof newState === 'function' ) {
		return newState( history.location.state );
	}
	return newState;
}

const useNavigate = () => {
	const history = useHistory();
	const routeTo = useCallback(
		( path : string ) => ( state? : unknown ) => history.push( path, updateState( state, history ) ),
		[ history ]
	);

	return map( Routes, ( route: Route | ParametricRoute ) => (
		typeof route === 'string'
			? routeTo( route )
			: ( id: string, state: unknown ) => history.push( route( id ), updateState( state, history ) )
	) ) as Navigate;
};

export default useNavigate;
