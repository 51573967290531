import { createSelector } from 'reselect';
import { firstBy } from 'thenby';
import { compareAsc, compareDesc } from '../lib/comparators';
import { getActiveProjects, getProjectID } from './projects';
import FilterOption from '../entities/filterOption';
import Notification from '../entities/notification';

const getRawNotifications = state => state.notifications;

const getNotificationsFromActiveProjects = createSelector(
	[
		getActiveProjects,
		getRawNotifications,
		state => state.selectedNotificationsProjectID
	],
	( activeProjects, notificationsSerialization, selectedProjectID ) => notificationsSerialization.map(
		properties => Notification.fromJSON( properties )
	).filter(
		notification => activeProjects.has( notification.projectID ) || notification.projectID === selectedProjectID
	)
);

const getProjectNotificationsPaginationData = createSelector(
	[
		getProjectID,
		state => state.projectsNotificationsPaginationData
	],
	( projectID, projectsPaginationData ) => {
		if ( !projectID || !projectsPaginationData ) { return null; }
		return projectsPaginationData.get( projectID ) || null;
	}
);

const getNotificationsFilteringByProject = createSelector(
	[
		( _, props ) => props.projectID,
		getNotificationsFromActiveProjects
	],
	( projectID, notifications ) => (
		notifications
			.filter( notification => notification.projectID === projectID )
			.sort( ( a, b ) => compareAsc( b.createdAt, a.createdAt ) )
			.toArray()
	)
);

const getMostRecentNotificationDateForProject = createSelector(
	[ getNotificationsFilteringByProject ],
	projectNotifications => (
		projectNotifications && projectNotifications.length > 0
			? projectNotifications[ 0 ].createdAt
			: null
	)
);

export const getNotificationsForProject = createSelector(
	[
		getNotificationsFromActiveProjects,
		getProjectNotificationsPaginationData
	],
	( notifications, paginationData ) => {
		const comparator = (
			firstBy( 'createdAt', -1 )
		);

		return (
			paginationData
				? paginationData.ids
					.map( id => notifications.get( id ) )
					.filter( not => !!not )
					.sort( comparator )
					.toArray()
				: []
		);
	}
);

export const getActiveProjectsUnseenNotificationsData = createSelector(
	[
		state => state,
		getActiveProjects,
		state => state.notificationsCount
	],
	( state, activeProjects, notificationsCount ) => activeProjects.valueSeq()
		.map( project => ( {
			project,
			unseenNotificationsCount: notificationsCount.get( project.id )?.unseenNotificationsCount || 0
		} ) )
		.sort( ( project, otherProject ) => {
			const projectsUnseenNotificationsComparationResult = compareDesc(
				project.unseenNotificationsCount,
				otherProject.unseenNotificationsCount
			);

			if ( projectsUnseenNotificationsComparationResult !== 0 ) {
				return projectsUnseenNotificationsComparationResult;
			}

			const projectMostRecentNotificationDate = getMostRecentNotificationDateForProject(
				state,
				{ projectID: project.project.id }
			);
			const otherProjectMostRecentNotificationDate = getMostRecentNotificationDateForProject(
				state,
				{ projectID: otherProject.project.id }
			);

			return compareDesc(
				projectMostRecentNotificationDate,
				otherProjectMostRecentNotificationDate
			);
		} )
		.toJS()
);

const getDefaultSelectedNotificationsProjectID = createSelector(
	[ getActiveProjectsUnseenNotificationsData ],
	( projects ) => {
		if ( !projects || projects.length === 0 ) { return null; }
		return projects.first.project.id;
	}
);

export const getSelectedNotificationsProjectID = createSelector(
	[
		state => state.selectedNotificationsProjectID,
		getDefaultSelectedNotificationsProjectID,
		getActiveProjects
	],
	( selectedProjectID, defaultSelectedProjectID, activeProjects ) => {
		if ( activeProjects.has( selectedProjectID ) ) { return selectedProjectID; }
		return defaultSelectedProjectID;
	}
);

export const getCurrentUserHasUnseenNotificationsFromActiveProjects = createSelector(
	[ getActiveProjectsUnseenNotificationsData ],
	activeProjectsUnseenNotificationsData => activeProjectsUnseenNotificationsData
		.some( activeProjectUnseenNotificationsData => (
			activeProjectUnseenNotificationsData.unseenNotificationsCount > 0
		) )
);

export const getProjectNotificationsHasNextPage = createSelector(
	[
		getProjectNotificationsPaginationData
	],
	paginationData => (
		paginationData
			? paginationData.hasNext
			: null
	)
);

export const getProjectNotificationsFetchedPage = createSelector(
	[
		getProjectNotificationsPaginationData
	],
	paginationData => (
		paginationData && paginationData.page
			? paginationData.page
			: null
	)
);

export const getProjectNotificationsPaginationOlderThan = createSelector(
	[
		getProjectNotificationsPaginationData
	],
	paginationData => (
		paginationData
			? paginationData.olderThan
			: null
	)
);

const getRawSelectedNotificationsFilter = state => state.selectedNotificationsFilter;

const getSelectedNotificationsFilter = createSelector(
	[ getRawSelectedNotificationsFilter ],
	selectedNotificationsFilterSerialization => selectedNotificationsFilterSerialization
		.map( filterOptionSerialization => FilterOption.fromJSON( filterOptionSerialization ) )
);

export const getSelectedNotificationsFilterForProject = createSelector(
	[
		getProjectID,
		getSelectedNotificationsFilter
	],
	( projectID, selectedNotificationsFilter ) => (
		selectedNotificationsFilter.get( projectID ) || FilterOption.none
	)
);

export const getIsFilteringNotificationForProject = createSelector(
	[
		getProjectID,
		getSelectedNotificationsFilter
	],
	( projectID, notificationsFilter ) => (
		notificationsFilter.get( projectID )
		&& notificationsFilter.get( projectID ) !== FilterOption.none
	)
);
