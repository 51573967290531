import { useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { cancelAllAssetUploads } from '../../../../actions/assetUploads';
import { clearLogin } from '../../../../actions/authentication';
import { setLoggingOutState } from '../../../../navigation/history';

const useSessionCleanup = () => {
	const queryClient = useQueryClient();
	const dispatch = useDispatch();

	return () => {
		queryClient.invalidateQueries();
		queryClient.removeQueries();
		setLoggingOutState();
		dispatch( cancelAllAssetUploads() );
		dispatch( clearLogin() );
	}
}

export default useSessionCleanup;
