import { sharedTracker } from '../Tracker';
import events from '../events';

const getMentionsCount = commentText => (
	( commentText.match( /@\[([\w\s]+)\]\(\d+\)/g ) || [] ).length
);

export const trackCommentCreation = ( { asset, shapes, files, text, isReply } ) => sharedTracker
	.trackCommentsAndMarkupsAction( {
		action: events.commentsAndMarkups.COMMENT_CREATED,
		asset_id: asset && asset.id,
		project_id: asset && asset.projectID,
		asset_name: asset && asset.name,
		has_attachments: files.length > 0,
		has_mentions: getMentionsCount( text ) > 0,
		has_markups: !!shapes,
		is_reply: isReply
	} );

export const trackCommentDeletion = ( { asset, comment } ) => sharedTracker
	.trackCommentsAndMarkupsAction( {
		action: events.commentsAndMarkups.COMMENT_DELETED,
		asset_id: asset && asset.id,
		asset_name: asset && asset.name,
		had_markups: comment.hasMarkup,
		had_attachments: comment.files.length > 0,
		had_mentions: getMentionsCount( comment.text ) > 0
	} );

export const trackCommentEdit = () => sharedTracker
	.trackCommentsAndMarkupsAction( {
		action: events.commentsAndMarkups.COMMENT_EDITED
	} );

export const trackCommentApproved = () => sharedTracker
	.trackCommentsAndMarkupsAction( {
		action: events.commentsAndMarkups.COMMENT_APPROVED
	} );

export const trackCommentsFilterChanged = filterOption => sharedTracker
	.trackCommentsAndMarkupsAction( {
		action: events.commentsAndMarkups.COMMENTS_FILTERED,
		criteria: filterOption.name
	} );
