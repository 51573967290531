import { SHOW_ROTATE_MOBILE_SCREEN, HIDE_ROTATE_MOBILE_SCREEN, LOGOUT } from '../actions/types';
import { handleStart } from '../lib/reduxUtils';

export const showRotateMobileScreen = ( state = true, action ) => {
	switch ( action.type ) {
	case SHOW_ROTATE_MOBILE_SCREEN:
		return true;
	case HIDE_ROTATE_MOBILE_SCREEN:
		return false;
	case LOGOUT:
		return handleStart( state, action, () => false );
	default:
		return state;
	}
};
