import guestLinkImg from '../../../assets/img/guest-link.png';
import Nav from '../../../components/navBar/Nav/Nav';
import NavigationLogo from '../../../components/navBar/NavigationLogo/NavigationLogo';
import AuthenticationLinksContainer from '../../../containers/AuthenticationLinksContainer/AuthenticationLinksContainer';

import './GuestAccessErrorScreen.scss';

const GuestAccessErrorScreen = ( { title, description }: { title: string, description: string } ) => (
	<>
		<Nav>
			<NavigationLogo isClickeable={false} />
			<AuthenticationLinksContainer />
		</Nav>
		<div className="error-screen">
			<img src={guestLinkImg} width="293px" alt="guest-link" />
			<h3>{title}</h3>
			<p>{description}</p>
		</div>
	</>
);

export default GuestAccessErrorScreen;
