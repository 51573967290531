import { useState } from 'react';

const useAllValuesTruthState = ( initialState ) => {
	const [ valuesTruthState, setValuesTruthState ] = useState( initialState );
	const updateValueTruthState = ( valueName, state ) => setValuesTruthState(
		prevValuesTruthState => ( {
			...prevValuesTruthState,
			[ valueName ]: state
		} )
	);
	const valueNames = Object.keys( valuesTruthState );

	const isValueTrue = valueName => valuesTruthState[ valueName ];
	const allValuesAreTrue = valueNames.every( isValueTrue );

	return [ allValuesAreTrue, updateValueTruthState ];
};

export default useAllValuesTruthState;
