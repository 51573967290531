import { createSelector } from 'reselect';
import { getUserPermissionsForProject } from './projectRolePermissions';
import { getCurrentUserRoleForProject } from '../projects';
import { getCurrentUserID } from '../currentUser';

export const getUserCanSeeProjectMembers = createSelector(
	[ getUserPermissionsForProject ],
	permissions => permissions && permissions.currentUserCan( 'see', 'users' )
);

export const getUserCanInviteProjectMembers = createSelector(
	[ getUserPermissionsForProject ],
	permissions => permissions && permissions.currentUserCan( 'invite', 'users' )
);

export const getUserCanDeleteProjectMember = createSelector(
	[
		( _, props ) => props.member,
		getCurrentUserID,
		getCurrentUserRoleForProject
	],
	( member, currentUserID, currentUserRole ) => (
		member && currentUserRole
			&& member.userID !== currentUserID // Can't delete himself
			&& currentUserRole.canDeleteProjectMember( member )
	)
);

export const getUserCanDeleteProjectInvitation = createSelector(
	[
		( _, props ) => props.invite,
		getCurrentUserRoleForProject
	],
	( invite, currentUserRole ) => (
		invite && currentUserRole
			&& currentUserRole.canDeleteProjectInvite( invite )
	)
);
