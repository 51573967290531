import { Map } from 'immutable';
import { handle } from 'redux-pack';

import {
	CHANGE_FOLDER_NAME, CREATE_FOLDER, DELETE_FOLDERS, FETCH_FOLDER,
	FETCH_FOLDERS_FOR_PROJECT, MOVE_ITEMS_INTO_FOLDER, FETCH_FOLDERS_FOR_FOLDER,
	FETCH_FOLDER_ANCESTORS, LOGOUT, CREATE_ASSET_FROM_DIRECT_UPLOAD, UNFOLD_ITEMS,
	MARK_FOLDER_NEW_ACTIVITY_AS_SEEN, CLEAR_DIRECT_SUBFOLDERS, FETCH_FOLDER_OVERVIEW, FETCH_FOLDER_INFORMATION
} from '../actions/types';
import { handleSuccess, makeAsyncActionReducer, orderedMapForPayload } from '../lib/reduxUtils';
import Folder from '../entities/folder';
import { UPDATER_ACTION_TYPE } from '../new_arch/services/ReduxStoreUpdater';

const parseFolderInfoFromPayload = ( payload ) => {
	if ( !payload ) { return null; }
	return payload;
};

const handleCreateFolderSuccess = ( state, { payload } ) => state.set(
	payload.id,
	parseFolderInfoFromPayload( payload )
);

const handleMoveAssetsIntoFolderSuccess = ( state, { meta } ) => {
	const { assets, folder, isFiltering } = meta;
	const unresolvedCommentsCountFromAssets = assets.accumulate( asset => asset.unresolvedCommentsCount );

	return state.updateIfSet( folder.id, prevStateFolder => ( {
		...prevStateFolder,
		images_count: prevStateFolder.images_count + assets.length,
		filtered_assets_count: prevStateFolder.filtered_assets_count + ( isFiltering ? assets.length : 0 ),
		top_level_images_count: prevStateFolder.top_level_images_count + assets.length,
		unresolved_comments_count: prevStateFolder.unresolved_comments_count + unresolvedCommentsCountFromAssets
	} ) );
};

const updateFolderInfoOnlyWithMovedItems = (
	state, { meta: { assets, folder, folders = [], isFiltering }, payload }
) => {
	const notMovedItemNames = payload?.meta?.duplicate_names || [];
	const movedAssets = assets.filter( asset => !notMovedItemNames.includes( asset.name ) );
	const unresolvedCommentsCountFromAssets = movedAssets.accumulate( asset => asset.unresolvedCommentsCount );

	const movedFolders = folders.filter( aFolder => !notMovedItemNames.includes( aFolder.name ) );
	const imagesCountFromFolders = movedFolders.reduce( ( count, aFolder ) => count + aFolder.imagesCount, 0 );
	const filteredAssetsCountFromFolders = movedFolders.reduce( ( count, f ) => count + f.filteredAssetsCount, 0 );
	const unresolvedCommentsCountFromFolders = movedFolders.accumulate( f => f.unresolvedCommentsCount );

	return state.updateIfSet( folder.id, prevStateFolder => ( {
		...prevStateFolder,
		images_count: folder.imagesCount + movedAssets.length + imagesCountFromFolders,
		filtered_assets_count: folder.filteredAssetsCount + (
			isFiltering ? movedAssets.length + filteredAssetsCountFromFolders : 0
		),
		top_level_images_count: folder.topLevelImagesCount + movedAssets.length,
		unresolved_comments_count: folder.unresolvedCommentsCount + (
			unresolvedCommentsCountFromAssets + unresolvedCommentsCountFromFolders
		),
		cover_url: prevStateFolder.cover_url || payload.response.cover_url
	} ) );
}

const updateFoldersOrganizerTo = ( { prevState, folders, newOrganizer, duplicateNames = [] } ) => {
	if ( !folders ) return prevState;
	const movedFolders = folders.filter( folder => !duplicateNames.includes( folder.name ) );
	const notMovedFolders = folders.filter( folder => duplicateNames.includes( folder.name ) );

	const stateWithMovedFolders = movedFolders
		.reduce( ( state, folder ) => state.updateIfSet( folder.id, prevStateFolder => ( {
			...prevStateFolder,
			organizer_id: newOrganizer.id,
			organizer_type: newOrganizer.type
		} ) ), prevState );

	return notMovedFolders.reduce( ( state, folder ) => state.updateIfSet( folder.id, prevStateFolder => ( {
		...prevStateFolder,
		organizer_id: folder.organizerID,
		organizer_type: newOrganizer.organizerType
	} ) ), stateWithMovedFolders );
}

const handleMoveFoldersIntoFolderSuccess = ( state, { meta } ) => {
	const { folders, folder, isFiltering } = meta;

	const stateWithUpdatedOrganizer = updateFoldersOrganizerTo( {
		prevState: state,
		folders,
		newOrganizer: folder
	} );
	const imagesCountFromFolders = folders.reduce( ( count, aFolder ) => count + aFolder.imagesCount, 0 );
	const filteredAssetsCountFromFolders = folders.reduce( ( count, f ) => count + f.filteredAssetsCount, 0 );
	const unresolvedCommentsCountFromFolders = folders.accumulate( f => f.unresolvedCommentsCount );

	return stateWithUpdatedOrganizer.updateIfSet( folder.id, prevStateFolder => ( {
		...prevStateFolder,
		top_level_folders_count: prevStateFolder.top_level_folders_count + folders.length,
		images_count: prevStateFolder.images_count + imagesCountFromFolders,
		unresolved_comments_count: prevStateFolder.unresolved_comments_count + unresolvedCommentsCountFromFolders,
		filtered_assets_count: prevStateFolder.filtered_assets_count + (
			isFiltering ? filteredAssetsCountFromFolders : 0
		)
	} ) );
};

const updateAncestors = ( state, folderID, updater ) => {
	if ( !state.has( folderID ) ) return state;

	let newState = state;
	let currentFolder = state.get( folderID );

	const hasParentFolder = folder => folder.organizer_type === 'Folder' && state.has( folder.organizer_id );

	while ( hasParentFolder( currentFolder ) ) {
		currentFolder = newState.get( currentFolder.organizer_id );
		newState = newState.update( currentFolder.id, updater );
	}

	return newState;
}

const handleCreateAssetInsideFolderSuccess = ( state, action ) => {
	const { folder_id } = action.payload.asset;

	const newState = state.updateIfSet( folder_id, ( prevStateFolder ) => {
		const {
			imagesCount,
			topLevelImagesCount
		} = Folder.fromJSON( prevStateFolder );

		return ( {
			...prevStateFolder,
			images_count: imagesCount + 1,
			top_level_images_count: topLevelImagesCount + 1
		} );
	} );

	return updateAncestors(
		newState,
		folder_id,
		ancestor => ( { ...ancestor, images_count: ancestor.images_count + 1 } )
	);
}

export const createFolderRequest = makeAsyncActionReducer( CREATE_FOLDER );
export const renameFolderRequest = makeAsyncActionReducer( CHANGE_FOLDER_NAME );

// The following are properties that are only serialized in the folder information endpoint
// So they should be maintained in the rest of the folder reducer's cases
const INFORMATION_ONLY_PROPERTIES = [ 'created_at', 'last_activity_by', 'owner', 'storage_used_in_bytes' ];
const mergeWithInformationOnlyProperties = ( { prevFolder, newFolder } ) => {
	const result = { ...newFolder };
	INFORMATION_ONLY_PROPERTIES.forEach( ( property ) => {
		result[ property ] = newFolder?.[ property ] ?? prevFolder?.[ property ];
	} );
	return result;
};

const handleFetchFoldersSuccess = ( state, payload, { organizerType, organizerID } ) => {
	const foldersFromOtherOrganizers = state.filter(
		folder => folder.organizer_id !== organizerID || folder.organizer_type !== organizerType
	);
	const payloadWithInformationOnlyProperties = payload.map( folder => mergeWithInformationOnlyProperties( {
		prevFolder: state.get( folder.id ),
		newFolder: folder
	} ) );

	return orderedMapForPayload( payloadWithInformationOnlyProperties ).merge( foldersFromOtherOrganizers );
};

export const folders = ( state = Map(), action ) => {
	switch ( action.type ) {
	case CREATE_FOLDER:
		return handleSuccess(
			state,
			action,
			prevState => handleCreateFolderSuccess( prevState, action )
		);
	case FETCH_FOLDERS_FOR_PROJECT:
	case FETCH_FOLDERS_FOR_FOLDER:
		return handleSuccess(
			state,
			action,
			prevState => handleFetchFoldersSuccess( prevState, action.payload, action.meta )
		);
	case FETCH_FOLDER_INFORMATION:
		return handleSuccess(
			state,
			action,
			prevState => prevState.updateIfSet(
				action.meta.folderID,
				prevStateFolder => ( {
					...prevStateFolder,
					...action.payload
				} )
			)
		);
	case MOVE_ITEMS_INTO_FOLDER:
		return handle( state, action, {
			start: ( prevState ) => {
				const stateWithAssetsMoved = handleMoveAssetsIntoFolderSuccess( prevState, action );
				return handleMoveFoldersIntoFolderSuccess( stateWithAssetsMoved, action );
			},
			success: ( prevState ) => {
				const stateWithSuccessfulAssetsMoved = updateFolderInfoOnlyWithMovedItems( prevState, action );
				return updateFoldersOrganizerTo( {
					prevState: stateWithSuccessfulAssetsMoved,
					folders: action.meta.folders,
					newOrganizer: action.meta.folder,
					duplicateNames: action.payload?.meta?.duplicate_names
				} );
			},
			failure: () => {
				const { folder, folders: movedFolders = [] } = action.meta;
				const foldersToRollback = [ folder, ...movedFolders ];
				return foldersToRollback.reduce( ( prevState, aFolder ) => (
					prevState.update( aFolder.id, () => aFolder.toJSON() )
				), state );
			}
		} );
	case FETCH_FOLDER:
	case CHANGE_FOLDER_NAME:
		return handleSuccess(
			state,
			action,
			( prevState ) => {
				const prevFolder = prevState.get( action.payload.id );
				return prevState.set(
					action.payload.id,
					mergeWithInformationOnlyProperties( { prevFolder, newFolder: action.payload } )
				)
			}
		);
	case DELETE_FOLDERS:
		return handleSuccess(
			state,
			action,
			prevState => action.payload.reduce(
				( result, deletedID ) => result.filter(
					folder => folder.id !== deletedID
				),
				prevState )
		);
	case CREATE_ASSET_FROM_DIRECT_UPLOAD:
		return handleSuccess(
			state,
			action,
			prevState => handleCreateAssetInsideFolderSuccess( prevState, action )
		)
	case UNFOLD_ITEMS:
		return handleSuccess( state, action, prevState => (
			prevState.map( folder => (
				action.payload.notDuplicatedFolderIDs.includes( folder.id )
					? { ...folder, organizer_id: folder.project_id, organizer_type: 'Project' }
					: folder
			) )
		) );
	case MARK_FOLDER_NEW_ACTIVITY_AS_SEEN:
		return handleSuccess( state, action, ( prevState ) => {
			const { folderID } = action.meta;
			const nextState = prevState.update( folderID, folder => ( { ...folder, has_new_activity: false } ) );
			return updateAncestors( nextState, folderID, ancestor => ( {
				...ancestor,
				subfolders_with_new_activity_count: ancestor.subfolders_with_new_activity_count - 1
			} ) );
		} );
	case CLEAR_DIRECT_SUBFOLDERS: {
		const { organizer } = action.payload;
		return state.filter( f => f.organizer_id !== organizer.id || f.organizer_type !== organizer.type );
	}
	case UPDATER_ACTION_TYPE.folders: {
		const { organizerID, organizerType } = action.meta;
		return handleFetchFoldersSuccess( state, [ ...action.payload ], { organizerID, organizerType } );
	}
	default:
		return state;
	}
};

const ancestorsOf = ( { folder, ancestors } ) => (
	ancestors.slice( 0, ancestors.findIndex( anAncestor => anAncestor.id === folder.id ) )
);

const addAncestorsToAncestors = ( { prevState, ancestors } ) => (
	ancestors.reduce(
		( state, ancestor ) => state.set( ancestor.id, ancestorsOf( { folder: ancestor, ancestors } ) ),
		prevState
	)
);

const handleFetchAncestorsSuccess = ( prevState, action ) => {
	const { payload: ancestors, meta: { folderID } } = action;
	return addAncestorsToAncestors( { prevState, ancestors } ).set( folderID, ancestors );
};

export const folderAncestors = ( state = Map(), action ) => {
	switch ( action.type ) {
	case FETCH_FOLDER_ANCESTORS:
		return handleSuccess(
			state,
			action,
			prevState => handleFetchAncestorsSuccess( prevState, action )
		);
	case LOGOUT:
		return Map();
	default:
		return state;
	}
};

export const folderOverviews = ( state = Map(), action ) => {
	switch ( action.type ) {
	case FETCH_FOLDER_OVERVIEW:
		return handleSuccess(
			state,
			action,
			prevState => (
				prevState.set( action.meta.folderID, action.payload )
			) );
	case LOGOUT:
		return Map();
	default:
		return state;
	}
};
