import PropTypes from 'prop-types';

import InviteWorkspaceMemberContainer from '../../containers/InviteWorkspaceMemberContainer/InviteWorkspaceMemberContainer';
import CloseableModalWithTitle from '../CloseableModalWithTitle/CloseableModalWithTitle';
import Workspace from '../../entities/workspace';

import './InviteWorkspaceMemberModal.scss';

const InviteWorkspaceMemberModal = ( { workspace, onClose } ) => (
	<CloseableModalWithTitle
		headerBorderType="small"
		onClose={onClose}
		name="invite-workspace-member"
		title="Invite members to your workspace"
	>
		<div className="InviteWorkspaceMemberModal">
			<div className="InviteWorkspaceMemberBox">
				<InviteWorkspaceMemberContainer
					workspace={workspace}
					onModalClose={onClose}
				/>
			</div>
		</div>
	</CloseableModalWithTitle>
);

InviteWorkspaceMemberModal.propTypes = {
	workspace: PropTypes.instanceOf( Workspace ).isRequired,
	onClose: PropTypes.func
};

InviteWorkspaceMemberModal.defaultProps = {
	onClose: () => {}
};

export default InviteWorkspaceMemberModal;
