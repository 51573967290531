import { removeJunkFiles } from '../../lib/fileUtils';
import FilePath from './FilePath';

export default class FileDirectory {
	name: string;
	files: File[];
	// eslint-disable-next-line no-use-before-define
	subDirectories: FileDirectory[];

	constructor(
		name: string,
		{
			files = [],
			subDirectories = []
		}: {
			files?: File[],
			subDirectories?: FileDirectory[]
		} = {}
	) {
		this.name = name;
		this.files = files;
		this.subDirectories = subDirectories;
	}

	get filesFromAllLevels(): File[] {
		const filesFromAllSubdirectoryLevels = this.subDirectories.map(
			subDirectory => subDirectory.filesFromAllLevels
		);

		return [ ...this.files, ...filesFromAllSubdirectoryLevels.flat() ];
	}

	get size(): number {
		return [ ...this.files, ...this.subDirectories ].reduce( ( sum, item ) => sum + item.size, 0 );
	}

	addFile( file: File, relativePath?: FilePath ) {
		if ( relativePath?.isEmpty || !relativePath ) {
			this.files.push( file );
		} else {
			const directory = this.findOrCreateSubDirectory( relativePath.head );
			directory.addFile( file, relativePath.tail );
		}
		return this;
	}

	removeJunkFiles() {
		this.files = removeJunkFiles( this.files );
		this.subDirectories.forEach( subDirectory => subDirectory.removeJunkFiles() );
	}

	private findOrCreateSubDirectory( name: string ) {
		return this.findSubDirectory( name ) || this.createSubDirectory( name );
	}

	private findSubDirectory( name: string ) {
		return this.subDirectories.find( subDirectory => subDirectory.name === name )
	}

	private createSubDirectory( name: string ) {
		const newSubDirectory = new FileDirectory( name );
		this.subDirectories.push( newSubDirectory );
		return newSubDirectory;
	}
}
