import { makeAutoObservable } from 'mobx';
import AssetDownloadJob from './AssetDownloadJob';
import type { DownloadInitializer } from './types';
import type { MessagingService } from '../MessagingService/types';

export type AssetDownloadServiceParams = {
	messagingService: MessagingService,
	downloadInitializer: DownloadInitializer
};

export default class AssetDownloadService {
	messagingService: MessagingService;
	downloadInitializer: DownloadInitializer;

	downloads: AssetDownloadJob[];

	constructor( { messagingService, downloadInitializer }: AssetDownloadServiceParams ) {
		this.messagingService = messagingService;
		this.downloadInitializer = downloadInitializer;

		this.downloads = [];

		makeAutoObservable( this );
	}

	get unfinishedDownloadsCount() {
		return this.unfinishedDownloads.length;
	}

	get hasUnfinishedDownloads() {
		return this.unfinishedDownloadsCount > 0;
	}

	get totalAssetsToZip() {
		return this.unfinishedDownloads.accumulate( download => download.totalAssets );
	}

	get totalAssetsZipped() {
		return this.unfinishedDownloads.accumulate( download => download.assetsZipped );
	}

	get allDownloadsSucceeded() {
		return this.downloads.every( download => download.status === 'successful' );
	}

	getDownload( downloadID: string ) {
		return this.downloads.find( download => download.id === downloadID );
	}

	registerAssetsDownload( id: string ) {
		const download = AssetDownloadJob.createAndObserve( {
			id,
			messagingService: this.messagingService,
			downloadInitializer: this.downloadInitializer
		} );

		this.downloads.push( download );
	}

	cancelAllDownloads() {
		this.unfinishedDownloads.forEach( download => download.cancel() );
	}

	private get unfinishedDownloads() {
		return this.downloads.filter( download => !download.hasFinished );
	}
}
