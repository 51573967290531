import { TEXT_GREY } from '../../styles/colors';

export default {
	base: {
		'fontFamily': "'Open Sans', sans-serif",
		'fontSize': '13px',
		'fontWeight': 'normal',
		'lineHeight': '1.46',
		'letterSpacing': '.14px',
		'color': '#000',
		'fontSmoothing': 'antialiased',
		':disabled': {
			color: TEXT_GREY
		}
	}
};
