import { isErrorWithStatus } from '.';

export const isNotAllowedError = isErrorWithStatus( 403 );
export const isNotFoundError = isErrorWithStatus( 404 );
export const isUnauthorizedError = isErrorWithStatus( 401 );
export const isInvalidError = isErrorWithStatus( 400 );
export const isNotAllowedOrNotFound = error => (
	isNotAllowedError( error ) || isNotFoundError( error )
);
export const isUnprocessableEntityError = isErrorWithStatus( 422 );
