import uuid from 'uuid/v1';
import { ITEM_UPLOAD_STATUS } from './DirectFilesUploaderCoordinator';

export default class FolderUploadJob {
	constructor( {
		projectID,
		organizerID,
		organizerType,
		directory,
		parentUploadID,
		onSuccess,
		onFailure,
		onStatusUpdate
	} ) {
		this.projectID = projectID;
		this.organizerID = organizerID;
		this.organizerType = organizerType;
		this.directory = directory;
		this.parentUploadID = parentUploadID;
		this.onSuccess = onSuccess;
		this.onFailure = onFailure;
		this.onStatusUpdate = onStatusUpdate;

		this.id = uuid();
		this.status = ITEM_UPLOAD_STATUS.WAITING;
		this.successfulChildUploads = 0;
		this.failedChildUploads = 0;
	}

	get isActive() {
		return this.status === ITEM_UPLOAD_STATUS.WAITING || this.status === ITEM_UPLOAD_STATUS.UPLOADING;
	}

	get folderID() {
		return this.organizerType === 'Folder' ? this.organizerID : null;
	}

	start( { createFolder, createFileUploads, createFolderUploads } ) {
		this._updateStatus( ITEM_UPLOAD_STATUS.UPLOADING );

		createFolder( {
			name: this.directory.name,
			organizerID: this.organizerID,
			organizerType: this.organizerType
		} ).then( ( { error, payload } ) => {
			this.createdFolderID = payload?.id;

			if ( !this._isUploading ) return;
			if ( error ) {
				this._fail();
			} else {
				this._createChildUploads( {
					createdFolderID: payload.id,
					createFileUploads,
					createFolderUploads
				} );
			}
		} );
	}

	onChildUploadSucceeded = () => {
		this.successfulChildUploads += 1;
		this._checkIfFinished();
	}

	onChildUploadFailed = () => {
		this.failedChildUploads += 1;
		this._checkIfFinished();
	}

	cancel() {
		this._updateStatus( ITEM_UPLOAD_STATUS.ABORTED );
	}

	_createChildUploads( { createdFolderID, createFileUploads, createFolderUploads } ) {
		createFileUploads( {
			projectID: this.projectID,
			folderID: createdFolderID,
			assetID: undefined,
			files: this.directory.files,
			uploadingRoundForAsset: false,
			parentUploadID: this.id
		} );

		createFolderUploads( {
			projectID: this.projectID,
			organizerID: createdFolderID,
			organizerType: 'Folder',
			directories: this.directory.subDirectories,
			parentUploadID: this.id,
			onSuccess: this.onChildUploadSucceeded,
			onFailure: this.onChildUploadFailed
		} );
	}

	_checkIfFinished() {
		if ( !this._isUploading ) return;

		const totalChildUploads = this.directory.files.length + this.directory.subDirectories.length;
		const finishedChildUploads = this.successfulChildUploads + this.failedChildUploads;

		if ( finishedChildUploads >= totalChildUploads ) {
			this.onSuccess?.( this.createdFolderID );

			if ( this.failedChildUploads > 0 ) {
				this._fail();
			} else {
				this._updateStatus( ITEM_UPLOAD_STATUS.SUCCEEDED );
			}
		}
	}

	_fail() {
		this._updateStatus( ITEM_UPLOAD_STATUS.FAILED );
		this.onFailure?.( this );
	}

	_isUploading() {
		return this.status === ITEM_UPLOAD_STATUS.UPLOADING;
	}

	_updateStatus( newStatus ) {
		this.status = newStatus;
		this.onStatusUpdate( this.id, newStatus );
	}
}
