export default abstract class AppError extends Error {
	public name: string;
	public message: string;
	public cause?: Error;

	constructor( { message = '', cause }: { message?: string, cause?: Error } ) {
		super( message );
		this.name = this.constructor.name;
		this.cause = cause;
		this.message = message;
	}
}
