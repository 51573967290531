import PropTypes from 'prop-types';
import BillingInput from '../BillingInput/BillingInput';

const AddressInput = ( { value, onChange, onValidationStatusChange, disabled, required } ) => (
	<BillingInput
		className="AddressInput"
		label="Address"
		name="address"
		placeholder="i.e. 42 Fake Street"
		value={value}
		onChange={onChange}
		onValidationStatusChange={onValidationStatusChange}
		disabled={disabled}
		required={required}
	/>
);

AddressInput.propTypes = {
	value: PropTypes.any,
	onChange: PropTypes.func,
	onValidationStatusChange: PropTypes.func,
	disabled: PropTypes.bool,
	required: PropTypes.bool
};

AddressInput.defaultProps = {
	value: '',
	onChange: () => {},
	onValidationStatusChange: () => {},
	disabled: false,
	required: false
};

export default AddressInput;
