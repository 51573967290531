import {
	SHOW_SUCCESS_SNACKBAR,
	SHOW_ERROR_SNACKBAR,
	DISMISS_CURRENT_SNACKBAR,
	SHOW_LOADING_SNACKBAR
} from './types';

const DEFAULT_TIMEOUT = 9000;
const DEFAULT_CHANNEL = 'default';
export const DELETION_CHANNEL = 'deletion';

export const dismissCurrentSnackbar = ( { channel = DEFAULT_CHANNEL } = {} ) => ( {
	type: DISMISS_CURRENT_SNACKBAR,
	payload: { channel }
} );

const dispatchMessageActionWithTimeout = ( { dispatch, actionType, texts, timeout, channel = DEFAULT_CHANNEL } ) => {
	dispatch( {
		type: actionType,
		payload: {
			channel,
			texts,
			onClose: () => dispatch( dismissCurrentSnackbar( { channel } ) )
		}
	} );

	setTimeout( () => dispatch( dismissCurrentSnackbar( { channel } ) ), timeout );
};

export const showSuccessMessage = (
	texts, timeout = DEFAULT_TIMEOUT, channel = DEFAULT_CHANNEL
) => dispatch => dispatchMessageActionWithTimeout( {
	actionType: SHOW_SUCCESS_SNACKBAR,
	channel,
	dispatch,
	texts,
	timeout
} );

export const showErrorMessage = (
	texts, timeout = DEFAULT_TIMEOUT, channel = DEFAULT_CHANNEL
) => dispatch => dispatchMessageActionWithTimeout( {
	actionType: SHOW_ERROR_SNACKBAR,
	channel,
	dispatch,
	texts,
	timeout
} );

export const showLoadingMessage = ( { channel = DEFAULT_CHANNEL, texts } ) => ( dispatch ) => {
	dispatch( {
		type: SHOW_LOADING_SNACKBAR,
		payload: {
			channel,
			texts
		}
	} );
};
