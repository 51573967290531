import BreadcrumbLevel from '../structures/breadcrumbLevel';

export default class BreadcrumbLevelFactory {
	constructor( { levels, history } ) {
		this._levels = levels.compact();
		this._history = history;
	}

	static arrayFrom( { levels, history } ) {
		return new this( { levels, history } ).createArray();
	}

	createArray() {
		return this._levels.reduce( ( breadcrumbs, level ) => {
			const breadcrumb = new BreadcrumbLevel( { level, history: this._history } );
			if ( breadcrumbs.length > 0 ) {
				breadcrumbs.last.next = breadcrumb;
			}
			return breadcrumbs.concat( breadcrumb );
		}, [] );
	}
}
