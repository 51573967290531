/* eslint-disable jsx-a11y/no-autofocus */
import { useMemo } from 'react';
import classNames from 'classnames';
import { stopPropagation } from '../../lib/eventUtils';

import './FormInput.scss';

export type FormInputProps = {
	key?: string;
	value: string;
	label?: string;
	onChange: ( value: string ) => void;
	onBlur?: () => void;
	onFocus?: () => void;
	type?: string;
	placeholder?: string;
	name?: string;
	disabled?: boolean;
	error?: string | boolean;
	autoComplete?: string;
	className?: string;
	required?: boolean;
	noError?: boolean;
	maxLength?: number;
	autoFocus?: boolean;
	onClick?: () => void;
	informationTooltip?: React.ReactElement;
	inputMode?: React.HTMLAttributes<HTMLInputElement>['inputMode'];
	infoMessage?: string;
	hideBottomMessageSpace?: boolean;
}

const FormInput = ( {
	value, label, onChange, onBlur,
	onFocus, type, placeholder, name,
	disabled, error, autoComplete,
	className, required, hideBottomMessageSpace,
	maxLength, autoFocus, onClick, informationTooltip,
	inputMode, infoMessage
} : FormInputProps ) => {
	const bottomMessage = useMemo( () => {
		if ( error && typeof error === 'string' ) {
			return <p className="error-message" data-testid="error-message">{error}</p>;
		}
		if ( infoMessage ) {
			return <p className="info-message" data-testid="info-message">{infoMessage}</p>;
		}
		return null;
	}, [ error, infoMessage ] );

	return (
		<div className={classNames( 'FormInput', { error: !!error, hideBottomMessageSpace }, className )}>
			{!!label && (
				<label htmlFor={name}>
					{label}
					{required && <span>*</span>}
					{informationTooltip}
				</label>
			)}
			<input
				id={name}
				name={name}
				type={type}
				disabled={disabled}
				className={classNames( 'input', { error: !!error } )}
				data-testid="input"
				value={value}
				onChange={event => onChange( event.target.value )}
				onBlur={onBlur}
				onFocus={onFocus}
				placeholder={placeholder}
				autoComplete={autoComplete}
				maxLength={maxLength}
				autoFocus={autoFocus}
				onClick={onClick}
				onDoubleClick={stopPropagation}
				inputMode={inputMode}
			/>
			<div className="bottom-message-container">
				{ bottomMessage }
			</div>
		</div>
	);
};

export default FormInput;
