import { Navigate } from '../../../navigation/useNavigate';

export default class Project {
	public id: number;
	public name: string;

	constructor( { id, name } : { id: number, name: string } ) {
		this.id = id;
		this.name = name;
	}

	static fromJson( json : { id: number, name: string } ) {
		return new this( json );
	}

	navigateToScreenWith( navigate : Navigate, state: unknown ) {
		navigate.toProject( this.id, state )
	}
}
