import TagManager, { TagManagerArgs } from 'react-gtm-module';

class GoogleTagManager {
	events = {
		registration: 'registrationComplete'
	};

	initializeEvent = ( event?: string ) => {
		if ( this.isGoogleTagManagerEnabled() && this.gtmId ) {
			const tagManagerArgs: TagManagerArgs = { gtmId: this.gtmId };

			if ( event ) {
				tagManagerArgs.dataLayer = { event };
			}

			TagManager.initialize( tagManagerArgs );
		}
	}

	initialize = () => {
		this.initializeEvent();
	}

	private get gtmId() {
		return process.env.REACT_APP_GTM_ID;
	}

	private isGoogleTagManagerEnabled = () => Boolean( process.env.REACT_APP_GTM_ENABLED === 'true' );
}

export default new GoogleTagManager();
