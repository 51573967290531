import { FETCH_NOTIFICATIONS_PREFERENCES, EDIT_NOTIFICATIONS_PREFERENCES, RESET_EDIT_NOTIFICATIONS_PREFERENCES } from './types';
import { NOTIFICATIONS_PREFERENCES_URL } from '../config/urls';

export const fetchNotificationsPreferences = () => (
	dispatch, _, api
) => dispatch( {
	type: FETCH_NOTIFICATIONS_PREFERENCES,
	promise: api
		.get( NOTIFICATIONS_PREFERENCES_URL )
		.then( response => response.response )
} );

/**
 * Calls the API to update the user's notifications preferences
 * @param {NotificationsPreferences} notificationPreferences
 */
export const editNotificationsPreferences = notificationPreferences => (
	dispatch, _, api
) => {
	const jsonPreferences = notificationPreferences.toJSON();
	return dispatch( {
		type: EDIT_NOTIFICATIONS_PREFERENCES,
		promise: api
			.put( NOTIFICATIONS_PREFERENCES_URL, jsonPreferences )
			.then( () => jsonPreferences )
	} );
};

export const resetEditNotificationsPreferencesRequest = () => ( {
	type: RESET_EDIT_NOTIFICATIONS_PREFERENCES
} );
