/* eslint-disable jsx-a11y/label-has-associated-control */
import { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';

import EmailChip from '../EmailChip/EmailChip';
import InputWithSuggestions from '../InputWithSuggestions/InputWithSuggestions';

import './EmailsInput.scss';
import Button from '../../new_arch/components/Button/Button';
import ContactsGroupsModal from '../../modals/ContactsGroupsModal/ContactsGroupsModal';
import Workspace from '../../entities/workspace';

const EmailsInput = forwardRef( ( {
	projectMemberRoleName, addedEmails, disabled, value, workspace,
	onChange, placeholder, onKeyDown, onDeleteEmail, onPaste, addEmailsFromGroups, name, autoComplete
}, ref ) => {
	const [ shouldShowContactsGroupsModal, setShouldShowContactsGroupsModal ] = useState( false );

	const onAddContactsGroupClicked = () => setShouldShowContactsGroupsModal( true );

	const onCloseContactsGroupsModal = () => {
		setShouldShowContactsGroupsModal( false );
	}

	const addEmailsFromSelectedGroups = ( emails ) => {
		addEmailsFromGroups( emails );
		onCloseContactsGroupsModal();
	};

	return (
		<div className="EmailsInput">
			<div className="invitees-container">
				<div className="label-line">
					<label>Invite {projectMemberRoleName}</label>
				</div>
				<div className="emails-input">
					{addedEmails.map( email => (
						<EmailChip
							key={email.value}
							isValid={email.isValid}
							value={email.value}
							onDeleteEmail={onDeleteEmail}
						/>
					) )}
					<div className="input">
						<InputWithSuggestions
							ref={ref}
							name={name}
							value={value}
							disabled={disabled}
							onChange={event => onChange( event.target.value.trim() )}
							placeholder={placeholder}
							onKeyDown={onKeyDown}
							onPaste={onPaste}
							autoComplete={autoComplete}
							autoFocus
						/>
					</div>
				</div>
				<Button
					className="add-group-button"
					type="text"
					onClick={onAddContactsGroupClicked}
				>
					+ add Group
				</Button>
				{shouldShowContactsGroupsModal && (
					<ContactsGroupsModal
						onClose={onCloseContactsGroupsModal}
						onConfirm={addEmailsFromSelectedGroups}
						workspace={workspace}
					/>
				)}
			</div>
		</div>
	);
} );

EmailsInput.propTypes = {
	projectMemberRoleName: PropTypes.string.isRequired,
	addedEmails: PropTypes.arrayOf( PropTypes.any ).isRequired,
	addEmailsFromGroups: PropTypes.func,
	disabled: PropTypes.bool,
	value: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
	onKeyDown: PropTypes.func.isRequired,
	onDeleteEmail: PropTypes.func.isRequired,
	onPaste: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	autoComplete: PropTypes.string.isRequired,
	workspace: PropTypes.instanceOf( Workspace ).isRequired
};

EmailsInput.defaultProps = {
	disabled: false,
	placeholder: ''
};

export default EmailsInput;
