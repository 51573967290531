import { FETCH_PLAN_ADD_ONS, BUY_ADD_ON, REMOVE_ADD_ON } from '../actions/types';
import { PRICING_PLANS_URL, WORKSPACES_URL } from '../config/urls';
import { trackAddOnBought } from '../services/tracker/trackers/addOns';

export default class AddOnSystem {
	constructor( dispatch ) {
		this.dispatch = dispatch;
	}

	buyAddOn( { addOn, addOnPrice, workspaceID } ) {
		return this.dispatch( ( dispatch, _, api ) => dispatch( {
			type: BUY_ADD_ON,
			promise: api.post(
				this._workspaceAddOnURL( { workspaceID } ),
				{ add_on_price_id: addOnPrice.id, quantity: 1 }
			).then( ( response ) => {
				trackAddOnBought( {
					addOnType: addOn.type,
					includedAmount: addOn.includedAmount
				} );
				return response.response;
			} )
		} ) );
	}

	fetchPlanAddOns( { pricingPlan } ) {
		return this.dispatch( ( dispatch, _, api ) => dispatch( {
			type: FETCH_PLAN_ADD_ONS,
			promise: api.get(
				`${PRICING_PLANS_URL}/${pricingPlan.id}/add_ons`
			).then( response => response.response )
		} ) );
	}

	removeAddOn( { addOnPriceID, workspaceID } ) {
		return this.dispatch( ( dispatch, _, api ) => dispatch( {
			type: REMOVE_ADD_ON,
			promise: api.delete(
				this._workspaceAddOnURL( { workspaceID } ),
				{ add_on_price_id: addOnPriceID, quantity: 1 }
			).then( response => response.response )
		} ) );
	}

	_workspaceAddOnURL( { workspaceID } ) {
		return `${WORKSPACES_URL}/${workspaceID}/add_ons`;
	}
}
