import { Component } from 'react';
import PropTypes from 'prop-types';
import { connectComponent } from '../../lib/connectComponent';
import { trackClickedLoginForSharedProject, trackClickedRegisterForSharedProject }
	from '../../services/tracker/trackers/projects';
import AuthenticationLinks from '../../components/AuthenticationLinks/AuthenticationLinks';
import { getSharedEntity } from '../../selectors/sharedProjects';
import SharedProject from '../../entities/sharedProject';
import Folder from '../../entities/folder';
import ShareRef from '../../structures/shareRef';

export class AuthenticationLinksContainer extends Component {
	constructor( props ) {
		super( props );

		this._onLoginClicked = this._onLoginClicked.bind( this );
		this._onRegisterClicked = this._onRegisterClicked.bind( this );
	}

	_onLoginClicked() {
		const { props: { sharedEntity } } = this;
		// TODO: track this for shared folders accordingly
		if ( sharedEntity?.isProject ) trackClickedLoginForSharedProject( sharedEntity );
	}

	_onRegisterClicked() {
		const { props: { sharedEntity } } = this;
		// TODO: track this for shared folders accordingly
		if ( sharedEntity?.isProject ) trackClickedRegisterForSharedProject( sharedEntity );
	}

	render() {
		return (
			<AuthenticationLinks
				onLoginClick={this._onLoginClicked}
				onRegisterClick={this._onRegisterClicked}
			/>
		);
	}
}

AuthenticationLinksContainer.propTypes = {
	shareRef: PropTypes.instanceOf( ShareRef ),
	sharedEntity: PropTypes.oneOfType( [
		PropTypes.instanceOf( SharedProject ),
		PropTypes.instanceOf( Folder )
	] )
};

AuthenticationLinksContainer.defaultProps = {
	sharedEntity: undefined
};

export default connectComponent( ( state, props ) => ( {
	sharedEntity: getSharedEntity( state, props )
} ) )( AuthenticationLinksContainer );
