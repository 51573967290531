import PropTypes from 'prop-types';

import './AuthenticationLinks.scss';

const AuthenticationLinks = ( { onLoginClick, onRegisterClick } ) => (
	<div className="AuthenticationLinks">
		<ul className="AuthenticationLinksList">
			<li><a href="/signup" onClick={onRegisterClick}>Register</a></li>
			<li><a href="/" onClick={onLoginClick}>Login</a></li>
		</ul>
	</div>
);

AuthenticationLinks.propTypes = {
	onLoginClick: PropTypes.func,
	onRegisterClick: PropTypes.func
};

AuthenticationLinks.defaultProps = {
	onLoginClick: undefined,
	onRegisterClick: undefined
};

export default AuthenticationLinks;
