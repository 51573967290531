export const parseIDsArrayFromStringifiedArray = ( string ) => {
	let array;
	if ( ( typeof string !== 'string' && !Array.isArray( string ) ) || !string ) return [];
	if ( Array.isArray( string ) ) {
		array = string;
	} else {
		array = string.match( /\d*/g );
	}

	return array
		.map( el => parseInt( el, 10 ) )
		.filter( el => !!el );
};
