import { MutateOptions } from '@tanstack/react-query';
import useMutationQuery from '../../../queries/useMutationQuery';
import AppError from '../../../errors/AppError';
import useAuthSystem from '../system/useAuthSystem';
import useSessionCleanup from '../hooks/useSessionCleanup';

const useLogout = () => {
	const authSystem = useAuthSystem();
	const cleanupSession = useSessionCleanup();

	const { mutate, ...mutation } = useMutationQuery(
		authSystem.logout,
		{ onSuccess: cleanupSession }
	)

	const logout = ( options?: MutateOptions<Response, AppError, unknown, unknown> ) => mutate( undefined, options )

	return { logout, ...mutation }
}

export default useLogout;
