import PropTypes from 'prop-types';

const HistoryPropType = PropTypes
	.shape( {
		push: PropTypes.func,
		replace: PropTypes.func,
		goBack: PropTypes.func,
		length: PropTypes.number
	} );

export const LocationPropType = PropTypes.shape( {
	pathname: PropTypes.string,
	search: PropTypes.string,
	hash: PropTypes.string,
	state: PropTypes.any,
	key: PropTypes.string
} );

export default HistoryPropType;
