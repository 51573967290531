import { createSelector } from 'reselect';
import Subscription from '../entities/subscription';
import { getPricingPlanPrices } from './pricingPlanPrices';

export const getRawSubscriptions = state => state.subscriptions;

export const getSubscriptions = createSelector(
	[ getRawSubscriptions, getPricingPlanPrices ],
	(
		rawSubscriptions,
		pricingPlanPrices
	) => rawSubscriptions.map( subscription => Subscription.fromJSON(
		subscription,
		pricingPlanPrices.get( subscription.pricing_plan_price )
	) )
);
