import { makeAutoObservable } from 'mobx';
import { handleAsyncActionResult } from '../../lib/reduxUtils';
import { ERROR_DEFAULT } from '../../messages/projects';

export default class LeaveWorkspaceTabPresenter {
	constructor( {
		popupSystem,
		workspace,
		leaveWorkspace,
		showSuccessMessage,
		showErrorMessage,
		history
	} ) {
		this._popupSystem = popupSystem;
		this.workspace = workspace;
		this.leaveWorkspace = leaveWorkspace;
		this.showSuccessMessage = showSuccessMessage;
		this.showErrorMessage = showErrorMessage;
		this.history = history;
		makeAutoObservable( this, { _popupSystem: false } );
	}

	onLeaveWorkspaceClick = () => {
		this._popupSystem.showDefaultConfirmationDialog( {
			title: 'Leave Workspace',
			description: `Are you sure you want to leave ${this.workspace?.name} workspace?\n\nTo be a workspace member again you will need to be re-invited by the owner.`,
			acceptText: 'Leave',
			cancelText: 'Cancel',
			descriptionAlignment: 'center'
		} )
			.then( ( confirm ) => {
				if ( confirm ) this._leaveWorkspace();
				this._popupSystem.hideConfirmationDialog();
			} );
	};

	_leaveWorkspace = () => {
		this._handleAsyncActionResult( this.leaveWorkspace( this.workspace.id ) );
	}

	_redirectToProfilePage = () => {
		this.history.replace( '/user/profile' );
	}

	_handleAsyncActionResult = result => handleAsyncActionResult(
		result,
		this._successMessageTexts,
		{ title: ERROR_DEFAULT },
		undefined,
		this.showSuccessMessage,
		this.showErrorMessage,
		this._redirectToProfilePage
	);

	get _successMessageTexts() {
		return {
			title: `You left ${this.workspace.name} workspace.`
		};
	}
}
