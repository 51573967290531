import { FETCH_PROJECT_INVITATION, FETCH_PROJECT_INVITATIONS, DELETE_PROJECT_INVITATION } from './types';
import { PROJECT_INVITATIONS_URL, PROJECTS_URL } from '../config/urls';

export const fetchProjectInvitation = ( hash, em ) => ( dispatch, _, api ) => dispatch( {
	type: FETCH_PROJECT_INVITATION,
	promise: api
		.get( `${PROJECT_INVITATIONS_URL}/${hash}?em=${em}` )
		.then( response => ( {
			...response.response,
			hash
		} ) )
} );

export const fetchProjectInvitations = projectID => ( dispatch, _, api ) => dispatch( {
	type: FETCH_PROJECT_INVITATIONS,
	promise: api
		.get( `${PROJECTS_URL}/${projectID}/pending_invites` )
		.then( response => response.response ),
	meta: { projectID }
} );

export const deleteProjectInvitation = invitationID => ( dispatch, _, api ) => dispatch( {
	type: DELETE_PROJECT_INVITATION,
	promise: api
		.delete( `${PROJECT_INVITATIONS_URL}/${invitationID}` ),
	meta: { invitationID }
} );
