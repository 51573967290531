import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import useSubscriptionModalPresenter from '../../presenters/SubscriptionModalPresenter/useSubscriptionModalPresenter';
import SubscriptionModal from '../../components/SubscriptionModal/SubscriptionModal';
import { PRICING_PLANS_AVAILABLE } from '../../config/global';

export const SubscriptionModalContainer = ( {
	currentUser
} ) => {
	const presenter = useSubscriptionModalPresenter( { currentUser } );
	const shouldRenderModal = PRICING_PLANS_AVAILABLE && presenter.showSubscriptionModal;

	return shouldRenderModal ? <SubscriptionModal presenter={presenter} /> : null;
};

SubscriptionModalContainer.propTypes = {
	currentUser: PropTypes.number.isRequired
};

export default observer( SubscriptionModalContainer );
