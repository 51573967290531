import { Map } from 'immutable';
import { SHOW_SUBSCRIPTION_MODAL, HIDE_SUBSCRIPTION_MODAL, LOGOUT,
	SHOW_PROJECT_SETTINGS_MODAL, HIDE_PROJECT_SETTINGS_MODAL,
	SHOW_INVITE_MEMBERS_MODAL, HIDE_INVITE_MEMBERS_MODAL } from '../actions/types';
import { SUBSCRIPTION_MODAL_KEY } from '../systems/ModalSystem';

const getDefaultState = () => Map( {
	[ SUBSCRIPTION_MODAL_KEY ]: false
} );

export const modals = ( state = getDefaultState(), action ) => {
	switch ( action.type ) {
	case SHOW_SUBSCRIPTION_MODAL:
		return state.set( action.payload, true );
	case HIDE_SUBSCRIPTION_MODAL:
		return state.set( action.payload, false );
	case LOGOUT:
		return getDefaultState();
	default:
		return state;
	}
};

export const isProjectSettingsModalOpen = ( state = false, action ) => {
	switch ( action.type ) {
	case SHOW_PROJECT_SETTINGS_MODAL:
		return true;
	case HIDE_PROJECT_SETTINGS_MODAL:
		return false;
	case LOGOUT:
		return false;
	default:
		return state;
	}
}

export const isInviteMembersModalOpen = ( state = false, action ) => {
	switch ( action.type ) {
	case SHOW_INVITE_MEMBERS_MODAL:
		return true;
	case HIDE_INVITE_MEMBERS_MODAL:
		return false;
	case LOGOUT:
		return false;
	default:
		return state;
	}
}
