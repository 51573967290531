import { SHOW_SUBSCRIPTION_MODAL, HIDE_SUBSCRIPTION_MODAL,
	SHOW_PROJECT_SETTINGS_MODAL, HIDE_PROJECT_SETTINGS_MODAL,
	SHOW_INVITE_MEMBERS_MODAL, HIDE_INVITE_MEMBERS_MODAL } from '../actions/types';

export const SUBSCRIPTION_MODAL_KEY = 'SUBSCRIPTION_MODAL';
export const PROJECT_SETTINGS_MODAL_KEY = 'PROJECT_SETTINGS_MODAL';
export const INVITE_MEMBERS_MODAL_KEY = 'INVITE_MEMBERS_MODAL';

export default class ModalSystem {
	constructor( dispatch ) {
		this.dispatch = dispatch;
		this.modals = {
			[ SUBSCRIPTION_MODAL_KEY ]: {
				showModalType: SHOW_SUBSCRIPTION_MODAL,
				hideModalType: HIDE_SUBSCRIPTION_MODAL
			},
			[ PROJECT_SETTINGS_MODAL_KEY ]: {
				showModalType: SHOW_PROJECT_SETTINGS_MODAL,
				hideModalType: HIDE_PROJECT_SETTINGS_MODAL
			},
			[ INVITE_MEMBERS_MODAL_KEY ]: {
				showModalType: SHOW_INVITE_MEMBERS_MODAL,
				hideModalType: HIDE_INVITE_MEMBERS_MODAL
			}
		};
	}

	showModal( { modalKey } ) {
		const { showModalType } = this.modals[ modalKey ];
		return this.dispatch( {
			type: showModalType,
			payload: modalKey
		} );
	}

	hideModal( { modalKey } ) {
		const { hideModalType } = this.modals[ modalKey ];
		return this.dispatch( {
			type: hideModalType,
			payload: modalKey
		} );
	}
}
