import { ReactNode, useEffect, useState } from 'react';
import { api, guestHashStore } from '../../store/store';

const GUEST_HASH_HTTP_CUSTOM_HEADER = 'X-Guest-Hash';

const GuestHashHeaderSetter = ( { projectID, children }: { projectID: number, children: ReactNode } ) => {
	const [ guestHashWasSearched, setGuestHashWasSearched ] = useState( false );

	useEffect( () => {
		const guestHash = guestHashStore.getProjectGuestHash( projectID );
		if ( guestHash ) api.headers.set( GUEST_HASH_HTTP_CUSTOM_HEADER, guestHash );

		setGuestHashWasSearched( true );

		return () => api.headers.delete( GUEST_HASH_HTTP_CUSTOM_HEADER );
	}, [ projectID ] );

	// If we rendered 'children' directly, some nested useEffect's could be
	// executed before this one, potentially leading to WS requests being
	// fired without the guest hash header set when it was needed.
	// That's why we wait for the guest hash header to be searched (and set, if necessary).
	return guestHashWasSearched ? children : <div />;
};

export default GuestHashHeaderSetter;
