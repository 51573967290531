const CANVAS = document.createElement( 'canvas' );
const DEFAULT_FONT_WEIGHT = 400;
const DEFAULT_FONT_STYLE = 'normal';

export const getTextWidth = (
	text,
	{
		fontFamily,
		fontSize,
		fontWeight = DEFAULT_FONT_WEIGHT,
		fontStyle = DEFAULT_FONT_STYLE
	}
) => {
	const ctx = CANVAS.getContext( '2d' );
	if ( !ctx ) { return 0; }
	ctx.font = `${fontWeight} ${fontStyle} ${fontSize} ${fontFamily}`;
	const { width } = ctx.measureText( text );

	return width;
};

export const getCharacterWidth = ( text, textWidth ) => (
	text.length ? textWidth / text.length : 0
);

export const reduceTextUntilItFits = ( text, intialStart, intialEnd, maxWidth, styles ) => {
	let start = intialStart;
	let end = intialEnd;

	let truncatedText = '';
	let truncatedTextWidth = 0;

	do {
		const startText = text.substring( 0, start );
		const endText = text.substring( text.length - end );

		truncatedText = `${startText}...${endText}`;
		truncatedTextWidth = getTextWidth( truncatedText, styles );

		start -= 1;
	} while ( ( truncatedTextWidth > maxWidth ) && start > 0 );

	return truncatedText;
};

export const removeTags = ( textWithHTML ) => {
	if ( textWithHTML === undefined || textWithHTML === null || textWithHTML === '' ) { return ''; }
	const stringWithHTML = textWithHTML.toString();
	return stringWithHTML.replace( /(<([^>]+)>)/ig, '' ) || '';
};

export const removeTagsAndTrim = textWithHTML => removeTags( textWithHTML ).trim();
