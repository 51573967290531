import PropTypes from 'prop-types';

import Select from '../UI/Select/Select';
import useDeepEffect from '../../hooks/useDeepEffect';

const StateSelector = ( {
	selected, onChange, required, isSearchable, disabled, label, options
} ) => {
	useDeepEffect(
		() => {
			onChange( undefined );
		},
		[ options ]
	);

	return (
		<div className="StateSelector">
			<Select
				key={selected}
				name="state"
				type="tall"
				label={label}
				options={options}
				selected={selected}
				onChange={onChange}
				placeholder={`Select a ${label}`}
				required={required}
				isSearchable={isSearchable}
				disabled={disabled}
			/>
		</div>
	);
};

StateSelector.propTypes = {
	onChange: PropTypes.func,
	selected: PropTypes.shape( {
		label: PropTypes.string,
		value: PropTypes.string
	} ),
	required: PropTypes.bool,
	isSearchable: PropTypes.bool,
	disabled: PropTypes.bool,
	label: PropTypes.string.isRequired,
	options: PropTypes.arrayOf( PropTypes.shape( {
		label: PropTypes.string,
		value: PropTypes.string
	} ) ).isRequired
};

StateSelector.defaultProps = {
	onChange: () => {},
	selected: undefined,
	required: false,
	isSearchable: true,
	disabled: false
};

export default StateSelector;
