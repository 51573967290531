const createStatusObject = ( text, isActiveStatus, showsInProfileDropdown ) => ( {
	text, isActiveStatus, showsInProfileDropdown
} );

const WORKSPACE_STATUSES = Object.freeze( {
	ACTIVE: createStatusObject( 'Active', true, false ),
	SUSPENDED: createStatusObject( 'Suspended', false, true )
} );

export class WorkspaceStatusFactory {
	static statusForWorkspace( workspace ) {
		return workspace.hasSubscription
			? this._statusForWorkspaceWithSubscription( workspace.subscription )
			: WORKSPACE_STATUSES.SUSPENDED;
	}

	static _statusForWorkspaceWithSubscription( subscription ) {
		return subscription.active
			? WORKSPACE_STATUSES.ACTIVE
			: WORKSPACE_STATUSES.SUSPENDED;
	}
}
