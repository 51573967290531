import Label from './label';
import NullLabel from './nullLabel';

export type OverviewData = Array<[ label: Label | NullLabel, assetCount: number ]>;

export default class OrganizerOverview {
	overviewData: OverviewData;
	constructor( overviewData: OverviewData ) {
		this.overviewData = overviewData;
	}
}
