import { useState } from 'react';
import classNames from 'classnames';

import ShareLinkButton from '../ShareLinkButton/ShareLinkButton';
import ShareLinkTooltip from '../ShareLinkTooltip/ShareLinkTooltip';

import Icon from '../../new_arch/components/Icon/Icon';
import { PRIMARY_BASE, TEXT_GREY } from '../../styles/colors';
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import Project from '../../entities/project';

import './ShareGuestLink.scss';

export type ShareGuestLinkProps = {
	project : Project,
	updateGuestHashEnabled: ( projectID: number, enabled: boolean ) => void,
	canShareGuestLink?: boolean
};

const ShareGuestLink = ( { project, updateGuestHashEnabled, canShareGuestLink }: ShareGuestLinkProps ) => {
	const [ showSuccessMessage, setShowSuccessMessage ] = useState( false );
	const { guestHashString, guestHashIsPublic } = project;
	const guestLink = `${document.location.origin}/p/${guestHashString}`;

	const copyShareLink = () => {
		navigator.clipboard.writeText( guestLink );
		setShowSuccessMessage( true );
		setTimeout( () => setShowSuccessMessage( false ), 2000 );
	};

	const shareLinkOptions = [
		{
			name: 'Project Link Settings'
		},
		{
			className: 'public-link-dropdown-icon',
			name: 'Anyone with link can access. No account needed',
			icon: 'public',
			onClick: () => { updateGuestHashEnabled( project.id, true ) },
			size: 12,
			color: PRIMARY_BASE
		},
		{
			className: 'private-link-dropdown-icon',
			name: 'Only invited collaborators can access',
			icon: 'people-outlined',
			onClick: () => { updateGuestHashEnabled( project.id, false ) },
			size: 12,
			color: TEXT_GREY
		}
	];

	const onOptionClicked = ( itemIndex: number ) => {
		shareLinkOptions[ itemIndex ].onClick?.();
	};

	return (
		<div className="ShareGuestLink">
			<div className="share-link-header">
				<h5>Share link</h5>
				<ShareLinkTooltip canShareGuestLink={canShareGuestLink} />
			</div>
			<div className="share-link-settings">
				<Icon
					className={guestHashIsPublic ? 'public-link-dropdown-icon' : 'private-link-dropdown-icon'}
					icon={guestHashIsPublic ? 'public' : 'people-outlined'}
					size={16}
					color={guestHashIsPublic ? PRIMARY_BASE : TEXT_GREY}
				/>
				<DropdownMenu
					text={guestHashIsPublic ? shareLinkOptions[ 1 ].name : shareLinkOptions[ 2 ].name}
					items={shareLinkOptions}
					onItemClick={onOptionClicked}
					buttonClassName={classNames( { 'disabled': !canShareGuestLink } )}
					disabled={!canShareGuestLink}
				/>
				<ShareLinkButton
					showSuccessMessage={showSuccessMessage}
					copyShareLink={copyShareLink}
					disabled={!guestHashIsPublic || !canShareGuestLink}
				/>
			</div>
		</div>
	);
};

export default ShareGuestLink;
