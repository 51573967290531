import { Component } from 'react';
import PropTypes from 'prop-types';
import FormInput from '../../new_arch/components/FormInput/FormInput';
import Button from '../../new_arch/components/Button/Button';
import { onFieldBlur, onFieldChange, getValidationErrorMessage } from '../../lib/forms/formUtils';

import { createForm } from '../../forms/passwordSettings';
import WhiteBox from '../WhiteBox/WhiteBox';

import './PasswordSettings.scss';

class PasswordSettings extends Component {
	constructor( props ) {
		super( props );

		this.state = {
			form: createForm( { oldPassword: '', password: '' } ),
			// eslint-disable-next-line react/no-unused-state
			editedFields: {}
		};

		this._onOldPasswordChange = onFieldChange.bind( this, 'oldPassword' );
		this._onNewPasswordChange = onFieldChange.bind( this, 'password' );

		this._onOldPasswordBlur = onFieldBlur.bind( this, 'oldPassword' );
		this._onNewPasswordBlur = onFieldBlur.bind( this, 'password' );

		this._getOldPasswordError = getValidationErrorMessage.bind( this, 'oldPassword' );
		this._getNewPasswordError = getValidationErrorMessage.bind( this, 'password' );

		this._onChangeClicked = this._onChangeClicked.bind( this );
	}

	get isValid() { return this.state.form.validate(); }

	clear() {
		this.setState( {
			form: createForm( { oldPassword: '', password: '' } ),
			// eslint-disable-next-line react/no-unused-state
			editedFields: {}
		} );
	}

	_onChangeClicked() {
		this.props.onChangeClick( this.state.form.data );
	}

	render() {
		const { oldPassword, password } = this.state.form.data;
		const { isValid } = this;
		const { changing } = this.props;

		return (
			<WhiteBox>
				<div className="PasswordSettings">
					<form>
						<FormInput
							name="current-password"
							autoComplete="current-password"
							label="Old Password"
							placeholder="Old Password"
							type="password"
							value={oldPassword}
							error={this._getOldPasswordError()}
							onChange={this._onOldPasswordChange}
							onBlur={this._onOldPasswordBlur}
						/>
						<FormInput
							name="new-password"
							autoComplete="new-password"
							label="New Password"
							placeholder="New Password"
							type="password"
							value={password}
							error={this._getNewPasswordError()}
							onChange={this._onNewPasswordChange}
							onBlur={this._onNewPasswordBlur}
						/>
					</form>
					<Button
						onClick={this._onChangeClicked}
						disabled={!isValid || changing}
					>
						{changing ? 'Chaging...' : 'Change Password'}
					</Button>
				</div>
			</WhiteBox>
		);
	}
}

PasswordSettings.propTypes = {
	changing: PropTypes.bool,
	onChangeClick: PropTypes.func
};

PasswordSettings.defaultProps = {
	changing: false,
	onChangeClick: () => {}
};

export default PasswordSettings;
