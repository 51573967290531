import { useState } from 'react';
import WhiteBox from '../WhiteBox/WhiteBox';
import labelsPlaceholder from '../../assets/img/label-groups-placeholder.png';
import Button from '../../new_arch/components/Button/Button';
import LabelGroupsTooltip from './LabelGroupsTooltip/LabelGroupsTooltip';
import List from '../List/List';
import WorkspaceLabelGroupRow from './WorkspaceLabelGroupRow/WorkspaceLabelGroupRow';
import type LabelGroup from '../../entities/labelGroup';
import CreateOrEditLabelGroupModal from '../../modals/CreateOrEditLabelGroupModal/CreateOrEditLabelGroupModal';

import './WorkspaceLabelGroups.scss';

type WorkspaceLabelGroupsProps = {
	workspaceID: number,
	groups: LabelGroup[],
	onDeleteGroupClick: ( group: LabelGroup ) => void
}

const WorkspaceLabelGroups = ( {
	workspaceID, groups, onDeleteGroupClick
}: WorkspaceLabelGroupsProps ) => {
	const [ showNewGroupModal, setShowNewGroupModal ] = useState( false );
	const [ groupToEdit, setGroupToEdit ] = useState<LabelGroup>();
	const showGroups = groups.length > 0;

	const onEditGroupClick = ( group: LabelGroup ) => setGroupToEdit( group );

	const renderHeader = () => (
		<div className="headLine">
			<div className="title">
				<h4>Label Groups</h4>
				<LabelGroupsTooltip />
			</div>
			<div className="button-container">
				<Button onClick={() => setShowNewGroupModal( true )}>
					New Group
				</Button>
			</div>
		</div>
	);

	const renderTopBar = () => {
		if ( !showGroups ) {
			return null;
		}

		return (
			<div className="column-names">
				<div className="name">
					<h5>Name</h5>
				</div>
				<div className="labels">
					<h5>Labels</h5>
				</div>
				<div className="actions">
					<h5>Actions</h5>
				</div>
			</div>
		);
	};

	const renderBody = () => {
		if ( showGroups ) {
			return (
				<div className="label-groups">
					{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
					{/* @ts-ignore */}
					<List itemsSize={50}>
						{groups.map( group => (
							<WorkspaceLabelGroupRow
								key={group.id}
								group={group}
								onEditClick={() => onEditGroupClick( group )}
								onDeleteClick={() => onDeleteGroupClick( group )}
							/>
						) )}
					</List>
				</div>
			);
		}

		return (
			<div className="labels-placeholder">
				<img src={labelsPlaceholder} width="230px" alt="labels-placeholder" />
				<h3>No labels yet</h3>
				<p>
					Start creating groups and save time creating labels for your projects
				</p>
			</div>
		);
	}

	return (
		<div className="WorkspaceLabelGroups">
			{showNewGroupModal
				&& (
					<CreateOrEditLabelGroupModal
						workspaceID={workspaceID}
						mode="create"
						onClose={() => setShowNewGroupModal( false )}
					/>
				)}
			{groupToEdit && (
				<CreateOrEditLabelGroupModal
					workspaceID={workspaceID}
					mode="edit"
					labelGroup={groupToEdit}
					onClose={() => setGroupToEdit( undefined )}
				/>
			)}
			<WhiteBox>
				{renderHeader()}
				{renderTopBar()}
				{renderBody()}
			</WhiteBox>
		</div>
	);
};

export default WorkspaceLabelGroups;
