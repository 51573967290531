import { useEffect, useCallback, useMemo } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../selectors/currentUser';

const getTabIndexWithKey = ( key, tabs ) => {
	const keyIndex = tabs.findIndex( tab => tab.key === key );
	return keyIndex !== -1 ? keyIndex : 0;
};

const useTabsWithRoutes = ( tabs, selectedTabRouteKey, routeBaseURL ) => {
	const history = useHistory();
	const match = useRouteMatch();
	const currentUser = useSelector( state => getCurrentUser( state ) );
	const filteredTabs = useMemo(
		() => tabs.filter( tab => !( 'availableForUser' in tab ) || tab.availableForUser( currentUser ) ),
		[ currentUser, tabs ]
	);

	const selectedTabIndex = getTabIndexWithKey( match.params[ selectedTabRouteKey ], filteredTabs );

	const navigateToTab = tab => (
		history.replace( tab.key ? `${routeBaseURL}/${tab.key}` : routeBaseURL )
	);

	useEffect(
		() => {
			if ( selectedTabIndex < filteredTabs.length ) { return; }
			navigateToTab( filteredTabs[ 0 ] );
		},
		[ selectedTabIndex, filteredTabs.length ]
	);

	const onTabChanged = useCallback(
		index => navigateToTab( filteredTabs[ index ] )
	);

	return [ filteredTabs, selectedTabIndex, onTabChanged ];
};

export default useTabsWithRoutes;
