export default class MinLengthValidator {
	constructor( length ) {
		this.length = length;
	}

	validate( value, label, errors ) {
		if ( value && value.length >= this.length ) { return true; }
		errors.push( `${label} should be at least ${this.length} characters long.` );
		return false;
	}
}
