import { Map } from 'immutable';
import { handleSuccess, mapForPayload } from '../lib/reduxUtils';
import {
	FETCH_PLAN_ADD_ONS, LOGOUT
} from '../actions/types';

export const addOns = ( state = Map(), action ) => {
	switch ( action.type ) {
	case FETCH_PLAN_ADD_ONS:
		return handleSuccess(
			state,
			action,
			() => mapForPayload( action.payload )
		);
	case LOGOUT:
		return Map();
	default:
		return state;
	}
};
