import { Form, email as emailValidator, minLength, textInput, FieldValidator, maxLength, required, select }
	from '@amalgamaco/mobx-form';
import { FormSubmitCallback } from '@amalgamaco/mobx-form/dist/Form/types';
import { defaultRole } from '../../../../types/role';

type PasswordFieldParams = { label?: string, placeholder?: string, validators?: FieldValidator<string>[] }

export default class FormFactory {
	static forCreateAccount( onSubmit : FormSubmitCallback ) {
		return new Form( {
			fields: {
				...this.createAccountBaseFields,
				email: this.emailInput( { placeholder: 'studio@avedon.com' } )
			},
			onSubmit
		} )
	}

	static forCreateAccountWithInvitation( onSubmit : FormSubmitCallback ) {
		return new Form( {
			fields: this.createAccountBaseFields,
			onSubmit
		} )
	}

	static forSignIn( onSubmit : FormSubmitCallback ) {
		return new Form( {
			fields: {
				email: this.emailInput(),
				password: this.passwordField()
			},
			onSubmit
		} )
	}

	static forEmailVerification( onSubmit : FormSubmitCallback ) {
		return new Form( {
			fields: {
				accessCode: textInput( {
					label: 'Code',
					placeholder: 'e.g. 654343.',
					validators: [ minLength( 6 ) ]
				} )
			},
			onSubmit
		} )
	}

	static forForgotPassword( onSubmit : FormSubmitCallback ) {
		return new Form( {
			fields: { email: this.emailInput() },
			onSubmit
		} )
	}

	static forResetPassword( onSubmit : FormSubmitCallback ) {
		return new Form( {
			fields: {
				password: this.passwordField(),
				confirmPassword: this.passwordField( {
					label: 'Confirm password',
					validators: [ this.sameValueValidator( 'password' ) ]
				} )
			},
			onSubmit
		} )
	}

	static forCreateNacGuest( onSubmit : FormSubmitCallback ) {
		return new Form( {
			fields: {
				name: this.fullNameField(),
				email: this.emailInput( { placeholder: 'studio@avedon.com' } ),
				role: select( {
					label: 'Role',
					defaultValue: defaultRole,
					validators: [ required( { message: 'Please select a role' } ) ]
				} )
			},
			onSubmit
		} )
	}

	private static emailInput( { placeholder } : { placeholder? : string } = {} ) {
		return textInput( {
			label: 'Email',
			placeholder,
			validators: [ required( { message: 'Please provide an email' } ), emailValidator() ]
		} )
	}

	private static get createAccountBaseFields() {
		return {
			name: this.fullNameField(),
			password: this.passwordField( { placeholder: 'Minimum 8 characters' } )
		}
	}

	private static minWordsValidator( count: number ) : FieldValidator<string> {
		return ( value, _, label ) => {
			const words = ( value ?? '' ).trim().split( ' ' ).filter( w => w.length > 0 );
			return {
				isValid: words.length >= count,
				error: `${label} should be at least ${count} ${count > 1 ? 'words' : 'word'}.`
			}
		}
	}

	private static sameValueValidator( fieldName: string ) : FieldValidator<string> {
		return ( value, form ) => {
			const otherValue = form?.select( fieldName ).value;
			return {
				isValid: value === otherValue,
				error: 'Passwords do not match'
			}
		}
	}

	private static passwordField( { label, placeholder, validators } : PasswordFieldParams = {} ) {
		return textInput( {
			label: label ?? 'Password',
			placeholder,
			validators: [
				required( { message: 'Please provide a password' } ),
				minLength( 8 ),
				maxLength( 40 ),
				...validators ?? []
			]
		} );
	}

	private static fullNameField() {
		return textInput( {
			label: 'Full name',
			placeholder: 'Richard Avedon',
			validators: [ this.minWordsValidator( 2 ) ]
		} )
	}
}
