import { useEffect } from 'react';
import PropTypes from 'prop-types';

import CardholderNameInput from '../CardholderNameInput/CardholderNameInput';
import CardInformationInput from '../CardInformationInput/CardInformationInput';

import './PaymentMethodFillingForm.scss';

import useAllValuesTruthState from '../../hooks/useAllValuesTruthState';

const PAYMENT_METHOD_NAME_ON_CARD_FIELD = 'PAYMENT_METHOD_NAME_ON_CARD_FIELD';
const PAYMENT_METHOD_CARD_INFORMATION_FIELD = 'PAYMENT_METHOD_CARD_INFORMATION_FIELD';

const DEFAULT_PAYMENT_METHOD_FIELDS_COMPLETION_STATE = {
	[ PAYMENT_METHOD_NAME_ON_CARD_FIELD ]: false,
	[ PAYMENT_METHOD_CARD_INFORMATION_FIELD ]: false
};

const PaymentMethodFillingForm = ( {
	cardholderName,
	onCardholderNameChange,
	onFieldsCompletionStateChange,
	disabled
} ) => {
	const [ allFieldsCompleted, setFieldCompletionState ] = useAllValuesTruthState(
		DEFAULT_PAYMENT_METHOD_FIELDS_COMPLETION_STATE
	);

	const makeOnFieldCompletionStateChangedHandler = field => ( valid ) => {
		setFieldCompletionState( field, valid );
	};

	useEffect(
		() => { onFieldsCompletionStateChange( allFieldsCompleted ); },
		[ allFieldsCompleted ]
	);

	return (
		<div className="PaymentMethodFillingForm">
			<CardholderNameInput
				value={cardholderName}
				onChange={onCardholderNameChange}
				onValidationStatusChange={
					makeOnFieldCompletionStateChangedHandler( PAYMENT_METHOD_NAME_ON_CARD_FIELD )
				}
				disabled={disabled}
				required
			/>
			<CardInformationInput
				onFieldsCompletionStateChange={
					makeOnFieldCompletionStateChangedHandler( PAYMENT_METHOD_CARD_INFORMATION_FIELD )
				}
				disabled={disabled}
				required
			/>
		</div>
	);
};

PaymentMethodFillingForm.propTypes = {
	cardholderName: PropTypes.string,
	onCardholderNameChange: PropTypes.func,
	onFieldsCompletionStateChange: PropTypes.func,
	disabled: PropTypes.bool
};

PaymentMethodFillingForm.defaultProps = {
	cardholderName: '',
	onCardholderNameChange: () => {},
	onFieldsCompletionStateChange: () => {},
	disabled: false
};

export default PaymentMethodFillingForm;
