export default class ValidatorComposer {
	constructor( validators ) {
		this.validators = validators;
	}

	validate( values ) {
		const result = this.validators.reduce( ( validation, validator ) => {
			const { isValid, error } = validator.validate( values );
			return ( {
				...validation,
				isValid: validation.isValid && isValid,
				error: validation.error || error
			} );
		}, { isValid: true } );

		if ( !result.isValid ) { throw result.error; }

		return true;
	}
}
