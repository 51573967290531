import PricingPlanToDisplay from './PricingPlanToDisplay';

export default class ProPlanToDisplay extends PricingPlanToDisplay {
	constructor( { pricingPlan } ) {
		super( { pricingPlan } );
	}

	get title() {
		return 'Pro';
	}

	get text() {
		return 'For individual use with the option of adding members to your team.';
	}

	get minimumMembersInfo() {
		return 'Starting at 1 member';
	}

	statusToShow( { workspace } ) {
		if ( workspace.isNullWorkspace ) {
			return this._showNoWorkspaceStatus();
		}
		if ( workspace.isSubscriptionActive ) {
			return this._showActiveSubscriptionStatus( workspace );
		}
		return this._showInactiveSubscriptionStatus( { workspace } );
	}

	_showNoWorkspaceStatus() {
		return { status: 'noWorkspace', text: 'Start Free Trial' };
	}

	_showActiveSubscriptionStatus( workspace ) {
		if ( !workspace.hasAPaidPlan ) return { status: 'upgrade', text: 'Choose Plan' };
		if (
			this._levelForPlan[ this.name ] === this._levelForPlan[ workspace.subscriptionPlanName ]
		) {
			return { status: 'stay', text: 'You are subscribed to Pro!' };
		}
		if (
			this._levelForPlan[ this.name ] < this._levelForPlan[ workspace.subscriptionPlanName ]
		) {
			return this._showDowngradeStatus( { workspace } );
		}
		return { status: 'upgrade', text: 'Upgrade to Pro' };
	}

	_showDowngradeStatus( { workspace } ) {
		return workspace?.hasADowngradePending
			? { status: 'downgradePending', text: 'You marked to downgrade to Pro' } : { status: 'downgrade', text: 'Downgrade to Pro' };
	}
}
