import SubscriptionType from './subscriptionType';

export default class FreeSubscriptionType extends SubscriptionType {
	get allowsMemberInvitations() {
		return false;
	}

	get isFree() {
		return true;
	}

	addAddOnsWith( addOnsSystem ) {
		addOnsSystem.alertOfPaidFeature();
	}
}
