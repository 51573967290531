import { Map } from 'immutable';
import { handleSuccess, mapForPayload } from '../lib/reduxUtils';
import { DELETE_PROJECTS, FETCH_WORKSPACE_PROJECTS, LEAVE_PROJECTS } from '../actions/types';

export const workspaceProjects = ( state = new Map(), action ) => {
	switch ( action.type ) {
	case FETCH_WORKSPACE_PROJECTS:
		return handleSuccess(
			state,
			action,
			prevState => prevState
				.merge( mapForPayload( action.payload ) )
		);
	case LEAVE_PROJECTS:
	case DELETE_PROJECTS:
		return handleSuccess( state, action, prevState => action.payload.reduce(
			( result, deletedID ) => result.delete( deletedID ),
			prevState
		) );
	default:
		return state;
	}
};
