import StorageLimitError from '../customErrors/StorageLimitError';

export default class StorageLimitValidator {
	constructor( storageLimit ) {
		this.storageLimit = storageLimit;
	}

	validate( files ) {
		const isValid = files.accumulate( file => file.size ) <= this.storageLimit;
		return {
			isValid,
			error: !isValid && new StorageLimitError()
		};
	}
}
