import { withRouter } from 'react-router';
import { connectComponent } from '../../lib/connectComponent';
import {
	getAssetUploadsProjectIDs, getAssetUploadsCount,
	getAssetActiveUploadsCount, getETAForAllAssetUploads,
	getAssetSucceededUploadsCount, getAssetNonAbortedOrFailedUploadsCount
} from '../../selectors/assetUploads';
import AssetUploaderBox from '../../components/assets/AssetUploaderBox/AssetUploaderBox';
import { getProject } from '../../selectors/projects';
import { getFolder } from '../../selectors/folders';

export default withRouter( connectComponent( ( state, props ) => {
	const succeededUploadsCount = getAssetSucceededUploadsCount( state );
	const nonAbortedOrFailedUploadsCount = getAssetNonAbortedOrFailedUploadsCount( state );

	return ( {
		uploadProjectIDs: getAssetUploadsProjectIDs( state ),
		assetUploadsCount: getAssetUploadsCount( state ),
		assetActiveUploadsCount: getAssetActiveUploadsCount( state ),
		totalTimeRemaining: getETAForAllAssetUploads( state ),
		project: getProject( state, props ),
		folder: getFolder( state, props ),
		succeededUploadsCount,
		nonAbortedOrFailedUploadsCount
	} );
} )( AssetUploaderBox ) );
