import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ContactsGroupsModalPresenter from './ContactsGroupsModalPresenter';
import WorkspaceSystem from '../../systems/WorkspaceSystem';
import useAutoupdatePresenterProperty from '../../hooks/useAutoupdatePresenterProperty';
import { getWorkspaceContactGroups } from '../../selectors/contactGroups';
import Workspace from '../../entities/workspace';

const useContactsGroupsModalPresenter = ( workspace: Workspace ) => {
	const dispatch = useDispatch();

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const contactGroups = useSelector( state => getWorkspaceContactGroups( state, {
		workspaceID: workspace.id
	} ) );

	const workspaceSystem : WorkspaceSystem = new WorkspaceSystem( dispatch );

	const createPresenter = () => new ContactsGroupsModalPresenter( {
		workspace,
		contactGroups,
		workspaceSystem
	} );

	const [ presenter ] = useState( createPresenter );

	useAutoupdatePresenterProperty( { presenter, name: 'workspace', value: workspace } );
	useAutoupdatePresenterProperty( { presenter, name: 'contactGroups', value: contactGroups } );

	return presenter;
};

export default useContactsGroupsModalPresenter;
