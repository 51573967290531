import { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import FloatingMenu from '../FloatingMenu/FloatingMenu';

import './ProfileWithDropdownActions.scss';
import { ProfileButtonContainer } from '../../containers/ProfileButtonContainer/ProfileButtonContainer';
import UserPropType from '../../types/user';

export class ProfileWithDropdownActions extends Component {
	constructor( props ) {
		super( props );

		this.state = {
			isOpen: false
		};

		this.viewRef = createRef();

		this._onToggleButtonClicked = this._onToggleButtonClicked.bind( this );
		this._onClickOutside = this._onClickOutside.bind( this );
		this._onItemClicked = this._onItemClicked.bind( this );
	}

	componentDidMount() {
		document.addEventListener( 'mousedown', this._onClickOutside );
	}

	componentWillUnmount() {
		document.removeEventListener( 'mousedown', this._onClickOutside );
	}

	toggle() {
		this.setState(
			prevState => ( { isOpen: !prevState.isOpen } )
		);
	}

	close() {
		this.setState( { isOpen: false } );
	}

	// Events handlers
	_onClickOutside( event ) {
		if (
			this.viewRef.current && !this.viewRef.current.contains( event.target )
		) {
			this.close();
		}
	}

	_onToggleButtonClicked( event ) {
		event.stopPropagation();
		this.toggle();
	}

	_onItemClicked( itemIndex, event ) {
		const { props: { actions } } = this;
		const action = actions[ itemIndex ];
		event.stopPropagation();
		this.close();
		action.onClick();
	}

	render() {
		const {
			state: { isOpen },
			props: {
				actions, currentUser, isGuest
			}
		} = this;

		return (
			<button
				className={classNames( 'ProfileWithDropdownActions', { isOpen }, { isGuest } )}
				ref={this.viewRef}
				onClick={this._onToggleButtonClicked}
			>
				<ProfileButtonContainer
					currentUser={currentUser}
				/>
				{isOpen && (
					<FloatingMenu
						items={actions}
						onItemClick={this._onItemClicked}
					/>
				)}
			</button>
		);
	}
}

ProfileWithDropdownActions.propTypes = {
	actions: PropTypes.arrayOf(	PropTypes.shape( {
		name: PropTypes.string,
		description: PropTypes.string,
		icon: PropTypes.string,
		onClick: PropTypes.func,
		color: PropTypes.string,
		image: PropTypes.string
	} ) ),
	currentUser: UserPropType.isRequired,
	isGuest: PropTypes.bool
};

ProfileWithDropdownActions.defaultProps = {
	actions: [],
	isGuest: false
};

export default ProfileWithDropdownActions;
