import {
	SHOW_POPUP,
	HIDE_POPUP
} from './types';
import { ERROR, SUCCESS, WARNING_BASE } from '../styles/colors';

const ERROR_ICON = { name: 'error', color: ERROR };
const SUCCESS_ICON = { name: 'check_circle', color: SUCCESS };
const WARNING_ICON = { name: 'error', color: WARNING_BASE };

export const showPopup = params => ( {
	type: SHOW_POPUP,
	payload: params
} );

export const hidePopup = () => ( {
	type: HIDE_POPUP
} );

export const showConfirmationDialog = (
	title, description, checkboxDescription,
	acceptText = 'Confirm', cancelText = 'Cancel', acceptButtonColor = 'primary', descriptionAlignment = 'left',
	radioOptions = [], onRadioOptionSelect = undefined
) => dispatch => new Promise(
	( resolve ) => {
		const cancelAction = () => {
			dispatch( hidePopup() );
			return resolve( false );
		};

		return dispatch(
			showPopup(
				{
					title,
					description,
					descriptionAlignment,
					checkboxDescription,
					radioOptions,
					onRadioOptionSelect,
					actions: [
						{
							text: cancelText,
							color: 'secondary',
							onClick: cancelAction
						},
						{
							text: acceptText,
							color: acceptButtonColor,
							enabledByCheckbox: !!checkboxDescription,
							onClick: () => resolve( true )
						}
					],
					onCrossClick: cancelAction
				}
			)
		);
	}
);

export const hideConfirmationDialog = hidePopup;

export const showDialog = (
	title, description, acceptText = 'OK', bigTitle = false, icon = {}, descriptionAlignment
) => dispatch => new Promise(
	( resolve ) => {
		const closeAndResolve = ( wasAccepted ) => {
			dispatch( hidePopup() );
			return resolve( wasAccepted );
		};
		return dispatch(
			showPopup(
				{
					title,
					description,
					descriptionAlignment,
					actions: [
						{
							text: acceptText,
							color: 'primary',
							onClick: () => closeAndResolve( true )
						}
					],
					onCrossClick: () => closeAndResolve( false ),
					bigTitle,
					headerIcon: icon.name,
					headerIconColor: icon.color
				}
			)
		);
	}
);

export const showErrorDialog = (
	title, description, acceptText = 'OK', buttonType = 'primary'
) => dispatch => new Promise(
	( resolve ) => {
		const closeWith = ( value ) => {
			dispatch( hidePopup() );
			return resolve( value );
		};
		return dispatch(
			showPopup(
				{
					title,
					description,
					actions: [
						{
							text: acceptText,
							color: 'primary',
							onClick: () => closeWith( true ),
							buttonType
						}
					],
					onCrossClick: () => closeWith( false ),
					headerIcon: 'error',
					headerIconColor: ERROR
				}
			)
		);
	}
);

export const showDefaultConfirmationDialog = (
	title, description, acceptText, cancelText, descriptionAlignment, acceptButtonColor
) => showConfirmationDialog(
	title, description, null, acceptText, cancelText, acceptButtonColor, descriptionAlignment
);

export const showDangerConfirmationDialog = (
	title, description, checkboxDescription, acceptText, cancelText, descriptionAlignment
) => showConfirmationDialog(
	title, description, checkboxDescription, acceptText, cancelText, 'danger', descriptionAlignment
);

export const showDialogWithIcon = (
	title, description, acceptText = 'OK', isError = true, descriptionAlignment
) => showDialog(
	title,
	description,
	acceptText,
	false,
	isError ? ERROR_ICON : SUCCESS_ICON,
	descriptionAlignment
);

export const showDialogWithWarningIcon = ( {
	title, description, acceptText = 'OK', descriptionAlignment
} ) => showDialog(
	title,
	description,
	acceptText,
	false,
	WARNING_ICON,
	descriptionAlignment
);

export const showRadioConfirmationDialog = (
	title, description, radioOptions, onRadioOptionSelect, acceptText, cancelText
) => showConfirmationDialog(
	title, description, undefined, acceptText, cancelText, 'primary', undefined, radioOptions, onRadioOptionSelect
);
