import PropTypes from 'prop-types';

import MyProfileSideBarItem from '../MyProfileSideBarItem/MyProfileSideBarItem';
import CreateWorkspace from '../../assets/img/icons/create-workspace.svg';

const CreateWorkspaceButton = ( { onClick } ) => (
	<MyProfileSideBarItem
		title="Create my Workspace"
		image={CreateWorkspace}
		onClick={onClick}
	/>
);

CreateWorkspaceButton.propTypes = {
	onClick: PropTypes.func
};

CreateWorkspaceButton.defaultProps = {
	onClick: () => {}
};

export default CreateWorkspaceButton;
