import DuplicateFolderNamesError from '../customErrors/DuplicateFolderNamesError';

export default class DuplicateFolderNamesValidator {
	constructor( { existingFolderNames } ) {
		this._existingFolderNames = existingFolderNames;
	}

	validate( names ) {
		const isInvalid = names.some( name => this._existingFolderNames.includes( name ) );

		if ( isInvalid ) throw new DuplicateFolderNamesError();
	}
}
