import { makeAutoObservable } from 'mobx';
import PricingPlanToDisplayFactory from '../../factories/pricingPlanToDisplayFactory';
import PlanCardPresenter from './PlanCardPresenter';

export default class PricingSubscriptionPresenter {
	constructor( { workspace } ) {
		this.workspace = workspace;
		this._isMonthly = workspace.hasAPaidPlan && workspace.hasAMonthlyBillingPeriod;

		makeAutoObservable( this );
	}

	get isMonthly() {
		return this._isMonthly;
	}

	get isAPaidPlan() {
		return this.workspace.hasAPaidPlan;
	}

	get billingPeriodName() {
		return this.isMonthly ? 'Monthly' : 'Annually';
	}

	get shouldShowBillingPeriodSwitch() {
		return !( this.isAPaidPlan && this.isSubscriptionActive )
			|| this.workspace.hasAMonthlyBillingPeriod;
	}

	get isSubscriptionActive() {
		return this.workspace.isSubscriptionActive;
	}

	get totalMembersCount() { return this.workspace.membersCount; }

	get projectsCount() { return this.workspace.projectsCount; }

	get hasNullWorkspace() {
		return this.workspace.isNullWorkspace;
	}

	minimumAnualDiscountPercentage( pricingPlans ) {
		return Math.min(
			...pricingPlans.filter( pricingPlan => !pricingPlan.isFree )
				.map( pricingPlan => pricingPlan.annualDiscount )
		);
	}

	onSwitched = () => {
		this._isMonthly = !this._isMonthly;
	}

	planCardPresenterForPricingPlan( pricingPlan ) {
		return new PlanCardPresenter( {
			pricingSubscriptionPresenter: this,
			pricingPlanToDisplay: PricingPlanToDisplayFactory.forPricingPlan( pricingPlan )
		} );
	}
}
