import NaturalCompare from 'natural-compare-lite';

const lowercaseOptionalString = aString => (
	aString
		? aString.toLowerCase()
		: aString
);

export const naturalCompareAsc = ( aValue, otherValue ) => ( NaturalCompare(
	lowercaseOptionalString( aValue ),
	lowercaseOptionalString( otherValue )
) );

export const naturalOptionalStringCompareAsc = ( aValue, otherValue ) => {
	if ( aValue === '' ) { return 1; }
	if ( otherValue === '' ) { return -1; }
	return NaturalCompare(
		lowercaseOptionalString( aValue ),
		lowercaseOptionalString( otherValue )
	);
};

export const naturalCompareDesc = (
	aValue, otherValue
) => naturalCompareAsc( otherValue, aValue );

export const compareAsc = ( aValue, otherValue ) => {
	if ( aValue < otherValue ) { return -1; }
	if ( aValue > otherValue ) { return 1; }
	return 0;
};

export const compareDesc = (
	aValue, otherValue
) => compareAsc( otherValue, aValue );

export const compareCaseInsensitiveStringsAsc = ( aString, otherString ) => compareAsc(
	lowercaseOptionalString( aString ),
	lowercaseOptionalString( otherString )
);

export const compareCaseInsensitiveStringsDesc = ( aString, otherString ) => compareDesc(
	lowercaseOptionalString( aString ),
	lowercaseOptionalString( otherString )
);

export const compareEntitiesByTwoCriterias = (
	aEntity,
	otherEntity,
	firstProperty,
	secondProperty,
	firstComparator = compareAsc,
	secondComparator = compareAsc
) => {
	const result = firstComparator( aEntity[ firstProperty ], otherEntity[ firstProperty ] );
	return result !== 0
		? result
		: secondComparator( aEntity[ secondProperty ], otherEntity[ secondProperty ] );
};

export const shallowEqual = ( object1, object2 ) => {
	const keys1 = Object.keys( object1 );
	const keys2 = Object.keys( object2 );

	if ( keys1.length !== keys2.length ) {
		return false;
	}

	// eslint-disable-next-line no-restricted-syntax
	for ( let key of keys1 ) {
		if ( object1[ key ] !== object2[ key ] ) {
			return false;
		}
	}

	return true;
};
