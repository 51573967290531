import { observer } from 'mobx-react-lite';

import useToggle from '../../hooks/useToggle';
import AssetDownloadBoxFooter from './AssetDownloadBoxFooter/AssetDownloadBoxFooter';
import AssetDownloadBoxHeader from './AssetDownloadBoxHeader/AssetDownloadBoxHeader';
import AssetDownloadBoxItem from './AssetDownloadBoxItem/AssetDownloadBoxItem';

import type AssetDownloadService from '../../services/AssetDownloadService/AssetDownloadService';

import './AssetDownloadBox.scss';

type AssetDownloadBoxProps = {
	assetDownloadService: AssetDownloadService,
	onBoxClosed?: () => void
};

const AssetDownloadBox = ( {
	assetDownloadService, onBoxClosed = () => {}
}: AssetDownloadBoxProps ) => {
	const [ isMinimized, minimize ] = useToggle();

	const { downloads, unfinishedDownloadsCount } = assetDownloadService;

	return (
		<div className="AssetDownloadBox">
			<AssetDownloadBoxHeader
				assetDownloadService={assetDownloadService}
				isMinimized={isMinimized}
				onMinimize={minimize}
				onClose={onBoxClosed}
			/>
			{!isMinimized && (
				<div className="download-list">
					{downloads.map( job => (
						<AssetDownloadBoxItem
							key={job.id}
							assetDownloadJob={job}
							testID={`asset-download-${job.id}`}
						/>
					) )}
				</div>
			)}
			{!isMinimized && unfinishedDownloadsCount > 1 && (
				<AssetDownloadBoxFooter
					onCancelAllClicked={() => assetDownloadService.cancelAllDownloads()}
				/>
			)}
		</div>
	);
};

export default observer( AssetDownloadBox );
