import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../../../new_arch/components/Icon/Icon';

import './IconButton.scss';

const IconButton = ( {
	keyID, icon, onClick, size, buttonClassName, iconClassName,
	color, disabled, onMouseEnter, onMouseLeave, type, testID
} ) => (
	<button
		data-testid={testID}
		type={type}
		key={keyID || icon}
		onClick={onClick}
		className={classNames( `IconButton ${buttonClassName}`, { disabled } )}
		disabled={disabled}
		onMouseEnter={onMouseEnter}
		onMouseLeave={onMouseLeave}
	>
		<Icon
			key={keyID || icon}
			icon={icon}
			color={color}
			size={size}
			className={iconClassName}
		/>
	</button>
);

IconButton.propTypes = {
	keyID: PropTypes.string,
	icon: PropTypes.string,
	color: PropTypes.string,
	onClick: PropTypes.func,
	size: PropTypes.oneOfType( [ PropTypes.number, PropTypes.string ] ),
	buttonClassName: PropTypes.string,
	iconClassName: PropTypes.string,
	disabled: PropTypes.bool,
	onMouseEnter: PropTypes.func,
	onMouseLeave: PropTypes.func,
	type: PropTypes.string,
	testID: PropTypes.string
};

IconButton.defaultProps = {
	keyID: undefined,
	icon: '',
	color: undefined,
	onClick: () => {},
	size: 20,
	buttonClassName: '',
	iconClassName: '',
	disabled: false,
	onMouseEnter: () => {},
	onMouseLeave: () => { },
	type: 'button',
	testID: ''
};

export default IconButton;
