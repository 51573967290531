import Markup from './markup';

export default class EditorMarkup extends Markup {
	constructor( id, number, shapes, wasMarkedAsCompleted ) {
		super( id, number, shapes );

		this.wasMarkedAsCompleted = wasMarkedAsCompleted;
	}

	static fromMarkup( markup, wasMarkedAsCompleted ) {
		return new EditorMarkup(
			markup.id,
			markup.number,
			markup.shapes,
			wasMarkedAsCompleted
		);
	}
}
