import { Component } from 'react';
import PropTypes from 'prop-types';

import NotificationsHeaderContainer
	from '../../../containers/NotificationsHeaderContainer/NotificationsHeaderContainer';
import NotificationsListContainer
	from '../../../containers/NotificationsListContainer/NotificationsListContainer';
import NotificationsProjectsSidebarContainer
	from '../../../containers/NotificationsProjectsSidebarContainer/NotificationsProjectsSidebarContainer';
import NotificationsPanelSettings from '../NotificationsPanelSettings/NotificationsPanelSettings';

import { sharedTracker } from '../../../services/tracker/Tracker';
import { trackNotificationOpened } from '../../../services/tracker/trackers/notifications';

import './NotificationsSidePanel.scss';

class NotificationsSidePanel extends Component {
	constructor( props ) {
		super( props );

		this.state = {
			closing: false,
			initialPosition: true,
			showSettings: false
		};

		this._onTransitionEnd = this._onTransitionEnd.bind( this );
		this._onClosePanel = this._onClosePanel.bind( this );
		this._onSettingsClosed = this._onSettingsClosed.bind( this );
		this._onEscPressed = this._onEscPressed.bind( this );
		this._onNotificationClicked = this._onNotificationClicked.bind( this );
		this._onSettingsOpened = this._onSettingsOpened.bind( this );
		this._onProjectSelected = this._onProjectSelected.bind( this );
		this._onNotificationProjectClicked = this._onNotificationProjectClicked.bind( this );
		this._onNotificationFolderClicked = this._onNotificationFolderClicked.bind( this );
	}

	componentDidMount() {
		setTimeout( () => { this.setState( { initialPosition: false } ); }, 10 );
		this.props.setNotificationsPanelAsOpen( true );
		document.body.parentNode.style.overflowY = 'hidden';
		document.addEventListener( 'keydown', this._onEscPressed );
		sharedTracker.trackPanel( 'notifications' );
	}

	componentWillUnmount() {
		this.props.setNotificationsPanelAsOpen( false );
		document.body.parentNode.style.overflowY = 'auto';
		document.removeEventListener( 'keydown', this._onEscPressed );
	}

	get backdropStyle() {
		const { initialPosition, closing } = this.state;
		if ( initialPosition ) {
			return 'backdrop';
		}
		return closing ? 'backdrop hide' : 'backdrop show';
	}

	get panelStyle() {
		const { initialPosition, closing } = this.state;
		if ( initialPosition ) {
			return 'panel';
		}
		return closing ? 'panel hide' : 'panel show';
	}

	_onEscPressed( event ) {
		if ( event.keyCode === 27 ) {
			this._onClosePanel();
		}
	}

	_onClosePanel() {
		this.setState( { closing: true } );
	}

	_onTransitionEnd() {
		const { props: { onClose }, state: { closing } } = this;
		if ( closing && onClose ) { onClose(); }
	}

	_onNotificationClicked( notification ) {
		trackNotificationOpened( notification );
		this._onClosePanel();
		this.props.onNotificationClicked( notification );
	}

	_onNotificationProjectClicked( notification ) {
		this.props.onNotificationProjectClicked( notification );
	}

	_onNotificationFolderClicked( notification ) {
		this.props.onNotificationFolderClicked( notification );
	}

	_onSettingsOpened() {
		this.setState( { showSettings: true } );
	}

	_onSettingsClosed() {
		this.setState( { showSettings: false } );
	}

	_onProjectSelected( projectID ) {
		const { onProjectSelect } = this.props;
		onProjectSelect( projectID );
	}

	render() {
		const {
			props: { selectedProjectID },
			state: { showSettings }
		} = this;

		return (
			<div className="NotificationSidePanel">
				<div className={this.backdropStyle} onClick={this._onClosePanel} />
				<div className={this.panelStyle} onTransitionEnd={this._onTransitionEnd}>
					{ showSettings ? (
						<NotificationsPanelSettings
							onBackClick={this._onSettingsClosed}
							onClose={this._onClosePanel}
						/>
					) : (
						<>
							<NotificationsHeaderContainer
								projectID={selectedProjectID}
								onClose={this._onClosePanel}
								onSettingsOpened={this._onSettingsOpened}
							/>
							<div className="notifications">
								<NotificationsListContainer
									projectID={selectedProjectID}
									onNotificationClicked={this._onNotificationClicked}
									onClose={this._onClosePanel}
									onNotificationProjectClicked={this._onNotificationProjectClicked}
									onNotificationFolderClicked={this._onNotificationFolderClicked}
								/>
								<NotificationsProjectsSidebarContainer
									selectedProjectID={selectedProjectID}
									onProjectClick={this._onProjectSelected}
								/>
							</div>
						</>
					) }
				</div>
			</div>
		);
	}
}

NotificationsSidePanel.propTypes = {
	selectedProjectID: PropTypes.number,
	onNotificationClicked: PropTypes.func,
	onProjectSelect: PropTypes.func,
	onClose: PropTypes.func,
	setNotificationsPanelAsOpen: PropTypes.func,
	onNotificationProjectClicked: PropTypes.func,
	onNotificationFolderClicked: PropTypes.func
};

NotificationsSidePanel.defaultProps = {
	selectedProjectID: null,
	onNotificationClicked: undefined,
	onProjectSelect: undefined,
	onClose: undefined,
	setNotificationsPanelAsOpen: () => {},
	onNotificationProjectClicked: undefined,
	onNotificationFolderClicked: undefined
};

export default NotificationsSidePanel;
