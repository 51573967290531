import PropTypes from 'prop-types';

import { connectComponent } from '../../lib/connectComponent';
import { getActiveProject } from '../../selectors/projects';
import { getSelectedNotificationsFilterForProject } from '../../selectors/notifications';
import Project from '../../entities/project';
import FilterOption from '../../entities/filterOption';

import NotificationsHeader
	from '../../components/notifications/NotificationsHeader/NotificationsHeader';
import { getCurrentUserOwnWorkspace } from '../../selectors/workspaces';
import Workspace from '../../entities/workspace';

export const NotificationsHeaderContainer = ( {
	project, onClose, selectedFilterOption, setNotificationsFilter,
	currentUserOwnWorkspace, onSettingsOpened
} ) => {
	const projectBelongsToSuspendedWorkspace = !project?.hasActiveWorkspace;
	const noProjectAndSuspendedWorkspace = !project && currentUserOwnWorkspace && !currentUserOwnWorkspace.isActive;
	const disabledFiltering = projectBelongsToSuspendedWorkspace || noProjectAndSuspendedWorkspace;

	return (
		<NotificationsHeader
			projectID={project && project.id}
			onClose={onClose}
			selectedFilterOption={selectedFilterOption}
			onSetNotificationFilter={setNotificationsFilter}
			disabledFiltering={disabledFiltering}
			onSettingsOpened={onSettingsOpened}
		/>
	);
};

NotificationsHeaderContainer.propTypes = {
	setNotificationsFilter: PropTypes.func,
	selectedFilterOption: PropTypes.instanceOf( FilterOption ).isRequired,
	project: PropTypes.instanceOf( Project ).isRequired,
	onClose: PropTypes.func,
	currentUserOwnWorkspace: PropTypes.instanceOf( Workspace ),
	onSettingsOpened: PropTypes.func.isRequired
};

NotificationsHeaderContainer.defaultProps = {
	setNotificationsFilter: () => {},
	onClose: undefined,
	currentUserOwnWorkspace: undefined

};

export default connectComponent( ( state, props ) => ( {
	selectedFilterOption: getSelectedNotificationsFilterForProject( state, props ),
	project: getActiveProject( state, props ),
	currentUserOwnWorkspace: getCurrentUserOwnWorkspace( state )
} ) )( NotificationsHeaderContainer );
