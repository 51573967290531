import { sharedTracker } from '../Tracker';
import events from '../events';

export const trackTaskAssigment = ( assigneeID, currentUserID, project ) => sharedTracker
	.trackTaskAction( {
		action: assigneeID === currentUserID
			? events.task.TASK_ASSIGNED_TO_MYSELF
			: events.task.TASK_ASSIGNED_TO_USER,
		label: project && project.name
	} );

export const trackTaskCompleted = () => sharedTracker
	.trackTaskAction( {
		action: events.task.TASK_COMPLETED
	} );
