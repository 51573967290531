import InformationTooltipIcon from '../../UI/InformationTooltipIcon/InformationTooltipIcon';
import { PRIMARY_BASE, WHITE } from '../../../styles/colors';

const GuestViewPreviewEmailTooltip = () => {
	const tooltipData = (
		<div style={{ textAlign: 'left', fontSize: '10px', width: '135px', marginBottom: '2px' }}>
			<p className="tooltip-title" style={{ color: WHITE, fontWeight: 'bold', marginBottom: '5px' }}>
				Why my email?
			</p>
			<p className="tooltip-text">
				We use it to help team owners know who they are collaborating with and keep track of project activities
			</p>
		</div>
	);

	return (
		<InformationTooltipIcon
			color={PRIMARY_BASE}
			position="right"
			html={tooltipData}
		/>
	);
};

export default GuestViewPreviewEmailTooltip;
