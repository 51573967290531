import PropTypes from 'prop-types';
import classNames from 'classnames';
import SwitchOption from '../SwitchOption/SwitchOption';

import './Switch.scss';

const Switch = ( {
	className, leftOption, rightOption, onSwitch
} ) => (
	<div className={classNames( 'Switch', className )}>
		<div className={classNames( 'animated-option-container', { 'on-the-left': leftOption.isSelected } )} />
		<SwitchOption option={leftOption} onClick={onSwitch} />
		<SwitchOption option={rightOption} onClick={onSwitch} />
	</div>
);

Switch.propTypes = {
	className: PropTypes.string,
	leftOption: PropTypes.shape( {
		Content: PropTypes.node,
		isSelected: PropTypes.bool
	} ).isRequired,
	rightOption: PropTypes.shape( {
		Content: PropTypes.node,
		isSelected: PropTypes.bool
	} ).isRequired,
	onSwitch: PropTypes.func.isRequired
};

Switch.defaultProps = {
	className: ''
};

export default Switch;
