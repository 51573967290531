import PropTypes from 'prop-types';
import { Fragment } from 'react';
import Workspace from '../../entities/workspace';
import Tabs from '../Tabs/Tabs';
import { ERROR } from '../../styles/colors';

export const WorkspaceFullSettingsPanel = ( {
	workspace, tabs, selectedTabIndex, onTabChange
} ) => {
	const tabItems = tabs.map( ( { name, key } ) => {
		if ( key === 'plan' && workspace?.showWarningIcon ) {
			return {
				label: name,
				icon: 'error',
				iconColor: ERROR
			};
		}
		return { label: name };
	} );
	const CurrentTabComponent = (
		tabs[ selectedTabIndex ] && tabs[ selectedTabIndex ].component
	) || Fragment;

	return (
		<>
			<div className="header">
				<Tabs
					tabSelected={selectedTabIndex}
					tabs={tabItems}
					onTabChange={onTabChange}
				/>
			</div>
			<div className="body">
				<CurrentTabComponent workspaceID={workspace.id} />
			</div>
		</>
	);
};

WorkspaceFullSettingsPanel.propTypes = {
	workspace: PropTypes.instanceOf( Workspace ).isRequired,
	selectedTabIndex: PropTypes.number,
	onTabChange: PropTypes.func,
	tabs: PropTypes.arrayOf( PropTypes.shape( {
		name: PropTypes.string.isRequired,
		component: PropTypes.any.isRequired,
		onlyOwner: PropTypes.bool
	} ) )
};

WorkspaceFullSettingsPanel.defaultProps = {
	tabs: [],
	selectedTabIndex: 0,
	onTabChange: undefined
};

export default WorkspaceFullSettingsPanel;
