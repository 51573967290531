import { useHistory, useLocation } from 'react-router';
import CloseableModal from './CloseableModal';

type CloseableModalProps = {
	canClose: boolean,
	children: React.ReactNode,
	className: string,
	onClose: () => void,
	name?: string,
};

const CloseableModalWithNavigation = ( props: CloseableModalProps ) => {
	const history = useHistory();
	const location = useLocation();

	return (
		<CloseableModal
			history={history}
			location={location}
			{...props}
		/>
	);
};

export default CloseableModalWithNavigation;
