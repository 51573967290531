import FreeSubscriptionType from '../entities/subscriptionType/freeSubscriptionType';
import PaidSubscriptionType from '../entities/subscriptionType/paidSubscriptionType';

export default class SubscriptionTypeFactory {
	static for( subscription ) {
		if ( subscription.planCost === 0 ) {
			return new FreeSubscriptionType();
		}

		return new PaidSubscriptionType();
	}
}
