import { makeAutoObservable } from 'mobx';

export default class SharedViewNavPresenter {
	constructor( {
		popupSystem, assets, renderDownloadAssetPopup
	} ) {
		this._popupSystem = popupSystem;
		this.assets = assets;
		this._renderDownloadAssetPopup = renderDownloadAssetPopup;

		makeAutoObservable( this );
	}

	downloadAllAssetsWasClicked = () => {
		this._popupSystem.showCustomPopup( {
			renderPopup: this._renderDownloadAssetPopup( {
				assets: this.assets
			} )
		} );
	}
}
