import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Switch as MaterialSwitch } from '@material-ui/core';

import './Switch.scss';

const Switch = ( { checked, onSwitch, disabled } ) => (
	<div className={classNames( 'Switch', { disabled } )} data-testid="settings-switch">
		<MaterialSwitch onChange={onSwitch} checked={checked} disabled={disabled} />
	</div>
);

Switch.propTypes = {
	checked: PropTypes.bool.isRequired,
	onSwitch: PropTypes.func.isRequired,
	disabled: PropTypes.bool
};

Switch.defaultProps = {
	disabled: false
};

export default Switch;
