import PropTypes from 'prop-types';
import { displayTimeLeft } from '../../../lib/dateUtils';

import ProjectAssetUploaderContainer
	from '../../../containers/ProjectAssetUploaderContainer/ProjectAssetUploaderContainer';

import './AssetUploaderUploadsListBox.scss';

const AssetUploaderUploadsListBox = ( {
	assetActiveUploadsCount, uploadProjectIDs, totalTimeRemaining,
	onCancelUploadClick, onCancelAllClick
} ) => (
	<div className="AssetUploaderUploadsListBox">
		<div className="uploader-header">
			<h3>
				{assetActiveUploadsCount > 0
					? `Uploading ${assetActiveUploadsCount} file${assetActiveUploadsCount !== 1 ? 's' : ''}...`
					: 'Upload Box'}
			</h3>
			{assetActiveUploadsCount > 0 && <p>{displayTimeLeft( totalTimeRemaining )}</p>}
		</div>
		<div className="project_uploads">
			{ uploadProjectIDs.map( projectID => (
				<ProjectAssetUploaderContainer
					key={projectID}
					projectID={projectID}
					onUploadCancel={onCancelUploadClick}
				/>
			) ) }
		</div>
		<div className="uploader-footer">
			<button
				className="cancel-all"
				disabled={assetActiveUploadsCount === 0}
				onClick={onCancelAllClick}
			>
				Cancel All
			</button>
		</div>
	</div>
);

AssetUploaderUploadsListBox.propTypes = {
	uploadProjectIDs: PropTypes.arrayOf( PropTypes.number ),
	assetActiveUploadsCount: PropTypes.number,
	totalTimeRemaining: PropTypes.number,
	onCancelUploadClick: PropTypes.func,
	onCancelAllClick: PropTypes.func
};

AssetUploaderUploadsListBox.defaultProps = {
	uploadProjectIDs: [],
	assetActiveUploadsCount: 0,
	totalTimeRemaining: 0,
	onCancelUploadClick: () => {},
	onCancelAllClick: () => {}
};

export default AssetUploaderUploadsListBox;
