import { useMemo } from 'react';
import { useApi } from '../../../providers/ApiProvider'
import AuthSystem from './AuthSystem';
import { useRootStore } from '../../../providers/RootStoreProvider';
import { useReduxTanStackSync } from '../../../providers/ReduxTanStackSyncProvider';

const useAuthSystem = () => {
	const api = useApi();
	const { authenticationStore } = useRootStore();
	const sync = useReduxTanStackSync();
	return useMemo( () => new AuthSystem( { api, authenticationStore, sync } ), [ api, authenticationStore ] );
}

export default useAuthSystem;
