export default class Filter {
	constructor( { field, value, type } ) {
		this.field = field;
		this.value = value;
		this.type = type;
	}

	match( item ) {
		return this._matchers[ this.type ]( {
			value: item[ this.field ],
			expected: this.value
		} );
	}

	get _matchers() {
		return {
			includes: this._includesMatcher,
			equal: this._equalMatcher
		};
	}

	_includesMatcher = ( { value, expected = '' } ) => (
		value.toLowerCase().includes( expected.toLowerCase() )
	)

	_equalMatcher = ( { value, expected } ) => (
		value === expected
	)
}
