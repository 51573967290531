import { DirectUpload } from '@rails/activestorage';
import uuid from 'uuid/v1';
import { DIRECT_FILE_UPLOAD_URL } from '../config/urls';

export const DIRECT_FILE_UPLOAD_EVENTS = Object.freeze( {
	SUCCESS: 'success',
	PROGRESS: 'progress',
	ERROR: 'error',
	UPLOAD_START: 'upload_start',
	FINISH: 'finish'
} );

export const DIRECT_FILE_UPLOAD_STATUS = Object.freeze( {
	WAITING: 'WAITING',
	UPLOADING: 'UPLOADING',
	ABORTED: 'ABORTED',
	FAILED: 'FAILED',
	SUCCEEDED: 'SUCCEEDED'
} );

class DirectFileUpload {
	constructor( id, file ) {
		this.id = id;
		this.file = file;
		this._xhr = null;
		this.status = DIRECT_FILE_UPLOAD_STATUS.WAITING;
		this._directUpload = new DirectUpload( file, DIRECT_FILE_UPLOAD_URL, this );
		this._eventListeners = {};
	}

	static fromFile( file ) {
		return new DirectFileUpload( uuid(), file );
	}

	get uploadIsActive() {
		return this.status === DIRECT_FILE_UPLOAD_STATUS.UPLOADING && this._xhr;
	}

	get uploadIsWaiting() {
		return this.status === DIRECT_FILE_UPLOAD_STATUS.WAITING;
	}

	upload() {
		this._directUpload.create( ( error, attributes ) => {
			this._xhr = null;
			this._handleFinish();
			if ( error ) {
				this._handleError( error );
			} else {
				this._handleSuccess( attributes );
			}
		} );
	}

	addEventListener( event, callback ) {
		this._eventListeners[ event ] = callback;
	}

	_handleEvent( eventName, newStatus, value ) {
		this.status = newStatus;
		if ( typeof this._eventListeners[ eventName ] !== 'function' ) { return; }
		this._eventListeners[ eventName ]( value );
	}

	_handleSuccess( attributes ) {
		this._handleEvent(
			DIRECT_FILE_UPLOAD_EVENTS.SUCCESS,
			DIRECT_FILE_UPLOAD_STATUS.SUCCEEDED,
			attributes
		);
	}

	_handleError( error ) {
		this._handleEvent(
			DIRECT_FILE_UPLOAD_EVENTS.ERROR,
			DIRECT_FILE_UPLOAD_STATUS.FAILED,
			error
		);
	}

	_handleFinish() {
		this._handleEvent(
			DIRECT_FILE_UPLOAD_EVENTS.FINISH,
			this.status
		);
	}

	abort() {
		const { uploadIsActive, uploadIsWaiting } = this;
		if ( !uploadIsWaiting && !uploadIsActive ) { return; }

		if ( uploadIsActive ) {
			this._abortXhr();
		} else {
			this._abortXhrWhenActive();
		}
	}

	_abortXhr() {
		this._xhr.abort();
		this._isMarkedForAbortion = false;
	}

	_abortXhrWhenActive() {
		this._isMarkedForAbortion = true;
	}

	_handleProgress( { loaded, total } ) {
		this._handleEvent(
			DIRECT_FILE_UPLOAD_EVENTS.PROGRESS,
			DIRECT_FILE_UPLOAD_STATUS.UPLOADING,
			{ loaded, total }
		);
	}

	directUploadWillCreateBlobWithXHR( xhr ) {
		this._xhr = xhr;
		this._xhr.withCredentials = true;
		this.status = DIRECT_FILE_UPLOAD_STATUS.UPLOADING;
		if ( this._isMarkedForAbortion ) {
			this._abortXhr();
			return;
		}
		xhr.upload.addEventListener( DIRECT_FILE_UPLOAD_EVENTS.ERROR, this._handleError.bind( this ) );
	}

	directUploadWillStoreFileWithXHR( xhr ) {
		this._xhr = xhr;
		this.status = DIRECT_FILE_UPLOAD_STATUS.UPLOADING;
		if ( this._isMarkedForAbortion ) {
			this._abortXhr();
			return;
		}
		this._handleEvent(
			DIRECT_FILE_UPLOAD_EVENTS.UPLOAD_START,
			DIRECT_FILE_UPLOAD_STATUS.UPLOADING
		);
		xhr.upload.addEventListener(
			DIRECT_FILE_UPLOAD_EVENTS.PROGRESS, this._handleProgress.bind( this )
		);
		xhr.upload.addEventListener(
			DIRECT_FILE_UPLOAD_EVENTS.ERROR, this._handleError.bind( this )
		);
	}
}

export default DirectFileUpload;
