import ColorView from '../structures/colorView';
import { LIGHT_GREY } from '../styles/colors';

export default class NullLabel {
	get id() {
		return null;
	}

	get name() {
		return 'No Label';
	}

	get isCustom() {
		return false;
	}

	get labellableID() {
		return undefined;
	}

	get labellableType() {
		return undefined;
	}

	get order() {
		return undefined;
	}

	get color() {
		return new ColorView( { base: LIGHT_GREY } );
	}
}
