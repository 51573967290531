import { useEffect } from 'react';
import PropTypes from 'prop-types';
import postalCodes from 'postal-codes-js';
import BillingInput from '../BillingInput/BillingInput';

const PostalCodeInput = ( {
	countryCode,
	stateCode,
	value,
	onChange,
	onValidationStatusChange,
	disabled,
	required
} ) => {
	const kind = countryCode === 'US' ? 'Zip' : 'Postal';
	const validation = postalCodes.validate( countryCode, value );
	const errorMessage = validation !== true ? validation : null;

	useEffect(
		() => { onChange( '' ); },
		[ countryCode, stateCode ]
	);

	return (
		<BillingInput
			className="PostalCodeInput"
			label={`${kind} Code`}
			name="name"
			value={value}
			onChange={onChange}
			placeholder={`Enter ${kind} Code`}
			required={required}
			onValidationStatusChange={onValidationStatusChange}
			disabled={disabled}
			errorMessage={errorMessage}
		/>
	);
}

PostalCodeInput.propTypes = {
	countryCode: PropTypes.string.isRequired,
	stateCode: PropTypes.string,
	value: PropTypes.any,
	onChange: PropTypes.func,
	onValidationStatusChange: PropTypes.func,
	disabled: PropTypes.bool,
	required: PropTypes.bool
};

PostalCodeInput.defaultProps = {
	stateCode: undefined,
	value: '',
	onChange: () => {},
	onValidationStatusChange: () => {},
	disabled: false,
	required: false
};

export default PostalCodeInput;
