import { createElement } from 'react';
import MarkupArrow from '../components/MarkupEditor/shapes/MarkupArrow';
import MarkupCurve from '../components/MarkupEditor/shapes/MarkupCurve';
import MarkupLine from '../components/MarkupEditor/shapes/MarkupLine';
import MarkupRect from '../components/MarkupEditor/shapes/MarkupRect';

export const INACTIVE_OPACITY = 0.3;
export const DISABLED_OPACITY = 0.15;
export const ACTIVE_OPACITY = 1;

const opacityForCurrentState = ( isActive, isDisabled ) => {
	if ( isActive ) { return ACTIVE_OPACITY; }
	if ( isDisabled ) { return DISABLED_OPACITY; }

	return INACTIVE_OPACITY;
};

class ShapeRenderer {
	constructor( shape, markupComponent, shapeKey ) {
		this._shape = shape;
		this._markupComponent = markupComponent;
		this._shapeKey = shapeKey;
	}

	static forArrow( arrow ) {
		return new ShapeRenderer( arrow, MarkupArrow, 'arrow' );
	}

	static forCurve( curve ) {
		return new ShapeRenderer( curve, MarkupCurve, 'curve' );
	}

	static forLine( line ) {
		return new ShapeRenderer( line, MarkupLine, 'line' );
	}

	static forRect( rect ) {
		return new ShapeRenderer( rect, MarkupRect, 'rect' );
	}

	get shape() { return this._shape; }

	get markupComponent() { return this._markupComponent; }

	get shapeKey() { return this._shapeKey; }

	render( scale, key, isActive, isDisabled, props ) {
		const { shapeKey, shape } = this;
		const opacity = opacityForCurrentState( isActive, isDisabled );
		return createElement(
			this.markupComponent,
			{
				[ shapeKey ]: shape,
				scale,
				opacity,
				key,
				...props
			}
		);
	}
}

export default ShapeRenderer;
