import { FETCH_WORKSPACE_INVITATIONS, FETCH_WORKSPACE_INVITATION, DELETE_WORKSPACE_INVITATION } from './types';
import { WORKSPACES_URL, WORKSPACES_INVITATIONS_URL } from '../config/urls';
import { catchNotAllowedResponseWrapper } from '../lib/catchNotAllowedResponseWrapper';

export const fetchWorkspaceInvitations = workspaceID => ( dispatch, _, api ) => dispatch( {
	type: FETCH_WORKSPACE_INVITATIONS,
	promise: catchNotAllowedResponseWrapper(
		api.get( `${WORKSPACES_URL}/${workspaceID}/invites` ),
		dispatch,
		workspaceID
	)
		.then( response => response.response ),
	meta: { workspaceID }
} );

export const fetchWorkspaceInvitation = ( hash, em ) => ( dispatch, _, api ) => dispatch( {
	type: FETCH_WORKSPACE_INVITATION,
	promise: api
		.get( `${WORKSPACES_INVITATIONS_URL}/${hash}?em=${em}` )
		.then( response => ( {
			...response.response,
			hash
		} ) )
} );

export const deleteWorkspaceInvitation = ( workspaceID, invitationID ) => (
	dispatch, _, api
) => dispatch( {
	type: DELETE_WORKSPACE_INVITATION,
	promise: catchNotAllowedResponseWrapper( api.delete(
		`${WORKSPACES_URL}/${workspaceID}/invites/${invitationID}` ), dispatch, workspaceID
	)
		.then( () => ( { invitationID } ) ),
	meta: { workspaceID }
} );
