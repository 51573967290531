import { Point, Rectangle } from '@mathigon/euclid';
import Arrow from './arrow';
import Curve from './curve';
import Line from './line';
import Rect from './rect';

const shapeTypeToClassMap = {
	'arrow': Arrow,
	'curve': Curve,
	'straight_line': Line,
	'rect': Rect,

	/* The following entry is temporary.
	 * By the time of this change, backend serializes markup curves
	 * with type "curve" as expected; they are no longer serialized
	 * with type "line". But some users may still have old markup
	 * curves persisted in their local storage with type "line". To
	 * avoid crashes for those users, persisted markups with type
	 * "line" will be recognized and treated as curves.
	 *
	 * After some reasonable time this line should be removed.
	 */
	'line': Curve
};

class Markup {
	constructor( id, number, shapes ) {
		this.id = id;
		this.number = number;
		this.shapes = shapes;
	}

	static fromCommentJSON( commentProperties ) {
		if ( !commentProperties.markup || commentProperties.markup.length === 0 ) {
			return undefined;
		}

		return new Markup(
			commentProperties.id,
			commentProperties.markup_number,
			commentProperties.markup.map(
				shape => shapeTypeToClassMap[ shape.type ].fromJSON( shape )
			)
		);
	}

	get boundingRectangle() {
		return Rectangle.aroundPoints( this._pointsOfLines() );
	}

	convertToRelative( { width, height } ) {
		if ( !width || !height ) return;
		this.shapes.forEach( ( shape ) => { shape.convertToRelative?.( { width, height } ); } );
	}

	toJSON() {
		return this.shapes.map( shape => shape.toJSON() );
	}

	addShape( shape ) {
		return new Markup( this.id, this.number, [ ...this.shapes, shape ] );
	}

	_pointsOfLines() {
		return this.shapes.reduce(
			( points, shape ) => [
				...points,
				...shape.points.map( ( point ) => {
					const x = point.get( 'x' );
					const y = point.get( 'y' );
					return new Point( x, y );
				} )
			],
			[]
		);
	}
}

export default Markup;
