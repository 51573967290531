import { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import AssetRound from '../../../entities/assetRound';

import './AssetRoundSelector.scss';
import DropdownMenu from '../../DropdownMenu/DropdownMenu';
import { AssetRoundsMenu } from '../AssetRoundsMenu/AssetRoundsMenu';
import { AssetRoundSelectorButtonNode } from '../AssetRoundSelectorButtonNode/AssetRoundSelectorButtonNode';

export const AssetRoundSelector = ( {
	disabled, selected, rounds, onChange, iconSize, iconColor, onDropdownToggle
} ) => {
	const [ menuIsOpen, setIsMenuOpen ] = useState( false );
	const hasMultipleRounds = rounds.length > 1;
	const onDropdownToggleClick = () => {
		onDropdownToggle();
		setIsMenuOpen( !menuIsOpen );
	};
	return (
		<DropdownMenu
			onDropdownToggle={onDropdownToggleClick}
			className="AssetRoundSelector"
			node={(
				<AssetRoundSelectorButtonNode
					round={selected}
					isOpen={menuIsOpen}
					showArrow={hasMultipleRounds}
				/>
			)}
			buttonClassName={classNames(
				'AssetRoundSelectorButton',
				{ 'without-arrow': !hasMultipleRounds, 'disabled': disabled }
			)}
			disabled={disabled || !hasMultipleRounds}
		>
			{ props => (
				<AssetRoundsMenu
					rounds={rounds}
					selected={selected}
					onSelect={( round ) => {
						if ( onChange ) { onChange( round ); }
					}}
					iconSize={iconSize}
					iconColor={iconColor}
					{...props}
				/>
			)}
		</DropdownMenu>
	);
};

AssetRoundSelector.propTypes = {
	rounds: PropTypes.arrayOf( PropTypes.instanceOf( AssetRound ) ),
	selected: PropTypes.instanceOf( AssetRound ),
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	iconSize: PropTypes.number,
	iconColor: PropTypes.number,
	onDropdownToggle: PropTypes.func
};

AssetRoundSelector.defaultProps = {
	rounds: [],
	selected: undefined,
	onChange: undefined,
	disabled: false,
	iconSize: 16,
	iconColor: 'white',
	onDropdownToggle: () => {}
};

export default AssetRoundSelector;
