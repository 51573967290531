import { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getWorkspace, getWorkspaceProjects, getCreditCardForWorkspace } from '../../selectors/workspaces';
import { connectComponent } from '../../lib/connectComponent';
import Workspace from '../../entities/workspace';
import WorkspaceInvoicesListContainer from '../WorkspaceInvoicesListContainer/WorkspaceInvoicesListContainer';
import WorkspaceBillingInformation from '../../entities/workspaceBillingInformation';
import CreditCard from '../../entities/creditCard';
import WorkspaceBillingAndPaymentInformation from '../../components/subscription/WorkspaceBillingAndPaymentInformation/WorkspaceBillingAndPaymentInformation';
import { getBillingInformationForWorkspace } from '../../selectors/workspacesBillingInformation';
import { trackNavigatedToWorkspaceBilling } from '../../services/tracker/trackers/navigation';

import './BillingSettings.scss';

export const BillingSettingsContainer = ( {
	workspaceID, workspace, billingInformation, creditCard, showDialog
} ) => {
	useEffect( () => trackNavigatedToWorkspaceBilling(), [] );

	const onEditPaymentMethodCompleted = useCallback(
		() => showDialog(
			'Payment Method updated',
			'The saved information will be used on the next billing period.'
		)
	);

	const showEditButton = !!workspace && ( workspace.isSubscriptionActive || workspace.isSuspended );
	const showInvalidPaymentMethodTooltip = !!workspace
		&& workspace.hasSubscription
		&& !workspace.subscriptionLastPaymentSucceeded;

	return (
		<div className="BillingSettings">
			<WorkspaceBillingAndPaymentInformation
				workspaceID={workspaceID}
				billingInformation={billingInformation}
				creditCard={creditCard}
				onEditPaymentMethodComplete={onEditPaymentMethodCompleted}
				showEditButton={showEditButton}
				showInvalidPaymentMethodTooltip={showInvalidPaymentMethodTooltip}
			/>
			<WorkspaceInvoicesListContainer workspaceID={workspaceID} />
		</div>
	);
};

BillingSettingsContainer.propTypes = {
	workspaceID: PropTypes.number.isRequired,
	workspace: PropTypes.instanceOf( Workspace ),
	billingInformation: PropTypes.instanceOf( WorkspaceBillingInformation ),
	creditCard: PropTypes.instanceOf( CreditCard ),
	showDialog: PropTypes.func
};

BillingSettingsContainer.defaultProps = {
	workspace: undefined,
	billingInformation: undefined,
	creditCard: undefined,
	showDialog: () => {}
};

export default connectComponent( ( state, props ) => ( {
	workspace: getWorkspace( state, props ),
	projectCount: getWorkspaceProjects( state, props ).length,
	billingInformation: getBillingInformationForWorkspace( state, props ),
	creditCard: getCreditCardForWorkspace( state, props )
} ) )( BillingSettingsContainer );
