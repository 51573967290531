import PropTypes from 'prop-types';
import { ERROR_LIGHT, PRIMARY_200, STRONG_ERROR } from '../../styles/colors';
import Chip from '../Chip/Chip';

import './EmailChip.scss';

const EmailChip = ( { isValid, value, onDeleteEmail } ) => (
	<div className="EmailChip">
		<Chip
			icon={!!onDeleteEmail && 'close'}
			textColor={!isValid && STRONG_ERROR}
			background={isValid ? PRIMARY_200 : ERROR_LIGHT}
			onClick={() => onDeleteEmail( value )}
		>
			{value}
		</Chip>
	</div>
);

EmailChip.propTypes = {
	isValid: PropTypes.bool.isRequired,
	value: PropTypes.string.isRequired,
	onDeleteEmail: PropTypes.func.isRequired
};

export default EmailChip;
