import { AVAILABLE_ROLES_BY_TYPE } from './projectRole';

export default class ProjectInvitation {
	constructor(
		id,
		hash,
		email,
		role,
		projectID,
		projectAttributes
	) {
		this.id = id;
		this.hash = hash;
		this.email = email;
		this.role = role;
		this.projectID = projectID;
		this.projectAttributes = projectAttributes;
	}

	static fromJSON( properties ) {
		return new ProjectInvitation(
			properties.id,
			properties.hash,
			properties.email,
			AVAILABLE_ROLES_BY_TYPE[ properties.role.type ],
			properties.project ? properties.project.id : properties.project_id,
			properties.project
		);
	}

	toJSON() {
		return {
			id: this.id,
			hash: this.hash,
			email: this.email,
			role: this.role,
			project_id: this.projectID,
			project: this.projectAttributes
		};
	}
}
