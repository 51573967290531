import { makeAutoObservable } from 'mobx';
import AsyncActionPresenterFactory from '../../factories/AsyncActionPresenterFactory';

export default class TwoFactorAuthenticationCardPresenter {
	constructor( {
		currentUser, popupSystem, snackbarSystem, userPreferencesSystem
	} ) {
		this.currentUser = currentUser;
		this._popupSystem = popupSystem;
		this._snackbarSystem = snackbarSystem;
		this._userPreferencesSystem = userPreferencesSystem;

		makeAutoObservable( this );
	}

	get email() { return this.currentUser.email; }

	get is2FAEnabled() {
		return this.currentUser.is2FAEnabled;
	}

	buttonWasClicked = () => {
		if ( this.is2FAEnabled ) {
			AsyncActionPresenterFactory.forDisable2FA( {
				userPreferencesSystem: this._userPreferencesSystem,
				snackbarSystem: this._snackbarSystem
			} ).trigger();
		} else {
			AsyncActionPresenterFactory.forEnable2FA( {
				email: this.email,
				userPreferencesSystem: this._userPreferencesSystem,
				popupSystem: this._popupSystem,
				snackbarSystem: this._snackbarSystem
			} ).trigger();
		}
	}
}
