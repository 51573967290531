import { Map } from 'immutable';
import { handle } from 'redux-pack';
import { makeAsyncActionReducer, handleSuccess, handleStart } from '../lib/reduxUtils';
import {
	CREATE_PROJECT, FETCH_PROJECT,
	FETCH_ACTIVE_PROJECTS,
	FETCH_HIDDEN_PROJECTS,
	CHANGE_PROJECT_NAME,
	LOGOUT,
	DELETE_PROJECTS,
	ARCHIVE_PROJECTS,
	UNARCHIVE_PROJECTS,
	SET_PROJECT_RUSH_PRIORITY,
	UNSET_PROJECT_RUSH_PRIORITY,
	UPDATE_PROJECT_SETTINGS,
	CREATE_ASSET_FROM_DIRECT_UPLOAD,
	TRANSFER_OWNERSHIP,
	TRANSFER_PROJECT_TO_WORKSPACE,
	LEAVE_PROJECTS,
	FETCH_PROJECT_LABELS,
	CREATE_PROJECT_LABEL,
	DELETE_PROJECT_LABEL,
	EDIT_WORKSPACE_LABEL,
	DELETE_WORKSPACE_LABEL,
	ADD_LABEL_TO_PROJECTS,
	REMOVE_LABEL_FROM_PROJECTS,
	FETCH_PROJECT_OVERVIEW,
	UPDATE_GUEST_HASH,
	CREATE_LABELS_FROM_GROUPS
} from '../actions/types';
import { PROJECT_STATUS_ACTIVE, PROJECT_STATUS_HIDDEN } from '../entities/project';
import { normalizeResponse } from '../lib/normalizeUtils';
import { project as projectScheme } from './schemes';
import { parseProject, parseProjects } from '../parsers/projects';
import { AVAILABLE_ROLES_BY_TYPE } from '../entities/projectRole';

const normalizeProjects = ( prevState, actionPayload ) => normalizeResponse(
	prevState, actionPayload, projectScheme, 'projects'
);

const parseAndNormalizeProjects = ( prevState, actionPayload ) => {
	const payload = Array.isArray( actionPayload )
		? parseProjects( actionPayload ) : parseProject( actionPayload );
	return normalizeProjects( prevState, payload );
};

export const createProjectRequest = makeAsyncActionReducer( CREATE_PROJECT );
export const fetchProjectRequest = makeAsyncActionReducer( FETCH_PROJECT );
export const deleteProjectsRequest = makeAsyncActionReducer( DELETE_PROJECTS );
export const fetchActiveProjectsRequest = makeAsyncActionReducer( FETCH_ACTIVE_PROJECTS );
export const fetchHiddenProjectsRequest = makeAsyncActionReducer( FETCH_HIDDEN_PROJECTS );

export const projects = ( state = new Map(), action ) => {
	switch ( action.type ) {
	case CREATE_PROJECT:
		return handleSuccess( state, action, ( prevState ) => {
			const project = {
				...action.payload,
				current_user_role: AVAILABLE_ROLES_BY_TYPE.ProjectCollaborator.id
			};

			return parseAndNormalizeProjects( prevState, project );
		} );
	case FETCH_PROJECT:
		return handle( state, action, {
			failure: ( prevState ) => {
				const { projectID } = action.meta;
				prevState.delete( projectID );
				return prevState;
			},
			success: ( prevState ) => {
				const { projectID } = action.meta;
				const project = action.payload;
				const prevProject = prevState.get( projectID, {} );

				return parseAndNormalizeProjects( prevState, { ...prevProject, ...project } );
			}
		} );
	case ARCHIVE_PROJECTS:
		return handleSuccess(
			state,
			action,
			prevState => action.payload.reduce(
				( result, archivedProjectID ) => result.updateIfSet(
					archivedProjectID,
					project => ( {
						...parseProject( project ),
						status: PROJECT_STATUS_HIDDEN
					} )
				),
				prevState
			)
		);
	case UNARCHIVE_PROJECTS:
		return handleSuccess(
			state,
			action,
			prevState => action.payload.reduce(
				( result, unarchivedProjectID ) => result.updateIfSet(
					unarchivedProjectID,
					project => ( {
						...parseProject( project ),
						status: PROJECT_STATUS_ACTIVE
					} )
				),
				prevState
			)
		);
	case LEAVE_PROJECTS:
	case DELETE_PROJECTS:
		return handleSuccess(
			state,
			action,
			prevState => action.payload.reduce(
				( result, deletedID ) => result.delete( deletedID ),
				prevState
			)
		);
	case FETCH_ACTIVE_PROJECTS:
	case FETCH_HIDDEN_PROJECTS:
		return handleSuccess(
			state,
			action,
			( prevState ) => {
				const payloadWithLabels = action.payload.map( ( project ) => {
					const projectLabels = prevState.get( project.id )?.labels ?? [];
					return {
						...project,
						labels: projectLabels
					};
				} );
				return parseAndNormalizeProjects( new Map(), payloadWithLabels );
			}
		);
	case CHANGE_PROJECT_NAME:
		return handle( state, action, {
			start: ( prevState ) => {
				const { projectID, newName } = action.meta;
				const project = prevState.get( projectID );

				return project
					? prevState.set( projectID, { ...project, name: newName } )
					: prevState;
			},
			failure: ( prevState ) => {
				const { projectID, originalName } = action.meta;
				if ( !originalName ) { return prevState; }

				const project = prevState.get( projectID );

				return project
					? prevState.set( projectID, { ...project, name: originalName } )
					: prevState;
			},
			success: ( prevState ) => {
				const prevProject = prevState.get( action.payload.id );
				return prevProject
					? prevState.update( action.payload.id, () => (
						{ ...prevProject, name: action.payload.name }
					) )
					: parseAndNormalizeProjects( prevState, action.payload );
			}
		} );
	case UPDATE_PROJECT_SETTINGS:
		return handle( state, action, {
			success: ( prevState ) => {
				const prevProject = prevState.get( action.meta.project.id );
				return prevProject
					? prevState.update( action.meta.project.id, () => (
						{
							...prevProject,
							...action.meta.project,
							cover_url: action.payload.cover_url
						}
					) )
					: parseAndNormalizeProjects( {
						...action.meta.project, cover_url: action.payload.cover_url
					} );
			}
		} );
	case SET_PROJECT_RUSH_PRIORITY:
		return handle( state, action, {
			start: prevState => prevState.update(
				action.meta.projectID,
				project => ( { ...project, rush_priority: true } )
			),
			failure: prevState => prevState.update(
				action.meta.projectID,
				project => ( { ...project, rush_priority: false } )
			),
			success: prevState => prevState.update(
				action.meta.projectID,
				project => (
					{ ...project, rush_priority: true, last_activity_at: new Date().toISOString() }
				)
			)
		} );
	case UNSET_PROJECT_RUSH_PRIORITY:
		return handle( state, action, {
			start: prevState => prevState.update(
				action.meta.projectID,
				project => ( { ...project, rush_priority: false } )
			),
			failure: prevState => prevState.update(
				action.meta.projectID,
				project => ( { ...project, rush_priority: true } )
			),
			success: prevState => prevState.update(
				action.meta.projectID,
				project => (
					{ ...project, rush_priority: false, last_activity_at: new Date().toISOString() }
				)
			)
		} );
	case LOGOUT:
		return handleStart( state, action, () => new Map() );
	case CREATE_ASSET_FROM_DIRECT_UPLOAD:
		return handleSuccess( state, action, ( prevState ) => {
			const { project_id: projectID, id: assetID } = action.payload.asset;
			const project = prevState.get( projectID );
			return !project || project.cover_id
				? prevState
				: prevState.update( projectID, p => ( { ...p, cover_id: assetID } ) );
		} );
	case TRANSFER_OWNERSHIP:
		return handleSuccess( state, action, prevState => (
			prevState.updateIfSet(
				action.meta.projectID,
				// eslint-disable-next-line max-len
				project => ( { ...project, current_user_role: AVAILABLE_ROLES_BY_TYPE.ProjectCollaborator.id } )
			)
		) );
	case TRANSFER_PROJECT_TO_WORKSPACE:
		return handleSuccess( state, action, prevState => (
			prevState.updateIfSet(
				action.meta.projectID,
				project => ( { ...project, workspace: action.meta.newTeamWorkspace } )
			)
		) );
	case FETCH_PROJECT_LABELS:
		return handleSuccess( state, action, prevState => (
			prevState.updateIfSet(
				action.meta.projectID,
				project => ( { ...project, labels: action.payload.map( label => label.id ) } )
			)
		) );
	case CREATE_PROJECT_LABEL:
		return handleSuccess( state, action, ( prevState ) => {
			const { labellable_id: labellableId, id } = action.payload;
			return (
				prevState.updateIfSet(
					labellableId,
					project => ( { ...project, labels: [ ...project.labels, id ] } )
				)
			);
		} );
	case CREATE_LABELS_FROM_GROUPS:
		return handleSuccess( state, action, ( prevState ) => {
			const { projectID } = action.meta;
			const labelsIDs = action.payload.map( label => label.id );
			return (
				prevState.updateIfSet(
					projectID,
					project => ( { ...project, labels: [ ...new Set( [ ...project.labels, ...labelsIDs ] ) ] } )
				)
			);
		} );
	case DELETE_PROJECT_LABEL:
		return handleSuccess( state, action, prevState => prevState.updateIfSet(
			action.meta.labellableID,
			project => ( {
				...project,
				labels: project.labels.filter( id => id !== action.meta.labelID )
			} )
		) );
	case EDIT_WORKSPACE_LABEL:
		return handleSuccess( state, action, ( prevState ) => {
			const { meta: { labelID }, payload: label } = action;
			return prevState.map(
				project => (
					project.label?.id === labelID
						? {
							...project,
							label: {
								...project.label,
								name: label.name,
								base_color: label.base_color,
								hover_color: label.hover_color
							}
						}
						: project
				)
			);
		} );
	case DELETE_WORKSPACE_LABEL:
		return handleSuccess( state, action, ( prevState ) => {
			const { labelID } = action.meta;
			return prevState.map(
				project => (
					project.label?.id === labelID
						? { ...project, label: undefined }
						: project
				)
			);
		} );

	case ADD_LABEL_TO_PROJECTS:
		return handleSuccess( state, action, ( prevState ) => {
			const { projectIDs } = action.meta;
			const label = action.payload;
			return projectIDs.reduce( ( newState, projectID ) => newState.update(
				projectID, project => ( { ...project, label } ) ),
			prevState
			);
		} );
	case REMOVE_LABEL_FROM_PROJECTS:
		return handleSuccess( state, action, ( prevState ) => {
			const { projectIDs } = action.meta;
			return projectIDs.reduce(
				( newState, projectID ) => newState.update(
					projectID, project => ( { ...project, label: undefined } )
				),
				prevState
			);
		} );
	case UPDATE_GUEST_HASH:
		return handleSuccess( state, action, prevState => (
			prevState.update(
				action.meta.projectID,
				project => ( { ...project, guest_hash: { ...project.guest_hash, enabled: action.meta.enabled } } )
			)
		) );
	default:
		return state;
	}
};

// FIXME: This is a temporary solution until the backend filters out archived/hidden projects from the notifications response
// Known issue: it will show archived projects in the notifications panel if the user has fetched that specific project before
// i.e. if the FETCH_PROJECT action has been dispatched for that project
export const activeProjects = ( state = new Map(), action ) => {
	switch ( action.type ) {
	case ARCHIVE_PROJECTS:
		return handleSuccess(
			state,
			action,
			prevState => action.payload.reduce(
				( result, archivedProjectID ) => result.delete( archivedProjectID ),
				prevState
			)
		);
	case FETCH_HIDDEN_PROJECTS:
		return state;
	default:
		return projects( state, action );
	}
};

export const projectOverviews = ( state = Map(), action ) => {
	switch ( action.type ) {
	case FETCH_PROJECT_OVERVIEW:
		return handleSuccess(
			state,
			action,
			prevState => (
				prevState.set( action.meta.projectID, action.payload )
			) );
	case LOGOUT:
		return Map();
	default:
		return state;
	}
};
