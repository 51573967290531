import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { isNotFoundError } from '../../errors/common';
import useAuthSystem from '../../new_arch/modules/authentication/system/useAuthSystem';
import { useApi } from '../../new_arch/providers/ApiProvider';
import { guestHashStore } from '../../store/store';
import FullScreenLoader from '../../components/FullScreenLoader/FullScreenLoader';
import GuestAccessErrorScreen from './GuestAccessErrorScreen/GuestAccessErrorScreen';
import ProjectSystem from '../../systems/ProjectSystem';
import ProjectGuestViewPreview from '../../components/ProjectGuestViewPreview/ProjectGuestViewPreview';

type ProjectGuestViewGatewayProps = {
	guestHash: string
}

const ProjectGuestViewGateway = ( { guestHash }: ProjectGuestViewGatewayProps ) => {
	const api = useApi();
	const authSystem = useAuthSystem();
	const projectSystem = new ProjectSystem( { api, authSystem } );
	const history = useHistory();
	const [ guestAccessError, setGuestAccessError ] = useState<unknown>();
	const [ projectPreview, setProjectPreview ] = useState<{ id: number, name: string }>();

	const enterProjectGuestView = async () => {
		try {
			if ( authSystem.isAuthenticated ) {
				const { projectID } = await projectSystem.requestProjectGuestAccess( { guestHash } );
				guestHashStore.setProjectGuestHash( projectID, guestHash );
				history.replace( `/projects/${projectID}` );
			} else {
				setProjectPreview( await projectSystem.getProjectPreview( { guestHash } ) );
			}
		} catch ( error: unknown ) {
			setGuestAccessError( error );
		}
	};

	useEffect( () => {
		enterProjectGuestView();
	}, [ guestHash ] );

	if ( guestAccessError ) {
		const linkIsUnavailable = isNotFoundError( guestAccessError );
		const errorTitle = linkIsUnavailable ? 'This link is not public' : 'We couldn’t find the link';
		const errorDescription = linkIsUnavailable
			? (
				'This link is not available for public use.\nTo access these files, '
				+ 'the owner needs to \nmake it public or invite you via email.'
			)
			: 'Please check your internet connection and retry.\nIf the issue persists, reach out to our support team.';

		return <GuestAccessErrorScreen title={errorTitle} description={errorDescription} />
	}

	if ( projectPreview ) {
		return (
			<ProjectGuestViewPreview
				projectID={projectPreview.id}
				name={projectPreview.name}
				guestHash={guestHash}
			/>
		);
	}

	return <FullScreenLoader />;
};

export default ProjectGuestViewGateway;
