import { Component } from 'react';
import PropTypes from 'prop-types';

import NotificationsPreferences from '../../../entities/notificationsPreferences/notificationsPreferences';
import Checkbox from '../../UI/Checkbox/Checkbox';
import Button from '../../../new_arch/components/Button/Button';
import Loader from '../../Loader/Loader';

import './NotificationsSettings.scss';
import Select from '../../UI/Select/Select';
import { SUCCESS } from '../../../styles/colors';
import WhiteBox from '../../WhiteBox/WhiteBox';

const frequencyOptions = [
	{ value: 'once_per_day', label: 'Once per day' },
	{ value: 'once_per_hour', label: 'Once per hour' },
	{ value: 'every_quarter_hour', label: 'Every 15 minutes' }
];

const getOption = optionValue => frequencyOptions.find( option => option.value === optionValue );

class NotificationsSettings extends Component {
	constructor( props ) {
		super( props );

		this.state = {
			settings: props.notificationsPreferences.clone()
		};
		this._onUpdateClicked = this._onUpdateClicked.bind( this );
		this._onPlatformSettingChange = this._onPlatformSettingChange.bind( this );
		this._onEmailFrequencyChange = this._onEmailFrequencyChange.bind( this );
	}

	componentDidUpdate( prevProps ) {
		const { notificationsPreferences } = this.props;
		if ( prevProps.notificationsPreferences !== notificationsPreferences ) {
			this._updatePreferences();
		}
	}

	_updatePreferences() {
		const { notificationsPreferences } = this.props;
		this.setState( { settings: notificationsPreferences.clone() } );
	}

	_onUpdateClicked() {
		const { onUpdateClick } = this.props;
		const { settings } = this.state;

		onUpdateClick( settings );
	}

	_onPlatformSettingChange( platform, setting ) {
		this.setState( prevState => ( {
			settings: prevState.settings
				.togglePlatformNotificationPreference( platform, setting.identifier )
		} ) );
	}

	_onEmailFrequencyChange( frequency ) {
		this.setState( prevState => ( {
			settings: prevState.settings.set( 'emailEvery', frequency.value )
		} ) );
	}

	_renderSetting( platform, setting ) {
		return (
			<Checkbox
				key={`${platform}-${setting.identifier}`}
				label={setting.text}
				value={setting.value}
				onChange={() => this._onPlatformSettingChange( platform, setting )}
				checkedColor={SUCCESS}
			/>
		);
	}

	render() {
		const { updating, showLoader } = this.props;
		const { notificationsAlarms, emailsAlarms, emailEvery } = this.state.settings;

		return (
			<WhiteBox className="NotificationsSettings">
				{showLoader
					? (
						<div className="loader-box">
							<Loader active />
						</div>
					)
					: (
						<>
							<div className="content">
								<div className="web">
									<h3>Send me Web Notifications...</h3>
									{ notificationsAlarms.toArray().map( setting => this._renderSetting( 'notificationsAlarms', setting ) ) }
								</div>
								<div className="email">
									<div className="email-head">
										<h3>Email me...</h3>
										<Select
											options={frequencyOptions}
											selected={getOption( emailEvery )}
											onChange={this._onEmailFrequencyChange}
											type="tall"
										/>
									</div>
									{ emailsAlarms.toArray().map( setting => this._renderSetting( 'emailsAlarms', setting ) ) }
								</div>
							</div>
							<Button
								onClick={this._onUpdateClicked}
								disabled={updating}
							>
								{updating ? 'Saving...' : 'Save Changes for all projects'}
							</Button>
						</>
					)}
			</WhiteBox>
		);
	}
}

NotificationsSettings.propTypes = {
	notificationsPreferences: PropTypes.instanceOf( NotificationsPreferences ).isRequired,
	onUpdateClick: PropTypes.func,
	updating: PropTypes.bool,
	showLoader: PropTypes.bool
};

NotificationsSettings.defaultProps = {
	onUpdateClick: () => {},
	updating: false,
	showLoader: false
};

export default NotificationsSettings;
