import PropTypes from 'prop-types';

import MyProfileSideBarItem from '../MyProfileSideBarItem/MyProfileSideBarItem';
import Workspace from '../../entities/workspace';
import {
	SUCCESS_700, PRIMARY_BASE
} from '../../styles/colors';

const WorkspaceSideBarItem = ( {
	workspace, selected, onClick, currentUserID
} ) => {
	const planToDisplay = {
		name: workspace.planName?.replace( ' Plan', '' ),
		tagColor: PRIMARY_BASE
	};

	const freePlanToDisplay = {
		name: 'Free Trial',
		tagColor: SUCCESS_700
	};

	return (
		<MyProfileSideBarItem
			title={workspace.name}
			subtitle={workspace.currentRoleIdToDisplay}
			image={workspace.logo}
			selected={selected}
			onClick={onClick}
			workspace={workspace}
			currentUserID={currentUserID}
			planToDisplay={workspace.isOnFreeTrial ? freePlanToDisplay : planToDisplay}
		/>
	);
};

WorkspaceSideBarItem.propTypes = {
	workspace: PropTypes.instanceOf( Workspace ).isRequired,
	selected: PropTypes.bool,
	onClick: PropTypes.func,
	currentUserID: PropTypes.number.isRequired
};

WorkspaceSideBarItem.defaultProps = {
	selected: false,
	onClick: () => {}
};

export default WorkspaceSideBarItem;
