import PropTypes from 'prop-types';

import MyProfileSideBarItem from '../MyProfileSideBarItem/MyProfileSideBarItem';

import User from '../../entities/user';

const UserProfileSideBarItem = ( { user, selected, onClick } ) => (
	<MyProfileSideBarItem
		title="My account"
		subtitle={user.email}
		image={user.avatar}
		selected={selected}
		onClick={onClick}
		isWorkspace={false}
	/>
);

UserProfileSideBarItem.propTypes = {
	user: PropTypes.instanceOf( User ).isRequired,
	selected: PropTypes.bool,
	onClick: PropTypes.func
};

UserProfileSideBarItem.defaultProps = {
	selected: false,
	onClick: () => {}
};

export default UserProfileSideBarItem;
