import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './BillingPeriodTitle.scss';

const BillingPeriodTitle = ( { text } ) => (
	<div className={classNames( 'BillingPeriodTitle' )}>
		<div className={classNames( 'billingPeriodContainer' )}>
			<p>{text}</p>
		</div>
	</div>
);

BillingPeriodTitle.propTypes = {
	text: PropTypes.string.isRequired
};

BillingPeriodTitle.defaultProps = {
};

export default observer( BillingPeriodTitle );
