import {
	SET_ASSETS_VIEW_SORT,
	UPDATE_ASSETS_VIEW_FILTERS,
	SET_ASSETS_CUSTOM_SORT,
	GET_PROJECT_SETTINGS
} from './types';
import { PROJECTS_URL } from '../config/urls';
import {
	trackChangedAssetsFilter,
	trackChangedAssetsSort,
	trackCustomSortedAssets
} from '../services/tracker/trackers/assets';

export const updateAssetsViewFilters = (
	projectID,
	{ filterOption, filenameFilter, assetsType }
) => {
	if ( filterOption ) trackChangedAssetsFilter( filterOption );
	return {
		type: UPDATE_ASSETS_VIEW_FILTERS,
		payload: { filter: filterOption?.id, filenameFilter, assetsType },
		meta: { projectID }
	};
};

export const setAssetsViewSort = ( projectID, sortOption, sortAscending ) => {
	trackChangedAssetsSort( sortOption );
	return {
		type: SET_ASSETS_VIEW_SORT,
		payload: { sort: sortOption.id, sortAscending },
		meta: { projectID }
	};
};

export const setAssetsCustomSort = ( projectID, customSort, sortDirection ) => (
	dispatch, _, api
) => {
	trackCustomSortedAssets();
	return dispatch( {
		type: SET_ASSETS_CUSTOM_SORT,
		promise: api
			.patch(
				`${PROJECTS_URL}/${projectID}/set_assets_order`,
				{ asset_ids: customSort, order: sortDirection }
			)
			.then( () => customSort ),
		meta: { projectID, customSort, ascending: ( sortDirection === 'asc' ) }
	} );
};

const parseProjectSettings = response => ( {
	labelsOrder: response.labels_order
} );

export const fetchProjectSettings = projectID => ( dispatch, _, api ) => dispatch( {
	type: GET_PROJECT_SETTINGS,
	meta: { projectID },
	promise: api
		.get( `${PROJECTS_URL}/${projectID}/custom_settings` )
		.then( response => parseProjectSettings( response.response ) )
} );
