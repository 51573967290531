import Button from '../../new_arch/components/Button/Button';
import User from '../../new_arch/models/user/user';
import './RoleSurveyPopup.scss';
import RoleBox from './components/RoleBox';
import useRoleSurveyPopup from './hooks/useRoleSurveyPopup';
import SnackbarSystem from '../../systems/SnackbarSystem';
import { ROLES } from '../../types/role';

type RoleSurveyPopupProps = {
	user: User,
	snackbarSystem: SnackbarSystem,
	onClose: () => void
}

const RoleSurveyPopup = ( { user, snackbarSystem, onClose }: RoleSurveyPopupProps ) => {
	const { selectedRole, onSelect, onConfirm, isLoading } = useRoleSurveyPopup(
		{ userId: user.id, snackbarSystem, onClose }
	);

	const welcomeMessage = `${user.isNew ? 'Welcome' : 'Hi'} ${user.firstName}`;
	const noRoleSelectedYet = selectedRole === '';

	return (
		<div className="RoleSurveyPopup">
			<div className="container">
				<div className="header">
					<h4 className="welcome-message">{welcomeMessage}</h4>
					<h3 className="question">Which of these options best fit your role?</h3>
					<h4 className="reason">This information helps us create better features</h4>
				</div>
				<div className="options">
					{
						ROLES.map( ( { value, label } ) => (
							<RoleBox
								key={value}
								role={value}
								text={label}
								onSelect={onSelect}
								isSelected={selectedRole === value}
							/>
						) )
					}
				</div>
				<Button
					type="filled"
					color="primary"
					disabled={noRoleSelectedYet || isLoading}
					onClick={onConfirm}
				>
					Continue
				</Button>
			</div>
		</div>
	)
}

export default RoleSurveyPopup;
