const scales = {
	0: 'Bytes',
	1: 'Kb',
	2: 'Mb',
	3: 'Gb',
	4: 'Tb'
};

const getFileSizeScale = ( fileSize ) => {
	let size = fileSize;
	let scale = 0;

	while ( size >= 1024 && scale < 4 ) {
		scale += 1;
		size /= 1024;
	}
	return { scale: scales[ scale ], size: size.toFixed( 1 ) };
};

export const formatFileSize = ( fileSize ) => {
	const { scale, size } = getFileSizeScale( fileSize );
	return `${size} ${scale}`;
};
