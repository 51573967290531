import {
	ownerRolePermissions, projectCollaboratorRolePermissions, projectGuestRolePermissions
} from './projectRolePermissions';

const OWNER_LEVEL = 1;
const PROJECT_COLLABORATOR_LEVEL = 2;
const PROJECT_GUEST_LEVEL = 3;

export default class ProjectRole {
	constructor( id, name, level, permissions, description ) {
		this.id = id;
		this.name = name;
		this.level = level;
		this.permissions = permissions;
		this.description = description;
	}

	get isOwner() { return this.id === ProjectRole.owner().id; }

	get isGuest() { return this.id === ProjectRole.projectGuest().id; }

	get projectsPermissions() { return this.permissions.projectsPermissions; }

	get assetsPermissions() { return this.permissions.assetsPermissions; }

	get tasksPermissions() { return this.permissions.tasksPermissions; }

	get commentsPermissions() { return this.permissions.commentsPermissions; }

	get usersPermissions() { return this.permissions.usersPermissions; }

	static owner() {
		return new ProjectRole(
			'ProjectOwner',
			'Owner',
			OWNER_LEVEL,
			ownerRolePermissions );
	}

	static projectCollaborator() {
		return new ProjectRole(
			'ProjectCollaborator',
			'Collaborator',
			PROJECT_COLLABORATOR_LEVEL,
			projectCollaboratorRolePermissions,
			"User that can collaborate but cannot edit the <br> project's information or delete this project."
		);
	}

	static projectGuest() {
		return new ProjectRole(
			'ProjectGuest',
			'Guest',
			PROJECT_GUEST_LEVEL,
			projectGuestRolePermissions,
			"User that can collaborate but cannot edit the project's information, delete this project, or invite other members."
		);
	}

	/* eslint-disable no-use-before-define */
	canInviteRoles() {
		return AVAILABLE_ROLES
			.filter(
				role => !role.isOwner && !role.isGuest && role.level >= this.level
			);
	}
	/* eslint-enable no-use-before-define */

	get roleThatCanInvite() {
		return this.canInviteRoles()[ 0 ];
	}

	isAtLeast( level ) {
		return this.level <= level;
	}

	// Project Members
	canEditProjectMemberRole( member ) {
		return !member.isOwner && this.usersPermissions.editRole;
	}

	canDeleteProjectMember( member ) {
		return !member.isOwner
		&& this.usersPermissions.destroy
		&& ( this.hasLowerLevel( member.role ) || this.hasSameLevel( member.role ) );
	}

	canDeleteProjectInvite( invite ) {
		return this.usersPermissions.destroy
		&& ( this.hasLowerLevel( invite.role ) || this.hasSameLevel( invite.role ) );
	}

	hasLowerLevel( role ) {
		return role && this.level < role.level;
	}

	hasSameLevel( role ) {
		return role && this.level === role.level;
	}
}

// The roles here are ordered by hierarchy
export const AVAILABLE_ROLES = [
	ProjectRole.owner(),
	ProjectRole.projectCollaborator(),
	ProjectRole.projectGuest()
];

export const VALID_ASSIGNABLE_ROLES = [
	ProjectRole.projectCollaborator()
];

export const AVAILABLE_ROLES_BY_TYPE = Object.freeze( {
	[ ProjectRole.owner().id ]: ProjectRole.owner(),
	[ ProjectRole.projectCollaborator().id ]: ProjectRole.projectCollaborator(),
	[ ProjectRole.projectGuest().id ]: ProjectRole.projectGuest()
} );

export const AVAILABLE_ROLES_NAMES_BY_KEY = Object.freeze( {
	[ ProjectRole.owner().id ]: 'Project Owner',
	[ ProjectRole.projectCollaborator().id ]: ProjectRole.projectCollaborator().name,
	[ ProjectRole.projectGuest().id ]: ProjectRole.projectGuest().name
} );
