import Markup from './markup';
import Comment from './comment';

export default class RootComment extends Comment {
	constructor( {
		id, assetID, roundID, text, authorID, repliesIDs = [],
		createdAt, completedAt, markup = undefined, isEdited = false,
		author = undefined, seenByUsers = [], replies = [], files = []
	} ) {
		super( {
			id,
			assetID,
			roundID,
			text,
			authorID,
			createdAt,
			author,
			seenByUsers,
			isEdited,
			files
		} );

		this.completedAt = completedAt;
		this.markup = markup;
		this.author = author;
		this.repliesIDs = repliesIDs;
		this.replies = replies;
	}

	static fromJSON( properties, author, seenByUsers = [], replies = [] ) {
		return new RootComment( {
			id: properties.id,
			assetID: properties.asset_id,
			roundID: properties.version_id,
			text: properties.text,
			authorID: properties.user,
			repliesIDs: properties.replies || [],
			createdAt: new Date( properties.created_at ),
			completedAt: properties.completed_at ? new Date( properties.completed_at ) : null,
			markup: Markup.fromCommentJSON( properties ),
			isEdited: properties.is_edited,
			author,
			seenByUsers,
			replies,
			files: properties.files
		} );
	}

	toJSON() {
		return {
			id: this.id,
			asset_id: this.assetID,
			version_id: this.roundID,
			text: this.text,
			user: this.authorID,
			replies: this.repliesIDs,
			created_at: this.createdAt.toISOString(),
			completed_at: this.completedAt ? this.completedAt.toISOString() : null,
			...( this.markup ? { markup_number: this.markup.number, markup: this.markup.toJSON() } : {} ),
			is_edited: this.isEdited,
			files: this.files
		};
	}

	get hasMarkup() { return !!this.markup; }

	get wasMarkedAsCompleted() { return !!this.completedAt; }

	get deleteConfirmationText() {
		return 'Are you sure you want to delete this comment?';
	}

	get deleteConfirmationTitle() {
		return 'Delete Comment';
	}

	get isRootComment() { return true; }

	get repliesCount() { return this.repliesIDs.length; }

	get hasReplies() { return this.repliesCount > 0; }

	clone() {
		return new RootComment( {
			id: this.id,
			assetID: this.assetID,
			roundID: this.roundID,
			text: this.text,
			authorID: this.authorID,
			repliesIDs: this.repliesIDs,
			createdAt: this.createdAt,
			completedAt: this.completedAt,
			markup: this.markup,
			isEdited: this.isEdited,
			author: this.author,
			seenByUsers: this.seenByUsers,
			replies: this.replies,
			files: this.files
		} );
	}

	hasReplyWithID( replyID ) {
		return this.repliesIDs.includes( replyID );
	}
}
