/* eslint-disable @typescript-eslint/no-unused-vars */
import SubclassResponsabilityError from '../../customErrors/SubclassResponsabilityError';

export default class SubscriptionType {
	get allowsMemberInvitations() {
		throw new SubclassResponsabilityError();
	}

	get isFree() {
		throw new SubclassResponsabilityError();
	}

	addAddOnsWith( addOnsSystem ) {
		throw new SubclassResponsabilityError();
	}
}
