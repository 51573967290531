import { Map } from 'immutable';
import { handle } from 'redux-pack';
import { normalize } from 'normalizr';
import { handleSuccess, makeAsyncActionReducer, handleStart } from '../lib/reduxUtils';
import {
	FETCH_WORKSPACES, DELETE_WORKSPACE, EDIT_WORKSPACE, LOGOUT, EDIT_PROFILE,
	TRANSFER_WORKSPACE_OWNERSHIP, CREATE_SUBSCRIPTION,
	FETCH_WORKSPACE_INVITATIONS, DELETE_WORKSPACE_INVITATION, FETCH_WORKSPACE_INVOICES,
	FETCH_WORKSPACE_INVOICES_NEXT_PAGE, FETCH_WORKSPACE_MEMBERS, FETCH_WORKSPACE_MEMBER_COLLABORATORS,
	INVITE_WORKSPACE_MEMBER, UPDATE_WORKSPACE_MEMBER_ROLE, DELETE_WORKSPACE_MEMBER,
	FETCH_WORKSPACE_PROJECTS, FETCH_WORKSPACE_BILLING_INFORMATION, FETCH_WORKSPACE_PAYMENT_METHOD,
	UPDATE_WORKSPACE_BILLING_INFORMATION, UPDATE_WORKSPACE_PAYMENT_METHOD,
	TRANSFER_PROJECT_TO_WORKSPACE, DELETE_PROJECTS, LEAVE_WORKSPACE,
	CREATE_WORKSPACE, FETCH_WORKSPACE_LABELS, CREATE_WORKSPACE_LABEL,
	DELETE_WORKSPACE_LABEL, BUY_ADD_ON, REMOVE_ADD_ON
} from '../actions/types';
import { isNotFoundError } from '../errors/common';
import { normalizeResponse } from '../lib/normalizeUtils';
import { workspace as workspaceScheme } from './schemes';
import WorkspaceRole from '../entities/workspaceRole';

const normalizeWorkspaces = ( actionPayload, prevState ) => normalizeResponse(
	prevState, actionPayload, workspaceScheme, 'workspaces'
);

const normalizeWorkspace = workspaceProperties => (
	normalize( workspaceProperties, workspaceScheme )
		.entities
		.workspaces[ workspaceProperties.id ]
);

const getWorkspacePropertiesWithUpdatedOwner = ( workspaceProperties, newOwnerUser ) => ( {
	...workspaceProperties,
	owner: newOwnerUser
} );

const getWorkspacesPropertiesWithUpdatedOwner = ( workspaces, newOwnerUser ) => workspaces
	.map( workspaceProperties => ( workspaceProperties.owner.id === newOwnerUser.id
		? getWorkspacePropertiesWithUpdatedOwner( workspaceProperties, newOwnerUser )
		: workspaceProperties
	) );

const failedWorkspaceActionHandler = action => ( prevState ) => {
	const { workspaceID } = action.meta;
	const error = action.payload;

	return isNotFoundError( error )
		? prevState.delete( workspaceID )
		: prevState;
};

export const workspaces = ( state = Map(), action ) => {
	switch ( action.type ) {
	case FETCH_WORKSPACES:
		return handleSuccess(
			state,
			action,
			() => normalizeWorkspaces( action.payload, Map() )
		);
	case DELETE_WORKSPACE:
	case LEAVE_WORKSPACE:
		return handle(
			state,
			action,
			{
				success: ( prevState ) => {
					const { workspaceID } = action.meta;

					return prevState.delete( workspaceID );
				},
				failure: failedWorkspaceActionHandler( action )
			}
		);
	case EDIT_WORKSPACE:
		return handle(
			state,
			action,
			{
				success: ( prevState ) => {
					const newWorkspaceProperties = action.payload;
					const { workspaceID } = action.meta;
					const workspace = prevState.get( workspaceID );

					return workspace
						? prevState.set( workspaceID, normalizeWorkspace( newWorkspaceProperties ) )
						: prevState;
				},
				failure: failedWorkspaceActionHandler( action )
			}
		);
	case EDIT_PROFILE:
		return handleSuccess(
			state,
			action,
			( prevState ) => {
				const user = action.payload;

				return getWorkspacesPropertiesWithUpdatedOwner( prevState, user );
			} );
	case TRANSFER_WORKSPACE_OWNERSHIP:
		return handle(
			state,
			action,
			{
				success: ( prevState ) => {
					const { workspaceID, newOwner } = action.meta;

					return prevState.update(
						workspaceID,
						workspace => ( {
							...getWorkspacePropertiesWithUpdatedOwner( workspace, newOwner.user ),
							current_user_role: WorkspaceRole.admin()
						} )
					);
				},
				failure: failedWorkspaceActionHandler( action )
			}
		);
	case CREATE_SUBSCRIPTION:
		return handle(
			state,
			action,
			{
				success: ( prevState ) => {
					const newWorkspace = action.payload;
					return prevState.updateIfSet(
						newWorkspace.id,
						() => normalizeWorkspace( newWorkspace )
					);
				},
				failure: failedWorkspaceActionHandler( action )
			}
		);
	case CREATE_WORKSPACE:
		return handleSuccess(
			state,
			action,
			( prevState ) => {
				const workspace = action.payload;
				return prevState.set( workspace.id, normalizeWorkspace( workspace ) );
			}
		);
	case TRANSFER_PROJECT_TO_WORKSPACE:
		return handleSuccess( state, action, prevState => (
			prevState
				.updateIfSet(
					action.meta.oldTeamWorkspace,
					workspace => ( { ...workspace, projects_count: workspace.projects_count - 1 } )
				).updateIfSet(
					action.meta.newTeamWorkspace,
					workspace => ( { ...workspace, projects_count: workspace.projects_count + 1 } )
				)
		) );

	case DELETE_PROJECTS:
		return handleSuccess( state, action, ( prevState ) => {
			const { workspacesWithDeletedProjects } = action.meta;
			return workspacesWithDeletedProjects.reduce( ( newState, workspaceID ) => (
				newState.updateIfSet(
					workspaceID,
					workspace => ( { ...workspace, projects_count: workspace.projects_count - 1 } )
				)
			), prevState );
		} );
	case BUY_ADD_ON:
	case REMOVE_ADD_ON:
		return handleSuccess( state, action, ( prevState ) => {
			const workspace = action.payload;
			return prevState.updateIfSet(
				workspace.id,
				() => normalizeWorkspace( workspace )
			);
		} );
	case FETCH_WORKSPACE_LABELS:
		return handleSuccess( state, action, prevState => (
			prevState.updateIfSet(
				action.meta.workspaceID,
				workspace => ( { ...workspace, labels: action.payload.map( label => label.id ) } )
			)
		) );
	case CREATE_WORKSPACE_LABEL:
		return handleSuccess( state, action, ( prevState ) => {
			const { labellable_id: labellableID, id } = action.payload;
			return (
				prevState.updateIfSet(
					labellableID,
					workspace => ( { ...workspace, labels: [ ...workspace.labels, id ] } )
				)
			);
		} );
	case DELETE_WORKSPACE_LABEL:
		return handleSuccess( state, action, prevState => prevState.updateIfSet(
			action.meta.labellableID,
			workspace => ( {
				...workspace,
				labels: workspace.labels.filter( id => id !== action.meta.labelID )
			} )
		) );
	case FETCH_WORKSPACE_INVITATIONS:
	case DELETE_WORKSPACE_INVITATION:
	case FETCH_WORKSPACE_INVOICES:
	case FETCH_WORKSPACE_INVOICES_NEXT_PAGE:
	case FETCH_WORKSPACE_MEMBERS:
	case FETCH_WORKSPACE_MEMBER_COLLABORATORS:
	case UPDATE_WORKSPACE_MEMBER_ROLE:
	case DELETE_WORKSPACE_MEMBER:
	case FETCH_WORKSPACE_PROJECTS:
	case FETCH_WORKSPACE_BILLING_INFORMATION:
	case FETCH_WORKSPACE_PAYMENT_METHOD:
	case UPDATE_WORKSPACE_BILLING_INFORMATION:
	case UPDATE_WORKSPACE_PAYMENT_METHOD:
		return handle(
			state,
			action,
			{ failure: failedWorkspaceActionHandler( action ) }
		);
	case INVITE_WORKSPACE_MEMBER:
		return handle(
			state,
			action,
			{
				success: ( prevState ) => {
					const workspace = action.payload;
					return prevState.updateIfSet(
						workspace.id,
						() => normalizeWorkspace( workspace )
					);
				},
				failure: failedWorkspaceActionHandler( action )
			}
		);
	case LOGOUT:
		return handleStart( state, action, () => Map() );
	default:
		return state;
	}
};

export const editWorkspaceRequest = makeAsyncActionReducer( EDIT_WORKSPACE );
