import { forwardRef, isValidElement } from 'react';
import Img from 'react-image';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from '../../new_arch/components/Icon/Icon';
import FloatingBox from '../FloatingBox/FloatingBox';
import { PRIMARY_BASE } from '../../styles/colors';

import './FloatingMenu.scss';

/* eslint-disable react/no-array-index-key */

const FloatingMenu = forwardRef( ( {
	items, showArrow, onItemClick, className, footer, style
}, ref ) => {
	const renderItem = item => (
		<>
			{item.icon
			&& (
				<Icon
					className={item.className || 'item-icon'}
					icon={item.icon}
					size={item.size || 15}
					color={item.color || PRIMARY_BASE}
				/>
			)}
			{item.image
			&& (
				<Img
					src={item.image}
					className="img_style"
				/>
			)}
			<div className="name-description">
				<h5 style={{ color: item.textColor }}>{item.name || item}</h5>
				<p>{item.description}</p>
			</div>
			{item.badge
			&& (
				<div className={classNames( 'badge', { danger: item.badge.dangerStyle } )}>
					{item.badge.text}
				</div>
			)}
		</>
	);

	return (
		<FloatingBox style={style} showArrow={showArrow} className={className}>
			<ul ref={ref} className="FloatingMenu">
				{items.map( ( item, index ) => (
					<>
						{item.startsNewGroup && index > 0 && <div className="group-divider" />}
						<li key={index}>
							<button
								className={classNames( item.classNames, {
									with_description: !!item.description,
									grey_out: item.greyOut
								} )}
								onClick={event => onItemClick( index, event )}
								disabled={item.greyOut}
							>
								{isValidElement( item ) ? item : renderItem( item )}
							</button>
						</li>
					</>
				) )}
			</ul>
			{isValidElement( footer )
				&& <div>{footer}</div>}
		</FloatingBox>
	);
} );

FloatingMenu.propTypes = {
	items: PropTypes.arrayOf( PropTypes.oneOfType( [
		PropTypes.shape( {
			name: PropTypes.string,
			description: PropTypes.string,
			icon: PropTypes.string,
			color: PropTypes.string,
			size: PropTypes.number,
			image: PropTypes.string,
			classNames: PropTypes.string,
			textColor: PropTypes.string
		} ),
		PropTypes.node
	] ) ),
	showArrow: PropTypes.bool,
	onItemClick: PropTypes.func,
	className: PropTypes.string,
	footer: PropTypes.node,
	style: PropTypes.any
};

FloatingMenu.defaultProps = {
	items: [],
	showArrow: true,
	onItemClick: () => {},
	className: undefined,
	footer: undefined,
	style: {}
};

FloatingMenu.displayName = 'FloatingMenu';

export default FloatingMenu;
