export default class EmailValidator {
	constructor() {
		this.regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	}

	validate( value, label, errors ) {
		const { regex } = this;

		if ( value && regex.test( value ) ) { return true; }
		errors.push( `${label} is invalid.` );
		return false;
	}
}
