import { Map } from 'immutable';
import { handleSuccess, makeAsyncActionReducer } from '../lib/reduxUtils';
import { normalizeResponse } from '../lib/normalizeUtils';
import { projectMember as projectMemberScheme } from './schemes';

import {
	FETCH_PROJECT_MEMBERS, INVITE_PROJECT_MEMBER,
	DELETE_PROJECT_MEMBER, TRANSFER_OWNERSHIP, LEAVE_PROJECTS
} from '../actions/types';
import ProjectRole from '../entities/projectRole';

export const inviteProjectMemberRequest = makeAsyncActionReducer( INVITE_PROJECT_MEMBER );

const updateMemberRole = ( member, role ) => ( { ...member, role } );

const memberRoleUpdaterFor = newRole => projectMemberProperties => updateMemberRole(
	projectMemberProperties,
	newRole
);

const normalizeProjectMembers = ( prevState, actionPayload ) => normalizeResponse(
	prevState, actionPayload, projectMemberScheme, 'projectMembers'
);

export const projectsMembers = ( state = new Map(), action ) => {
	switch ( action.type ) {
	case FETCH_PROJECT_MEMBERS:
		return handleSuccess(
			state,
			action,
			( prevState ) => {
				const { projectID } = action.meta;

				return normalizeProjectMembers(
					prevState.filter( member => member.project_id !== projectID ),
					action.payload.map( member => ( { ...member, project_id: projectID } ) )
				);
			}
		);
	case TRANSFER_OWNERSHIP:
		return handleSuccess(
			state,
			action,
			( prevState ) => {
				const { prevOwnerID, newOwnerID } = action.meta;

				if ( !prevState.has( prevOwnerID ) || !prevState.has( newOwnerID ) ) {
					return prevState;
				}

				return prevState
					.update(
						prevOwnerID,
						memberRoleUpdaterFor( ProjectRole.projectCollaborator().id )
					)
					.update(
						newOwnerID,
						memberRoleUpdaterFor( ProjectRole.owner().id )
					);
			}
		);
	case LEAVE_PROJECTS:
		return handleSuccess(
			state,
			action,
			( prevState ) => {
				const { memberIDs } = action.meta;
				return prevState.filter( member => !memberIDs.includes( member.id ) );
			}
		);
	case DELETE_PROJECT_MEMBER:
		return handleSuccess(
			state,
			action,
			( prevState ) => {
				const { memberID } = action.meta;
				return prevState.delete( memberID );
			}
		);
	default:
		return state;
	}
};
