import Form from '../lib/forms/Form';
import Field from '../lib/forms/Field';
import EmailValidator from '../lib/forms/validators/EmailValidator';
import MinWordCount from '../lib/forms/validators/MinWordCount';
import { IsFileValidator } from '../lib/forms/validators';

export const createForm = values => new Form( [
	new Field( 'name', 'Name', values.name, [ new MinWordCount( 2 ) ] ),
	new Field( 'email', 'Email', values.email, [ new EmailValidator() ] ),
	new Field( 'avatar', 'Avatar', values.avatar, [ new IsFileValidator() ] )
] );
