import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Workspace from '../../entities/workspace';
import { connectComponent } from '../../lib/connectComponent';
import { getWorkspace } from '../../selectors/workspaces';
import WorkspaceOverviewPanel from '../../components/WorkspaceOverviewPanel/WorkspaceOverviewPanel';
import useTabsWithRoutes from '../../hooks/useTabsWithRoutes';
import WorkspaceProjectsTab from '../../components/WorkspaceProjectsTab/WorkspaceProjectsTab';
import LeaveWorkspaceTabContainer
	from '../LeaveWorkspaceTabContainer/LeaveWorkspaceTabContainer';

export const OTHER_WORKSPACE_PANEL_TABS = [
	{
		name: 'Projects', component: WorkspaceProjectsTab, key: 'projects'
	},
	{
		name: 'General', component: LeaveWorkspaceTabContainer, key: 'general'
	}
];

export const WorkspaceOverviewPanelContainer = ( {
	workspace, fetchWorkspaceProjects
} ) => {
	const [ tabs, selectedTabIndex, onTabChanged ] = useTabsWithRoutes(
		OTHER_WORKSPACE_PANEL_TABS, 'section', `/user/workspaces/${workspace.id}`
	);
	useEffect( () => {
		if ( workspace?.id ) {
			fetchWorkspaceProjects( workspace.id );
		}
	}, [ workspace.id ] );

	return (
		<WorkspaceOverviewPanel
			workspace={workspace}
			tabs={tabs}
			selectedTabIndex={selectedTabIndex}
			onTabChange={onTabChanged}
		/>
	);
};

WorkspaceOverviewPanelContainer.propTypes = {
	workspace: PropTypes.instanceOf( Workspace ),
	fetchWorkspaceProjects: PropTypes.func
};

WorkspaceOverviewPanelContainer.defaultProps = {
	workspace: undefined,
	fetchWorkspaceProjects: () => {}
};

export default connectComponent( ( state, props ) => ( {
	workspace: getWorkspace( state, props )
} ) )( WorkspaceOverviewPanelContainer );
