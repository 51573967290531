import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import Button from '../../../new_arch/components/Button/Button';
import MembersUsageContainer from '../../../containers/MembersUsageContainer/MembersUsageContainer';
import StorageUsageContainer from '../../../containers/StorageUsageContainer/StorageUsageContainer';
import Workspace from '../../../entities/workspace';

import './SubscriptionDetailPaidPlanFooter.scss';

const SubscriptionDetailPaidPlanFooter = ( {
	workspace,
	onUpgradePlanButton,
	onCancelSubscriptionClick,
	showCancelSubscriptionButton
} ) => (
	<div className="SubscriptionDetailPaidPlanFooter">
		<MembersUsageContainer
			workspace={workspace}
			onPaidFeatureModalAcceptClick={onUpgradePlanButton}
		/>
		<StorageUsageContainer
			workspace={workspace}
			onPaidFeatureModalAcceptClick={onUpgradePlanButton}
		/>
		<div className="button-container">
			{
				showCancelSubscriptionButton && (
					<Button
						type="text"
						color="danger"
						onClick={onCancelSubscriptionClick}
					>
						Cancel Subscription
					</Button>
				)
			}
		</div>
	</div>
);

SubscriptionDetailPaidPlanFooter.propTypes = {
	workspace: PropTypes.instanceOf( Workspace ).isRequired,
	onUpgradePlanButton: PropTypes.func.isRequired,
	onCancelSubscriptionClick: PropTypes.func.isRequired,
	showCancelSubscriptionButton: PropTypes.bool.isRequired
};

export default observer( SubscriptionDetailPaidPlanFooter );
