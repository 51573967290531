import { createSelector } from 'reselect';
import Asset from '../entities/asset';
import Folder from '../entities/folder';
import SharedProject from '../entities/sharedProject';
import { getAssetRounds } from './assetRounds';
import { getAssetID } from './assets';

const getRawSharedProjectAssets = state => state.sharedProjectAssets;
const getRawSharedProjects = state => state.sharedProjects;

const getRawSharedProjectFolders = state => state.sharedProjectFolders;

const getAssets = createSelector(
	[
		getRawSharedProjectAssets,
		getAssetRounds
	],
	( assets, assetsRounds ) => assets?.map(
		assetSerialization => Asset.fromJSON( assetSerialization ).withCurrentRound(
			assetsRounds.get( assetSerialization.version_id ) ) ).toArray()
);

export const getSharedProjectAsset = createSelector(
	[ getAssets, getAssetID ],
	( assets, assetID ) => assets?.find( asset => asset.id === assetID )
);

const getShareRef = ( _, props ) => props.shareRef;

const getSharedFolders = createSelector(
	[ getRawSharedProjectFolders ],
	folders => folders.toArray().map( Folder.fromJSON )
);

export const getSharedEntity = createSelector(
	[
		getRawSharedProjects,
		getRawSharedProjectFolders,
		getShareRef
	],
	( sharedProjectStore, sharedFolderStore, shareRef ) => (
		shareRef?.getReferencedEntityFromStores( { sharedProjectStore, sharedFolderStore } )
	)
);

export const getSharedEntityAssets = createSelector(
	[
		getSharedEntity,
		getAssets
	],
	( sharedEntity, assets ) => assets.filter( asset => sharedEntity?.containsAsset( asset ) )
);

export const getSharedEntityImages = createSelector(
	[ getSharedEntityAssets ],
	assets => assets.filter( asset => asset.isImage )
);

export const getSharedEntityDocuments = createSelector(
	[ getSharedEntityAssets ],
	assets => assets.filter( asset => asset.isDocument )
);

export const getSharedEntityFolders = createSelector(
	[
		getSharedEntity,
		getSharedFolders
	],
	( sharedEntity, folders ) => folders
		.filter( folder => sharedEntity?.containsFolder( folder ) )
		.sort( ( a, b ) => a.name.localeCompare( b.name ) )
)

const findAssetIndex = ( assetsArray, assetID ) => assetsArray.findIndex( asset => asset.id === assetID );

export const getNextAssetID = createSelector(
	[ getSharedEntityImages, getAssetID ],
	( assets, assetID ) => {
		const assetIndex = findAssetIndex( assets, assetID );

		return assetIndex !== -1 && assetIndex < assets.length - 1
			? assets[ assetIndex + 1 ].id : undefined;
	}
);

export const getPreviousAssetID = createSelector(
	[ getSharedEntityImages, getAssetID ],
	( assets, assetID ) => {
		const assetIndex = findAssetIndex( assets, assetID );

		return assetIndex !== -1 && assetIndex > 0
			? assets[ assetIndex - 1 ].id : undefined;
	}
);

export const getSharedEntityAncestors = createSelector(
	[ getSharedEntity, getRawSharedProjectFolders, getRawSharedProjects ],
	( sharedEntity, sharedFolders, sharedProjects ) => {
		if ( !sharedEntity || sharedEntity.isProject ) return [];

		const ancestors = [];
		let currentFolder = sharedEntity;

		while ( currentFolder.organizerType === 'Folder' ) {
			const rawAncestor = sharedFolders.get( currentFolder.organizerID );
			if ( !rawAncestor ) break;
			const ancestor = Folder.fromJSON( rawAncestor );
			ancestors.unshift( ancestor );
			currentFolder = ancestor;
		}

		if ( currentFolder.organizerType === 'Project' ) {
			const rawProject = sharedProjects.find( project => project.id === currentFolder.organizerID );
			if ( rawProject ) ancestors.unshift( SharedProject.fromJSON( rawProject ) );
		}

		return ancestors;
	}
)
