import './AssetDownloadBoxFooter.scss';

type AssetDownloadBoxFooterProps = {
	onCancelAllClicked: () => void
}

const AssetDownloadBoxFooter = ( { onCancelAllClicked }: AssetDownloadBoxFooterProps ) => (
	<div className="AssetDownloadBoxFooter">
		<button
			type="button"
			className="cancel-all-button"
			onClick={onCancelAllClicked}
		>
			Cancel All
		</button>
	</div>
);

export default AssetDownloadBoxFooter;
