import { reaction } from 'mobx';
import StorageService from '../services/StorageService';
import { RootStore } from './RootStore';
import { RootStoreSerialization } from './types';

export const STORAGE_KEY = '__ROOT_STORE_0.0.0__';
export const PERSIST_DELAY = 1000;

export default class RootStorePersistor {
	rootStore: RootStore;
	storage: StorageService;

	constructor(
		{ rootStore, storage }: { rootStore: RootStore, storage: StorageService }
	) {
		this.rootStore = rootStore;
		this.storage = storage;

		reaction(
			() => this.rootStore.serializationToPersist(),
			() => this.persist(),
			{
				delay: PERSIST_DELAY
			}
		);
	}

	persist() {
		const serialization = JSON.stringify( this.rootStore.serializationToPersist() );
		this.storage.setItem( STORAGE_KEY, serialization, { expirationInDays: 1 } );
	}

	hydrate() {
		const serialization = JSON.parse( this.storage.getItem( STORAGE_KEY ) || '{}' );
		try {
			this.rootStore.rehydrateWithSerialization( serialization as RootStoreSerialization );
		} catch {
			this.storage.removeItem( STORAGE_KEY );
		}
	}
}
