type GuestHashes = Record<number, string>;

export const STORAGE_KEY_FOR_PROJECT_GUEST_HASHES = 'projectGuestHashes';

export default class GuestHashStore {
	localStorage: Storage;

	constructor( { localStorage = window.localStorage }: { localStorage?: Storage } = {} ) {
		this.localStorage = localStorage;
	}

	getProjectGuestHash( projectID: number ) {
		return this.getProjectGuestHashes()[ projectID ] as string | undefined;
	}

	setProjectGuestHash( projectID: number, guestHash: string ) {
		this.setProjectGuestHashes( {
			...this.getProjectGuestHashes(),
			[ projectID ]: guestHash
		} );
	}

	private getProjectGuestHashes() {
		return JSON.parse(
			this.localStorage.getItem( STORAGE_KEY_FOR_PROJECT_GUEST_HASHES ) || '{}'
		) as GuestHashes;
	}

	private setProjectGuestHashes( guestHashes: GuestHashes ) {
		this.localStorage.setItem(
			STORAGE_KEY_FOR_PROJECT_GUEST_HASHES,
			JSON.stringify( guestHashes )
		);
	}
}
