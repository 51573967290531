import { isErrorWithStatus } from '.';
import { invalidUserErrorMessage, limitReachedErrorMessage, defaultErrorMessage } from '../messages/projectInvitation';

export const isInvalidUserError = isErrorWithStatus( 400, 'invalid_invites' );
export const isForbiddenError = isErrorWithStatus( 403, 'forbidden_invites' );

export const messageForError = ( error ) => {
	if ( isInvalidUserError( error ) ) {
		const invalid_emails = error.data.meta;
		return invalidUserErrorMessage( invalid_emails.first );
	}
	if ( isForbiddenError( error ) ) {
		return limitReachedErrorMessage;
	}
	return defaultErrorMessage;
};
