import PropTypes from 'prop-types';
import VeryBusyLogo from '../VeryBusyLogo/VeryBusyLogo';

import './MobileWarning.scss';

const MobileWarning = ( { Content } ) => (
	<div className="MobileWarning">
		<div className="warning-text">
			<h1>
				Hi!
				<span role="img" aria-hidden aria-label="">👋</span>
			</h1>
			<h2 data-testid="content">
				<Content />
			</h2>
		</div>
		<VeryBusyLogo />
	</div>
);

MobileWarning.propTypes = {
	Content: PropTypes.node
};

MobileWarning.defaultProps = {
	Content: null
};

export default MobileWarning;
