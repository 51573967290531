import { connectComponent } from '../../lib/connectComponent';
import { getProject } from '../../selectors/projects';
import { getMainAssetsUploadsForProject } from '../../selectors/assetUploads';
import { getMainFolderUploadsForProject } from '../../selectors/folderUploads';
import ProjectAssetUploader from '../../components/ProjectAssetUploader/ProjectAssetUploader';

export default connectComponent( ( state, props ) => ( {
	project: getProject( state, props ),
	projectAssetUploads: getMainAssetsUploadsForProject( state, props ),
	projectFolderUploads: getMainFolderUploadsForProject( state, props )
} ) )( ProjectAssetUploader );
