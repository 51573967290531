export default class AbortablePromise {
	constructor( { promise, onAbort, aborted = false } ) {
		this.promise = promise;
		this.onAbort = onAbort;
		this.aborted = aborted;
	}

	get [ Symbol.toStringTag ]() {
		return 'AbortablePromise';
	}

	then( ...params ) {
		return this._applyHandler( 'then', ...params );
	}

	catch( ...params ) {
		return this._applyHandler( 'catch', ...params );
	}

	finally( ...params ) {
		return this._applyHandler( 'finally', ...params );
	}

	abort() {
		if ( !this.aborted ) this.onAbort();
	}

	_applyHandler( handlerName, ...handlerParams ) {
		return new AbortablePromise( {
			promise: this.promise[ handlerName ]( ...handlerParams ),
			onAbort: this.onAbort,
			aborted: this.aborted
		} );
	}
}
