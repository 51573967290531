export default class BreadcrumbLevel {
	constructor( {
		level, history, next
	} ) {
		this.next = next;
		this._level = level;
		this._history = history;
	}

	get name() {
		return this._level.name;
	}

	get levelUrl() {
		return this._level.url;
	}

	get isClickable() {
		return !this.isLastLevel;
	}

	get isLastLevel() {
		return !this.next;
	}

	get searchParams() {
		return this._history.location.search ?? '';
	}

	onClick = () => {
		if ( !this.isClickable ) return null;
		if ( this._isSecondToLastLevel && this._canGoBack ) return this._history.goBack();
		return this._history.replace( this._level.url + this.searchParams );
	}

	get _canGoBack() {
		if ( !this._history.previousLocation ) return false;
		return this._history.previousLocation.pathname === this._level.url;
	}

	get _isSecondToLastLevel() {
		return this.next?.isLastLevel;
	}
}
