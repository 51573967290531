import { useState } from 'react';
import { useDispatch } from 'react-redux';
import WorkspaceSystem from '../../systems/WorkspaceSystem';
import CreateOrEditContactGroupModalPresenter, { type CreateOrEditContactGroupModalPresenterParams } from './CreateOrEditContactGroupModalPresenter';
import SnackbarSystem from '../../systems/SnackbarSystem';

const useCreateOrEditContactGroupModalPresenter = ( {
	workspaceID, mode, contactGroup, onClose
}: Omit<CreateOrEditContactGroupModalPresenterParams, 'workspaceSystem' | 'snackbarSystem'> ) => {
	const dispatch = useDispatch();
	const workspaceSystem = new WorkspaceSystem( dispatch );
	const snackbarSystem = new SnackbarSystem( dispatch );

	const createPresenter = () => new CreateOrEditContactGroupModalPresenter( {
		workspaceID,
		mode,
		contactGroup,
		onClose,
		workspaceSystem,
		snackbarSystem
	} );

	const [ presenter ] = useState( createPresenter );

	return presenter;
};

export default useCreateOrEditContactGroupModalPresenter;
