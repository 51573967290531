/* eslint-disable camelcase */
import { Map } from 'immutable';
import { makeAsyncActionReducer, handleSuccess, mapForPayload } from '../lib/reduxUtils';
import { FETCH_SHARED_FOLDER, FETCH_SHARED_PROJECT } from '../actions/types';

export const fetchSharedProjectRequest = makeAsyncActionReducer( FETCH_SHARED_PROJECT );
export const fetchSharedFolderRequest = makeAsyncActionReducer( FETCH_SHARED_FOLDER );

export const sharedProjects = ( state = new Map(), action ) => {
	switch ( action.type ) {
	case FETCH_SHARED_PROJECT:
		return handleSuccess( state, action, ( prevState ) => {
			const {
				id, share_hash, name, allows_downloads
			} = action.payload;
			return prevState.set( share_hash, {
				id, name, share_hash, allows_downloads
			} );
		} );
	default:
		return state;
	}
};

export const sharedProjectAssets = ( state = new Map(), action ) => {
	switch ( action.type ) {
	case FETCH_SHARED_FOLDER:
	case FETCH_SHARED_PROJECT:
		return handleSuccess(
			state,
			action,
			() => mapForPayload( action.payload.assets )
		);
	default:
		return state;
	}
};

export const sharedProjectFolders = ( state = new Map(), action ) => {
	switch ( action.type ) {
	case FETCH_SHARED_FOLDER:
		return handleSuccess(
			state,
			action,
			prevState => prevState
				.set( action.payload.id, action.payload )
				.merge( mapForPayload( action.payload.folders ) )
		);
	case FETCH_SHARED_PROJECT:
		return handleSuccess(
			state,
			action,
			prevState => prevState
				.replaceMatchingWithPayload( action.payload.folders, action.payload.id, 'project_id' )
		);
	default:
		return state;
	}
};

export const sharedProjectFolder = ( state = {}, action ) => {
	switch ( action.type ) {
	case FETCH_SHARED_FOLDER:
		return handleSuccess(
			state,
			action,
			() => action.payload
		);
	default:
		return state;
	}
};
