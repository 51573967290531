import PropTypes from 'prop-types';
import { List } from 'immutable';
import { Line as KonvaLine } from 'react-konva';
import tinycolor from 'tinycolor2';

const MarkupCurve = ( {
	curve, scale, opacity, ...props
} ) => (
	<KonvaLine
		points={curve.points.map(
			point => List.of( point.get( 'x' ) * scale.x, point.get( 'y' ) * scale.y )
		).flatten().toJS()}
		stroke={tinycolor( curve.strokeColor ).setAlpha( opacity ).toRgbString()}
		strokeWidth={curve.strokeSize || 1}
		hitStrokeWidth={20}
		perfectDrawEnabled={false}
		lineJoin="round"
		lineCap="round"
		// We apply this condition because the line is not displayed at all
		// if curve has 2 or less points and bezier is true
		bezier={curve.points.size > 2}
		{...props}
	/>
);

MarkupCurve.propTypes = {
	curve: PropTypes.any.isRequired,
	scale: PropTypes.any.isRequired,
	opacity: PropTypes.number
};

MarkupCurve.defaultProps = {
	opacity: 1
};

export default MarkupCurve;
