import moment from 'moment';
import ShareRef from '../structures/shareRef';
import User from './user';

export const OrganizerType = {
	project: 'Project',
	folder: 'Folder'
};

export default class Folder {
	constructor( {
		id,
		name = '',
		organizerID = null,
		organizerType = 'Project',
		imagesCount = 0,
		topLevelImagesCount = 0,
		documentsCount = 0,
		topLevelFoldersCount = 0,
		filteredAssetsCount = 0,
		coverUrl = null,
		currentUserIsOwner = false,
		projectID,
		ancestors = null,
		hasNewActivity = false,
		subfoldersWithNewActivityCount = 0,
		lastActivityAt,
		shareHash,
		shareHashDownloadable,
		sharedHash,
		allowsDownloads,
		unresolvedCommentsCount = 0,
		storageUsedInBytes = 0,
		createdAt,
		lastModifier,
		owner
	} ) {
		this.id = id;
		this.name = name;
		this.organizerID = organizerID;
		this.organizerType = organizerType;
		this.imagesCount = imagesCount;
		this.topLevelImagesCount = topLevelImagesCount;
		this.documentsCount = documentsCount;
		this.topLevelFoldersCount = topLevelFoldersCount;
		this.filteredAssetsCount = filteredAssetsCount;
		this.coverUrl = coverUrl;
		this.currentUserIsOwner = currentUserIsOwner;
		this.projectID = projectID;
		this.ancestors = ancestors;
		this.hasNewActivity = hasNewActivity;
		this.subfoldersWithNewActivityCount = subfoldersWithNewActivityCount;
		this.lastActivityAt = lastActivityAt;
		this.shareHash = shareHash;
		this.shareHashDownloadable = shareHashDownloadable;
		this.sharedHash = sharedHash;
		this.allowsDownloads = allowsDownloads;
		this.unresolvedCommentsCount = unresolvedCommentsCount;
		this.storageUsedInBytes = storageUsedInBytes;
		this.createdAt = createdAt;
		this.lastModifier = lastModifier;
		this.owner = owner;
	}

	static fromJSON( json ) {
		return new Folder( {
			...json,
			organizerID: json.organizer_id,
			organizerType: json.organizer_type,
			imagesCount: json.images_count,
			topLevelImagesCount: json.top_level_images_count,
			documentsCount: json.documents_count,
			topLevelFoldersCount: json.top_level_folders_count,
			filteredAssetsCount: json.filtered_assets_count,
			coverUrl: json.cover_url,
			currentUserIsOwner: json.current_user_is_owner,
			projectID: json.project_id,
			ancestors: json.ancestors?.map( Folder.fromJSON ),
			hasNewActivity: json.has_new_activity,
			subfoldersWithNewActivityCount: json.subfolders_with_new_activity_count,
			lastActivityAt: json.last_activity_at && new Date( json.last_activity_at ),
			lastModifier: json.last_activity_by && User.fromJSON( json.last_activity_by ),
			shareHash: json.share_hash,
			shareHashDownloadable: json.share_hash_downloadable,
			sharedHash: json.shared_hash,
			allowsDownloads: json.allows_downloads,
			unresolvedCommentsCount: json.unresolved_comments_count,
			storageUsedInBytes: json.storage_used_in_bytes,
			createdAt: json.created_at && new Date( json.created_at ),
			owner: json.owner && User.fromJSON( json.owner )
		} );
	}

	toJSON() {
		return {
			id: this.id,
			name: this.name,
			organizer_id: this.organizerID,
			organizer_type: this.organizerType,
			images_count: this.imagesCount,
			top_level_images_count: this.topLevelImagesCount,
			documents_count: this.documentsCount,
			top_level_folders_count: this.topLevelFoldersCount,
			filtered_assets_count: this.filteredAssetsCount,
			cover_url: this.coverUrl,
			current_user_is_owner: this.currentUserIsOwner,
			project_id: this.projectID,
			has_new_activity: this.hasNewActivity,
			subfolders_with_new_activity_count: this.subfoldersWithNewActivityCount,
			last_activity_at: this.lastActivityAt?.toISOString(),
			share_hash: this.shareHash,
			share_hash_downloadable: this.shareHashDownloadable,
			shared_hash: this.sharedHash,
			allows_downloads: this.allowsDownloads,
			unresolved_comments_count: this.unresolvedCommentsCount,
			storage_used_in_bytes: this.storageUsedInBytes,
			created_at: this.createdAt?.toISOString(),
			last_activity_by: this.lastModifier?.toJSON(),
			owner: this.owner?.toJSON()
		};
	}

	get selectionID() {
		return `folder-${this.id}`;
	}

	get assetsCount() {
		return this.imagesCount + this.documentsCount;
	}

	get totalAssetsCount() {
		return this.assetsCount;
	}

	get topLevelAssetsCount() {
		return this.topLevelImagesCount;
	}

	get topLevelPreviewablesCount() {
		return this.topLevelAssetsCount + this.topLevelFoldersCount;
	}

	assetsCountDescription( { useFilteredCount = false } = {} ) {
		const assetsCount = useFilteredCount ? this.filteredAssetsCount : this.imagesCount;
		return `${assetsCount} ${'asset'.pluralize( assetsCount )}`;
	}

	get createdAtToDisplay() {
		return this._formatDateIfPresent( this.createdAt, 'MM/DD/YY' );
	}

	get lastActivityToDisplay() {
		return this._formatDateIfPresent( this.lastActivityAt, 'MM/DD, h:mmA' );
	}

	get url() {
		return `/projects/${this.projectID}/folders/${this.id}`;
	}

	get type() {
		return OrganizerType.folder;
	}

	get canHoldItems() {
		return true;
	}

	get canBeManuallySorted() {
		return false;
	}

	get isInsideFolder() {
		return this.organizerType === OrganizerType.folder;
	}

	get hasNewActivityItselfOrInSubfolders() {
		return this.hasNewActivity || this.subfoldersWithNewActivityCount > 0;
	}

	shareLink( { withDownloads } ) {
		const shareHash = withDownloads ? this.shareHashDownloadable : this.shareHash;
		return `folders/shared_view/${shareHash}`;
	}

	get shareViewPath() {
		return ShareRef.forFolder( this.sharedHash ).appPath;
	}

	belongsToOrganizer( { organizerID, organizerType } ) {
		return this.organizerID === organizerID && this.organizerType === organizerType;
	}

	fetchAssetsWith( system ) {
		return system.fetchAssetsForFolder( this );
	}

	async fetchFoldersWith( system ) {
		return system.fetchFoldersForFolder( this );
	}

	async fetchOverviewWith( system ) {
		return system.fetchOverviewForFolder( this );
	}

	async fetchInformationWith( system ) {
		return system.fetchInformationForFolder( this );
	}

	containsAsset( asset ) {
		return asset.folderID === this.id;
	}

	containsFolder( folder ) {
		return folder.belongsToOrganizer( { organizerID: this.id, organizerType: 'Folder' } );
	}

	get hasUnresolvedComments() {
		return this.unresolvedCommentsCount > 0;
	}

	get formattedStoragedUsed() {
		return this.storageUsedInBytes.formatBytes();
	}

	get isFolder() {
		return true;
	}

	get isProject() {
		return false;
	}

	get folderID() {
		return this.isInsideFolder ? this.organizerID : null;
	}

	_formatDateIfPresent( date, format ) {
		return date ? moment( date ).format( format ) : '';
	}
}
