import { List } from 'immutable';
import Drawable from './drawable';
import ShapeRenderer from '../lib/shapeRenderer';
import Point from './point';

class Line extends Drawable {
	constructor( startPoint, endPoint, strokeColor, strokeSize, isRelative ) {
		super( strokeColor, strokeSize, isRelative );

		this._startPoint = startPoint;
		this._endPoint = endPoint;
	}

	static fromJSON( properties ) {
		return new this(
			new Point( {
				x: properties.position_x,
				y: properties.position_y
			} ),
			new Point( {
				x: properties.end_point_x,
				y: properties.end_point_y
			} ),
			properties.stroke_color,
			properties.stroke_size,
			properties.is_relative
		);
	}

	toJSON() {
		return {
			type: 'straight_line',
			position_x: this.startPoint.x,
			position_y: this.startPoint.y,
			end_point_x: this.endPoint.x,
			end_point_y: this.endPoint.y,
			stroke_color: this.strokeColor,
			stroke_size: this.strokeSize
		};
	}

	get startPoint() { return this._startPoint; }

	get endPoint() { return this._endPoint; }

	get renderer() { return ShapeRenderer.forLine( this ); }

	get isValid() { return true; }

	get points() { return List.of( this._startPoint, this._endPoint ); }

	static fromPoint( point, strokeColor, strokeSize ) {
		return new this( point, point, strokeColor, strokeSize );
	}

	updateWith( point ) {
		return new this.constructor( this.startPoint, point, this.strokeColor, this.strokeSize );
	}

	convertToRelative( { width, height } ) {
		if ( this._isRelative || !width || !height ) return;

		const convertToRelative = this._toRelativeCoordsConverter( { width, height } );

		this._startPoint = convertToRelative( this._startPoint );
		this._endPoint = convertToRelative( this._endPoint );
		this._isRelative = true;
	}
}

export default Line;
