import { InviteWorkspaceMemberStrategy } from './InviteWorkspaceMemberStrategy';

export default class InviteSingleWorkspaceMemberStrategy extends InviteWorkspaceMemberStrategy {
	protected renderSuccessMessageWithEnoughSpace() {
		this.showSuccessMessage( {
			title: this.successMessageTitle,
			description: `${this.email} will receive an invitation to join as a workspace member.`
		} );
	}

	protected renderSuccessMessageWithoutEnoughSpace() {
		this.showSuccessMessage( {
			title: this.successMessageTitle,
			description: `You've successfully added 1 seat and invited ${this.email} to your workspace.`
		} );
	}

	protected renderAlreadyInvitedErrorMessage() {
		this.showErrorMessage( {
			title: this.errorMessageTitle,
			description: `The email: ${this.email} has already been invited to your workspace.`
		} );
	}

	protected renderDefaultErrorMessage() {
		this.showErrorMessage( {
			title: this.errorMessageTitle,
			description: `An error occurred while inviting ${this.email}. Please try again later.`
		} );
	}

	private get email() {
		return this.emails.first;
	}
}
