import PropTypes from 'prop-types';
import { connectComponent } from '../../lib/connectComponent';

import './Overlay.scss';

export const zIndexAboveOverlay = 16;
export const defaultZIndex = 'auto';

export const Overlay = ( { isShowingOverlay } ) => (
	isShowingOverlay ? <div className="Overlay" /> : null
);

Overlay.propTypes = {
	isShowingOverlay: PropTypes.bool.isRequired
};

export default connectComponent( state => ( {
	isShowingOverlay: state.isShowingOverlay
} ) )( Overlay );
