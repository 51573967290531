import { createRef, PureComponent } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import IconButton from '../UI/IconButton/IconButton';
import './AvatarPicker.scss';
import { ASSET_IMAGE_EXTENSIONS } from '../../entities/asset';
import { mapExtensionsForFileInput, readLocalFile } from '../../lib/fileUtils';
import defaultProfilePicture from '../../assets/img/default-profile-picture.png';
import { WHITE } from '../../styles/colors';

class AvatarPicker extends PureComponent {
	constructor( props ) {
		super( props );
		this.inputRef = createRef();
		this.state = { imagePreview: this.props.avatar };

		this._openImagePicker = this._openImagePicker.bind( this );
		this._onAvatarChange = this._onAvatarChange.bind( this );
	}

	get currentImage() {
		const { imagePreview } = this.state;
		const { avatar } = this.props;
		return imagePreview || avatar || defaultProfilePicture;
	}

	clear() {
		this.setState( { imagePreview: null } );
	}

	_openImagePicker() {
		if ( this.inputRef && this.inputRef.current ) {
			this.inputRef.current.click();
		}
	}

	_onAvatarChange( fileChangeEvent ) {
		const file = fileChangeEvent.target.files[ 0 ];
		this.props.onFileChange( file );
		readLocalFile( file ).then( ( imagePreview ) => {
			this.setState( { imagePreview } );
		} );
	}

	render() {
		const { currentImage } = this;
		const { disabled } = this.props;
		return (
			<div className={classNames( 'AvatarPicker', { disabled } )}>
				<IconButton
					onClick={this._openImagePicker}
					icon="camera_alt"
					size={40}
					color={WHITE}
				/>
				<img src={currentImage} alt="avatar" />
				<input
					onChange={this._onAvatarChange}
					ref={this.inputRef}
					type="file"
					accept={mapExtensionsForFileInput( ASSET_IMAGE_EXTENSIONS )}
				/>
			</div>
		);
	}
}

AvatarPicker.propTypes = {
	avatar: PropTypes.string,
	onFileChange: PropTypes.func,
	disabled: PropTypes.bool
};

AvatarPicker.defaultProps = {
	avatar: null,
	onFileChange: () => {},
	disabled: false
};

export default AvatarPicker;
