export type Role = { value: string, label: string };

export const ROLES : Role[] = [
	{ value: 'retoucher', label: 'Retoucher' },
	{ value: 'photographer', label: 'Photographer' },
	{ value: 'director/manager', label: 'Director/Manager' },
	{ value: 'other', label: 'Other' }
];

export const defaultRole : Role = { value: 'photographer', label: 'Photographer' };
