/**
 * Takes a map of assets and returns a new map, with the given assetIDs
 * setted as approved (or not, depending of the third argument)
 * @param {Map} initialState
 * @param {Array} assetIDs
 * @param {boolean} approval
 * @returns {Map} newState
 */
export const changeAssetsMapApproval = (
	initialState,
	assetIDs,
	approval = true
) => assetIDs.reduce(
	( newState, approvedAssetID ) => newState.update(
		approvedAssetID,
		asset => (
			{
				...asset,
				approve: approval ? 'confirmed' : 'pending'
			} )
	),
	initialState
);

/**
 * Returns an array of filtered assets by a given approval condition
 * @param {Asset[]|Map<id, Asset>} assets Array or Map with instances of Assets
 * that needs to be filtered
 * @param {number[]} selectedAssetsIDs Array with the selected assets_ids
 * @param {boolean} approvalCondition if set to `true`, will only return
 * the assets that are approved, `false` for non-approved.
 * @returns {number[]} filtered array ids with the given approval condition
 */
export const extractAssetsIDsWithApproval = ( assets, selectedAssetsIDs, approvalCondition ) => (
	selectedAssetsIDs
		.map( assetID => assets.get( assetID ) )
		.filter( asset => asset && asset.isApproved === approvalCondition )
		.map( asset => asset.id )
);
