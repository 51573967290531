import { makeAutoObservable } from 'mobx';
import type {
	AssetDownloadStatus,
	DownloadInitializer
} from './types';
import { DownloadEventData, MessagingService } from '../MessagingService/types';

export type AssetDownloadJobParams = {
	id: string,
	messagingService: MessagingService,
	downloadInitializer: DownloadInitializer
};

export default class AssetDownloadJob {
	id: string;
	messagingService: MessagingService;
	downloadInitializer: DownloadInitializer;

	status: AssetDownloadStatus;
	assetsZipped: number;
	totalAssets: number;
	isObservingDownload: boolean;

	static createAndObserve = ( params: AssetDownloadJobParams ) => {
		const downloadJob = new AssetDownloadJob( params );
		downloadJob.observeDownloadFromBackend();
		return downloadJob;
	}

	constructor( { id, messagingService, downloadInitializer }: AssetDownloadJobParams ) {
		this.id = id;
		this.messagingService = messagingService;
		this.downloadInitializer = downloadInitializer;

		this.status = 'unknown';
		this.assetsZipped = 0;
		this.totalAssets = 0;
		this.isObservingDownload = false;

		makeAutoObservable( this );
	}

	get hasFinished() {
		return this.status === 'successful' || this.status === 'failed' || this.status === 'canceled';
	}

	get progressPercent() {
		if ( this.status === 'unknown' || this.totalAssets === 0 ) return 0;

		return ( this.assetsZipped / this.totalAssets ) * 100;
	}

	get zippedAssetsCountText() {
		if ( this.status === 'successful' ) return `Finished ${this.assetsZipped}/${this.totalAssets}`;
		if ( this.status === 'failed' ) return 'Error Downloading';
		if ( this.status === 'canceled' ) return 'Canceled';

		return `Preparing ${this.assetsZipped}/${this.totalAssets}`;
	}

	observeDownloadFromBackend() {
		if ( this.isObservingDownload ) return;

		this.messagingService.subscribeToDownload( this.id, this.onDownloadEventReceived );
		this.isObservingDownload = true;
	}

	cancel() {
		this.status = 'canceled';
		this.stopObservingDownloadFromBackend();
	}

	private onDownloadEventReceived = ( data: DownloadEventData ) => {
		const { status, files_zipped, total_files, url } = data;

		this.status = this.mapToAssetDownloadStatus( status );
		this.assetsZipped = files_zipped;
		this.totalAssets = total_files;

		if ( this.hasFinished ) this.stopObservingDownloadFromBackend();
		if ( status === 'ready' ) this.downloadInitializer( url! );
	}

	private mapToAssetDownloadStatus( status: DownloadEventData[ 'status' ] ) {
		if ( status === 'ready' ) return 'successful';
		if ( status === 'error' ) return 'failed';

		return status;
	}

	private stopObservingDownloadFromBackend() {
		if ( !this.isObservingDownload ) return;

		this.messagingService.unsubscribeFromDownload( this.id );
	}
}
