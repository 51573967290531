import { makeAutoObservable } from 'mobx';

type LabelColor = { baseColor: string, hoverColor: string };

export type LabelModalPresenterParams = {
	initialName?: string,
	initialColor?: LabelColor,
	namesAlreadyUsed: string[],
	onSubmit: ( name: string, color: LabelColor ) => void
};

const DEFAULT_COLOR = { baseColor: '#FED2B2', hoverColor: '#FCBA8A' };

export default class LabelModalPresenter {
	name: string;
	color: LabelColor;
	namesAlreadyUsed: string[];
	onSubmitCallback: ( name: string, color: LabelColor ) => void;

	nameError: string;

	constructor( { initialName, initialColor, namesAlreadyUsed, onSubmit }: LabelModalPresenterParams ) {
		this.name = initialName || '';
		this.color = initialColor || DEFAULT_COLOR;
		this.namesAlreadyUsed = namesAlreadyUsed;
		this.onSubmitCallback = onSubmit;
		this.nameError = '';

		makeAutoObservable( this );
	}

	onNameChanged = ( newName: string ) => {
		this.name = newName;
		if ( this.namesAlreadyUsed.includes( newName ) ) {
			this.nameError = 'This label already exists.';
		} else {
			this.nameError = '';
		}
	}

	onColorChanged = ( newColor: LabelColor ) => {
		this.color = newColor;
	}

	get isSubmitButtonDisabled() {
		return !this.name || !this.color || !!this.nameError;
	}

	onSubmit = () => this.onSubmitCallback( this.name, this.color );
}
