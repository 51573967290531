import {
	createContext, ReactNode, useContext, useEffect, useState
} from 'react';
import { RootStore } from '../stores/RootStore';
import StorageService from '../services/StorageService';
import RootStorePersistor from '../stores/RootStorePersistor';

const rootStore: RootStore = new RootStore();

const RootStoreContext = createContext<RootStore>( rootStore );

interface RootStoreProviderProps {
	children: ReactNode,
	rootStore?: RootStore,
	fallback?: ReactNode
}

const RootStoreProvider = ( {
	children, rootStore: rootStoreParam, fallback = null
}: RootStoreProviderProps ) => {
	const [ isRehydratingStore, setIsRehydratingStore ] = useState( true );

	useEffect(
		() => {
			const storage = new StorageService( window.localStorage );
			const persistor = new RootStorePersistor( { rootStore, storage } );
			setIsRehydratingStore( true );

			function rehydrateRootStore() {
				persistor.hydrate();
				setIsRehydratingStore( false );
			}

			rehydrateRootStore();
		},
		[ rootStoreParam ]
	);

	return (
		<>
			{!isRehydratingStore ? (
				<RootStoreContext.Provider value={rootStoreParam || rootStore}>
					{children}
				</RootStoreContext.Provider>
			) : fallback}
		</>
	);
};

export const useRootStore = () => useContext( RootStoreContext );

export default RootStoreProvider;
