import { Map } from 'immutable';
import { handleSuccess } from '../lib/reduxUtils';
import {
	FETCH_WORKSPACES, FETCH_PRICING_PLANS, LOGOUT
} from '../actions/types';
import { normalizeResponse } from '../lib/normalizeUtils';
import {
	pricingPlanPrice as pricingPlanPriceScheme,
	pricingPlanPriceWithPlan as pricingPlanPriceWithPlanScheme
} from './schemes';

const normalizePricesFromPricingPlans = ( actionPayload, prevState ) => {
	const normalizedPayload = normalizeResponse(
		prevState,
		actionPayload.reduce(
			( prices, pricingPlan ) => [
				...prices,
				...pricingPlan.prices.map( price => ( { ...price, pricing_plan: pricingPlan.id } ) )
			],
			[]
		),
		pricingPlanPriceScheme,
		pricingPlanPriceScheme.key
	);
	return normalizedPayload.map( pricingPlanPrice => ( {
		...pricingPlanPrice,
		pricing_plan: prevState.get( pricingPlanPrice.id )?.pricing_plan
			?? pricingPlanPrice.pricing_plan
	} ) );
};

const normalizePricesFromWorkspaces = ( workspaces, prevState ) => {
	const subscriptions = workspaces
		.map( workspace => workspace.subscription )
		.filter( subscription => !!subscription );

	const pricingPlanPrices = subscriptions.map( subscription => subscription.pricing_plan_price );

	return normalizeResponse(
		prevState,
		pricingPlanPrices,
		pricingPlanPriceWithPlanScheme,
		pricingPlanPriceWithPlanScheme.key
	);
};

export const pricingPlanPrices = ( state = Map(), action ) => {
	switch ( action.type ) {
	case FETCH_PRICING_PLANS:
		return handleSuccess(
			state,
			action,
			prevState => normalizePricesFromPricingPlans( action.payload, prevState )
		);
	case FETCH_WORKSPACES:
		return handleSuccess(
			state,
			action,
			prevState => normalizePricesFromWorkspaces( action.payload, prevState )
		);
	case LOGOUT:
		return Map();
	default:
		return state;
	}
};
