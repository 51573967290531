import { Redirect } from 'react-router';

export const renderOrRedirect = ( {
	component, redirectIf, redirectTo, onRedirect
} ) => {
	if ( redirectIf ) {
		onRedirect?.();
		return <Redirect to={redirectTo} />;
	}
	return component;
};
