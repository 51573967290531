import './WorkspaceContactGroupRow.scss';
import PropTypes from 'prop-types';

import ContactGroupPropType from '../../types/contactGroup';
import EmailChip from '../EmailChip/EmailChip';
import IconButton from '../UI/IconButton/IconButton';

const WorkspaceContactGroupRow = ( { group, onEditContactGroupClick, onDeleteContactGroupClick } ) => (
	<div key={group.id} className="contact-group">
		<div className="contact-name">
			<p>{group.name}</p>
		</div>
		<div className="contact-emails">
			{group.emails.map( email => (
				<EmailChip key={email} value={email} isValid />
			) )}
		</div>
		<div className="actions">
			<IconButton icon="edit" testID="edit-row-icon" onClick={() => onEditContactGroupClick( group )} />
			<IconButton icon="delete" testID="delete-row-icon" onClick={() => onDeleteContactGroupClick( group )} />
		</div>
	</div>
);

WorkspaceContactGroupRow.propTypes = {
	group: ContactGroupPropType.isRequired,
	onEditContactGroupClick: PropTypes.func.isRequired,
	onDeleteContactGroupClick: PropTypes.func.isRequired
};

export default WorkspaceContactGroupRow;
