import isEqual from 'lodash/isEqual';
import { useEffect, useRef } from 'react';

export function useDeepEffect( callback, dependencies ) {
	const isFirstRender = useRef( true );
	const prevDependencies = useRef( dependencies );

	useEffect( () => {
		const noChanges = prevDependencies.current.every(
			( obj, index ) => isEqual( obj, dependencies[ index ] )
		);

		if ( isFirstRender.current || !noChanges ) { callback(); }

		isFirstRender.current = false;
		prevDependencies.current = dependencies;
	}, dependencies );
}

export default useDeepEffect;
