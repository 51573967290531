import PropTypes from 'prop-types';
import Tooltip from '../../UI/Tooltip/Tooltip';

import NotificationsButtonContainer
	from '../../../containers/NotificationsButtonContainer/NotificationsButtonContainer';
import AssetsUploaderButtonContainer
	from '../../../containers/AssetsUploaderButtonContainer/AssetsUploaderButtonContainer';
import './NavBarActions.scss';
import IntercomButton from '../../IntercomButton/IntercomButton';
import UpgradeButtonContainer from '../../../containers/UpgradeButtonContainer/UpgradeButtonContainer';
import FreeAccountPushForGuestsButton
	from '../../../new_arch/modules/noAccountCollaboration/FreeAccountPushForGuestsButton/FreeAccountPushForGuestsButton';
import { useIsGuest } from '../../../hooks/useIsGuest';

const NavBarActions = ( { showingProjectID, showingFolderID, showingFreeAccountGuestButton } ) => {
	const isGuest = useIsGuest();

	return (
		<ul className="NavBarActions">
			<li>
				<UpgradeButtonContainer />
			</li>
			{isGuest && showingFreeAccountGuestButton && (
				<li>
					<FreeAccountPushForGuestsButton />
				</li>
			)}
			{!isGuest && (
				<li>
					<NotificationsButtonContainer />
				</li>
			)}
			<li>
				<Tooltip title="Upload">
					<AssetsUploaderButtonContainer projectID={showingProjectID} folderID={showingFolderID} />
				</Tooltip>
			</li>
			<li>
				<IntercomButton />
			</li>
		</ul>
	)
};

NavBarActions.propTypes = {
	showingProjectID: PropTypes.number,
	showingFolderID: PropTypes.number,
	showingFreeAccountGuestButton: PropTypes.bool
};

NavBarActions.defaultProps = {
	showingProjectID: null,
	showingFolderID: null,
	showingFreeAccountGuestButton: true
};

export default NavBarActions;
