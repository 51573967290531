import PasswordSettingsContainer from '../../containers/PasswordSettingsContainer/PasswordSettingsContainer';
import useTwoFactorAuthenticationCardPresenter from '../../presenters/TwoFactorAuthenticationCardPresenter/useTwoFactorAuthenticationCardPresenter';
import TwoFactorAuthenticationCard from '../TwoFactorAuthenticationCard/TwoFactorAuthenticationCard';

const SecurityTab = () => {
	const twoFactorAuthenticationCardPresenter = useTwoFactorAuthenticationCardPresenter();

	return (
		<>
			<PasswordSettingsContainer />
			<TwoFactorAuthenticationCard presenter={twoFactorAuthenticationCardPresenter} />
		</>
	);
};

export default SecurityTab;
