import * as snackbar from '../actions/snackbars';

export default class SnackbarSystem {
	constructor( dispatch ) {
		this.dispatch = dispatch;
	}

	showSuccessMessage( texts, timeout ) {
		return this.dispatch( snackbar.showSuccessMessage( texts, timeout ) );
	}

	showErrorMessage( texts, timeout ) {
		return this.dispatch( snackbar.showErrorMessage( texts, timeout ) );
	}

	showLoadingMessage( { channel, texts } ) {
		return this.dispatch( snackbar.showLoadingMessage( { channel, texts } ) );
	}

	dismissCurrentSnackbar( { channel } = {} ) {
		return this.dispatch( snackbar.dismissCurrentSnackbar( { channel } ) );
	}
}
