import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import Button from '../../../new_arch/components/Button/Button';
import MembersUsagePresenter from '../../../presenters/MembersUsagePresenter/MembersUsagePresenter';
import AddOnUsage from '../AddOnUsage/AddOnUsage';

const MembersUsage = ( { presenter, inviteMemberButton } ) => (
	<AddOnUsage
		title="Workspace seats"
		icon="person"
		showAddOnsButton={presenter.showAddOnsButton}
		onAddOnsButtonClick={presenter.addOnsButtonWasClicked}
		addOnsButtonText={presenter.addMembersButtonText}
		addOnsModalIsOpen={presenter.addOnsModalIsOpen}
		addOnsModalPresenter={presenter.addOnsModalPresenter}
		valueCount={presenter.membersCount}
		maxValue={presenter.maxMembersCount}
		greyOut={!presenter.isSubscriptionActive}
		unit={presenter.membersUnitText}
		button={inviteMemberButton}
		hasAddOns={presenter.hasMembersAddOns}
		disabledButton={presenter.disabledAddOnButton}
	/>
);

MembersUsage.propTypes = {
	presenter: PropTypes.instanceOf( MembersUsagePresenter ).isRequired,
	inviteMemberButton: PropTypes.instanceOf( Button )
};

MembersUsage.defaultProps = {
	inviteMemberButton: null
};

export default observer( MembersUsage );
