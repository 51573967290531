import { getFilteredCommentIDsForSelectedAssetRound } from '../selectors/commentsFilterOptions';
import { FREEZE_COMMENTS_VIEW_FILTER, SET_COMMENTS_VIEW_FILTER, UNFREEZE_COMMENTS_VIEW_FILTER } from './types';

export const setCommentsViewFilter = ( assetID, filterOption ) => ( {
	type: SET_COMMENTS_VIEW_FILTER,
	payload: { assetID, filterID: filterOption.id }
} );

export const freezeCommentsViewFilter = assetID => ( dispatch, getState ) => {
	const commentIDs = getFilteredCommentIDsForSelectedAssetRound( getState(), { assetID } );
	dispatch( {
		type: FREEZE_COMMENTS_VIEW_FILTER,
		payload: commentIDs
	} );
};

export const unfreezeCommentsViewFilter = () => ( {
	type: UNFREEZE_COMMENTS_VIEW_FILTER
} );
