import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import Switch from '../Switch/Switch';

import './AddOnsSwitch.scss';
import AddOn from '../../entities/addOn';

const StorageOptionContent = () => <h6>Storage</h6>;
const SeatsOptionContent = () => <h6>Seats</h6>;

const AddOnsSwitch = ( { onSwitch, selectedAddOnType } ) => {
	const storageAddOnIsSelected = selectedAddOnType === AddOn.storageType;
	return (
		<Switch
			className="AddOnsSwitch"
			leftOption={{
				Content: StorageOptionContent,
				isSelected: storageAddOnIsSelected
			}}
			rightOption={{
				Content: SeatsOptionContent,
				isSelected: !storageAddOnIsSelected
			}}
			onSwitch={onSwitch}
		/>
	);
};

AddOnsSwitch.propTypes = {
	selectedAddOnType: PropTypes.oneOf( [ AddOn.storageType, AddOn.membersType ] ).isRequired,
	onSwitch: PropTypes.func
};

AddOnsSwitch.defaultProps = {
	onSwitch: () => {}
};

export default observer( AddOnsSwitch );
