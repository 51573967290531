import { MutationOptions, useMutation } from '@tanstack/react-query';
import AbortablePromise from '../../services/AbortablePromise';
import AppError from '../errors/AppError';

const useMutationQuery = <TBodyParams>(
	query : ( params: TBodyParams ) => Promise<Response> | AbortablePromise,
	options?: MutationOptions<Response, AppError, TBodyParams>
) => {
	const mutation = useMutation( query, options );
	const error = mutation.error as AppError;

	return {
		...mutation,
		errorMessage: error?.message,
		errorName: error?.name
	}
}

export default useMutationQuery;
