import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Circle } from 'rc-progress';

import ShowElementToggler from '../../ShowElementToggler/ShowElementToggler';
import IconButton from '../../UI/IconButton/IconButton';
import NavBarPopup from '../../navBar/NavBarPopup/NavBarPopup';
import AssetUploaderBoxContainer
	from '../../../containers/AssetUploaderBoxContainer/AssetUploaderBoxContainer';

import './AssetsUploaderButton.scss';

const afterOneSecond = close => setTimeout( close, 1000 );

export const AssetsUploaderButton = ( {
	projectID, folderID, isUploadingAssets, totalUploadProgress, forwardedRef
} ) => (
	<ShowElementToggler
		className="AssetsUploaderButton"
		ref={forwardedRef}
		renderToggler={open => (
			<span className="asset-uploader-toggler">
				{ isUploadingAssets && (
					<Circle
						percent={totalUploadProgress}
						strokeWidth="8"
						trailColor="#FFFFFF"
						strokeColor="#3F9CFF"
					/>
				) }
				<IconButton
					buttonClassName="upload_icon"
					onClick={open}
					icon="publish_icon"
					size={18}
				/>
			</span>
		)}
		renderComponent={close => (
			<NavBarPopup>
				<AssetUploaderBoxContainer
					projectID={projectID}
					folderID={folderID}
					onClickOutside={close}
					onAllUploadsSucceeded={() => afterOneSecond( close )}
				/>
			</NavBarPopup>
		)}
	/>
);

AssetsUploaderButton.propTypes = {
	projectID: PropTypes.number,
	folderID: PropTypes.number,
	forwardedRef: PropTypes.any,
	isUploadingAssets: PropTypes.bool,
	totalUploadProgress: PropTypes.number
};

AssetsUploaderButton.defaultProps = {
	projectID: null,
	folderID: null,
	forwardedRef: undefined,
	isUploadingAssets: false,
	totalUploadProgress: 0
};

export default forwardRef( ( props, ref ) => (
	<AssetsUploaderButton {...props} forwardedRef={ref} />
) );
