export default class IsWorkspaceActiveValidator {
	constructor( workspaces, skipValidationForValues ) {
		this.workspaces = workspaces;
		this.skipValidationForValues = skipValidationForValues;
	}

	validate( value, label, errors ) {
		const currentWorkspace = !!value && this.workspaces.find( workspace => workspace.id === value );
		const skipValue = this.skipValidationForValues.includes( value );
		if ( skipValue || value === null || currentWorkspace.isActive ) {
			return true;
		}
		errors.push( 'This workspace is suspended. Please choose another one.' );
		return false;
	}
}
