import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import Tooltip from '../UI/Tooltip/Tooltip';
import Button from '../../new_arch/components/Button/Button';
import UpgradeButtonPresenter from '../../presenters/UpgradeButtonPresenter/UpgradeButtonPresenter';

import './UpgradeButton.scss';
import { ERROR, WARNING_BASE } from '../../styles/colors';
import WarningIcon from '../UI/WarningIcon/WarningIcon';

const daysLeftWarning = daysLeft => `${daysLeft} ${daysLeft === 1 ? 'day' : 'days'} left`;

export const UpgradeButton = ( { presenter } ) => {
	const expiredFreeTrialInfo = () => (
		<div className="expired-free-trial">
			<h6>Free Trial Expired</h6>
			<WarningIcon color={ERROR} size={20} />
		</div>
	);

	const freeTrialInfo = () => (
		<Tooltip title="Free Trial">
			<h6 className="days-left">{daysLeftWarning( presenter.daysLeft )}</h6>
			{presenter.showWarningIcon && <WarningIcon color={WARNING_BASE} size={20} />}
		</Tooltip>
	);

	return (
		<ul className="UpgradeButton">
			<li>
				{presenter.isOnExpiredFreeTrial
					? expiredFreeTrialInfo()
					: freeTrialInfo()}
			</li>
			<li>
				<Button onClick={presenter.openSubscriptionModal}>Upgrade</Button>
			</li>
		</ul>
	);
};

UpgradeButton.propTypes = {
	presenter: PropTypes.instanceOf( UpgradeButtonPresenter ).isRequired
};

export default observer( UpgradeButton );
