import React from 'react';
import PropTypes from 'prop-types';
import Provinces from 'provinces';
import StateSelector from '../StateSelector/StateSelector';
import BillingInput from '../BillingInput/BillingInput';

const OPTIONS = Provinces.reduce( ( options, { name, short, country } ) => ( {
	...options,
	[ country ]: [
		...( options[ country ] || [] ),
		{ label: name, value: short || name }
	]
} ), {} );

const StateField = ( {
	savedValue, countryCode, value, onChange, required, isSearchable,
	disabled, onValidationStatusChange, statesByCountry
} ) => {
	const label = countryCode === 'CA' ? 'Province' : 'State';
	const selectedOptions = statesByCountry[ countryCode ];
	const hasOptions = selectedOptions?.length > 0;

	if ( !value ) {
		onChange(
			hasOptions ? selectedOptions.find( ( { value: option } ) => option === savedValue ) : { value: savedValue }
		);
	}

	const renderInput = () => (
		<BillingInput
			className="StateField"
			label="State or Province"
			name="state"
			placeholder="Enter your province or state..."
			value={value?.value}
			onChange={v => onChange( { value: v } )}
			required={required}
			disabled={disabled}
			onValidationStatusChange={onValidationStatusChange}
		/>
	)

	const renderSelector = () => (
		<StateSelector
			label={label}
			countryCode={countryCode}
			selected={value}
			onChange={onChange}
			disabled={disabled}
			required={required}
			isSearchable={isSearchable}
			options={selectedOptions}
		/>
	)

	return (
		<div className="StateSelector">
			{hasOptions ? renderSelector() : renderInput()}
		</div>
	);
};

StateField.propTypes = {
	savedValue: PropTypes.string,
	onChange: PropTypes.func,
	value: PropTypes.shape( {
		label: PropTypes.string,
		value: PropTypes.string
	} ),
	required: PropTypes.bool,
	isSearchable: PropTypes.bool,
	disabled: PropTypes.bool,
	countryCode: PropTypes.string.isRequired,
	onValidationStatusChange: PropTypes.func.isRequired,
	statesByCountry: PropTypes.shape( {
		[ PropTypes.string ]: PropTypes.arrayOf( PropTypes.shape( {
			label: PropTypes.string,
			value: PropTypes.string
		} ) )
	} )
};

StateField.defaultProps = {
	savedValue: undefined,
	onChange: () => {},
	value: undefined,
	required: false,
	isSearchable: true,
	disabled: false,
	statesByCountry: OPTIONS
};

export default StateField;
