import TeamPlanToDisplay from '../entities/entitiesToDisplay/pricingPlans/TeamPlanToDisplay';
import ProPlanToDisplay from '../entities/entitiesToDisplay/pricingPlans/ProPlanToDisplay';

export const PRO_PLAN = 'Pro Plan';
export const TEAM_PLAN = 'Team Plan';

export default class PricingPlanToDisplayFactory {
	static buildersByName = {
		[ TEAM_PLAN ]: pricingPlan => new TeamPlanToDisplay( { pricingPlan } ),
		[ PRO_PLAN ]: pricingPlan => new ProPlanToDisplay( { pricingPlan } )
	};

	static forPricingPlan( pricingPlan ) {
		return PricingPlanToDisplayFactory.fromPlan( pricingPlan );
	}

	static fromPlan( pricingPlan ) {
		if ( pricingPlan.name in this.buildersByName ) {
			return this.buildersByName[ pricingPlan.name ]( pricingPlan );
		}

		return undefined;
	}
}
