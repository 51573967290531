import PropTypes from 'prop-types';
import HistoryPropType, { LocationPropType } from '../../../types/history';

import Nav from '../Nav/Nav';
import AssetDetailsHeaderContainer
	from '../../../containers/AssetDetailsHeaderContainer/AssetDetailsHeaderContainer';
import ProfileWithDropdownActionsContainer
	from '../../../containers/ProfileWithDropdownActionsContainer/ProfileWithDropdownActionsContainer';
import NavBarActions
	from '../NavBarActions/NavBarActions';
import { isMobile } from '../../../lib/isMobile';
import { useIsGuest } from '../../../hooks/useIsGuest';

const AssetDetailsNav = ( {
	projectID, assetID, history, location
} ) => {
	const isGuest = useIsGuest();
	return ( !isMobile && (
		<Nav>
			<AssetDetailsHeaderContainer
				projectID={projectID}
				assetID={assetID}
				history={history}
				location={location}
				isGuest={isGuest}
			/>
			<div className="right-side">
				<NavBarActions showingProjectID={projectID} showingFreeAccountGuestButton={false} />
				<ProfileWithDropdownActionsContainer history={history} />
			</div>
		</Nav>
	) )
};

AssetDetailsNav.propTypes = {
	projectID: PropTypes.number.isRequired,
	assetID: PropTypes.number.isRequired,
	history: HistoryPropType.isRequired,
	location: LocationPropType.isRequired
};

export default AssetDetailsNav;
