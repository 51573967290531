import PropTypes from 'prop-types';
import Select from '../UI/Select/Select';
import FilterOption from '../../entities/filterOption';

import './FilterSelector.scss';

const FilterSelector = ( {
	selectedFilterOption, onViewFilterSet, filterOptions, disabled,
	isSearchable, selectedOptionType
} ) => {
	const filterIsSelected = selectedFilterOption?.id !== FilterOption.none.id;

	return (
		<Select
			selected={selectedFilterOption}
			placeholder="No Filter"
			options={filterOptions}
			onChange={onViewFilterSet}
			disabled={disabled}
			isSearchable={isSearchable}
			showSelectedDot={filterIsSelected}
			selectedOptionType={selectedOptionType}
		/>
	);
};

FilterSelector.propTypes = {
	selectedFilterOption: PropTypes.instanceOf( FilterOption ),
	onViewFilterSet: PropTypes.func,
	filterOptions: PropTypes.arrayOf( PropTypes.instanceOf( FilterOption ) ).isRequired,
	disabled: PropTypes.bool,
	isSearchable: PropTypes.bool,
	selectedOptionType: PropTypes.string
};

FilterSelector.defaultProps = {
	selectedFilterOption: FilterOption.none,
	onViewFilterSet: () => { },
	disabled: false,
	isSearchable: false,
	selectedOptionType: 'Filter:'
};

export default FilterSelector;
