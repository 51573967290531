import MobileWarning from './MobileWarning';

import './MobileWarning.scss';

const MobileWarningContent = () => (
	<>
		Success!
		<br />
		<br />
		Your account has been created.
		<br />
		To use VeryBusy, please sign in on desktop.
		<br />
		<br />
		A mobile version is in the works.
	</>
);

const MobileWarningSignup = () => (
	<MobileWarning Content={MobileWarningContent} />
);

export default MobileWarningSignup;
