import IconButton from '../../UI/IconButton/IconButton';
import ColorLabel from '../../UI/ColorLabel/ColorLabel';
import type LabelGroup from '../../../entities/labelGroup';

import './WorkspaceLabelGroupRow.scss';

type WorkspaceLabelGroupRowProps = {
	group: LabelGroup,
	onEditClick: () => void
	onDeleteClick: () => void
}

const WorkspaceLabelGroupRow = ( { group, onEditClick, onDeleteClick }: WorkspaceLabelGroupRowProps ) => (
	<div key={group.id} className="WorkspaceLabelGroupRow">
		<div className="name">
			<p>{group.name}</p>
		</div>
		<div className="labels">
			{group.labels.map( label => (
				<ColorLabel
					key={label.name}
					text={label.name}
					backgroundColor={{
						base: label.baseColor,
						hovered: label.hoverColor
					}}
				/>
			) )}
		</div>
		<div className="actions">
			<IconButton icon="edit" testID="edit-row-icon" onClick={onEditClick} />
			<IconButton icon="delete" testID="delete-row-icon" onClick={onDeleteClick} />
		</div>
	</div>
);

export default WorkspaceLabelGroupRow;
