import useDeepEffect from './useDeepEffect';

const useAutoupdatePresenterProperty = ( {
	presenter, name, value
} ) => {
	useDeepEffect( () => {
		if ( !presenter ) { return; }
		presenter[ name ] = value;
	}, [ presenter, value ] );
};

export default useAutoupdatePresenterProperty;
