export class ProjectsPermissions {
	constructor( {
		add = false,
		edit = false,
		destroy = false,
		archive = false,
		see = false,
		seeSettings = false,
		share = false
	} ) {
		this.add = add;
		this.edit = edit;
		this.destroy = destroy;
		this.archive = archive;
		this.see = see;
		this.seeSettings = seeSettings;
		this.share = share;
	}
}

export const ownerProjectsPermissions = new ProjectsPermissions( {
	add: true,
	edit: true,
	destroy: true,
	archive: true,
	see: true,
	seeSettings: true,
	share: true
} );

export const projectCollaboratorProjectsPermissions = new ProjectsPermissions( {
	add: false,
	edit: false,
	destroy: false,
	archive: false,
	see: true,
	seeSettings: true,
	share: true
} );

export const projectGuestProjectsPermissions = new ProjectsPermissions( {
	add: false,
	edit: false,
	destroy: false,
	archive: false,
	see: true,
	seeSettings: false,
	share: false
} );
