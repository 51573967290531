import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import Workspace from '../../entities/workspace';
import useStorageUsagePresenter from '../../presenters/StorageUsagePresenter/useStorageUsagePresenter';
import StorageUsage from '../../components/subscription/StorageUsage/StorageUsage';

const StorageUsageContainer = ( {
	workspace, onPaidFeatureModalAcceptClick
} ) => {
	const presenter = useStorageUsagePresenter( { workspace, onPaidFeatureModalAcceptClick } );
	return <StorageUsage presenter={presenter} />;
};

StorageUsageContainer.propTypes = {
	workspace: PropTypes.instanceOf( Workspace ).isRequired,
	onPaidFeatureModalAcceptClick: PropTypes.func.isRequired
};

export default observer( StorageUsageContainer );
