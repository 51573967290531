import { isErrorWithStatus } from '.';

export const isProcessingError = isErrorWithStatus( 400, 'processing_error' );
export const isInvalidCardError = isErrorWithStatus( 400, 'invalid_card' );
export const isCardDeclinedError = isErrorWithStatus( 400, 'card_declined' );
export const isInvalidPromoCodeError = isErrorWithStatus( 400, 'invalid_promotion_code' );

export const getErrorDescription = ( error ) => {
	if ( isProcessingError( error ) ) { return 'Please try again later.'; }
	if ( isInvalidCardError( error ) ) { return 'You should enter a new payment method to continue.'; }
	if ( isInvalidPromoCodeError( error ) ) { return 'The promotion code is not valid for your purchase.'; }

	return 'Please review the information entered or contact your card issuer to continue.';
};
