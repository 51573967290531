import PropTypes from 'prop-types';
import BlueLogo from '../../assets/img/vb-logo-blue.svg';
import WhiteLogo from '../../assets/img/vb-logo.svg';

const VeryBusyLogo = ( { inBlue } ) => (
	<img
		className="veryBusy-logo"
		alt="logo"
		src={inBlue ? BlueLogo : WhiteLogo}
	/>
);

VeryBusyLogo.propTypes = {
	inBlue: PropTypes.bool
};

VeryBusyLogo.defaultProps = {
	inBlue: false
};

export default VeryBusyLogo;
