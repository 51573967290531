import { SHARED_FOLDERS_URL, SHARED_PROJECTS_URL } from '../config/urls';
import Folder from '../entities/folder';
import SharedProject from '../entities/sharedProject';

export default class ShareRef {
	static forProject( hash ) {
		return new ShareRef( { hash, type: 'project' } );
	}

	static forFolder( hash ) {
		return new ShareRef( { hash, type: 'folder' } );
	}

	constructor( { hash, type } ) {
		this.hash = hash;
		this.type = type;
	}

	get appPath() {
		const basePath = this.isForProject ? '/projects' : '/folders';
		return `${basePath}/shared_view/${this.hash}`;
	}

	get apiUrl() {
		const baseUrl = this.isForProject ? SHARED_PROJECTS_URL : SHARED_FOLDERS_URL;
		return `${baseUrl}/${this.hash}`;
	}

	get isForProject() {
		return this.type === 'project';
	}

	get parts() {
		return [ this.hash, this.type ];
	}

	equals( shareRef ) {
		return this.hash === shareRef.hash && this.type === shareRef.type;
	}

	getReferencedEntityFromStores( { sharedProjectStore, sharedFolderStore } ) {
		if ( this.isForProject ) {
			const rawProject = sharedProjectStore.get( this.hash );
			return rawProject ? SharedProject.fromJSON( rawProject ) : undefined;
		}

		const rawFolder = sharedFolderStore.toArray().find( folder => folder.shared_hash === this.hash );
		return rawFolder ? Folder.fromJSON( rawFolder ) : undefined;
	}

	fetchReferencedEntity( { fetchSharedProject, fetchSharedFolder } ) {
		if ( this.isForProject ) {
			return fetchSharedProject( this.hash );
		}
		return fetchSharedFolder( this.hash );
	}
}
