import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AsyncDownloadSharedProjectAssetsPresenterFactory from '../../factories/AsyncDownloadSharedProjectAssetsPresenterFactory';
import useAutoupdatePresenterProperty from '../../hooks/useAutoupdatePresenterProperty';
import { getCurrentUser } from '../../selectors/currentUser';
import AssetSystem from '../../systems/AssetSystem';
import GuestUserPreferencesSystem from '../../systems/GuestUserPreferencesSystem';
import SnackbarSystem from '../../systems/SnackbarSystem';
import DownloadItemsPopupPresenter from './DownloadItemsPopupPresenter';
import { useApi } from '../../new_arch/providers/ApiProvider';
import ProjectSystem from '../../systems/ProjectSystem';

const useDownloadSharedProjectItemsPopupPresenter = ( {
	onConfirm, onCancel, assets, folders, shareRef
} ) => {
	const dispatch = useDispatch();
	const api = useApi();

	const currentUser = useSelector( state => getCurrentUser( state ) );

	const createPresenter = () => {
		const assetSystem = new AssetSystem( dispatch );
		const snackbarSystem = new SnackbarSystem( dispatch );
		const userPreferencesSystem = new GuestUserPreferencesSystem( dispatch );
		const projectSystem = new ProjectSystem( { api } );
		const downloadAssetPresenterFactory = new AsyncDownloadSharedProjectAssetsPresenterFactory( {
			shareRef
		} );

		return new DownloadItemsPopupPresenter( {
			assetSystem,
			projectSystem,
			snackbarSystem,
			userPreferencesSystem,
			assets,
			folders,
			onConfirm,
			onCancel,
			currentUser,
			downloadAssetPresenterFactory,
			canDownloadPreviousRounds: false
		} );
	};

	const [ presenter ] = useState( createPresenter );

	useAutoupdatePresenterProperty( { presenter, name: 'assets', value: assets } );
	useAutoupdatePresenterProperty( { presenter, name: 'folders', value: folders } );
	useAutoupdatePresenterProperty( { presenter, name: 'currentUser', value: currentUser } );

	return presenter;
};

export default useDownloadSharedProjectItemsPopupPresenter;
