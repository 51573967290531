import { createSelector } from 'reselect';

import AssetsFilterAndSortOptions, {
	DEFAULT_ASSETS_SORT_OPTION
} from '../reducers/records/assetsFilterAndSortOptions';
import SortOption from '../entities/sortOption';
import AssetType from '../entities/assetType';
import { getLabelsForProject } from './projects';
import FilterFactory from '../factories/FilterFactory';
import AssetFilters from '../entities/assetFilters';

import NewArchAssetFilters from '../new_arch/modules/projectView/models/filtersAndSorts/assetFilters';
import LabelFilter from '../new_arch/modules/projectView/models/filtersAndSorts/labelFilter';
import StateFilter from '../new_arch/modules/projectView/models/filtersAndSorts/stateFilter';
import ItemSorting from '../new_arch/modules/projectView/models/filtersAndSorts/itemSorting';
import NewArchSortOption from '../new_arch/modules/projectView/models/filtersAndSorts/sortOption';

const getAssetsTypeFromProps = ( _, props ) => props.forAssetsType;

const getFilterAndSortOptionsForProject = createSelector(
	[
		state => state.assetsFilterAndSortOptions,
		( _, props ) => props.projectID
	],
	( assetsFilterAndSortOptions, projectID ) => (
		assetsFilterAndSortOptions.get( projectID ) || AssetsFilterAndSortOptions()
	)
);

const getSelectedSortOptionForProject = createSelector(
	[ getFilterAndSortOptionsForProject ],
	projectFilterAndSortOptions => SortOption.optionsByID[
		projectFilterAndSortOptions.get( 'sort' )
	]
);

const getFilterAndSortOptionForProject = option => createSelector(
	[ getFilterAndSortOptionsForProject ],
	projectFilterAndSortOptions => projectFilterAndSortOptions.get( option )
);

export const getSortAscendingForProject = getFilterAndSortOptionForProject( 'sortAscending' );

export const getFilenameFilterForProject = getFilterAndSortOptionForProject( 'filenameFilter' );

export const getAssetsTypeForProject = getFilterAndSortOptionForProject( 'assetsType' );

export const getAssetsSortOptionsForAssetsType = createSelector(
	[
		getAssetsTypeFromProps
	],
	assetsType => ( assetsType === AssetType.DOCUMENT ? SortOption.documentsOptions : SortOption.imagesOptions )
);

const getAssetsSortOptionsForAssetsTypeAsList = createSelector(
	[ getAssetsSortOptionsForAssetsType ],
	options => options.reduce(
		( res, option ) => {
			const isGroup = !!option.options;

			return [ ...res, ...( isGroup ? option.options : [ option ] ) ];
		},
		[]
	)
);

export const getSelectedSortOptionForProjectAndSelectedAssetsType = createSelector(
	[
		getAssetsSortOptionsForAssetsTypeAsList,
		getSelectedSortOptionForProject
	],
	( sortOptions, selected ) => (
		sortOptions.includes( selected )
			? selected
			: DEFAULT_ASSETS_SORT_OPTION
	)
);

export const getSelectedSortOptionForProjectForImages = createSelector(
	[
		state => state,
		( _, props ) => props
	],
	( state, props ) => getSelectedSortOptionForProjectAndSelectedAssetsType(
		state, { ...props, forAssetsType: AssetType.IMAGE }
	)
);

export const getSelectedSortOptionForProjectForDocuments = createSelector(
	[
		state => state,
		( _, props ) => props
	],
	( state, props ) => getSelectedSortOptionForProjectAndSelectedAssetsType(
		state, { ...props, forAssetsType: AssetType.DOCUMENT }
	)
);

export const getSelectedFilterOptionForProject = createSelector(
	[ getFilterAndSortOptionsForProject, getLabelsForProject ],
	( projectFilterAndSortOptions, projectLabels ) => projectFilterAndSortOptions.has( 'filter' )
			&& FilterFactory.assetsFiltersByID( projectLabels )[ projectFilterAndSortOptions.get( 'filter' ) ]
);

export const getSelectedFiltersForProject = createSelector(
	[
		getSelectedFilterOptionForProject,
		getFilenameFilterForProject,
		getAssetsTypeForProject
	],
	( filterByAttribute, filterByName, filterByType ) => new AssetFilters( {
		filterByAttribute,
		filterByName,
		filterByType
	} )
);

export const getAssetFiltersForNewArch = createSelector(
	[ getFilterAndSortOptionsForProject ],
	( opts ) => {
		const filterOption = opts.get( 'filter' );

		let newArchFilterOption;
		if ( typeof filterOption === 'string' && filterOption !== 'none' ) {
			newArchFilterOption = StateFilter.stateFiltersById[ filterOption ];
		} else if ( typeof filterOption === 'number' || filterOption === null ) {
			newArchFilterOption = new LabelFilter( filterOption );
		}

		return new NewArchAssetFilters( {
			type: opts.get( 'assetsType' ),
			searchText: opts.get( 'filenameFilter' ),
			filterOption: newArchFilterOption
		} );
	}
);

export const getAssetSortingForNewArch = createSelector(
	[ getFilterAndSortOptionsForProject ],
	opts => new ItemSorting( {
		sortBy: NewArchSortOption.sortOptionsByBackendId[ opts.get( 'sort' ) ],
		direction: opts.get( 'sortAscending' ) ? 'asc' : 'desc'
	} )
);
