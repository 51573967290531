import { createSelector } from 'reselect';
import PricingPlanPrice from '../entities/pricing/pricingPlanPrice';
import { getPricingPlans } from './pricingPlans';

export const getRawPricingPlanPrices = state => state.pricingPlanPrices;

export const getPricingPlanPrices = createSelector(
	[ getRawPricingPlanPrices, getPricingPlans ],
	( rawPrices, pricingPlans ) => rawPrices.map(
		price => PricingPlanPrice.fromJSON( price, pricingPlans.get( price.pricing_plan ) )
	)
);
