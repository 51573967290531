import ShapeRenderer from '../lib/shapeRenderer';
import Line from './line';

class Arrow extends Line {
	toJSON() {
		return {
			...super.toJSON(),
			type: 'arrow'
		};
	}

	get renderer() { return ShapeRenderer.forArrow( this ); }
}

export default Arrow;
