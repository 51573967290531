import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import Workspace from '../../entities/workspace';
import useMembersUsagePresenter from '../../presenters/MembersUsagePresenter/useMembersUsagePresenter';
import MembersUsage from '../../components/subscription/MembersUsage/MembersUsage';
import Button from '../../new_arch/components/Button/Button';

const MembersUsageContainer = ( {
	workspace, inviteMemberButton, onPaidFeatureModalAcceptClick
} ) => {
	const presenter = useMembersUsagePresenter( { workspace, onPaidFeatureModalAcceptClick } );
	return <MembersUsage presenter={presenter} inviteMemberButton={inviteMemberButton} />;
};

MembersUsageContainer.propTypes = {
	workspace: PropTypes.instanceOf( Workspace ).isRequired,
	inviteMemberButton: PropTypes.instanceOf( Button ),
	onPaidFeatureModalAcceptClick: PropTypes.func.isRequired
};

MembersUsageContainer.defaultProps = {
	inviteMemberButton: undefined
};

export default observer( MembersUsageContainer );
