import Tooltip from '../UI/Tooltip/Tooltip';

import './UserAvatar.scss';

type UserAvatarProps = {
	user: { avatar: string, name: string, email: string },
	hideTooltip?: boolean
}

const UserAvatar = ( { user: { avatar, name, email }, hideTooltip = false }: UserAvatarProps ) => {
	const tooltipData = `${name} (${email})`;

	return (
		<div className="UserAvatar" data-testid="user-avatar">
			<Tooltip
				title={hideTooltip ? '' : tooltipData}
				position="bottom"
			>
				<img
					className="user-avatar"
					src={avatar}
					alt={name}
				/>
			</Tooltip>
		</div>
	);
};

export default UserAvatar;
