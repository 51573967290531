export default class History {
	constructor( browserHistory ) {
		this._browserHistory = browserHistory;
		this._locations = [ this._browserHistory.location ];

		this._listenForPush();
		this._listenForPop();
	}

	static asProxyOf( browserHistory ) {
		return new Proxy( browserHistory, new this( browserHistory ) );
	}

	get( browserHistory, property ) {
		if ( property === 'previousLocation' ) return this._previousLocation;
		return browserHistory[ property ];
	}

	get _previousLocation() {
		return this._locations.length > 1 ? this._locations[ this._locations.length - 2 ] : null;
	}

	_listenForPop() {
		this._browserHistory.listen( ( _, action ) => {
			if ( action !== 'POP' ) return;
			this._locations.pop();
		} );
	}

	_listenForPush() {
		this._browserHistory.listen( ( location, action ) => {
			if ( action !== 'PUSH' ) return;
			this._locations.push( location );
		} );
	}
}
