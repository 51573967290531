import { Map } from 'immutable';
import { handle } from 'redux-pack';
import {
	CREATE_FILE_UPLOADS,
	UPDATE_ASSET_UPLOAD_PROGRESS,
	UPDATE_ASSET_UPLOAD_STATUS,
	CANCEL_ALL_ASSET_UPLOADS,
	CREATE_ASSET_FROM_DIRECT_UPLOAD,
	CREATE_ROUND_FROM_DIRECT_UPLOAD,
	DELETE_PROJECTS,
	LOGOUT
} from '../actions/types';
import { ITEM_UPLOAD_STATUS } from '../services/DirectFilesUploaderCoordinator';
import { uploadIsActive } from '../selectors/assetUploads';
import { handleStart, handleSuccess } from '../lib/reduxUtils';

const transitionToStatus = ( upload, status, errorName ) => ( {
	...upload,
	status,
	errorName,
	startedAt: status === ITEM_UPLOAD_STATUS.UPLOADING
		? new Date()
		: upload.startedAt
} );

export const assetUploads = ( state = new Map(), action ) => {
	switch ( action.type ) {
	case CREATE_FILE_UPLOADS:
		return action.payload.reduce(
			( uploads, upload ) => uploads.set( upload.id, upload ),
			state
		);
	case UPDATE_ASSET_UPLOAD_PROGRESS: {
		const { id } = action.meta;
		return state.updateIfSet(
			id,
			upload => ( { ...upload, bytesUploaded: action.payload.totalBytesUploaded } )
		);
	}
	case UPDATE_ASSET_UPLOAD_STATUS: {
		const { id } = action.meta;
		return state.updateIfSet(
			id,
			upload => ( transitionToStatus( upload, action.payload ) )
		);
	}
	case CANCEL_ALL_ASSET_UPLOADS:
		return state
			.map( upload => (
				uploadIsActive( upload.status )
					? transitionToStatus( upload, ITEM_UPLOAD_STATUS.ABORTED )
					: upload
			) );
	case CREATE_ASSET_FROM_DIRECT_UPLOAD:
	case CREATE_ROUND_FROM_DIRECT_UPLOAD:
		return handle(
			state,
			action,
			{
				start: prevState => prevState.updateIfSet(
					action.meta.upload_id,
					upload => transitionToStatus( upload, ITEM_UPLOAD_STATUS.CREATING_ASSET )
				),
				success: prevState => prevState.updateIfSet(
					action.meta.upload_id,
					upload => transitionToStatus( upload, ITEM_UPLOAD_STATUS.SUCCEEDED )
				),
				failure: prevState => prevState.updateIfSet(
					action.meta.upload_id,
					upload => transitionToStatus( upload, ITEM_UPLOAD_STATUS.FAILED, action.payload?.name )
				)
			}
		);
	case DELETE_PROJECTS:
		return handleSuccess(
			state,
			action,
			prevState => action.payload.reduce(
				( result, deletedID ) => result.filter(
					upload => upload.projectID !== deletedID
				),
				prevState
			)
		);
	case LOGOUT:
		return handleStart( state, action, () => new Map() );
	default:
		return state;
	}
};
