import { Comparator, comparatorBy, compose, flip } from '../../../../lib/comparators';
import type Asset from '../asset';
import type Folder from '../folder';
import { type SortDirection } from './types';

type ItemComparator = Comparator<Asset | Folder>;

export default class SortOption {
	backendId: string;
	comparator: ItemComparator;
	forAssetsOnly: boolean;

	constructor( {
		backendId,
		comparator,
		forAssetsOnly = false
	}: {
		backendId: string,
		comparator: ItemComparator,
		forAssetsOnly?: boolean
	} ) {
		this.backendId = backendId;
		this.comparator = comparator;
		this.forAssetsOnly = forAssetsOnly;
	}

	sortAssets( assets: Asset[], direction: SortDirection ) {
		return this.sortItems( assets, direction );
	}

	sortFolders( folders: Folder[], direction: SortDirection ) {
		if ( this.forAssetsOnly ) {
			throw new Error( `Cannot apply '${this.backendId}' sort option for folders` );
		}
		return this.sortItems( folders, direction );
	}

	private sortItems<TItem extends Asset | Folder>( items: TItem[], direction: SortDirection ) {
		return items.sort( direction === 'asc' ? this.comparator : flip( this.comparator ) );
	}

	static byName = new SortOption( {
		backendId: 'filename',
		comparator: comparatorBy( 'name', { natural: true } )
	} );

	static byDateUploaded = new SortOption( {
		backendId: 'created_at',
		comparator: flip( comparatorBy( 'createdAt' ) )
	} );

	static byNewActivity = new SortOption( {
		backendId: 'last_activity_at',
		comparator: comparatorBy( 'lastActivityAt' )
	} );

	static byUnresolvedComments = new SortOption( {
		backendId: 'unresolved_comments_count',
		comparator: comparatorBy( 'unresolvedCommentsCount' )
	} );

	static byRoundNumber = new SortOption( {
		backendId: 'asset_versions_count',
		comparator: comparatorBy<Asset>( 'roundCount' ) as ItemComparator,
		forAssetsOnly: true
	} );

	static byFileType = new SortOption( {
		backendId: 'extension',
		comparator: comparatorBy<Asset>( 'fileExtension' ) as ItemComparator,
		forAssetsOnly: true
	} );

	static byApproved = new SortOption( {
		backendId: 'approved_at',
		comparator: compose(
			comparatorBy<Asset>( 'isApproved' ) as ItemComparator,
			comparatorBy( 'name', { natural: true } )
		),
		forAssetsOnly: true
	} );

	static byPriority = new SortOption( {
		backendId: 'rush_priority',
		comparator: compose(
			comparatorBy<Asset>( 'hasRushPriority' ) as ItemComparator,
			flip( comparatorBy( 'lastActivityAt' ) )
		),
		forAssetsOnly: true
	} );

	static manual = new SortOption( {
		backendId: 'custom',
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		comparator: ( _e1, _e2 ) => 0,
		forAssetsOnly: true
	} );

	static sortOptionsByBackendId = {
		filename: SortOption.byName,
		created_at: SortOption.byDateUploaded,
		last_activity_at: SortOption.byNewActivity,
		unresolved_comments_count: SortOption.byUnresolvedComments,
		asset_versions_count: SortOption.byRoundNumber,
		extension: SortOption.byFileType,
		approved_at: SortOption.byApproved,
		rush_priority: SortOption.byPriority,
		custom: SortOption.manual
	}
}
