import { sharedTracker } from '../Tracker';
import events from '../events';

export const trackWorkspaceDeletion = workspace => sharedTracker
	.trackWorkspaceAction( {
		action: events.workspace.WORKSPACE_DELETED,
		label: workspace.name
	} );

export const trackTransferWorkspaceOwnership = () => sharedTracker
	.trackWorkspaceAction( {
		action: events.workspace.WORKSPACE_OWNERSHIP_TRANSFERRED
	} );

export const trackWorkspaceInvite = ( { workspaceRole, workspaceID } ) => sharedTracker
	.trackWorkspaceAction( {
		action: events.workspace.WORKSPACE_MEMBER_INVITED,
		role: workspaceRole,
		workspace_id: workspaceID
	} );

export const trackWorkspaceRoleEdit = workspaceRole => sharedTracker
	.trackWorkspaceAction( {
		action: events.workspace.WORKSPACE_ROLE_EDITED,
		label: workspaceRole
	} );

export const trackWorkspaceBillingEdit = () => sharedTracker
	.trackWorkspaceAction( {
		action: events.workspace.WORKSPACE_BILLING_EDITED
	} );

export const trackWorkspaceInvoiceOpen = () => sharedTracker
	.trackWorkspaceAction( {
		action: events.workspace.INVOICE_OPENED
	} );
