import AppError from './AppError';

export default class ResponseError extends AppError {
	public readonly status: number;
	public readonly data: unknown;

	constructor( { status = 400, data = {} } : { status : number, data?: unknown } ) {
		super( { message: '' } );
		this.status = status;
		this.data = data;
		this.message = this.getMessage();
		this.name = this.getName();
	}

	getMetaField( fieldName: string ) {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return ( this.data as any )?.meta?.[ fieldName ] as unknown;
	}

	private getMessage() {
		if ( this.data instanceof Object && 'error_message' in this.data ) {
			return this.data.error_message as string;
		}
		return 'Something went wrong';
	}

	private getName() {
		if ( this.data instanceof Object && 'error' in this.data ) {
			return this.data.error as string;
		}
		return 'ResponseError';
	}

	static async createFromResponse( aResponse : Response ) {
		const json = await aResponse.json();
		const { response } = json;
		return new ResponseError( { status: aResponse.status, data: response } );
	}
}
