import moment from 'moment';
import {
	FETCH_PROJECT_TASKS,
	MARK_PROJECT_TASK_AS_COMPLETED,
	CREATE_TASK, RESET_CREATE_TASK,
	UNMARK_PROJECT_TASK_AS_COMPLETED,
	SET_EDITING_TASK,
	CANCEL_TASK_EDIT,
	UPDATE_TASK
} from './types';
import { PROJECTS_URL } from '../config/urls';
import { getProjectIDFromTaskID } from '../selectors/tasks';
import { trackTaskAssigment, trackTaskCompleted } from '../services/tracker/trackers/tasks';
import { getProject } from '../selectors/projects';
import { getRawCurrentUser } from '../selectors/currentUser';

const serializeOptionalDate = date => (
	date && moment( date ).format( 'DD/MM/YYYY' )
);

export const fetchTasksForProject = projectID => ( dispatch, _, api ) => dispatch( {
	type: FETCH_PROJECT_TASKS,
	promise: api
		.get( `${PROJECTS_URL}/${projectID}/tasks` )
		.then( response => response.response
			.map( task => ( { ...task, project_id: projectID } ) ) )
} );

export const completeProjectTask = ( projectID, taskID ) => ( dispatch, _, api ) => {
	trackTaskCompleted();

	return dispatch( {
		type: MARK_PROJECT_TASK_AS_COMPLETED,
		promise: api
			.patch( `${PROJECTS_URL}/${projectID}/tasks/${taskID}/mark_as_completed` )
			.then( ( response ) => {
				const task = response.response;
				return { ...task, project_id: projectID };
			} )
	} );
};

export const uncompleteProjectTask = (
	projectID, taskID
) => (
	dispatch, _, api
) => dispatch( {
	type: UNMARK_PROJECT_TASK_AS_COMPLETED,
	promise: api
		.patch( `${PROJECTS_URL}/${projectID}/tasks/${taskID}/unmark_as_completed` )
		.then( ( response ) => {
			const task = response.response;
			return { ...task, project_id: projectID };
		} )
} );

export const createTask = (
	projectID, text, assigneeID, dueDate = undefined
) => (
	dispatch, getState, api
) => {
	const project = getProject( getState(), { projectID } );
	const { userID } = getRawCurrentUser( getState() );
	trackTaskAssigment( assigneeID, userID, project );

	return dispatch( {
		type: CREATE_TASK,
		promise: api
			.post(
				`${PROJECTS_URL}/${projectID}/tasks`,
				{
					text,
					due_date: serializeOptionalDate( dueDate ),
					assigned_ids: [ assigneeID ]
				}
			)
			.then( ( response ) => {
				const task = response.response;
				return { ...task, project_id: projectID };
			} )
	} );
};

export const resetCreateTaskRequest = () => ( {
	type: RESET_CREATE_TASK
} );

export const setEditingTask = taskID => ( {
	type: SET_EDITING_TASK,
	payload: { taskID }
} );

export const cancelTaskEdit = () => ( {
	type: CANCEL_TASK_EDIT
} );

export const updateTask = (
	taskID, text, assigneeID, dueDate
) => ( dispatch, getState, api ) => {
	const projectID = getProjectIDFromTaskID( getState(), taskID );
	return dispatch( {
		type: UPDATE_TASK,
		promise: api.patch( `${PROJECTS_URL}/${projectID}/tasks/${taskID}`, {
			text,
			due_date: serializeOptionalDate( dueDate ),
			assigned_ids: [ assigneeID ]
		} ).then( response => ( { ...response.response, project_id: projectID } ) )
	} );
};
