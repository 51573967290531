import { Map } from 'immutable';

import { FETCH_WORKSPACE_BILLING_INFORMATION, UPDATE_WORKSPACE_BILLING_INFORMATION, LOGOUT } from '../actions/types';
import { handleStart, handleSuccess } from '../lib/reduxUtils';

export const workspacesBillingInformation = ( state = new Map(), action ) => {
	switch ( action.type ) {
	case FETCH_WORKSPACE_BILLING_INFORMATION:
	case UPDATE_WORKSPACE_BILLING_INFORMATION:
		return handleSuccess(
			state,
			action,
			( prevState ) => {
				const { workspaceID } = action.meta;
				return action.payload
					? prevState.set( workspaceID, action.payload )
					: prevState;
			}
		);
	case LOGOUT:
		return handleStart( state, action, () => new Map() );
	default:
		return state;
	}
};
