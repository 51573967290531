import PopupFactory from '../../factories/PopupFactory';

export default class CreateProjectPresenter {
	constructor( {
		popupSystem, workspace, onErrorAccept,
		startCreateProjectFlow, createWorkspace
	} ) {
		this._popupSystem = popupSystem;
		this._workspace = workspace;
		this._onErrorAccept = onErrorAccept;
		this._startCreateProjectFlow = startCreateProjectFlow;
		this._createWorkspace = createWorkspace;
	}

	createProject() {
		if ( this._userCanCreateProjects ) {
			return this._startCreateProjectFlow();
		}
		return this._handleCannotCreateProjectError();
	}

	_alertOfSuspendedWorkspace() {
		return PopupFactory.cannotCreateProjectWithSuspendedWorkspace( this._popupSystem )
			.then( accept => accept && this._onErrorAccept() );
	}

	_alertOfFreeTrialExpired() {
		return PopupFactory.cannotCreateProjectWithExpiredFreeTrial( this._popupSystem )
			.then( accept => accept && this._onErrorAccept() );
	}

	_alertOfNoWorkspace() {
		return PopupFactory.cannotCreateProjectWithoutWorkspace( this._popupSystem )
			.then( accept => accept && this._createWorkspace() )
			.then( response => response && !response.isError && this._startCreateProjectFlow() );
	}

	_handleCannotCreateProjectError() {
		if ( !this._workspace ) return this._alertOfNoWorkspace();
		if ( this._workspace.isOnExpiredFreeTrial ) return this._alertOfFreeTrialExpired();
		if ( this._workspace.isSuspended ) return this._alertOfSuspendedWorkspace();
		return null;
	}

	get _userCanCreateProjects() {
		return this._workspace?.isActive;
	}
}
