/* eslint-disable no-bitwise */
/* eslint-disable func-names */
/* eslint-disable no-extend-native */

String.prototype.capitalize = function () {
	return this.replace( /(^\w{1})|(\s+\w{1})/g, char => char.toUpperCase() );
};

Object.defineProperty( String.prototype, 'firstWord', {
	get: function firstWord() {
		return this.split( ' ' ).first;
	}
} );

String.prototype.filter = function filter( predicate ) {
	return this.split( '' ).filter( predicate ).join( '' );
};

String.prototype.pluralize = function ( count ) {
	return count === 1 ? this : `${this}s`;
};
