import { createSelector } from 'reselect';
import AddOn from '../entities/addOn';

const getRawAddOns = state => state.addOns;

export const getAddOns = createSelector(
	getRawAddOns,
	addOns => addOns?.map( AddOn.fromJSON )
);

export const getMemberAddOn = createSelector(
	getAddOns,
	addOns => addOns?.find( addOn => addOn.type === AddOn.membersType )
);
