import { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { mapExtensionsForFileInput } from '../../../lib/fileUtils';
import { ASSET_ALL_EXTENSIONS } from '../../../entities/asset';

import Button from '../../../new_arch/components/Button/Button';
import HistoryPropType from '../../../types/history';
import PopupSystem from '../../../systems/PopupSystem';
import AssetUploadPresenter from '../../../presenters/AssetUploadPresenter/AssetUploadPresenter';
import openEmailClient from '../../../services/openEmailClient';
import Project from '../../../entities/project';
import Folder from '../../../entities/folder';

import './AssetUploaderEmptyBox.scss';
import FileSelection from '../../../services/FileSelector/FileSelection';

class AssetUploaderEmptyBox extends Component {
	constructor( props ) {
		super( props );

		this.fileInputRef = createRef();

		this._selectFilesForUpload = this._selectFilesForUpload.bind( this );
		this._fileChangeHandler = this._fileChangeHandler.bind( this );
	}

	get showUploadButton() {
		return !!this.props.project;
	}

	_selectFilesForUpload() {
		const { current } = this.fileInputRef;
		if ( !current ) { return; }
		current.click();
	}

	_fileChangeHandler( event ) {
		const {
			props: {
				project, folder, createFileUploads, createFolderUploads, popupSystem, history
			}
		} = this;

		new AssetUploadPresenter( {
			fileSelection: FileSelection.fromHTMLInputFiles( event.target.files ),
			project,
			folder,
			createFileUploads,
			createFolderUploads,
			popupSystem,
			openEmailClient,
			history
		} ).trigger();

		event.target.value = '';
	}

	render() {
		const { showUploadButton } = this;

		return (
			<div className={classNames( 'AssetUploaderEmptyBox', { showUploadButton } )}>
				<div className="uploader-header">
					<h3>Upload Box</h3>
				</div>
				<div className="content">
					<h5>No files are being uploaded</h5>
					{showUploadButton && (
						<>
							<Button
								onClick={this._selectFilesForUpload}
							>
								Upload Now
							</Button>
							<input
								type="file"
								ref={this.fileInputRef}
								onChange={this._fileChangeHandler}
								multiple
								accept={mapExtensionsForFileInput( ASSET_ALL_EXTENSIONS )}
							/>
						</>
					)}
				</div>
			</div>
		);
	}
}

AssetUploaderEmptyBox.propTypes = {
	project: PropTypes.instanceOf( Project ),
	folder: PropTypes.instanceOf( Folder ),
	createFileUploads: PropTypes.func,
	createFolderUploads: PropTypes.func,
	popupSystem: PropTypes.instanceOf( PopupSystem ).isRequired,
	history: HistoryPropType.isRequired
};

AssetUploaderEmptyBox.defaultProps = {
	project: null,
	folder: null,
	createFileUploads: () => {},
	createFolderUploads: () => {}
};

export default AssetUploaderEmptyBox;
