import PropTypes from 'prop-types';

import { connectComponent } from '../../lib/connectComponent';
import { getUserCanDeleteWorkspaceInvitation }
	from '../../selectors/workspaces';
import WorkspaceInvitationBox from '../../components/WorkspaceInvitationBox/WorkspaceInvitationBox';
import WorkspaceInvitation from '../../entities/workspaceInvitation';
import Container from '../Container';
import { actionResultIsError } from '../../lib/reduxUtils';
import { isNotAllowedOrNotFound } from '../../errors/common';

export class WorkspaceInvitationContainer extends Container {
	constructor( props ) {
		super( props );

		this._onDeleteClicked = this._onDeleteClicked.bind( this );
	}

	deleteWorkspaceInvitation() {
		const {
			workspaceID, invite, deleteWorkspaceInvitation, showSuccessMessage, showErrorMessage
		} = this.props;
		const successMessage = { title: 'The pending invite has been successfully deleted.' };
		const errorMessage = { title: 'Oops, something went wrong! Try again.' };

		return deleteWorkspaceInvitation( workspaceID, invite.id )
			.then( ( result ) => {
				if ( actionResultIsError( result ) ) {
					throw result.payload;
				}

				showSuccessMessage( successMessage );
			} ).catch( error => !isNotAllowedOrNotFound( error ) && showErrorMessage( errorMessage ) );
	}

	_onDeleteClicked() {
		const { props: { invite } } = this;

		this.showAsyncActionConfirmation(
			`Are you sure you want to uninvite ${invite.email} from the workspace?`,
			'Uninvite',
			'Cancel',
			() => this.deleteWorkspaceInvitation()
		);
	}

	render() {
		const {
			props: { invite, canDelete }
		} = this;

		return (
			<WorkspaceInvitationBox
				key={invite.id}
				invitation={invite}
				onDeleteClicked={this._onDeleteClicked}
				showDeleteIcon={canDelete}
			/>
		);
	}
}

WorkspaceInvitationContainer.propTypes = {
	projectID: PropTypes.number.isRequired,
	invite: PropTypes.instanceOf( WorkspaceInvitation ),
	canDelete: PropTypes.bool
};

WorkspaceInvitationContainer.defaultProps = {
	invite: null,
	canDelete: true
};

export default connectComponent( ( state, props ) => ( {
	canDelete: getUserCanDeleteWorkspaceInvitation( state, props )
} ), true )( WorkspaceInvitationContainer );
