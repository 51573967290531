import { Map } from 'immutable';
import {
	DELETE_PROJECTS,
	LOGOUT,
	CREATE_FOLDER_UPLOADS,
	UPDATE_FOLDER_UPLOAD_STATUS,
	UPDATE_ASSET_UPLOAD_PROGRESS
} from '../actions/types';
import { handleStart, handleSuccess } from '../lib/reduxUtils';

const updateUploadAndAncestors = ( state, uploadID, updater ) => {
	let currentUpload = state.get( uploadID );
	let currentState = state;

	while ( currentUpload ) {
		currentState = currentState.update( currentUpload.id, updater );
		currentUpload = currentState.get( currentUpload.parentUploadID )
	}

	return currentState;
}

export const folderUploads = ( state = new Map(), action ) => {
	switch ( action.type ) {
	case CREATE_FOLDER_UPLOADS:
		return action.payload.reduce(
			( uploads, upload ) => uploads.set( upload.id, upload ),
			state
		);
	case UPDATE_FOLDER_UPLOAD_STATUS: {
		const { id } = action.meta;
		return state.updateIfSet(
			id,
			upload => ( { ...upload, status: action.payload } )
		);
	}
	case UPDATE_ASSET_UPLOAD_PROGRESS: {
		const { newBytesUploaded } = action.payload;
		const { parentUploadID } = action.meta;

		return updateUploadAndAncestors(
			state,
			parentUploadID,
			upload => ( { ...upload, bytesUploaded: upload.bytesUploaded + newBytesUploaded } )
		);
	}
	case DELETE_PROJECTS:
		return handleSuccess(
			state,
			action,
			prevState => action.payload.reduce(
				( result, deletedID ) => result.filter(
					upload => upload.projectID !== deletedID
				),
				prevState
			)
		);
	case LOGOUT:
		return handleStart( state, action, () => new Map() );
	default:
		return state;
	}
};
