const FALLBACK_DISCOUNT = 0;

export default class PricingPlan {
	constructor( {
		id, name, includedProjects, includedMembers, includedStorage, prices = []
	} ) {
		this.id = id;
		this.name = name;
		this.includedProjects = parseFloat( includedProjects );
		this.includedMembers = parseFloat( includedMembers );
		this.includedStorage = includedStorage;
		this.prices = prices;
	}

	get annualDiscount() {
		if ( !this.monthlyPrice || !this.annualPrice ) { return FALLBACK_DISCOUNT; }
		return Math.floor( ( 1 - ( this.annualPriceCost / ( this.monthlyPriceCost * 12 ) ) ) * 100 );
	}

	get monthlyPrice() {
		return this.prices?.find( price => price.isMonthly );
	}

	get freePrice() {
		return this.prices?.find( price => price.isFree );
	}

	get annualPrice() {
		return this.prices?.find( price => price.isAnnualPrice );
	}

	get hasPrices() {
		return this.prices.length > 0;
	}

	get isFree() {
		return this.prices.some( price => price.isFree );
	}

	get hasFreePrice() {
		return this.prices.some( price => price.isFree );
	}

	static fromJSON( properties, prices ) {
		return new PricingPlan( {
			id: properties.id,
			name: properties.name,
			includedProjects: properties.included_projects,
			includedMembers: properties.included_members,
			includedStorage: properties.included_storage_in_bytes,
			prices
		} );
	}

	get monthlyPriceCost() {
		return this.monthlyPrice?.cost;
	}

	get annualPriceCost() {
		return this.annualPrice?.cost;
	}

	get monthlyPriceCostPerMember() {
		return this.monthlyPriceCost / this.includedMembers;
	}

	get annualPriceCostPerMember() {
		return this.annualPriceCost / this.includedMembers;
	}
}
