import type Asset from '../asset';
import { type FilterOption } from './types';

export default class LabelFilter implements FilterOption {
	labelId: number | null;

	constructor( labelId: number | null ) {
		this.labelId = labelId;
	}

	get id() {
		return `label-${this.labelId}`;
	}

	isPassedBy( asset: Asset ) {
		return ( asset.label?.id || null ) === this.labelId;
	}

	toJson() {
		return { label: this.labelId }
	}
}
