import { useHistory } from 'react-router';
import Button from '../../../components/Button/Button';
import useFetchCurrentUserQuery from '../../authentication/queries/useFetchCurrentUserQuery';
import useLogout from '../../authentication/queries/useLogout';

import './FreeAccountPushForGuestsButton.scss';

const FreeAccountPushForGuestsButton = () => {
	const { data: currentUser } = useFetchCurrentUserQuery();
	const { logout } = useLogout();
	const history = useHistory();
	const onSignUpClicked = ( event : React.MouseEvent ) => {
		event.preventDefault();
		logout();
		history.replace( '/signup', {
			isGuestSignup: true,
			email: currentUser?.email
		} );
	}

	return (
		<Button
			onClick={onSignUpClicked}
			className="FreeAccountPushForGuestsButton"
		>
			Create free account
		</Button>
	)
};

export default FreeAccountPushForGuestsButton;
