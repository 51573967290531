import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { countries } from 'countries-list';
import { firstBy } from 'thenby';

import Select from '../UI/Select/Select';

const OPTIONS = Object.keys( countries )
	.map( countryCode => ( { label: countries[ countryCode ].name, value: countryCode } ) )
	.sort(
		firstBy( ( { value } ) => value === 'US', -1 )
			.thenBy( ( { value } ) => value === 'CA', -1 )
			.thenBy( 'label' )
	);

const CountrySelector = ( {
	initialCountryCode, onChange, required, isSearchable, disabled
} ) => {
	const initialCountry = OPTIONS.find( ( { value } ) => value === initialCountryCode );
	const [ selected, setSelected ] = useState( initialCountry );

	useEffect(
		() => onChange( selected ),
		[ selected ]
	);

	return (
		<div className="CountrySelector">
			<Select
				initialValue={initialCountry}
				name="country"
				type="tall"
				label="Country"
				options={OPTIONS}
				value={selected}
				onChange={setSelected}
				placeholder="Select a Country"
				required={required}
				isSearchable={isSearchable}
				disabled={disabled}
			/>
		</div>
	);
};

CountrySelector.propTypes = {
	initialCountryCode: PropTypes.string,
	onChange: PropTypes.func,
	required: PropTypes.bool,
	isSearchable: PropTypes.bool,
	disabled: PropTypes.bool
};

CountrySelector.defaultProps = {
	initialCountryCode: undefined,
	onChange: () => {},
	required: false,
	isSearchable: true,
	disabled: false
};

export default CountrySelector;
