import { observer } from 'mobx-react-lite';
import CloseableModalWithTitle from '../CloseableModalWithTitle/CloseableModalWithTitle';
import FormInput from '../../new_arch/components/FormInput/FormInput';
import EmailsInput from './EmailsInput/EmailsInput';
import Button from '../../new_arch/components/Button/Button';
import { preventDefaultAndCall } from '../../new_arch/lib/eventUtils';
import useCreateOrEditContactGroupModalPresenter from '../../presenters/CreateOrEditContactGroupModalPresenter/useCreateOrEditContactGroupModalPresenter';
import { type ContactGroup } from '../../types/contactGroup';

import './CreateOrEditContactGroupModal.scss';

type CreateOrEditContactGroupModalProps = {
	workspaceID: number,
	mode: 'create' | 'edit',
	contactGroup?: ContactGroup,
	onClose: () => void
};

const CreateOrEditContactGroupModal = ( {
	workspaceID,
	mode,
	contactGroup,
	onClose
}: CreateOrEditContactGroupModalProps ) => {
	const presenter = useCreateOrEditContactGroupModalPresenter( { workspaceID, mode, contactGroup, onClose } );

	return (
		<CloseableModalWithTitle
			headerBorderType="small"
			onClose={onClose}
			name="contact-group-form"
			title={presenter.title}
		>
			<div className="CreateOrEditContactGroupModal">
				<form onSubmit={preventDefaultAndCall( presenter.onSubmit )}>
					<FormInput
						value={presenter.name}
						error={presenter.error}
						onChange={presenter.onNameChanged}
						label="Group Name"
						name="group-name"
						placeholder="Enter your group name..."
						autoFocus
					/>
					<EmailsInput
						emails={presenter.emails}
						onChange={presenter.onEmailsChanged}
						label="Emails"
						placeholder="Use enter, space or tab to add emails"
					/>
					<div className="actions">
						<Button buttonType="button" color="secondary" onClick={onClose}>Cancel</Button>
						<Button disabled={presenter.isSubmitButtonDisabled}>{presenter.submitButtonLabel}</Button>
					</div>
				</form>
			</div>
		</CloseableModalWithTitle>
	);
}

export default observer( CreateOrEditContactGroupModal );
