import { sharedTracker } from '../services/tracker/Tracker';

const toggleItemRushPriority = (
	itemsSelector,
	itemID,
	hasRushPriority,
	baseApiURL,
	metaKey,
	setactionType,
	unsetActionType,
	trackAction,
	flaggedEventName,
	unflaggedEventName,
	nameField
) => ( dispatch, getState, api ) => {
	const item = itemsSelector( getState(), { [ metaKey ]: itemID } );
	if ( item && item.hasRushPriority === hasRushPriority ) {
		return Promise.resolve( itemID );
	}

	sharedTracker[ trackAction ](
		hasRushPriority ? flaggedEventName : unflaggedEventName,
		item && item[ nameField ]
	);

	const { type, endpoint } = hasRushPriority
		? { type: setactionType, endpoint: 'set_rush_priority' }
		: { type: unsetActionType, endpoint: 'unset_rush_priority' };

	const promise = api
		.post( `${baseApiURL}/${itemID}/${endpoint}` )
		.then( () => itemID );

	return dispatch( { type, promise, meta: { [ metaKey ]: itemID } } );
};

export default toggleItemRushPriority;
