import {
	CLOSE_REACHED_PROJECT_LIMIT_WARNING,
	CLOSE_PENDING_DOWNGRADE_WARNING,
	CLOSE_SUSPENDED_FOR_DOWNGRADE_WARNING,
	CLOSE_EXPIRED_FREE_TRIAL_POPUP
} from './types';

export const REACHED_PROJECT_LIMIT_WARNING_BANNER_KEY = 'REACHED_PROJECT_LIMIT_WARNING';
export const SUSPENDED_FOR_DOWNGRADE_WARNING_POPUP_KEY = 'SUSPENDED_FOR_DOWNGRADE_WARNING';
export const PENDING_DOWNGRADE_WARNING_BANNER_KEY = 'PENDING_DOWNGRADE_WARNING';
export const EXPIRED_FREE_TRIAL_POPUP_KEY = 'EXPIRED_FREE_TRIAL_POPUP';

export const closeReachedProjectLimitWarning = () => ( {
	type: CLOSE_REACHED_PROJECT_LIMIT_WARNING,
	payload: REACHED_PROJECT_LIMIT_WARNING_BANNER_KEY
} );

export const closeSuspendedForDowngradeWarning = () => ( {
	type: CLOSE_SUSPENDED_FOR_DOWNGRADE_WARNING,
	payload: SUSPENDED_FOR_DOWNGRADE_WARNING_POPUP_KEY
} );

export const closePendingDowngradeWarning = () => ( {
	type: CLOSE_PENDING_DOWNGRADE_WARNING,
	payload: PENDING_DOWNGRADE_WARNING_BANNER_KEY
} );

export const closeExpiredFreeTrialPopupWarning = () => ( {
	type: CLOSE_EXPIRED_FREE_TRIAL_POPUP,
	payload: EXPIRED_FREE_TRIAL_POPUP_KEY
} );
