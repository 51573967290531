import { Map } from 'immutable';
import { handleStart, handleSuccess } from '../lib/reduxUtils';

import {
	FETCH_WORKSPACE_MEMBERS, UPDATE_WORKSPACE_MEMBER_ROLE, DELETE_WORKSPACE_MEMBER, LOGOUT,
	FETCH_WORKSPACE_MEMBER_COLLABORATORS
} from '../actions/types';

const updateWorkspaceMemberRole = ( workspacesMembers, memberID, role ) => {
	if ( !role ) return workspacesMembers;

	return workspacesMembers
		.updateIfSet(
			memberID,
			member => ( { ...member, role } )
		);
};

export const workspacesMembers = ( state = new Map(), action ) => {
	switch ( action.type ) {
	case FETCH_WORKSPACE_MEMBERS:
	case FETCH_WORKSPACE_MEMBER_COLLABORATORS:
		return handleSuccess(
			state,
			action,
			( prevState ) => {
				const { workspaceID } = action.meta;
				const membersWithWorkspaceID = action.payload
					.map( member => ( {
						...member,
						workspace_id: workspaceID
					} ) );
				return prevState
					.replaceMatchingWithPayload( membersWithWorkspaceID, workspaceID, 'workspace_id' );
			}
		);
	case UPDATE_WORKSPACE_MEMBER_ROLE:
		return handleSuccess(
			state, action,
			( prevState ) => {
				const { memberID, roleID } = action.payload;
				return updateWorkspaceMemberRole( prevState, memberID, roleID );
			}
		);
	case DELETE_WORKSPACE_MEMBER:
		return handleSuccess(
			state,
			action,
			( prevState ) => {
				const { memberID } = action.meta;

				return prevState.delete( memberID );
			}
		);
	case LOGOUT:
		return handleStart( state, action, () => new Map() );
	default:
		return state;
	}
};
