import { Map } from 'immutable';
import { normalize } from 'normalizr';
import { handle } from 'redux-pack';
import {
	CANCEL_SUBSCRIPTION,
	CREATE_SUBSCRIPTION,
	CREATE_WORKSPACE,
	DELETE_WORKSPACE,
	DELETE_WORKSPACE_INVITATION,
	DELETE_WORKSPACE_MEMBER,
	FETCH_WORKSPACES,
	FETCH_WORKSPACE_BILLING_INFORMATION,
	FETCH_WORKSPACE_INVITATIONS,
	FETCH_WORKSPACE_INVOICES,
	FETCH_WORKSPACE_INVOICES_NEXT_PAGE,
	FETCH_WORKSPACE_MEMBERS,
	FETCH_WORKSPACE_MEMBER_COLLABORATORS,
	FETCH_WORKSPACE_PAYMENT_METHOD,
	FETCH_WORKSPACE_PROJECTS,
	INVITE_WORKSPACE_MEMBER,
	LOGOUT,
	REACTIVATE_SUBSCRIPTION,
	DOWNGRADE_SUBSCRIPTION,
	UPDATE_WORKSPACE_BILLING_INFORMATION,
	UPDATE_WORKSPACE_MEMBER_ROLE,
	UPDATE_WORKSPACE_PAYMENT_METHOD,
	REMOVE_ADD_ON,
	BUY_ADD_ON,
	CANCEL_DOWNGRADE_SUBSCRIPTION
} from '../actions/types';
import { isNotFoundError } from '../errors/common';
import { normalizeResponse } from '../lib/normalizeUtils';
import { handleStart, handleSuccess } from '../lib/reduxUtils';
import { workspace as workspaceScheme, subscription as subscriptionScheme } from './schemes';

const normalizeSubscriptionsFromWorkspace = ( actionPayload ) => {
	const subscriptions = actionPayload
		.map( workspace => workspace.subscription )
		.filter( subscription => !!subscription );
	return normalizeResponse(
		Map(), subscriptions, subscriptionScheme, 'subscriptions'
	);
};

const deleteSubscriptionFromWorkspace = ( workspaceID, prevState ) => (
	prevState.filter(
		subscription => subscription.workspace_id !== workspaceID
	)
);

const normalizeSubscriptionFromWorkspace = ( workspaceProperties, prevState ) => {
	if ( !workspaceProperties.subscription ) {
		return deleteSubscriptionFromWorkspace( workspaceProperties.id, prevState );
	}
	const subscriptionID = workspaceProperties.subscription.id;
	return (
		prevState.update(
			subscriptionID,
			( subscription ) => {
				const newNormalizedSubscription = normalize(
					workspaceProperties, workspaceScheme
				).entities.subscriptions[ subscriptionID ];
				const previousPricingPlanPrice = subscription && subscription.pricing_plan_price;
				const currentPricingPlanPrice = newNormalizedSubscription.pricing_plan_price;
				return ( {
					...newNormalizedSubscription,
					pricing_plan_price: previousPricingPlanPrice || currentPricingPlanPrice
				} );
			}
		) );
};

const failedSubscriptionActionHandler = action => ( prevState ) => {
	const { workspaceID } = action.meta;
	const error = action.payload;

	return isNotFoundError( error )
		? deleteSubscriptionFromWorkspace( workspaceID, prevState )
		: prevState;
};

export const subscriptions = ( state = Map(), action ) => {
	switch ( action.type ) {
	case FETCH_WORKSPACES:
		return handleSuccess(
			state,
			action,
			() => normalizeSubscriptionsFromWorkspace( action.payload )
		);
	case DELETE_WORKSPACE:
		return handle(
			state,
			action,
			{
				success: prevState => deleteSubscriptionFromWorkspace( action.meta.workspaceID, prevState ),
				failure: failedSubscriptionActionHandler( action )
			}
		);
	case CREATE_WORKSPACE:
		return handleSuccess(
			state,
			action,
			prevState => normalizeSubscriptionFromWorkspace( action.payload, prevState )
		);
	case CANCEL_SUBSCRIPTION:
		return handle(
			state,
			action,
			{
				success: prevState => prevState.updateIfSet(
					action.meta.subscriptionID,
					subscription => ( { ...subscription, cancel_at_period_end: true } )
				),
				failure: failedSubscriptionActionHandler( action )
			}
		);
	case REACTIVATE_SUBSCRIPTION:
		return handle(
			state,
			action,
			{
				success: prevState => prevState.updateIfSet(
					action.meta.subscriptionID,
					subscription => ( { ...subscription, cancel_at_period_end: false } )
				),
				failure: failedSubscriptionActionHandler( action )
			}
		);
	case DOWNGRADE_SUBSCRIPTION:
	case INVITE_WORKSPACE_MEMBER:
	case CREATE_SUBSCRIPTION:
	case CANCEL_DOWNGRADE_SUBSCRIPTION:
		return handle(
			state,
			action,
			{
				success: prevState => normalizeSubscriptionFromWorkspace( action.payload, prevState ),
				failure: failedSubscriptionActionHandler( action )
			}
		);
	case BUY_ADD_ON:
	case REMOVE_ADD_ON:
		return handleSuccess(
			state,
			action,
			prevState => normalizeSubscriptionFromWorkspace( action.payload, prevState )
		);
	case FETCH_WORKSPACE_INVITATIONS:
	case DELETE_WORKSPACE_INVITATION:
	case FETCH_WORKSPACE_INVOICES:
	case FETCH_WORKSPACE_INVOICES_NEXT_PAGE:
	case FETCH_WORKSPACE_MEMBERS:
	case FETCH_WORKSPACE_MEMBER_COLLABORATORS:
	case UPDATE_WORKSPACE_MEMBER_ROLE:
	case DELETE_WORKSPACE_MEMBER:
	case FETCH_WORKSPACE_PROJECTS:
	case FETCH_WORKSPACE_BILLING_INFORMATION:
	case FETCH_WORKSPACE_PAYMENT_METHOD:
	case UPDATE_WORKSPACE_BILLING_INFORMATION:
	case UPDATE_WORKSPACE_PAYMENT_METHOD:
		return handle(
			state,
			action,
			{ failure: failedSubscriptionActionHandler( action ) }
		);
	case LOGOUT:
		return handleStart( state, action, () => new Map() );
	default:
		return state;
	}
};
