import {
	action, computed, makeObservable, observable
} from 'mobx';
import SubclassResponsabilityError from '../../customErrors/SubclassResponsabilityError';
import AddOnModalPresenter from '../AddOnsModal/AddOnsModalPresenter';
import { PRICING_PLANS_AVAILABLE } from '../../config/global';

export default class UsagePresenter {
	constructor( {
		popupSystem,
		addOnSystem,
		snackbarSystem,
		workspace,
		addOns,
		history,
		onPaidFeatureModalAcceptClick
	} ) {
		this.popupSystem = popupSystem;
		this.addOnSystem = addOnSystem;
		this.snackbarSystem = snackbarSystem;
		this.workspace = workspace;
		this.addOns = addOns;
		this.history = history;
		this._onPaidFeatureModalAcceptClick = onPaidFeatureModalAcceptClick;

		this.addOnsModalPresenter = new AddOnModalPresenter( this );

		makeObservable( this, {
			workspace: observable,
			addOns: observable,
			addOnsModalPresenter: observable,
			addOnsModalIsOpen: computed,
			showAddOnsButton: computed,
			isSubscriptionActive: computed,
			addOnsButtonWasClicked: action,
			addAddOns: action,
			alertOfPaidFeature: action
		} );
	}

	get addOnsModalIsOpen() {
		return this.addOnsModalPresenter.isOpen;
	}

	get showAddOnsButton() {
		return this.workspace.currentUserIsOwner;
	}

	get isSubscriptionActive() {
		return this.workspace.isSubscriptionActive;
	}

	get selectedAddOnType() {
		throw SubclassResponsabilityError();
	}

	get disabledAddOnButton() {
		return !PRICING_PLANS_AVAILABLE || ( this.workspace.isSuspended && this.workspace.hasAPaidPlan );
	}

	addOnsButtonWasClicked = () => {
		this.workspace.addAddOnsWith( this );
	};

	addAddOns = () => {
		this.addOnsModalPresenter.open();
	}

	alertOfPaidFeature = () => {
		this.popupSystem.showDialogWithIcon( {
			title: 'You\'ve found a paid feature',
			description: 'Functionality not available for Free Trial users.',
			acceptText: 'Upgrade',
			isError: true
		} ).then( accept => accept && this._onPaidFeatureModalAcceptClick() );
	}
}
