import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import Button from '../../new_arch/components/Button/Button';
import Loader from '../../components/Loader/Loader';
import StorageUsagePresenter from '../../presenters/StorageUsagePresenter/StorageUsagePresenter';
import CloseableModalWithTitle from '../CloseableModalWithTitle/CloseableModalWithTitle';
import PriceLine from '../../components/subscription/PriceLine/PriceLine';
import AddOnsSwitch from '../../components/AddOnsSwitch/AddOnsSwitch';

import './AddOnsModal.scss';
import { PRICING_PLANS_AVAILABLE } from '../../config/global';

export const AddOnsModal = ( {
	presenter
} ) => {
	useEffect( presenter.onMount, [] );

	return PRICING_PLANS_AVAILABLE && (
		<CloseableModalWithTitle
			headerBorderType="small"
			onClose={presenter.close}
			name="add-ons"
			title="Customize your plan"
		>
			<div className="AddOnsModal">
				<AddOnsSwitch
					onSwitch={presenter.addOnTypeWasSwitched}
					selectedAddOnType={presenter.selectedAddOnType}
				/>
				<Loader active={presenter.loading} />
				{!presenter.loading && (
					<div className="AddOnsModalContent">
						<h2>{presenter.title}</h2>
						<h6 className="modalDescription">{presenter.text}</h6>
						<PriceLine
							cost={presenter.cost}
							costDescription={presenter.costDescription}
							tooltipInfoText={presenter.infoText}
						/>
						<div className="quantityTextBox">
							{presenter.hasAddOns && (
								<span className="quantityText">
									{presenter.quantityText}
								</span>
							)}
						</div>
						<Button onClick={presenter.buyButtonWasClicked}>
							{presenter.buttonText}
						</Button>
						{presenter.hasAddOns && (
							<Button color="secondary" onClick={presenter.removeButtonWasClicked}>
								{presenter.removeButtonText}
							</Button>
						)}
					</div>
				)}
			</div>
		</CloseableModalWithTitle>

	);
};

AddOnsModal.propTypes = {
	presenter: PropTypes.instanceOf( StorageUsagePresenter ).isRequired
};

AddOnsModal.defaultProps = {
};

export default observer( AddOnsModal );
