export function onFieldChange( field, value ) {
	this.validate?.();
	this.setState(
		prevState => ( {
			form: prevState.form.set( field, value ),
			editedFields: {
				...prevState.editedFields,
				[ field ]: false
			}
		} )
	);
}

export function onFieldBlur( field ) {
	if ( !this.state.form.data[ field ] ) { return; }
	this.setState(
		prevState => ( {
			editedFields: {
				...prevState.editedFields,
				[ field ]: true
			}
		} )
	);
}

export function getValidationErrorMessage( field ) {
	this.validate?.();
	const { editedFields, form: { errors } } = this.state;
	return editedFields && editedFields[ field ] && errors[ field ] && errors[ field ][ 0 ];
}
