import Snackbar from './Snackbar/Snackbar';
import { type SnackbarListProps } from './types';
import './SnackbarList.scss';

const SnackbarList = ( { snackbarsByChannel }: SnackbarListProps ) => (
	<div className="SnackbarList">
		{
			Object.keys( snackbarsByChannel ).map( ( channel ) => {
				const snackbar = snackbarsByChannel[ channel ];

				return (
					<Snackbar
						key={channel}
						type={snackbar.type}
						title={snackbar.title}
						description={snackbar.description}
						onClose={snackbar.onClose}
					/>
				);
			} )
		}
	</div>
);

export default SnackbarList;
