import PropTypes from 'prop-types';
import { Rect as KonvaRect } from 'react-konva';
import tinycolor from 'tinycolor2';

const MarkupRect = ( {
	rect, scale, opacity, ...props
} ) => (
	<KonvaRect
		x={rect.position.x * scale.x}
		y={rect.position.y * scale.y}
		width={rect.width * scale.x}
		height={rect.height * scale.y}
		stroke={tinycolor( rect.strokeColor ).setAlpha( opacity ).toRgbString()}
		strokeWidth={rect.strokeSize || 1}
		hitStrokeWidth={20}
		perfectDrawEnabled={false}
		lineJoin="miter"
		{...props}
	/>
);

MarkupRect.propTypes = {
	rect: PropTypes.any.isRequired,
	scale: PropTypes.any.isRequired,
	opacity: PropTypes.number
};

MarkupRect.defaultProps = {
	opacity: 1
};

export default MarkupRect;
