import PropTypes from 'prop-types';
import { createElement } from 'react';

const ConditionalWrapper = ( {
	condition, wrapper, wrapperProps, children
} ) => (
	condition
		? createElement(
			wrapper,
			wrapperProps,
			children
		)
		: children
);

ConditionalWrapper.propTypes = {
	condition: PropTypes.bool.isRequired,
	wrapper: PropTypes.node.isRequired,
	wrapperProps: PropTypes.any,
	children: PropTypes.element.isRequired
};

ConditionalWrapper.defaultProps = {
	wrapperProps: {}
};

export default ConditionalWrapper;
