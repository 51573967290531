import { DefaultRootState } from 'react-redux';
import { DARK_GREY } from '../../styles/colors';
import ProfileWithDropdownActions from '../../components/ProfileWithDropdownActions/ProfileWithDropdownActions';
import { getCurrentUserOwnsSingleWorkspace, getFirstWorkspaceInWorkspacesArray } from '../../selectors/workspaces';
import useNavigate from '../../new_arch/navigation/useNavigate';
import User from '../../new_arch/models/user/user';
import Workspace from '../../entities/workspace';
import useLogout from '../../new_arch/modules/authentication/queries/useLogout';
import { connectComponent } from '../../lib/connectComponent';
import { getCurrentUser } from '../../selectors/currentUser';
import { useIsGuest } from '../../hooks/useIsGuest';
import FreeAccountPushForGuests from '../../components/FreeAccountPushForGuests/FreeAccountPushForGuests';

type Props = {
	defaultWorkspace: Workspace | undefined,
	userOwnsOneWorkspace: boolean,
	currentUser: User | undefined,
}

export const ProfileWithDropdownActionsContainer = (
	{ defaultWorkspace, userOwnsOneWorkspace, currentUser } : Props
) => {
	const navigate = useNavigate();
	const { logout } = useLogout();
	const isGuest = useIsGuest();

	const badgeForWorkspaceStatus = {
		text: defaultWorkspace?.status.text,
		dangerStyle: !defaultWorkspace?.status.isActiveStatus
	};

	const onLogoutClicked = () => {
		if ( window.confirm( 'Are you sure you want to log out?' ) ) {
			logout();
		}
	};

	const showsWorkspaceStatusBadge = userOwnsOneWorkspace && !!defaultWorkspace
			&& defaultWorkspace.status.showsInProfileDropdown;

	if ( !currentUser ) return null;

	const nameAndAvatar = {
		name: currentUser.name,
		description: isGuest ? '' : currentUser.email,
		image: currentUser.avatar,
		onClick: () => {},
		size: 20,
		color: DARK_GREY
	};

	const guestDropdownActions = [
		nameAndAvatar,
		{
			...<FreeAccountPushForGuests />,
			classNames: 'free-account-push-item',
			onClick: () => {}
		}
	];

	const profileDropdownActions = [
		nameAndAvatar,
		{
			name: 'Settings',
			description: 'My account, Workspaces & Billing',
			icon: 'settings',
			onClick: navigate.toProfile,
			size: 20,
			color: DARK_GREY,
			badge: showsWorkspaceStatusBadge && badgeForWorkspaceStatus
		},
		{
			name: 'Logout',
			icon: 'exit_to_app',
			onClick: onLogoutClicked,
			size: 20,
			color: DARK_GREY
		}
	];

	const actions = isGuest ? guestDropdownActions : profileDropdownActions;

	return <ProfileWithDropdownActions actions={actions} currentUser={currentUser} isGuest={isGuest} />
};

type ReduxSelector<R> = ( state: DefaultRootState, props: Props ) => R

const getDefaultWorkspace = getFirstWorkspaceInWorkspacesArray as ReduxSelector<Workspace | undefined>;
const getUserOwnsWorkspace = getCurrentUserOwnsSingleWorkspace as ReduxSelector<boolean>;

export default connectComponent( ( state: DefaultRootState, props: Props ) => ( {
	defaultWorkspace: getDefaultWorkspace( state, props ),
	userOwnsOneWorkspace: getUserOwnsWorkspace( state, props ),
	currentUser: getCurrentUser( state )
} ) )( ProfileWithDropdownActionsContainer );
