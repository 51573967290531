import { observer } from 'mobx-react-lite';
import useIsLoggedIn from '../hooks/useIsLoggedIn';
import { useIsGuest } from '../hooks/useIsGuest';
import { renderOrRedirect } from '../lib/renderUtils';
import { unsetLoggingOutState } from './history';

export const withAuthentication = ( Screen, { disableForGuests = false } = {} ) => observer( ( props ) => {
	const isLoggedIn = useIsLoggedIn();
	const isGuest = useIsGuest();

	return renderOrRedirect( {
		component: <Screen {...props} />,
		redirectIf: !isLoggedIn || ( isGuest && disableForGuests ),
		redirectTo: { pathname: '/sign-in', state: { from: props.location.pathname } },
		onRedirect: unsetLoggingOutState
	} );
} );

export const withoutAuthentication = Screen => observer( ( props ) => {
	const isLoggedIn = useIsLoggedIn();
	const isGuest = useIsGuest();

	return renderOrRedirect( {
		component: <Screen {...props} />,
		redirectIf: isLoggedIn && !isGuest,
		redirectTo: { pathname: props.location.state?.from || '/projects' }
	} );
} );
