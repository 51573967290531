import PropTypes from 'prop-types';

import Workspace from '../../entities/workspace';
import WorkspaceProjectListContainer from '../../containers/WorkspaceProjectListContainer/WorkspaceProjectListContainer';
import WorkspaceContactGroupsContainer from '../../containers/WorkspaceContactGroupsContainer/WorkspaceContactGroupsContainer';
import WorkspaceLabelGroupsContainer from '../../containers/WorkspaceLabelGroupsContainer/WorkspaceLabelGroupsContainer';

const WorkspaceProjectsTab = ( {
	workspaceID, workspace
} ) => (
	<div className="WorkspaceProjectsTab">
		<WorkspaceProjectListContainer
			workspaceID={workspaceID}
			workspace={workspace}
		/>
		<WorkspaceContactGroupsContainer
			workspaceID={workspaceID}
		/>
		<WorkspaceLabelGroupsContainer
			workspaceID={workspaceID}
		/>
	</div>
);

WorkspaceProjectsTab.propTypes = {
	workspaceID: PropTypes.number,
	workspace: PropTypes.instanceOf( Workspace ).isRequired
};

WorkspaceProjectsTab.defaultProps = {
	workspaceID: undefined
};

export default WorkspaceProjectsTab;
