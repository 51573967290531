import { useState } from 'react';
import PromoCodeInputPresenter from './PromoCodeInputPresenter';
import Api from '../../services/Api';
import StripePaymentService from '../../services/PaymentService/StripePaymentService';
import Coupon from '../../entities/coupon';
import PricingPlanPrice from '../../entities/pricing/pricingPlanPrice';

type UsePromoCodeInputPresenterParams = {
	onPromoCodeApplied: ( appliedPromoCodeID: string | null, appliedCoupon: Coupon | null ) => void;
	pricingPlanPrice: PricingPlanPrice;
};

const usePromoCodeInputPresenter = ( { onPromoCodeApplied, pricingPlanPrice }: UsePromoCodeInputPresenterParams ) => {
	const api = new Api();

	const [ presenter ] = useState( () => {
		const paymentService = new StripePaymentService( api );
		return new PromoCodeInputPresenter( { onPromoCodeApplied, paymentService, pricingPlanPrice } )
	} );

	return presenter;
};

export default usePromoCodeInputPresenter;
