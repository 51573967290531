import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import useAutoupdatePresenterProperty from '../../hooks/useAutoupdatePresenterProperty';
import LeaveWorkspaceTabPresenter from './LeaveWorkspaceTabPresenter';
import { getWorkspace } from '../../selectors/workspaces';
import {
	leaveWorkspace as leaveWorkspaceAction
} from '../../actions/workspaces';
import {
	showErrorMessage as showErrorMessageAction,
	showSuccessMessage as showSuccessMessageAction
} from '../../actions/snackbars';

import PopupSystem from '../../systems/PopupSystem';

const useLeaveWorkspaceTabPresenter = ( { workspaceID } ) => {
	const dispatch = useDispatch();
	const history = useHistory();
	// Redux State
	const workspace = useSelector(
		state => getWorkspace( state, { workspaceID } )
	);

	const createPresenter = () => {
		// Redux Actions
		const popupSystem = new PopupSystem( dispatch );
		const leaveWorkspace = ( ...args ) => dispatch(
			leaveWorkspaceAction( ...args )
		);
		const showSuccessMessage = ( ...args ) => dispatch( showSuccessMessageAction( ...args ) );
		const showErrorMessage = ( ...args ) => dispatch( showErrorMessageAction( ...args ) );

		return new LeaveWorkspaceTabPresenter( {
			popupSystem,
			workspace,
			leaveWorkspace,
			showSuccessMessage,
			showErrorMessage,
			history
		} );
	};

	const [ presenter ] = useState( createPresenter );

	useAutoupdatePresenterProperty( { presenter, name: 'workspace', value: workspace } );

	return presenter;
};

export default useLeaveWorkspaceTabPresenter;
