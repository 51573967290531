import { handleStart } from '../lib/reduxUtils';
import { LOGOUT } from '../actions/types';
import { UPDATER_ACTION_TYPE } from '../new_arch/services/ReduxStoreUpdater';

const getIDIfDefined = properties => (
	properties ? properties.id : undefined
);

const parseCurrentUser = properties => ( {
	userID: properties.user.id,
	userPreferencesID: getIDIfDefined( properties.user.user_preference ),
	notificationsPreferencesID: getIDIfDefined( properties.user.notifications_preferences )
} );

export const currentUser = ( state = null, action ) => {
	switch ( action.type ) {
	case UPDATER_ACTION_TYPE.currentUser:
		return parseCurrentUser( action.payload );
	case LOGOUT:
		return handleStart( state, action, () => null );
	default:
		return state;
	}
};
