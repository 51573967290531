import { Component } from 'react';
import PropTypes from 'prop-types';
import { connectComponent } from '../../lib/connectComponent';
import { getShowingAssetRound, getRoundsForAsset, getUserIsComparingRounds } from '../../selectors/assetRounds';
import { getAsset } from '../../selectors/assets';
import ItemViewMode from '../../viewEntities/itemViewMode';

import AssetDetailsHeader from '../../components/assets/AssetDetailsHeader/AssetDetailsHeader';

import Asset from '../../entities/asset';
import AssetRound from '../../entities/assetRound';
import { LocationPropType } from '../../types/history';

export class AssetDetailsHeaderContainer extends Component {
	constructor( props ) {
		super( props );

		this._onSelectedRoundChanged = this._onSelectedRoundChanged.bind( this );
	}

	get viewModeSelected() {
		const { props: { location } } = this;
		const params = new URLSearchParams( location.search );
		return params.has( 'mode' ) && params.get( 'mode' ) === 'list'
			? ItemViewMode.LIST
			: ItemViewMode.GRID;
	}

	_onSelectedRoundChanged( round ) {
		const { props: { asset, setSelectedAssetRound } } = this;
		if ( !setSelectedAssetRound || !round ) { return; }
		setSelectedAssetRound( asset.id, round.id );
	}

	render() {
		const {
			props: {
				asset, selectedRound, rounds,
				projectID, userIsComparingRounds,
				isGuest
			}
		} = this;

		return (
			<AssetDetailsHeader
				asset={asset}
				selectedRound={selectedRound}
				rounds={rounds}
				onSelectedRoundChange={this._onSelectedRoundChanged}
				projectID={projectID}
				userIsComparingRounds={userIsComparingRounds}
				hideHomeIcon={isGuest}
			/>
		);
	}
}

AssetDetailsHeaderContainer.propTypes = {
	asset: PropTypes.instanceOf( Asset ),
	selectedRound: PropTypes.instanceOf( AssetRound ),
	rounds: PropTypes.arrayOf( PropTypes.instanceOf( AssetRound ) ),
	setSelectedAssetRound: PropTypes.func,
	location: LocationPropType.isRequired,
	projectID: PropTypes.number,
	userIsComparingRounds: PropTypes.bool,
	isGuest: PropTypes.bool
};

AssetDetailsHeaderContainer.defaultProps = {
	asset: null,
	selectedRound: null,
	rounds: [],
	setSelectedAssetRound: undefined,
	projectID: undefined,
	userIsComparingRounds: false,
	isGuest: false
};

export default connectComponent( ( state, props ) => ( {
	asset: getAsset( state, props ),
	selectedRound: getShowingAssetRound( state, props ),
	rounds: getRoundsForAsset( state, props ),
	userIsComparingRounds: getUserIsComparingRounds( state )
} ) )( AssetDetailsHeaderContainer );
