/* eslint-disable no-extend-native, func-names */

Math.discountPercentage = function ( totalAmount, amountWithDiscount ) {
	return ( ( totalAmount - amountWithDiscount ) / totalAmount ) * 100;
};

Math.clamp = function ( number, min, max ) {
	if ( number > max ) return max;
	if ( number < min ) return min;
	return number;
};

Math.logBase = function ( base, value ) {
	return Math.log( value ) / Math.log( base );
};
