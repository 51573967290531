import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import NotificationsSidePanelContainer
	from '../../../containers/NotificationsSidePanelContainer/NotificationsSidePanelContainer';

import './NotificationsButton.scss';

import ShowElementToggler from '../../ShowElementToggler/ShowElementToggler';
import IconButton from '../../UI/IconButton/IconButton';
import Tooltip from '../../UI/Tooltip/Tooltip';

const bellRender = ( onOpen, hasUnseenNotifications ) => (
	<>
		<IconButton
			buttonClassName="notifcations"
			onClick={onOpen}
			size={18}
			icon="notifications"
			color="white"
		/>
		{hasUnseenNotifications && (
			<div className="new-notifications-indicator" />
		)}
	</>
);

const NotificationsButton = forwardRef( ( {
	hasUnseenNotifications, onNotificationClicked, onNotificationProjectClicked, onNotificationFolderClicked
}, ref ) => (
	<ShowElementToggler
		ref={ref}
		className="NotificationsButton"
		renderToggler={( onOpen, isModalOpen ) => (
			isModalOpen
				? bellRender( onOpen, hasUnseenNotifications )
				: <Tooltip title="Notifications">{bellRender( onOpen, hasUnseenNotifications )}</Tooltip>
		)}
		renderComponent={onClose => (
			<NotificationsSidePanelContainer
				onClose={onClose}
				onNotificationClicked={onNotificationClicked}
				onNotificationProjectClicked={onNotificationProjectClicked}
				onNotificationFolderClicked={onNotificationFolderClicked}
			/>
		)}
	/>
) );

NotificationsButton.propTypes = {
	hasUnseenNotifications: PropTypes.bool,
	onNotificationClicked: PropTypes.func,
	onNotificationProjectClicked: PropTypes.func,
	onNotificationFolderClicked: PropTypes.func
};

NotificationsButton.defaultProps = {
	hasUnseenNotifications: false,
	onNotificationClicked: undefined,
	onNotificationProjectClicked: undefined,
	onNotificationFolderClicked: undefined
};

export default NotificationsButton;
