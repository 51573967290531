import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useAutoupdatePresenterProperty from '../../hooks/useAutoupdatePresenterProperty';
import { getCurrentUser } from '../../selectors/currentUser';
import PopupSystem from '../../systems/PopupSystem';
import SnackbarSystem from '../../systems/SnackbarSystem';
import UserPreferencesSystem from '../../systems/UserPreferencesSystem';
import TwoFactorAuthenticationCardPresenter from './TwoFactorAuthenticationCardPresenter';

const useTwoFactorAuthenticationCardPresenter = () => {
	const dispatch = useDispatch();

	const currentUser = useSelector(
		state => getCurrentUser( state )
	);

	const createPresenter = () => {
		const popupSystem = new PopupSystem( dispatch );
		const snackbarSystem = new SnackbarSystem( dispatch );
		const userPreferencesSystem = new UserPreferencesSystem( dispatch );

		return new TwoFactorAuthenticationCardPresenter( {
			currentUser,
			popupSystem,
			snackbarSystem,
			userPreferencesSystem
		} );
	};

	const [ presenter ] = useState( createPresenter );

	useAutoupdatePresenterProperty( { presenter, name: 'currentUser', value: currentUser } );

	return presenter;
};

export default useTwoFactorAuthenticationCardPresenter;
