import { makeAutoObservable } from 'mobx';
import { Form } from '@amalgamaco/mobx-form';
import { History } from 'history';
import { ROLES, Role, defaultRole } from '../../types/role';
import AuthSystem from '../../new_arch/modules/authentication/system/AuthSystem';
import { isErrorWithStatus } from '../../errors';
import { Navigate } from '../../new_arch/navigation/useNavigate';
import FormFactory from '../../new_arch/modules/authentication/forms/formFactory';
import { preventDefaultAndCall } from '../../new_arch/lib/eventUtils';
import { CreateNacAccountFields, EmailField, NameField, RoleField } from './types';

type ProjectGuestViewPreviewPresenterProps = {
	guestHash: string;
	projectID: number;
	history: History<unknown>;
	authSystem: AuthSystem;
	navigate: Navigate;
}

export default class ProjectGuestViewPreviewPresenter {
	guestHash: string;
	role: Role;
	formError: string;

	private history: History<unknown>;
	private projectID: number;
	private authSystem: AuthSystem;
	private navigate: Navigate;
	private form: Form;

	constructor( { guestHash, projectID, history, authSystem, navigate } : ProjectGuestViewPreviewPresenterProps ) {
		this.guestHash = guestHash;
		this.role = defaultRole;
		this.formError = '';

		this.form = FormFactory.forCreateNacGuest( this.onSubmit );
		this.history = history;
		this.projectID = projectID;
		this.authSystem = authSystem;
		this.navigate = navigate;
		makeAutoObservable( this );
	}

	onSubmit = async ( form: Form ) => {
		try {
			const { email, name, role } = form.values as CreateNacAccountFields;

			await this.authSystem.createGuestSession(
				{
					guestHash: this.guestHash,
					email,
					name,
					role: role!.value
				}
			)

			this.history.replace( `/projects/${this.projectID}` );
		} catch ( error: unknown ) {
			this.handleError( error );
		}
	}

	onFormSubmitted = preventDefaultAndCall( () => this.form.submit() );

	onRedirectToSignIn = () => {
		this.navigate.toSignIn( { guestHash: this.guestHash } );
	}

	handleError = ( error: unknown ) => {
		if ( isErrorWithStatus( 404, 'not_found_project' )( error ) ) {
			this.setFormError( 'This link is not available for public use.' );
			return;
		}

		if ( isErrorWithStatus( 400, 'invalid_email' )( error ) ) {
			this.setFormError( "There's an existing account with this email. Use another email or try logging in" );
			return;
		}

		this.setFormError( 'Something went wrong. Please try again later' );
	}

	get emailInputProps() {
		const field = this.form.field<EmailField>( 'email' );

		return {
			name: 'email',
			label: field.label,
			value: field.value,
			onChange: ( value: string ) => field.write( value ),
			onBlur: () => field.blur(),
			error: field.error,
			placeholder: field.placeholder
		}
	}

	get nameInputProps() {
		const field = this.form.field<NameField>( 'name' );

		return {
			name: 'name',
			label: field.label,
			value: field.value,
			onChange: ( value: string ) => field.write( value ),
			onBlur: () => field.blur(),
			error: field.error,
			placeholder: field.placeholder
		}
	}

	get roleSelectProps() {
		const field = this.form.field<RoleField>( 'role' );

		return {
			label: field.label,
			selected: field.value,
			onChange: ( value: string ) => field.change( value ),
			placeholder: 'Role',
			options: ROLES
		}
	}

	private setFormError( value: string ) {
		this.formError = value;
	}
}
