import { useEffect } from 'react';
import PropTypes from 'prop-types';

import CountrySelector from '../CountrySelector/CountrySelector';
import StateField from '../StateField/StateField';
import PostalCodeInput from '../PostalCodeInput/PostalCodeInput';
import AddressInput from '../AddressInput/AddressInput';
import CompanyNameInput from '../CompanyNameInput/CompanyNameInput';
import CityInput from '../CityInput/CityInput';

import useAllValuesTruthState from '../../hooks/useAllValuesTruthState';

import './BillingInformationFillingForm.scss';

const BILLING_INFORMATION_COUNTRY_FIELD = 'BILLING_INFORMATION_COUNTRY_FIELD';
const BILLING_INFORMATION_ADDRESS_FIELD = 'BILLING_INFORMATION_ADDRESS_FIELD';
const BILLING_INFORMATION_CITY_FIELD = 'BILLING_INFORMATION_CITY_FIELD';
const BILLING_INFORMATION_STATE_FIELD = 'BILLING_INFORMATION_STATE_FIELD';
const BILLING_INFORMATION_POSTAL_CODE_FIELD = 'BILLING_INFORMATION_POSTAL_CODE_FIELD';

const DEFAULT_BILLING_INFORMATION_FIELDS_COMPLETION_STATE = {
	[ BILLING_INFORMATION_COUNTRY_FIELD ]: false,
	[ BILLING_INFORMATION_ADDRESS_FIELD ]: false,
	[ BILLING_INFORMATION_CITY_FIELD ]: false,
	[ BILLING_INFORMATION_STATE_FIELD ]: false,
	[ BILLING_INFORMATION_POSTAL_CODE_FIELD ]: false
};

const BillingInformationFillingForm = ( {
	initialCountryCode,
	country,
	onCountryChange,
	initialStateCode,
	state,
	onStateChange,
	postalCode,
	onPostalCodeChange,
	address,
	onAddressChange,
	city,
	onCityChange,
	companyName,
	onCompanyNameChange,
	onFieldsCompletionStateChange,
	disabled
} ) => {
	const [ allFieldsCompleted, setFieldCompletionState ] = useAllValuesTruthState(
		DEFAULT_BILLING_INFORMATION_FIELDS_COMPLETION_STATE
	);

	useEffect(
		() => {
			setFieldCompletionState( BILLING_INFORMATION_COUNTRY_FIELD, country !== undefined );
		},
		[ country ]
	);

	useEffect(
		() => {
			setFieldCompletionState( BILLING_INFORMATION_STATE_FIELD, state !== undefined );
		},
		[ state ]
	);

	const makeOnFieldCompletionStateChangedHandler = field => ( valid ) => {
		setFieldCompletionState( field, valid );
	};

	useEffect(
		() => { onFieldsCompletionStateChange( allFieldsCompleted ); },
		[ allFieldsCompleted ]
	);

	return (
		<div className="BillingInformationFillingForm">
			<CountrySelector
				initialCountryCode={initialCountryCode}
				onChange={onCountryChange}
				disabled={disabled}
				required
			/>
			<AddressInput
				value={address}
				onChange={onAddressChange}
				onValidationStatusChange={
					makeOnFieldCompletionStateChangedHandler( BILLING_INFORMATION_ADDRESS_FIELD )
				}
				disabled={disabled}
				required
			/>
			<CityInput
				value={city}
				onChange={onCityChange}
				onValidationStatusChange={
					makeOnFieldCompletionStateChangedHandler( BILLING_INFORMATION_CITY_FIELD )
				}
				disabled={disabled}
				required
			/>
			<StateField
				countryCode={country ? country.value : initialCountryCode}
				savedValue={initialStateCode}
				value={state}
				onChange={onStateChange}
				disabled={disabled}
				onValidationStatusChange={
					makeOnFieldCompletionStateChangedHandler( BILLING_INFORMATION_STATE_FIELD )
				}
				required
			/>
			<PostalCodeInput
				countryCode={country ? country.value : initialCountryCode}
				stateCode={state ? state.value : initialStateCode}
				value={postalCode}
				onChange={onPostalCodeChange}
				onValidationStatusChange={
					makeOnFieldCompletionStateChangedHandler( BILLING_INFORMATION_POSTAL_CODE_FIELD )
				}
				disabled={disabled}
				required
			/>
			<CompanyNameInput
				value={companyName}
				onChange={onCompanyNameChange}
				disabled={disabled}
			/>
		</div>
	);
};

BillingInformationFillingForm.propTypes = {
	initialCountryCode: PropTypes.string,
	country: PropTypes.shape( {
		label: PropTypes.string,
		value: PropTypes.string
	} ),
	onCountryChange: PropTypes.func,
	initialStateCode: PropTypes.string,
	state: PropTypes.shape( {
		label: PropTypes.string,
		value: PropTypes.string
	} ),
	onStateChange: PropTypes.func,
	postalCode: PropTypes.string,
	onPostalCodeChange: PropTypes.func,
	address: PropTypes.string,
	onAddressChange: PropTypes.func,
	city: PropTypes.string,
	onCityChange: PropTypes.func,
	companyName: PropTypes.string,
	onCompanyNameChange: PropTypes.func,
	onFieldsCompletionStateChange: PropTypes.func,
	disabled: PropTypes.bool
};

BillingInformationFillingForm.defaultProps = {
	initialCountryCode: undefined,
	country: undefined,
	onCountryChange: () => {},
	initialStateCode: undefined,
	state: undefined,
	onStateChange: () => {},
	postalCode: '',
	onPostalCodeChange: () => {},
	address: '',
	onAddressChange: () => {},
	city: '',
	onCityChange: () => {},
	companyName: '',
	onCompanyNameChange: () => {},
	onFieldsCompletionStateChange: () => {},
	disabled: false
};

export default BillingInformationFillingForm;
