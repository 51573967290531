import InvalidExtensionError from '../customErrors/InvalidExtensionError';
import { getFileExtension } from '../lib/fileUtils';

export default class FileExtensionValidator {
	constructor( validExtensions ) {
		this.validExtensions = validExtensions;
	}

	validate( files ) {
		const invalidFiles = files.filter( this._hasInvalidExtension );
		const isValid = invalidFiles.length === 0;
		const error = isValid ? undefined : new InvalidExtensionError( { files: invalidFiles } );

		return { isValid, error };
	}

	_hasInvalidExtension = ( file ) => {
		const extension = getFileExtension( file.name );
		return !this.validExtensions.includes( extension );
	}
}
