import { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../../new_arch/components/Icon/Icon';

import FloatingMenu from '../FloatingMenu/FloatingMenu';

import './DropdownMenu.scss';
import LabelFloatingMenuPresenter from '../../presenters/LabelFloatingMenu/LabelFloatingMenuPresenter';

class DropdownMenu extends Component {
	constructor( props ) {
		super( props );

		this.state = { isOpen: false };
		this.viewRef = createRef();
	}

	componentDidMount() {
		document.addEventListener( 'mousedown', this.onClickOutside );
	}

	componentWillUnmount() {
		document.removeEventListener( 'mousedown', this.onClickOutside );
	}

	itemWasClicked = ( item, event ) => {
		event.stopPropagation();
		this.close();
		this.props.onItemClick( item );
	}

	close = () => {
		if ( this.state.isOpen ) { this.props.onDropdownToggle?.(); }
		this.setState( { isOpen: false } );
	}

	onClickOutside = ( event ) => {
		if ( !this.isClickingOutside( event ) ) return;
		this.close();
	}

	onToggleButtonClicked = ( event ) => {
		const { onDropdownToggle } = this.props;
		event.stopPropagation();
		this.toggle();
		if ( onDropdownToggle ) { onDropdownToggle(); }
	}

	isClickingOutside( event ) {
		return !this.viewRef.current?.contains( event.target );
	}

	toggle() {
		this.setState(
			prevState => ( { isOpen: !prevState.isOpen } )
		);
	}

	renderDefaultMenu = () => (
		<FloatingMenu
			items={this.props.items}
			showArrow={this.props.showArrow}
			onItemClick={this.itemWasClicked}
			footer={this.props.footer}
		/>
	)

	renderMenu() {
		const menu = this.props.children || this.renderDefaultMenu;
		return menu( { onClose: this.close } );
	}

	render() {
		const { isOpen } = this.state;
		const {
			text, icon, disabled, node, className, showDropdown,
			buttonClassName, upRight, iconSize, iconColor, isSelected
		} = this.props;

		const iconSizeWithText = text && iconSize ? iconSize : 20;

		return (
			<div
				className={classNames( 'DropdownMenu', className, { withText: !!text, upRight } )}
				ref={this.viewRef}
			>
				{isSelected && <div className="selected-filter-indicator" />}
				<button
					data-testid="dropdown-menu-button"
					className={buttonClassName}
					onClick={this.onToggleButtonClicked}
					disabled={disabled}
				>
					{text
						? (
							<span>
								{text}
								<Icon
									icon="keyboard_arrow_down"
									color={iconColor}
									size={iconSizeWithText}
									className="DropdownMenuArrowIcon"
								/>
							</span>
						)
						: node || <Icon icon={icon} size={iconSize} color={iconColor} />}
				</button>
				{showDropdown && isOpen && this.renderMenu()}
			</div>
		);
	}
}

DropdownMenu.propTypes = {
	text: PropTypes.string,
	items: PropTypes.arrayOf( PropTypes.oneOfType( [ PropTypes.string, PropTypes.node ] ) ),
	presenter: PropTypes.instanceOf( LabelFloatingMenuPresenter ).isRequired,
	onItemClick: PropTypes.func,
	onDropdownToggle: PropTypes.func,
	icon: PropTypes.string,
	disabled: PropTypes.bool,
	node: PropTypes.node,
	className: PropTypes.string,
	footer: PropTypes.node,
	buttonClassName: PropTypes.string,
	upRight: PropTypes.bool,
	children: PropTypes.func,
	iconSize: PropTypes.number,
	iconColor: PropTypes.string,
	isSelected: PropTypes.bool,
	showArrow: PropTypes.bool,
	showDropdown: PropTypes.bool
};

DropdownMenu.defaultProps = {
	text: undefined,
	items: [],
	onItemClick: () => {},
	onDropdownToggle: () => { },
	icon: 'more_vert',
	disabled: false,
	node: undefined,
	className: '',
	footer: undefined,
	buttonClassName: '',
	upRight: false,
	children: undefined,
	iconSize: undefined,
	iconColor: undefined,
	isSelected: false,
	showArrow: false,
	showDropdown: true
};

export default DropdownMenu;
