import { FETCH_NOTIFICATIONS_PREFERENCES, EDIT_NOTIFICATIONS_PREFERENCES } from '../actions/types';
import { handleSuccess, makeAsyncActionReducer } from '../lib/reduxUtils';

const defaultState = {
	email_every: 'once_per_day',
	notification_alarms: [],
	email_alarms: []
};

export const editNotificationsPreferencesRequest = makeAsyncActionReducer(
	EDIT_NOTIFICATIONS_PREFERENCES
);

export const notificationsPreferences = ( state = defaultState, action ) => {
	switch ( action.type ) {
	case FETCH_NOTIFICATIONS_PREFERENCES:
		return handleSuccess(
			state,
			action,
			() => action.payload
		);
	default:
		return state;
	}
};

export const fetchNotificationsPrefsRequest	= makeAsyncActionReducer(
	FETCH_NOTIFICATIONS_PREFERENCES
);
