/* eslint-disable camelcase */
export const parseAsset = ( asset, projectID ) => {
	asset.project_id = projectID;
	asset.approve = asset.approved_at ? 'confirmed' : 'pending';
	asset.rush_priority = !!asset.rush_priority_set_at;
	delete asset.approved_at;
	delete asset.rush_priority_set_at;
	return asset;
};

export const parseAssets = ( assets, projectID ) => assets
	.map( asset => parseAsset( asset, projectID ) );

export const parseAssetToRound = ( {
	version_id: id,
	id: asset_id,
	version_number: number,
	image_url,
	thumbnail_url,
	current_version: { uploader } = {},
	lqip_url
} ) => ( {
	id,
	asset_id,
	number,
	image_url,
	thumbnail_url,
	uploader,
	lqip_url
} );
