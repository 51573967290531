export enum AssetDownloadsResolution {
	Original = 'original',
	LowRes = 'low_res'
}

export type UserPreferencesParams = {
	id: number,
	is2FAEnabled: boolean,
	assetDownloadsResolution: AssetDownloadsResolution,
	projectsOrder?: number[]
}

export type UserPreferencesJson = {
	id: number,
	is_2fa_enabled: boolean,
	asset_downloads_resolution: AssetDownloadsResolution,
	projects_order?: string
}

export default class UserPreferences {
	public id: number;
	public is2FAEnabled: boolean;
	public assetDownloadsResolution: AssetDownloadsResolution;
	public projectsOrder?: number[];

	constructor( { id, is2FAEnabled, assetDownloadsResolution, projectsOrder } : UserPreferencesParams ) {
		this.id = id;
		this.is2FAEnabled = is2FAEnabled;
		this.assetDownloadsResolution = assetDownloadsResolution;
		this.projectsOrder = projectsOrder;
	}

	static fromJson( json : UserPreferencesJson ) {
		return new this( {
			id: json.id,
			is2FAEnabled: json.is_2fa_enabled,
			assetDownloadsResolution: json.asset_downloads_resolution,
			projectsOrder: json.projects_order ? JSON.parse( json.projects_order ) : undefined
		} );
	}

	toJson() : UserPreferencesJson {
		return {
			id: this.id,
			is_2fa_enabled: this.is2FAEnabled,
			asset_downloads_resolution: this.assetDownloadsResolution,
			projects_order: JSON.stringify( this.projectsOrder )
		};
	}
}
