import './RotateMobileScreen.scss';
import RotateMobileImg from '../../assets/img/rotate-mobile.svg';

const RotateMobileScreen = () => (
	<div className="RotateMobileScreen">
		<img src={RotateMobileImg} alt="rotate-mobile-img" />
		<h2>Please, rotate your device</h2>
	</div>
);

export default RotateMobileScreen;
