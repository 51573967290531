import { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import WhiteBox from '../WhiteBox/WhiteBox';

import './WorkspaceSettings.scss';
import Workspace from '../../entities/workspace';

import EditWorkspaceContainer from '../../containers/EditWorkspaceContainer/EditWorkspaceContainer';
import DeleteWorkspaceContainer from '../../containers/DeleteWorkspaceContainer/DeleteWorkspaceContainer';
import Button from '../../new_arch/components/Button/Button';
import TransferWorkspaceOwnershipModalContainer
	from '../../containers/TransferWorkspaceOwnershipModalContainer/TransferWorkspaceOwnershipModalContainer';

const WorkspaceSettings = ( {
	workspace, isOwner, canTransferOwnership
} ) => {
	const [ showingOwnershipModal, setShowingOwnershipModal ] = useState( false );

	const onOpenOwnershipModal = () => {
		setShowingOwnershipModal( true );
	};

	const onCloseOwnershipModal = () => {
		setShowingOwnershipModal( false );
	};

	const canEditWorkspace = isOwner && workspace.isActive;
	const hideTransferOwnership = !workspace.hasSubscription;

	return (
		<div className={classNames( 'WorkspaceSettings', { hideTransferOwnership } )}>
			{showingOwnershipModal
				&& (
					<TransferWorkspaceOwnershipModalContainer
						workspaceID={workspace.id}
						onClose={onCloseOwnershipModal}
					/>
				)}
			<div className="upper-box-container">
				<WhiteBox>
					<EditWorkspaceContainer
						workspaceID={workspace.id}
						readOnly={!canEditWorkspace}
					/>
				</WhiteBox>
			</div>
			{isOwner
				&& (
					<div className="lower-boxes-container">
						{!hideTransferOwnership
						&& (
							<div className="left-lower-box">
								<WhiteBox className="transfer-ownership">
									<h4>Transfer Ownership</h4>
									<p>
										You are currently the Owner of this workspace.
										<br />
										Do you wish to transfer ownership to someone else?
									</p>
									<Button
										onClick={onOpenOwnershipModal}
										disabled={!canTransferOwnership}
									>
										Transfer Ownership
									</Button>
								</WhiteBox>
							</div>
						)}
						<div className="right-lower-box">
							<WhiteBox>
								<DeleteWorkspaceContainer workspaceID={workspace.id} />
							</WhiteBox>
						</div>
					</div>
				)}
		</div>
	);
};

WorkspaceSettings.propTypes = {
	workspace: PropTypes.instanceOf( Workspace ),
	isOwner: PropTypes.bool,
	canTransferOwnership: PropTypes.bool
};

WorkspaceSettings.defaultProps = {
	workspace: undefined,
	isOwner: false,
	canTransferOwnership: false
};

export default WorkspaceSettings;
