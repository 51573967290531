import PropTypes from 'prop-types';
import { connectComponent } from '../../lib/connectComponent';
import Container from '../Container';
import AsyncApiActionPropType, { defaultProp as defaultAsyncApiAction } from '../../types/asyncApiAction';

import NotificationsSettings
	from '../../components/notifications/NotificationsSettings/NotificationsSettings';
import { getNotificationsPreferences } from '../../selectors/notificationsPreferences';

export class NotificationsSettingsContainer extends Container {
	constructor( props ) {
		super( props );

		this._onUpdateClicked = this._onUpdateClicked.bind( this );
	}

	componentDidMount() {
		this.props.fetchNotificationsPreferences();
	}

	get showLoader() {
		const { fetchNotificationsPrefsRequest } = this.props;
		return fetchNotificationsPrefsRequest && fetchNotificationsPrefsRequest.sending;
	}

	_onUpdateClicked( editedNotificationsPreferences ) {
		const {
			editNotificationsPreferences,
			resetEditNotificationsPreferencesRequest
		} = this.props;

		this.handleAsyncActionResult(
			editNotificationsPreferences( editedNotificationsPreferences ),
			{ title: 'Success! Your changes have been saved!' },
			{ title: 'Oops, something went wrong! Try again.' },
			resetEditNotificationsPreferencesRequest
		);
	}

	render() {
		const {
			editNotificationsPreferencesRequest,
			notificationsPreferences
		} = this.props;

		return (
			<NotificationsSettings
				notificationsPreferences={notificationsPreferences}
				onUpdateClick={this._onUpdateClicked}
				updating={editNotificationsPreferencesRequest.sending}
				showLoader={this.showLoader}
			/>
		);
	}
}

NotificationsSettingsContainer.propTypes = {
	notificationsPreferences: PropTypes.any,
	editNotificationsPreferencesRequest: AsyncApiActionPropType,
	editNotificationsPreferences: PropTypes.func,
	resetEditNotificationsPreferencesRequest: PropTypes.func,
	fetchNotificationsPreferences: PropTypes.func
};

NotificationsSettingsContainer.defaultProps = {
	notificationsPreferences: null,
	editNotificationsPreferencesRequest: defaultAsyncApiAction,
	editNotificationsPreferences: () => {},
	resetEditNotificationsPreferencesRequest: () => {},
	fetchNotificationsPreferences: () => {}
};

export default connectComponent( state => ( {
	notificationsPreferences: getNotificationsPreferences( state ),
	fetchNotificationsPrefsRequest: state.fetchNotificationsPrefsRequest
} ) )( NotificationsSettingsContainer );
