/* eslint-disable func-names */

const scales = Object.freeze( {
	bytes: 0,
	kb: 1,
	mb: 2,
	gb: 3,
	tb: 4
} );

Object.defineProperty( File.prototype, 'sizeInMb', {
	get: function sizeInMb() {
		let scaledSize = this.size;

		for ( let i = 0; i < scales.mb; i += 1 ) {
			scaledSize /= 1024;
		}

		return scaledSize;
	}
} );
