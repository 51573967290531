import { createBrowserHistory } from 'history';
import VeryBusyHistory from '../services/History';

const browserHistory = createBrowserHistory();

export const history = VeryBusyHistory.asProxyOf( browserHistory );

export const setLoggingOutState = () => {
	history.location.state = {
		...( history.location.state ? history.location.state : {} ),
		loggingOut: true
	};
};

export const unsetLoggingOutState = () => {
	if ( !history.location.state ) return;
	delete history.location.state.loggingOut;
};

export default history;
