import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useStripe } from '@stripe/react-stripe-js';

import { connectComponent } from '../../lib/connectComponent';

import BillingAndPaymentInformationEditModal from '../../modals/BillingAndPaymentInformationEditModal/BillingAndPaymentInformationEditModal';
import { getWorkspace, getCreditCardForWorkspace } from '../../selectors/workspaces';
import { getBillingInformationForWorkspace } from '../../selectors/workspacesBillingInformation';
import Workspace from '../../entities/workspace';
import WorkspaceBillingInformation from '../../entities/workspaceBillingInformation';
import CreditCard from '../../entities/creditCard';
import { actionResultIsError } from '../../lib/reduxUtils';
import { isNotAllowedOrNotFound } from '../../errors/common';

export const BillingAndPaymentInformationEditModalContainer = ( {
	onClose,
	billingInformation,
	creditCard,
	onEditComplete,
	updateWorkspaceBillingInformation,
	updateWorkspacePaymentMethod,
	showErrorMessage,
	workspace
} ) => {
	const stripe = useStripe();
	const [ sending, setSending ] = useState( false );

	const onSave = useCallback(
		( newBillingInformation, newPaymentMethodParams ) => {
			setSending( true );
			const isCreatingNewPaymentMethod = !!newPaymentMethodParams;

			const managePaymentMethod = isCreatingNewPaymentMethod
				? () => stripe.createPaymentMethod( {
					type: 'card',
					card: newPaymentMethodParams.cardElement,
					billing_details: {
						name: newPaymentMethodParams.cardholderName
					}
				} ).then(
					( { paymentMethod } ) => updateWorkspacePaymentMethod( workspace.id, paymentMethod.id )
				)
				: () => Promise.resolve( { paymentMethod: null } );

			managePaymentMethod()
				.then( ( paymentMethodResult ) => {
					if ( actionResultIsError( paymentMethodResult ) ) {
						throw paymentMethodResult.payload;
					}
				} )
				.then( () => updateWorkspaceBillingInformation( workspace.id, newBillingInformation )
					.then( ( result ) => {
						if ( actionResultIsError( result ) ) {
							throw result.payload;
						}
						onEditComplete();
					} ) )
				.catch( ( error ) => {
					if ( !isNotAllowedOrNotFound( error ) ) {
						showErrorMessage( {
							title: 'There was an error trying to save your payment method',
							description: 'Please try again later.'
						} );
					}
				} )
				.finally( () => {
					setSending( false );
				} );
		},
		[ workspace ]
	);

	return (
		<BillingAndPaymentInformationEditModal
			initialBillingInformation={billingInformation}
			initialCreditCard={creditCard}
			onClose={onClose}
			onSave={onSave}
			sending={sending}
		/>
	);
};

BillingAndPaymentInformationEditModalContainer.propTypes = {
	onClose: PropTypes.func,
	billingInformation: PropTypes.instanceOf( WorkspaceBillingInformation ),
	creditCard: PropTypes.instanceOf( CreditCard ),
	onEditComplete: PropTypes.func,
	updateWorkspaceBillingInformation: PropTypes.func,
	updateWorkspacePaymentMethod: PropTypes.func,
	showErrorMessage: PropTypes.func,
	workspace: PropTypes.instanceOf( Workspace )
};

BillingAndPaymentInformationEditModalContainer.defaultProps = {
	onClose: () => {},
	billingInformation: undefined,
	creditCard: undefined,
	onEditComplete: () => {},
	updateWorkspaceBillingInformation: () => {},
	updateWorkspacePaymentMethod: () => {},
	showErrorMessage: () => {},
	workspace: undefined
};

export default connectComponent( ( state, props ) => ( {
	workspace: getWorkspace( state, props ),
	billingInformation: getBillingInformationForWorkspace( state, props ),
	creditCard: getCreditCardForWorkspace( state, props )
} ) )( BillingAndPaymentInformationEditModalContainer );
