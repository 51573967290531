import Api from '../Api';
import { PROMO_CODES_URL } from '../../config/urls';
import Coupon, { CouponJSON } from '../../entities/coupon';

import { type PaymentService } from './index';

const EXPIRED_PROMO_CODE_ERROR_MESSAGE = 'Promo code is expired';

type PromoCodeJson = {
	id: string,
	active: boolean
	coupon: CouponJSON
}

export default class StripePaymentService implements PaymentService {
	private readonly api: Api;

	constructor( api = new Api() ) {
		this.api = api;
	}

	async validatePromoCode( code: string ) {
		try {
			const { response } = await ( this.api.post( `${PROMO_CODES_URL}/validate`, { code } ) as Promise<{ response: PromoCodeJson }> );

			if ( !response.active ) {
				throw new Error( EXPIRED_PROMO_CODE_ERROR_MESSAGE );
			}

			return {
				id: response.id,
				coupon: Coupon.fromJSON( response.coupon )
			};
		} catch ( error ) {
			if ( error instanceof Error && error.message === EXPIRED_PROMO_CODE_ERROR_MESSAGE ) {
				throw error;
			}
			throw new Error( 'Promo code not valid' );
		}
	}
}
