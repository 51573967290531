import { DIRECT_FILE_UPLOAD_EVENTS } from './DirectFileUpload';

export const MAX_CONCURRENT_UPLOADS = 6;

class Uploader {
	constructor( limit = MAX_CONCURRENT_UPLOADS ) {
		this.uploads = [];
		this.limit = limit;
		this.onUploadFinish = null;
	}

	get hasCapacity() {
		return this.uploads.length < this.limit;
	}

	start( upload ) {
		if ( !this.hasCapacity ) { throw new Error( 'Tried to start a new upload when Uploader is full' ); }
		this._listenForUploadFinish( upload );
		this.uploads.push( upload );
		upload.upload();
	}

	cancelUpload( uploadID ) {
		const upload = this.find( uploadID );
		if ( !upload ) { return; }

		upload.abort();
		this._uploadFinish( upload.id );
	}

	cancelAll() {
		this.uploads.forEach( ( upload ) => {
			this.cancelUpload( upload.id );
		} );
	}

	addUploadFinishListener( listener ) {
		this.onUploadFinish = listener;
	}

	find( uploadID ) {
		return this.uploads.find( ( { id } ) => uploadID === id );
	}

	remove( uploadID ) {
		this.uploads = this.uploads.filter( ( { id } ) => uploadID !== id );
	}

	_listenForUploadFinish( upload ) {
		upload.addEventListener(
			DIRECT_FILE_UPLOAD_EVENTS.FINISH,
			this._uploadFinish.bind( this, upload.id )
		);
	}

	_uploadFinish( uploadID ) {
		this.remove( uploadID );
		this.onUploadFinish();
	}
}

export default Uploader;
