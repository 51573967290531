import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';

import stripeElementStyle from './StripeElementStyle';

import useAllValuesTruthState from '../../hooks/useAllValuesTruthState';

import './CardInformationInput.scss';

export const CARD_NUMBER_FIELD = 'CARD_NUMBER_FIELD';
export const CARD_EXPIRY_FIELD = 'CARD_EXPIRY_FIELD';
export const CARD_CVC_FIELD = 'CARD_CVC_FIELD';

const DEFAULT_CARD_FIELDS_COMPLETION_STATE = {
	[ CARD_NUMBER_FIELD ]: false,
	[ CARD_EXPIRY_FIELD ]: false,
	[ CARD_CVC_FIELD ]: false
};

const CardInformationInput = ( {
	onFieldsCompletionStateChange,
	disabled,
	required
} ) => {
	const [
		allCardFieldsCompleted, setCardFieldCompletionState
	] = useAllValuesTruthState( DEFAULT_CARD_FIELDS_COMPLETION_STATE );

	useEffect(
		() => {
			onFieldsCompletionStateChange( allCardFieldsCompleted );
		},
		[ allCardFieldsCompleted ]
	);

	const makeOnFieldCompletionStateChangedHandler = field => ( { complete } ) => (
		setCardFieldCompletionState( field, complete )
	);

	const elementOptions = {
		style: stripeElementStyle,
		disabled,
		classes: {
			base: disabled ? 'StripeElement disabled' : 'StripeElement'
		}
	};

	return (
		<div className="CardInformationInput">
			<label htmlFor="card-information">
				Card Information
				{required && <span>*</span>}
			</label>
			<CardNumberElement
				options={{
					...elementOptions,
					showIcon: true,
					iconStyle: 'solid'
				}}
				onChange={makeOnFieldCompletionStateChangedHandler( CARD_NUMBER_FIELD )}
			/>
			<div className="expiry-and-cvc-container">
				<CardExpiryElement
					options={{
						...elementOptions,
						placeholder: 'MM/YY'
					}}
					onChange={makeOnFieldCompletionStateChangedHandler( CARD_EXPIRY_FIELD )}
				/>
				<CardCvcElement
					options={elementOptions}
					onChange={makeOnFieldCompletionStateChangedHandler( CARD_CVC_FIELD )}
				/>
			</div>
		</div>
	);
};

CardInformationInput.propTypes = {
	onFieldsCompletionStateChange: PropTypes.func,
	disabled: PropTypes.bool,
	required: PropTypes.bool
};

CardInformationInput.defaultProps = {
	onFieldsCompletionStateChange: () => {},
	disabled: false,
	required: false
};

export default CardInformationInput;
