import { useState } from 'react';
import { useSelector } from 'react-redux';
import useAutoupdatePresenterProperty from '../../hooks/useAutoupdatePresenterProperty';
import UpgradeButtonPresenter from './UpgradeButtonPresenter';
import { getWorkspacesArray } from '../../selectors/workspaces';

const useUpgradeButtonPresenter = ( { modalSystem } ) => {
	const workspaces = useSelector( state => getWorkspacesArray( state ) );

	const createPresenter = () => new UpgradeButtonPresenter( { workspaces, modalSystem } );

	const [ presenter ] = useState( createPresenter );

	useAutoupdatePresenterProperty( { presenter, name: 'workspaces', value: workspaces } );

	return presenter;
};

export default useUpgradeButtonPresenter;
