import { useCallback } from 'react';
import PropTypes from 'prop-types';
import className from 'classnames';

import TimePassed from '../../TimePassed/TimePassed';
import NotificationPropType from '../../../types/notification';
import { htmlForMentions } from '../../../lib/mentionsUtils';

import './NotificationListItem.scss';
import { removeTags } from '../../../lib/textUtils';

/* eslint-disable react/no-danger */

const NotificationListItem = ( {
	notification, onNotificationClicked, onNotificationProjectClicked, onNotificationFolderClicked
} ) => {
	const notificationClicked = useCallback( () => onNotificationClicked( notification ),
		[ notification, onNotificationClicked ]
	);
	const notificationProjectClicked = useCallback( () => onNotificationProjectClicked( notification ),
		[ notification, onNotificationProjectClicked ]
	);

	const notificationFolderClicked = useCallback( () => onNotificationFolderClicked( notification ),
		[ notification, onNotificationFolderClicked ]
	);

	const relatedObjectTextWithoutHTML = removeTags( notification.relatedObjectText );
	const relatedObjectText = htmlForMentions( relatedObjectTextWithoutHTML );
	const isNew = !notification.wasRead;

	return (
		<div
			className={className( 'NotificationListItem', { isNew } )}
		>
			<div className="image-red-dot-container">
				{isNew && ( <div className="red-dot" /> )}
				<div className={className( 'img-container', { 'no-image': !notification.image } )}>
					<img src={notification.image} alt={notification.title} />
				</div>
			</div>
			<div className="notification-preview">
				<div className="related-object-redirection" onClick={notificationClicked}>
					<span className="name">{notification.title} </span>
					{notification.htmlMessage}
					{notification.hasRelatedObjectText && (
						<p
							className="tagged_comment"
							dangerouslySetInnerHTML={{
								__html: (
									`"${relatedObjectText}"`
								)
							}}
						/>
					)}
				</div>
				<div className="breadcrumbs-time">
					<div className="notification-breadcrumbs">
						<div className="project-redirection" onClick={notificationProjectClicked}>
							<p>{notification.projectBreadcrumbs}</p>
						</div>
						{notification.folderID && (
							<>{notification.folderHasAncestors && ( <p>/...</p> )}
								<div className="folder-redirection" onClick={notificationFolderClicked}>
									<p>{notification.folderBreadcrumbs}</p>
								</div>
							</>
						)}
					</div>
					<p className="timestamp"><TimePassed date={notification.createdAt} /></p>
				</div>
			</div>
		</div>
	);
};

NotificationListItem.propTypes = {
	notification: NotificationPropType.isRequired,
	onNotificationClicked: PropTypes.func,
	onNotificationProjectClicked: PropTypes.func,
	onNotificationFolderClicked: PropTypes.func
};

NotificationListItem.defaultProps = {
	onNotificationClicked: () => {},
	onNotificationProjectClicked: () => {},
	onNotificationFolderClicked: () => {}
};

export default NotificationListItem;
