import { sharedTracker } from '../Tracker';
import events from '../events';

export const trackChangedUserRole = project => sharedTracker
	.trackCollaborationAction( {
		action: events.collaboration.PROJECT_ROLE_EDITED,
		label: project && project.name
	} );

export const trackInvitedProjectMembers = ( { project, emails } ) => {
	sharedTracker
		.trackCollaborationAction( {
			action: events.collaboration.INVITED_PROJECT_MEMBERS,
			project_id: project && project.id,
			project_name: project && project.name,
			amount_invited: emails.length
		} );
}

export const trackProjectLinkShared = ( { allowsDownload } ) => sharedTracker
	.trackProjectAction( {
		action: events.project.PROJECT_LINK_SHARED,
		allows_download: allowsDownload
	} );

export const trackChangedProjectsSort = sortOption => sharedTracker
	.trackProjectAction( {
		action: events.project.PROJECT_SORTED,
		label: sortOption.name
	} );

export const trackChangedProjectsFilter = filterOption => sharedTracker
	.trackProjectAction( {
		action: events.project.PROJECT_FILTERED,
		label: filterOption.name
	} );

export const trackCustomSortedProjects = () => sharedTracker
	.trackProjectAction( {
		action: events.project.PROJECT_CUSTOM_SORTED
	} );

export const trackSearchedProjects = ( filterText ) => {
	if ( !filterText || filterText.length === 0 ) { return; }

	sharedTracker.trackProjectAction( {
		action: events.project.PROJECT_SEARCHED
	} );
};

export const trackClickedLoginForSharedProject = project => sharedTracker
	.trackCollaborationAction( {
		action: events.collaboration.CLICKED_LOGIN_FOR_SHARED_PROJECT,
		label: project && project.name
	} );

export const trackClickedRegisterForSharedProject = project => sharedTracker
	.trackCollaborationAction( {
		action: events.collaboration.CLICKED_REGISTER_FOR_SHARED_PROJECT,
		label: project && project.name
	} );

export const trackProjectCreated = projectName => sharedTracker
	.trackProjectAction( {
		action: events.project.PROJECT_CREATED,
		label: projectName
	} );

export const trackProjectDownloaded = () => sharedTracker
	.trackProjectAction( {
		action: events.project.PROJECT_DOWNLOADED
	} );

export const trackProjectDeleted = () => sharedTracker
	.trackProjectAction( {
		action: events.project.PROJECT_DELETED
	} );

export const trackProjectHidden = () => sharedTracker
	.trackProjectAction( {
		action: events.project.PROJECT_HIDDEN
	} );

export const trackProjectUnhidden = () => sharedTracker
	.trackProjectAction( {
		action: events.project.PROJECT_UNHIDDEN
	} );
