import LinkButton from '../../UI/LinkButton/LinkButton';

type NotificationsSuspendedWorkspaceOverlayProps = {
	redirectionURL: string;
	showButton: boolean;
	onButtonClick: () => void;
	visible: boolean;
}

const NotificationsSuspendedWorkspaceOverlay = ( {
	redirectionURL, onButtonClick, showButton, visible
}: NotificationsSuspendedWorkspaceOverlayProps ) => {
	if ( !visible ) { return null; }

	return (
		<div className="NotificationsSuspendedWorkspaceOverlay">
			<h4>{'This project\'s workspace has been suspended'}</h4>
			{ !!showButton && (
				<LinkButton
					to={redirectionURL}
					size="regular"
					onClick={onButtonClick}
				>
					Go to Plan
				</LinkButton>
			) }
		</div>
	);
};

export default NotificationsSuspendedWorkspaceOverlay;
