import PropTypes from 'prop-types';

import SortOption from '../../entities/sortOption';
import Icon from '../../new_arch/components/Icon/Icon';

import './SortColumnName.scss';

const SortColumnName = ( {
	title, selectedSortOption, onChange, ascending, sortOptionID
} ) => {
	const isSelected = selectedSortOption.id === sortOptionID;
	const sortOption = SortOption.optionsByID[ sortOptionID ];

	const selectNewOption = () => onChange( sortOption, ascending );
	const changeSortDirection = () => onChange( selectedSortOption, !ascending );
	const buttonWasClicked = isSelected ? changeSortDirection : selectNewOption;

	return (
		<button className="SortColumnName" onClick={buttonWasClicked}>
			<div className="sort-column-title">
				<h5>{title}</h5>
			</div>
			{isSelected && (
				<Icon
					className="sort-direction-button"
					icon={ascending ? 'arrow-upward' : 'arrow-downward'}
					size="medium"
				/>
			)}
		</button>
	);
};

SortColumnName.propTypes = {
	title: PropTypes.string,
	selectedSortOption: PropTypes.instanceOf( SortOption ),
	onChange: PropTypes.func,
	ascending: PropTypes.bool,
	sortOptionID: PropTypes.instanceOf( SortOption.id )
};

SortColumnName.defaultProps = {
	title: '',
	selectedSortOption: undefined,
	onChange: () => {},
	ascending: true,
	sortOptionID: undefined
};

export default SortColumnName;
