import PropTypes from 'prop-types';
import classNames from 'classnames';

import CharactersCount from './CharactersCount/CharactersCount';

import './TextArea.scss';

const TextArea = ( {
	value, label, onChange, onBlur,
	onFocus, type, placeholder, name,
	disabled, hasError, autoComplete,
	rows, maxLength
} ) => (
	<div className={classNames( 'TextArea', { error: hasError } )}>
		{label && <label htmlFor={name}>{label}</label>}
		<textarea
			name={name}
			type={type}
			rows={rows}
			disabled={disabled}
			value={value}
			onChange={event => onChange( event.target.value )}
			onBlur={onBlur}
			onFocus={onFocus}
			placeholder={placeholder}
			autoComplete={autoComplete}
			maxLength={maxLength}
		/>
		{maxLength && <CharactersCount count={value ? value.length : 0} max={maxLength} />}
	</div>
);

TextArea.propTypes = {
	value: PropTypes.any,
	label: PropTypes.string,
	rows: PropTypes.number,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	onFocus: PropTypes.func,
	type: PropTypes.string,
	placeholder: PropTypes.string,
	hasError: PropTypes.bool,
	name: PropTypes.string,
	autoComplete: PropTypes.string,
	maxLength: PropTypes.number
};

TextArea.defaultProps = {
	value: '',
	label: undefined,
	rows: 1,
	disabled: false,
	onChange: () => {},
	onBlur: () => {},
	onFocus: () => {},
	type: 'text',
	placeholder: '',
	hasError: false,
	name: undefined,
	autoComplete: undefined,
	maxLength: undefined
};

export default TextArea;
