import { makeAutoObservable } from 'mobx';

export default class PlanCardPresenter {
	constructor( { pricingSubscriptionPresenter, pricingPlanToDisplay } ) {
		this._pricingSubscriptionPresenter = pricingSubscriptionPresenter;
		this._pricingPlanToDisplay = pricingPlanToDisplay;

		makeAutoObservable( this );
	}

	get cost() {
		const costPeriod = this.isMonthly
			? this._pricingPlanToDisplay.monthlyCost
			: this._pricingPlanToDisplay.annualCost;
		return costPeriod?.toMoney( 2 );
	}

	get costDescription() {
		return this._pricingPlanToDisplay.costDescription;
	}

	get minimumMembersInfo() {
		return this._pricingPlanToDisplay.minimumMembersInfo;
	}

	get currentStatusToShow() {
		return this._pricingPlanToDisplay.statusToShow( {
			workspace: this._pricingSubscriptionPresenter.workspace
		} );
	}

	get features() {
		return this._pricingPlanToDisplay.features;
	}

	get isMonthly() {
		return this._pricingSubscriptionPresenter.isMonthly;
	}

	get title() {
		return this._pricingPlanToDisplay.title;
	}

	get text() {
		return this._pricingPlanToDisplay.text;
	}

	get _hasNullWorkspace() {
		return this._pricingSubscriptionPresenter.hasNullWorkspace;
	}

	get _hasFreePrice() {
		return this._pricingPlanToDisplay.hasFreePrice;
	}

	get _pricingPlanFreePrice() {
		return this._pricingPlanToDisplay.freePrice;
	}

	get _pricingPlanMonthlyPrice() {
		return this._pricingPlanToDisplay.monthlyPrice;
	}

	get _pricingPlanAnnualPrice() {
		return this._pricingPlanToDisplay.annualPrice;
	}

	onUpgradeButtonClicked = ( handler ) => {
		if ( this._hasNullWorkspace && this._hasFreePrice ) {
			return handler( this._pricingPlanFreePrice );
		}
		if ( this.isMonthly ) return handler( this._pricingPlanMonthlyPrice );
		return handler( this._pricingPlanAnnualPrice );
	}
}
