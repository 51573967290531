import { Map } from 'immutable';
import {
	makeAsyncActionReducer, handleSuccess, mapForPayload, handleStart
} from '../lib/reduxUtils';
import {
	FETCH_PROJECT_TASKS,
	MARK_PROJECT_TASK_AS_COMPLETED,
	CREATE_TASK,
	LOGOUT,
	UNMARK_PROJECT_TASK_AS_COMPLETED,
	SET_EDITING_TASK,
	CANCEL_TASK_EDIT,
	UPDATE_TASK
} from '../actions/types';
import { parseTask } from '../parsers/tasks';

export const fetchProjectTasksRequest = makeAsyncActionReducer( FETCH_PROJECT_TASKS );
export const updateTaskRequest = makeAsyncActionReducer( UPDATE_TASK );
export const completeProjectTaskRequest =	makeAsyncActionReducer( MARK_PROJECT_TASK_AS_COMPLETED );
export const createTaskRequest = makeAsyncActionReducer( CREATE_TASK );

export const tasks = ( state = new Map(), action ) => {
	switch ( action.type ) {
	case FETCH_PROJECT_TASKS:
		return handleSuccess(
			state,
			action,
			() => mapForPayload( action.payload.map( parseTask ) )
		);
	case CREATE_TASK:
	case UNMARK_PROJECT_TASK_AS_COMPLETED:
	case MARK_PROJECT_TASK_AS_COMPLETED:
	case UPDATE_TASK:
		return handleSuccess(
			state,
			action,
			( prevState ) => {
				const task = parseTask( action.payload );
				return prevState.set( task.id, task );
			}
		);
	case LOGOUT:
		return handleStart( state, action, () => new Map() );
	default:
		return state;
	}
};

export const currentActiveTask = ( state = null, action ) => {
	switch ( action.type ) {
	case SET_EDITING_TASK:
		return action.payload.taskID;
	case CANCEL_TASK_EDIT:
		return null;
	case UPDATE_TASK:
		return handleSuccess(
			state,
			action,
			() => null
		);
	default:
		return state;
	}
};
