import ShareRef from '../structures/shareRef';
import ImmutableEntity from './immutableEntity';

export default class SharedProject extends ImmutableEntity {
	constructor( {
		id, name, shareHash, allowsDownloads
	} ) {
		super();

		this.id = id;
		this.name = name;
		this.shareHash = shareHash;
		this.allowsDownloads = allowsDownloads;
	}

	// Serialization
	static fromJSON( properties ) {
		return new SharedProject( {
			id: properties?.id,
			name: properties?.name,
			shareHash: properties?.share_hash,
			allowsDownloads: properties?.allows_downloads
		} );
	}

	toJSON() {
		return {
			id: this.id,
			name: this.name,
			share_hash: this.shareHash,
			allows_downloads: this.allowsDownloads
		}
	}

	containsAsset( asset ) {
		return asset.projectID === this.id && !asset.folderID;
	}

	containsFolder( folder ) {
		return folder.belongsToOrganizer( { organizerID: this.id, organizerType: 'Project' } );
	}

	get isProject() {
		return true;
	}

	get shareViewPath() {
		return ShareRef.forProject( this.shareHash ).appPath;
	}
}
