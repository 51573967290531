import PropTypes from 'prop-types';
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import BreadcrumbLevel from '../../structures/breadcrumbLevel';
import BreadcrumbDividingArrow from '../BreadcrumbDividingArrow/BreadcrumbDividingArrow';
import { simpleTextTruncate } from '../../lib/textTruncate';
import { WHITE } from '../../styles/colors';

import './CollapsedBreadcrumbs.scss';

const CollapsedBreadcrumbs = ( { breadcrumbs } ) => (
	<>
		<BreadcrumbDividingArrow />
		<DropdownMenu
			className="CollapsedBreadcrumbs"
			icon="more_horiz"
			iconSize={30}
			iconColor={WHITE}
			items={breadcrumbs.map( breadcrumb => simpleTextTruncate( breadcrumb.name, 50, 30 ) )}
			onItemClick={index => breadcrumbs[ index ].onClick()}
		/>
	</>
);

CollapsedBreadcrumbs.propTypes = {
	breadcrumbs: PropTypes.arrayOf( PropTypes.instanceOf( BreadcrumbLevel ) ).isRequired
};

export default CollapsedBreadcrumbs;
