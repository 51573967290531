import PricingPlanToDisplay from './PricingPlanToDisplay';

export default class TeamPlanToDisplay extends PricingPlanToDisplay {
	constructor( { pricingPlan } ) {
		super( { pricingPlan } );
	}

	get title() {
		return 'Team';
	}

	get text() {
		return 'For teams and agencies that need even more capabilities.';
	}

	get minimumMembersInfo() {
		return 'Starting at 2 members';
	}

	get annualCost() {
		return ( this._pricingPlan.annualPriceCostPerMember / 12 ) || undefined;
	}

	get monthlyCost() {
		return this._pricingPlan.monthlyPriceCostPerMember;
	}

	statusToShow( { workspace } ) {
		if ( workspace.isNullWorkspace ) {
			return this._showNoWorkspaceStatus();
		}
		if ( workspace.isSubscriptionActive ) {
			return workspace?.hasADowngradePending ? this._showDowngradeStatus()
				: this._showActiveSubscriptionStatus( workspace );
		}
		return this._showInactiveSubscriptionStatus( { workspace } );
	}

	_showNoWorkspaceStatus() {
		return { status: 'noWorkspace', text: 'Choose Plan' };
	}

	_showActiveSubscriptionStatus( workspace ) {
		if ( !workspace.hasAPaidPlan ) return { status: 'upgrade', text: 'Choose Plan' };
		if (
			this._levelForPlan[ this.name ] === this._levelForPlan[ workspace.subscriptionPlanName ]
		) {
			return { status: 'stay', text: 'You are subscribed to Team!' };
		}
		return { status: 'upgrade', text: 'Upgrade to Team' };
	}

	_showDowngradeStatus() {
		return { status: 'cancelDowngrade', text: 'Restore Team' };
	}
}
