import { useState } from 'react';
import PropTypes from 'prop-types';
import useDeepEffect from '../../../hooks/useDeepEffect';
import ColorView from '../../../structures/colorView';
import { BLACK } from '../../../styles/colors';

import './ColorLabel.scss';

const ColorLabel = ( {
	text, backgroundColor, textColor, className
} ) => {
	const [ isHovering, setIsHovering ] = useState( false );
	useDeepEffect( () => setIsHovering( false ), [ backgroundColor, textColor ] );

	const currentBackgroundColor = isHovering ? backgroundColor.hovered : backgroundColor?.base;
	const currentTextColor = isHovering ? textColor.hovered : textColor?.base;
	return (
		<div
			className={`ColorLabel ${className}`}
			data-testid="ColorLabel"
			style={{ backgroundColor: currentBackgroundColor, color: currentTextColor }}
			onMouseEnter={() => setIsHovering( true )}
			onMouseLeave={() => setIsHovering( false )}
		>
			{text}
		</div>
	);
};

ColorLabel.propTypes = {
	text: PropTypes.string.isRequired,
	backgroundColor: PropTypes.instanceOf( ColorView ).isRequired,
	textColor: PropTypes.instanceOf( ColorView ),
	className: PropTypes.string
};

ColorLabel.defaultProps = {
	className: '',
	textColor: new ColorView( { base: BLACK } )
};

export default ColorLabel;
