import { Map } from 'immutable';
import {
	LOGOUT, SET_ASSET_DOWNLOAD_RESOLUTION, SET_IS_2FA_ENABLED
} from '../actions/types';
import { handleStart, handleSuccess } from '../lib/reduxUtils';
import { UPDATER_ACTION_TYPE } from '../new_arch/services/ReduxStoreUpdater';

export const userPreferences = ( state = Map(), action ) => {
	switch ( action.type ) {
	case UPDATER_ACTION_TYPE.currentUser:
		return state.set(
			action.payload.user.user_preference.id,
			action.payload.user.user_preference
		);
	case SET_IS_2FA_ENABLED:
	case SET_ASSET_DOWNLOAD_RESOLUTION:
		return handleSuccess(
			state,
			action,
			prevState => prevState.set( action.payload.id, action.payload ) );
	case LOGOUT:
		return handleStart( state, action, () => Map() );
	default:
		return state;
	}
};
