import PropTypes from 'prop-types';
import BillingInput from '../BillingInput/BillingInput';

const CityInput = ( {
	value,
	onChange,
	onValidationStatusChange,
	disabled,
	required
} ) => (
	<BillingInput
		className="CityInput"
		name="city"
		placeholder="Enter a City"
		label="City"
		value={value}
		onChange={onChange}
		required={required}
		disabled={disabled}
		onValidationStatusChange={onValidationStatusChange}
	/>
);

CityInput.propTypes = {
	value: PropTypes.any,
	onChange: PropTypes.func,
	onValidationStatusChange: PropTypes.func,
	disabled: PropTypes.bool,
	required: PropTypes.bool
};

CityInput.defaultProps = {
	value: '',
	onChange: () => {},
	onValidationStatusChange: () => {},
	disabled: false,
	required: false
};

export default CityInput;
