import PropTypes from 'prop-types';

import UserPropType from '../../../types/user';

import UserAvatar from '../../UserAvatar/UserAvatar';

import './ProfileButton.scss';

const ProfileButton = ( { currentUser, onAvatarClick } ) => (
	<div onClick={onAvatarClick} className="ProfileButton">
		<UserAvatar user={currentUser} />
	</div>
);

ProfileButton.propTypes = {
	currentUser: UserPropType.isRequired,
	onAvatarClick: PropTypes.func
};

ProfileButton.defaultProps = {
	onAvatarClick: () => {}
};

export default ProfileButton;
