import { Helmet } from 'react-helmet';

const Metadata = ( { title, description } : { title?: string, description?: string } ) => (
	<Helmet>
		{title && <title>{title}</title>}
		{description && <meta name="description" content={description} />}
	</Helmet>
);

export default Metadata;
