import Label from '../entities/label';
import {
	ADD_LABEL_TO_ASSETS, CREATE_PROJECT_LABEL,
	DELETE_PROJECT_LABEL,
	EDIT_PROJECT_LABEL,
	FETCH_PROJECT_LABELS, REMOVE_LABEL_FROM_ASSETS,
	FETCH_WORKSPACE_LABELS, CREATE_WORKSPACE_LABEL,
	EDIT_WORKSPACE_LABEL, DELETE_WORKSPACE_LABEL,
	ADD_LABEL_TO_PROJECTS, REMOVE_LABEL_FROM_PROJECTS,
	SET_PROJECT_LABELS_ORDER, SET_WORKSPACE_LABELS_ORDER,
	CREATE_LABELS_FROM_GROUPS
} from '../actions/types';
import {
	ASSETS_URL, LABELS_URL, PROJECTS_URL, WORKSPACES_URL
} from '../config/urls';
import QueryClientUpdater from '../new_arch/services/QueryClientUpdater';

export default class LabelSystem {
	constructor( dispatch ) {
		this.dispatch = dispatch;
	}

	toggleLabelOnAsset( { asset, label } ) {
		this.toggleLabelOnAssets( { assets: [ asset ], label } );
	}

	toggleLabelOnAssets( { assets, label } ) {
		if ( this._allLabellablesHaveLabel( { labellables: assets, label } ) ) {
			this.removeLabelFromAssets( assets );
		} else {
			this.addLabelToAssets( { assets, label } );
		}
	}

	toggleLabelOnProjects( { projects, label } ) {
		if ( this._allLabellablesHaveLabel( { labellables: projects, label } ) ) {
			this.removeLabelFromProjects( projects );
		} else {
			this.addLabelToProjects( { projects, label } );
		}
	}

	fetchProjectLabels( projectID ) {
		return this.dispatch( ( dispatch, _, api ) => dispatch( {
			type: FETCH_PROJECT_LABELS,
			promise: api.get( `${PROJECTS_URL}/${projectID}/labels` ).then( response => response.response ),
			meta: { projectID }
		} ) );
	}

	addLabelToAsset( { asset, label } ) {
		return this.addLabelToAssets( { assets: [ asset ], label } );
	}

	addLabelToAssets( { assets, label } ) {
		const assetIDs = assets.map( asset => asset.id );
		return this.dispatch( ( dispatch, _, api ) => dispatch( {
			type: ADD_LABEL_TO_ASSETS,
			promise: api.post(
				`${ASSETS_URL}/add_label`,
				{ assets_ids: assetIDs, label_id: label.id }
			).then( () => {
				QueryClientUpdater.addedLabelToAssets( { assets, label } );
				return label.toJSON();
			} ),
			meta: { assetIDs }
		} ) );
	}

	removeLabelFromAsset( asset ) {
		return this.removeLabelFromAssets( [ asset ] );
	}

	removeLabelFromAssets( assets ) {
		const assetIDs = assets.map( asset => asset.id );
		return this.dispatch( ( dispatch, _, api ) => dispatch( {
			type: REMOVE_LABEL_FROM_ASSETS,
			promise: api.post(
				`${ASSETS_URL}/remove_label`,
				{ assets_ids: assetIDs }
			).then( ( response ) => {
				QueryClientUpdater.removedLabelFromAssets( { assets } );
				return response;
			} ),
			meta: { assetIDs }
		} ) );
	}

	addLabelToProject( { project, label } ) {
		return this.addLabelToProjects( { projects: [ project ], label } );
	}

	addLabelToProjects( { projects, label } ) {
		const projectIDs = projects.map( project => project.id );
		return this.dispatch( ( dispatch, _, api ) => dispatch( {
			type: ADD_LABEL_TO_PROJECTS,
			promise: api.post(
				`${PROJECTS_URL}/add_label`,
				{ projects_ids: projectIDs, label_id: label.id }
			).then( () => label.toJSON() ),
			meta: { projectIDs }
		} ) );
	}

	removeLabelFromProject( project ) {
		return this.removeLabelFromProjects( [ project ] );
	}

	removeLabelFromProjects( projects ) {
		const projectIDs = projects.map( project => project.id );
		return this.dispatch( ( dispatch, _, api ) => dispatch( {
			type: REMOVE_LABEL_FROM_PROJECTS,
			promise: api.post(
				`${PROJECTS_URL}/remove_label`,
				{ projects_ids: projectIDs }
			),
			meta: { projectIDs }
		} ) );
	}

	_actionTypeOfLabellable( { labellableType, projectActionType, workspaceActionType } ) {
		return labellableType === Label.type.project ? projectActionType : workspaceActionType;
	}

	createLabel( {
		name, baseColor, hoverColor, labellableType, labellableID
	} ) {
		return this.dispatch( ( dispatch, _, api ) => dispatch( {
			type: this._actionTypeOfLabellable( {
				labellableType,
				projectActionType: CREATE_PROJECT_LABEL,
				workspaceActionType: CREATE_WORKSPACE_LABEL
			} ),
			promise: api.post(
				LABELS_URL,
				{
					name,
					base_color: baseColor,
					hover_color: hoverColor,
					labellable_type: labellableType,
					labellable_id: labellableID
				}
			).then( response => response.response ),
			meta: { labellableID }
		} ) );
	}

	editLabel( { name, color, label } ) {
		return this.dispatch( ( dispatch, _, api ) => dispatch( {
			type: this._actionTypeOfLabellable( {
				labellableType: label.labellableType,
				projectActionType: EDIT_PROJECT_LABEL,
				workspaceActionType: EDIT_WORKSPACE_LABEL
			} ),
			promise: api
				.patch(
					`${LABELS_URL}/${label.id}`,
					{ name, base_color: color.baseColor, hover_color: color.hoverColor }
				)
				.then( this._notifyLabelUpdateOrDeletionToQueryClient ),
			meta: { labelID: label.id }
		} ) );
	}

	deleteLabel( { label } ) {
		return this.dispatch( ( dispatch, _, api ) => dispatch( {
			type: this._actionTypeOfLabellable( {
				labellableType: label.labellableType,
				projectActionType: DELETE_PROJECT_LABEL,
				workspaceActionType: DELETE_WORKSPACE_LABEL
			} ),
			promise: api
				.delete( `${LABELS_URL}/${label.id}` )
				.then( this._notifyLabelUpdateOrDeletionToQueryClient ),
			meta: { labelID: label.id, labellableID: label.labellableID }
		} ) );
	}

	fetchWorkspaceLabels( workspaceID ) {
		return this.dispatch( ( dispatch, _, api ) => dispatch( {
			type: FETCH_WORKSPACE_LABELS,
			promise: api.get( `${WORKSPACES_URL}/${workspaceID}/labels` ).then( response => response.response ),
			meta: { workspaceID }
		} ) );
	}

	setProjectLabelsOrder( { projectID, labels } ) {
		const labelIDs = labels.map( label => label.id );
		return this.dispatch( ( dispatch, _, api ) => dispatch( {
			type: SET_PROJECT_LABELS_ORDER,
			promise: api
				.patch(
					`${PROJECTS_URL}/${projectID}/set_labels_order`,
					{ label_ids: labelIDs }
				).then( () => labelIDs ),
			meta: { labellableID: projectID, labellableType: Label.type.project }
		} ) );
	}

	setWorkspaceLabelsOrder( { workspaceID, labels } ) {
		const labelIDs = labels.map( label => label.id );
		return this.dispatch( ( dispatch, _, api ) => dispatch( {
			type: SET_WORKSPACE_LABELS_ORDER,
			promise: api
				.patch(
					`${WORKSPACES_URL}/${workspaceID}/set_labels_order`,
					{ label_ids: labelIDs }
				).then( () => labelIDs ),
			meta: { labellableID: workspaceID, labellableType: Label.type.workspace }
		} ) );
	}

	createFromGroups( { projectID, groupsIDs } ) {
		return this.dispatch( ( dispatch, _, api ) => dispatch( {
			type: CREATE_LABELS_FROM_GROUPS,
			promise: api.post(
				`${LABELS_URL}/create_from_groups`,
				{ groups_ids: groupsIDs, labellable_id: projectID, labellable_type: 'Project' }
			).then( response => response.response ),
			meta: { projectID }
		} ) );
	}

	_allLabellablesHaveLabel( { labellables, label } ) {
		return labellables.every( labellable => labellable.hasLabel( label ) );
	}

	_notifyLabelUpdateOrDeletionToQueryClient = ( { response } ) => {
		QueryClientUpdater.updatedOrDeletedLabel( { labelJson: response } );
		return response;
	}
}
