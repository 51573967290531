import Comment from './comment';

export default class CommentReply extends Comment {
	constructor( {
		id, assetID, roundID, text, authorID, parentID,
		createdAt, isEdited, author = undefined, seenByUsers = [], files = []
	} ) {
		super( {
			id,
			assetID,
			roundID,
			text: text || '',
			authorID,
			createdAt,
			author,
			seenByUsers,
			isEdited,
			files
		} );

		this.parentID = parentID;
		this.author = author;
		this.seenByUsers = seenByUsers;
	}

	get wasMarkedAsCompleted() {
		return false;
	}

	get deleteConfirmationText() {
		return 'Are you sure you want to delete this reply?';
	}

	get deleteConfirmationTitle() {
		return 'Delete Reply';
	}

	static fromJSON( properties, author, seenByUsers = [] ) {
		return new CommentReply( {
			id: properties.id,
			assetID: properties.asset_id,
			roundID: properties.version_id,
			text: properties.text,
			authorID: properties.user,
			parentID: properties.parent_id,
			createdAt: new Date( properties.created_at ),
			isEdited: properties.is_edited,
			author,
			seenByUsers,
			files: properties.files
		} );
	}

	toJSON() {
		return {
			id: this.id,
			asset_id: this.assetID,
			version_id: this.roundID,
			text: this.text,
			user: this.authorID,
			parent_id: this.parentID,
			created_at: this.createdAt.toISOString(),
			is_edited: this.isEdited,
			files: this.files
		};
	}

	clone() {
		return new CommentReply( {
			id: this.id,
			assetID: this.assetID,
			roundID: this.roundID,
			text: this.text,
			authorID: this.authorID,
			parentID: this.parentID,
			createdAt: this.createdAt,
			isEdited: this.isEdited,
			author: this.author,
			seenByUsers: this.seenByUsers,
			files: this.files
		} );
	}
}
