import AddOn from '../entities/addOn';
import StorageAddOnModalPresenter from '../presenters/AddOnsModal/StorageAddOnModalPresenter';
import MembersAddOnModalPresenter from '../presenters/AddOnsModal/MembersAddOnModalPresenter';

export default class AddOnModalPresenterTypeFactory {
	static for( addOnModalPresenter ) {
		switch ( addOnModalPresenter.selectedAddOnType ) {
		case AddOn.storageType:
			return new StorageAddOnModalPresenter( addOnModalPresenter );
		case AddOn.membersType:
			return new MembersAddOnModalPresenter( addOnModalPresenter );
		default:
			throw Error( 'Invalid addOn type' );
		}
	}
}
