import { Tooltip as Tippy } from 'react-tippy';

import './Tooltip.scss';

type TooltipProps = {
	zIndex?: number,
	className?: string,
	title?: string,
	children: React.ReactNode
} & React.ComponentProps<typeof Tippy>

const escapeHtml = ( unsafeString?: string ) => (
	unsafeString
		?.replace( /&/g, '&amp;' )
		.replace( /</g, '&lt;' )
		.replace( />/g, '&gt;' )
		.replace( /"/g, '&quot;' )
		.replace( /'/g, '&#039;' )
);

export const Tooltip = ( { zIndex = 9999, className, children, position = 'top', title, ...props }: TooltipProps ) => (
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	<Tippy
		trigger="mouseenter"
		animation="fade"
		position={position}
		distance={10}
		duration={200}
		arrow
		{...props}
		title={escapeHtml( title )}
		className={`Tooltip ${className ?? ''}`}
		popperOptions={{
			modifiers: {
				addZIndex: {
					enabled: true,
					order: 100,
					fn: ( data : { styles?: React.CSSProperties } ) => ( {
						...data,
						styles: {
							...data.styles,
							zIndex
						}
					} )
				}
			}
		}}
	>
		{children}
	</Tippy>
);

export default Tooltip;
