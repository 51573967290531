import { makeAutoObservable } from 'mobx';
import PricingSubscriptionPresenter from '../PlanSettings/PricingSubscriptionPresenter';
import { SUBSCRIPTION_MODAL_KEY } from '../../systems/ModalSystem';

export default class SubscriptionModalPresenter {
	constructor( {
		workspace, modalSystem, modals, createWorkspaceAction
	} ) {
		this.workspace = workspace;
		this.modalSystem = modalSystem;
		this.modals = modals;
		this.createWorkspaceAction = createWorkspaceAction;

		this.showingCheckoutModal = false;
		this.pricingPlanPrice = undefined;
		makeAutoObservable( this );
	}

	get showSubscriptionModal() {
		return this.modals.get( SUBSCRIPTION_MODAL_KEY );
	}

	openSubscriptionModal = () => {
		this.modalSystem.showModal( { modalKey: SUBSCRIPTION_MODAL_KEY } );
	};

	closeSubscriptionModal = () => {
		this.modalSystem.hideModal( { modalKey: SUBSCRIPTION_MODAL_KEY } );
	};

	closeCheckoutModal = () => {
		this.showingCheckoutModal = false;
	}

	openCheckoutModal = () => {
		this.showingCheckoutModal = true;
	}

	_setPricingPlanPrice = ( { aPricingPlanPrice } ) => {
		this.pricingPlanPrice = aPricingPlanPrice;
	}

	onUpgradeButtonClicked = ( aPricingPlanPrice ) => {
		this.upgradeToPrice( { aPricingPlanPrice } );
	};

	upgradeToPrice = ( { aPricingPlanPrice } ) => {
		this._setPricingPlanPrice( { aPricingPlanPrice } );
		this.openCheckoutModal();
	}

	onSuccessfulPaymentDialogAccepted = () => {
		this.closeCheckoutModal();
		this.closeSubscriptionModal();
	};

	_createFreeTrialWorkspace = ( { aPricingPlanPrice } ) => {
		this.createWorkspaceAction( aPricingPlanPrice );
	}

	onNoWorkspaceButtonClicked = ( aPricingPlanPrice ) => {
		if ( aPricingPlanPrice.pricingPlan.name === 'Pro Plan' ) {
			this._createFreeTrialWorkspace( { aPricingPlanPrice } );
			this.closeSubscriptionModal();
		} else {
			this.onUpgradeButtonClicked( aPricingPlanPrice );
		}
	}

	get pricingSubscriptionPresenter() {
		return new PricingSubscriptionPresenter( { workspace: this.workspace } );
	}
}
