export default class CheckoutSubscriptionOperationFactory {
	constructor( createSubscription, createWorkspace ) {
		this.createSubscription = createSubscription;
		this.createWorkspace = createWorkspace;
	}

	operationForWorkspace = workspace => (
		!workspace
			? this.createWorkspace
			: ( ...args ) => this.createSubscription( workspace.id, ...args )
	)
}
