import classNames from 'classnames';
import Lottie from 'react-lottie';
import { type Snackbar as SnackbarProps } from '../types';
import * as animationData from '../../../assets/animations/circle-loader.json';
import Icon from '../../../new_arch/components/Icon/Icon';
import IconButton from '../../UI/IconButton/IconButton';

import './Snackbar.scss';

const ICONS_FOR_TYPE = {
	error: 'error',
	loading: null,
	success: 'check_circle_outline'
} as const;

const Snackbar = ( { title, type, description, onClose }: SnackbarProps ) => {
	const icon = ICONS_FOR_TYPE[ type ];
	const isLoaderSnackbar = type === 'loading';

	const renderIconOrLoader = () => {
		if ( icon ) {
			return ( <Icon icon={icon} size={18} /> );
		}

		if ( isLoaderSnackbar ) {
			return (
				<Lottie
					options={{
						loop: true,
						autoplay: true,
						animationData
					}}
					height={18}
					width={18}

				/>
			);
		}

		return null;
	};

	return (
		<div className={classNames( 'Snackbar', { [ type ]: type, withDescription: description } )}>
			{renderIconOrLoader()}
			<div className="texts">
				<span className="title-text">{title}</span>
				{description && <p className="description">{description}</p>}
			</div>
			{ !isLoaderSnackbar && (
				<IconButton icon="close" size={18} onClick={onClose} />
			) }
		</div>
	);
};

export default Snackbar;
