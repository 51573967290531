import { Map } from 'immutable';
import { handle } from 'redux-pack';
import {
	UPDATE_ASSETS_VIEW_FILTERS,
	SET_ASSETS_VIEW_SORT,
	SET_ASSETS_CUSTOM_SORT,
	GET_PROJECT_SETTINGS,
	DELETE_PROJECT_LABEL
} from '../actions/types';
import SortOption from '../entities/sortOption';
import AssetsFilterAndSortOptions, { DEFAULT_ASSETS_FILTER_OPTION } from './records/assetsFilterAndSortOptions';
import { handleSuccess } from '../lib/reduxUtils';
import { compact } from '../lib/objectUtils';

const getProjectIDFromAction = action => action.meta.projectID;

const updateProjectOptions = (
	prevState, projectID, optionsToUpdate
) => prevState
	.update(
		projectID,
		AssetsFilterAndSortOptions(),
		options => Object
			.keys( optionsToUpdate )
			.reduce(
				( result, optionKey ) => result
					.set( optionKey, optionsToUpdate[ optionKey ] ),
				options
			)
	);

export const assetsFilterAndSortOptions = ( state = new Map(), action ) => {
	switch ( action.type ) {
	case SET_ASSETS_VIEW_SORT:
		return updateProjectOptions(
			state,
			getProjectIDFromAction( action ),
			action.payload
		);
	case UPDATE_ASSETS_VIEW_FILTERS:
		return updateProjectOptions(
			state,
			getProjectIDFromAction( action ),
			compact( action.payload )
		);
	case SET_ASSETS_CUSTOM_SORT:
		return handle( state, action, {
			start: prevState => updateProjectOptions(
				prevState,
				getProjectIDFromAction( action ),
				{
					sort: SortOption.byUser.id,
					customSort: action.meta.customSort,
					sortAscending: action.meta.ascending
				}
			)
		} );
	case GET_PROJECT_SETTINGS:
		return handleSuccess(
			state,
			action,
			prevState => updateProjectOptions(
				prevState,
				getProjectIDFromAction( action ),
				{ labelsOrder: action.payload.labelsOrder }
			)
		);
	case DELETE_PROJECT_LABEL:
		return handleSuccess(
			state,
			action,
			prevState => prevState
				.update(
					action.meta.labellableID,
					AssetsFilterAndSortOptions(),
					options => (
						options.get( 'filter' ) === action.meta.labelID
							? options.set( 'filter', DEFAULT_ASSETS_FILTER_OPTION )
							: options
					)
				)
		);
	default:
		return state;
	}
};
