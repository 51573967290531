const { REACT_APP_API_URL: API_URL } = process.env;

export const PRICING_PLANS_URL = `${API_URL}/1/pricing_plans`;
export const WORKSPACES_URL = `${API_URL}/1/workspaces`;
export const PROJECTS_URL = `${API_URL}/1/projects`;
export const FOLDERS_URL = `${API_URL}/1/folders`;
export const LABELS_URL = `${API_URL}/1/labels`;
export const SHARED_PROJECTS_VIEW_URL = `${API_URL}/1/shared_projects`;
export const SHARED_FOLDERS_URL = `${API_URL}/1/shared_folders`;
export const SHARED_PROJECTS_URL = `${API_URL}/1/shared_projects`;
export const ASSETS_URL = `${API_URL}/1/assets`;
export const COMMENTS_URL = `${API_URL}/1/comments`;
export const NOTIFICATIONS_URL = `${API_URL}/1/notifications`;
export const SESSIONS_URL = `${API_URL}/1/sessions`;
export const CURRENT_USER_URL = `${API_URL}/1/users/me`;
export const USERS_URL = `${API_URL}/1/users`;
export const GUESTS_URL = `${API_URL}/1/guests`;
export const NOTIFICATIONS_PREFERENCES_URL = `${API_URL}/1/notifications_preferences`;
export const USER_PREFERENCES = `${API_URL}/1/user_preferences`;
export const NOTIFICATIONS_MARK_AS_SEEN_URL = `${API_URL}/1/mark_notifications_as_read`;
export const DIRECT_FILE_UPLOAD_URL = `${API_URL}/rails/active_storage/direct_uploads`;
export const PROJECT_INVITATIONS_URL = `${API_URL}/1/projects/invites`;
export const WORKSPACES_INVITATIONS_URL = `${API_URL}/1/workspaces/invites`;
export const ACCESS_CODES_URL = `${API_URL}/1/access_codes`;
export const PROMO_CODES_URL = `${API_URL}/1/promotion_codes`;
export const CREATE_GUEST_SESSION_URL = `${API_URL}/1/sessions/guest`;
export const GUEST_USER_AVATAR_URL = `${API_URL}/images/guest-user-avatar.png`;
export const PUSHER_AUTH_URL = `${API_URL}/1/pusher/auth`;

// LEGAL
export const TERMS_AND_CONDITIONS_URL = 'http://support.verybusy.io/en/articles/5368112-terms-and-conditions';
export const PRIVACY_POLICY_URL = 'http://support.verybusy.io/en/articles/5368037-privacy-policy';
