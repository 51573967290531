import type { InfiniteData, QueryClient, QueryFilters, QueryKey } from '@tanstack/react-query';

import { PaginatedFetchResult } from '../types';

export interface Identifiable { id: number }
export type QueryDataUpdater<TData> = ( data: TData ) => TData;
export type PaginatedData<TItemJSON> = InfiniteData<PaginatedFetchResult<TItemJSON>>;
export type PaginatedDataUpdater<TItemJSON extends Identifiable> = QueryDataUpdater<PaginatedData<TItemJSON>>;

const itemArrayDifference = <TLeftItem extends Identifiable, TRightItem extends Identifiable>(
	leftItems: TLeftItem[], rightItems: TRightItem[]
) => (
		leftItems.filter( i1 => rightItems.every( i2 => i1.id !== i2.id ) )
	);

const addItemToPaginatedData = <TItemJSON extends Identifiable>(
	item: TItemJSON,
	data: PaginatedData<TItemJSON>
): PaginatedData<TItemJSON> => ( {
		...data,
		pages: [
			{
				...data.pages[ 0 ],
				items: [ item, ...data.pages[ 0 ].items ]
			},
			...data.pages.slice( 1 )
		]
	} );

const hasItemPaginated = <TItemJSON extends Identifiable>(
	item: TItemJSON,
	data: PaginatedData<TItemJSON>
): boolean => data.pages.some(
		page => page.items.some( i => i.id === item.id )
	);

const updateItemsInPaginatedData = <TItemJSON extends Identifiable>(
	itemIdsToUpdate: number[],
	itemUpdater: ( item: TItemJSON ) => TItemJSON,
	data: PaginatedData<TItemJSON>
): PaginatedData<TItemJSON> => ( {
		...data,
		pages: data.pages.map(
			page => ( {
				...page,
				items: page.items.map( item => (
					itemIdsToUpdate.includes( item.id ) ? itemUpdater( item ) : item )
				)
			} )
		)
	} );

export const addItem = <TItemJSON extends Identifiable>( item: TItemJSON ) => (
	( data: TItemJSON[] ): TItemJSON[] => [ item, ...data ]
);

export const replaceItem = <TItemJSON extends Identifiable>( withItem: TItemJSON ) => (
	( data: TItemJSON[] ): TItemJSON[] => (
		data.map( item => ( item.id === withItem.id ? withItem : item ) )
	)
);

export const updateItems = <TItemJSON extends Identifiable>(
	itemIdsToUpdate: number[],
	itemUpdater: ( item: TItemJSON ) => TItemJSON
) => (
		( data: TItemJSON[] ): TItemJSON[] => (
			data.map( item => ( itemIdsToUpdate.includes( item.id ) ? itemUpdater( item ) : item ) )
		)
	);

export const updateItemProperty = <
	TItemJSON extends Identifiable, K extends keyof TItemJSON
>( id: number, key: K, value: TItemJSON[ K ] ) => (
		( data: TItemJSON[] ): TItemJSON[] => (
			data.map( item => ( item.id === id ? { ...item, [ key ]: value } : item ) )
		)
	);

export const removeItems = <TItemJSON extends Identifiable, TItemToRemove extends Identifiable>(
	itemsToRemove: TItemToRemove[]
) => (
		( data: TItemJSON[] ): TItemJSON[] => itemArrayDifference( data, itemsToRemove )
	);

export const removeItemsPaginated = <TItemJSON extends Identifiable, TItemToRemove extends Identifiable>(
	itemsToRemove: TItemToRemove[]
) => (
		( data: PaginatedData<TItemJSON> ): PaginatedData<TItemJSON> => ( {
			...data,
			pages: data.pages.map(
				page => ( {
					...page,
					items: itemArrayDifference( page.items, itemsToRemove )
				} )
			)
		} )
	);

export const addOrUpdateItemPaginated = <TItemJSON extends Identifiable>(
	item: TItemJSON
) => ( data: PaginatedData<TItemJSON> ): PaginatedData<TItemJSON> => (
		hasItemPaginated( item, data )
			? updateItemsInPaginatedData( [ item.id ], () => item, data )
			: addItemToPaginatedData( item, data )
	);

export const updateItemsPaginated = <TItemJSON extends Identifiable>(
	itemIdsToUpdate: number[],
	itemUpdater: ( item: TItemJSON ) => TItemJSON
) => ( data: PaginatedData<TItemJSON> ): PaginatedData<TItemJSON> => (
		updateItemsInPaginatedData( itemIdsToUpdate, itemUpdater, data )
	);

export const addItemPaginated = <TItemJSON extends Identifiable>(
	item: TItemJSON
) => ( data: PaginatedData<TItemJSON> ): PaginatedData<TItemJSON> => (
		addItemToPaginatedData( item, data )
	);

export const updateItemsPropertyPaginated = <TItemJSON extends Identifiable, K extends keyof TItemJSON>(
	itemIdsToUpdate: number[], property: K, value: TItemJSON[ K ]
): PaginatedDataUpdater<TItemJSON> => updateItemsPaginated(
		itemIdsToUpdate,
		item => ( { ...item, [ property ]: value } )
	);

export const applyAll = <TData>( ...updaters: Array<QueryDataUpdater<TData>> ) => (
	( data: TData ) => updaters.reduce(
		( currentData, updater ) => updater( currentData ),
		data
	)
);

const applyUpdater = <TData>( updater: QueryDataUpdater<TData>, data?: TData ) => {
	if ( !data ) return undefined;

	return updater( data );
}

export const updateQueryData = <TData>(
	queryClient: QueryClient,
	queryKey: QueryKey,
	updater: QueryDataUpdater<TData>
) => {
	queryClient.setQueryData<TData>( queryKey, data => applyUpdater( updater, data ) );
}

export const updateQueriesData = <TData>(
	queryClient: QueryClient,
	queryFilters: QueryFilters,
	updater: QueryDataUpdater<TData>
) => {
	queryClient.setQueriesData<TData>( queryFilters, data => applyUpdater( updater, data ) );
}
