class AssetRound {
	constructor( id, assetID, number, imageURL, thumbnailURL, uploaderID, lowQualityPlaceholderURL ) {
		this.id = id;
		this.assetID = assetID;
		this.number = number;
		this.imageURL = imageURL;
		this.thumbnailURL = thumbnailURL;
		this.uploaderID = uploaderID;
		this.lowQualityPlaceholderURL = lowQualityPlaceholderURL;
	}

	static fromJSON( properties ) {
		return new AssetRound(
			properties.id,
			properties.asset_id,
			properties.number,
			properties.image_url,
			properties.thumbnail_url,
			properties.uploader && properties.uploader.id,
			properties.lqip_url
		);
	}

	toJSON() {
		return {
			id: this.id,
			asset_id: this.assetID,
			number: this.number,
			image_url: this.imageURL,
			thumbnail_url: this.thumbnailURL,
			lqip_url: this.lowQualityPlaceholderURL
		};
	}

	get isProcessing() {
		return !this.imageURL || !this.thumbnailURL;
	}

	get image() {
		return {
			fullQualityURL: this.imageURL,
			lowQualityPlaceholderURL: this.lowQualityPlaceholderURL
		};
	}

	belongsToAsset( assetID ) {
		return this.assetID === assetID;
	}
}

export default AssetRound;
