import { Location } from 'history';
import type TrackerBackend from './TrackerBackend';
import MixpanelTrackerBackend from './MixpanelTrackerBackend';

type UserData = {
	id: number,
	isGuest: boolean,
	name: string,
	email: string,
	role: string
}

class Tracker {
	private trackerBackend: TrackerBackend;

	constructor( trackerBackend: TrackerBackend ) {
		this.trackerBackend = trackerBackend;
	}

	initialize() {
		this.trackerBackend.initialize();
	}

	setUser( { id, isGuest, name, email, role } : UserData ) {
		this.trackerBackend.setUserID( id );

		const mixpanelSuperProperties = this.getSuperProperties( isGuest );
		const mixpanelUserProperties = this.getUserProperties( isGuest, email, name, role );

		( this.trackerBackend as MixpanelTrackerBackend ).setSuperProperties( mixpanelSuperProperties );
		( this.trackerBackend as MixpanelTrackerBackend ).setUserProperties( mixpanelUserProperties );
	}

	private getSuperProperties( isGuest: boolean ) {
		return {
			is_guest: isGuest
		};
	}

	private getUserProperties( isGuest: boolean, email: string, name: string, role: string ) {
		return {
			is_guest: isGuest,
			$email: email,
			$name: name,
			role
		};
	}

	trackLocation( location: Location ) {
		this.trackerBackend.trackLocation( location );
	}

	trackPanel( panelName: string ) {
		this.trackerBackend.trackPanel( panelName );
	}

	trackCollaborationAction( args: { action: string, [key: string]: unknown } ) {
		this.trackerBackend.trackEvent( { category: 'Collaboration', ...args } );
	}

	trackAccessAction( args: { action: string, [key: string]: unknown } ) {
		this.trackerBackend.trackEvent( { category: 'Access', ...args } );
	}

	trackSubscriptionAction( args: { action: string, label: string, value: number } ) {
		this.trackerBackend.trackEvent( { category: 'Subscription', ...args } );
	}

	trackWorkspaceAction( args: { action: string, value: number } ) {
		this.trackerBackend.trackEvent( { category: 'Workspace Management', ...args } );
	}

	trackProjectAction( args: { action: string, label: string, value: number } ) {
		this.trackerBackend.trackEvent( { category: 'Project Management', ...args } );
	}

	trackFolderAction( args: { action: string, [key: string]: unknown } ) {
		this.trackerBackend.trackEvent( { category: 'Folder Management', ...args } );
	}

	trackAssetAction( args: { action: string, [key: string]: unknown } ) {
		this.trackerBackend.trackEvent( { category: 'Asset Management', ...args } );
	}

	trackCommentsAndMarkupsAction( args: { action: string, [key: string]: unknown } ) {
		this.trackerBackend.trackEvent( { category: 'Comments & Markups', ...args } );
	}

	trackTaskAction( args: { action: string, label: string, value: number } ) {
		this.trackerBackend.trackEvent( { category: 'Task', ...args } );
	}

	trackImageToolAction( args: { action: string, label: string, value: number } ) {
		this.trackerBackend.trackEvent( { category: 'Image Tools', ...args } );
	}

	trackNotificationAction( args: { action: string, label: string, value: number } ) {
		this.trackerBackend.trackEvent( { category: 'Notifications', ...args } );
	}

	trackNavigationAction( args: { action: string, [key: string]: unknown } ) {
		this.trackerBackend.trackEvent( { category: 'Navigation', ...args } );
	}

	trackOverviewAction( args: { action: string, [key: string]: unknown } ) {
		this.trackerBackend.trackEvent( { category: 'Overview', ...args } );
	}

	trackSharingAction( args: { action: string, [key: string]: unknown } ) {
		this.trackerBackend.trackEvent( { category: 'Sharing', ...args } );
	}

	trackNACAction( args: { action: string, [key: string]: unknown } ) {
		this.trackerBackend.trackEvent( { category: 'No Account Collaboration', ...args } );
	}

	trackAddOnAction( args: { action: string, [key: string]: unknown } ) {
		this.trackerBackend.trackEvent( { category: 'Add Ons', ...args } );
	}
}

export default Tracker;
export const sharedTracker = new Tracker( new MixpanelTrackerBackend() );
