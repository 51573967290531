import PropTypes from 'prop-types';
import classNames from 'classnames';

import IconButton from '../IconButton/IconButton';

import { MIDDLE_GREY, PRIMARY_BASE, SECONDARY_PRESSED } from '../../../styles/colors';

import './RadioButton.scss';

const RadioButton = ( {
	selected, onClick, size, disabled, selectedColor, unselectedColor
} ) => {
	const icon = selected ? 'radio_button_checked' : 'radio_button_unchecked';
	const color = selected ? selectedColor : unselectedColor;
	const buttonClassName = selected ? 'selected-state' : 'default-state';

	const onButtonClick = ( event ) => {
		event.stopPropagation();
		return onClick();
	};

	return (
		<div className={classNames( 'RadioButton', { disabled } )}>
			<IconButton
				icon={icon}
				onClick={onButtonClick}
				size={size}
				buttonClassName={buttonClassName}
				color={disabled ? MIDDLE_GREY : color}
				disabled={disabled}
			/>
		</div>
	);
};

RadioButton.propTypes = {
	selected: PropTypes.bool,
	onClick: PropTypes.func,
	size: PropTypes.number,
	disabled: PropTypes.bool,
	selectedColor: PropTypes.string,
	unselectedColor: PropTypes.string
};

RadioButton.defaultProps = {
	selected: false,
	onClick: () => {},
	size: 20,
	disabled: false,
	selectedColor: PRIMARY_BASE,
	unselectedColor: SECONDARY_PRESSED
};

export default RadioButton;
