import { observer } from 'mobx-react-lite';
import classNames from 'classnames';

import WhiteBox from '../WhiteBox/WhiteBox';
import GuestViewPreviewEmailTooltip from './GuestViewPreviewEmailTooltip/GuestViewPreviewEmailTooltip';
import ErrorCard from '../../new_arch/modules/authentication/components/ErrorCard/ErrorCard';
import Button from '../../new_arch/components/Button/Button';
import VeryBusyPreviewLogo from '../../new_arch/components/VeryBusyPreviewLogo/VeryBusyPreviewLogo';
import FormInput from '../../new_arch/components/FormInput/FormInput';
import DropdownSelect from '../DropdownSelect/DropdownSelect';
import useProjectGuestViewPreviewPresenter from '../../presenters/ProjectGuestViewPreview/useProjectGuestViewPreviewPresenter';
import { PRIVACY_POLICY_URL, TERMS_AND_CONDITIONS_URL } from '../../config/urls';

import './ProjectGuestViewPreview.scss';

type ProjectGuestViewPreviewProps = {
	projectID: number,
	name: string,
	guestHash: string
}

const ProjectGuestViewPreview =	( { projectID, name, guestHash }: ProjectGuestViewPreviewProps ) => {
	const presenter = useProjectGuestViewPreviewPresenter( { projectID, guestHash } );

	const renderTitle = () => (
		<div className="title">
			<p className="title-invitation">
				You&apos;ve been invited to collaborate on:
			</p>
			<h4 className="project-title">{name}</h4>
		</div>
	)

	const renderTermsAndConditions = () => (
		<p className="terms-and-conditions">
			By continuing as a guest, I agree to VeryBusy&apos;s
			<br />
			<a
				target="_blank"
				rel="noopener noreferrer"
				href={TERMS_AND_CONDITIONS_URL}
			>
				Terms & Conditions
			</a>
			&nbsp;and
			<a
				target="_blank"
				rel="noopener noreferrer"
				href={PRIVACY_POLICY_URL}
			>
				Privacy Policy
			</a>
		</p>
	)

	const renderForm = () => (
		<form onSubmit={presenter.onFormSubmitted}>
			<FormInput
				className={classNames( 'NameInput' )}
				{...presenter.nameInputProps}
			/>
			<FormInput
				className={classNames( 'EmailInput' )}
				{...presenter.emailInputProps}
				informationTooltip={<GuestViewPreviewEmailTooltip />}
			/>
			<div className={classNames( 'RoleDropdownSelect' )}>
				<div className="RoleDropDownLabel">
					<p>{presenter.roleSelectProps.label}</p>
				</div>
				<DropdownSelect
					{...presenter.roleSelectProps}
				/>
			</div>
			{renderTermsAndConditions()}
			<Button className="SubmitButton" disabled={false} testId="done-button">
				Continue as Guest
			</Button>
		</form>
	)

	const renderLoginInvitation = () => (
		<p className="login">
			Already have an account?&nbsp;
			<button onClick={presenter.onRedirectToSignIn}>
				Login
			</button>
		</p>
	)

	return (
		<div className="ProjectGuestViewPreview">
			<div className="left-side">
				<VeryBusyPreviewLogo />
			</div>
			<div className="right-side">
				<WhiteBox>
					{renderTitle()}
					{!!presenter.formError && <ErrorCard>{presenter.formError}</ErrorCard>}
					{renderForm()}
					{renderLoginInvitation()}
				</WhiteBox>
			</div>
		</div>
	);
};

export default observer( ProjectGuestViewPreview );
