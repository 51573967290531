import { PROJECTS_URL } from '../config/urls';
import { AssetsDownload } from '../entities/assetsDownload';
import AuthSystem from '../new_arch/modules/authentication/system/AuthSystem';
import Api from '../services/Api';
import defaultAssetDownloadService from '../services/AssetDownloadService';
import AssetDownloadService from '../services/AssetDownloadService/AssetDownloadService';
import { trackDownloadedMultipleAssets } from '../services/tracker/trackers/assets';
import { trackGuestLinkOpened } from '../services/tracker/trackers/nac';

type ProjectSystemParams = {
	api: Api,
	assetDownloadService?: AssetDownloadService,
	authSystem?: AuthSystem
}

type DownloadItemsParams = {
	projectID: number,
	assetIDs: number[],
	folderIDs: number[],
	withRoundNumber: boolean,
	lowRes: boolean
};

export default class ProjectSystem {
	private api: Api;
	private assetDownloadService: AssetDownloadService;
	private authSystem?: AuthSystem;

	constructor( {
		api,
		assetDownloadService = defaultAssetDownloadService,
		authSystem
	}: ProjectSystemParams ) {
		this.api = api;
		this.assetDownloadService = assetDownloadService;
		this.authSystem = authSystem;
	}

	async getProjectPreview( { guestHash }: { guestHash: string } ) {
		const projectPreviewResponse = await this.api.get(
			`${PROJECTS_URL}/${guestHash}/preview`
		)

		const { response } = projectPreviewResponse as unknown as { response: { id: number, name: string } };

		return response;
	}

	async requestProjectGuestAccess( { guestHash }: { guestHash: string } ) {
		const guestAccessResponse = await this.api.post(
			`${PROJECTS_URL}/guest_access`,
			{ guest_hash: guestHash }
		);
		const { project_id: projectID } = guestAccessResponse as unknown as { project_id: number };
		trackGuestLinkOpened( {
			projectID,
			guestHash,
			/* Specifying isGuest should not be necessary because the is_guest event property
			is set as a Mixpanel super property from <App /> component when current user changes.
			However for some reason that happens after the call to this method, even though
			the guest session is created before (assuming there was no session before.). As a
			temporary fix we specify it here explicitly. */
			isGuest: this.authSystem?.isGuest
		} );
		return { projectID };
	}

	downloadItems = async ( { projectID, assetIDs, folderIDs, withRoundNumber, lowRes }: DownloadItemsParams ) => {
		trackDownloadedMultipleAssets( {
			projectID,
			assetsCount: assetIDs.length,
			foldersCount: folderIDs.length,
			withRoundNumber,
			lowRes
		} );
		const downloadJson = await this.api.post(
			`${PROJECTS_URL}/${projectID}/download_items`,
			{
				assets_ids: assetIDs,
				folders_ids: folderIDs,
				low_res: lowRes,
				with_round_number: withRoundNumber
			}
		);
		return AssetsDownload.handle( downloadJson, this.assetDownloadService )
	}
}
