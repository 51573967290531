import { Component, createRef } from 'react';
import PropTypes from 'prop-types';

import { connectComponent } from '../../lib/connectComponent';
import { getAssetsUploadsTotalProgress, getAssetActiveUploadsCount } from '../../selectors/assetUploads';

import AssetsUploaderButton from '../../components/assets/AssetsUploaderButton/AssetsUploaderButton';

class AssetsUploaderButtonContainer extends Component {
	constructor( props ) {
		super( props );

		this._assetsUploaderButtonRef = createRef();
	}

	componentDidUpdate( prevProps ) {
		if (
			prevProps.activeAssetUploads === 0 && this.props.activeAssetUploads > 0
			&& this._assetsUploaderButtonRef.current
		) {
			this._assetsUploaderButtonRef.current.show();
		}
	}

	render() {
		const {
			props: {
				projectID, folderID, assetUploadsTotalProgress, activeAssetUploads
			}
		} = this;

		return (
			<AssetsUploaderButton
				ref={this._assetsUploaderButtonRef}
				projectID={projectID}
				folderID={folderID}
				isUploadingAssets={activeAssetUploads > 0}
				totalUploadProgress={assetUploadsTotalProgress}
			/>
		);
	}
}

AssetsUploaderButtonContainer.propTypes = {
	projectID: PropTypes.number,
	folderID: PropTypes.number,
	assetUploadsTotalProgress: PropTypes.number,
	activeAssetUploads: PropTypes.number
};

AssetsUploaderButtonContainer.defaultProps = {
	projectID: null,
	folderID: null,
	assetUploadsTotalProgress: 0,
	activeAssetUploads: 0
};

export default connectComponent( state => ( {
	assetUploadsTotalProgress: getAssetsUploadsTotalProgress( state ),
	activeAssetUploads: getAssetActiveUploadsCount( state )
} ) )( AssetsUploaderButtonContainer );
