import { createSelector } from 'reselect';
import WorkspaceInvitation from '../entities/workspaceInvitation';

export const getRawWorkspaceInvitations = state => state.workspaceInvitations;

const getWorkspaceInvitations = createSelector(
	[ getRawWorkspaceInvitations ],
	workspaceInvitationsSerialization => workspaceInvitationsSerialization
		.map( WorkspaceInvitation.fromJSON )
);

export const getWorkspaceInvitationFromHash = createSelector(
	[
		getWorkspaceInvitations,
		( _, props ) => props.invitationHash
	],
	( workspaceInvitations, hash ) => workspaceInvitations
		.find( invitation => invitation.hash === hash )
);
