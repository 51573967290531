import { Map } from 'immutable';
import {
	CREATE_PROJECT_LABEL, DELETE_PROJECTS, DELETE_PROJECT_LABEL,
	EDIT_PROJECT_LABEL, FETCH_PROJECT_LABELS, LOGOUT,
	FETCH_WORKSPACE_LABELS, CREATE_WORKSPACE_LABEL,
	EDIT_WORKSPACE_LABEL, DELETE_WORKSPACE_LABEL, GET_PROJECT_SETTINGS,
	SET_PROJECT_LABELS_ORDER, SET_WORKSPACE_LABELS_ORDER,
	CREATE_LABELS_FROM_GROUPS
} from '../actions/types';
import Label from '../entities/label';
import { handleStart, handleSuccess, mapForPayload } from '../lib/reduxUtils';

const updateLabelWithOrder = ( {
	label, labelIDs, labellableID, labellableType
} ) => {
	if ( !labelIDs ) return label;
	const order = labelIDs.includes( label.id )
		? labelIDs.findIndex( labelID => label.id === labelID )
		: undefined;
	return (
		label.labellable_type === labellableType && label.labellable_id === labellableID
			? { ...label, order }
			: label
	);
};

export const labels = ( state = Map(), action ) => {
	switch ( action.type ) {
	case FETCH_WORKSPACE_LABELS:
	case FETCH_PROJECT_LABELS:
		return handleSuccess(
			state,
			action,
			prevState => prevState.merge( mapForPayload( action.payload ) )
		);
	case CREATE_WORKSPACE_LABEL:
	case CREATE_PROJECT_LABEL:
		return handleSuccess(
			state,
			action,
			prevState => prevState.merge( mapForPayload( [ action.payload ] ) )
		);
	case EDIT_WORKSPACE_LABEL:
	case EDIT_PROJECT_LABEL:
		return handleSuccess(
			state,
			action,
			prevState => prevState.updateIfSet(
				action.meta.labelID,
				label => ( {
					...label,
					name: action.payload.name,
					base_color: action.payload.base_color,
					hover_color: action.payload.hover_color
				} )
			)
		);
	case DELETE_WORKSPACE_LABEL:
	case DELETE_PROJECT_LABEL:
		return handleSuccess( state, action, prevState => prevState.delete( action.meta.labelID ) );
	case DELETE_PROJECTS:
		return handleSuccess( state, action, prevState => prevState.filter(
			label => !action.payload.includes( label.labellable_id )
		) );
	case GET_PROJECT_SETTINGS:
		return handleSuccess( state, action,
			prevState => prevState.map(
				label => updateLabelWithOrder( {
					label,
					labelIDs: action.payload.labelsOrder,
					labellableID: action.meta.projectID,
					labellableType: Label.type.project
				} ) )
		);
	case SET_PROJECT_LABELS_ORDER:
	case SET_WORKSPACE_LABELS_ORDER:
		return handleSuccess( state, action,
			prevState => prevState.map(
				label => updateLabelWithOrder( {
					label,
					labelIDs: action.payload,
					labellableType: action.meta.labellableType,
					labellableID: action.meta.labellableID
				} ) )
		);
	case CREATE_LABELS_FROM_GROUPS:
		return handleSuccess(
			state,
			action,
			prevState => prevState.merge( mapForPayload( action.payload ) )
		);
	case LOGOUT:
		return handleStart( state, action, () => Map() );
	default:
		return state;
	}
};
