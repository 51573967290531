/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeAutoObservable } from 'mobx';
import WorkspaceSystem from '../../systems/WorkspaceSystem';
import SnackbarSystem from '../../systems/SnackbarSystem';
import { isErrorWithStatus } from '../../errors';
import LabelGroupCreateStrategy from './utils/LabelGroupCreateStrategy';
import LabelGroupEditStrategy from './utils/LabelGroupEditStrategy';
import LabelModalPresenter, { LabelModalPresenterParams } from './utils/LabelModalPresenter';
import type LabelGroup from '../../entities/labelGroup';
import { type LabelParams } from '../../entities/labelGroup';

export type CreateOrEditLabelGroupModalPresenterParams = {
	workspaceID: number,
	mode: 'create' | 'edit',
	labelGroup?: LabelGroup,
	onClose: () => void,
	workspaceSystem: WorkspaceSystem;
	snackbarSystem: SnackbarSystem;
}

export default class CreateOrEditLabelGroupModalPresenter {
	name: string;
	labels: LabelParams[];
	isSubmitting: boolean;
	error: string;
	labelModal: LabelModalPresenter | null;

	private onClose: () => void;
	private snackbarSystem: SnackbarSystem;
	private strategy: LabelGroupCreateStrategy | LabelGroupEditStrategy;

	constructor( {
		workspaceID,
		mode,
		labelGroup,
		onClose,
		workspaceSystem,
		snackbarSystem
	}: CreateOrEditLabelGroupModalPresenterParams ) {
		this.onClose = onClose;
		this.snackbarSystem = snackbarSystem;
		this.strategy = mode === 'create'
			? new LabelGroupCreateStrategy( { workspaceID, workspaceSystem } )
			: new LabelGroupEditStrategy( { workspaceID, labelGroup, workspaceSystem } );

		this.name = this.strategy.initialName;
		this.labels = this.strategy.initialLabels;
		this.isSubmitting = false;
		this.error = '';

		this.labelModal = null;

		makeAutoObservable( this );
	}

	get title() {
		return this.isCreatingLabel ? 'Add label' : this.strategy.title;
	}

	get submitButtonLabel() {
		return this.strategy.submitButtonLabel;
	}

	get isSubmitButtonDisabled() {
		return !this.name.trim() || this.labels.length === 0 || this.isSubmitting;
	}

	get isCreatingLabel() {
		return !!this.labelModal;
	}

	onGroupNameChanged = ( newName: string ) => {
		this.name = newName;
	}

	addLabelWasClicked = () => {
		this.openLabelModal( {
			onSubmit: ( name, { baseColor, hoverColor } ) => {
				this.labels.push( { name, baseColor, hoverColor } );
			}
		} );
	}

	editLabelWasClicked = ( labelIndex: number ) => {
		const labelToEdit = this.labels[ labelIndex ];
		this.openLabelModal( {
			initialName: labelToEdit.name,
			initialColor: {
				baseColor: labelToEdit.baseColor,
				hoverColor: labelToEdit.hoverColor
			},
			onSubmit: ( name, { baseColor, hoverColor } ) => {
				this.labels[ labelIndex ] = { name, baseColor, hoverColor };
			}
		} );
	}

	deleteLabelWasClicked = ( labelIndex: number ) => {
		this.labels.splice( labelIndex, 1 );
	}

	backArrowClicked = () => {
		this.labelModal = null;
	}

	onSubmit = () => {
		this.setIsSubmitting( true );
		this.strategy.makeLabelGroupRequest(
			this.name,
			this.labels
		)
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
			.then( ( response: any ) => {
				if ( !response.error ) {
					this.snackbarSystem.showSuccessMessage( { title: this.strategy.successMessage } );
					this.onClose();
				} else if ( isErrorWithStatus( 422 )( response.payload ) ) {
					this.setError( 'This name is used in another group. Please choose another one.' );
				} else {
					this.setError( 'Something went wrong. Please try again.' );
				}
			} )
			.finally( () => this.setIsSubmitting( false ) );
	}

	private setError( error: string ) {
		this.error = error;
	}

	private setIsSubmitting( isSubmitting: boolean ) {
		this.isSubmitting = isSubmitting;
	}

	private openLabelModal( params: Omit<LabelModalPresenterParams, 'namesAlreadyUsed'> ) {
		this.labelModal = new LabelModalPresenter( {
			...params,
			namesAlreadyUsed: this.labels.map( label => label.name ),
			onSubmit: ( ...args ) => {
				params.onSubmit( ...args );
				this.labelModal = null;
			}
		} );
	}
}
