import DirectFileUpload, { DIRECT_FILE_UPLOAD_EVENTS } from './DirectFileUpload';
import PendingQueue from './PendingQueue';
import Uploader from './Uploader';

export const ASSET_UPLOAD_EVENTS = Object.freeze( {
	SUCCESS: 'asset_upload_success',
	PROGRESS: 'asset_upload_progress',
	ERROR: 'asset_upload_error',
	UPLOAD_START: 'asset_upload_start',
	FINISH: 'asset_upload_finish'
} );

export const ASSET_UPLOAD_ERRORS = Object.freeze( {
	APPROVED_ASSET: 'invalid_asset_version'
} );

export const ITEM_UPLOAD_STATUS = Object.freeze( {
	WAITING: 'ASSET_WAITING',
	UPLOADING: 'ASSET_UPLOADING',
	ABORTED: 'ASSET_ABORTED',
	FAILED: 'ASSET_FAILED',
	CREATING_ASSET: 'CREATING_ASSET',
	SUCCEEDED: 'ASSET_SUCCEEDED'
} );

export const ITEM_UPLOAD_STATUS_ORDER = Object.freeze( {
	[ ITEM_UPLOAD_STATUS.FAILED ]: 1,
	[ ITEM_UPLOAD_STATUS.UPLOADING ]: 2,
	[ ITEM_UPLOAD_STATUS.WAITING ]: 3,
	[ ITEM_UPLOAD_STATUS.SUCCEEDED ]: 4,
	[ ITEM_UPLOAD_STATUS.CREATING_ASSET ]: 5,
	[ ITEM_UPLOAD_STATUS.ABORTED ]: 6
} );

class DirectFilesUploaderCoordinator {
	constructor() {
		this.pendingsQueue = new PendingQueue();
		this.uploader = new Uploader();
		this.eventListeners = [];

		this.uploader.addUploadFinishListener( this._uploadFinished.bind( this ) );
	}

	get hasPendingUploads() {
		return !this.pendingsQueue.isEmpty;
	}

	addFiles( files ) {
		const uploads = files.map(
			file => DirectFileUpload.fromFile( file )
		);
		uploads.forEach( ( upload ) => {
			this.setUpUploadEvents( upload );
		} );

		this.pendingsQueue.add( uploads );
		this.processPendingsQueue();
		return uploads;
	}

	setUpUploadEvents( upload ) {
		upload.addEventListener(
			DIRECT_FILE_UPLOAD_EVENTS.PROGRESS,
			this._uploadProgressChanged.bind( this, upload.id )
		);
		upload.addEventListener(
			DIRECT_FILE_UPLOAD_EVENTS.SUCCESS,
			this._uploadSucceeded.bind( this, upload.id )
		);
		upload.addEventListener(
			DIRECT_FILE_UPLOAD_EVENTS.ERROR,
			this._uploadError.bind( this, upload.id )
		);
		upload.addEventListener(
			DIRECT_FILE_UPLOAD_EVENTS.UPLOAD_START,
			this._uploadStarted.bind( this, upload.id )
		);
	}

	processPendingsQueue() {
		while ( this.uploader.hasCapacity && this.hasPendingUploads ) {
			this.uploader.start( this.pendingsQueue.dequeue() );
		}
	}

	cancelAll() {
		this.pendingsQueue.cancelAll();
		this.uploader.cancelAll();
	}

	cancelUpload( uploadID ) {
		this.uploader.cancelUpload( uploadID );
		this.pendingsQueue.cancelUpload( uploadID );
		this.processPendingsQueue();
	}

	addEventListener( event, callback ) {
		this.eventListeners.push( { event, callback } );
	}

	handleEvent( id, eventName, ...params ) {
		this.eventListeners
			.filter( eventListener => eventListener.event === eventName )
			.forEach( eventListener => eventListener.callback( id, ...params ) );
	}

	_uploadStarted( id ) {
		this.handleEvent( id, ASSET_UPLOAD_EVENTS.UPLOAD_START );
	}

	_uploadFinished() {
		this.processPendingsQueue();
	}

	_uploadProgressChanged( id, progress ) {
		this.handleEvent( id, ASSET_UPLOAD_EVENTS.PROGRESS, progress );
	}

	_uploadSucceeded( id, attributes ) {
		this.handleEvent( id, ASSET_UPLOAD_EVENTS.SUCCESS, attributes );
	}

	_uploadError( id, error ) {
		this.handleEvent( id, ASSET_UPLOAD_EVENTS.ERROR, error );
	}
}

export default DirectFilesUploaderCoordinator;
