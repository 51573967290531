import {
	START_DRAWING_MARKUP,
	ADD_SHAPE_TO_CURRENT_DRAWING_MARKUP,
	REMOVE_LAST_SHAPE_FROM_CURRENT_DRAWING_MARKUP,
	STOP_DRAWING_MARKUP,
	SET_MARKUP_EDITING_TOOL,
	SET_MARKUP_EDITING_COLOR,
	SET_MARKUP_EDITING_SIZE,
	RESET_MARKUP_EDITING_SETTINGS,
	SET_MARKUP_EDITOR_ACTIVE_STATUS,
	RESET_MARKUP_EDITOR_ACTIVE_STATUS
} from './types';

export const startDrawingMarkup = () => ( {
	type: START_DRAWING_MARKUP
} );

export const addShapeToCurrentDrawingMarkup = shape => ( {
	type: ADD_SHAPE_TO_CURRENT_DRAWING_MARKUP,
	payload: shape
} );

export const removeLastShapeFromCurrentDrawingMarkup = () => ( {
	type: REMOVE_LAST_SHAPE_FROM_CURRENT_DRAWING_MARKUP
} );

export const stopDrawingMarkup = () => ( {
	type: STOP_DRAWING_MARKUP
} );

export const resetMarkupEditingSettings = () => ( {
	type: RESET_MARKUP_EDITING_SETTINGS
} );

export const setMarkupEditingTool = tool => ( {
	type: SET_MARKUP_EDITING_TOOL,
	payload: tool
} );

export const setMarkupEditingColor = color => ( {
	type: SET_MARKUP_EDITING_COLOR,
	payload: color
} );

export const setMarkupEditingSize = size => ( {
	type: SET_MARKUP_EDITING_SIZE,
	payload: size
} );

export const setMarkupEditorActiveStatus = () => ( {
	type: SET_MARKUP_EDITOR_ACTIVE_STATUS
} );

export const resetMarkupEditorActiveStatus = () => ( {
	type: RESET_MARKUP_EDITOR_ACTIVE_STATUS
} );
