import PropTypes from 'prop-types';

import UserPropType from '../../types/user';

import './UserSelectorItem.scss';
import UserAvatar from '../UserAvatar/UserAvatar';

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

const UserSelectorItem = ( { user, onClick } ) => (
	<div className="UserSelectorItem" onClick={onClick}>
		<div className="small-profile-pic">
			<UserAvatar user={user} />
		</div>
		<div className="user-name">
			<p>{user.name}</p>
		</div>
	</div>
);

UserSelectorItem.propTypes = {
	user: UserPropType.isRequired,
	onClick: PropTypes.func
};

UserSelectorItem.defaultProps = {
	onClick: () => {}
};

export default UserSelectorItem;
