import { createRef, PureComponent } from 'react';
import PropTypes from 'prop-types';

import './NavBarPopup.scss';

class NavBarPopup extends PureComponent {
	constructor( props ) {
		super( props );

		this._containerRef = createRef();
		this._clickHandler = this._clickHandler.bind( this );
	}

	componentDidMount() {
		document.addEventListener( 'click', this._clickHandler );
	}

	componentWillUnmount() {
		document.removeEventListener( 'click', this._clickHandler );
	}

	_clickHandler( event ) {
		const {
			_containerRef: { current },
			props: { onClickOutside }
		} = this;

		if ( current && !current.contains( event.target ) ) {
			onClickOutside( event );
		}
	}

	render() {
		const { props: { children } } = this;

		return (
			<div ref={this._containerRef} className="NavBarPopup">
				<div className="nav-bar-popup-content">
					{children}
				</div>
			</div>
		);
	}
}

NavBarPopup.propTypes = {
	children: PropTypes.node,
	onClickOutside: PropTypes.func
};

NavBarPopup.defaultProps = {
	children: undefined,
	onClickOutside: () => {}
};

export default NavBarPopup;
