import { createRef } from 'react';
import PropTypes from 'prop-types';
import './AssetUploaderBox.scss';
import Container from '../../../containers/Container';

import AssetUploaderUploadsListBox from '../AssetUploaderUploadsListBox/AssetUploaderUploadsListBox';
import AssetUploaderEmptyBox from '../AssetUploaderEmptyBox/AssetUploaderEmptyBox';
import { UPLOADING_KEY, WAITING_KEY } from '../ItemUploadBox/ItemUploadBox';
import Project from '../../../entities/project';
import Folder from '../../../entities/folder';
import PopupSystem from '../../../systems/PopupSystem';
import HistoryPropType from '../../../types/history';
import { directFilesUploaderSubscriber } from '../../../services/directFilesUploaderInitializer';

const isCancelButton = node => (
	node.classList.contains( UPLOADING_KEY ) || node.classList.contains( WAITING_KEY )
);

const hasClickedOutsideUploaderBox = ( uploaderBox, event ) => (
	uploaderBox
	&& event.target
	&& !uploaderBox.contains( event.target )
	&& !isCancelButton( event.target )
);

class AssetUploaderBox extends Container {
	constructor( props ) {
		super( props );

		this.assetUploaderBoxRef = createRef();
		this._clickHandler = this._clickHandler.bind( this );
		this._cancelAllUploads = this._cancelAllUploads.bind( this );
	}

	componentDidMount() {
		document.addEventListener( 'click', this._clickHandler );
	}

	componentDidUpdate( {
		succeededUploadsCount: previousSucceededUploadsCount
	} ) {
		const {
			succeededUploadsCount,
			nonAbortedOrFailedUploadsCount,
			onAllUploadsSucceeded
		} = this.props;

		const allNewUploadsSucceeded = succeededUploadsCount > previousSucceededUploadsCount
			&& succeededUploadsCount === nonAbortedOrFailedUploadsCount;

		if ( allNewUploadsSucceeded ) { onAllUploadsSucceeded(); }
	}

	componentWillUnmount() {
		document.removeEventListener( 'click', this._clickHandler );
	}

	_clickHandler( event ) {
		const { current } = this.assetUploaderBoxRef;
		const { onClickOutside } = this.props;
		if ( hasClickedOutsideUploaderBox( current, event ) ) {
			onClickOutside( event );
		}
	}

	_cancelUpload = ( { id, isFolderUpload } ) => {
		if ( isFolderUpload ) {
			directFilesUploaderSubscriber.cancelFolderUpload( id );
		} else {
			this.props.cancelAssetUpload( id );
		}
	}

	_cancelAllUploads() {
		this.showActionConfirmation(
			this.props.cancelAllAssetUploads,
			'Are you sure you want to cancel all pending uploads?',
			'Ok',
			'Cancel'
		);
	}

	render() {
		const {
			project, folder, uploadProjectIDs, assetUploadsCount, assetActiveUploadsCount,
			totalTimeRemaining, createFileUploads, createFolderUploads, popupSystem,
			history
		} = this.props;

		return (
			<div ref={this.assetUploaderBoxRef} className="AssetUploaderBox">
				{assetUploadsCount > 0
					? (
						<AssetUploaderUploadsListBox
							uploadProjectIDs={uploadProjectIDs}
							assetActiveUploadsCount={assetActiveUploadsCount}
							totalTimeRemaining={totalTimeRemaining}
							onCancelUploadClick={this._cancelUpload}
							onCancelAllClick={this._cancelAllUploads}
						/>
					) : (
						<AssetUploaderEmptyBox
							project={project}
							folder={folder}
							createFileUploads={createFileUploads}
							createFolderUploads={createFolderUploads}
							popupSystem={popupSystem}
							history={history}
						/>
					)}
			</div>
		);
	}
}

AssetUploaderBox.propTypes = {
	project: PropTypes.instanceOf( Project ),
	folder: PropTypes.instanceOf( Folder ),
	uploadProjectIDs: PropTypes.arrayOf( PropTypes.number ),
	assetUploadsCount: PropTypes.number,
	assetActiveUploadsCount: PropTypes.number,
	createFileUploads: PropTypes.func,
	createFolderUploads: PropTypes.func,
	cancelAssetUpload: PropTypes.func,
	cancelAllAssetUploads: PropTypes.func,
	totalTimeRemaining: PropTypes.number,
	onClickOutside: PropTypes.func,
	onAllUploadsSucceeded: PropTypes.func,
	succeededUploadsCount: PropTypes.number,
	nonAbortedOrFailedUploadsCount: PropTypes.number,
	popupSystem: PropTypes.instanceOf( PopupSystem ).isRequired,
	history: HistoryPropType.isRequired
};

AssetUploaderBox.defaultProps = {
	project: null,
	folder: null,
	uploadProjectIDs: [],
	assetUploadsCount: 0,
	assetActiveUploadsCount: 0,
	createFileUploads: () => {},
	createFolderUploads: () => {},
	cancelAssetUpload: () => {},
	cancelAllAssetUploads: () => {},
	totalTimeRemaining: 0,
	onClickOutside: () => {},
	onAllUploadsSucceeded: () => { },
	succeededUploadsCount: 0,
	nonAbortedOrFailedUploadsCount: 0
};

export default AssetUploaderBox;
