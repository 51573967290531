import { createTransform } from 'redux-persist';
import { Map } from 'immutable';

const createMapReducersTransform = ( { whitelist } ) => createTransform(
	inboundState => (
		inboundState
			? inboundState.toJS()
			: {}
	),

	outboundState => (
		new Map(
			Object.keys( outboundState ).map( entityID => [
				parseInt( entityID, 10 ),
				outboundState[ entityID ]
			] )
		)
	),
	{ whitelist }
);

export default createMapReducersTransform;
