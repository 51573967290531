import PropTypes from 'prop-types';
import TimeAgo from 'react-timeago';
import defaultFormatter from 'react-timeago/lib/defaultFormatter';

const TimePassed = ( {
	date
} ) => {
	const formatter = ( value, unit, suffix ) => {
		if ( unit === 'second' ) { return 'Just now'; }
		const revisedUnit = unit === 'minute' ? 'min' : unit;
		return defaultFormatter( value, revisedUnit, suffix );
	};

	return (
		<TimeAgo
			date={date}
			formatter={formatter}
		/>
	);
};

TimePassed.propTypes = {
	date: PropTypes.instanceOf( Date )
};

TimePassed.defaultProps = {
	date: undefined
};

export default TimePassed;
