import { List } from 'immutable';
import {
	SHOW_POPUP,
	HIDE_POPUP
} from '../actions/types';

export const popup = ( state = new List(), action ) => {
	switch ( action.type ) {
	case SHOW_POPUP: {
		return state.push( action.payload );
	}
	case HIDE_POPUP:
		return state.shift();
	default:
		return state;
	}
};
