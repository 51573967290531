import { createSelector } from 'reselect';
import moment from 'moment';
import { compareDatesAsc } from '../lib/dateUtils';

import { getAssets, getSelectedAssets, getAssetIDFromProps } from './assets';
import { getUsers } from './users';
import { getShowingAssetRound } from './assetRounds';

import RootComment from '../entities/rootComment';
import CommentReply from '../entities/commentReply';

const getRawComments = state => state.comments;
const getRawCommentsReplies = state => state.commentsReplies;
const getAssetCommentLastSeenByUsers = state => state.assetCommentLastSeenByUsers;

export const getSeenByUsersByComments = createSelector(
	[ getRawComments, getAssetCommentLastSeenByUsers, getUsers ],
	( comments, lastSeenByUsers, users ) => (
		comments.reduce(
			( seenByUsers, comment ) => {
				const assetID = comment.asset_id;
				if ( !lastSeenByUsers.has( assetID ) ) { return seenByUsers; }
				const createdAtDate = moment( comment.created_at );
				const commentAuthorID = comment.user;
				const currentCommentSeenByUserIDs = lastSeenByUsers.get( assetID )
					.filter(
						( { userID, lastSeenAt } ) => {
							const lastSeenAtDate = moment( lastSeenAt );
							return userID !== commentAuthorID && createdAtDate < lastSeenAtDate;
						}
					).map( ( { userID } ) => userID );
				const currentCommentSeenByUsers = users.valueSeq().filter(
					user => currentCommentSeenByUserIDs.includes( user.id )
				).toArray();

				return { [ comment.id ]: currentCommentSeenByUsers, ...seenByUsers };
			},
			{}
		)
	)
);

const getCommentsReplies = createSelector(
	[ getRawCommentsReplies, getAssetCommentLastSeenByUsers, getUsers ],
	( comments, seenByUsers, users ) => comments?.map(
		comment => CommentReply.fromJSON(
			comment,
			users.get( comment.user ),
			seenByUsers[ comment.id ]
		)
	)
);

const getComments = createSelector(
	[ getRawComments, getCommentsReplies, getUsers, getSeenByUsersByComments ],
	( comments, commentsReplies, users, seenByUsers ) => comments.map(
		comment => RootComment.fromJSON(
			comment,
			users.get( comment.user ),
			seenByUsers[ comment.id ],
			comment.replies
				.map( id => commentsReplies.get( id ) )
				.filter( reply => !!reply )
		)
	)
);

export const getActiveCommentIDAndSource = state => ( {
	commentID: state.currentActiveMarkupComment.get( 'commentID' ),
	sourceIsComment: state.currentActiveMarkupComment.get( 'sourceIsComment' )
} );
export const getActiveCommentIsEditing = state => state.currentActiveMarkupComment.get( 'isEditing' );
export const getComment = ( state, commentID ) => getComments( state ).get( commentID );
export const getCommentOrReply = ( state, commentID ) => (
	getComment( state, commentID ) || getCommentsReplies( state ).get( commentID ) );

export const getCommentsForSelectedAsset = createSelector(
	[ getComments, getAssetIDFromProps ],
	( comments, selectedAssetID ) => comments.filter(
		comment => comment.belongsToAsset( selectedAssetID )
	).sortBy(
		comment => comment.createdAt,
		compareDatesAsc
	).toArray()
);

const filterCommentsThatBelongToRound = ( comments, assetRoundID ) => comments
	.filter(
		comment => comment.belongsToAssetRound( assetRoundID )
	);

export const getCommentsForSelectedAssetRound = createSelector(
	[
		getCommentsForSelectedAsset,
		getShowingAssetRound
	],
	( comments, selectedAssetRound ) => filterCommentsThatBelongToRound(
		comments, selectedAssetRound?.id
	)
);

export const getSelectedAssetRoundHasComments = createSelector(
	[ getCommentsForSelectedAssetRound ],
	selectedAssetRoundHasComments => selectedAssetRoundHasComments.length > 0
);

export const getActiveComment = createSelector(
	[ getComments, getActiveCommentIDAndSource ],
	( comments, activeCommentIDAndSource ) => comments.get( activeCommentIDAndSource.commentID )
);

export const getActiveCommentOrReply = createSelector(
	[ getComments, getCommentsReplies, getActiveCommentIDAndSource ],
	( comments, replies, activeCommentIDAndSource ) => (
		comments.get( activeCommentIDAndSource.commentID )
		|| replies.get( activeCommentIDAndSource.commentID ) )
);

export const getActiveCommentMarkupID = createSelector(
	[ getActiveComment ],
	activeComment => (
		activeComment && activeComment.markup
			? activeComment.markup.id
			: null
	)
);

export const getEditingComment = createSelector(
	[ getActiveCommentOrReply, getActiveCommentIsEditing ],
	( activeComment, isEditing ) => ( isEditing ? activeComment : null )
);

export const getEditingCommentID = createSelector(
	[ getEditingComment ],
	editingComment => ( editingComment ? editingComment.id : null )
);

export const getAssetForCommentID = createSelector(
	[

		( _, props ) => props.commentID,
		getRawComments,
		getRawCommentsReplies,
		getAssets
	],
	( commentID, comments, commentsReplies, assets ) => {
		const comment = comments.get( commentID ) || commentsReplies.get( commentID );
		if ( !comment ) { return undefined; }

		return assets.get( comment.asset_id );
	}
);

export const getHoveredCommentID = createSelector(
	[ state => state.hoveredCommentID ],
	commentID => commentID
);

export const getSelectedAssetsHaveComments = createSelector(
	[ getSelectedAssets ],
	selectedAssets => selectedAssets.some( asset => asset.hasComments )
);

export const getSelectedAssetsHaveCommentsOnLastRound = createSelector(
	[ getSelectedAssets, getComments ],
	( selectedAssets, comments ) => selectedAssets
		.some(
			asset => filterCommentsThatBelongToRound( comments, asset.currentRoundID ).size > 0
		)
);
