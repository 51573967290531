import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connectComponent } from '../../lib/connectComponent';
import { getSelectedNotificationsProjectID } from '../../selectors/notifications';

import NotificationsSidePanel
	from '../../components/notifications/NotificationsSidePanel/NotificationsSidePanel';
import { trackNavigatedToNotificationsPanel } from '../../services/tracker/trackers/navigation';

export const NotificationsSidePanelContainer = ( {
	selectedProjectID, setSelectedNotificationsProjectID, onNotificationClicked,
	onClose, setNotificationsPanelAsOpen, onNotificationProjectClicked, onNotificationFolderClicked
} ) => {
	useEffect( () => trackNavigatedToNotificationsPanel(), [] );

	return (
		<NotificationsSidePanel
			selectedProjectID={selectedProjectID}
			onProjectSelect={setSelectedNotificationsProjectID}
			onNotificationClicked={onNotificationClicked}
			onClose={onClose}
			setNotificationsPanelAsOpen={setNotificationsPanelAsOpen}
			onNotificationProjectClicked={onNotificationProjectClicked}
			onNotificationFolderClicked={onNotificationFolderClicked}
		/>
	);
};

NotificationsSidePanelContainer.propTypes = {
	setSelectedNotificationsProjectID: PropTypes.func,
	selectedProjectID: PropTypes.number,
	onNotificationClicked: PropTypes.func,
	onClose: PropTypes.func,
	setNotificationsPanelAsOpen: PropTypes.func,
	onNotificationProjectClicked: PropTypes.func,
	onNotificationFolderClicked: PropTypes.func
};

NotificationsSidePanelContainer.defaultProps = {
	setSelectedNotificationsProjectID: () => {},
	selectedProjectID: null,
	onNotificationClicked: undefined,
	onClose: undefined,
	setNotificationsPanelAsOpen: () => {},
	onNotificationProjectClicked: undefined,
	onNotificationFolderClicked: undefined
};

export default connectComponent( state => ( {
	selectedProjectID: getSelectedNotificationsProjectID( state )
} ) )( NotificationsSidePanelContainer );
