import PropTypes from 'prop-types';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { TEXT_GREY } from '../../styles/colors';

const BreadcrumbDividingArrow = ( { visible } ) => (
	<KeyboardArrowRight
		title="right arrow"
		style={{ fontSize: 20, color: TEXT_GREY, visibility: visible ? 'visible' : 'hidden' }}
	/>
);

BreadcrumbDividingArrow.propTypes = {
	visible: PropTypes.bool
}

BreadcrumbDividingArrow.defaultProps = {
	visible: true
}

export default BreadcrumbDividingArrow;
