import { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ASSET_IMAGE_EXTENSIONS } from '../../entities/asset';
import { mapExtensionsForFileInput, readLocalFile } from '../../lib/fileUtils';

import IconButton from '../UI/IconButton/IconButton';
import Button from '../../new_arch/components/Button/Button';

import './WorkspaceLogoPicker.scss';
import { WHITE } from '../../styles/colors';

class WorkspaceLogoPicker extends PureComponent {
	constructor( props ) {
		super( props );

		this.inputRef = createRef();

		this.state = {
			pickedImage: null
		};

		this._openImagePicker = this._openImagePicker.bind( this );
		this._onLogoChange = this._onLogoChange.bind( this );
	}

	componentDidUpdate( { initialLogo: previousInitialLogo } ) {
		const { initialLogo } = this.props;

		if ( previousInitialLogo !== initialLogo ) {
			this.clear();
		}
	}

	get currentImage() {
		const { pickedImage } = this.state;
		const { initialLogo } = this.props;
		return pickedImage || initialLogo;
	}

	clear() {
		this.setState( { pickedImage: null } );
		if ( this.inputRef && this.inputRef.current ) {
			this.inputRef.current.value = null;
		}
	}

	_openImagePicker() {
		if ( this.inputRef && this.inputRef.current ) {
			this.inputRef.current.click();
		}
	}

	_onLogoChange( fileChangeEvent ) {
		const { onFileChange } = this.props;
		const file = fileChangeEvent.target.files[ 0 ];

		if ( file ) {
			onFileChange( file );
			readLocalFile( file ).then( ( pickedImage ) => {
				this.setState( { pickedImage } );
			} );
		}
	}

	render() {
		const { currentImage, props: { disabled } } = this;

		return (
			<div className="WorkspaceLogoPicker">
				<div className={classNames( 'picker', { disabled } )}>
					{!disabled && (
						<IconButton
							onClick={this._openImagePicker}
							icon="camera_alt"
							size={30}
							color={WHITE}
						/>
					)}
					<img src={currentImage} alt="logo" />
					<input
						onChange={this._onLogoChange}
						ref={this.inputRef}
						type="file"
						accept={mapExtensionsForFileInput( ASSET_IMAGE_EXTENSIONS )}
					/>
				</div>
				{!disabled && (
					<Button
						type="text"
						onClick={this._openImagePicker}
					>
						Change Logo
					</Button>
				)}
			</div>
		);
	}
}

WorkspaceLogoPicker.propTypes = {
	initialLogo: PropTypes.string,
	onFileChange: PropTypes.func,
	disabled: PropTypes.bool
};

WorkspaceLogoPicker.defaultProps = {
	initialLogo: null,
	onFileChange: () => {},
	disabled: true
};

export default WorkspaceLogoPicker;
