import PropTypes from 'prop-types';

import Select from '../UI/Select/Select';
import SortOption from '../../entities/sortOption';
import IconButton from '../UI/IconButton/IconButton';
import SortSelectorMultiOptionType from '../../types/sortSelectorMultiOption';
import { NEUTRAL_800 } from '../../styles/colors';

import './SortSelector.scss';

const SortSelector = ( {
	options, selected, onChange, ascending
} ) => {
	const onOptionChange = newSelected => onChange( newSelected, ascending );
	const onDirectionButtonClick = () => onChange( selected, !ascending );

	return (
		<div className="SortSelector">
			<Select
				selected={selected}
				placeholder="No Sort"
				options={options}
				onChange={onOptionChange}
				showSelectedDot
				selectedOptionType="Sort:"
			/>
			<IconButton
				buttonClassName="sort-direction-button"
				color={NEUTRAL_800}
				icon={ascending ? 'arrow-upward' : 'arrow-downward'}
				onClick={onDirectionButtonClick}
				size={16}
			/>
		</div>
	);
};

SortSelector.propTypes = {
	options: PropTypes.oneOfType( [
		PropTypes.arrayOf(
			SortSelectorMultiOptionType
		),
		PropTypes.arrayOf(
			PropTypes.instanceOf( SortOption )
		)
	] ),
	selected: PropTypes.instanceOf( SortOption ),
	onChange: PropTypes.func,
	ascending: PropTypes.bool
};

SortSelector.defaultProps = {
	options: [],
	selected: undefined,
	onChange: () => {},
	ascending: true
};

export default SortSelector;
