import PropTypes from 'prop-types';

import { connectComponent } from '../../lib/connectComponent';
import { getUserCanDeleteProjectInvitation }
	from '../../selectors/permissions/usersPermissions';
import ProjectInvitationsBox from '../../components/ProjectInvitationsBox/ProjectInvitationsBox';
import ProjectInvitation from '../../entities/projectInvitation';
import Container from '../Container';

export class ProjectInvitationContainer extends Container {
	constructor( props ) {
		super( props );

		this._onDeleteClicked = this._onDeleteClicked.bind( this );
	}

	deleteProjectInvitation() {
		const { invite, deleteProjectInvitation } = this.props;

		return this.handleAsyncActionResult(
			deleteProjectInvitation( invite.id ),
			{ title: 'The pending invite has been successfully deleted.' },
			{ title: 'Oops, something went wrong! Try again.' }
		);
	}

	_onDeleteClicked() {
		const { props: { invite } } = this;

		this.showAsyncActionConfirmation(
			`Are you sure you want to uninvite ${invite.email} from the project?`,
			'Ok',
			'Cancel',
			() => this.deleteProjectInvitation()
		);
	}

	render() {
		const {
			props: { invite, canDelete }
		} = this;

		return (
			<ProjectInvitationsBox
				key={invite.id}
				invitation={invite}
				canDelete={canDelete}
				onDeleteClick={this._onDeleteClicked}
			/>
		);
	}
}

ProjectInvitationContainer.propTypes = {
	projectID: PropTypes.number.isRequired,
	invite: PropTypes.instanceOf( ProjectInvitation ),
	canDelete: PropTypes.bool,
	deleteProjectInvite: PropTypes.func
};

ProjectInvitationContainer.defaultProps = {
	invite: null,
	canDelete: true,
	deleteProjectInvite: () => {}
};

export default connectComponent( ( state, props ) => ( {
	canDelete: getUserCanDeleteProjectInvitation( state, props )
} ), true )( ProjectInvitationContainer );
