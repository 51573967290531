import { makeAutoObservable } from 'mobx';
import WorkspaceSystem from '../../systems/WorkspaceSystem';
import { type ContactGroup } from '../../types/contactGroup';
import Workspace from '../../entities/workspace';

interface ContactsGroupsModalPresenterProps {
	workspace: Workspace;
	contactGroups: ContactGroup[];
	workspaceSystem: WorkspaceSystem;
}

export default class ContactsGroupsModalPresenter {
	private workspace: Workspace;
	private workspaceSystem: WorkspaceSystem;
	private contactGroups: ContactGroup[];
	private checkedGroups: number[] = [];
	private loading = true;

	constructor( { workspace, contactGroups, workspaceSystem } : ContactsGroupsModalPresenterProps ) {
		this.workspace = workspace;
		this.contactGroups = contactGroups;
		this.workspaceSystem = workspaceSystem;

		makeAutoObservable( this );
	}

	onMount = async () => {
		await this._fetchContactGroups( this.workspaceID );
	}

	get workspaceID() : number {
		return this.workspace.id;
	}

	get getContactsGroups() : ContactGroup[] {
		return this.contactGroups;
	}

	get thereAreGroups() : boolean {
		return !!this.contactGroups.length;
	}

	get userCanManageContactsGroups() : boolean {
		return this.workspace.currentUserCanCreateProject;
	}

	get isLoading() : boolean {
		return this.loading;
	}

	setLoading = ( value: boolean ) : void => {
		this.loading = value;
	}

	toggleGroupCheck = ( { groupID } : { groupID: number } ) : void => {
		if ( this.isGroupChecked( { groupID } ) ) this._uncheckGroup( { groupID } );
		else this._checkGroup( { groupID } );
	}

	isGroupChecked = ( { groupID } : { groupID: number } ) : boolean => (
		this.checkedGroups.includes( groupID )
	);

	uncheckAllGroups = () : void => {
		this.checkedGroups = [];
	}

	getEmailsFromCheckedGroups = () : string[] => this.contactGroups
		.filter( group => this.checkedGroups.includes( group.id ) )
		.flatMap( group => group.emails );

	_checkGroup = ( { groupID } : { groupID: number } ) : void => {
		this.checkedGroups = [ ...this.checkedGroups, groupID ];
	}

	_uncheckGroup = ( { groupID } : { groupID: number } ) : void => {
		this.checkedGroups = this.checkedGroups.filter( id => id !== groupID );
	}

	_fetchContactGroups = async ( workspaceID: number ) => this.workspaceSystem.getContactsGroups( workspaceID );
}
