import type WorkspaceSystem from '../../../systems/WorkspaceSystem';
import { type ContactGroup } from '../../../types/contactGroup';

type ContactGroupEditStrategyParams = {
	workspaceID: number,
	contactGroup?: ContactGroup,
	workspaceSystem: WorkspaceSystem
}

export default class ContactGroupEditStrategy {
	private workspaceID: number;
	private contactGroup: ContactGroup;
	private workspaceSystem: WorkspaceSystem;

	constructor( { workspaceID, contactGroup, workspaceSystem }: ContactGroupEditStrategyParams ) {
		if ( !contactGroup ) throw new Error( 'ContactGroupEditStrategy requires an existing contact group' );

		this.workspaceID = workspaceID;
		this.contactGroup = contactGroup;
		this.workspaceSystem = workspaceSystem;
	}

	get title() {
		return 'Edit group';
	}

	get initialName() {
		return this.contactGroup.name;
	}

	get initialEmails() {
		return this.contactGroup.emails;
	}

	get submitButtonLabel() {
		return 'Save';
	}

	get successMessage() {
		return 'Contact group edited successfully';
	}

	makeContactGroupRequest( name: string, emails: string[] ) {
		return this.workspaceSystem.updateContactGroup( {
			workspaceID: this.workspaceID,
			contactGroupID: this.contactGroup.id,
			name,
			emails
		} );
	}
}
