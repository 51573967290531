import Coupon, { CouponJSON } from './coupon';

type WorkspaceInvoiceJSON = {
	id: string;
	amount: number;
	coupon: CouponJSON | null;
	created_at: string;
	detail: string;
	invoice_url: string | null;
	status: string;
	workspace_id: number;
}

type WorkspaceInvoiceParams = {
	id: string;
	amount: number;
	coupon: Coupon | null;
	createdAt: string;
	detail: string;
	invoiceURL: string | null;
	status: string;
	workspaceID: number;
}

export default class WorkspaceInvoice {
	id: string;
	createdAt: string;
	detail: string;
	amount: number;
	status: string;
	workspaceID: number;
	invoiceURL: string | null;
	coupon: Coupon | null;

	constructor( { id, createdAt, detail, amount, status, workspaceID, invoiceURL, coupon }: WorkspaceInvoiceParams ) {
		this.id = id;
		this.createdAt = createdAt;
		this.detail = detail;
		this.amount = amount;
		this.status = status;
		this.workspaceID = workspaceID;
		this.invoiceURL = invoiceURL;
		this.coupon = coupon;
	}

	static fromJSON( json: WorkspaceInvoiceJSON ) {
		return new WorkspaceInvoice( {
			id: json.id,
			createdAt: json.created_at,
			detail: json.detail,
			amount: json.amount,
			status: json.status,
			workspaceID: json.workspace_id,
			invoiceURL: json.invoice_url,
			coupon: json.coupon ? Coupon.fromJSON( json.coupon ) : null
		} );
	}

	toJSON() {
		return {
			id: this.id,
			created_at: this.createdAt,
			detail: this.detail,
			amount: this.amount,
			status: this.status,
			workspace_id: this.workspaceID,
			invoice_url: this.invoiceURL,
			coupon: this.coupon ? this.coupon.toJSON() : null
		};
	}
}
