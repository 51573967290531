import { observer } from 'mobx-react-lite';
import CloseableModalWithTitle from '../CloseableModalWithTitle/CloseableModalWithTitle';
import FormInput from '../../new_arch/components/FormInput/FormInput';
import Button from '../../new_arch/components/Button/Button';
import useEmailVerificationModalPresenter from '../../presenters/EmailVerificationModalPresenter/useEmailVerificationModalPresenter';
import { preventDefaultAndCall } from '../../new_arch/lib/eventUtils';
import { UserProfile } from '../../presenters/EmailVerificationModalPresenter/EmailVerificationModalPresenter';

import './EmailVerificationModal.scss';

export type EmailVerificationModalProps = {
	onClose: () => void;
	data: UserProfile;
	userID: number;
};

const EmailVerificationModal = ( {
	onClose,
	data,
	userID
}: EmailVerificationModalProps ) => {
	const presenter = useEmailVerificationModalPresenter( {
		onClose,
		data,
		userID
	} );

	return (
		<CloseableModalWithTitle
			headerBorderType="small"
			onClose={onClose}
			name="email-verification"
			className="EmailVerificationModal"
		>
			<div className="body">
				<h4 className="title">Verify Email</h4>
				<p className="description">
					Enter the code we&apos;ve sent to <br />
					<strong>{data.email}</strong> <br />
					It&apos;s only valid for 2 minutes.
				</p>
				<form onSubmit={preventDefaultAndCall( presenter.updateUserProfile )}>
					<FormInput
						value={presenter.code}
						error={presenter.error}
						onChange={presenter.onCodeChange}
						label="Code"
						name="verification-code"
						placeholder="e.g. 654343"
						autoFocus
					/>
					<div className="continue-btn">
						<Button disabled={presenter.isSubmitting}>Continue</Button>
					</div>
				</form>
				<Button
					type="text"
					className="resend-btn"
					onClick={presenter.resendEmailVerification}
				>
					Resend Code
				</Button>
			</div>
		</CloseableModalWithTitle>
	)
};

export default observer( EmailVerificationModal );
