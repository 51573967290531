import { computed, makeObservable } from 'mobx';
import AddOn from '../../entities/addOn';
import UsagePresenter from '../UsagePresenter/UsagePresenter';

export default class MembersUsagePresenter extends UsagePresenter {
	constructor( {
		popupSystem, addOnSystem, snackbarSystem,
		workspace, addOns, history, onPaidFeatureModalAcceptClick
	} ) {
		super( {
			popupSystem,
			addOnSystem,
			snackbarSystem,
			workspace,
			addOns,
			history,
			onPaidFeatureModalAcceptClick
		} );

		makeObservable( this, {
			membersCount: computed,
			maxMembersCount: computed,
			hasMembersAddOns: computed,
			membersAddOnTooltipText: computed,
			addMembersButtonText: computed,
			membersUnitText: computed,
			selectedAddOnType: computed,
			_extraMembers: computed
		} );
	}

	get membersCount() {
		return this.workspace.membersCount;
	}

	get maxMembersCount() {
		return this.workspace.subscriptionMaxMembersCount;
	}

	get hasMembersAddOns() {
		return this.workspace.hasMembersAddOns;
	}

	get membersAddOnTooltipText() {
		return this.hasMembersAddOns
			? `You have ${this._extraMembers} extra Seat as add-ons`
			: undefined;
	}

	get addMembersButtonText() {
		return this.hasMembersAddOns ? 'Manage Seats' : 'Add Seat';
	}

	get membersUnitText() {
		return `available seat${this.maxMembersCount === 1 ? '' : 's'}`;
	}

	get selectedAddOnType() {
		return AddOn.membersType;
	}

	get _extraMembers() {
		return this.workspace.extraMembers;
	}
}
