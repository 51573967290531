import PropTypes from 'prop-types';

import { connectComponent } from '../../lib/connectComponent';
import Button from '../../new_arch/components/Button/Button';
import Container from '../Container';

import './DeleteWorkspaceContainer.scss';

export class DeleteWorkspaceContainer extends Container {
	constructor( props ) {
		super( props );

		this._onDeleteClicked = this._onDeleteClicked.bind( this );
	}

	deleteWorkspace() {
		const { workspaceID, deleteWorkspace } = this.props;

		return this.handleAsyncActionResult(
			deleteWorkspace( workspaceID ),
			{ title: 'The workspace has been successfully deleted.' },
			{ title: 'There was an error deleting the workspace. Please try again later.' }
		);
	}

	_onDeleteClicked() {
		const { showDangerConfirmationDialog, hideConfirmationDialog } = this.props;
		const description = 'By deleting the workspace you will lose all projects and assets within it '
		+ 'with no chance of recovery.\nAre you sure you want to delete the workspace?';

		showDangerConfirmationDialog(
			'Delete Workspace forever',
			description,
			'I understand that I will lose all my projects and won\'t be able to recover them',
			'Delete Workspace',
			'Cancel',
			'center'
		)
			.then( ( confirm ) => {
				if ( confirm ) this.deleteWorkspace();
				hideConfirmationDialog();
			} );
	}

	render() {
		return (
			<div className="DeleteWorkspaceContainer">
				<h4>Delete Workspace</h4>
				<p className="box-content">
					Deleting the workspace will delete all projects and content.
					You won’t be able to recover it later.
				</p>
				<Button
					className="delete-button"
					color="secondary"
					onClick={this._onDeleteClicked}
				>
					Delete Workspace
				</Button>
			</div>
		);
	}
}

DeleteWorkspaceContainer.propTypes = {
	workspaceID: PropTypes.number.isRequired,
	deleteWorkspace: PropTypes.func
};

DeleteWorkspaceContainer.defaultProps = {
	deleteWorkspace: () => {}
};

export default connectComponent( () => ( {} ) )( DeleteWorkspaceContainer );
