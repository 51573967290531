const ONE_MINUTE_IN_MILISECONDS = 60 * 1000;
const ONE_HOUR_IN_MILISECONDS = ONE_MINUTE_IN_MILISECONDS * 60;

export const areSameDate = ( aDate, otherDate ) => (
	aDate.toDateString() === otherDate.toDateString()
);

export const now = () => new Date();

export const yesterday = () => {
	const today = now();
	today.setDate( today.getDate() - 1 );
	return today;
};

export const isToday = date => areSameDate( date, now() );
export const isYesterday = date => areSameDate( date, yesterday() );

export const compareDatesDesc = ( aDate, otherDate ) => {
	if ( aDate > otherDate ) { return -1; }
	if ( aDate < otherDate ) { return 1; }
	return 0;
};

export const compareDatesAsc = ( aDate, otherDate ) => {
	if ( aDate > otherDate ) { return 1; }
	if ( aDate < otherDate ) { return -1; }
	return 0;
};

const isNotValidTime = timeInMilliseconds => Number.isNaN( timeInMilliseconds );
const isLessThanOneMinute = timeInMilliseconds => timeInMilliseconds < ONE_MINUTE_IN_MILISECONDS;
const isMoreThanOneHour = timeInMilliseconds => timeInMilliseconds >= ONE_HOUR_IN_MILISECONDS;

export const displayTimeLeft = ( timeInMilliseconds ) => {
	const oneMinute = 60 * 1000;
	const oneHour = 60 * oneMinute;
	if ( isNotValidTime( timeInMilliseconds ) ) { return 'Calculating time left'; }
	if ( isLessThanOneMinute( timeInMilliseconds ) ) { return 'Less than a minute'; }
	if ( isMoreThanOneHour( timeInMilliseconds ) ) {
		const hoursLeft = Math.floor( timeInMilliseconds / oneHour );
		return `${hoursLeft} ${hoursLeft === 1 ? 'hour' : 'hours'} left`;
	}
	const minutesLeft = Math.floor( timeInMilliseconds / oneMinute );
	return `${minutesLeft} ${minutesLeft === 1 ? 'minute' : 'minutes'} left`;
};
