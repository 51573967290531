import MobileWarning from './MobileWarning';

import './MobileWarning.scss';

const MobileWarningContent = () => (
	<>
		VeryBusy is currently
		<br />
		available on desktop.
		<br />
		Mobile is coming soon!
	</>
);

const MobileWarningLogin = () => (
	<MobileWarning Content={MobileWarningContent} />
);

export default MobileWarningLogin;
