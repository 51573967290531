import { ReactComponent as Logo } from '../../../assets/img/logo.svg';

const VeryBusyPreviewLogo = () => (
	<div className="VeryBusyLogo">
		<Logo className="logo" />
		<p className="prop-value" data-testid="pepe">
			Streamline your post-production workflow <br />
			for large fast-paced projects.
		</p>
	</div>
);

export default VeryBusyPreviewLogo;
