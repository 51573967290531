import moment from 'moment';
import AddOn from './addOn';
import AddOnItem from './addOnItem';
import SubscriptionTypeFactory from '../factories/SubscriptionTypeFactory';

class Subscription {
	constructor( {
		id,
		active,
		workspaceID,
		cancelAtPeriodEnd,
		billingPeriodEndsAt,
		lastPaymentSucceeded,
		pricingPlanPrice,
		addOnItems = [],
		downgradeAtPeriodEnd,
		freeTrialExpiresAt,
		priceWithDiscount
	} ) {
		this.id = id;
		this.active = active;
		this.workspaceID = workspaceID;
		this.cancelAtPeriodEnd = cancelAtPeriodEnd;
		this.billingPeriodEndsAt = billingPeriodEndsAt;
		this.lastPaymentSucceeded = lastPaymentSucceeded;
		this.pricingPlanPrice = pricingPlanPrice;
		this.addOnItems = addOnItems;
		this.downgradeAtPeriodEnd = downgradeAtPeriodEnd;
		this._subscriptionType = SubscriptionTypeFactory.for( this );
		this.freeTrialExpiresAt = freeTrialExpiresAt;
		this.priceWithDiscount = priceWithDiscount;
	}

	static fromJSON( subscriptionProperties, pricingPlanPrice ) {
		if ( !subscriptionProperties ) {
			return undefined;
		}

		return new Subscription( {
			id: subscriptionProperties.id,
			active: subscriptionProperties.active,
			workspaceID: subscriptionProperties.workspace_id,
			cancelAtPeriodEnd: subscriptionProperties.cancel_at_period_end,
			billingPeriodEndsAt: subscriptionProperties.billing_period_ends_at,
			lastPaymentSucceeded: subscriptionProperties.last_payment_succeeded,
			pricingPlanPrice,
			addOnItems: subscriptionProperties.add_on_items?.map( json => AddOnItem.fromJSON( json ) ),
			downgradeAtPeriodEnd: subscriptionProperties.downgrade_at_period_end,
			freeTrialExpiresAt: subscriptionProperties.free_trial_expires_at,
			priceWithDiscount: subscriptionProperties.price_with_discount
		} );
	}

	get canBeCancelled() {
		return this.active && !this.cancelAtPeriodEnd;
	}

	get isSuspendedForDowngrade() {
		return !this.active && this.downgradeAtPeriodEnd;
	}

	get planName() {
		return this.pricingPlanPrice && this.pricingPlanPrice.planName;
	}

	get isOnFreeTrial() {
		return this._subscriptionType.isFree;
	}

	get isAPaidSubscription() {
		return !this._subscriptionType.isFree;
	}

	get planCost() {
		return this.pricingPlanPrice && this.pricingPlanPrice.cost;
	}

	get billingPeriod() {
		return this.pricingPlanPrice && this.pricingPlanPrice.billingPeriod;
	}

	get billingPeriodIsMonthly() {
		return this.pricingPlanPrice && !this.pricingPlanPrice.isAnnualPrice;
	}

	prorateCostToEndOfBillingPeriod( cost ) {
		const diffInDays = this._billingPeriodEndDate.diff( moment(), 'days', true );
		return Math.round( diffInDays * ( cost / 365 ) );
	}

	get daysUntilDowngrade() {
		return this._billingPeriodEndDate.diff( moment(), 'days' );
	}

	get daysLeftOnFreeTrial() {
		return this._freeTrialEndDate.diff( moment(), 'days' );
	}

	get timeLeftUntilBillingPeriodEnd() {
		const timeLeft = moment.duration( this._billingPeriodEndDate.diff( moment() ) );
		return ( timeLeft.months() === 0 ) ? `${timeLeft.days()} days` : `${timeLeft.months()} months and ${timeLeft.days()} days`;
	}

	get showPendingDowngradeBanner() {
		return moment().isAfter( this._billingPeriodEndDate.subtract( 16, 'days' ) ) && this.downgradeAtPeriodEnd;
	}

	get includedProjects() {
		return this.pricingPlanPrice && this.pricingPlanPrice.includedProjects;
	}

	get includedMembers() {
		return ( this.pricingPlanPrice && this.pricingPlanPrice.includedMembers ) + this.extraMembers;
	}

	get includedStorage() {
		return ( this.pricingPlanPrice && this.pricingPlanPrice.includedStorage ) + this.extraStorage;
	}

	get extraStorage() {
		return this._storageAddOnItem.extraAmount;
	}

	get extraMembers() {
		return this._membersAddOnItem.extraAmount;
	}

	get membersAddOnsCost() {
		return this._membersAddOnItem.cost;
	}

	get storageAddOnsCost() {
		return this._storageAddOnItem.cost;
	}

	get storageAddOnQuantity() {
		return this._storageAddOnItem?.quantity;
	}

	get storageAddOnIncludedAmount() {
		return this._storageAddOnItem?.includedAmount;
	}

	get membersAddOnQuantity() {
		return this._membersAddOnItem?.quantity;
	}

	get membersAddOnIncludedAmount() {
		return this._membersAddOnItem?.includedAmount;
	}

	get allowsMemberInvitations() {
		return this._subscriptionType.allowsMemberInvitations;
	}

	get pricingPlan() {
		return this.pricingPlanPrice.pricingPlan;
	}

	get priceWithDiscountForDisplay() {
		return this.priceWithDiscount && this.priceWithDiscount.toFixed( 2 );
	}

	addAddOnsWith( addOnsSystem ) {
		this._subscriptionType.addAddOnsWith( addOnsSystem );
	}

	toJSON() {
		return {
			id: this.id,
			active: this.active,
			workspace_id: this.workspaceID,
			cancel_at_period_end: this.cancelAtPeriodEnd,
			billing_period_ends_at: this.billingPeriodEndsAt,
			last_payment_succeeded: this.lastPaymentSucceeded,
			pricing_plan_price: this.pricingPlanPrice,
			add_on_items: this.addOnItems?.map( addOnItem => addOnItem.toJSON() ),
			downgrade_at_period_end: this.downgradeAtPeriodEnd,
			free_trial_expires_at: this.freeTrialExpiresAt,
			price_with_discount: this.priceWithDiscount
		};
	}

	get _storageAddOnItem() {
		return this._findAddOnItemByType( AddOn.storageType );
	}

	get _membersAddOnItem() {
		return this._findAddOnItemByType( AddOn.membersType );
	}

	get _billingPeriodEndDate() {
		return moment( this.billingPeriodEndsAt );
	}

	get _freeTrialEndDate() {
		return moment( this.freeTrialExpiresAt );
	}

	_findAddOnItemByType( type ) {
		return this.addOnItems.find( addOnItem => addOnItem.type === type ) || AddOnItem.null;
	}
}

export default Subscription;
