import PropTypes from 'prop-types';
import Container from '../containers/Container';
import HistoryPropType, { LocationPropType } from '../types/history';

class BaseModal extends Container {
	constructor( props ) {
		super( props );

		this._history = props.history;
		this._location = props.location;
		this._name = props.name;

		this._addModalNameToQueryParams = this._addModalNameToQueryParams.bind( this );
		this._removeModalNameFromQueryParams = this._removeModalNameFromQueryParams.bind( this );
		this._canUpdateQueryParams = this._canUpdateQueryParams.bind( this );
		this._currentQueryParams = this._currentQueryParams.bind( this );
		this._replaceHistoryWith = this._replaceHistoryWith.bind( this );
	}

	componentDidMount() {
		this._addModalNameToQueryParams();
	}

	componentWillUnmount() {
		this._removeModalNameFromQueryParams();
	}

	_addModalNameToQueryParams() {
		if ( this._canUpdateQueryParams() ) {
			const queryParams = this._currentQueryParams();
			queryParams.append( 'modal', this._name );
			this._replaceHistoryWith( queryParams.toString() );
		}
	}

	_removeModalNameFromQueryParams() {
		if ( this._canUpdateQueryParams() ) {
			const queryParams = this._currentQueryParams();
			queryParams.delete( 'modal', this._name );
			this._replaceHistoryWith( queryParams.toString() );
		}
	}

	_canUpdateQueryParams() {
		return this._name && this._history && this._location;
	}

	_currentQueryParams() {
		return new URLSearchParams( this._location.search );
	}

	_replaceHistoryWith( newQueryParams ) {
		this._history.replace( {
			pathname: this._location.pathname,
			state: this._location.state,
			search: newQueryParams
		} );
	}
}

BaseModal.propTypes = {
	name: PropTypes.string,
	history: HistoryPropType,
	location: LocationPropType
};

BaseModal.defaultProps = {
	name: '',
	history: null,
	location: null
};

export default BaseModal;
