const MAX_UNCOLLAPSED_BREADCRUMBS = 5;
const ALWAYS_UNCOLLAPSED_BREADCRUMBS = 3;

const useCollapsedBreadcrumbs = ( breadcrumbs ) => {
	const [ firstBreadcrumb, ...restBreadcrumbs ] = breadcrumbs;

	const collapsesBreadcrumb = breadcrumbs.length > MAX_UNCOLLAPSED_BREADCRUMBS;

	if ( !collapsesBreadcrumb ) {
		return {
			firstBreadcrumb,
			collapsedBreadcrumbs: [],
			uncollapsedBreadcrumbs: restBreadcrumbs
		};
	}

	const breadcrumbsToCollapse = restBreadcrumbs.length - ALWAYS_UNCOLLAPSED_BREADCRUMBS;

	return {
		firstBreadcrumb,
		collapsedBreadcrumbs: restBreadcrumbs.slice( 0, breadcrumbsToCollapse ),
		uncollapsedBreadcrumbs: restBreadcrumbs.slice( breadcrumbsToCollapse )
	};
};

export default useCollapsedBreadcrumbs;
