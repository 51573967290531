import { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { firstBy } from 'thenby';

import { createProjectForm, createProjectWithoutWorkspaceForm } from '../../../forms/createProject';
import { onFieldBlur, onFieldChange, getValidationErrorMessage } from '../../../lib/forms/formUtils';

import './CreateProjectForm.scss';

import InformationTooltipIcon from '../../UI/InformationTooltipIcon/InformationTooltipIcon';
import FormInput from '../../../new_arch/components/FormInput/FormInput';
import DatePicker from '../../UI/DatePicker/DatePicker';
import Select from '../../UI/Select/Select';
import Workspace from '../../../entities/workspace';
import LoaderButton from '../../UI/LoaderButton/LoaderButton';
import Checkbox from '../../UI/Checkbox/Checkbox';
import { PRIMARY_BASE } from '../../../styles/colors';

const roundOptions = [
	{ label: 'Unretouched Images (Start at R0)', value: 'false' },
	{ label: 'Retouched Images (Start at R1)', value: 'true' }
];

export default class CreateProjectForm extends Component {
	constructor( props ) {
		super( props );

		const { workspaces } = this.props;
		const form = this.props.skipWorkspaceSelection
			? createProjectWithoutWorkspaceForm
			: createProjectForm;

		this.state = {
			form: form( {
				name: '',
				startDate: new Date(),
				assetsStartRetouched: false,
				workspaceID: this.defaultSelectedWorkspaceID,
				clientName: ''
			}, workspaces ),
			editedFields: {},
			inviteAllMembers: false
		};

		this._startDateRef = createRef();
		this._dueDateRef = createRef();

		this._onNameInputBlur = onFieldBlur.bind( this, 'name' );
		this._onClientNameInputBlur = onFieldBlur.bind( this, 'clientName' );
		this._getNameErrorMessage = getValidationErrorMessage.bind( this, 'name' );
		this._getClientNameErrorMessage = getValidationErrorMessage.bind( this, 'clientName' );
		this._getWorkspaceError = getValidationErrorMessage.bind( this, 'workspaceID' );

		this._onNameInputChange = onFieldChange.bind( this, 'name' );
		this._onClientNameInputChange = onFieldChange.bind( this, 'clientName' );
		this._onStartAtChange = onFieldChange.bind( this, 'startDate' );
		this._onDueDateChange = onFieldChange.bind( this, 'dueDate' );

		this._onAssetsStartRetouchedSelectChange = this
			._onAssetsStartRetouchedSelectChange.bind( this );
		this._onWorkspaceSelectChange = this._onWorkspaceSelectChange.bind( this );
		this._onFormSubmitted = this._onFormSubmitted.bind( this );
	}

	get defaultSelectedWorkspaceID() {
		if ( this.props.initialWorkspaceID ) {
			return this._workspaceOptions
				.find( option => option.value === this.props.initialWorkspaceID )
				?.value;
		}
		return this._workspaceOptions?.length === 1
			? this._workspaceOptions.first.value
			: null;
	}

	get isValid() {
		const { form } = this.state;
		return form.validate();
	}

	get _selectedWorkspaceOption() {
		const { _workspaceOptions, state: { form } } = this;
		const { workspaceID } = form.data;
		return _workspaceOptions.find( option => option.value === workspaceID );
	}

	get inviteAllWorkspaceMembersText() {
		return 'Invite all workspace members to this project';
	}

	get _workspaceOptions() {
		const { workspaces } = this.props;
		const workspaceOptions = workspaces.map(
			workspace => ( { label: workspace.name, value: workspace.id, greyOut: !workspace.isActive } )
		).sort(
			firstBy( ( { greyOut } ) => greyOut )
				.thenBy( 'label', { ignoreCase: true } )
		);

		return workspaceOptions;
	}

	get _showWorkspaceSelectErrorMessage() {
		const { isValid, state: { form: { errors } } } = this;
		return !!( !isValid && errors.workspaceID );
	}

	_inviteAllMemberWasChanged = () => {
		this.setState( prevState => ( {
			inviteAllMembers: !prevState.inviteAllMembers
		} ) );
	}

	_onFormSubmitted( event ) {
		event.preventDefault();

		const {
			isValid,
			props: { onSubmit },
			state: { form: { data }, inviteAllMembers }
		} = this;

		if ( !onSubmit || !isValid ) { return; }

		data.name = data.name.trim();
		data.clientName = data.clientName.trim();

		onSubmit( { ...data, inviteAllMembers } );
	}

	_onAssetsStartRetouchedSelectChange( item ) {
		onFieldChange.call( this, 'assetsStartRetouched', item.value === 'true' );
	}

	_onWorkspaceSelectChange( selectedOption ) {
		this.setState(
			prevState => ( {
				form: prevState.form.set( 'workspaceID', selectedOption.value ),
				editedFields: {
					...prevState.editedFields,
					'workspaceID': true
				}
			} )
		);
	}

	startRetouchedSelectTooltip = () => (
		<InformationTooltipIcon
			html={'Select unretouched or retouched images'
			+ ' to determine what review round the project will start on'}
		/>
	);

	workspaceSelectTooltip = () => (
		<InformationTooltipIcon
			html={'All projects must belong to a billed workspace.'
			+ 'Please select the workspace that will own the project.'}
		/>
	);

	render() {
		const {
			isValid, _workspaceOptions, _showWorkspaceSelectErrorMessage,
			state: { form: { data } },
			props: { isCreatingProject, skipWorkspaceSelection }
		} = this;

		return (
			<form
				className={classNames( 'CreateProjectForm', { showWorkspaces: !skipWorkspaceSelection } )}
				onSubmit={this._onFormSubmitted}
			>
				<div className="top-block">
					<FormInput
						name="name"
						label="Project Name"
						value={data.name}
						error={this._getNameErrorMessage()}
						onChange={this._onNameInputChange}
						onBlur={this._onNameInputBlur}
						autoComplete="su-name"
						required
						placeholder="Enter your project name..."
						autoFocus
					/>
					<div className="dates">
						<DatePicker
							key="startDate"
							displayText="Start Date"
							name="startDate"
							disabledDays={{
								before: new Date(),
								after: data.dueDate ? new Date( data.dueDate ) : null
							}}
							refHandler={this._startDateRef}
							value={data.startDate ? new Date( data.startDate ) : null}
							dayChangeHandler={this._onStartAtChange}
						/>
						<DatePicker
							key="dueDate"
							displayText="End Date"
							name="endDate"
							disabledDays={{
								before: new Date( data.startDate )
							}}
							refHandler={this._dueDateRef}
							value={data.dueDate ? new Date( data.dueDate ) : null}
							dayChangeHandler={this._onDueDateChange}
							placeholder="Select end date"
						/>
					</div>
					<FormInput
						name="clientName"
						label="Client Name"
						value={data.clientName}
						error={this._getClientNameErrorMessage()}
						onChange={this._onClientNameInputChange}
						onBlur={this._onClientNameInputBlur}
						placeholder="Enter your client name"
					/>
					<Select
						name="assetsStartRetouched"
						label="Where are you starting?"
						selected={data.assetsStartRetouched ? roundOptions[ 1 ] : roundOptions[ 0 ]}
						options={roundOptions}
						onChange={this._onAssetsStartRetouchedSelectChange}
						type="tall"
						informationTooltip={this.startRetouchedSelectTooltip()}
					/>
					{ skipWorkspaceSelection && (
						<div className="invite-all-members">
							<Checkbox
								checkedColor={PRIMARY_BASE}
								label={this.inviteAllWorkspaceMembersText}
								onChange={this._inviteAllMemberWasChanged}
								value={this.state.inviteAllMembers}
							/>
						</div>
					)}
				</div>
				<div className="bottom-block">
					{!skipWorkspaceSelection
						&& (
							<>
								<Select
									name="workspace"
									label="This project will be created in"
									selected={this._selectedWorkspaceOption}
									options={_workspaceOptions}
									error={this._getWorkspaceError()}
									onChange={this._onWorkspaceSelectChange}
									type="tall"
									informationTooltip={this.workspaceSelectTooltip()}
									required
									placeholder="Select an Option"
									invalidSelection={_showWorkspaceSelectErrorMessage}
									willShowError
								/>

								<div className="invite-all-members">
									<Checkbox
										checkedColor={PRIMARY_BASE}
										label={this.inviteAllWorkspaceMembersText}
										onChange={this._inviteAllMemberWasChanged}
										value={this.state.inviteAllMembers}
									/>
								</div>
							</>
						)}
					<LoaderButton disabled={!isValid} loading={isCreatingProject}>
						Create Project
					</LoaderButton>
				</div>
			</form>
		);
	}
}

CreateProjectForm.propTypes = {
	onSubmit: PropTypes.func,
	workspaces: PropTypes.arrayOf( Workspace ),
	isCreatingProject: PropTypes.bool,
	skipWorkspaceSelection: PropTypes.bool,
	initialWorkspaceID: PropTypes.number
};

CreateProjectForm.defaultProps = {
	onSubmit: undefined,
	workspaces: [],
	isCreatingProject: false,
	skipWorkspaceSelection: true,
	initialWorkspaceID: undefined
};
