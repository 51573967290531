import PropTypes from 'prop-types';
import Icon from '../../../new_arch/components/Icon/Icon';
import AssetRound from '../../../entities/assetRound';

import './AssetRoundSelectorButtonNode.scss';

export const AssetRoundSelectorButtonNode = ( {
	round, isOpen, showArrow
} ) => (
	<div>
		{`Round ${round.number}`}
		{showArrow && (
			<Icon
				className="arrow-icon"
				key={isOpen ? 'arrow-up' : 'arrow-down'}
				color="white"
				icon={isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
				size={16}
			/>
		)}
	</div>
);

AssetRoundSelectorButtonNode.propTypes = {
	round: PropTypes.instanceOf( AssetRound ).isRequired,
	isOpen: PropTypes.bool,
	showArrow: PropTypes.bool
};

AssetRoundSelectorButtonNode.defaultProps = {
	isOpen: false,
	showArrow: true
};
