import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CardNumberElement, useElements } from '@stripe/react-stripe-js';

import CloseableModal from '../CloseableModal';
import IconButton from '../../components/UI/IconButton/IconButton';
import BillingInformationFillingForm from '../../components/BillingInformationFillingForm/BillingInformationFillingForm';
import PaymentMethodFillingForm from '../../components/PaymentMethodFillingForm/PaymentMethodFillingForm';

import useAllValuesTruthState from '../../hooks/useAllValuesTruthState';

import './WorkspaceSubscriptionCheckoutModal.scss';
import { WHITE } from '../../styles/colors';
import WorkspaceBillingInformation from '../../entities/workspaceBillingInformation';
import CreditCard from '../../entities/creditCard';
import PricingPlanPrice from '../../entities/pricing/pricingPlanPrice';
import RadioButton from '../../components/UI/RadioButton/RadioButton';
import CreditCardInformation from '../../components/subscription/CreditCardInformation/CreditCardInformation';
import { TERMS_AND_CONDITIONS_URL, PRIVACY_POLICY_URL } from '../../config/urls';
import VeryBusyLogo from '../../components/VeryBusyLogo/VeryBusyLogo';
import PromoCodeInput from '../../components/PromoCodeInput/PromoCodeInput';
import PayButton from './PayButton';
import Workspace from '../../entities/workspace';

const BILLING_INFORMATION_FORM = 'BILLING_INFORMATION_FORM';
const PAYMENT_METHOD_FORM = 'PAYMENT_METHOD_FORM';

const DEFAULT_FORMS_COMPLETION_STATE = {
	[ BILLING_INFORMATION_FORM ]: false,
	[ PAYMENT_METHOD_FORM ]: false
};

const WorkspaceSubscriptionCheckoutModal = ( {
	initialBillingInformation: {
		country: initialCountryCode,
		state: initialStateCode,
		postalCode: initialPostalCode,
		address: initialAddress,
		city: initialCity,
		companyName: initialCompanyName
	} = {},
	initialCreditCard,
	onClose,
	onCheckout,
	onSetPromoCodeID,
	sending,
	pricingPlanPrice,
	workspace
} ) => {
	const [ bothFormsCompleted, setFormCompletionState ] = useAllValuesTruthState(
		DEFAULT_FORMS_COMPLETION_STATE
	);

	const makeOnFormCompletionStateChangedHandler = form => ( valid ) => {
		setFormCompletionState( form, valid );
	};

	const hasSavedPaymentMethod = !!initialCreditCard;
	const [ usingSavedPaymentMethod, setUsingSavedPaymentMethod ] = useState( hasSavedPaymentMethod );

	const [ country, setCountry ] = useState( undefined );
	const [ state, setState ] = useState( undefined );
	const [ postalCode, setPostalCode ] = useState( initialPostalCode );
	const [ address, setAddress ] = useState( initialAddress );
	const [ city, setCity ] = useState( initialCity );
	const [ companyName, setCompanyName ] = useState( initialCompanyName );

	const [ cardholderName, setCardholderName ] = useState( '' );

	const [ coupon, setCoupon ] = useState( null );

	const onPromoCodeApplied = ( promoCodeID, appliedCoupon ) => {
		onSetPromoCodeID( promoCodeID );
		setCoupon( appliedCoupon );
	};

	useEffect(
		() => {
			if ( usingSavedPaymentMethod ) {
				setFormCompletionState( PAYMENT_METHOD_FORM, true );
			}
		},
		[ usingSavedPaymentMethod ]
	);

	useEffect(
		() => {
			setPostalCode( initialPostalCode );
		},
		[ country ]
	);

	const elements = useElements();

	const onPayButtonPressed = () => {
		const billingInformation = {
			country,
			state,
			postalCode,
			address,
			city,
			companyName
		};

		const cardElement = elements.getElement( CardNumberElement );

		const newPaymentMethodParams = usingSavedPaymentMethod
			? undefined
			: {
				cardElement,
				cardholderName
			};

		onCheckout( billingInformation, newPaymentMethodParams );
	};

	const header = pricingPlanPrice?.cost && `$${pricingPlanPrice.cost.toFixed( 2 )}`;

	const description = `${pricingPlanPrice?.planName} - paid ${pricingPlanPrice?.isMonthly ? 'monthly' : 'annually'}`.capitalize();

	const headerAndDescription = ( !pricingPlanPrice?.cost || !pricingPlanPrice?.planName )
		? <h1 className={classNames( 'description' )}>Loading pricing details...</h1>
		: (
			<>
				<h1>{header}</h1>
				<p className={classNames( 'description' )}>{description}</p>
			</>
		);

	return (
		<CloseableModal
			canClose={!sending}
			onClose={onClose}
			name="workspace-subscription-checkout"
		>
			<div className="WorkspaceSubscriptionCheckoutModal">
				<div className="header">
					<IconButton
						onClick={onClose}
						size={24}
						icon="close"
						color={WHITE}
						disabled={sending}
					/>
					<VeryBusyLogo />
					{headerAndDescription}
				</div>
				<div className="body">
					<div className="billing-information-container">
						<h4 className="section-title">Billing Information</h4>
						<BillingInformationFillingForm
							initialCountryCode={initialCountryCode}
							country={country}
							onCountryChange={setCountry}
							initialStateCode={initialStateCode}
							state={state}
							onStateChange={setState}
							postalCode={postalCode}
							onPostalCodeChange={setPostalCode}
							address={address}
							onAddressChange={setAddress}
							city={city}
							onCityChange={setCity}
							companyName={companyName}
							onCompanyNameChange={setCompanyName}
							onFieldsCompletionStateChange={
								makeOnFormCompletionStateChangedHandler( BILLING_INFORMATION_FORM )
							}
							disabled={sending}
						/>
					</div>
					<div className="divider" />
					<div className={classNames( 'payment-method-container', { hasSavedPaymentMethod } )}>
						<h4 className="section-title">Payment Method</h4>
						<div className={classNames( 'payment-method', { hasSavedPaymentMethod, usingSavedPaymentMethod } )}>
							{
								hasSavedPaymentMethod && (
									<div>
										<RadioButton
											size={22}
											selected={usingSavedPaymentMethod}
											onClick={() => setUsingSavedPaymentMethod( true )}
											disabled={sending}
										/>
										<button className="payment-method-button" onClick={() => setUsingSavedPaymentMethod( true )}>
											<CreditCardInformation card={initialCreditCard} />
										</button>
									</div>
								)
							}
							<div>
								{
									hasSavedPaymentMethod && (
										<RadioButton
											selected={!usingSavedPaymentMethod}
											onClick={() => setUsingSavedPaymentMethod( false )}
											size={22}
											disabled={sending}
										/>
									)
								}
								{
									hasSavedPaymentMethod && usingSavedPaymentMethod
										? (
											<div className={classNames( 'pay-with-new-card-label-container', { disabled: sending } )}>
												<button
													onClick={() => setUsingSavedPaymentMethod( false )}
													disabled={sending}
												>
													Pay with a different Card
												</button>
											</div>
										)
										: (
											<PaymentMethodFillingForm
												cardholderName={cardholderName}
												onCardholderNameChange={setCardholderName}
												onFieldsCompletionStateChange={
													makeOnFormCompletionStateChangedHandler( PAYMENT_METHOD_FORM )
												}
												disabled={sending}
											/>
										)
								}
							</div>
							{
								( workspace && workspace.isOnFreeTrial ) && (
									<PromoCodeInput
										onPromoCodeApplied={onPromoCodeApplied}
										pricingPlanPrice={pricingPlanPrice}
									/>
								)
							}
						</div>
						<PayButton
							coupon={coupon}
							disabled={!bothFormsCompleted}
							onClick={onPayButtonPressed}
							pricingPlanPrice={pricingPlanPrice}
							sending={sending}
						/>
						<p className="terms-and-conditions">
							By making this purchase, you agree to {'VeryBusy\'s '}<br />
							{' '}
							<a
								href={TERMS_AND_CONDITIONS_URL}
								target="_blank"
								rel="noopener noreferrer"
							>
								Terms & Conditions
							</a>
							{' '}
							and
							{' '}
							<a
								href={PRIVACY_POLICY_URL}
								target="_blank"
								rel="noopener noreferrer"
							>
								Privacy Policy
							</a>
						</p>
					</div>
				</div>
			</div>
		</CloseableModal>
	);
};

WorkspaceSubscriptionCheckoutModal.propTypes = {
	initialBillingInformation: PropTypes.instanceOf( WorkspaceBillingInformation ),
	initialCreditCard: PropTypes.instanceOf( CreditCard ),
	onClose: PropTypes.func,
	onCheckout: PropTypes.func,
	onSetPromoCodeID: PropTypes.func,
	sending: PropTypes.bool,
	pricingPlanPrice: PropTypes.instanceOf( PricingPlanPrice ).isRequired,
	workspace: PropTypes.instanceOf( Workspace ).isRequired
};

WorkspaceSubscriptionCheckoutModal.defaultProps = {
	initialBillingInformation: undefined,
	initialCreditCard: undefined,
	onClose: () => {},
	onCheckout: () => {},
	onSetPromoCodeID: () => {},
	sending: false
};

export default WorkspaceSubscriptionCheckoutModal;
