import PropTypes from 'prop-types';

import MemberPropType from '../../types/member';

import './WorkspaceMemberWithRole.scss';
import UserAvatar from '../UserAvatar/UserAvatar';
import { AVAILABLE_ROLES_NAMES_BY_KEY } from '../../entities/workspaceRole';
import Icon from '../../new_arch/components/Icon/Icon';

const WorkspaceMemberWithRole = ( {
	member, showDeleteIcon, onDeleteClicked
} ) => {
	const projectsCount = member.ownedProjectsCount;
	const isOwner = ( member.roleID === 'WorkspaceOwner' );

	return (
		<div className="WorkspaceMemberWithRole">
			<div className="profile-pic">
				<div className="img-container">
					<UserAvatar user={member.user} />
				</div>
			</div>
			<div className="member-info">
				<h5>
					{member.user.name}
					{isOwner
						&& <span className="workspace-owner"> (Me)</span>}
				</h5>
				<p>{member.user.email}</p>
			</div>
			<p className="projects-count">{projectsCount} Owned</p>
			<p className="workspace-role">
				{AVAILABLE_ROLES_NAMES_BY_KEY[ member.roleID ]}
			</p>
			<div className="remove-container">
				{showDeleteIcon && (
					<button className="remove" onClick={onDeleteClicked}>
						<Icon icon="delete_outlined" size={20} />
					</button>
				)}
			</div>
		</div>
	);
};

WorkspaceMemberWithRole.propTypes = {
	member: MemberPropType.isRequired,
	onDeleteClicked: PropTypes.func,
	showDeleteIcon: PropTypes.bool
};

WorkspaceMemberWithRole.defaultProps = {
	onDeleteClicked: () => {},
	showDeleteIcon: false
};

export default WorkspaceMemberWithRole;
