import PropTypes from 'prop-types';

const NotificationPropType = PropTypes.shape( {
	id: PropTypes.number.isRequired,
	title: PropTypes.string.isRequired,
	message: PropTypes.string.isRequired,
	image: PropTypes.string,
	wasRead: PropTypes.bool.isRequired,
	createdAt: PropTypes.instanceOf( Date )
} );

export default NotificationPropType;
