import { Component } from 'react';
import PropTypes from 'prop-types';

class ShowElementToggler extends Component {
	constructor( props ) {
		super( props );

		this.state = {
			show: false
		};

		this._onButtonClicked = this._onButtonClicked.bind( this );
		this._onClosed = this._onClosed.bind( this );
	}

	show() {
		this.setState( { show: true } );
	}

	_onButtonClicked( event ) {
		event.stopPropagation();
		this.setState( ( { show } ) => ( { show: !show } ) );
	}

	_onClosed() {
		this.setState( { show: false } );
	}

	render() {
		const {
			props: {
				className, renderToggler, renderComponent
			},
			state: { show }
		} = this;

		return (
			<div className={className} data-testid="ShowElementToggler">
				{renderToggler( this._onButtonClicked, show )}
				{show && renderComponent( this._onClosed )}
			</div>
		);
	}
}

ShowElementToggler.propTypes = {
	className: PropTypes.string,
	renderToggler: PropTypes.func.isRequired,
	renderComponent: PropTypes.func.isRequired
};

ShowElementToggler.defaultProps = {
	className: undefined
};

export default ShowElementToggler;
