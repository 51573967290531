import { Map } from 'immutable';
import { handle } from 'redux-pack';
import { normalizeResponse } from '../lib/normalizeUtils';
import {
	comment as commentScheme,
	task as taskScheme,
	projectMember as projectMemberScheme,
	workspaceMember as workspaceMemberScheme,
	activity as activityScheme,
	seenByUsers as seenByUsersScheme
} from './schemes';
import { handleSuccess, makeAsyncActionReducer } from '../lib/reduxUtils';
import {
	FETCH_ASSET_ROUND_COMMENTS, CREATE_COMMENT_FOR_ASSET, FETCH_WORKSPACE_MEMBER_COLLABORATORS,
	FETCH_PROJECT_MEMBERS, FETCH_WORKSPACE_MEMBERS, FETCH_PROJECT_TASKS,
	CREATE_TASK, EDIT_PROFILE, CHANGE_USER_PASSWORD, FETCH_PROJECT_ACTIVITIES,
	FETCH_COMMENTS_LAST_SEEN_BY_USERS, FETCH_ASSET_COMMENTS
} from '../actions/types';
import { UPDATER_ACTION_TYPE } from '../new_arch/services/ReduxStoreUpdater';

const normalizeUsersForComments = ( prevState, actionPayload ) => normalizeResponse(
	prevState, actionPayload, commentScheme, 'users'
);

const normalizeUsersForTasks = ( prevState, actionPayload ) => normalizeResponse(
	prevState, actionPayload, taskScheme, 'users'
);

const normalizeUsersForProjectMembers = ( prevState, actionPayload ) => normalizeResponse(
	prevState, actionPayload, projectMemberScheme, 'users'
);

const normalizeUsersForWorkspaceMembers = ( prevState, actionPayload ) => normalizeResponse(
	prevState, actionPayload, workspaceMemberScheme, 'users'
);

const normalizeUsersForActivities = ( prevState, actionPayload ) => normalizeResponse(
	prevState, actionPayload, activityScheme, 'users'
);

const normalizeUsersForCommentsSeenBy = ( prevState, actionPayload ) => normalizeResponse(
	prevState, actionPayload, seenByUsersScheme, 'users'
);

export const changeUserPasswordRequest = makeAsyncActionReducer( CHANGE_USER_PASSWORD );

export const users = ( state = new Map(), action ) => {
	switch ( action.type ) {
	case CREATE_COMMENT_FOR_ASSET:
		return handle(
			state,
			action,
			{
				start: prevState => normalizeUsersForComments( prevState, action.meta.tempComment ),
				success: prevState => normalizeUsersForComments(
					prevState.delete( action.meta.tempComment.user.id ),
					action.payload
				),
				failure: prevState => prevState.delete( action.meta.tempComment.user.id )
			}
		);
	case FETCH_ASSET_COMMENTS:
	case FETCH_ASSET_ROUND_COMMENTS:
		return handleSuccess(
			state,
			action,
			prevState => normalizeUsersForComments( prevState, action.payload.comments )
		);
	case FETCH_PROJECT_TASKS:
	case CREATE_TASK:
		return handleSuccess(
			state,
			action,
			prevState => normalizeUsersForTasks( prevState, action.payload )
		);
	case FETCH_PROJECT_MEMBERS:
		return handleSuccess(
			state,
			action,
			prevState => normalizeUsersForProjectMembers( prevState, action.payload )
		);
	case FETCH_WORKSPACE_MEMBERS:
	case FETCH_WORKSPACE_MEMBER_COLLABORATORS:
		return handleSuccess(
			state,
			action,
			prevState => normalizeUsersForWorkspaceMembers( prevState, action.payload )
		);
	case FETCH_PROJECT_ACTIVITIES:
		return handleSuccess(
			state,
			action,
			prevState => normalizeUsersForActivities( prevState, action.payload.activities )
		);
	case UPDATER_ACTION_TYPE.currentUser:
		return state.set( action.payload.user.id, action.payload.user );
	case EDIT_PROFILE:
		return handleSuccess(
			state,
			action,
			prevState => prevState.set( action.payload.id, action.payload )
		);
	case FETCH_COMMENTS_LAST_SEEN_BY_USERS:
		return handleSuccess(
			state,
			action,
			prevState => normalizeUsersForCommentsSeenBy( prevState, action.payload )
		);
	default:
		return state;
	}
};

export const fetchActivityRequest = makeAsyncActionReducer( FETCH_PROJECT_ACTIVITIES );
