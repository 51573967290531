/* eslint-disable jsx-a11y/control-has-associated-label */
import { forwardRef, useState, useImperativeHandle, useRef, ComponentProps } from 'react';

import { preventDefault } from '../../new_arch/lib/eventUtils';

type InputProps = ComponentProps<'input'>;

export type InputWithSuggestionsProps = {
	name: string;
	autoComplete: string;
} & InputProps;

const InputWithSuggestions = forwardRef( ( props: InputWithSuggestionsProps, forwardedRef ) => {
	const ref = useRef<HTMLButtonElement>( null );
	const [ formKey, setFormKey ] = useState( false );
	const remountForm = () => setFormKey( k => !k );

	useImperativeHandle( forwardedRef, () => ( {
		saveSuggestion: () => ref.current?.click()
	} ) )

	return (
		<form key={`${formKey}`} name={props.name} onSubmit={preventDefault}>
			<input {...props} />
			<button ref={ref} type="submit" onClick={remountForm} />
		</form>
	);
} );

export default InputWithSuggestions;
