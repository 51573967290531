/* eslint-disable no-extend-native, react/no-this-in-sfc, func-names */
function toLocaleStringWithMinimumFractionDigits(
	number, minimumFractionDigits,
	maximumFractionDigits = 2 ) {
	return number.toLocaleString(
		'en-US',
		{
			useGrouping: true,
			minimumFractionDigits,
			maximumFractionDigits
		}
	);
}

Number.prototype.toMoney = function toMoney( maximumFractionDigits ) {
	return parseFloat( toLocaleStringWithMinimumFractionDigits( this, 2, maximumFractionDigits ) );
};

Number.prototype.toDisplay = function toDisplay( maximumFractionDigits ) {
	return toLocaleStringWithMinimumFractionDigits( this, 0, maximumFractionDigits );
};

Number.prototype.mod = function ( n ) {
	return ( ( this % n ) + n ) % n;
};

Object.defineProperty( Number.prototype, 'bytesToGb', {
	get: function bytesToGb() {
		const numberInGB = ( this / ( 1024 ** 3 ) ).toFixed( 1 );
		return numberInGB.slice( -1 ) === '0' ? ( this / ( 1024 ** 3 ) ).toFixed() : numberInGB;
	}
} );

Number.prototype.formatBytes = function formatBytes( decimals = 1 ) {
	const units = [ 'Bytes', 'KB', 'MB', 'GB', 'TB' ];
	const power = Math.max(
		Math.min( Math.floor( Math.logBase( 1024, this ) ), units.length - 1 ),
		0
	);
	const unit = units[ power ];
	return `${parseFloat( ( this / 1024 ** power ).toFixed( decimals ) )} ${unit}`;
};
