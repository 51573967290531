import { Map } from 'immutable';

import { handleSuccess } from '../lib/reduxUtils';

import { FETCH_WORKSPACE_INVITATIONS, FETCH_WORKSPACE_INVITATION, DELETE_WORKSPACE_INVITATION } from '../actions/types';

export const workspaceInvitations = ( state = new Map(), action ) => {
	switch ( action.type ) {
	case FETCH_WORKSPACE_INVITATIONS:
		return handleSuccess(
			state,
			action,
			( prevState ) => {
				const { workspaceID } = action.meta;
				return prevState
					.replaceMatchingWithPayload( action.payload, workspaceID, 'workspace.id' );
			}
		);
	case FETCH_WORKSPACE_INVITATION:
		return handleSuccess(
			state,
			action,
			prevState => prevState.set(
				action.payload.id,
				action.payload
			)
		);
	case DELETE_WORKSPACE_INVITATION:
		return handleSuccess(
			state,
			action,
			prevState => prevState.delete( action.payload.invitationID )
		);
	default:
		return state;
	}
};
