/* eslint-disable max-len */
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import { PRIMARY_BASE } from '../../../styles/colors';

const PlanNameLine = ( {
	planName, planNameColor
} ) => (
	<h4 className="PlanNameLine">
		You are currently on a
		<span
			className="plan-name"
			style={{ color: planNameColor }}
		>
			{planName}
		</span>.
	</h4>
);

PlanNameLine.propTypes = {
	planName: PropTypes.string,
	planNameColor: PropTypes.string
};

PlanNameLine.defaultProps = {
	planName: '',
	planNameColor: PRIMARY_BASE
};

export default observer( PlanNameLine );
