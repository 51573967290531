import SortOption from './sortOption';

import type Asset from '../asset';
import type Folder from '../folder';
import { type SortDirection } from './types';

export default class ItemSorting {
	sortBy: SortOption;
	direction: SortDirection;

	constructor( { sortBy, direction }: { sortBy: SortOption, direction: SortDirection } ) {
		this.sortBy = sortBy;
		this.direction = direction;
	}

	get sortOptionId() {
		return this.sortBy.backendId;
	}

	sortAssets( assets: Asset[] ) {
		return this.sortBy.sortAssets( assets, this.direction );
	}

	sortFolders( folders: Folder[] ) {
		if ( this.sortBy.forAssetsOnly ) {
			return SortOption.byName.sortFolders( folders, 'asc' );
		}
		return this.sortBy.sortFolders( folders, this.direction );
	}

	queryParams() {
		return this.toJson();
	}

	toJson() {
		return {
			sort_by: this.sortOptionId,
			sort_order: this.direction
		}
	}
}
