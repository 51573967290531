import { Record } from 'immutable';
import FilterOption from '../../entities/filterOption';
import SortOption from '../../entities/sortOption';

export const DEFAULT_ASSETS_SORT_OPTION = SortOption.byLastActivity;
export const DEFAULT_ASSETS_FILTER_OPTION = FilterOption.none;

export const DEFAULT_ASSETS_FILTER_AND_SORT_OPTIONS = {
	sort: DEFAULT_ASSETS_SORT_OPTION.id,
	sortAscending: false,
	filter: DEFAULT_ASSETS_FILTER_OPTION.id,
	customSort: [],
	labelsOrder: [],
	filenameFilter: '',
	assetsType: 'image'
};

const AssetsFilterAndSortOptions = new Record(
	DEFAULT_ASSETS_FILTER_AND_SORT_OPTIONS
);

export default AssetsFilterAndSortOptions;
