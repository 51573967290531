import { createRef } from 'react';
import PropTypes from 'prop-types';
import { connectComponent } from '../../lib/connectComponent';
import { getCurrentUser } from '../../selectors/currentUser';

import AsyncApiActionPropType, { defaultProp as defaultAsyncApiAction } from '../../types/asyncApiAction';

import PasswordSettings from '../../components/PasswordSettings/PasswordSettings';
import Container from '../Container';

export class PasswordSettingsContainer extends Container {
	constructor( props ) {
		super( props );

		this.passwordSettingsRef = createRef();
		this._onChangePasswordClicked = this._onChangePasswordClicked.bind( this );
	}

	_clearInfomation() {
		if ( !this.passwordSettingsRef.current ) { return; }
		this.passwordSettingsRef.current.clear();
	}

	_onChangePasswordClicked( data ) {
		const { currentUserID, changeUserPassword } = this.props;

		this.handleAsyncActionResult(
			changeUserPassword( currentUserID, data.oldPassword, data.password ),
			{ title: 'Success! Your changes have been saved!' },
			{ title: 'Oops, something went wrong! Try again.' },
			undefined,
			() => { this._clearInfomation(); }
		);
	}

	render() {
		const { changeUserPasswordRequest } = this.props;

		return (
			<PasswordSettings
				ref={this.passwordSettingsRef}
				onChangeClick={this._onChangePasswordClicked}
				changing={changeUserPasswordRequest.sending}
			/>
		);
	}
}

PasswordSettingsContainer.propTypes = {
	currentUserID: PropTypes.number.isRequired,
	changeUserPasswordRequest: AsyncApiActionPropType,
	changeUserPassword: PropTypes.func
};

PasswordSettingsContainer.defaultProps = {
	changeUserPasswordRequest: defaultAsyncApiAction,
	changeUserPassword: () => {}
};

export default connectComponent( state => ( {
	currentUserID: getCurrentUser( state ).id
} ) )( PasswordSettingsContainer );
