import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useAutoupdatePresenterProperty from '../../hooks/useAutoupdatePresenterProperty';
import StorageUsagePresenter from './StorageUsagePresenter';
import PopupSystem from '../../systems/PopupSystem';
import AddOnSystem from '../../systems/AddOnSystem';
import SnackbarSystem from '../../systems/SnackbarSystem';
import { getAddOns } from '../../selectors/addOns';

const useStorageUsagePresenter = ( { workspace, onPaidFeatureModalAcceptClick } ) => {
	const dispatch = useDispatch();
	const addOns = useSelector( getAddOns );

	const createPresenter = () => {
		const popupSystem = new PopupSystem( dispatch );
		const addOnSystem = new AddOnSystem( dispatch );
		const snackbarSystem = new SnackbarSystem( dispatch );

		return new StorageUsagePresenter( {
			popupSystem,
			addOnSystem,
			snackbarSystem,
			workspace,
			addOns,
			onPaidFeatureModalAcceptClick
		} );
	};

	const [ presenter ] = useState( createPresenter );

	useAutoupdatePresenterProperty( { presenter, name: 'workspace', value: workspace } );
	useAutoupdatePresenterProperty( { presenter, name: 'addOns', value: addOns } );
	useAutoupdatePresenterProperty( { presenter: presenter.addOnsModalPresenter, name: 'workspace', value: workspace } );
	useAutoupdatePresenterProperty( { presenter: presenter.addOnsModalPresenter, name: 'addOns', value: addOns } );

	return presenter;
};

export default useStorageUsagePresenter;
