import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Icon from '../../new_arch/components/Icon/Icon';
import Project from '../../entities/project';
import ItemUploadBox from '../assets/ItemUploadBox/ItemUploadBox';
import { itemUploadPropType } from '../../types/itemUpload';
import { textEndEllipsis } from '../../lib/textTruncate';

import './ProjectAssetUploader.scss';

class ProjectAssetUploader extends PureComponent {
	constructor( props ) {
		super( props );

		this.state = { collapsed: false };
		this._projectUploadsCollapseHandler = this._projectUploadsCollapseHandler.bind( this );
	}

	get collapseIcon() {
		const iconForStatus = {
			false: 'expand_less',
			true: 'expand_more'
		};

		return iconForStatus[ this.state.collapsed ];
	}

	_projectUploadsCollapseHandler() {
		this.setState( prevState => ( { collapsed: !prevState.collapsed } ) );
	}

	render() {
		const { project, projectAssetUploads, projectFolderUploads, onUploadCancel } = this.props;
		const { collapsed } = this.state;

		const uploads = [ ...projectFolderUploads, ...projectAssetUploads ].sort(
			( a, b ) => ( a.statusOrder - b.statusOrder )
		);

		return (
			<div className="ProjectAssetUploader">
				<div onClick={this._projectUploadsCollapseHandler} className="project_head aligned">
					{project
					&& (
						<p className="project-title">
							{textEndEllipsis( project.name, 40 )}
						</p>
					)}
					<Icon icon={this.collapseIcon} />
				</div>
				{ !collapsed && (
					<ul className="files">
						{uploads
							.map( upload => (
								<ItemUploadBox
									key={upload.id}
									upload={upload}
									onUploadCancel={() => onUploadCancel( upload )}
								/>
							) )}
					</ul>
				) }
			</div>
		);
	}
}

ProjectAssetUploader.propTypes = {
	project: PropTypes.instanceOf( Project ),
	projectAssetUploads: PropTypes.arrayOf( itemUploadPropType ),
	projectFolderUploads: PropTypes.arrayOf( itemUploadPropType ),
	onUploadCancel: PropTypes.func
};

ProjectAssetUploader.defaultProps = {
	project: undefined,
	projectAssetUploads: [],
	projectFolderUploads: [],
	onUploadCancel: () => {}
};

export default ProjectAssetUploader;
