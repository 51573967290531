import { List } from 'immutable';
import Drawable from './drawable';
import ShapeRenderer from '../lib/shapeRenderer';
import Point from './point';

class Rect extends Drawable {
	constructor( startCorner, endCorner, strokeColor, strokeSize, isRelative ) {
		super( strokeColor, strokeSize, isRelative );

		this._startCorner = startCorner;
		this._endCorner = endCorner;
	}

	static fromJSON( properties ) {
		return new Rect(
			new Point( {
				x: properties.position_x,
				y: properties.position_y
			} ),
			new Point( {
				x: properties.position_x + properties.width,
				y: properties.position_y + properties.height
			} ),
			properties.stroke_color,
			properties.stroke_size,
			properties.is_relative
		);
	}

	toJSON() {
		return {
			type: 'rect',
			position_x: this.position.x,
			position_y: this.position.y,
			width: this.width,
			height: this.height,
			stroke_color: this.strokeColor,
			stroke_size: this.strokeSize
		};
	}

	get startCorner() {
		return this._startCorner;
	}

	get endCorner() {
		return this._endCorner;
	}

	get position() {
		return new Point( {
			x: Math.min( this._startCorner.x, this._endCorner.x ),
			y: Math.min( this._startCorner.y, this._endCorner.y )
		} );
	}

	get width() { return Math.abs( this._startCorner.x - this._endCorner.x ) }

	get height() { return Math.abs( this._startCorner.y - this._endCorner.y ) }

	get points() {
		return List.of( this._startCorner, this._endCorner );
	}

	get renderer() { return ShapeRenderer.forRect( this ); }

	get isValid() { return true; }

	static fromPoint( point, strokeColor, strokeSize ) {
		return new Rect( point, point, strokeColor, strokeSize );
	}

	updateWith( point ) {
		return new Rect( this._startCorner, point, this.strokeColor, this.strokeSize );
	}

	convertToRelative( { width, height } ) {
		if ( this._isRelative || !width || !height ) return;

		const convertToRelative = this._toRelativeCoordsConverter( { width, height } );

		this._startCorner = convertToRelative( this._startCorner );
		this._endCorner = convertToRelative( this._endCorner );
		this._isRelative = true;
	}
}

export default Rect;
