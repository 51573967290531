import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import LeaveWorkspaceTab from '../../components/LeaveWorkspaceTab/LeaveWorkspaceTab';
import useLeaveWorkspaceTabPresenter from '../../presenters/LeaveWorkspaceTabPresenter/useLeaveWorkspaceTabPresenter';

const LeaveWorkspaceTabContainer = ( { workspaceID } ) => {
	const presenter = useLeaveWorkspaceTabPresenter( { workspaceID } );
	return (
		<LeaveWorkspaceTab presenter={presenter} />
	);
};

LeaveWorkspaceTabContainer.propTypes = {
	workspaceID: PropTypes.number.isRequired
};

export default observer( LeaveWorkspaceTabContainer );
