import { createSelector } from 'reselect';
import ProjectInvitation from '../entities/projectInvitation';

export const getRawProjectsInvitations = state => state.projectInvitations;

export const getProjectsInvitations = createSelector(
	[ getRawProjectsInvitations ],
	projectInvitationsSerialization => projectInvitationsSerialization
		.map( ProjectInvitation.fromJSON )
);

export const getProjectInvitationFromHash = createSelector(
	[
		getProjectsInvitations,
		( _, props ) => props.invitationHash
	],
	( projectInvitations, hash ) => projectInvitations
		.find( invitation => invitation.hash === hash )
);
