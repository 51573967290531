import { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
	getWorkspaceMembersForWorkspace, getWorkspaceInvitations, getWorkspace
} from '../../selectors/workspaces';

import { connectComponent } from '../../lib/connectComponent';
import WorkspaceMemberList from '../../components/WorkspaceMemberList/WorkspaceMemberList';
import WorkspaceInvitation from '../../entities/workspaceInvitation';
import WorkspaceMember from '../../entities/workspaceMember';
import Workspace from '../../entities/workspace';
import { trackNavigatedToWorkspaceUsers } from '../../services/tracker/trackers/navigation';
import { PRO_PLAN, TEAM_PLAN } from '../../factories/pricingPlanToDisplayFactory';

const FREE_TRIAL = 'Free Trial';
const POP_UP_INFO_BY_PLAN = {
	[ FREE_TRIAL ]: {
		title: 'You\'ve found a paid feature',
		text: 'To add members to your workspace\nplease upgrade your plan.',
		buttonText: 'Upgrade',
		buttonAction: 'show-pricing-plans'
	},
	[ PRO_PLAN ]: {
		title: 'You\'ve reached the limit for workspace members on your current plan',
		text: 'Please upgrade your plan to add more members.',
		buttonText: 'Upgrade',
		buttonAction: 'show-pricing-plans'
	},
	[ TEAM_PLAN ]: {
		title: 'You\'ve reached the limit for workspace members',
		text: 'Please contact us to add more members to your workspace.',
		buttonText: 'Contact Us',
		buttonAction: 'contact-us'
	}
};

const popUpInfoFor = ( aWorkspace ) => {
	if ( aWorkspace.isOnFreeTrial ) { return POP_UP_INFO_BY_PLAN[ FREE_TRIAL ]; }

	return POP_UP_INFO_BY_PLAN[ aWorkspace.planName ];
};

export const WorkspaceMemberListContainer = ( {
	workspaceID, members, pendingInvites, workspace, showDialogWithIcon,
	fetchWorkspaceMembersAndInvitations, fetchWorkspaceMemberCollaborators
} ) => {
	useEffect( () => trackNavigatedToWorkspaceUsers(), [] );

	const fetchWorkspaceMembersForCurrentWorkspace = () => {
		if ( workspace?.userCanListAllMembers ) {
			fetchWorkspaceMembersAndInvitations( workspaceID );
		} else {
			fetchWorkspaceMemberCollaborators( workspaceID );
		}
	};

	useEffect(
		fetchWorkspaceMembersForCurrentWorkspace,
		[ workspaceID, workspace?.currentRoleId ]
	);

	return (
		<WorkspaceMemberList
			workspaceID={workspaceID}
			members={members}
			pendingInvites={pendingInvites}
			isSubscriptionActive={workspace.isActive}
			showDialogWithIcon={showDialogWithIcon}
			popupInfo={popUpInfoFor( workspace )}
			workspace={workspace}
		/>
	);
};

WorkspaceMemberListContainer.propTypes = {
	workspaceID: PropTypes.number.isRequired,
	members: PropTypes.arrayOf( PropTypes.instanceOf( WorkspaceMember ) ),
	pendingInvites: PropTypes.arrayOf( PropTypes.instanceOf( WorkspaceInvitation ) ),
	workspace: PropTypes.instanceOf( Workspace ),
	showDialogWithIcon: PropTypes.func,
	fetchWorkspaceMembersAndInvitations: PropTypes.func,
	fetchWorkspaceMemberCollaborators: PropTypes.func
};

WorkspaceMemberListContainer.defaultProps = {
	members: [],
	pendingInvites: [],
	workspace: undefined,
	showDialogWithIcon: () => {},
	fetchWorkspaceMembersAndInvitations: () => {},
	fetchWorkspaceMemberCollaborators: () => {}
};

export default connectComponent( ( state, props ) => ( {
	members: getWorkspaceMembersForWorkspace( state, props ),
	pendingInvites: getWorkspaceInvitations( state, props ),
	workspace: getWorkspace( state, props )
} ) )( WorkspaceMemberListContainer );
