import { createSelector } from 'reselect';
import { firstBy } from 'thenby';
import FolderUpload from '../entities/folderUpload';
import { getProjectID } from './projects';

const getRawFolderUploads = state => state.folderUploads;

export const getFolderUploadID = ( _, props ) => props.folderUploadID;

export const getFolderUploads = createSelector(
	[ getRawFolderUploads ],
	rawFolderUploads => rawFolderUploads
		.map( rawUpload => new FolderUpload( {
			id: rawUpload.id,
			status: rawUpload.status,
			bytesUploaded: rawUpload.bytesUploaded,
			projectID: rawUpload.projectID,
			organizerID: rawUpload.organizerID,
			organizerType: rawUpload.organizerType,
			directory: rawUpload.directory,
			parentUploadID: rawUpload.parentUploadID
		} ) )
);

const getFolderUploadsArray = createSelector(
	[ getFolderUploads ],
	folderUploads => folderUploads.valueSeq().toArray().sort(
		firstBy( ( a, b ) => ( a.statusOrder - b.statusOrder ) )
	)
);

export const getMainFolderUploadsForProject = createSelector(
	[ getFolderUploadsArray, getProjectID ],
	( folderUploads, projectID ) => folderUploads.filter( upload => (
		upload.projectID === projectID && !upload.parentUploadID
	) )
);
