import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Tooltip from '../../UI/Tooltip/Tooltip';
import Project from '../../../entities/project';
import Icon from '../../../new_arch/components/Icon/Icon';
import { ERROR } from '../../../styles/colors';
import { textEndEllipsis } from '../../../lib/textTruncate';

import './NotificationsProjectThumbnail.scss';

const NotificationsProjectThumbnail = forwardRef( ( {
	project, highlighted, unseenNotificationsCount, onClick
}, ref ) => {
	const limitedUnseenNotificationsCount = Math.min( unseenNotificationsCount, 99 );
	return (
		<Tooltip position="right" title={textEndEllipsis( project.name )}>
			<button
				className={classNames( 'NotificationsProjectThumbnail', { highlighted } )}
				onClick={onClick}
				ref={ref}
			>
				<div className="project-image-container">
					<img alt={project.name} src={project.coverImageURL} />
				</div>
				{highlighted && <div className="thumbnail-highlight" />}
				{!project.hasActiveWorkspace
					? <Icon icon="error" className="error-icon" size={16} color={ERROR} />
					: !!unseenNotificationsCount
					&& <div className="red-dot">{limitedUnseenNotificationsCount}</div>}
			</button>
		</Tooltip>
	);
} );

NotificationsProjectThumbnail.propTypes = {
	project: PropTypes.instanceOf( Project ).isRequired,
	unseenNotificationsCount: PropTypes.number,
	highlighted: PropTypes.bool,
	onClick: PropTypes.func
};

NotificationsProjectThumbnail.defaultProps = {
	unseenNotificationsCount: 0,
	highlighted: false,
	onClick: undefined
};

export default NotificationsProjectThumbnail;
