import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import Switch from '../../Switch/Switch';

import './PriceSwitch.scss';

const PriceSwitch = ( { isMonthly, annualDiscountPercentage, onSwitch } ) => (
	<Switch
		className="PriceSwitch"
		leftOption={{
			Content: () => (
				<h2 className="SwitchAnnualOption">
					Annually <span className="annualDiscountPercentage"> -{annualDiscountPercentage}%</span>
				</h2>
			),
			isSelected: !isMonthly
		}}
		rightOption={{
			Content: () => <h2>Monthly</h2>,
			isSelected: isMonthly
		}}
		onSwitch={onSwitch}
	/>
);

PriceSwitch.propTypes = {
	isMonthly: PropTypes.bool,
	annualDiscountPercentage: PropTypes.number,
	onSwitch: PropTypes.func
};

PriceSwitch.defaultProps = {
	isMonthly: true,
	annualDiscountPercentage: 20,
	onSwitch: () => {}
};

export default observer( PriceSwitch );
