export default class AddOnPrice {
	constructor( {
		id, billingPeriod, cost
	} ) {
		this.id = id;
		this.billingPeriod = billingPeriod;
		this.cost = parseFloat( cost );
	}

	static fromJSON( properties ) {
		return new AddOnPrice( {
			id: properties.id,
			billingPeriod: properties.billing_period,
			cost: properties.cost
		} );
	}

	static get null() {
		return new this( {
			id: 0, billingPeriod: null, cost: 0
		} );
	}

	toJSON() {
		return {
			id: this.id,
			billing_period: this.billingPeriod,
			cost: this.cost
		};
	}

	get isMonthly() {
		return this.billingPeriod === 'month';
	}

	get isAnnualPrice() {
		return this.billingPeriod === 'year';
	}
}
