import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import assetDownloadService from '../../services/AssetDownloadService';
import AssetDownloadBox from './AssetDownloadBox';

const AssetDownloadBoxDisplayer = () => {
	const [ displayBox, setDisplayBox ] = useState( false );
	const { hasUnfinishedDownloads } = assetDownloadService;

	useEffect( () => {
		if ( hasUnfinishedDownloads ) setDisplayBox( true );
	}, [ hasUnfinishedDownloads ] );

	return displayBox
		? (
			<AssetDownloadBox
				assetDownloadService={assetDownloadService}
				onBoxClosed={() => setDisplayBox( false )}
			/>
		)
		: <div />;
}

export default observer( AssetDownloadBoxDisplayer );
