import Workspace from '../../../entities/workspace';
import { isErrorWithStatus } from '../../../errors';

export type InviteWorkspaceMemberStrategyParams = {
	workspace: Workspace,
	showSuccessMessage: ( message: { title: string, description: string } ) => void,
	showErrorMessage: ( message: { title: string, description: string } ) => void,
	emails: string[]
};

export class InviteWorkspaceMemberStrategy {
	workspace: Workspace;
	showSuccessMessage: ( message: { title: string, description: string } ) => void;
	showErrorMessage: ( message: { title: string, description: string } ) => void;
	emails: string[];

	protected membersToAdd: number;
	protected seatsToAdd: number;

	constructor(
		{ workspace, showSuccessMessage, showErrorMessage, emails } : InviteWorkspaceMemberStrategyParams
	) {
		this.workspace = workspace;
		this.showSuccessMessage = showSuccessMessage;
		this.showErrorMessage = showErrorMessage;
		this.emails = emails;

		this.membersToAdd = emails.length;
		this.seatsToAdd = Math.max( this.membersToAdd - this.workspace.remainingSeats, 0 );
	}

	get haveEnoughSpaceForInvitation() {
		return this.workspace.canInviteMembers( this.membersToAdd );
	}

	renderSuccessMessage() {
		if ( this.haveEnoughSpaceForInvitation ) {
			this.renderSuccessMessageWithEnoughSpace();
		} else {
			this.renderSuccessMessageWithoutEnoughSpace();
		}
	}

	renderErrorMessage( error: unknown ) {
		if ( this.isAlreadyInvitedError( error ) ) {
			this.renderAlreadyInvitedErrorMessage();
			return;
		}

		this.renderDefaultErrorMessage();
	}

	protected renderDefaultErrorMessage() {
		throw new Error( 'Method not implemented.' );
	}

	protected renderAlreadyInvitedErrorMessage() {
		throw new Error( 'Method not implemented.' );
	}

	protected get isAlreadyInvitedError() {
		return isErrorWithStatus( 400, 'already_invited_emails' )
	}

	protected renderSuccessMessageWithoutEnoughSpace() {
		throw new Error( 'Method not implemented.' );
	}

	protected renderSuccessMessageWithEnoughSpace() {
		throw new Error( 'Method not implemented.' );
	}

	protected get successMessageTitle() {
		return 'Member Invitation Sent';
	}

	protected get errorMessageTitle() {
		return 'Member Invitation Error';
	}
}
