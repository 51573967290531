import { Component, createRef } from 'react';
import PropTypes from 'prop-types';

import { connectComponent } from '../../lib/connectComponent';
import { actionResultIsError } from '../../lib/reduxUtils';

import InviteMember from '../../components/InviteMember/InviteMember';
import WorkspaceRole from '../../entities/workspaceRole';
import Workspace from '../../entities/workspace';
import AddOn from '../../entities/addOn';
import { getMemberAddOn } from '../../selectors/addOns';
import InviteWorkspaceMemberBillingConfirmationModal
	from '../../modals/InviteWorkspaceMemberBillingConfirmationModal/InviteWorkspaceMemberBillingConfirmationModal';
import InviteMultipleWorkspaceMemberStrategy from './utils/InviteMultipleWorkspaceMemberStrategy';
import InviteSingleWorkspaceMemberStrategy from './utils/InviteSingleWorkspaceMemberStrategy';

const availableRoles = [
	{
		id: WorkspaceRole.collaborator().id,
		name: WorkspaceRole.collaborator().name,
		description: 'User can collaborate on projects they’ve been invited to but cannot create new projects.'
	},
	{
		id:	WorkspaceRole.admin().id,
		name: WorkspaceRole.admin().name,
		description: 'User that can create and delete projects, and<br>edit their settings. And can also work on them.'
	}
];
export class InviteWorkspaceMemberContainer extends Component {
	constructor( props ) {
		super( props );

		this.inviteMember = createRef();

		this.state = {
			shouldOpenBillingConfirmationModal: false,
			inviteData: {},
			loading: false
		};

		this._onInviteMemberClicked = this._onInviteMemberClicked.bind( this );
		this.inviteMemberToWorkspace = this.inviteMemberToWorkspace.bind( this );
		this._onConfirmBillingConfirmationModal = this._onConfirmBillingConfirmationModal.bind( this );
		this._closeBillingConfirmationModal = this._closeBillingConfirmationModal.bind( this );
	}

	inviteMemberToWorkspace() {
		const {
			workspace, inviteWorkspaceMember, fetchWorkspaceMembersAndInvitations, onModalClose
		} = this.props;
		const { emails, role, message } = this.state.inviteData;
		const { strategy } = this.state;

		return inviteWorkspaceMember( workspace.id, emails, role, message )
			.then( ( result ) => {
				if ( actionResultIsError( result ) ) {
					throw result.payload;
				}

				strategy.renderSuccessMessage();
				fetchWorkspaceMembersAndInvitations( workspace.id );

				if ( this.inviteMember.current ) {
					this.inviteMember.current.clear();
				}

				onModalClose();
			} )
			.catch( error => strategy.renderErrorMessage( error ) );
	}

	buildStrategy( emails ) {
		const { workspace, showSuccessMessage, showErrorMessage } = this.props;

		const strategyProps = {
			workspace,
			showSuccessMessage,
			showErrorMessage,
			emails
		};

		return emails.length > 1
			? new InviteMultipleWorkspaceMemberStrategy( strategyProps )
			: new InviteSingleWorkspaceMemberStrategy( strategyProps );
	}

	// Events handlers
	_onInviteMemberClicked( inviteData ) {
		const { workspace } = this.props;
		const { emails } = inviteData;

		this.setState(
			{ inviteData, strategy: this.buildStrategy( emails ) },
			() => {
				if ( !workspace.canInviteMembers( emails.length ) ) {
					this._openBillingConfirmationModal();
					return;
				}

				this.inviteMemberToWorkspace();
			}
		);
	}

	_openBillingConfirmationModal() {
		this.setState( {
			shouldOpenBillingConfirmationModal: true
		} );
	}

	_closeBillingConfirmationModal() {
		this.setState( { shouldOpenBillingConfirmationModal: false } );
	}

	_onConfirmBillingConfirmationModal() {
		this.setState( { loading: true } );
		this.inviteMemberToWorkspace()
			.then( () => {
				this.setState( { loading: false } );
				this._closeBillingConfirmationModal();
			} );
	}

	renderBillingConfirmationModal() {
		const { workspace, memberAddOn } = this.props;
		const { emails } = this.state.inviteData;
		return (
			<InviteWorkspaceMemberBillingConfirmationModal
				workspace={workspace}
				addOn={memberAddOn}
				onCancel={() => this._closeBillingConfirmationModal()}
				onConfirm={() => this._onConfirmBillingConfirmationModal()}
				loading={this.state.loading}
				emails={emails}
			/>
		);
	}

	render() {
		const { shouldOpenBillingConfirmationModal } = this.state;
		const { workspace } = this.props;

		return (
			<>
				{shouldOpenBillingConfirmationModal && this.renderBillingConfirmationModal()}
				<InviteMember
					ref={this.inviteMember}
					role={availableRoles[ 1 ]}
					defaultRole={availableRoles[ 1 ]}
					onInviteClick={this._onInviteMemberClicked}
					selectWithDescription
					projectMemberRoleName={availableRoles[ 1 ].name}
					workspace={workspace}
				/>
			</>
		);
	}
}

InviteWorkspaceMemberContainer.propTypes = {
	workspace: PropTypes.instanceOf( Workspace ).isRequired,
	onModalClose: PropTypes.func,
	memberAddOn: PropTypes.instanceOf( AddOn ).isRequired,
	inviteWorkspaceMember: PropTypes.func,
	fetchWorkspaceMembersAndInvitations: PropTypes.func,
	showSuccessMessage: PropTypes.func,
	showErrorMessage: PropTypes.func
};

InviteWorkspaceMemberContainer.defaultProps = {
	inviteWorkspaceMember: () => {},
	fetchWorkspaceMembersAndInvitations: () => {},
	showSuccessMessage: () => {},
	showErrorMessage: () => {},
	onModalClose: () => {}
};

export default connectComponent( state => ( {
	memberAddOn: getMemberAddOn( state )
} ) )( InviteWorkspaceMemberContainer );
