import { matchPath } from 'react-router';

export const createURLWithParams = ( baseURL, params ) => {
	if ( Object.keys( params ).length === 0 ) { return baseURL; }

	const encodedParams = Object.entries( params ).map(
		( [ key, value ] ) => value !== undefined && `${key}=${value}`
	)
		.filter( encodedParam => encodedParam !== false )
		.join( '&' );

	return `${baseURL}?${encodedParams}`;
};

export const getLocationSearchParam = ( location, name ) => new URLSearchParams(
	location.search
).get( name );

export const matchesWithSomePath = ( { paths, location } ) => (
	paths.some( path => matchPath( location.pathname, { path } )?.isExact )
);

export const isHomePage = ( { location } ) => (
	matchPath( location.pathname, '/projects' )?.isExact
);
