import { useState } from 'react';
import classNames from 'classnames';
import './CopyTextButton.scss';

type CopyTextButtonProps = {
	text: string,
	className?: string,
}

const CopyTextButton = ( { text, className }: CopyTextButtonProps ) => {
	const [ isSuccessMessageVisible, showSuccessMessage ] = useState( false );

	const onClick = () => {
		navigator.clipboard.writeText( text );
		showSuccessMessage( true );
		setTimeout( () => showSuccessMessage( false ), 750 );
	}

	return (
		<div className={classNames( 'CopyTextButton', className )}>
			{isSuccessMessageVisible
				? <span>Copied to clipboard!</span>
				: (
					<button
						type="button"
						onClick={onClick}
					>
						<u>Copy text</u>
					</button>
				)}
		</div>
	);
}

export default CopyTextButton;
