import { useState } from 'react';
import PropTypes from 'prop-types';
import WhiteBox from '../WhiteBox/WhiteBox';
import contactsPlaceholder from '../../contacts-placeholder.png'
import CreateOrEditContactGroupModal from '../../modals/CreateOrEditContactGroupModal/CreateOrEditContactGroupModal';
import Button from '../../new_arch/components/Button/Button';
import ContactGroupsTooltip from '../ContactGroupsTooltip/ContactGroupsTooltip';
import List from '../List/List';
import ContactGroupPropType from '../../types/contactGroup';
import WorkspaceContactGroupRow from '../WorkspaceContactGroupRow/WorkspaceContactGroupRow';

import './WorkspaceContactGroups.scss';

const WorkspaceContactGroups = ( {
	workspaceID, contactGroups, onDeleteContactGroupClick
} ) => {
	const [ showNewGroupModal, setShowNewGroupModal ] = useState( false );
	const [ contactGroupToEdit, setContactGroupToEdit ] = useState();
	const showContactGroups = contactGroups.length > 0;

	const onEditContactGroupClick = group => setContactGroupToEdit( group );

	const renderHeader = () => (
		<div className="headLine">
			<div className="title">
				<h4>Collaborator Groups</h4>
				<ContactGroupsTooltip />
			</div>
			<div className="button-container">
				<Button onClick={() => setShowNewGroupModal( true )}>
					New Group
				</Button>
			</div>
		</div>
	);

	const renderTopBar = () => {
		if ( !showContactGroups ) {
			return null;
		}

		return (
			<div className="column-names">
				<div className="contact-name">
					<h5>Name</h5>
				</div>
				<div className="contact-emails">
					<h5>Emails</h5>
				</div>
				<div className="actions">
					<h5>Actions</h5>
				</div>
			</div>
		);
	};

	const renderBody = () => {
		if ( showContactGroups ) {
			return (
				<div className="contact-groups">
					<List itemsSize={50}>
						{contactGroups.map( group => (
							<WorkspaceContactGroupRow
								key={group.id}
								group={group}
								onEditContactGroupClick={onEditContactGroupClick}
								onDeleteContactGroupClick={onDeleteContactGroupClick}
							/>
						) )}
					</List>
				</div>
			);
		}

		return (
			<div className="contacts-placeholder">
				<img src={contactsPlaceholder} width="230px" alt="contacts-placeholder" />
				<h3>No groups yet</h3>
				<p>
					Start creating contact groups to optimize your flow while
					inviting your team members to a project
				</p>
			</div>
		);
	}

	return (
		<div className="WorkspaceContactGroups">
			{showNewGroupModal
				&& (
					<CreateOrEditContactGroupModal
						workspaceID={workspaceID}
						mode="create"
						onClose={() => setShowNewGroupModal( false )}
					/>
				)}
			{contactGroupToEdit && (
				<CreateOrEditContactGroupModal
					workspaceID={workspaceID}
					mode="edit"
					contactGroup={contactGroupToEdit}
					onClose={() => setContactGroupToEdit( undefined )}
				/>
			)}
			<WhiteBox>
				{renderHeader()}
				{renderTopBar()}
				{renderBody()}
			</WhiteBox>
		</div>
	);
};

WorkspaceContactGroups.propTypes = {
	workspaceID: PropTypes.number,
	contactGroups: PropTypes.arrayOf( ContactGroupPropType ),
	onDeleteContactGroupClick: PropTypes.func
};

WorkspaceContactGroups.defaultProps = {
	workspaceID: undefined,
	contactGroups: [],
	onDeleteContactGroupClick: () => {}
};

export default WorkspaceContactGroups;
