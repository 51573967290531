import WorkspaceRole, { AVAILABLE_ROLES_BY_TYPE } from './workspaceRole';
import ImmutableEntity from './immutableEntity';

export default class WorkspaceMember extends ImmutableEntity {
	constructor(
		id, workspaceID, userID, roleID, projects, user = undefined, userOwnsAWorkspace = false,
		ownedProjectsCount = 0
	) {
		super();

		this.id = id;
		this.workspaceID = workspaceID;
		this.userID = userID;
		this.roleID = roleID;
		this.projects = projects;
		this.user = user;
		this.userOwnsAWorkspace = userOwnsAWorkspace;
		this.ownedProjectsCount = ownedProjectsCount;
	}

	get role() { return AVAILABLE_ROLES_BY_TYPE[ this.roleID ]; }

	get isOwner() { return this.roleID === WorkspaceRole.owner().id; }

	static fromJSON( properties ) {
		return new WorkspaceMember(
			properties.id,
			properties.workspace_id,
			properties.user_id,
			properties.role.type,
			properties.projects,
			undefined,
			properties.user_owns_a_workspace,
			properties.owned_projects_count
		);
	}

	clone() {
		return new WorkspaceMember(
			this.id,
			this.workspaceID,
			this.userID,
			this.roleID,
			this.projects,
			this.user,
			this.userOwnsAWorkspace,
			this.ownedProjectsCount
		);
	}

	withUser( user ) {
		return this.set( 'user', user );
	}

	withRole( roleID ) {
		return this.set( 'roleID', roleID );
	}

	toJSON() {
		return {
			id: this.id,
			workspace_id: this.workspaceID,
			user_id: this.userID,
			role: this.roleID,
			projects: this.projects,
			user_owns_a_workspace: this.userOwnsAWorkspace,
			owned_projects_count: this.ownedProjectsCount
		};
	}
}
