import PropTypes from 'prop-types';

import { connectComponent } from '../../lib/connectComponent';
import { getUserCanDeleteProjectMember }
	from '../../selectors/permissions/usersPermissions';
import ProjectMemberWithRole from '../../components/ProjectMemberWithRole/ProjectMemberWithRole';
import ProjectMember from '../../entities/projectMember';
import Container from '../Container';

export class ProjectMemberWithRoleContainer extends Container {
	constructor( props ) {
		super( props );

		this._onDeleteClicked = this._onDeleteClicked.bind( this );
	}

	deleteProjectMember() {
		const { projectID, member: { id, user }, deleteProjectMember } = this.props;

		return this.handleAsyncActionResult(
			deleteProjectMember( projectID, id ),
			{
				title: 'User Removed',
				description: `The user with email: ${user.email}, is no longer a part of this project.`
			},
			{ title: 'Oops, something went wrong! Try again.' }
		);
	}

	_onDeleteClicked() {
		const { props: { member } } = this;

		this.showAsyncActionConfirmation(
			`Are you sure you want to delete ${member.user.name} from the project?`,
			'Ok',
			'Cancel',
			() => this.deleteProjectMember()
		);
	}

	render() {
		const {
			props: { member, canDelete }
		} = this;

		return (
			<ProjectMemberWithRole
				member={member}
				canDelete={canDelete}
				onDeleteClick={this._onDeleteClicked}
			/>
		);
	}
}

ProjectMemberWithRoleContainer.propTypes = {
	projectID: PropTypes.number.isRequired,
	member: PropTypes.instanceOf( ProjectMember ),
	canDelete: PropTypes.bool,
	deleteProjectMember: PropTypes.func
};

ProjectMemberWithRoleContainer.defaultProps = {
	member: null,
	canEdit: true,
	canDelete: true,
	deleteProjectMember: () => {}
};

export default connectComponent( ( state, props ) => ( {
	canDelete: getUserCanDeleteProjectMember( state, props )
} ), true )( ProjectMemberWithRoleContainer );
