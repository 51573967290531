import { useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import NotificationPropType from '../../../types/notification';

import Loader from '../../Loader/Loader';
import NotificationListItem from '../NotificationListItem/NotificationListItem';
import NoItemsIndicatorBox from '../../NoItemsIndicatorBox/NoItemsIndicatorBox';

import './NotificationsList.scss';

const NotificationsList = ( {
	notifications, hasNextPage, onNotificationClicked, onFetchNextPage,
	showEmptyMessage, showLoader, filterIsApplied,
	onNotificationProjectClicked, onNotificationFolderClicked
} ) => {
	const listContainerRef = useRef( null );

	const checkForScrollBottom = useCallback(
		() => {
			const { current: listContainer } = listContainerRef;

			if (
				!hasNextPage || !listContainer || !onFetchNextPage
			) { return; }

			const {
				scrollTop: listScrollTop,
				clientHeight: listHeight,
				scrollHeight: listScrollHeight
			} = listContainer;

			if ( ( listScrollTop + listHeight ) < listScrollHeight ) { return; }

			onFetchNextPage();
		},
		[ listContainerRef, onFetchNextPage, notifications, hasNextPage ]
	);

	useEffect( () => {
		const { current: listContainer } = listContainerRef;
		if ( !listContainer ) { return undefined; }

		listContainer.addEventListener( 'scroll', checkForScrollBottom );

		return () => {
			listContainer.removeEventListener( 'scroll', checkForScrollBottom );
		};
	} );

	const renderNotificationItems = () => (
		notifications.map(
			notification => (
				<NotificationListItem
					key={notification.id}
					notification={notification}
					onNotificationClicked={onNotificationClicked}
					onNotificationProjectClicked={onNotificationProjectClicked}
					onNotificationFolderClicked={onNotificationFolderClicked}
				/>
			)
		)
	);

	const renderNoItemsText = () => (
		<NoItemsIndicatorBox
			text={filterIsApplied
				? 'You don\'t have any notifications that\nmatch the selected filters.'
				: 'You have no\nnotifications here yet.'}
			small
		/>
	);

	return (
		<div className="NotificationsList">
			{showLoader
				? <Loader active />
				: (
					<div className="body" ref={listContainerRef}>
						{showEmptyMessage && ( notifications.length === 0 )
							? renderNoItemsText()
							: renderNotificationItems()}
					</div>
				)}
		</div>
	);
};

NotificationsList.propTypes = {
	filterIsApplied: PropTypes.bool,
	notifications: PropTypes.arrayOf( NotificationPropType ),
	hasNextPage: PropTypes.bool,
	showEmptyMessage: PropTypes.bool,
	onNotificationClicked: PropTypes.func,
	onFetchNextPage: PropTypes.func,
	showLoader: PropTypes.bool,
	onNotificationProjectClicked: PropTypes.func,
	onNotificationFolderClicked: PropTypes.func
};

NotificationsList.defaultProps = {
	filterIsApplied: false,
	notifications: [],
	hasNextPage: false,
	showEmptyMessage: true,
	onNotificationClicked: undefined,
	onFetchNextPage: undefined,
	showLoader: false,
	onNotificationProjectClicked: undefined,
	onNotificationFolderClicked: undefined
};

export default NotificationsList;
