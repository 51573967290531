import CloseableModalWithTitle from '../CloseableModalWithTitle/CloseableModalWithTitle';
import ShareReviewLink from '../../components/ShareReviewLink/ShareReviewLink';

import './ShareReviewLinkModal.scss';

export type Shareable = {
	shareLink: ( { withDownloads } : { withDownloads: boolean } ) => string
}

const ShareReviewLinkModal = ( { onClose, shareable }: { onClose: () => void, shareable: Shareable } ) => (
	<CloseableModalWithTitle
		headerBorderType="small"
		onClose={onClose}
		name="share-review-link"
		title="Share Review Link"
	>
		<ShareReviewLink shareable={shareable} />
	</CloseableModalWithTitle>
);

export default ShareReviewLinkModal;
