import { useState } from 'react';
import PropTypes from 'prop-types';
import { firstBy } from 'thenby';

import WhiteBox from '../WhiteBox/WhiteBox';

import './WorkspaceMemberList.scss';
import WorkspaceMember from '../../entities/workspaceMember';
import WorkspaceInvitation from '../../entities/workspaceInvitation';
import WorkspaceMemberWithRoleContainer from '../../containers/WorkspaceMemberWithRoleContainer/WorkspaceMemberWithRoleContainer';
import WorkspaceInvitationContainer from '../../containers/WorkspaceInvitationContainer/WorkspaceInvitationContainer';
import Button from '../../new_arch/components/Button/Button';
import List from '../List/List';
import InviteWorkspaceMemberModal from '../../modals/InviteWorkspaceMemberModal/InviteWorkspaceMemberModal';
import MembersUsageContainer from '../../containers/MembersUsageContainer/MembersUsageContainer';
import Workspace from '../../entities/workspace';
import PricingSubscriptionSelectionModal from '../../modals/PricingSubscriptionSelectionModal/PricingSubscriptionSelectionModal';
import PricingSubscriptionPresenter from '../../presenters/PlanSettings/PricingSubscriptionPresenter';
import WorkspaceSubscriptionCheckoutModalContainer from '../../containers/WorkspaceSubscriptionCheckoutModalContainer/WorkspaceSubscriptionCheckoutModalContainer';
import openEmailClient from '../../services/openEmailClient';
import { SUPPORT_EMAIL } from '../../config/email-addresses';

const WorkspaceMemberList = ( {
	workspaceID, members, pendingInvites,
	showDialogWithIcon, popupInfo, workspace
} ) => {
	const [ showInviteModal, setShowInviteModal ] = useState( false );
	const [ suscriptionModalIsOpen, setSuscriptionModalIsOpen ] = useState( false );
	const [ showingCheckoutModal, setShowingCheckoutModal ] = useState( false );
	const [ pricingPlanPrice, setPricingPlanPrice ] = useState( undefined );

	const sortedMembers = members.sort(
		firstBy( member => member.role?.level )
			.thenBy( member => member.user.name, { ignoreCase: true } )
	);
	const sortedPendingInvites = pendingInvites.sort(
		firstBy( invite => invite.role?.level )
			.thenBy( 'email' )
	);

	const buttonActions = {
		'show-pricing-plans': () => setSuscriptionModalIsOpen( true ),
		'contact-us': () => openEmailClient( SUPPORT_EMAIL )
	};

	const showErrorModal = () => showDialogWithIcon(
		popupInfo.title,
		popupInfo.text,
		popupInfo.buttonText,
		true
	).then( accept => accept && buttonActions[ popupInfo.buttonAction ]() );

	const onInviteMemberClick = () => {
		if ( workspace.isOnFreeTrial ) {
			showErrorModal();
			return;
		}

		setShowInviteModal( true );
	};

	const inviteMemberButton = (
		<Button
			onClick={() => onInviteMemberClick()}
			disabled={!workspace.isSubscriptionActive}
		>
			Invite Member
		</Button>
	);

	const onUpgradeButtonClicked = ( aPricingPlanPrice ) => {
		setPricingPlanPrice( aPricingPlanPrice );
		setShowingCheckoutModal( true );
		setSuscriptionModalIsOpen( false );
	};

	const closeCheckoutModal = () => setShowingCheckoutModal( false );

	const onSuccessfulPaymentDialogAccepted = () => {
		setSuscriptionModalIsOpen( false );
		setShowingCheckoutModal( false );
	};

	const pricingSubscriptionPresenter = new PricingSubscriptionPresenter( { workspace } );

	const renderWorkspaceSubscriptionCheckoutModal = () => (
		<WorkspaceSubscriptionCheckoutModalContainer
			workspaceID={workspace.id}
			onClose={closeCheckoutModal}
			onSuccessfulDialogAccepted={onSuccessfulPaymentDialogAccepted}
			pricingPlanPrice={pricingPlanPrice}
		/>
	);

	const renderPricingSubscriptionSelectionModal = () => (
		<PricingSubscriptionSelectionModal
			workspaceID={workspace.id}
			presenter={pricingSubscriptionPresenter}
			onClose={() => setSuscriptionModalIsOpen( false )}
			onUpgradeButtonClicked={onUpgradeButtonClicked}
		/>
	);

	return (
		<div className="WorkspaceMemberList">
			{ suscriptionModalIsOpen && renderPricingSubscriptionSelectionModal() }
			{ showingCheckoutModal && renderWorkspaceSubscriptionCheckoutModal() }
			{showInviteModal
				&& (
					<InviteWorkspaceMemberModal
						workspace={workspace}
						onClose={() => setShowInviteModal( false )}
					/>
				)}
			<WhiteBox>
				<div className="title">
					<h4>{workspace.name}{"'s Members"}</h4>
				</div>
				{workspace.hasAPaidPlan && (
					<MembersUsageContainer
						workspace={workspace}
						inviteMemberButton={inviteMemberButton}
						onPaidFeatureModalAcceptClick={() => setSuscriptionModalIsOpen( true )}
					/>
				)}
				<div className="column-names">
					<div className="member-info">
						<h5>
							Members
						</h5>
					</div>
					<div className="project-counter">
						<h5>Projects</h5>
					</div>
					<div className="role">
						<h5>Workspace Role</h5>
					</div>
					{workspace.hasAPaidPlan
					&& (
						<div className="actions">
							<h5>Actions</h5>
						</div>
					)}
				</div>
				<div className="members-invites">
					<List itemsSize={50}>
						{sortedMembers.map( member => (
							<WorkspaceMemberWithRoleContainer
								key={member.id}
								workspaceID={workspaceID}
								member={member}
							/>
						) )}
					</List>
					{pendingInvites.length > 0
						&& (
							<List itemsSize={50}>
								{sortedPendingInvites.map( invite => (
									<WorkspaceInvitationContainer
										key={invite.id}
										workspaceID={workspaceID}
										invite={invite}
									/>
								) )}
							</List>
						)}
				</div>
				{!workspace.hasAPaidPlan
				&& (
					<div className="button-container">
						<Button onClick={onInviteMemberClick}>
							Invite Member
						</Button>
					</div>
				)}
			</WhiteBox>
		</div>
	);
};

WorkspaceMemberList.propTypes = {
	workspaceID: PropTypes.number,
	members: PropTypes.arrayOf( PropTypes.instanceOf( WorkspaceMember ) ),
	pendingInvites: PropTypes.arrayOf( PropTypes.instanceOf( WorkspaceInvitation ) ),
	showDialogWithIcon: PropTypes.func,
	popupInfo: PropTypes.shape( {
		title: PropTypes.string.isRequired,
		text: PropTypes.string.isRequired,
		buttonText: PropTypes.string.isRequired,
		buttonAction: PropTypes.oneOf( [ 'show-pricing-plans', 'contact-us' ] ).isRequired
	} ),
	workspace: PropTypes.instanceOf( Workspace ).isRequired
};

WorkspaceMemberList.defaultProps = {
	workspaceID: undefined,
	members: [],
	pendingInvites: [],
	showDialogWithIcon: () => {},
	popupInfo: {}
};

export default WorkspaceMemberList;
