class PendingQueue {
	constructor() {
		this.queue = [];
	}

	get isEmpty() {
		return this.queue.length === 0;
	}

	add( uploads ) {
		this.queue = this.queue.concat( uploads );
	}

	dequeue() {
		return this.isEmpty ? undefined : this.queue.pop();
	}

	find( uploadID ) {
		return this.queue.find( ( { id } ) => id === uploadID );
	}

	remove( uploadID ) {
		this.queue = this.queue.filter( ( { id } ) => uploadID !== id );
	}

	cancelUpload( uploadID ) {
		const upload = this.find( uploadID );
		if ( !upload ) { return; }

		upload.abort();
		this.remove( uploadID );
	}

	cancelAll() {
		this.queue = [];
	}
}

export default PendingQueue;
