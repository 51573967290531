export default class FilePath {
	private parts: string[];

	constructor( parts: string[] ) {
		this.parts = parts;
	}

	static parse( pathString: string ) {
		if ( !pathString ) return new FilePath( [] );

		let trimmedPath = pathString;

		while ( trimmedPath.startsWith( '/' ) ) trimmedPath = trimmedPath.slice( 1 );
		while ( trimmedPath.endsWith( '/' ) ) trimmedPath = trimmedPath.slice( 0, -1 );

		return new FilePath( trimmedPath.split( '/' ).filter( part => !!part ) );
	}

	get head() {
		return this.parts[ 0 ];
	}

	get tail() {
		return new FilePath( this.parts.slice( 1 ) );
	}

	get isEmpty() {
		return this.parts.length === 0;
	}

	get dropFilename() {
		return new FilePath( this.parts.slice( 0, -1 ) );
	}
}
