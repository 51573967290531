import { makeAutoObservable } from 'mobx';

export default class Pagination {
	constructor( {
		page = 1,
		perPage = 100,
		totalPages = -1,
		isFirstPage = true,
		isLastPage = false,
		totalCount = 0
	} = {} ) {
		this.page = page;
		this.perPage = perPage;
		this.totalPages = totalPages;
		this.totalCount = totalCount;
		this.isFirstPage = isFirstPage;
		this.isLastPage = isLastPage;

		makeAutoObservable( this );
	}

	reset() {
		this.setPage( 1 );
		this.setIsFirstPage( true );
		this.setIsLastPage( false );
		this.setTotalPages( -1 );
	}

	resetLastPage() {
		this.setPage( this.page - 1 );
		this.setIsLastPage( false );
	}

	setPage( page ) {
		this.page = page;
	}

	setNextPage() {
		this.setPage( this.nextPage );
	}

	setTotalPages( totalPages ) {
		this.totalPages = totalPages;
	}

	setIsLastPage( isLastPage ) {
		this.isLastPage = isLastPage;
	}

	setIsFirstPage( isFirstPage ) {
		this.isFirstPage = isFirstPage;
	}

	get hasMorePages() {
		return ( this.page <= this.totalPages || this.totalPages === -1 )
				&& !this.isLastPage;
	}

	get nextPage() {
		return this.page + 1;
	}

	toJSON() {
		return {
			current: this.page,
			pages: this.totalPages,
			total_count: this.totalCount,
			first_page: this.isFirstPage,
			last_page: this.isLastPage
		};
	}

	static fromJSON( properties ) {
		return new Pagination( {
			page: properties.current,
			totalPages: properties.pages,
			totalCount: properties.total_count,
			isFirstPage: properties.first_page,
			isLastPage: properties.last_page
		} );
	}
}
