import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import FormInput from '../../new_arch/components/FormInput/FormInput';

import Field from '../../lib/forms/Field';
import MinWordCount from '../../lib/forms/validators/MinWordCount';

const CardholderNameInput = ( {
	value,
	onChange,
	onValidationStatusChange,
	disabled,
	required
} ) => {
	const [ hadFocusOnce, setHadFocusOnce ] = useState( false );

	const formField = new Field( 'cardholder-name', 'Cardholder name', value, [ new MinWordCount( 2 ) ] );

	const hasError = !!value && !formField.validate();

	const errorMessage = hasError && hadFocusOnce
		? formField.errors.first
		: null;

	const isValid = !!value && !hasError;

	useEffect(
		() => onValidationStatusChange( isValid ),
		[ isValid ]
	);

	return (
		<FormInput
			className="CardholderNameInput"
			name="cardholder-name"
			value={value}
			onChange={onChange}
			placeholder="Name on Card"
			error={errorMessage}
			required={required}
			onFocus={() => setHadFocusOnce( true )}
			disabled={disabled}
			label="Name on Card"
		/>
	);
};

CardholderNameInput.propTypes = {
	value: PropTypes.any,
	onChange: PropTypes.func,
	onValidationStatusChange: PropTypes.func,
	disabled: PropTypes.bool,
	required: PropTypes.bool
};

CardholderNameInput.defaultProps = {
	value: '',
	onChange: () => {},
	onValidationStatusChange: () => {},
	disabled: false,
	required: false
};

export default CardholderNameInput;
