import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import User from '../../../models/user/user';
import useFetchCurrentUserQuery from './useFetchCurrentUserQuery';
import useIsLoggedIn from '../../../../hooks/useIsLoggedIn';
import PopupSystem from '../../../../systems/PopupSystem';
import PopupFactory from '../../../../factories/PopupFactory';
import SnackbarSystem from '../../../../systems/SnackbarSystem';

const withGlobalModals = <P extends {currentUser?: User}>( Component : React.ComponentType<P> ) => observer(
	( props : P ) => {
		const [ hasRoleSurveyPopupBeenOpened, setRoleSurveyPopupBeenOpened ] = useState( false );
		const isLoggedIn = useIsLoggedIn();
		const { data: currentUser } = useFetchCurrentUserQuery();

		const dispatch = useDispatch();
		const popupSystem = new PopupSystem( dispatch );
		const snackbarSystem = new SnackbarSystem( dispatch );

		useEffect( () => {
			const userNeedsToSubmitRoles = currentUser && !currentUser.role && !currentUser.isGuest;

			if ( isLoggedIn && userNeedsToSubmitRoles && !hasRoleSurveyPopupBeenOpened ) {
				PopupFactory.roleSurveyPopup( { popupSystem, snackbarSystem, user: currentUser } );
				setRoleSurveyPopupBeenOpened( true );
			}
		}, [ isLoggedIn, currentUser ] );

		return <Component {...props} />;
	}
);

export default withGlobalModals;
