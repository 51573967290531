export default class IsUniqueValidator {
	constructor( others ) {
		this.others = others;
	}

	validate( value, label, errors ) {
		if ( !this.others.includes( value ) ) { return true; }
		errors.push( `This ${label} already exists` );
		return false;
	}
}
