import PropTypes from 'prop-types';
import Icon from '../../new_arch/components/Icon/Icon';
import VeryBusyLogo from '../../components/VeryBusyLogo/VeryBusyLogo';
import IconButton from '../../components/UI/IconButton/IconButton';

import './MaintenanceScreen.scss';

const MaintenanceScreen = ( { showCloseButton, onClose } ) => (
	<div className="MaintenanceScreen">
		<VeryBusyLogo inBlue />
		<div className="message-and-icon">
			<Icon icon="wrench" size={106} />
			<div className="message">
				<h2>
					We are under maintenance.
					<br />
					We will be back real soon!
				</h2>
				<h4>
					We are making VeryBusy better for you.
				</h4>
				<h4>
					(Your files are safe) and this shouldn’t take too long, so please come back shortly.
				</h4>
			</div>
		</div>
		{showCloseButton && <IconButton onClick={onClose} icon="close" size={30} />}
	</div>
);

MaintenanceScreen.propTypes = {
	showCloseButton: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired
};

export default MaintenanceScreen;
