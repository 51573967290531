import PropTypes from 'prop-types';

import CloseableModalWithTitle from '../CloseableModalWithTitle/CloseableModalWithTitle';

import InviteProjectMemberBoxContainer from '../../containers/InviteProjectMemberBoxContainer/InviteProjectMemberBoxContainer';
import BaseModal from '../BaseModal';
import { trackNavigatedToProjectInviteModal } from '../../services/tracker/trackers/navigation';
import Tabs from '../../components/Tabs/Tabs';
import ShareReviewLink from '../../components/ShareReviewLink/ShareReviewLink';

import './InviteProjectMemberBoxModal.scss';
import Project from '../../entities/project';

const COLLABORATE_TAB = 0;
const tabs = [ { label: 'Collaborate' }, { label: 'View-Only' } ];

export class InviteProjectMemberBoxModal extends BaseModal {
	constructor( props ) {
		super( props );

		this.state = { currentTab: 0 };

		this._onTabChanged = this._onTabChanged.bind( this );
	}

	componentDidMount() {
		const currentTab = COLLABORATE_TAB;
		this.setState( { currentTab } );
		super.componentDidMount();
		this.props.fetchProjectMembers( this.props.projectID );
		trackNavigatedToProjectInviteModal();
	}

	_onTabChanged( currentTab ) {
		this.setState( { currentTab } );
	}

	render() {
		const { props: { shareable, onClose } } = this;

		return (
			<CloseableModalWithTitle
				headerBorderType="small"
				onClose={onClose}
				name="invite-project-member"
				title="Share Project"
			>
				<div className="InviteProjectMemberBoxModal">
					<Tabs
						tabs={tabs}
						tabSelected={this.state.currentTab}
						onTabChange={this._onTabChanged}
					/>
					{this.state.currentTab === COLLABORATE_TAB
						? (
							<InviteProjectMemberBoxContainer
								projectID={shareable.id}
								onClose={onClose}
								projectMemberRoleName="Collaborators"
								currentUserCanShareGuestLink={shareable.workspace?.currentUserCanShareGuestLink}
							/>
						) : (
							<ShareReviewLink shareable={shareable} />
						)}
				</div>
			</CloseableModalWithTitle>
		);
	}
}

InviteProjectMemberBoxModal.propTypes = {
	onClose: PropTypes.func,
	shareable: PropTypes.instanceOf( Project ),
	fetchProjectMembers: PropTypes.func
};

InviteProjectMemberBoxModal.defaultProps = {
	onClose: () => {},
	shareable: undefined,
	fetchProjectMembers: () => {}
};

export default InviteProjectMemberBoxModal;
