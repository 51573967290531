import PropTypes from 'prop-types';
import classNames from 'classnames';
import { stopPropagation } from '../../new_arch/lib/eventUtils';

import './FloatingBox.scss';

const FloatingBox = ( {
	children, showArrow, className, style
} ) => (
	<div
		style={style}
		className={classNames( 'FloatingBox bottom hide', className, { 'show-arrow': showArrow } )}
		onClick={stopPropagation}
	>
		{children}
	</div>
);

FloatingBox.propTypes = {
	children: PropTypes.node.isRequired,
	showArrow: PropTypes.bool,
	className: PropTypes.string,
	style: PropTypes.any
};

FloatingBox.defaultProps = {
	showArrow: true,
	className: undefined,
	style: {}
};

export default FloatingBox;
