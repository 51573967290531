import classNames from 'classnames';

type RoleBoxProps = {
	role: string,
	text: string,
	onSelect: ( role: string ) => void,
	isSelected: boolean
}

const RoleBox = ( { role, text, onSelect, isSelected } : RoleBoxProps ) => (
	<div
		className={classNames( 'RoleBox', { selected: isSelected } )}
		onClick={() => { onSelect( role ) }}
	>
		<span>{text}</span>
	</div>
);

export default RoleBox;
