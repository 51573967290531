import { ClipboardEvent, KeyboardEvent, useMemo, useState } from 'react';

import EmailChip from '../../../components/EmailChip/EmailChip';
import InputWithSuggestions from '../../../components/InputWithSuggestions/InputWithSuggestions';

import './EmailsInput.scss';

type EmailsInputProps = {
	emails: Array<{
		value: string,
		isValid: boolean
	}>
	onChange: ( newEmails: string[] ) => void
	label: string,
	placeholder: string,
};

const ENTER_KEY_CODE = 'Enter';
const SPACE_KEY_CODE = 'Space';
const TAB_KEY_CODE = 'Tab';

const extractEmailsFromText = ( text: string ) => text
	.split( /, +|,| +|\n/g )
	.map( email => email.trim() )
	.filter( email => email !== '' )
	.uniq()

const getClipboardEventText = <T, >( event: ClipboardEvent<T> ) => event.clipboardData?.getData( 'text/plain' ) || '';

const EmailsInput = ( {
	emails, onChange, label, placeholder
}: EmailsInputProps ) => {
	const [ currentInput, setCurrentInput ] = useState( '' );
	const emailValues = useMemo( () => emails.map( email => email.value ), [ emails ] );

	const feedInput = ( input: string ) => {
		const enteredEmails = extractEmailsFromText( input );
		onChange( [
			...emailValues,
			...enteredEmails.filter( email => !emailValues.includes( email ) )
		] );
	}

	const onKeyDown = ( event: KeyboardEvent<HTMLInputElement> ) => {
		if ( [ ENTER_KEY_CODE, SPACE_KEY_CODE, TAB_KEY_CODE ].includes( event.code ) ) {
			event.preventDefault();
			feedInput( currentInput );
			setCurrentInput( '' );
		}
	}

	const onPaste = ( event: ClipboardEvent<HTMLInputElement> ) => {
		event.preventDefault();
		feedInput( getClipboardEventText( event ) );
	}

	return (
		<div className="EmailsInput">
			<p className="label">{label}</p>
			<div className="input">
				{emails.map( ( { value, isValid } ) => (
					<EmailChip
						key={value}
						isValid={isValid}
						value={value}
						onDeleteEmail={() => onChange( emailValues.filter( v => v !== value ) )}
					/>
				) )}
				<InputWithSuggestions
					name="invite-email"
					autoComplete="invite-email"
					value={currentInput}
					onChange={event => setCurrentInput( event.target.value )}
					onKeyDown={onKeyDown}
					onPaste={onPaste}
					placeholder={emails.length === 0 ? placeholder : undefined}
				/>
			</div>
		</div>
	);
}

export default EmailsInput;
