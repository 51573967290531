import { Map } from 'immutable';

import { handleStart, handleSuccess, mapForPayload } from '../lib/reduxUtils';

import { FETCH_WORKSPACE_INVOICES, FETCH_WORKSPACE_INVOICES_NEXT_PAGE, LOGOUT } from '../actions/types';

export const workspaceInvoices = ( state = new Map(), action ) => {
	switch ( action.type ) {
	case FETCH_WORKSPACE_INVOICES:
		return handleSuccess(
			state,
			action,
			( ) => {
				const { invoices } = action.payload;

				return mapForPayload( invoices );
			}
		);
	case FETCH_WORKSPACE_INVOICES_NEXT_PAGE:
		return handleSuccess(
			state,
			action,
			( prevState ) => {
				const { workspaceID } = action.meta;
				const { invoices } = action.payload;

				return prevState
					.filter( invoice => invoice.workspace_id === workspaceID )
					.merge( mapForPayload( invoices ) );
			}
		);
	case LOGOUT:
		return handleStart( state, action, () => new Map() );
	default:
		return state;
	}
};

export const workspaceInvoicesPaginationData = ( state = new Map(), action ) => {
	switch ( action.type ) {
	case FETCH_WORKSPACE_INVOICES:
	case FETCH_WORKSPACE_INVOICES_NEXT_PAGE:
		return handleSuccess(
			state,
			action,
			( prevState ) => {
				const { workspaceID } = action.meta;
				const {
					last_id: lastInvoiceID,
					has_more: hasMore
				} = action.payload;
				return prevState
					.set( workspaceID, { lastInvoiceID, hasMore } );
			}
		);
	case LOGOUT:
		return handleStart( state, action, () => new Map() );
	default:
		return state;
	}
};
