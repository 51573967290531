import { Map } from 'immutable';

import { FETCH_WORKSPACE_PAYMENT_METHOD, UPDATE_WORKSPACE_PAYMENT_METHOD, LOGOUT } from '../actions/types';
import { handleStart, handleSuccess } from '../lib/reduxUtils';

export const creditCards = ( state = new Map(), action ) => {
	switch ( action.type ) {
	case FETCH_WORKSPACE_PAYMENT_METHOD:
	case UPDATE_WORKSPACE_PAYMENT_METHOD:
		return handleSuccess(
			state,
			action,
			( prevState ) => {
				const { workspaceID } = action.meta;
				return action.payload
					? prevState
						.filter( creditCard => creditCard.workspace_id !== workspaceID )
						.set(
							action.payload.id,
							{
								...action.payload,
								workspace_id: workspaceID
							}
						)
					: prevState;
			}
		);
	case LOGOUT:
		return handleStart( state, action, () => new Map() );
	default:
		return state;
	}
};
