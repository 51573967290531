import Filter from '../structures/filter';

export default class Filters {
	constructor( filters ) {
		this._filters = filters.compact().map( filter => new Filter( filter ) );
	}

	match( item ) {
		return this._filters.every( filter => filter.match( item ) );
	}

	apply( collection ) {
		return collection.compact().filter( item => this.match( item ) );
	}
}
