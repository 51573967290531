import Icon from '../../../../components/Icon/Icon';
import { ERROR } from '../../../../../styles/colors';

import './ErrorCard.scss'

const ErrorCard = ( { children } : { children: React.ReactNode } ) => (
	<div className="ErrorCard">
		<Icon icon="error" color={ERROR} />
		<p>{children}</p>
	</div>
);

export default ErrorCard;
