import uuid from 'uuid/v1';

import { PROJECTS_URL } from '../config/urls';
import {
	CREATE_PROJECT, FETCH_PROJECT, FETCH_ACTIVE_PROJECTS, FETCH_HIDDEN_PROJECTS,
	RESET_CREATE_PROJECT, CHANGE_PROJECT_NAME, DELETE_PROJECTS,
	RESET_DELETE_PROJECTS,
	ARCHIVE_PROJECTS,
	RESET_ARCHIVE_PROJECTS,
	UNARCHIVE_PROJECTS,
	RESET_UNARCHIVE_PROJECTS,
	SET_PROJECT_RUSH_PRIORITY, UNSET_PROJECT_RUSH_PRIORITY,
	MARK_PROJECT_AS_SEEN,
	UPDATE_PROJECT_SETTINGS,
	TRANSFER_OWNERSHIP,
	DOWNLOAD_PROJECT,
	TRANSFER_PROJECT_TO_WORKSPACE,
	LEAVE_PROJECTS,
	UNFOLD_ITEMS,
	FETCH_PROJECT_OVERVIEW,
	UPDATE_GUEST_HASH
} from './types';

import toggleItemRushPriority from '../lib/toggleItemRushPriority';
import { getAssetsUploadsForProject } from '../selectors/assetUploads';
import { cancelAssetUpload } from './assetUploads';
import events from '../services/tracker/events';
import { getCurrentUserMembersForSelectedProjects, getProject, getProjectName } from '../selectors/projects';
import {
	trackProjectCreated,
	trackProjectDownloaded,
	trackProjectDeleted,
	trackProjectHidden,
	trackProjectUnhidden
} from '../services/tracker/trackers/projects';
import { AssetsDownload } from '../entities/assetsDownload';
import assetDownloadService from '../services/AssetDownloadService';
import { trackGuestLinkSettingsChanged } from '../services/tracker/trackers/nac';
import QueryClientUpdater from '../new_arch/services/QueryClientUpdater';

const serializeProperties = ( {
	name,
	startDate,
	dueDate,
	assetsStartRetouched,
	workspaceID,
	inviteAllMembers,
	clientName
} = {} ) => ( {
	name,
	start_date: startDate ? startDate.toISOString() : null,
	due_date: dueDate ? dueDate.toISOString() : null,
	assets_start_retouched: assetsStartRetouched,
	workspace_id: workspaceID,
	invite_all_members: inviteAllMembers,
	client_name: clientName
} );

export const createProject = properties => ( dispatch, _, api ) => dispatch( {
	type: CREATE_PROJECT,
	promise: api
		.post( PROJECTS_URL, serializeProperties( properties ) )
		.then( ( response ) => {
			trackProjectCreated( properties.name );

			return ( {
				...response.response,
				has_new_comments: false,
				has_new_assets: false
			} );
		} )
} );

export const fetchProject = projectID => ( dispatch, _, api ) => dispatch( {
	type: FETCH_PROJECT,
	promise: api
		.get( `${PROJECTS_URL}/${projectID}` )
		.then( response => response.response ),
	meta: { projectID }
} );

export const fetchActiveProjects = () => ( dispatch, _, api ) => dispatch( {
	type: FETCH_ACTIVE_PROJECTS,
	promise: api
		.get( `${PROJECTS_URL}/active`, {}, { abortable: true } )
		.then( response => response.response )
} );

export const fetchHiddenProjects = () => ( dispatch, _, api ) => dispatch( {
	type: FETCH_HIDDEN_PROJECTS,
	promise: api
		.get( `${PROJECTS_URL}/archived`, {}, { abortable: true } )
		.then( response => response.response )
} );

export const fetchProjectOverview = projectID => ( dispatch, _, api ) => dispatch( {
	type: FETCH_PROJECT_OVERVIEW,
	promise: api.get( `${PROJECTS_URL}/${projectID}/overview` ),
	meta: { projectID }
} );

export const changeProjectName = ( projectID, newName ) => ( dispatch, getState, api ) => {
	const originalName = getProjectName( getState(), { projectID } );

	if ( originalName === newName ) { return Promise.resolve( undefined ); }

	return dispatch( {
		type: CHANGE_PROJECT_NAME,
		promise: api
			.put( `${PROJECTS_URL}/${projectID}`, { name: newName } )
			.then( response => response.response ),
		meta: { projectID, originalName, newName }
	} );
};

export const updateProject = ( projectID, settings ) => ( dispatch, _, api ) => dispatch( {
	type: UPDATE_PROJECT_SETTINGS,
	meta: {
		project: {
			id: projectID,
			...settings,
			cover_id: settings.cover_asset_id
		}
	},
	promise: api
		.patch( `${PROJECTS_URL}/${projectID}`, settings )
		.then( response => response.response )
} );

export const deleteProjects = projectsIDs => ( dispatch, getState, api ) => {
	projectsIDs.forEach( () => trackProjectDeleted() );
	const workspacesWithDeletedProjects = projectsIDs.map(
		projectID => getProject( getState(), { projectID } )?.workspaceID
	);

	return dispatch( {
		type: DELETE_PROJECTS,
		promise: api
			.delete( `${PROJECTS_URL}`, { ids: projectsIDs } )
			.then( () => {
				const state = getState();
				const projectsUploads = projectsIDs.reduce(
					( result, projectID ) => [ ...result, ...getAssetsUploadsForProject( state, { projectID } ) ], []
				);

				projectsUploads.forEach( upload => dispatch( cancelAssetUpload( upload.id ) ) );

				return projectsIDs;
			} ),
		meta: { workspacesWithDeletedProjects }
	} );
};

export const resetCreateProjectRequest = () => ( {
	type: RESET_CREATE_PROJECT
} );

export const resetDeleteProjectsRequest = () => ( {
	type: RESET_DELETE_PROJECTS
} );

export const archiveProjects = projectsIDs => ( dispatch, _, api ) => {
	projectsIDs.forEach( () => trackProjectHidden() );

	return dispatch( {
		type: ARCHIVE_PROJECTS,
		promise: api
			.post( `${PROJECTS_URL}/mark_as_archived`, { ids: projectsIDs } )
			.then( () => projectsIDs )
	} );
};

export const resetArchiveProjectsRequest = () => ( {
	type: RESET_ARCHIVE_PROJECTS
} );

export const unarchiveProjects = projectsIDs => ( dispatch, _, api ) => {
	projectsIDs.forEach( () => trackProjectUnhidden() );

	return dispatch( {
		type: UNARCHIVE_PROJECTS,
		promise: api
			.post( `${PROJECTS_URL}/mark_as_active`, { ids: projectsIDs } )
			.then( () => projectsIDs )
	} );
};

export const resetUnarchiveProjectsRequest = () => ( {
	type: RESET_UNARCHIVE_PROJECTS
} );

const toggleProjectRushPriority = ( projectID, hasRushPriority ) => toggleItemRushPriority(
	getProject,
	projectID,
	hasRushPriority,
	PROJECTS_URL,
	'projectID',
	SET_PROJECT_RUSH_PRIORITY,
	UNSET_PROJECT_RUSH_PRIORITY,
	'trackProjectAction',
	events.project.PROJECT_FLAGGED,
	events.project.PROJECT_UNFLAGGED,
	'name'
);

export const setProjectRushPriority = projectID => toggleProjectRushPriority( projectID, true );
export const unsetProjectRushPriority = projectID => toggleProjectRushPriority( projectID, false );

export const markProjectAsSeen = projectID => ( dispatch, _, api ) => dispatch( {
	type: MARK_PROJECT_AS_SEEN,
	promise: api
		.post( `${PROJECTS_URL}/${projectID}/update_project_last_seen` )
		.then( () => projectID )
} );

export const transferOwnership = (
	projectID, prevOwnerID, newOwnerID, message
) => ( dispatch, _, api ) => {
	const parameters = { member_id: newOwnerID, message };

	return dispatch( {
		type: TRANSFER_OWNERSHIP,
		promise: api
			.post( `${PROJECTS_URL}/${projectID}/transfer_ownership`, parameters ),
		meta: { projectID, prevOwnerID, newOwnerID }
	} );
};

export const downloadProject = projectID => ( dispatch, _, api ) => {
	const downloadID = uuid();

	return dispatch( {
		type: DOWNLOAD_PROJECT,
		promise: api
			.post( `${PROJECTS_URL}/${projectID}/download` )
			.then( ( downloadJson ) => {
				const download = AssetsDownload.handle( downloadJson, assetDownloadService );

				trackProjectDownloaded();

				return download;
			} ),
		meta: { downloadID }
	} );
};

export const transferProjectToWorkspace = ( {
	workspaceID, projectID
} ) => ( dispatch, getState, api ) => {
	const project = getProject( getState(), { projectID } );
	const oldTeamWorkspace = project && project.workspaceID;

	return dispatch( {
		type: TRANSFER_PROJECT_TO_WORKSPACE,
		promise: api
			.post(
				`${PROJECTS_URL}/${projectID}/transfer_to_workspace`,
				{ workspace_id: workspaceID }
			),
		meta: {
			newTeamWorkspace: workspaceID,
			oldTeamWorkspace,
			projectID
		}
	} );
};

export const leaveProjects = projectIDs => ( dispatch, getState, api ) => dispatch( {
	type: LEAVE_PROJECTS,
	promise: api.post(
		`${PROJECTS_URL}/leave`,
		{ ids: projectIDs }
	).then( () => projectIDs ),
	meta: {
		memberIDs: getCurrentUserMembersForSelectedProjects(
			getState(),
			{ selectedProjectIDs: projectIDs }
		).map( member => member.id )
	}
} );

export const unfoldItems = ( { assets, folders } ) => {
	const { projectID } = assets[ 0 ] || folders[ 0 ];
	const assetIDs = assets.map( asset => asset.id );
	const folderIDs = folders.map( folder => folder.id );

	return ( dispatch, _, api ) => dispatch( {
		type: UNFOLD_ITEMS,
		promise: api.post(
			`${PROJECTS_URL}/${projectID}/unfold_items`,
			{ assets_ids: assetIDs, folders_ids: folderIDs }
		).then( ( response ) => {
			const duplicatedItemNames = response?.meta?.duplicated_names || [];

			const notDuplicatedAssets = assets.filter( asset => !duplicatedItemNames.includes( asset.name ) );
			const notDuplicatedAssetIDs = notDuplicatedAssets.map( asset => asset.id );

			const notDuplicatedFolders = folders.filter( folder => !duplicatedItemNames.includes( folder.name ) );
			const notDuplicatedFolderIDs = notDuplicatedFolders.map( folder => folder.id );

			QueryClientUpdater.itemsWereUnfolded( {
				assets: notDuplicatedAssets, folders: notDuplicatedFolders
			} );

			return { ...response, notDuplicatedAssetIDs, notDuplicatedFolderIDs };
		} ),
		meta: { assetIDs, folderIDs }
	} );
};

export const updateGuestHashEnabled = ( projectID, enabled ) => ( dispatch, _, api ) => dispatch( {
	type: UPDATE_GUEST_HASH,
	meta: { projectID, enabled },
	promise: api
		.put( `${PROJECTS_URL}/${projectID}/update_guest_hash`, { enabled } )
		.then( ( { response } ) => {
			trackGuestLinkSettingsChanged( { projectID, enabled } );
			return response;
		} )
} );
