import { countries } from 'countries-list';

export default class WorkspaceBillingInformation {
	constructor( country, state, postalCode, address, city, companyName ) {
		this.country = country;
		this.state = state;
		this.postalCode = postalCode;
		this.address = address;
		this.city = city;
		this.companyName = companyName;
	}

	static fromJSON( {
		country,
		state,
		postal_code: postalCode,
		address,
		city,
		company_name: companyName
	} ) {
		return new WorkspaceBillingInformation(
			country,
			state,
			postalCode,
			address,
			city,
			companyName
		);
	}

	get countryName() {
		const country = countries[ this.country ];
		return country ? country.name : undefined;
	}
}
