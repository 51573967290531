import { useEffect } from 'react';
import PropTypes from 'prop-types';

import Workspace from '../../entities/workspace';
import { connectComponent } from '../../lib/connectComponent';
import { getWorkspace, getWorkspacePanelTabsForWorkspaceAndCurrentUser } from '../../selectors/workspaces';

import WorkspaceSettingsContainer from '../WorkspaceSettingsContainer/WorkspaceSettingsContainer';
import BillingSettingsContainer from '../BillingSettingsContainer/BillingSettingsContainer';
import PlanSettingsContainer from '../PlanSettingsContainer/PlanSettingsContainer';
import WorkspaceFullSettingsPanel from '../../components/WorkspaceFullSettingsPanel/WorkspaceFullSettingsPanel';
import useTabsWithRoutes from '../../hooks/useTabsWithRoutes';
import WorkspaceProjectsTab from '../../components/WorkspaceProjectsTab/WorkspaceProjectsTab';
import WorkspaceMemberListContainer from '../WorkspaceMemberListContainer/WorkspaceMemberListContainer';

export const WORKSPACE_PANEL_TABS = [
	{
		name: 'Plan', component: PlanSettingsContainer, onlyOwner: true, key: 'plan'
	},
	{
		name: 'Billing', component: BillingSettingsContainer, onlyOwner: true, key: 'billing'
	},
	{
		name: 'Projects', component: WorkspaceProjectsTab, onlyOwner: true, key: 'projects'
	},
	{
		name: 'Members', component: WorkspaceMemberListContainer, key: 'members'
	},
	{
		name: 'General', component: WorkspaceSettingsContainer, key: 'general'
	}
];

export const WorkspaceFullSettingsPanelContainer = ( {
	workspace,
	tabs,
	fetchWorkspaceSettingsInformation
} ) => {
	const [ availableTabs, selectedTabIndex, onTabChanged ] = useTabsWithRoutes(
		tabs, 'section', `/user/workspaces/${workspace.id}`
	);

	useEffect( () => {
		if ( workspace ) {
			fetchWorkspaceSettingsInformation( workspace );
		}
	}, [ workspace.id ] );

	return (
		<WorkspaceFullSettingsPanel
			workspace={workspace}
			tabs={availableTabs}
			selectedTabIndex={selectedTabIndex}
			onTabChange={onTabChanged}
		/>
	);
};

WorkspaceFullSettingsPanelContainer.propTypes = {
	workspace: PropTypes.instanceOf( Workspace ).isRequired,
	tabs: PropTypes.arrayOf( PropTypes.shape( {
		name: PropTypes.string.isRequired,
		component: PropTypes.any.isRequired,
		onlyOwner: PropTypes.bool
	} ) ),
	fetchWorkspaceSettingsInformation: PropTypes.func
};

WorkspaceFullSettingsPanelContainer.defaultProps = {
	tabs: [],
	fetchWorkspaceSettingsInformation: () => {}
};

export default connectComponent( ( state, props ) => ( {
	workspace: getWorkspace( state, props ),
	tabs: getWorkspacePanelTabsForWorkspaceAndCurrentUser(
		state,
		{ ...props, tabs: WORKSPACE_PANEL_TABS }
	)
} ) )( WorkspaceFullSettingsPanelContainer );
