import { SET_ASSET_DOWNLOAD_RESOLUTION, SET_IS_2FA_ENABLED } from '../actions/types';
import { USER_PREFERENCES } from '../config/urls';
import { getCurrentUserPreferences } from '../selectors/userPreferences';

export default class UserPreferencesSystem {
	constructor( dispatch ) {
		this.dispatch = dispatch;
	}

	enable2FA() {
		return this._setIs2FAEnabled( true );
	}

	disable2FA() {
		return this._setIs2FAEnabled( false );
	}

	setAssetDownloadResolution( resolution ) {
		return this.dispatch( ( dispatch, getState, api ) => dispatch( {
			type: SET_ASSET_DOWNLOAD_RESOLUTION,
			promise: this._patchCurrentUserPreferences( {
				api,
				getState,
				newPreferences: { asset_downloads_resolution: resolution }
			} )
		} ) );
	}

	_setIs2FAEnabled( value ) {
		return this.dispatch( ( dispatch, getState, api ) => dispatch( {
			type: SET_IS_2FA_ENABLED,
			promise: this._patchCurrentUserPreferences( {
				api,
				getState,
				newPreferences: { is_2fa_enabled: value }
			} )
		} ) );
	}

	_patchCurrentUserPreferences( { api, getState, newPreferences } ) {
		const { id } = getCurrentUserPreferences( getState() );
		return api.patch(
			`${USER_PREFERENCES}/${id}`, { user_preference: newPreferences }
		).then( ( { response } ) => response );
	}
}
