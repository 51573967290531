import AddOn from '../../entities/addOn';
import Workspace from '../../entities/workspace';

type InviteWorkspaceMemberBillingConfBoxPresenterParams = {
	workspace: Workspace,
	addOn: AddOn,
	emails: string[]
};

export default class InviteWorkspaceMemberBillingConfBoxPresenter {
	workspace: Workspace;
	addOn: AddOn;
	emails: string[];

	constructor( { workspace, addOn, emails } : InviteWorkspaceMemberBillingConfBoxPresenterParams ) {
		this.workspace = workspace;
		this.addOn = addOn;
		this.emails = emails;
	}

	get newMembersText() {
		return this.membersToAdd === 1
			? '1 new workspace member'
			: `${this.membersToAdd} new workspace members`;
	}

	get remainingSeatsText() {
		return this.remainingSeats === 1
			? '1 open workspace seat'
			: `${this.remainingSeats} open workspace seats`;
	}

	get billingInterval() {
		return this.workspace.hasYearlySubscriptionInterval ? 'annually' : 'monthly';
	}

	get cost() {
		return ( this.unitaryCost * this.seatsToAdd ).toFixed( 2 );
	}

	private get unitaryCost() {
		return this.workspace.hasYearlySubscriptionInterval
			? this.addOn.annualPriceCost
			: this.addOn.monthlyPriceCost;
	}

	private get seatsToAdd() {
		return this.membersToAdd - this.remainingSeats;
	}

	get remainingSeats() {
		return this.workspace.remainingSeats;
	}

	get membersToAdd() {
		return this.emails.length;
	}
}
