import { createSelector } from 'reselect';
import EditorMarkup from '../entities/editorMarkup';
import { getDisplayComments } from './commentsFilterOptions';

const filterMarkupWithComments = comments => comments
	.map( comment => comment.markup && EditorMarkup.fromMarkup(
		comment.markup,
		comment.wasMarkedAsCompleted
	) )
	.filter( markup => !!markup );

export const getMarkupsForSelectedAssetRound = createSelector(
	[ getDisplayComments ],
	comments => filterMarkupWithComments( comments )
);

export const getShapesFromComment = comment => ( comment && comment.hasMarkup
	? comment.markup.shapes
	: null );

export const getIsDrawingMarkup = createSelector(
	[ state => state.markupEditorCurrentDrawingShapes ],
	shapes => !!shapes
);

export const getSelectedAssetRoundHasMarkups = createSelector(
	[ getMarkupsForSelectedAssetRound ],
	markups => markups?.length > 0
);
