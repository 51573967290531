import * as colors from '../styles/colors';

export default class ColorView {
	constructor( { base, hovered = base } ) {
		this.base = base;
		this.hovered = hovered;
	}

	static fromColorName( name ) {
		return new ColorView( {
			base: colors[ `${name.toUpperCase()}_BASE` ],
			hovered: colors[ `${name.toUpperCase()}_600` ]
		} );
	}
}
