import { showDialogWithIcon } from '../actions/popups';
import history from '../navigation/history';
import { getWorkspace } from '../selectors/workspaces';
import { store } from '../store/store';
import { textEndEllipsis } from './textTruncate';
import { isNotAllowedError, isNotAllowedOrNotFound } from '../errors/common';
import { fetchCurrentUserWorkspaces } from '../actions/workspaces';

export const catchNotAllowedResponseWrapper = (
	promise,
	dispatch,
	workspaceID
) => promise.catch( ( error ) => {
	if ( !isNotAllowedOrNotFound( error ) ) { throw error; }
	const workspace = getWorkspace( store.getState(), { workspaceID } );
	const contactWorkspaceOwnerMessage = workspace
		? `Contact the workspace owner for more information.\n\n${textEndEllipsis( workspace.owner.email, 40 )}`
		: '';

	return dispatch( showDialogWithIcon(
		'Oops, something went wrong',
		`Looks like you can't continue working on this workspace right now. ${contactWorkspaceOwnerMessage}`
	) ).then( () => {
		if ( isNotAllowedError( error ) ) { dispatch( fetchCurrentUserWorkspaces() ); }
		history.replace( '/user/profile' );
		throw error;
	} );
} );
