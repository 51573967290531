import PropTypes from 'prop-types';

import './Tabs.scss';
import Tab from '../Tab/Tab';

/* eslint-disable */

const Tabs = ( { tabs, tabSelected, onTabChange, withArrowPointer } ) => (
	<ul className="Tabs">
		{ tabs.map( ( {label, icon, iconColor}, tabIndex ) => (
			<Tab
				className={`Tab-${label}`}
				key={tabIndex}
				label={label}
				selected={tabIndex === tabSelected}
				onClick={() => onTabChange( tabIndex )}
				withArrowPointer={withArrowPointer}
				icon={icon}
				iconColor={iconColor}
			/>
		) ) }
	</ul>
);

Tabs.propTypes = {
	tabs: PropTypes.arrayOf( PropTypes.shape( {
		label: PropTypes.string.isRequired,
		icon: PropTypes.string,
		iconColor: PropTypes.string
	} ) ).isRequired,
	tabSelected: PropTypes.number,
	onTabChange: PropTypes.func,
	withArrowPointer: PropTypes.bool
};

Tabs.defaultProps = {
	tabSelected: 0,
	onTabChange: () => {},
	withArrowPointer: false
};

export default Tabs;
