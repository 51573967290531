import { actionResultIsError } from '../../lib/reduxUtils';

export default class AsyncActionPresenter {
	constructor( {
		action,
		confirmationPopup,
		successMessage,
		errorMessage,
		resetAction,
		onSuccess,
		onError,
		onStart,
		onFinish
	} ) {
		this.action = action;
		this.confirmationPopup = confirmationPopup;
		this.successMessage = successMessage;
		this.errorMessage = errorMessage;
		this.resetAction = resetAction;
		this.onSuccess = onSuccess;
		this.onError = onError;
		this.onStart = onStart;
		this.onFinish = onFinish;
	}

	async trigger() {
		const runAction = !this.confirmationPopup || await this.confirmationPopup();
		if ( !runAction ) return undefined;
		return this._handleAction();
	}

	_handleAction() {
		this.onStart?.();
		return this.action().then( ( result ) => {
			if ( !actionResultIsError( result ) ) {
				this.successMessage?.( result );
				this.resetAction?.();
				this.onSuccess?.( result );
			} else {
				this.errorMessage?.( result );
				this.onError?.( result );
			}
			return result;
		} ).finally( () => this.onFinish?.() );
	}
}
