import { makeAutoObservable } from 'mobx';
import { SUBSCRIPTION_MODAL_KEY } from '../../systems/ModalSystem';

export default class UpgradeButtonPresenter {
	constructor( { workspaces, modalSystem } ) {
		this.workspaces = workspaces;
		this.modalSystem = modalSystem;

		this.subscriptionModalIsOpen = false;
		makeAutoObservable( this );
	}

	openSubscriptionModal = () => {
		this.modalSystem.showModal( { modalKey: SUBSCRIPTION_MODAL_KEY } );
	};

	get daysLeft() {
		return this._currentUserWorkspace?.daysLeftOnFreeTrial;
	}

	get showUpgradeButton() {
		return !!this._currentUserWorkspace && !this.currentUserIsOnActivePaidWorkspace;
	}

	get currentUserIsOnActivePaidWorkspace() {
		return this.workspaces?.some( workspace => workspace.hasAPaidPlan && workspace.isActive )
	}

	get showWarningIcon() {
		return this.daysLeft <= 7;
	}

	get isOnExpiredFreeTrial() {
		return this._currentUserWorkspace?.isOnExpiredFreeTrial;
	}

	get _currentUserWorkspace() {
		return this.workspaces?.find( workspace => workspace.currentUserIsOwner );
	}
}
