import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actionsCreator from '../actions';
import systems from '../systems';

const bindSystems = dispatch => Object.keys( systems ).reduce(
	( systemsWithDispatch, systemName ) => ( {
		...systemsWithDispatch,
		[ systemName ]: new systems[ systemName ]( dispatch )
	} ),
	{}
);

const mapDispatchToProps = dispatch => ( {
	...bindSystems( dispatch ),
	...bindActionCreators( actionsCreator, dispatch )
} );

export const connectComponent = ( mapStateToProps, forwardRef ) => connect(
	mapStateToProps, mapDispatchToProps, null, { forwardRef }
);
