import PropTypes from 'prop-types';
import BreadcrumbLevel from '../../structures/breadcrumbLevel';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import CollapsedBreadcrumbs from '../CollapsedBreadcrumbs/CollapsedBreadcrumbs';
import useCollapsedBreadcrumbs from './useCollapsableBreadcrumbs';

const CollapsableBreadcrumbs = ( { breadcrumbs, hideFirstArrow } ) => {
	const {
		firstBreadcrumb,
		collapsedBreadcrumbs,
		uncollapsedBreadcrumbs
	} = useCollapsedBreadcrumbs( breadcrumbs );

	return (
		<div className="CollapsableBreadcrumbs">
			<Breadcrumb breadcrumb={firstBreadcrumb} bold withDividingArrow={!hideFirstArrow} />
			{collapsedBreadcrumbs.length > 0
			&& <CollapsedBreadcrumbs breadcrumbs={collapsedBreadcrumbs} />}
			{uncollapsedBreadcrumbs.map( breadcrumb => (
				<Breadcrumb key={breadcrumb.name} breadcrumb={breadcrumb} />
			) )}
		</div>
	);
};

CollapsableBreadcrumbs.propTypes = {
	breadcrumbs: PropTypes.arrayOf( PropTypes.instanceOf( BreadcrumbLevel ) ).isRequired,
	hideFirstArrow: PropTypes.bool
};

CollapsableBreadcrumbs.defaultProps = {
	hideFirstArrow: false
};

export default CollapsableBreadcrumbs;
