import { makeAutoObservable } from 'mobx';
import UserPreferences from '../../entities/userPreferences';

export default class DownloadItemsPopupPresenter {
	constructor( {
		assetSystem, projectSystem, snackbarSystem, userPreferencesSystem,
		assets, folders, onConfirm, onCancel, currentUser, downloadAssetPresenterFactory,
		canDownloadPreviousRounds
	} ) {
		this._assetSystem = assetSystem;
		this._projectSystem = projectSystem;
		this._snackbarSystem = snackbarSystem;
		this._userPreferencesSystem = userPreferencesSystem;
		this.assets = assets;
		this.folders = folders ?? [];
		this._onConfirm = onConfirm;
		this._onCancel = onCancel;
		this.downloadingAllRounds = false;
		this._appendingRoundNumber = false;
		this.isLoading = false;
		this.currentUser = currentUser;
		this._downloadAssetPresenterFactory = downloadAssetPresenterFactory;
		this._canDownloadPreviousRounds = canDownloadPreviousRounds;
		this._imageResolutionSelected = this.currentUser?.preferedAssetDownloadsResolution
			?? UserPreferences.assetDownloadsResolutions.original;

		makeAutoObservable( this );
	}

	get appendingRoundNumber() {
		return this._appendingRoundNumber || this.downloadingAllRounds;
	}

	get imageResolutionOptions() {
		return [
			{ value: UserPreferences.assetDownloadsResolutions.original, label: 'Original' },
			{ value: UserPreferences.assetDownloadsResolutions.lowRes, label: 'Low res image' }
		];
	}

	get withDownloadAllRoundsOption() {
		return this.downloadingOneAsset && this.assets.first.roundsCount > 1
			&& this._canDownloadPreviousRounds;
	}

	get downloadingOneAsset() {
		return this.assets.length === 1 && this.folders?.length === 0;
	}

	get selectedImageResolution() {
		return this.imageResolutionOptions.find(
			option => option.value === this._imageResolutionSelected
		);
	}

	modalWasClosed = () => {
		this._onCancel();
	}

	downloadWasClicked = () => {
		this._onDownloadStart();
		if ( this.downloadingOneAsset ) { return this._downloadAsset(); }
		return this._downloadAssets();
	}

	downloadAllRoundsWasSwitched = () => {
		this.downloadingAllRounds = !this.downloadingAllRounds;
	}

	appendRoundNumberWasSwitched = () => {
		this._appendingRoundNumber = !this._appendingRoundNumber;
	}

	imageResolutionWasChanged = ( newImageResolution ) => {
		this._imageResolutionSelected = newImageResolution.value;
		this._userPreferencesSystem.setAssetDownloadResolution( newImageResolution.value );
	}

	_onDownloadStart = () => {
		this.isLoading = true;
	}

	_onDownloadFinish = () => {
		this.isLoading = false;
		this._onConfirm();
	}

	_downloadAsset() {
		this._downloadAssetPresenterFactory.forDownloadAsset( {
			asset: this.assets.first,
			assetSystem: this._assetSystem,
			snackbarSystem: this._snackbarSystem,
			withRoundNumber: this.appendingRoundNumber,
			allRounds: this.downloadingAllRounds,
			lowRes: this._downloadingLowResImage,
			onFinish: this._onDownloadFinish
		} ).trigger();
	}

	_downloadAssets() {
		this._downloadAssetPresenterFactory.forDownloadAssets( {
			assets: this.assets,
			folders: this.folders,
			lowRes: this._downloadingLowResImage,
			withRoundNumber: this.appendingRoundNumber,
			assetSystem: this._assetSystem,
			projectSystem: this._projectSystem,
			snackbarSystem: this._snackbarSystem,
			onFinish: this._onDownloadFinish
		} ).trigger();
	}

	get _projectID() {
		return this.assets.first.projectID;
	}

	get _assetIDs() {
		return this.assets.map( asset => asset.id );
	}

	get _downloadingLowResImage() {
		return this.selectedImageResolution.value === UserPreferences.assetDownloadsResolutions.lowRes;
	}
}
