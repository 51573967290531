import { useEffect } from 'react';
import PropTypes from 'prop-types';
import SubscriptionDetail from '../../components/subscription/SubscriptionDetail/SubscriptionDetail';
import { trackNavigatedToWorkspaceBilling } from '../../services/tracker/trackers/navigation';
import usePlanSettingsPresenter from '../../presenters/PlanSettings/usePlanSettingsPresenter';

export const PlanSettingsContainer = ( { workspaceID } ) => {
	useEffect( () => trackNavigatedToWorkspaceBilling(), [] );
	const presenter = usePlanSettingsPresenter( { workspaceID } );
	return (
		<>
			<SubscriptionDetail
				presenter={presenter}
			/>
		</>
	);
};

PlanSettingsContainer.propTypes = {
	workspaceID: PropTypes.number.isRequired
};

export default PlanSettingsContainer;
