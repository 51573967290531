import Form from '../lib/forms/Form';
import Field from '../lib/forms/Field';
import NotEmptyValidator from '../lib/forms/validators/NotEmptyValidator';
import IsWorkspaceActiveValidator from '../lib/forms/validators/IsWorkspaceActiveValidator';

export const createProjectForm = (
	values = {}, workspaces = [], skipValidationForValues = []
) => new Form( [
	new Field( 'name', 'Name', values.name, [ new NotEmptyValidator() ] ),
	new Field( 'startDate', 'Start Date', values.startDate, [] ),
	new Field( 'dueDate', 'End Date', values.dueDate, [] ),
	new Field( 'assetsStartRetouched', 'Where are you starting?', values.assetsStartRetouched, [] ),
	new Field( 'workspaceID', 'Workspace', values.workspaceID, [ new IsWorkspaceActiveValidator( workspaces, skipValidationForValues ), new NotEmptyValidator() ] ),
	new Field( 'clientName', 'Client Name', values.clientName, [ ] )
] );

export const createProjectWithoutWorkspaceForm = ( values = {} ) => new Form( [
	new Field( 'name', 'Name', values.name, [ new NotEmptyValidator() ] ),
	new Field( 'startDate', 'Start Date', values.startDate, [] ),
	new Field( 'dueDate', 'End Date', values.dueDate, [] ),
	new Field( 'assetsStartRetouched', 'Where are you starting?', values.assetsStartRetouched, [] ),
	new Field( 'workspaceID', 'Workspace', values.workspaceID, [] ),
	new Field( 'clientName', 'Client Name', values.clientName, [ ] )
] );
