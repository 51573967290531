import { useState } from 'react';
import SnackbarSystem from '../../../systems/SnackbarSystem';
import useUpdateCurrentUserRole from './useUpdateCurrentUserRole';

type useRoleSurveyPopupProps = {
	userId: number,
	snackbarSystem: SnackbarSystem,
	onClose: () => void
}

const useRoleSurveyPopup = ( { userId, snackbarSystem, onClose } : useRoleSurveyPopupProps ) => {
	const [ selectedRole, setSelectedRole ] = useState<string>( '' );

	const onSelect = ( role: string ) => {
		setSelectedRole( prevSelectedRole => ( prevSelectedRole === role ? '' : role ) );
	};

	const { mutate, isLoading } = useUpdateCurrentUserRole();

	const onConfirm = () => {
		mutate( { selectedRole, userId },
			{
				onError: () => snackbarSystem.showErrorMessage( {
					title: 'Unknown Error',
					description: 'There was an error while saving your role.'
				} ),
				onSettled: onClose
			}
		);
	};

	return {
		selectedRole,
		onSelect,
		onConfirm,
		isLoading
	};
}

export default useRoleSurveyPopup;
