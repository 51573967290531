import { useState } from 'react';
import PropTypes from 'prop-types';

import RadioButton from '../RadioButton/RadioButton';

import './RadioGroup.scss';

const RadioGroup = ( {
	options, onOptionSelect
} ) => {
	const [ selectedOption, setSelectedOption ] = useState( 0 );

	const onOptionClicked = ( optionIndex, optionID ) => {
		setSelectedOption( optionIndex );
		onOptionSelect( optionID );
	};

	return (
		<div className="RadioGroup">
			{options.map( ( option, index ) => (
				<div className="option-container" key={option.title}>
					<RadioButton
						selected={selectedOption === index}
						onClick={() => onOptionClicked( index, option.id )}
					/>
					<div className="texts">
						<p className="title">{option.title}</p>
						<p className="subtitle">{option.subtitle}</p>
					</div>
				</div>
			) )}
		</div>
	);
};

RadioGroup.propTypes = {
	options: PropTypes.arrayOf(
		{ id: PropTypes.string, title: PropTypes.string, subtitle: PropTypes.string }
	).isRequired,
	onOptionSelect: PropTypes.func
};

RadioGroup.defaultProps = {
	onOptionSelect: () => {}
};

export default RadioGroup;
