export default class MaxLengthValidator {
	constructor( length ) {
		this.length = length;
	}

	validate( value, label, errors ) {
		if ( !value || value?.length <= this.length ) { return true; }
		errors.push( `${label} should be up to ${this.length} characters long.` );
		return false;
	}
}
