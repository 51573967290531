/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeAutoObservable } from 'mobx';
import WorkspaceSystem from '../../systems/WorkspaceSystem';
import { validateEmail } from '../../validators/emailValidator';
import SnackbarSystem from '../../systems/SnackbarSystem';
import { isErrorWithStatus } from '../../errors';
import ContactGroupCreateStrategy from './strategies/ContactGroupCreateStrategy';
import ContactGroupEditStrategy from './strategies/ContactGroupEditStrategy';
import { type ContactGroup } from '../../types/contactGroup';

export type CreateOrEditContactGroupModalPresenterParams = {
	workspaceID: number,
	mode: 'create' | 'edit',
	contactGroup?: ContactGroup,
	onClose: () => void,
	workspaceSystem: WorkspaceSystem;
	snackbarSystem: SnackbarSystem;
}

export default class CreateOrEditContactGroupModalPresenter {
	name: string;
	emails: Array<{ value: string, isValid: boolean }>;
	isSubmitting: boolean;
	error: string;

	private onClose: () => void;
	private snackbarSystem: SnackbarSystem;
	private strategy: ContactGroupCreateStrategy | ContactGroupEditStrategy;

	constructor( {
		workspaceID,
		mode,
		contactGroup,
		onClose,
		workspaceSystem,
		snackbarSystem
	}: CreateOrEditContactGroupModalPresenterParams ) {
		this.onClose = onClose;
		this.snackbarSystem = snackbarSystem;
		this.strategy = mode === 'create'
			? new ContactGroupCreateStrategy( { workspaceID, workspaceSystem } )
			: new ContactGroupEditStrategy( { workspaceID, contactGroup, workspaceSystem } );

		this.name = this.strategy.initialName;
		this.emails = this.strategy.initialEmails.map(
			( email: string ) => ( { value: email, isValid: true } )
		);
		this.isSubmitting = false;
		this.error = '';

		makeAutoObservable( this );
	}

	get title() {
		return this.strategy.title;
	}

	get submitButtonLabel() {
		return this.strategy.submitButtonLabel;
	}

	get isSubmitButtonDisabled() {
		return !this.name.trim() || !this.emailsAreValid || this.isSubmitting;
	}

	onNameChanged = ( newName: string ) => {
		this.name = newName;
	}

	onEmailsChanged = ( newEmailValues: string[] ) => {
		this.emails = newEmailValues.map( value => ( {
			value,
			isValid: validateEmail( value )
		} ) );
	}

	onSubmit = () => {
		this.setIsSubmitting( true );
		this.strategy.makeContactGroupRequest(
			this.name,
			this.emails.map( email => email.value )
		)
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
			.then( ( response: any ) => {
				if ( !response.error ) {
					this.snackbarSystem.showSuccessMessage( { title: this.strategy.successMessage } );
					this.onClose();
				} else if ( isErrorWithStatus( 422 )( response.payload ) ) {
					this.setError( 'This name is used in another group. Please choose another one.' );
				} else {
					this.setError( 'Something went wrong. Please try again.' );
				}
			} )
			.finally( () => this.setIsSubmitting( false ) );
	}

	private get emailsAreValid() {
		return this.emails.length > 0 && this.emails.every( email => email.isValid )
	}

	private setError( error: string ) {
		this.error = error;
	}

	private setIsSubmitting( isSubmitting: boolean ) {
		this.isSubmitting = isSubmitting;
	}
}
