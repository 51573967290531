import Field from './Field';

export default class Form {
	constructor( fields ) {
		this.fields = fields;
	}

	get data() {
		return this.fields.reduce( ( result, field ) => ( {
			...result,
			[ field.name ]: field.value
		} ), {} );
	}

	get errors() {
		return this.fields.reduce( ( result, field ) => ( {
			...result,
			...(
				field.errors && field.errors.length > 0
					? { [ field.name ]: field.errors }
					: {}
			)
		} ), {} );
	}

	addField( {
		name, label, value, validators
	} ) {
		return new Form( this.fields.concat( new Field( name, label, value, validators ) ) );
	}

	set( fieldName, value ) {
		const fields = this.fields.reduce( ( result, field ) => [
			...result,
			( field.name === fieldName ? field.setValue( value ) : field )
		], [] );

		return new Form( fields );
	}

	validate() {
		const { data } = this;

		return this.fields.reduce( ( result, field ) => {
			const validationResult = field.validate( data );
			return result && validationResult;
		}, true );
	}

	getOnlyEditedData( originalData ) {
		const { data } = this;

		return Object.keys( data ).reduce(
			( result, field ) => ( {
				...result,
				...(
					data[ field ] !== originalData[ field ]
						? { [ field ]: data[ field ] }
						: {}
				)
			} ),
			{}
		);
	}
}
