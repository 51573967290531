import PropTypes from 'prop-types';
import classNames from 'classnames';

import './WhiteBox.scss';

const WhiteBox = ( { children, className } ) => (
	<div className={classNames( 'WhiteBox', className )}>
		{children}
	</div>
);

WhiteBox.propTypes = {
	children: PropTypes.any,
	className: PropTypes.string
};

WhiteBox.defaultProps = {
	children: null,
	className: undefined
};

export default WhiteBox;
