import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import './CustomCard.scss';
import { IntercomAPI } from 'react-intercom';
import Button from '../../../new_arch/components/Button/Button';
import { SUPPORT_EMAIL } from '../../../config/email-addresses';
import openEmailClient from '../../../services/openEmailClient';

const CustomCard = () => {
	const [ showingIntercom ] = useState( false );

	return (
		<div className="CustomCard">
			<div className="title">
				<h2>Custom</h2>
			</div>
			<div className="customInfo">
				<h5>Need something different?</h5>
				<h5>Request a tailored proposal for you and your team.</h5>
				<h5><span onClick={() => openEmailClient( SUPPORT_EMAIL )}>{SUPPORT_EMAIL}</span></h5>
			</div>
			<Button size="large" onClick={() => IntercomAPI( showingIntercom ? 'hide' : 'show' )}>
				Contact us
			</Button>
		</div>
	);
};

export default observer( CustomCard );
