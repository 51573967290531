import DirectFileUpload, { DIRECT_FILE_UPLOAD_EVENTS } from '../DirectFileUpload';
import { FILE_UPLOAD_STATUS, FILE_UPLOAD_EVENTS } from './FilesUploaderConsts';

class FilesUploader {
	constructor() {
		this.uploads = [];
		this.eventListeners = [];
	}

	addEventListener( event, callback ) {
		this.eventListeners.push( { event, callback } );
	}

	removeEventListener( event, callback ) {
		this.eventListeners = this
			.eventListeners
			.filter(
				eventListener => eventListener.event !== event || eventListener.callback !== callback
			);
	}

	_handleEvent( eventName, params ) {
		this.eventListeners
			.filter( eventListener => eventListener.event === eventName )
			.forEach( eventListener => eventListener.callback( params ) );
	}

	upload( files ) {
		const uploads = files.map(
			file => DirectFileUpload.fromFile( file )
		);
		uploads.forEach( ( upload ) => {
			this._setUpUploadEvents( upload );
			upload.upload();
		} );
		this.uploads = this.uploads.concat( uploads );
		return uploads;
	}

	abortUpload( id ) {
		this.uploads.forEach( ( upload ) => {
			if ( upload.id === id ) { upload.abort(); }
		} );
	}

	abortUploads() {
		this.uploads.forEach( upload => upload.abort() );
	}

	_setUpUploadEvents( upload ) {
		upload.addEventListener(
			DIRECT_FILE_UPLOAD_EVENTS.PROGRESS,
			this._uploadProgressChanged.bind( this, upload.id )
		);
		upload.addEventListener(
			DIRECT_FILE_UPLOAD_EVENTS.SUCCESS,
			this._uploadSucceeded.bind( this, upload.id )
		);
		upload.addEventListener(
			DIRECT_FILE_UPLOAD_EVENTS.ERROR,
			this._uploadError.bind( this, upload.id )
		);
		upload.addEventListener(
			DIRECT_FILE_UPLOAD_EVENTS.UPLOAD_START,
			this._uploadStarted.bind( this, upload.id )
		);
	}

	_uploadProgressChanged( id, progress ) {
		this._handleEvent(
			FILE_UPLOAD_EVENTS.PROGRESS,
			{ id, status: FILE_UPLOAD_STATUS.UPLOADING, progress }
		);
	}

	_uploadStarted( id ) {
		this._handleEvent(
			FILE_UPLOAD_EVENTS.START,
			{ id, status: FILE_UPLOAD_STATUS.UPLOADING }
		);
	}

	_uploadSucceeded( id, { signed_id: file } ) {
		this._handleEvent(
			FILE_UPLOAD_EVENTS.SUCCESS,
			{ id, status: FILE_UPLOAD_STATUS.SUCCEEDED, file }
		);
	}

	_uploadError( id ) {
		this._handleEvent(
			FILE_UPLOAD_EVENTS.FAILURE,
			{ id, status: FILE_UPLOAD_STATUS.FAILED }
		);
	}
}

export default FilesUploader;
