import { workspaceOwnerUsersPermissions, adminPermissions, collaboratorPermissions }
	from './permissions/usersPermissions';

export class WorkspaceRolePermissions {
	constructor(
		usersPermissions
	) {
		this.usersPermissions = usersPermissions;
	}

	/**
	 * @param {bool} condition Accepts any boolean. Usually a selector of the permission
	 * @param {*} componentToShow
	 * @example RolePermissions.renderIfUserIsAllowed( getUserCanUploadAssets, <button /> )
	 */
	static renderIfUserIsAllowed( condition, componentToShow ) {
		return condition
			? componentToShow
			: null;
	}

	/**
	 * @param {'edit'|'add'|'destroy'|'archive'|'view'|'seeVersions'} permit
	 * @param {'projects'|'assets'|'markups'|'tasks'|'comments'|'users'} entity
	 * @example currentUserCan( 'edit', 'projects' ) // => true
	 */

	currentUserCan( permit, entity ) {
		const permission = `${entity}Permissions`;
		if ( !this[ permission ] || this[ permission ][ permit ] === undefined ) {
			throw new Error( 'The requested permission does not exist' );
		}
		return this[ permission ][ permit ];
	}
}

export const ownerRolePermissions = new WorkspaceRolePermissions(
	workspaceOwnerUsersPermissions
);

export const adminRolePermissions = new WorkspaceRolePermissions(
	adminPermissions
);

export const collaboratorRolePermissions = new WorkspaceRolePermissions(
	collaboratorPermissions
);
