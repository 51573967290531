import { Fragment } from 'react';
import PropTypes from 'prop-types';
import Workspace from '../../entities/workspace';
import Tabs from '../Tabs/Tabs';

import './WorkspaceOverviewPanel.scss';

export const WorkspaceOverviewPanel = ( {
	workspace, tabs, selectedTabIndex, onTabChange
} ) => {
	const CurrentTabComponent = (
		tabs[ selectedTabIndex ]?.component
	) || Fragment;
	const tabItems = tabs.map( ( { name } ) => ( { label: name } ) );
	return (
		<div className="WorkspaceOverviewPanel">
			<div className="header">
				<Tabs
					tabSelected={selectedTabIndex}
					tabs={tabItems}
					onTabChange={onTabChange}
				/>
			</div>
			<div className="body">
				<CurrentTabComponent workspaceID={workspace.id} />
			</div>
		</div>
	);
};

WorkspaceOverviewPanel.propTypes = {
	workspace: PropTypes.instanceOf( Workspace ).isRequired,
	selectedTabIndex: PropTypes.number,
	onTabChange: PropTypes.func,
	tabs: PropTypes.arrayOf( PropTypes.shape( {
		name: PropTypes.string.isRequired,
		component: PropTypes.any.isRequired,
		onlyOwner: PropTypes.bool
	} ) )
};

WorkspaceOverviewPanel.defaultProps = {
	tabs: [],
	selectedTabIndex: 0,
	onTabChange: () => {}
};

export default WorkspaceOverviewPanel;
