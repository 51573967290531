import { createSelector } from 'reselect';
import { getCurrentUserPreferences } from './userPreferences';
import { getUsers } from './users';

export const getRawCurrentUser = state => state.currentUser;

export const getCurrentUserID =	state => (
	state.currentUser ? state.currentUser.userID : undefined
);

export const getCurrentUser = createSelector(
	[ getUsers, getCurrentUserID, getCurrentUserPreferences ],
	( users, currentUserID, currentUserPreferences ) => ( currentUserID
		? users.get( currentUserID )?.addPreferences( currentUserPreferences )
		: undefined
	)
);
