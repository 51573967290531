import { PROJECTS_URL } from '../config/urls';
import { FETCH_PROJECT_ACTIVITIES } from './types';

import '../extensions/promise';

export const fetchActivitiesForProject = projectID => ( dispatch, _, api ) => dispatch( {
	type: FETCH_PROJECT_ACTIVITIES,
	promise: api
		.get( `${PROJECTS_URL}/${projectID}/activity_logs` )
		.then( response => ( {
			projectID,
			activities: response.response
				.map( activity => ( { ...activity, project_id: projectID } ) )
		} ) )
} );
