export function shallowClone( obj ) {
	return Object.create(
		Object.getPrototypeOf( obj ),
		Object.getOwnPropertyDescriptors( obj )
	);
}

export function compact( obj ) {
	return Object.keys( obj ).reduce( ( result, key ) => {
		if ( obj[ key ] !== undefined ) {
			result[ key ] = obj[ key ];
		}
		return result;
	}, {} );
}
