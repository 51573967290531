import { Map } from 'immutable';

import { handleSuccess } from '../lib/reduxUtils';

import { FETCH_PROJECT_INVITATION, FETCH_PROJECT_INVITATIONS, DELETE_PROJECT_INVITATION } from '../actions/types';

export const projectInvitations = ( state = new Map(), action ) => {
	switch ( action.type ) {
	case FETCH_PROJECT_INVITATION:
		return handleSuccess(
			state,
			action,
			prevState => prevState.set(
				action.payload.id,
				action.payload
			)
		);
	case FETCH_PROJECT_INVITATIONS:
		return handleSuccess(
			state,
			action,
			( prevState ) => {
				const { projectID } = action.meta;
				return prevState
					.replaceMatchingWithPayload( action.payload, projectID, 'project.id' );
			}
		);
	case DELETE_PROJECT_INVITATION:
		return handleSuccess(
			state,
			action,
			prevState => prevState.delete( action.meta.invitationID )
		);
	default:
		return state;
	}
};
