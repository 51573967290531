const DEFAULT_NAME_CHAR_LENGTH = 20;

export const simpleTextTruncate = ( text, maxCharLength, finalCharLength, isFilename = false ) => {
	const splitText = text.split( '.' );
	const extensionLength = isFilename ? splitText[ splitText.length - 1 ].length + 1 : 0;
	if ( text.length < maxCharLength + extensionLength ) { return text; }
	const firstHalf = text.slice( 0, finalCharLength / 2 );
	const secondHalf = text.slice(
		text.length - ( ( finalCharLength / 2 ) + extensionLength )
	);
	return `${firstHalf}...${secondHalf}`;
};

export const fileNameTextTruncate = ( text, maxCharLength, finalCharLength ) => (
	simpleTextTruncate( text, maxCharLength, finalCharLength, true )
);

export const textEndEllipsis = ( text, maxCharLength = DEFAULT_NAME_CHAR_LENGTH ) => {
	if ( !text || text.length <= maxCharLength ) { return text; }
	const slicedText = text.slice( 0, maxCharLength ).trim();
	return `${slicedText}...`;
};
