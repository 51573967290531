import { makeAutoObservable } from 'mobx';
import AuthenticationStore from './AuthenticationStore';
import { RootStoreSerialization } from './types';

export type PersistableRootStore = {
	serializationToPersist: () => object;
	rehydrateWithSerialization: ( serialization: object ) => void;
};

export class RootStore implements PersistableRootStore {
	authenticationStore: AuthenticationStore;

	constructor( { isAuthenticated }: { isAuthenticated?: boolean } = {} ) {
		this.authenticationStore = new AuthenticationStore( isAuthenticated );

		makeAutoObservable( this );
	}

	get isAuthenticated() {
		return this.authenticationStore.isAuthenticated;
	}

	serializationToPersist(): RootStoreSerialization {
		return {
			authenticationStore: this.authenticationStore.serialize()
		};
	}

	rehydrateWithSerialization( serialization: RootStoreSerialization ) {
		if ( serialization.authenticationStore ) {
			this.authenticationStore.hydrate( serialization.authenticationStore );
		}
	}

	clearStore() {
		this.authenticationStore.clear();
	}
}
