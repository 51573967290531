import AppError from '../../../errors/AppError';
import ResponseError from '../../../errors/ResponseError';

export default class UnprocessableCredentialsError extends AppError {
	static fromResponseError( error : ResponseError ) {
		return new this( {
			message: 'That email / password combination is not valid',
			cause: error
		} );
	}
}
