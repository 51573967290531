import {
	SHOW_SUCCESS_SNACKBAR,
	DISMISS_CURRENT_SNACKBAR,
	SHOW_ERROR_SNACKBAR,
	SHOW_LOADING_SNACKBAR
} from '../actions/types';

const showSnackbarWith = ( { state, action, type, keepCount = false } ) => {
	const { channel, onClose, texts: { title, description } } = action.payload;
	return {
		...state,
		[ channel ]: {
			title,
			description,
			onClose,
			type,
			count: state[ channel ] && keepCount ? state[ channel ].count + 1 : 1
		}
	};
};

export const snackbar = ( state = {}, action ) => {
	switch ( action.type ) {
	case SHOW_SUCCESS_SNACKBAR: {
		return showSnackbarWith( { state, action, type: 'success', keepCount: false } );
	}
	case SHOW_ERROR_SNACKBAR: {
		return showSnackbarWith( { state, action, type: 'error', keepCount: false } );
	}
	case SHOW_LOADING_SNACKBAR: {
		return showSnackbarWith( { state, action, type: 'loading', keepCount: true } );
	}
	case DISMISS_CURRENT_SNACKBAR: {
		if ( !state[ action.payload.channel ] ) { return state; }

		const newState = { ...state };
		newState[ action.payload.channel ].count -= 1
		if ( newState[ action.payload.channel ].count < 1 ) { delete newState[ action.payload.channel ]; }
		return newState;
	}
	default:
		return state;
	}
};
