import PropTypes from 'prop-types';
import Icon from '../../new_arch/components/Icon/Icon';
import DefaultImg from '../../assets/img/pending-invite.png';

import './WorkspaceInvitationBox.scss';

const WorkspaceInvitationBox = ( { invitation, showDeleteIcon, onDeleteClicked } ) => (
	<div className="WorkspaceInvitation">
		<div className="profile-pic">
			<div className="img-container">
				<img alt="default pic" src={DefaultImg} />
			</div>
		</div>
		<div className="invitation-info">
			<h5>Pending Invite</h5>
			<p>{invitation.email}</p>
		</div>
		<p className="pending-text">0 Owned</p>
		<p className="invitation-role">{invitation.role?.name}</p>
		<div className="remove-container">
			{showDeleteIcon && (
				<button className="remove" onClick={onDeleteClicked}>
					<Icon icon="delete_outlined" size={20} />
				</button>
			)}
		</div>
	</div>
);

WorkspaceInvitationBox.propTypes = {
	invitation: PropTypes.shape( {
		email: PropTypes.string,
		role: PropTypes.string
	} ),
	showDeleteIcon: PropTypes.bool,
	onDeleteClicked: PropTypes.func
};

WorkspaceInvitationBox.defaultProps = {
	invitation: null,
	showDeleteIcon: false,
	onDeleteClicked: () => {}
};

export default WorkspaceInvitationBox;
