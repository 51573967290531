/* eslint-disable max-len */
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import PlanSettingsPresenter from '../../../presenters/PlanSettings/PlanSettingsPresenter';
import ReactivateWorkspaceBox from '../../ReactivateWorkspaceBox/ReactivateWorkspaceBox';
import DowngradeWarning from '../../UI/DowngradeWarning/DowngradeWarning';

const SuspendedSubscriptionDetail = ( {
	presenter
} ) => (
	<div className="subscription-details">
		<div className="planInfoBoxSuspended">
			<div className="subscription-type">
				<h5>Your workspace has been
					<span className="type-flag">Suspended</span>
					{presenter.workspace.isOnExpiredFreeTrial ? ' since your Free Trial expired' : ''}
				</h5>
			</div>
			<ReactivateWorkspaceBox onSeePlansClick={presenter.onUpgradePlanButtonClicked} />
			{presenter.shouldRenderDowngradeWarning && <DowngradeWarning presenter={presenter} />}
		</div>
	</div>
);

SuspendedSubscriptionDetail.propTypes = {
	presenter: PropTypes.instanceOf( PlanSettingsPresenter ).isRequired
};

export default observer( SuspendedSubscriptionDetail );
