import SubscriptionType from './subscriptionType';

export default class PaidSubscriptionType extends SubscriptionType {
	get allowsMemberInvitations() {
		return true;
	}

	get isFree() {
		return false;
	}

	addAddOnsWith( addOnsSystem ) {
		addOnsSystem.addAddOns();
	}
}
