import PropTypes from 'prop-types';

import IconButton from '../UI/IconButton/IconButton';

import './Chip.scss';

const Chip = ( {
	textColor, background, children, onClick, icon
} ) => (
	<span className="Chip" style={{ background, color: textColor }}>
		{children}
		{!!icon && (
			<IconButton
				buttonClassName="icon"
				icon={icon}
				onClick={onClick}
				size={16}
			/>
		)}
	</span>
);

Chip.propTypes = {
	children: PropTypes.oneOfType( [ PropTypes.node, PropTypes.arrayOf( PropTypes.node ) ] ).isRequired,
	background: PropTypes.string,
	textColor: PropTypes.string,
	icon: PropTypes.string,
	onClick: PropTypes.func
};

Chip.defaultProps = {
	background: '',
	textColor: '',
	icon: undefined,
	onClick: () => {}
};

export default Chip;
