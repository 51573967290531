import ReactLoader from 'react-loaders';

import './Loader.scss';

export type LoaderProps = {
	active: boolean;
};

const Loader = ( { active } : LoaderProps ) => {
	if ( !active ) return null;

	return (
		<div className="Loader" data-testid="loader">
			<ReactLoader active type="ball-triangle-path" />
		</div>
	);
};

export default Loader;
