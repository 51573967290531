import PropTypes from 'prop-types';
import { useMemo } from 'react';

import WorkspaceProjectListItem from '../WorkspaceProjectListItem/WorkspaceProjectListItem';
import NoItemsIndicatorBox from '../NoItemsIndicatorBox/NoItemsIndicatorBox';
import List from '../List/List';

import './WorkspaceProjectList.scss';
import WhiteBox from '../WhiteBox/WhiteBox';
import Workspace from '../../entities/workspace';
import StorageUsageContainer from '../../containers/StorageUsageContainer/StorageUsageContainer';
import Button from '../../new_arch/components/Button/Button';
import SortSelector from '../SortSelector/SortSelector';
import SortOption from '../../entities/sortOption';
import SortColumnName from '../SortColumnName/SortColumnName';
import { EMPTY_PROJECTS_TEXT } from '../../screens/Projects/const';
import useToggle from '../../hooks/useToggle';

const MAX_PROJECTS_TO_DISPLAY = 2;

const WorkspaceProjectList = ( {
	projects, workspace, selectedSortOption, setProjectsViewSort,
	ascending, dropdownItemsForProject,
	onDropdownItemClick, onAddProjectButtonClick, onPaidFeatureModalAcceptClick
} ) => {
	const [ isViewingAll, toggleIsViewingAll ] = useToggle( false );
	const projectsToDisplay = useMemo(
		() => ( isViewingAll ? projects : projects.slice( 0, MAX_PROJECTS_TO_DISPLAY ) ),
		[ projects, isViewingAll ]
	);
	const showViewAllButton = projects.length > MAX_PROJECTS_TO_DISPLAY;
	const renderViewAllButton = () => (
		<Button
			type="link"
			className="view-all-button"
			buttonType="button"
			onClick={toggleIsViewingAll}
		>
			{isViewingAll ? 'View less' : 'View all'}
		</Button>
	);

	return (
		<div className="WorkspaceProjectList">
			<WhiteBox>
				<div className="title">
					<h4>{workspace?.name}{"'s Projects"}</h4>
				</div>
				{projects && projects.length > 0
					? (
						<>
							<StorageUsageContainer
								workspace={workspace}
								onPaidFeatureModalAcceptClick={onPaidFeatureModalAcceptClick}
							/>
							<div className="SortButtonContainer">
								<div className="SortSelector">
									<SortSelector
										options={SortOption.projectsTabOptions}
										selected={selectedSortOption}
										onChange={setProjectsViewSort}
										ascending={ascending}
									/>
								</div>
								<Button size="large" onClick={onAddProjectButtonClick}>
									Create Project
								</Button>
							</div>
							<div className="column-names">
								<div className="project-info">
									<SortColumnName
										title="Project Name"
										selectedSortOption={selectedSortOption}
										onChange={setProjectsViewSort}
										ascending={ascending}
										sortOptionID="by_name"
									/>
								</div>
								<div className="created-on">
									<SortColumnName
										title="Date Created"
										selectedSortOption={selectedSortOption}
										onChange={setProjectsViewSort}
										ascending={ascending}
										sortOptionID="by_date_added"
									/>
								</div>
								<div className="collaborators-count">
									<h5>Collaborators</h5>
								</div>
								<div className="size">
									<SortColumnName
										title="Size"
										selectedSortOption={selectedSortOption}
										onChange={setProjectsViewSort}
										ascending={ascending}
										sortOptionID="by_size"
									/>
								</div>
								<div className="my-role">
									<h5>My Role</h5>
								</div>
								<div className="actions" />
							</div>
							<div className="workspace-projects">
								<List itemsSize={50}>
									{projectsToDisplay.map( project => (
										<WorkspaceProjectListItem
											key={project.id}
											project={project}
											dropdownItems={dropdownItemsForProject( project )}
											onDropdownItemClick={index => onDropdownItemClick( { index, project } )}
										/>
									) )}
								</List>
							</div>
							{showViewAllButton && renderViewAllButton()}
						</>

					) : (
						<div className="EmptyWorkspaceProjectList">
							<NoItemsIndicatorBox
								text={EMPTY_PROJECTS_TEXT}
								decreasedLeftMargin
							/>
							<Button size="large" onClick={onAddProjectButtonClick}>
								Create Project
							</Button>
						</div>
					)}
			</WhiteBox>
		</div>
	);
}

WorkspaceProjectList.propTypes = {
	projects: PropTypes.arrayOf( PropTypes.shape( {
		id: PropTypes.number,
		name: PropTypes.string.isRequired,
		coverUrl: PropTypes.string,
		owner: PropTypes.shape( { name: PropTypes.string.isRequired } ),
		users: PropTypes.arrayOf( PropTypes.number ),
		createdAt: PropTypes.instanceOf( Date )
	} ) ),
	workspace: PropTypes.instanceOf( Workspace ),
	selectedSortOption: PropTypes.instanceOf( SortOption ),
	setProjectsViewSort: PropTypes.func,
	ascending: PropTypes.bool,
	dropdownItemsForProject: PropTypes.func.isRequired,
	onDropdownItemClick: PropTypes.func.isRequired,
	onAddProjectButtonClick: PropTypes.func.isRequired,
	onPaidFeatureModalAcceptClick: PropTypes.func.isRequired
};

WorkspaceProjectList.defaultProps = {
	projects: [],
	workspace: undefined,
	selectedSortOption: undefined,
	setProjectsViewSort: () => {},
	ascending: true
};

export default WorkspaceProjectList;
