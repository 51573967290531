import { initializeDownload } from '../../lib/fileUtils';
import defaultMessagingService from '../MessagingService';
import AssetDownloadService from './AssetDownloadService';

const assetDownloadService = new AssetDownloadService( {
	messagingService: defaultMessagingService,
	downloadInitializer: initializeDownload
} );

export default assetDownloadService;
