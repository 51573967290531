import { Component, createRef } from 'react';
import PropTypes from 'prop-types';

import { validateEmail } from '../../validators/emailValidator';

import ProjectRole from '../../entities/projectRole';
import ProjectRolePropType from '../../types/projectRole';
import Button from '../../new_arch/components/Button/Button';
import EmailsInput from '../EmailsInput/EmailsInput';

import './InviteMember.scss';
import Workspace from '../../entities/workspace';

class InviteMember extends Component {
	constructor( props ) {
		super( props );

		this.defaultState = {
			currentEmailInput: '',
			role: props.defaultRole.id,
			emails: []
		};

		this.state = { ...this.defaultState };

		this._inputWithSuggestionsRef = createRef();

		this._onCurrentEmailInputChanged = this._changeCurrentEmailInput.bind( this );
		this._onInviteClicked = this._onInviteClicked.bind( this );
		this._onCancelClicked = this._onCancelClicked.bind( this );
	}

	get isFormValid() {
		return validateEmail( this.state.currentEmailInput );
	}

	get hasInvalidEmail() {
		return this.state.emails.some( email => !email.isValid );
	}

	get formy() {
		const emails = this.state.emails.map( email => email.value );
		return { role: this.state.role, emails };
	}

	get _hasAddedEmail() {
		return this.state.emails.length > 0;
	}

	get _disableInviteButton() {
		return this.props.disabled
			|| this.props.sending
			|| this.hasInvalidEmail
			|| ( !this._hasAddedEmail && this.state.currentEmailInput.length === 0 );
	}

	_onDeleteEmail = ( emailToDelete ) => {
		this.setState(
			prevState => ( {
				...prevState,
				emails: prevState.emails.filter( email => email.value !== emailToDelete )
			} )
		);
	}

	_isValidEvent = ( e ) => {
		const spaceKey = 32;
		const tabKey = 9;
		const enterKey = 13;

		return ( e.keyCode === spaceKey || e.keyCode === tabKey || e.keyCode === enterKey );
	}

	_onKeyDown = ( event ) => {
		if ( !this._isValidEvent( event ) ) return;
		event.preventDefault();
		this._addTextAsEmail();
		if ( this.isFormValid ) this._inputWithSuggestionsRef?.current?.saveSuggestion();
		this._clearEmail();
	}

	_onPaste = ( event ) => {
		event.preventDefault();
		const emailsFromClipboard = this._extractEmailsFromClipboard( event );
		const parsedEmails = emailsFromClipboard.map( this._parseEmail );
		this.setState( prevState => ( {
			emails: [
				...prevState.emails,
				...parsedEmails
			].uniq( email => email.value )
		} ) );
	}

	_parseEmail = email => ( { value: email, isValid: validateEmail( email ) } )

	_placeHolderText = () => {
		if ( this._hasAddedEmail ) return '';
		return 'Email, space or tab separated';
	}

	_extractEmailsFromClipboard( event ) {
		return event.clipboardData.getData( 'text/plain' )
			.split( /, +|,| +|\n/g )
			.map( email => email.trim() )
			.filter( email => email !== '' );
	}

	_isUniqueEmail( emailToCheck ) {
		return !this.state.emails.map( email => email.value ).includes( emailToCheck );
	}

	_clearEmail() {
		this._changeCurrentEmailInput( '' );
	}

	_changeCurrentEmailInput( value ) {
		this.setState( { currentEmailInput: value } );
	}

	_addTextAsEmail( { then } = { then: () => { } } ) {
		const { currentEmailInput } = this.state;

		if ( currentEmailInput.length === 0 ) { then(); return; }
		if ( !this._isUniqueEmail( currentEmailInput ) ) return;
		this.setState( prevState => ( {
			emails: [
				...prevState.emails,
				this._parseEmail( prevState.currentEmailInput )
			]
		} ), then );
	}

	_addEmailsFromGroups = ( emails ) => {
		emails.forEach( ( email ) => {
			if ( !this._isUniqueEmail( email ) ) return;
			this.setState( prevState => ( {
				emails: [
					...prevState.emails,
					this._parseEmail( email )
				]
			} ) );
		} );
	}

	_onInviteClicked() {
		this._addTextAsEmail( {
			then: () => {
				this._clearEmail();
				if ( this.hasInvalidEmail ) return;
				this.props.onInviteClick( this.formy );
			}
		} );
	}

	_onCancelClicked() {
		this.props.onCancelClick();
	}

	clear() {
		this.setState( { ...this.defaultState } );
	}

	render() {
		const {
			sending,
			workspace,
			disabled,
			showCancelButton,
			projectMemberRoleName
		} = this.props;
		const { currentEmailInput, emails } = this.state;
		const {
			_onCurrentEmailInputChanged, _placeHolderText, _onKeyDown, _onPaste
		} = this;

		return (
			<div className="InviteMember">
				<EmailsInput
					ref={this._inputWithSuggestionsRef}
					projectMemberRoleName={projectMemberRoleName}
					value={currentEmailInput}
					addedEmails={emails}
					onChange={_onCurrentEmailInputChanged}
					onKeyDown={_onKeyDown}
					placeholder={!disabled ? _placeHolderText() : 'Only admins can invite people'}
					disabled={disabled || sending}
					onDeleteEmail={this._onDeleteEmail}
					onPaste={_onPaste}
					addEmailsFromGroups={this._addEmailsFromGroups}
					name="invite-email"
					autoComplete="invite-email"
					workspace={workspace}
				/>
				<div className="submit-buttons">
					{showCancelButton ? (
						<Button
							type="text"
							onClick={this._onCancelClicked}
						>
							Cancel
						</Button>
					) : null }
					<Button
						disabled={this._disableInviteButton}
						onClick={this._onInviteClicked}
					>
						{sending ? 'Inviting...' : 'Invite'}
					</Button>
				</div>
			</div>
		);
	}
}

InviteMember.propTypes = {
	role: ProjectRolePropType,
	defaultRole: ProjectRolePropType,
	sending: PropTypes.bool,
	disabled: PropTypes.bool,
	showCancelButton: PropTypes.bool,
	onInviteClick: PropTypes.func,
	onCancelClick: PropTypes.func,
	workspace: PropTypes.instanceOf( Workspace ).isRequired,
	projectMemberRoleName: PropTypes.string.isRequired
};

InviteMember.defaultProps = {
	role: ProjectRole.projectCollaborator(),
	defaultRole: ProjectRole.projectCollaborator(),
	sending: false,
	disabled: false,
	showCancelButton: false,
	onInviteClick: () => {},
	onCancelClick: () => {}
};

export default InviteMember;
