import { useState } from 'react';
import { useHistory } from 'react-router';
import ProjectGuestViewPreviewPresenter from './ProjectGuestViewPreviewPresenter';
import useAuthSystem from '../../new_arch/modules/authentication/system/useAuthSystem';
import useNavigate from '../../new_arch/navigation/useNavigate';

const useProjectGuestViewPreviewPresenter = (
	{ guestHash, projectID }: { guestHash: string, projectID: number }
) => {
	const history = useHistory();
	const authSystem = useAuthSystem();
	const navigate = useNavigate();

	const createPresenter = () => new ProjectGuestViewPreviewPresenter(
		{ guestHash, projectID, history, authSystem, navigate }
	);

	const [ presenter ] = useState( createPresenter );

	return presenter;
}

export default useProjectGuestViewPreviewPresenter;
