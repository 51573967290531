import { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import DatePicker from '../UI/DatePicker/DatePicker';
import IconButton from '../UI/IconButton/IconButton';
import UserPropType from '../../types/user';
import { DATE_FORMAT } from '../../config/global';

import Button from '../../new_arch/components/Button/Button';
import TextArea from '../UI/TextArea/TextArea';
import UserSelector from '../UserSelector/UserSelector';
import { SUCCESS } from '../../styles/colors';

import './AddNewTask.scss';

export const formatDate = date => moment( date ).format( DATE_FORMAT );

const initialState = {
	text: '',
	assignee: undefined,
	dueDate: undefined,
	showUserSelector: false,
	errors: {
		text: false,
		dueDate: false,
		assignee: false
	}
};

class AddNewTask extends Component {
	constructor( props ) {
		super( props );

		this.state = initialState;

		this._dueDateRef = createRef();

		this._onDueDateChanged = this._onDueDateChanged.bind( this );
		this._onAssignUserButtonClicked = this._onAssignUserButtonClicked.bind( this );
		this._onAssignedSelected = this._onAssignedSelected.bind( this );
		this._onTaskTextChanged = this._onTaskTextChanged.bind( this );
		this._onAddTaskClicked = this._onAddTaskClicked.bind( this );
	}

	get isNewTaskValid() {
		const { text, assignee, dueDate } = this.state;
		return text && text.length > 0 && assignee && dueDate;
	}

	clear() {
		this.setState( initialState );
	}

	// Events handler
	_onDueDateChanged( dueDate ) {
		this.setState( prevState => ( {
			dueDate,
			errors: {
				...prevState.errors,
				dueDate: !dueDate
			}
		} ) );
	}

	_onAssignUserButtonClicked() {
		this.setState(
			( { showUserSelector } ) => (
				{ showUserSelector: !showUserSelector }
			)
		);
	}

	_onAssignedSelected( assignee ) {
		this.setState( prevState => ( {
			assignee,
			showUserSelector: false,
			errors: {
				...prevState.errors,
				assignee: !assignee
			}
		} ) );
	}

	_onTaskTextChanged( text ) {
		this.setState( prevState => ( {
			text,
			errors: {
				...prevState.errors,
				text: !text || text.length === 0
			}
		} ) );
	}

	_validate() {
		const { state: { text, assignee, dueDate } } = this;
		const errors = { text: false, dueDate: false, assignee: false };

		if ( !text || text.length === 0 ) { errors.text = true; }
		if ( !assignee ) { errors.assignee = true; }
		if ( !dueDate || !moment( dueDate ).isValid() ) { errors.dueDate = true; }

		this.setState( { errors } );
		return !errors.text && !errors.dueDate && !errors.assignee;
	}

	_onAddTaskClicked() {
		if ( !this._validate() ) { return; }

		const { text, assignee, dueDate } = this.state;
		this.props.onTaskAdd(
			{ text, assignee, dueDate }
		);
	}

	render() {
		const {
			state: {
				text, assignee, dueDate,
				showUserSelector, errors
			},
			props: {
				assignableUsers, addingTask
			}
		} = this;

		return (
			<div className="AddNewTask">
				<h4>Add new task</h4>
				<div
					className={classNames(
						'assignto',
						{ error: errors.assignee }
					)}
				>
					<IconButton
						onClick={this._onAssignUserButtonClicked}
						icon="add_circle"
						size={18}
						color={SUCCESS}
					/>
					{assignee && (
						<div className="small-profile-pic">
							<img src={assignee.avatar} alt={assignee.name} />
						</div>
					)}
					{showUserSelector && (
						<UserSelector
							users={assignableUsers}
							onUserClick={this._onAssignedSelected}
						/>
					)}
				</div>
				<TextArea
					hasError={!!errors.text}
					name="new-task-text"
					value={text}
					onChange={this._onTaskTextChanged}
					placeholder="Write a task"
				/>
				<div className="actions">
					<DatePicker
						key="dueDate"
						name="endDate"
						disabledDays={{	before: new Date() }}
						refHandler={this._dueDateRef}
						value={dueDate}
						dayChangeHandler={this._onDueDateChanged}
						placeholder="Due date"
						error={!!errors.dueDate}
						decreasedHeigth
						showOnTop
					/>
					<Button
						onClick={this._onAddTaskClicked}
						disabled={addingTask}
					>
						{addingTask ? 'Adding...' : 'Add'}
					</Button>
				</div>
			</div>
		);
	}
}

AddNewTask.propTypes = {
	assignableUsers: PropTypes.arrayOf( UserPropType ),
	addingTask: PropTypes.bool,
	onTaskAdd: PropTypes.func
};

AddNewTask.defaultProps = {
	assignableUsers: [],
	addingTask: false,
	onTaskAdd: () => {}
};

export default AddNewTask;
