import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CardNumberElement, useElements } from '@stripe/react-stripe-js';

import CloseableModal from '../CloseableModal';
import BillingInformationFillingForm from '../../components/BillingInformationFillingForm/BillingInformationFillingForm';
import PaymentMethodFillingForm from '../../components/PaymentMethodFillingForm/PaymentMethodFillingForm';
import Button from '../../new_arch/components/Button/Button';
import IconButton from '../../components/UI/IconButton/IconButton';
import Loader from '../../components/Loader/Loader';

import useAllValuesTruthState from '../../hooks/useAllValuesTruthState';

import './BillingAndPaymentInformationEditModal.scss';
import { SECONDARY_PRESSED } from '../../styles/colors';

import WorkspaceBillingInformation from '../../entities/workspaceBillingInformation';
import CreditCard from '../../entities/creditCard';
import CreditCardInformation from '../../components/subscription/CreditCardInformation/CreditCardInformation';

const BILLING_INFORMATION_FORM = 'BILLING_INFORMATION_FORM';
const PAYMENT_METHOD_FORM = 'PAYMENT_METHOD_FORM';

const DEFAULT_FORMS_COMPLETION_STATE = {
	[ BILLING_INFORMATION_FORM ]: false,
	[ PAYMENT_METHOD_FORM ]: false
};

const BillingAndPaymentInformationEditModal = ( {
	initialBillingInformation: {
		country: initialCountryCode,
		state: initialStateCode,
		postalCode: initialPostalCode,
		address: initialAddress,
		city: initialCity,
		companyName: initialCompanyName
	} = {},
	initialCreditCard,
	onClose,
	onSave,
	sending
} ) => {
	const [ bothFormsCompleted, setFormCompletionState ] = useAllValuesTruthState(
		DEFAULT_FORMS_COMPLETION_STATE
	);

	const makeOnFormCompletionStateChangedHandler = form => ( valid ) => {
		setFormCompletionState( form, valid );
	};

	const [ creatingNewPaymentMethod, setCreatingNewPaymentMethod ] = useState( false );

	const [ country, setCountry ] = useState( undefined );
	const [ state, setState ] = useState( undefined );
	const [ postalCode, setPostalCode ] = useState( initialPostalCode );
	const [ address, setAddress ] = useState( initialAddress );
	const [ city, setCity ] = useState( initialCity );
	const [ companyName, setCompanyName ] = useState( initialCompanyName );

	const [ cardholderName, setCardholderName ] = useState( '' );

	useEffect(
		() => {
			if ( !creatingNewPaymentMethod ) {
				setFormCompletionState( PAYMENT_METHOD_FORM, true );
			}
		},
		[ creatingNewPaymentMethod ]
	);

	useEffect(
		() => {
			setPostalCode( initialPostalCode );
		},
		[ country ]
	);

	const thereAreChanges = ( country && country.value !== initialCountryCode )
		|| ( state && state.value !== initialStateCode )
		|| postalCode !== initialPostalCode
		|| address !== initialAddress
		|| city !== initialCity
		|| companyName !== initialCompanyName
		|| creatingNewPaymentMethod;

	const elements = useElements();

	const onPayButtonPressed = () => {
		const billingInformation = {
			country,
			state,
			postalCode,
			address,
			city,
			companyName
		};

		const cardElement = elements.getElement( CardNumberElement );

		const newPaymentMethodParams = creatingNewPaymentMethod
			? {
				cardElement,
				cardholderName
			}
			: undefined;

		onSave( billingInformation, newPaymentMethodParams );
	};

	return (
		<CloseableModal
			canClose={!sending}
			onClose={onClose}
			name="billing-and-payment-information-edit"
		>
			<div className="BillingAndPaymentInformationEditModal">
				<div className="body">
					<IconButton
						onClick={onClose}
						size={24}
						icon="close"
						color={SECONDARY_PRESSED}
						disabled={sending}
					/>
					<div className="billing-information-container">
						<h4 className="section-title">Billing Information</h4>
						<BillingInformationFillingForm
							initialCountryCode={initialCountryCode}
							country={country}
							onCountryChange={setCountry}
							initialStateCode={initialStateCode}
							state={state}
							onStateChange={setState}
							postalCode={postalCode}
							onPostalCodeChange={setPostalCode}
							address={address}
							onAddressChange={setAddress}
							city={city}
							onCityChange={setCity}
							companyName={companyName}
							onCompanyNameChange={setCompanyName}
							onFieldsCompletionStateChange={
								makeOnFormCompletionStateChangedHandler( BILLING_INFORMATION_FORM )
							}
							disabled={sending}
						/>
					</div>
					<div className="divider" />
					<div className="payment-method-container">
						<h4 className="section-title">Payment Method</h4>
						<p>You will not be charged. Your information will be saved.</p>
						<div className={classNames( 'payment-method', { usingSavedPaymentMethod: !creatingNewPaymentMethod } )}>
							{!creatingNewPaymentMethod && <CreditCardInformation card={initialCreditCard} />}
							<div>
								{
									creatingNewPaymentMethod
										? (
											<PaymentMethodFillingForm
												cardholderName={cardholderName}
												onCardholderNameChange={setCardholderName}
												onFieldsCompletionStateChange={
													makeOnFormCompletionStateChangedHandler( PAYMENT_METHOD_FORM )
												}
												disabled={sending}
											/>
										)
										: (
											<div className={classNames( 'use-different-card-label-container', { disabled: sending } )}>
												<button
													onClick={() => setCreatingNewPaymentMethod( true )}
													disabled={sending}
												>
													Use a different Credit Card
												</button>
											</div>
										)
								}

							</div>
						</div>
						<div className="buttons-container">
							<Button
								disabled={sending}
								onClick={onClose}
								color="secondary"
							>
								Cancel
							</Button>
							<Button
								disabled={!bothFormsCompleted || sending || !thereAreChanges}
								onClick={onPayButtonPressed}
							>
								{
									sending
										? ( <Loader active /> )
										: 'Save changes'
								}
							</Button>
						</div>
					</div>
				</div>
			</div>
		</CloseableModal>
	);
};
BillingAndPaymentInformationEditModal.propTypes = {
	initialBillingInformation: PropTypes.instanceOf( WorkspaceBillingInformation ),
	initialCreditCard: PropTypes.instanceOf( CreditCard ),
	onClose: PropTypes.func,
	onSave: PropTypes.func,
	sending: PropTypes.bool
};

BillingAndPaymentInformationEditModal.defaultProps = {
	initialBillingInformation: undefined,
	initialCreditCard: undefined,
	onClose: () => {},
	onSave: () => {},
	sending: false
};

export default BillingAndPaymentInformationEditModal;
