import isString from 'lodash/isString';

export default class NotEmptyValidator {
	validate( value, label, errors ) {
		const isEmptyString = isString( value ) && value.trim().length === 0;
		if ( value !== undefined && value !== null && !isEmptyString ) {
			return true;
		}
		errors.push( `${label} must not be empty.` );
		return false;
	}
}
