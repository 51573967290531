import { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import FormInput from '../../new_arch/components/FormInput/FormInput';
import Button from '../../new_arch/components/Button/Button';
import WorkspaceLogoPicker from '../WorkspaceLogoPicker/WorkspaceLogoPicker';

import { createForm } from '../../forms/workspaceSettings';

import './EditWorkspace.scss';

const EditWorkspace = ( {
	initialLogo,
	initialName,
	updating,
	onUpdateClick,
	readOnly
} ) => {
	const [ name, setName ] = useState( initialName );
	const [ logo, setLogo ] = useState( initialLogo );
	const formData = createForm( { name, logo } );

	useEffect(
		() => { setName( initialName ); },
		[ initialName ]
	);

	useEffect(
		() => { setLogo( initialLogo ); },
		[ initialLogo ]
	);

	const nameHasChanges = name !== initialName;
	const logoHasChanges = logo !== initialLogo;
	const hasChanges = nameHasChanges || logoHasChanges;

	const isValid = formData.validate();

	const onUpdateClicked = useCallback(
		() => {
			onUpdateClick(
				formData.getOnlyEditedData( {
					name: initialName,
					logo: initialLogo
				} )
			);
		},
		[ initialName, initialLogo, formData ]
	);

	const getValidationErrorMessage = ( field ) => {
		const { errors } = formData;
		const firstError = errors[ field ] && errors[ field ][ 0 ];

		return firstError;
	};

	return (
		<div className="EditWorkspace">
			<div className="workspace-logo-container">
				<h4>Workspace Logo</h4>
				<WorkspaceLogoPicker
					initialLogo={initialLogo}
					disabled={readOnly}
					onFileChange={setLogo}
				/>
			</div>
			<div className="workspace-name-container">
				<FormInput
					name="name"
					label="Workspace Name"
					value={name}
					error={getValidationErrorMessage( 'name' )}
					onChange={setName}
					placeholder="i.e. The Name of your Company"
					disabled={readOnly}
				/>
				{!readOnly && (
					<Button
						onClick={onUpdateClicked}
						disabled={!hasChanges || !isValid || updating}
					>
						Save Changes
					</Button>
				)}
			</div>
		</div>
	);
};

EditWorkspace.propTypes = {
	initialLogo: PropTypes.string,
	initialName: PropTypes.string,
	updating: PropTypes.bool,
	onUpdateClick: PropTypes.func,
	readOnly: PropTypes.bool
};

EditWorkspace.defaultProps = {
	initialLogo: undefined,
	initialName: '',
	updating: false,
	readOnly: true,
	onUpdateClick: () => {}
};

export default EditWorkspace;
