import { arrayMove } from '@dnd-kit/sortable';
import {
	SET_PROJECTS_VIEW_SORT, SET_PROJECTS_VIEW_FILTER,
	SET_PROJECTS_VIEW_ITEMS_SIZE, SET_PROJECTS_CUSTOM_SORT
} from './types';
import { USER_PREFERENCES } from '../config/urls';
import { trackCustomSortedProjects, trackChangedProjectsFilter, trackChangedProjectsSort } from '../services/tracker/trackers/projects';
import { getRawCurrentUser } from '../selectors/currentUser';

export const setProjectsViewSort = ( sortOption, ascending ) => {
	trackChangedProjectsSort( sortOption );

	return {
		type: SET_PROJECTS_VIEW_SORT,
		payload: { sortOption: sortOption.id, ascending }
	};
};

export const setProjectsViewFilter = ( filterOption ) => {
	trackChangedProjectsFilter( filterOption );

	return {
		type: SET_PROJECTS_VIEW_FILTER,
		payload: filterOption.id
	};
};

export const setProjectsGridItemSize = itemSize => ( {
	type: SET_PROJECTS_VIEW_ITEMS_SIZE,
	payload: itemSize
} );

export const setProjectsCustomSort = ( {
	projectIDs, previousIndex, newIndex
} ) => ( dispatch, getState, api ) => {
	const newSort = arrayMove( projectIDs, previousIndex, newIndex );
	const { userPreferencesID } = getRawCurrentUser( getState() );

	trackCustomSortedProjects();

	return dispatch( {
		type: SET_PROJECTS_CUSTOM_SORT,
		meta: { newSort },
		promise: api
			.patch( `${USER_PREFERENCES}/${userPreferencesID}/set_projects_order`, { project_ids: newSort } )
	} );
};
