import PropTypes from 'prop-types';

import Loader from '../../Loader/Loader';
import Button from '../../../new_arch/components/Button/Button';

const LoaderButton = ( {
	loading, disabled, children, ...props
} ) => (
	<Button
		disabled={disabled || loading}
		{...props}
	>
		{
			loading
				? ( <Loader active /> )
				: children
		}
	</Button>
);

LoaderButton.propTypes = {
	loading: PropTypes.bool.isRequired,
	disabled: PropTypes.bool,
	children: PropTypes.node.isRequired
};

LoaderButton.defaultProps = {
	disabled: false
};

export default LoaderButton;
