import { createSelector } from 'reselect';
import PricingPlan from '../entities/pricing/pricingPlan';
import PricingPlanPrice from '../entities/pricing/pricingPlanPrice';

export const getRawPricingPlans = state => state.pricingPlans;
const getRawPricingPlanPrices = state => state.pricingPlanPrices;
export const getWorkspaceID = ( _, props ) => props.workspaceID;

export const getPricingPlans = createSelector(
	[ getRawPricingPlans, getRawPricingPlanPrices ],
	(
		plansSerialization,
		pricingPlanPrices
	) => plansSerialization.map( plan => PricingPlan.fromJSON(
		plan,
		plan.prices?.map(
			id => PricingPlanPrice.fromJSON( pricingPlanPrices.get( id ), plan )
		)
	) )
);

export const getFreePricingPlanPrice = createSelector(
	[ getPricingPlans ],
	pricingPlans => pricingPlans.find( plan => plan.isFree )?.monthlyPrice
);
