import PropTypes from 'prop-types';
import moment from 'moment';

import { GREEN_700, SUCCESS_200 } from '../../styles/colors';
import Icon from '../../new_arch/components/Icon/Icon';
import Chip from '../Chip/Chip';

import { trackWorkspaceInvoiceOpen } from '../../services/tracker/trackers/workspaces';
import WorkspaceInvoice from '../../entities/workspaceInvoice';

import './WorkspaceInvoiceBox.scss';

const WorkspaceInvoiceBox = ( { invoice } ) => {
	const invoiceDate = moment( invoice.createdAt ).format( 'MM/DD/YYYY' );
	const totalAmount = `$${( invoice.amount / 100 ).toFixed( 2 )}`;
	const invoiceStatus = `(${invoice.status.capitalize()})`;
	const invoiceDetail = invoice.detail.capitalize();
	const discount = invoice.coupon?.discountToDisplay;

	return (
		<div className="WorkspaceInvoice">
			<p className="column-date">{ invoiceDate }</p>
			<p className="column-details">{ invoiceDetail }</p>
			<div className="column-total">
				<p>{ totalAmount }</p>
				{ discount && (
					<Chip background={SUCCESS_200} textColor={GREEN_700}>
						{`${discount} Off`}
					</Chip>
				) }
			</div>
			<p className="column-status">{ invoiceStatus }</p>
			<div className="column-url">
				{
					invoice.invoiceURL && (
						<a
							href={invoice.invoiceURL}
							rel="noopener noreferrer"
							target="_blank"
							onClick={() => trackWorkspaceInvoiceOpen()}
						>
							<Icon icon="launch" />
						</a>
					)
				}
			</div>
		</div>
	);
};

WorkspaceInvoiceBox.propTypes = {
	invoice: PropTypes.instanceOf( WorkspaceInvoice )
};

WorkspaceInvoiceBox.defaultProps = {
	invoice: null
};

export default WorkspaceInvoiceBox;
