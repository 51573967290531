import PropTypes from 'prop-types';
import Workspace from '../../entities/workspace';
import { connectComponent } from '../../lib/connectComponent';
import { getWorkspace } from '../../selectors/workspaces';
import WorkspaceFullSettingsPanelContainer from '../WorkspaceFullSettingsPanelContainer/WorkspaceFullSettingsPanelContainer';
import WorkspaceOverviewPanelContainer from '../WorkspaceOverviewPanelContainer/WorkspaceOverviewPanelContainer';
import { getCurrentUser } from '../../selectors/currentUser';

export const WorkspacePanelContainer = ( {
	workspace,
	currentUserID
} ) => (
	<div className="header-and-body">
		{workspace.userIsOwner( currentUserID )
			? <WorkspaceFullSettingsPanelContainer workspaceID={workspace.id} />
			: <WorkspaceOverviewPanelContainer workspaceID={workspace.id} />}
	</div>
);

WorkspacePanelContainer.propTypes = {
	workspace: PropTypes.instanceOf( Workspace ).isRequired,
	currentUserID: PropTypes.number,
	// eslint-disable-next-line react/no-unused-prop-types
	workspaceID: PropTypes.number.isRequired
};

WorkspacePanelContainer.defaultProps = {
	currentUserID: undefined
};

export default connectComponent( ( state, props ) => ( {
	currentUserID: getCurrentUser( state ).id,
	workspace: getWorkspace( state, props )
} ) )( WorkspacePanelContainer );
