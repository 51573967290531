import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import IconButton from '../../UI/IconButton/IconButton';
import NotificationsProjectThumbnail from '../NotificationsProjectThumbnail/NotificationsProjectThumbnail';

import ProjectWithUnseenNotificationsCountPropType from '../../../types/projectWithUnseenNotificationsCount';
import { SECONDARY_BASE, MIDDLE_GREY } from '../../../styles/colors';

import './NotificationsProjectsSidebar.scss';

const NotificationsProjectsSidebar = forwardRef( ( {
	projectsWithUnseenNotificationsCount, selectedProjectID, onProjectClick,
	disableUpButton, disableDownButton, onScrollProjectsUp, onScrollProjectsDown
}, ref ) => {
	const highlighted = projectID => projectID === selectedProjectID;
	return (
		<div className="NotificationsProjectsSidebar">
			<IconButton
				keyID={`up ${disableUpButton}`}
				icon="keyboard_arrow_up"
				size={35}
				color={disableUpButton ? MIDDLE_GREY : SECONDARY_BASE}
				disabled={disableUpButton}
				onClick={onScrollProjectsUp}
			/>
			<div className="projects-container" ref={ref}>
				{ projectsWithUnseenNotificationsCount.map(
					( { project, unseenNotificationsCount } ) => (
						<NotificationsProjectThumbnail
							key={project.id}
							project={project}
							highlighted={highlighted( project.id )}
							unseenNotificationsCount={unseenNotificationsCount}
							onClick={() => onProjectClick && onProjectClick( project.id )}
						/>
					) )}
			</div>
			<IconButton
				keyID={`down ${disableDownButton}`}
				icon="keyboard_arrow_down"
				size={35}
				color={disableDownButton ? MIDDLE_GREY : SECONDARY_BASE}
				disabled={disableDownButton}
				onClick={onScrollProjectsDown}
			/>
		</div>
	);
} );

NotificationsProjectsSidebar.propTypes = {
	onScrollProjectsUp: PropTypes.func,
	onScrollProjectsDown: PropTypes.func,
	disableUpButton: PropTypes.bool,
	disableDownButton: PropTypes.bool,
	projectsWithUnseenNotificationsCount: PropTypes.arrayOf(
		ProjectWithUnseenNotificationsCountPropType
	),
	selectedProjectID: PropTypes.number,
	onProjectClick: PropTypes.func
};

NotificationsProjectsSidebar.defaultProps = {
	onScrollProjectsDown: () => {},
	onScrollProjectsUp: () => {},
	disableUpButton: false,
	disableDownButton: false,
	projectsWithUnseenNotificationsCount: [],
	selectedProjectID: undefined,
	onProjectClick: undefined
};

export default NotificationsProjectsSidebar;
