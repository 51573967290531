import { makeAutoObservable } from 'mobx';

export interface AuthenticationStoreSerialization {
	is_authenticated: boolean;
	is_guest: boolean;
}

export default class AuthenticationStore {
	isAuthenticated: boolean;
	isGuest: boolean;

	constructor( isAuthenticated = false, isGuest = false ) {
		this.isAuthenticated = isAuthenticated;
		this.isGuest = isGuest;

		makeAutoObservable( this );
	}

	set() {
		this.isAuthenticated = true;
	}

	setGuest() {
		this.isGuest = true;
	}

	serialize(): AuthenticationStoreSerialization {
		return {
			is_authenticated: this.isAuthenticated,
			is_guest: this.isGuest
		};
	}

	hydrate( serialization: AuthenticationStoreSerialization ) {
		this.isAuthenticated = serialization.is_authenticated;
		this.isGuest = serialization.is_guest;
	}

	clear() {
		this.isAuthenticated = false;
		this.isGuest = false;
	}
}
