import ImmutableEntity from './immutableEntity';
import SubclassResponsabilityError from '../customErrors/SubclassResponsabilityError';

export default class Comment extends ImmutableEntity {
	constructor( {
		id, assetID, roundID, text, authorID, createdAt,
		author = undefined, seenByUsers = [], isEdited = false, files
	} ) {
		super();

		this.id = id;
		this.assetID = assetID;
		this.roundID = roundID;
		this.text = text;
		this.authorID = authorID;
		this.createdAt = createdAt;
		this.author = author;
		this.seenByUsers = seenByUsers;
		this.isEdited = isEdited;
		this.files = files;
	}

	get isRootComment() { return false; }

	get deleteConfirmationText() {
		throw SubclassResponsabilityError();
	}

	get deleteConfirmationTitle() {
		throw SubclassResponsabilityError();
	}

	belongsToAsset( assetID ) { return this.assetID === assetID; }

	belongsToAssetRound( roundID ) { return this.roundID === roundID; }

	clone() {
		return new Comment(
			{
				id: this.id,
				assetID: this.assetID,
				roundID: this.roundID,
				text: this.text,
				authorID: this.authorID,
				createdAt: this.createdAt,
				author: this.author,
				seenByUsers: this.seenByUsers,
				isEdited: this.isEdited,
				files: this.files
			}
		);
	}

	isAuthor( userID ) {
		return this.authorID === userID;
	}
}
