export const FILE_UPLOAD_STATUS = Object.freeze( {
	UPLOADING: 'UPLOADING',
	FAILED: 'FAILED',
	SUCCEEDED: 'SUCCEEDED'
} );

export const FILE_UPLOAD_EVENTS = Object.freeze( {
	SUCCESS: 'SUCCESS',
	FAILURE: 'FAILURE',
	START: 'START',
	PROGRESS: 'PROGRESS'
} );
