import { createSelector } from 'reselect';
import Task from '../entities/task';
import { compareDatesAsc } from '../lib/dateUtils';

import { getCurrentUserID } from './currentUser';
import { getUsers } from './users';

const getRawTasks = state => state.tasks;
export const getTasks = createSelector(
	[ getRawTasks ],
	tasksSerialization => tasksSerialization
		.map( Task.fromJSON )
);

const getProjectID = ( _, props ) => props.projectID;

export const getEditingTaskID = state => state.currentActiveTask;
export const getTask = ( state, taskID ) => getTasks( state ).get( taskID );
export const getProjectIDFromTaskID = ( state, taskID ) => getTask( state, taskID ).projectID;

export const getTasksForProject = createSelector(
	[ getProjectID, getTasks, getUsers ],
	( projectID, tasks, users ) => tasks
		.filter( task => task.belongsToProject( projectID ) )
		.map( task => task.withAuthorAndAsignee(
			users.get( task.authorID ),
			users.get( task.asigneeID )
		) )
		.sortBy(
			task => task.dueDate,
			compareDatesAsc
		)
		.sortBy( task => task.isCompleted )
		.toArray()
);

export const getCurrentUserTasksForProject = createSelector(
	[ getTasksForProject, getCurrentUserID ],
	( tasks, currentUserID ) => tasks
		.filter( task => task.asigneeID === currentUserID )
);

export const getEditingTask = createSelector(
	[ getTasks, getEditingTaskID ],
	( tasks, editingTaskID ) => tasks.get( editingTaskID )
);

export const getEditingTaskWithAuthorAndAsignee = createSelector(
	[ getEditingTask, getUsers ],
	( editingTask, users ) => ( editingTask ? editingTask.withAuthorAndAsignee(
		users.get( editingTask.authorID ),
		users.get( editingTask.asigneeID )
	) : null )
);
