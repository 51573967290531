import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import Button from '../../new_arch/components/Button/Button';
import WhiteBox from '../WhiteBox/WhiteBox';
import './LeaveWorkspaceTab.scss';
import LeaveWorkspaceTabPresenter from '../../presenters/LeaveWorkspaceTabPresenter/LeaveWorkspaceTabPresenter';

const FOCUS_TEXT = 'You will remain a collaborator in these projects and any other projects';

const LeaveWorkspaceTab = ( { presenter } ) => (
	<div className="LeaveWorkspaceTab">
		<WhiteBox>
			<div className="title">
				<h4>Leave Workspace</h4>
			</div>
			<p>
				Upon leaving this workspace, you will automatically transfer ownership of all projects
				you own to the owner of this workspace.
				<span className="body-focus"> {FOCUS_TEXT} </span>
				belonging to this workspace.
			</p>
			<Button
				color="secondary"
				onClick={presenter.onLeaveWorkspaceClick}
			>
				Leave Workspace
			</Button>
		</WhiteBox>
	</div>
);

LeaveWorkspaceTab.propTypes = {
	presenter: PropTypes.instanceOf( LeaveWorkspaceTabPresenter ).isRequired
};

export default observer( LeaveWorkspaceTab );
