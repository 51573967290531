import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Logo from '../../../assets/img/icons/vb-logo.svg';
import HomeIcon from '../../../assets/img/icons/home.svg';
import CollapsableBreadcrumbs from '../../CollapsableBreadcrumbs/CollapsableBreadcrumbs';

import './NavigationBreadcrumbs.scss';

export const NavigationBreadcrumbs = ( {
	breadcrumbs, linkURL, hideHomeIcon, hideFirstArrow
} ) => {
	const showVeryBusyIcon = breadcrumbs.length === 0;

	return (
		<div className={classNames( 'NavigationBreadcrumbs' )}>
			{!hideHomeIcon && (
				<Link className={showVeryBusyIcon ? 'show-vb-logo' : 'show-home-icon'} to={linkURL || '/'}>
					<img
						alt={showVeryBusyIcon ? 'VeryBusy logo' : 'Home icon'}
						src={showVeryBusyIcon ? Logo : HomeIcon}
					/>
				</Link>
			)}
			{!showVeryBusyIcon && (
				<CollapsableBreadcrumbs breadcrumbs={breadcrumbs} hideFirstArrow={hideFirstArrow} />
			)}
		</div>
	);
};

NavigationBreadcrumbs.propTypes = {
	breadcrumbs: PropTypes.arrayOf( { name: PropTypes.string, url: PropTypes.string } ),
	linkURL: PropTypes.string,
	hideHomeIcon: PropTypes.bool,
	hideFirstArrow: PropTypes.bool
};

NavigationBreadcrumbs.defaultProps = {
	breadcrumbs: [],
	linkURL: undefined,
	hideHomeIcon: false,
	hideFirstArrow: false
};

export default NavigationBreadcrumbs;
