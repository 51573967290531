import PropTypes from 'prop-types';

import WhiteBox from '../WhiteBox/WhiteBox';
import Button from '../../new_arch/components/Button/Button';

import './WorkspaceInvoicesList.scss';
import WorkspaceInvoice from '../../entities/workspaceInvoice';
import WorkspaceInvoiceBox from '../WorkspaceInvoiceBox/WorkspaceInvoiceBox';
import List from '../List/List';
import NoItemsIndicatorBox from '../NoItemsIndicatorBox/NoItemsIndicatorBox';

const WorkspaceInvoicesList = ( {
	workspaceID, invoices, hasMore, onShowMoreClicked
} ) => (
	<div className="WorkspaceInvoicesList">
		<WhiteBox>
			<div className="invoices-header">
				<h4>Invoices</h4>
			</div>
			{invoices && invoices.length > 0
				? (
					<>
						<div className="column-names">
							<div className="column-date">
								<p>Date</p>
							</div>
							<div className="column-details">
								<p>Type</p>
							</div>
							<div className="column-total">
								<p>Total</p>
							</div>
							<div className="column-status">
								<p>Status</p>
							</div>
							<div className="column-view">
								<p>Detail</p>
							</div>
						</div>
						<div className="workspace-invoices">
							<List itemsSize={50}>
								{invoices.map( invoice => (
									<WorkspaceInvoiceBox
										key={invoice.id}
										workspaceID={workspaceID}
										invoice={invoice}
									/>
								) )}
							</List>
						</div>
						{ hasMore
							&& (
								<Button
									className="show-more-option"
									type="text"
									onClick={onShowMoreClicked}
								>
									Show more
								</Button>
							)}
					</>
				) : (
					<div className="no-items-box">
						<NoItemsIndicatorBox
							text="No invoices here yet."
							decreasedLeftMargin
							small
						/>
					</div>
				)}
		</WhiteBox>
	</div>
);

WorkspaceInvoicesList.propTypes = {
	workspaceID: PropTypes.number,
	invoices: PropTypes.arrayOf( PropTypes.instanceOf( WorkspaceInvoice ) ),
	hasMore: PropTypes.bool,
	onShowMoreClicked: PropTypes.func
};

WorkspaceInvoicesList.defaultProps = {
	workspaceID: undefined,
	invoices: [],
	hasMore: false,
	onShowMoreClicked: () => {}
};

export default WorkspaceInvoicesList;
