/* eslint-disable react/prop-types */
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connectComponent } from '../../../lib/connectComponent';

import Nav from '../Nav/Nav';
import AuthenticationLinksContainer
	from '../../../containers/AuthenticationLinksContainer/AuthenticationLinksContainer';
import NavigationLogo from '../NavigationLogo/NavigationLogo';
import FileDownload from '../../../assets/img/icons/file-download.svg';
import SharedNavigationBreadcrumbsContainer from '../../../containers/SharedNavigationBreadcrumbsContainer/SharedNavigationBreadcrumbsContainer';

import './SharedViewNav.scss';
import Tooltip from '../../UI/Tooltip/Tooltip';
import DownloadSharedProjectItemsPopupContainer from '../../../containers/DownloadSharedProjectItemsPopupContainer/DownloadSharedProjectItemsPopupContainer';
import useSharedViewNavPresenter from '../../../presenters/SharedViewNavPresenter/useSharedViewNavPresenter';
import Asset from '../../../entities/asset';
import { getSharedProjectAsset, getSharedEntity } from '../../../selectors/sharedProjects';
import SharedProject from '../../../entities/sharedProject';
import Folder from '../../../entities/folder';
import ShareRef from '../../../structures/shareRef';

export const SharedViewNav = ( {
	shareRef, assetID, asset, sharedEntity
} ) => {
	const sharedEntityPath = shareRef.appPath;

	const renderDownloadAssetPopup = useCallback( ( { assets: assetsToDownload } ) => ( {
		onConfirm, onCancel
	} ) => (
		<DownloadSharedProjectItemsPopupContainer
			assets={assetsToDownload}
			folders={[]}
			onConfirm={onConfirm}
			onCancel={onCancel}
			shareRef={shareRef}
		/>
	) );

	const presenter = useSharedViewNavPresenter( {
		assets: [ asset ], renderDownloadAssetPopup
	} );

	return (
		<Nav className="SharedViewNav">
			<div className="NavigationContainer">
				<NavigationLogo linkURL={sharedEntityPath} isClickeable={false} />
				<SharedNavigationBreadcrumbsContainer
					shareRef={shareRef}
					assetID={assetID}
				/>
			</div>
			{ !!assetID && sharedEntity?.allowsDownloads && (
				<Tooltip
					className="file-download"
					title="Download"
					position="bottom"
				>
					<button onClick={presenter.downloadAllAssetsWasClicked}>
						<img
							data-testid="file-download"
							alt="file download"
							src={FileDownload}
						/>
					</button>
				</Tooltip>
			)}
			<AuthenticationLinksContainer shareRef={shareRef} />
		</Nav>
	);
};

SharedViewNav.propTypes = {
	shareRef: PropTypes.instanceOf( ShareRef ).isRequired,
	assetID: PropTypes.number,
	asset: PropTypes.instanceOf( Asset ),
	sharedEntity: PropTypes.oneOfType( [
		PropTypes.instanceOf( SharedProject ),
		PropTypes.instanceOf( Folder )
	] )
};

SharedViewNav.defaultProps = {
	assetID: undefined,
	asset: undefined,
	sharedEntity: undefined
};

export default connectComponent( ( state, props ) => ( {
	asset: getSharedProjectAsset( state, props ),
	sharedEntity: getSharedEntity( state, props )
} ) )( SharedViewNav );
