import PricingPlanPrice from './pricing/pricingPlanPrice';

export enum CouponDuration {
	REPEATING = 'repeating',
	ONCE = 'once',
	FOREVER = 'forever'
}

type CouponParams = {
	id: number;
	name: string;
	percentOff: number | null;
	amountOff: number | null;
	duration: CouponDuration;
	durationInMonths: number | null;
}

export type CouponJSON = {
	id: number;
	name: string;
	percent_off: number | null;
	amount_off: number | null;
	duration: CouponDuration;
	duration_in_months: number | null;
}

export default class Coupon {
	id: number;
	name: string;
	percentOff: number;
	amountOff: number;
	duration: CouponDuration;
	durationInMonths: number;

	constructor( { id, name, percentOff, amountOff, duration, durationInMonths }: CouponParams ) {
		this.id = id;
		this.name = name;
		this.percentOff = percentOff || 0;
		this.amountOff = amountOff || 0;
		this.duration = duration;
		this.durationInMonths = durationInMonths || 0;
	}

	static fromJSON( json: CouponJSON ) {
		return new this( {
			id: json.id,
			name: json.name,
			percentOff: json.percent_off,
			amountOff: json.amount_off,
			duration: json.duration,
			durationInMonths: json.duration_in_months
		} );
	}

	toJSON() {
		return {
			id: this.id,
			name: this.name,
			percent_off: this.percentOff,
			amount_off: this.amountOff,
			duration: this.duration,
			duration_in_months: this.durationInMonths
		};
	}

	get discountToDisplay() {
		return this.percentOff ? `${this.percentOff}%` : `$${this.amountOff}`;
	}

	durationToDisplayFor( pricingPlanPrice: PricingPlanPrice ) {
		if ( this.duration === CouponDuration.ONCE ) { return this.onceDurationToDisplay; }
		if ( this.duration === CouponDuration.FOREVER ) { return this.foreverDurationToDisplay; }
		return this.repeatingDurationToDisplayFor( pricingPlanPrice );
	}

	applyDiscountTo( pricingPlanPrice: PricingPlanPrice ) {
		if ( this.percentOff ) {
			return pricingPlanPrice.cost * ( 1 - this.percentOff / 100 );
		}

		return Math.max( pricingPlanPrice.cost - this.amountOff, 0 );
	}

	private get onceDurationToDisplay() {
		return 'Valid on the first charge';
	}

	private get foreverDurationToDisplay() {
		return 'Valid forever';
	}

	private repeatingDurationToDisplayFor( pricingPlanPrice: PricingPlanPrice ) {
		if ( pricingPlanPrice.isMonthly ) {
			return `Valid for ${this.durationInMonths} ${'month'.pluralize( this.durationInMonths )}`;
		}

		const durationInYears = Math.ceil( this.durationInMonths / 12 );
		return `Valid for ${durationInYears} ${'year'.pluralize( durationInYears )}`;
	}
}
