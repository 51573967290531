import PropTypes from 'prop-types';

const CharactersCount = ( {
	count, max
} ) => <div className="length-count">{count}/{max} characters</div>;

CharactersCount.propTypes = {
	count: PropTypes.number.isRequired,
	max: PropTypes.number.isRequired
};

export default CharactersCount;
