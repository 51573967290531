import { WORKSPACES_URL } from '../config/urls';
import { FETCH_WORKSPACE_PROJECTS } from './types';
import { catchNotAllowedResponseWrapper } from '../lib/catchNotAllowedResponseWrapper';

export const fetchWorkspaceProjects = workspaceID => ( dispatch, _, api ) => dispatch( {
	type: FETCH_WORKSPACE_PROJECTS,
	promise: catchNotAllowedResponseWrapper( api.get(
		`${WORKSPACES_URL}/${workspaceID}/projects` ), dispatch, workspaceID )
		.then( response => response.response ),
	meta: { workspaceID }
} );
