import { createRef, Component } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'throttle-debounce';

import ProjectWithUnseenNotificationsCountPropType from '../../types/projectWithUnseenNotificationsCount';
import { connectComponent } from '../../lib/connectComponent';
import { getActiveProjectsUnseenNotificationsData } from '../../selectors/notifications';

import NotificationsProjectsSidebar
	from '../../components/notifications/NotificationsProjectsSidebar/NotificationsProjectsSidebar';

const selectSeenProjects = ( projects, currentPage, itemsPerPage ) => projects.slice(
	( currentPage * itemsPerPage ) - itemsPerPage, currentPage * itemsPerPage
);

const MIN_PROJECT_ON_SCREEN = 4;
const PROJECT_THUMBNAIL_SHADOW = 8;
const PROJECT_THUMBNAIL_HEIGHT = 55;
const PROJECT_THUMBNAIL_BOTTOM_MARGIN = 16;

const getProjectIndex = ( projects, projectID ) => (
	projects.findIndex(
		p => p.project.id === projectID
	)
);

const resetUnseenCountForProjectAt = ( projects, index ) => {
	const updatedProjects = [ ...projects ];
	updatedProjects[ index ] = {
		...updatedProjects[ index ],
		unseenNotificationsCount: 0
	};
	return updatedProjects;
};

export class NotificationsProjectsSidebarContainer extends Component {
	constructor( props ) {
		super( props );

		this.state = {
			currentPage: 1,
			containerHeight: undefined,
			projects: []
		};

		this._projectsContainerRef = createRef();

		this._onUpButtonClicked = this._onUpButtonClicked.bind( this );
		this._onDownButtonClicked = this._onDownButtonClicked.bind( this );
		this._setElementsHeight = debounce( 250, this._setElementsHeight.bind( this ) );
	}

	componentDidMount() {
		window.addEventListener( 'resize', this._setElementsHeight );
		this._setElementsHeight();
	}

	componentDidUpdate( prevProps ) {
		if ( prevProps.selectedProjectID !== this.props.selectedProjectID ) {
			this._resetUnseenCountForProject( prevProps.selectedProjectID );
		}
	}

	componentWillUnmount() {
		window.removeEventListener( 'resize', this._setElementsHeight );
	}

	static getDerivedStateFromProps( props, state ) {
		if ( state.projects.length > 0 ) { return null; }

		return {
			projects: props.projectsWithUnseenNotificationsCount
		};
	}

	get itemsPerPage() {
		const { state: { containerHeight, projects } } = this;
		const thumbnailWithMargin = PROJECT_THUMBNAIL_HEIGHT + PROJECT_THUMBNAIL_BOTTOM_MARGIN;
		if ( containerHeight ) {
			const minProjects = Math.floor( containerHeight / thumbnailWithMargin );
			const surplus = containerHeight - ( thumbnailWithMargin * minProjects );
			return surplus > PROJECT_THUMBNAIL_HEIGHT + PROJECT_THUMBNAIL_SHADOW
				? minProjects + 1 : minProjects;
		}
		return Math.min( projects.length, MIN_PROJECT_ON_SCREEN );
	}

	get totalPages() {
		const { state: { projects } } = this;
		return Math.ceil( projects.length / this.itemsPerPage );
	}

	get upButtonIsEnabled() {
		const { state: { currentPage } } = this;
		return currentPage > 1;
	}

	get downButtonIsEnabled() {
		const { state: { currentPage }, totalPages } = this;
		return currentPage < totalPages;
	}

	get projectsWithUnseenNotificationsCount() {
		const { state: { currentPage, projects } } = this;
		return selectSeenProjects(
			projects, currentPage, this.itemsPerPage
		);
	}

	_setInitialPage() {
		const { projectsWithUnseenNotificationsCount, selectedProjectID } = this.props;
		const selectedProjectIndex = projectsWithUnseenNotificationsCount.findIndex(
			element => element.project.id === selectedProjectID
		);
		const correctPage = Math.ceil( ( selectedProjectIndex + 1 ) / this.itemsPerPage ) || 1;
		return this.setState( { currentPage: correctPage } );
	}

	_setElementsHeight() {
		if ( !this._projectsContainerRef.current ) {
			return;
		}

		this.setState( {
			containerHeight: this._projectsContainerRef.current.offsetHeight
		}, () => {
			this._setInitialPage();
			this._adjustCurrentPage();
		} );
	}

	_adjustCurrentPage() {
		const { currentPage } = this.state;
		if ( currentPage > this.totalPages ) {
			this.setState( { currentPage: this.totalPages } );
		}
	}

	_onUpButtonClicked() {
		this.setState( prevState => ( { currentPage: prevState.currentPage - 1 } ) );
	}

	_onDownButtonClicked() {
		this.setState( prevState => ( { currentPage: prevState.currentPage + 1 } ) );
	}

	_resetUnseenCountForProject( projectID ) {
		this.setState( ( { projects } ) => {
			const indexToUpdate = getProjectIndex( projects, projectID );
			if ( indexToUpdate === -1 ) { return {}; }

			return {
				projects: resetUnseenCountForProjectAt( projects, indexToUpdate )
			};
		} );
	}

	render() {
		const {
			props: {
				selectedProjectID, onProjectClick
			}
		} = this;
		return (
			<NotificationsProjectsSidebar
				ref={this._projectsContainerRef}
				selectedProjectID={selectedProjectID}
				onProjectClick={onProjectClick}
				onScrollProjectsUp={this._onUpButtonClicked}
				onScrollProjectsDown={this._onDownButtonClicked}
				disableUpButton={!this.upButtonIsEnabled}
				disableDownButton={!this.downButtonIsEnabled}
				projectsWithUnseenNotificationsCount={this.projectsWithUnseenNotificationsCount}
			/>
		);
	}
}

NotificationsProjectsSidebarContainer.propTypes = {
	selectedProjectID: PropTypes.number,
	// eslint-disable-next-line react/no-unused-prop-types
	projectsWithUnseenNotificationsCount: PropTypes.arrayOf(
		ProjectWithUnseenNotificationsCountPropType
	),
	onProjectClick: PropTypes.func
};

NotificationsProjectsSidebarContainer.defaultProps = {
	selectedProjectID: null,
	projectsWithUnseenNotificationsCount: [],
	onProjectClick: undefined
};

export default connectComponent( state => ( {
	projectsWithUnseenNotificationsCount: getActiveProjectsUnseenNotificationsData( state )
} ) )( NotificationsProjectsSidebarContainer );
