import { createSelector } from 'reselect';
import FilterOption from '../entities/filterOption';
import Filters from '../entities/filters';
import {
	getRoundsForAsset,
	getSelectedAssetRoundOrCurrent,
	getShowingAssetRound,
	getShowingAssetRoundIsCurrentRound
} from './assetRounds';
import { getCommentsForSelectedAssetRound, getCommentsForSelectedAsset } from './comments';

const getSelectedFilterOptionsID = state => state.commentsViewFilter;

const getSelectedFilterOptionIDForAsset = createSelector(
	[ getSelectedFilterOptionsID, getSelectedAssetRoundOrCurrent ],
	(
		filterOptionIDs, selectedRound
	) => filterOptionIDs.get( selectedRound?.assetID )
);

export const getSelectedFilterOptionForAsset = createSelector(
	[ getSelectedFilterOptionIDForAsset ],
	filterOptionID => FilterOption.commentsFiltersByID[ filterOptionID ]
		|| FilterOption.byNotMarkAsCompleted
);

export const getFilteredCommentsForSelectedAssetRound = createSelector(
	[
		getCommentsForSelectedAssetRound,
		getCommentsForSelectedAsset,
		getSelectedFilterOptionForAsset,
		getShowingAssetRoundIsCurrentRound
	],
	(
		commentsForSelectedRound,
		commentsForSelectedAsset,
		selectedFilterOption,
		showingCurrentRound
	) => {
		const filters = new Filters(
			[ selectedFilterOption?.filterCondition ]
		);

		if ( !showingCurrentRound ) return filters.apply( commentsForSelectedRound );

		return filters.apply( commentsForSelectedAsset )
			.concat( commentsForSelectedRound )
			.uniq();
	}
);

export const getFilteredCommentIDsForSelectedAssetRound = createSelector(
	[ getFilteredCommentsForSelectedAssetRound ],
	filteredComments => filteredComments.map( comment => comment.id )
);

const getFrozenCommentsIDs = state => state.commentsFrozenViewFilter;

export const getFrozenCommentsWithRoundNumberByAssetRound = createSelector(
	[
		getFrozenCommentsIDs,
		getCommentsForSelectedAsset,
		getRoundsForAsset
	],
	( frozenCommentIDs, commentsForAsset, assetRounds ) => assetRounds.reduce(
		( commentsByRound, { id: roundID, number } ) => {
			const commentsForRound = commentsForAsset.filter(
				comment => comment.belongsToAssetRound( roundID ) && frozenCommentIDs.includes( comment.id )
			);
			return {
				[ roundID ]: { roundNumber: number, list: commentsForRound },
				...commentsByRound
			};
		},
		{}
	)
);

export const getDisplayCommentsWithRoundNumber = createSelector(
	[
		getFrozenCommentsWithRoundNumberByAssetRound,
		getShowingAssetRoundIsCurrentRound,
		getShowingAssetRound
	],
	( frozenComments, isShowingCurrentRound, showingRound ) => (
		isShowingCurrentRound
			? Object.values( frozenComments )
			: [ frozenComments[ showingRound?.id ] ]
	)
);

export const getDisplayComments = createSelector(
	[ getDisplayCommentsWithRoundNumber ],
	commentsWithRoundNumber => commentsWithRoundNumber.reduce(
		( comments, { list = [] } = {} ) => [ ...list, ...comments ],
		[]
	)
);
