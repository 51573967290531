import { Map, List } from 'immutable';
import {
	handleSuccess, mapForPayload, makeAsyncActionReducer, handleStart
} from '../lib/reduxUtils';
import {
	FETCH_NOTIFICATIONS, LOGOUT, MARK_NOTIFICATIONS_AS_SEEN,
	FETCH_PROJECT_NOTIFICATIONS, SET_SELECTED_NOTIFICATIONS_PROJECT_ID,
	SET_NOTIFICATIONS_FILTER, SET_NOTIFICATIONS_PANEL_AS_OPEN,
	SET_NOTIFICATIONS_PANEL_AS_CLOSED
} from '../actions/types';

export const markNotificationsAsSeenRequest = makeAsyncActionReducer( MARK_NOTIFICATIONS_AS_SEEN );

export const notifications = ( state = new Map(), action ) => {
	switch ( action.type ) {
	case FETCH_PROJECT_NOTIFICATIONS:
		return handleSuccess(
			state,
			action,
			prevState => prevState.merge(
				mapForPayload(
					action.payload.notifications
				)
			)
		);
	case MARK_NOTIFICATIONS_AS_SEEN: {
		const { projectID } = action.meta;

		return handleSuccess(
			state,
			action,
			prevState => prevState.map(
				notification => (
					notification.project_id === projectID
						? { ...notification, was_read: true }
						: notification
				)
			)
		);
	}
	case LOGOUT:
		return handleStart( state, action, () => new Map() );
	default:
		return state;
	}
};

export const fetchProjectNotificationsRequest = makeAsyncActionReducer(
	FETCH_PROJECT_NOTIFICATIONS
);

export const notificationsCount = ( state = new Map(), action ) => {
	switch ( action.type ) {
	case FETCH_NOTIFICATIONS:
		return handleSuccess(
			state,
			action,
			() => action.payload.reduce(
				( res, projectWithNotifications ) => res.set(
					projectWithNotifications.project.id,
					{
						unseenNotificationsCount: projectWithNotifications.unseen_notifications_count,
						totalNotificationsCount: projectWithNotifications.total_notifications_count
					}
				),
				new Map() )
		);
	case MARK_NOTIFICATIONS_AS_SEEN: {
		const { projectID } = action.meta;
		return handleSuccess(
			state,
			action,
			prevState => prevState.update(
				projectID,
				projectNotificationsCount => ( { ...projectNotificationsCount, unseenNotificationsCount: 0 } )
			)
		);
	}
	default:
		return state;
	}
};

export const projectsNotificationsPaginationData = ( state = new Map(), action ) => {
	switch ( action.type ) {
	case FETCH_PROJECT_NOTIFICATIONS:
		return handleSuccess(
			state,
			action,
			( prevState ) => {
				const { projectID, page, isRefreshing } = action.meta;
				const {
					notifications: nots,
					total_pages: totalPages,
					older_than: olderThan
				} = action.payload;

				const prevPaginationData = prevState.get( projectID );
				const hasNext = page < totalPages;

				const pageIDs = new List(
					nots.map( not => not.id )
				);

				const ids = prevPaginationData && page !== 1 && !isRefreshing
					? prevPaginationData.ids.concat( pageIDs )
					: pageIDs;

				return prevState.set(
					projectID,
					{
						page, hasNext, olderThan, ids
					}
				);
			}
		);
	case LOGOUT:
		return handleStart( state, action, () => new Map() );
	default:
		return state;
	}
};

export const selectedNotificationsProjectID = ( state = null, action ) => {
	switch ( action.type ) {
	case SET_SELECTED_NOTIFICATIONS_PROJECT_ID:
		return action.payload.projectID;
	default:
		return state;
	}
};

export const selectedNotificationsFilter = ( state = new Map(), action ) => {
	switch ( action.type ) {
	case SET_NOTIFICATIONS_FILTER: {
		const { projectID, filterType } = action.payload;
		return state.set( projectID, filterType );
	}
	default:
		return state;
	}
};

export const notificationsPanelIsOpen = ( state = false, action ) => {
	switch ( action.type ) {
	case SET_NOTIFICATIONS_PANEL_AS_OPEN:
		return true;
	case SET_NOTIFICATIONS_PANEL_AS_CLOSED:
		return false;
	default:
		return state;
	}
};
