import MaterialIcon, { MaterialIconString } from './MaterialIcon/MaterialIcon';
import SvgIcon, { SVG_ICONS, SvgIconString } from './SvgIcon/SvgIcon';

import { SECONDARY_BASE } from '../../../styles/colors';

export type IconProps = { icon: SvgIconString | MaterialIconString; size?: number; color?: string; className?: string; }

const isSvgIconString = ( icon: string ): icon is SvgIconString => icon in SVG_ICONS;

const Icon = ( { icon, size = 20, color = SECONDARY_BASE, className }: IconProps ) => (
	isSvgIconString( icon )
		? <SvgIcon icon={icon} size={size} className={className} color={color} />
		: <MaterialIcon icon={icon} size={size} color={color} className={className} />
);

export default Icon;
