import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useHotjar from 'react-use-hotjar';
import { getCurrentUser } from '../../selectors/currentUser';
import { HOTJAR_ID } from '../../config/global';

const withHotjar = Component => ( props ) => {
	const { initHotjar, identifyHotjar } = useHotjar();
	const [ isHotjarReady, setIsHotjarReady ] = useState( false );
	const currentUser = useSelector( state => getCurrentUser( state ) );

	useEffect( () => {
		if ( !currentUser || !isHotjarReady ) return;
		const { email, ...restOfInformation } = currentUser;
		identifyHotjar(
			email,
			JSON.stringify( restOfInformation ),
			console.log
		);
	}, [ identifyHotjar, currentUser, isHotjarReady ] );

	useEffect( () => {
		const isReady = initHotjar( HOTJAR_ID, 6, false );
		setIsHotjarReady( isReady );
	}, [ initHotjar ] );

	return <Component {...props} />;
};

export default withHotjar;
