import { Map } from 'immutable';

import { CREATE_CONTACT_GROUP, DELETE_CONTACT_GROUP, FETCH_CONTACT_GROUPS, LOGOUT, UPDATE_CONTACT_GROUP } from '../actions/types';
import { handleStart, handleSuccess } from '../lib/reduxUtils';

export const contactGroups = ( state = new Map(), action ) => {
	switch ( action.type ) {
	case FETCH_CONTACT_GROUPS:
		return handleSuccess( state, action, ( prevState ) => {
			const { workspaceID } = action.meta;
			const payloadWithWorkspaceID = action.payload.map( group => ( { ...group, workspace_id: workspaceID } ) );
			return prevState.replaceMatchingWithPayload( payloadWithWorkspaceID, workspaceID, 'workspace_id' );
		} );
	case CREATE_CONTACT_GROUP:
	case UPDATE_CONTACT_GROUP:
		return handleSuccess( state, action, prevState => prevState.set(
			action.payload.id,
			{ ...action.payload, workspace_id: action.meta.workspaceID }
		) );
	case DELETE_CONTACT_GROUP:
		return handleSuccess( state, action, prevState => prevState.delete( action.payload.id ) )
	case LOGOUT:
		return handleStart( state, action, () => new Map() );
	default:
		return state;
	}
};
