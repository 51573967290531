import { useState } from 'react';
import { IntercomAPI } from 'react-intercom';
import { INTERCOM_ENABLED } from '../../config/global';

import { WHITE } from '../../styles/colors';
import IconButton from '../UI/IconButton/IconButton';

const IntercomButton = () => {
	const [ showingIntercom, setShowingIntercom ] = useState( false );

	if ( INTERCOM_ENABLED ) {
		IntercomAPI( 'onHide', () => setShowingIntercom( false ) );
		IntercomAPI( 'onShow', () => setShowingIntercom( true ) );
	}

	return (
		<IconButton
			icon="help_outline"
			color={WHITE}
			onClick={() => IntercomAPI( showingIntercom ? 'hide' : 'show' )}
			size="18px"
			disabled={!INTERCOM_ENABLED}
		/>
	);
};

export default IntercomButton;
