import { useEffect, useCallback, useRef } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

import historyPropType from '../../types/history';
import { connectComponent } from '../../lib/connectComponent';

import NotificationsButton
	from '../../components/notifications/NotificationsButton/NotificationsButton';
import { getCurrentUserHasUnseenNotificationsFromActiveProjects } from '../../selectors/notifications';

export const NotificationsButtonContainer = (
	{
		hasUnseenNotifications, fetchNotifications, history, setCurrentActiveMarkupComment
	}
) => {
	const ref = useRef();

	useEffect(
		() => {
			if ( fetchNotifications ) { fetchNotifications(); }
		},
		[ fetchNotifications ]
	);

	useEffect( () => {
		if ( history.location?.state?.onOpen ) ref.current?.show();
	}, [ history.location?.state?.onOpen ] )

	const onNotificationClicked = useCallback(
		( notification ) => {
			if ( notification.hasAValidRelatedObject ) {
				if ( notification.relatedObjectIsComment ) {
					const comment = notification.relatedObject;
					const commentID = comment.parentID || comment.id;
					setCurrentActiveMarkupComment( commentID, false, false );
				}
				history.replace( notification.relatedObjectUrl );
			}
		},
		[ history ]
	);

	const onNotificationProjectClicked = useCallback(
		( notification ) => {
			history.replace( notification.projectUrl, { onOpen: true } );
		},
		[ history ]
	);

	const onNotificationFolderClicked = useCallback(
		( notification ) => {
			history.replace( notification.folderUrl, { onOpen: true } );
		},
		[ history ]
	);

	return (
		<NotificationsButton
			ref={ref}
			hasUnseenNotifications={hasUnseenNotifications}
			onNotificationClicked={onNotificationClicked}
			onNotificationProjectClicked={onNotificationProjectClicked}
			onNotificationFolderClicked={onNotificationFolderClicked}
		/>
	);
};

NotificationsButtonContainer.propTypes = {
	fetchNotifications: PropTypes.func,
	hasUnseenNotifications: PropTypes.bool,
	history: historyPropType.isRequired,
	setCurrentActiveMarkupComment: PropTypes.func
};

NotificationsButtonContainer.defaultProps = {
	hasUnseenNotifications: false,
	fetchNotifications: undefined,
	setCurrentActiveMarkupComment: () => {}
};

export default connectComponent( state => ( {
	hasUnseenNotifications: getCurrentUserHasUnseenNotificationsFromActiveProjects( state )
} ) )( withRouter( NotificationsButtonContainer ) );
