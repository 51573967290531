import { createRef } from 'react';
import PropTypes from 'prop-types';
import { connectComponent } from '../../lib/connectComponent';
import { getCurrentUser } from '../../selectors/currentUser';
import { isEmailTakenError } from '../../errors/user';
import { ERROR_DEFAULT, ERROR_TAKEN_EMAIL, SUCCESS_UPDATE_DEFAULT } from '../../messages/user';

import UserPropType from '../../types/user';

import ProfileSettings from '../../components/ProfileSettings/ProfileSettings';
import Container from '../Container';

export class ProfileSettingsContainer extends Container {
	constructor( props ) {
		super( props );

		this.profileSettingsRef = createRef();
		this._onUpdateProfile = this._onUpdateProfile.bind( this );
		this._onSendEmailVerification = this._onSendEmailVerification.bind( this );
	}

	_clearInfomation() {
		if ( !this.profileSettingsRef.current ) { return; }
		this.profileSettingsRef.current.clear();
	}

	_onUpdateProfile( data, onSuccess ) {
		const { currentUser, editProfile, showErrorMessage } = this.props;

		this.handleAsyncActionResult(
			editProfile( currentUser.id, data, undefined ),
			{ title: SUCCESS_UPDATE_DEFAULT },
			null,
			undefined,
			() => { onSuccess(); this._clearInfomation(); },
			() => showErrorMessage( { title: ERROR_DEFAULT } )
		);
	}

	_onSendEmailVerification( email, onSuccess ) {
		const { sendProfileVerificationEmail } = this.props;

		this.handleAsyncActionResult(
			sendProfileVerificationEmail( email ),
			null,
			null,
			undefined,
			onSuccess(),
			result => this._showEmailVerificationErrorMessage( result )
		);
	}

	_showEmailVerificationErrorMessage( { payload } ) {
		const { showErrorMessage } = this.props;
		const message = isEmailTakenError( payload )
			? ERROR_TAKEN_EMAIL
			: ERROR_DEFAULT;
		showErrorMessage( { title: message } );
	}

	render() {
		const { currentUser } = this.props;

		return (
			<ProfileSettings
				ref={this.profileSettingsRef}
				currentUser={currentUser}
				updateProfileHandler={this._onUpdateProfile}
				sendEmailVerificationHandler={this._onSendEmailVerification}
			/>
		);
	}
}

ProfileSettingsContainer.propTypes = {
	currentUser: UserPropType.isRequired,
	editProfile: PropTypes.func,
	sendProfileVerificationEmail: PropTypes.func
};

ProfileSettingsContainer.defaultProps = {
	editProfile: () => {},
	sendProfileVerificationEmail: () => {}
};

export default connectComponent( state => ( {
	currentUser: getCurrentUser( state )
} ) )( ProfileSettingsContainer );
