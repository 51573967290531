/* eslint-disable import/no-mutable-exports */
import FilesUploader from './FilesUploader/FilesUploader';
import DirectFilesUploaderCoordinator from './DirectFilesUploaderCoordinator';
import DirectFileUploaderStoreSubscriber from './DirectFileUploaderStoreSubscriber';

let directFilesUploader;
let directFilesUploaderSubscriber;
let filesUploader;

const initializeDirectFilesUploader = ( store ) => {
	directFilesUploader = new DirectFilesUploaderCoordinator();
	directFilesUploaderSubscriber = new DirectFileUploaderStoreSubscriber(
		directFilesUploader,
		store
	);
	directFilesUploaderSubscriber.start();
	filesUploader = new FilesUploader();
};

export { directFilesUploader, directFilesUploaderSubscriber, filesUploader };
export default initializeDirectFilesUploader;
