import { Map } from 'immutable';
import { SET_ASSETS_VIEW_SIZE } from '../actions/types';

const defaultState = new Map( {
	itemSize: 50
} );

export const assetsViewOptions = ( state = defaultState, action ) => {
	switch ( action.type ) {
	case SET_ASSETS_VIEW_SIZE:
		return state.set( 'itemSize', action.payload );
	default:
		return state;
	}
};
