export default class PricingPlanToDisplay {
	constructor( { pricingPlan } ) {
		this._pricingPlan = pricingPlan;
	}

	get isFree() {
		return this._pricingPlan.isFree;
	}

	get hasFreePrice() {
		return this._pricingPlan.hasFreePrice;
	}

	get annualCost() {
		return this._pricingPlan.annualPrice?.cost / 12 || undefined;
	}

	get annualDiscount() {
		return this._pricingPlan.annualDiscount;
	}

	get includedMembers() {
		return this._pricingPlan.includedMembers;
	}

	get includedProjects() {
		return this._pricingPlan.includedProjects;
	}

	get includedStorage() {
		return this._pricingPlan.includedStorage;
	}

	get monthlyCost() {
		return this._pricingPlan.monthlyPrice?.cost;
	}

	get name() {
		return this._pricingPlan.name;
	}

	get freePrice() {
		return this._pricingPlan.freePrice;
	}

	get monthlyPrice() {
		return this._pricingPlan.monthlyPrice;
	}

	get annualPrice() {
		return this._pricingPlan.annualPrice;
	}

	get pricingPlan() {
		return this._pricingPlan;
	}

	get features() {
		return [
			`${this.includedStorage.formatBytes()} of storage per workspace`,
			'Unlimited collaborators',
			'Unlimited projects',
			'Option to add Storage',
			'Option to add Members'
		];
	}

	get costDescription() {
		return 'per member per month';
	}

	get _levelForPlan() {
		return { 'Pro Plan': 0, 'Team Plan': 1 };
	}

	get _nameWithOutPlan() { return this.name.split( ' ' ).slice( 0, -1 ).join( ' ' ); }

	_showInactiveSubscriptionStatus( { workspace } ) {
		return {
			status: 'reactivate',
			text: `Choose ${this._nameWithOutPlan}`,
			disabled: !this._canReactiveSubscription( workspace )
		};
	}

	_canReactiveSubscription( { membersCount } ) {
		return membersCount <= this.includedMembers;
	}
}
