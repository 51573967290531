import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';

import InformationTooltipIcon from '../../UI/InformationTooltipIcon/InformationTooltipIcon';

export const PriceLine = ( {
	cost, costDescription, tooltipInfoText
} ) => (

	<div className="PriceLine">
		<h2>${cost}</h2><h5>{costDescription}</h5>
		{tooltipInfoText && (
			<InformationTooltipIcon
				html={(
					<div style={{ width: '280px', textAlign: 'center' }}>
						{tooltipInfoText}
					</div>
				)}
			/>
		)}
	</div>

);

PriceLine.propTypes = {
	cost: PropTypes.number.isRequired,
	costDescription: PropTypes.string.isRequired,
	tooltipInfoText: PropTypes.string
};

PriceLine.defaultProps = {
	tooltipInfoText: undefined
};

export default observer( PriceLine );
