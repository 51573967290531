import { NOTIFICATIONS_URL, PROJECTS_URL, NOTIFICATIONS_MARK_AS_SEEN_URL } from '../config/urls';
import {
	FETCH_NOTIFICATIONS, MARK_NOTIFICATIONS_AS_SEEN,
	FETCH_PROJECT_NOTIFICATIONS, SET_SELECTED_NOTIFICATIONS_PROJECT_ID,
	SET_NOTIFICATIONS_FILTER, SET_NOTIFICATIONS_PANEL_AS_OPEN,
	SET_NOTIFICATIONS_PANEL_AS_CLOSED
} from './types';
import {
	getProjectNotificationsPaginationOlderThan,
	getSelectedNotificationsFilterForProject,
	getProjectNotificationsFetchedPage
} from '../selectors/notifications';
import { createURLWithParams } from '../lib/urlUtils';
import FilterOption from '../entities/filterOption';

export const FETCH_NOTIFICATIONS_COUNT_PER_PAGE = 20;
export const FIRST_PAGE = 1;

const hasSelectedFilter = filter => (
	filter && filter.id !== FilterOption.none.id
);

export const fetchNotifications = () => ( dispatch, _, api ) => dispatch( {
	type: FETCH_NOTIFICATIONS,
	promise: api
		.get( NOTIFICATIONS_URL )
		.then( response => response.response )
} );

const fetchNotificationsFromApi = (
	api,
	state,
	projectID,
	page,
	olderThan,
	perPage = FETCH_NOTIFICATIONS_COUNT_PER_PAGE
) => {
	const notificationsFilter = getSelectedNotificationsFilterForProject(
		state, { projectID }
	);

	return api
		.get( createURLWithParams(
			`${PROJECTS_URL}/${projectID}/notifications`,
			{
				page,
				per_page: perPage,
				older_than: (
					olderThan || new Date().toISOString()
				),
				filter_type: (
					hasSelectedFilter( notificationsFilter )
						? notificationsFilter.filterCondition.value
						: undefined
				),
				...(
					{}
				)
			}
		) )
		.then( response => response.response );
};

const fetchProjectNotificationsPage = (
	api,
	state,
	projectID,
	page,
	olderThan,
	perPage = FETCH_NOTIFICATIONS_COUNT_PER_PAGE
) => ( {
	type: FETCH_PROJECT_NOTIFICATIONS,
	promise: fetchNotificationsFromApi(
		api,
		state,
		projectID,
		page,
		olderThan,
		perPage
	),
	meta: { projectID, page }
} );

export const fetchProjectNotifications = projectID => (
	dispatch, getState, api
) => dispatch( fetchProjectNotificationsPage(
	api, getState(), projectID, 1
) );

export const fetchProjectNotificationsNextPage = projectID => (
	dispatch, getState, api
) => {
	const state = getState();

	const page = getProjectNotificationsFetchedPage( state, { projectID } );
	const olderThan = getProjectNotificationsPaginationOlderThan( state, { projectID } );

	return dispatch( fetchProjectNotificationsPage(
		api, state, projectID, page ? page + 1 : 1, olderThan
	) );
};

export const refreshProjectNotifications = projectID => (
	dispatch, getState, api
) => {
	const state = getState();

	const page = getProjectNotificationsFetchedPage( state, { projectID } );
	const olderThan = getProjectNotificationsPaginationOlderThan( state, { projectID } );
	const perPage = page * FETCH_NOTIFICATIONS_COUNT_PER_PAGE;

	return dispatch( {
		type: FETCH_PROJECT_NOTIFICATIONS,
		promise: fetchNotificationsFromApi(
			api,
			state,
			projectID,
			1,
			olderThan,
			perPage
		),
		meta: { projectID, page, isRefreshing: true }
	} );
};

export const markNotificationsAsSeen = projectID => ( dispatch, _, api ) => dispatch( {
	type: MARK_NOTIFICATIONS_AS_SEEN,
	promise: api
		.post(
			NOTIFICATIONS_MARK_AS_SEEN_URL,
			{ project_id: projectID }
		),
	meta: { projectID }
} );

export const setSelectedNotificationsProjectID = projectID => ( {
	type: SET_SELECTED_NOTIFICATIONS_PROJECT_ID,
	payload: { projectID }
} );

export const setNotificationsFilter = (
	projectID, filterType
) => ( dispatch ) => {
	dispatch( {
		type: SET_NOTIFICATIONS_FILTER,
		payload: { projectID, filterType }
	} );

	return dispatch(
		fetchProjectNotifications( projectID )
	).then(
		() => ( { projectID } )
	);
};

export const setNotificationsPanelAsOpen = isOpen => ( {
	type: isOpen ? SET_NOTIFICATIONS_PANEL_AS_OPEN : SET_NOTIFICATIONS_PANEL_AS_CLOSED
} );
