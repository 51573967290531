export type LabelParams = {
	name: string,
	baseColor: string,
	hoverColor: string
};

type LabelGroupParams = {
	id: number,
	name: string,
	labels: LabelParams[]
};

export type LabelGroupJSON = {
	id: number,
	name: string,
	labels: Array<{
		name: string,
		base_color: string,
		hover_color: string
	}>;
};

export default class LabelGroup {
	id: number;
	name: string;
	labels: LabelParams[];

	constructor( { id, name, labels }: LabelGroupParams ) {
		this.id = id;
		this.name = name;
		this.labels = labels;
	}

	static fromJSON( json: LabelGroupJSON ) {
		return new LabelGroup( {
			id: json.id,
			name: json.name,
			labels: json.labels.map(
				( { name, base_color, hover_color } ) => ( {
					name,
					baseColor: base_color,
					hoverColor: hover_color
				} )
			)
		} );
	}

	toJSON(): LabelGroupJSON {
		return {
			id: this.id,
			name: this.name,
			labels: this.labels.map(
				( { name, baseColor, hoverColor } ) => ( {
					name,
					base_color: baseColor,
					hover_color: hoverColor
				} )
			)
		};
	}
}
