import { createSelector } from 'reselect';
import { getWorkspaceID } from './workspaces';
import LabelGroup from '../entities/labelGroup';

const getRawLabelGroups = state => state.labelGroups;

export const getWorkspaceLabelGroups = createSelector(
	[ getRawLabelGroups, getWorkspaceID ],
	( labelGroups, workspaceID ) => labelGroups
		.filter( group => group.workspace_id === workspaceID )
		.toArray()
		.map( LabelGroup.fromJSON )
);
